import React from 'react';
import shared from 'app/shared';
import { connect } from 'react-redux';
import { getByPageId } from 'actions/crudActions';
import { updateCategoryArrangement } from 'actions/categoryAction';
import styled from 'styled-components';
import crudTypes from '../../../config/crudTypes';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import BannerCard from './bannerCard';
import { format } from 'date-fns';
import { getSortState } from 'config/helpers/sorter';
import AddBannerCard from './addBannerCard';
import AddBannerPopup from './addBannerPopup';
import EditBanner from './editBanner';


const {
  views: { Page, FilterableTableStructure, Grid },
  helpers: { colors, transition },
} = shared;

const {
  StatsSectionWrapper,
} = FilterableTableStructure;



const GridWrapper = styled.div`
  padding-top: 30px;
`;

const CategoryTypeWrapper = styled.div`
  border-bottom: 2px solid ${colors.blue};
  margin-bottom: 28px;
  display: flex;
`;

const CategoryTypeText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  width: 100%;
  color: ${colors.blue};
  margin: 10px 0;
`;

const cardWrapper = (Element) => styled(Element)`
  transition: ${transition};
  transform: translateY(0);

  :hover {
    cursor: pointer;
  }
`;

const initialState = {
  sort: {
    key: 'id',
    order: 1,
  },
  search: '',
  title: '',
  uri: '',
  startTime: '',
  endTime: '',
  imageUri: '',
  status: '',
  period: false,
  showAddPopup: false,
  currentPage: 1,
  extraCatCurrentPage: 1,
  getStatus: '',
  categoryType: 0,
  addMainOrExtraCategory: 0,
  filter: {
    filterCondition: null,
    filterParameters: [],
  },
  pageNumber: 1,
  pageSize: 30,
  sorting: [
    {
      columnName: 'name',
      direction: 'asc',
    },
  ],
};

const BannerCardWrapper = cardWrapper(BannerCard);

const SortableGrid = sortableContainer(({ children }) => {
  return <Grid>{children}</Grid>;
});

const AddBannerCardWrapper = cardWrapper(AddBannerCard);

SortableGrid.Section = sortableElement(({ children, sizes }) => {
  return <Grid.Section sizes={sizes}>{children}</Grid.Section>;
});

class BannerPage extends React.Component {
  state = initialState;

  setGetStatus = (status) => {
    this.setState({ getStatus: status });
  };

  componentDidMount = () => {
    this.props.getByPageId(crudTypes.banner, this.props.facebookPages.activePage.id);
    this.setState({title: this.props.banner.current.title})
  };

  componentDidUpdate(prevProps) {
    if (prevProps.banner.data.length !== this.props.banner.data.length) this.componentDidMount();
  }

  updateSortState = (key) => {
    this.setState((prevState) => ({
      sort: getSortState(prevState.sort, key),
    }));
  };

  updateAddMainOrExtraCategory = (addMainOrExtraCategory) => {
    this.setState({ addMainOrExtraCategory });
  };

  toggleAddPopup = () => this.setState({ showEditPopup: !this.state.showEditPopup });
  toggleEditPopup = () => this.setState({ showEditBanner: !this.state.showEditBanner });

  render() {
    const {
      state: { showEditPopup, showEditBanner, addMainOrExtraCategory},
      updateAddMainOrExtraCategory,
      toggleAddPopup,
      toggleEditPopup,
      
    } = this;

    return (
      <Page>
        <AddBannerPopup
          show={showEditPopup}
          toggle={toggleAddPopup}
          selectedCategoryType={addMainOrExtraCategory}
        />
        <EditBanner
          show={showEditBanner}
          toggle={toggleEditPopup}
          selectedCategoryType={addMainOrExtraCategory}
        />
        <StatsSectionWrapper>
          <GridWrapper>
            <CategoryTypeWrapper>
              <CategoryTypeText>Visibility</CategoryTypeText>
            </CategoryTypeWrapper>

            {!this.props.banner.current.id ? (
              <AddBannerCardWrapper
                onClick={() => {
                  updateAddMainOrExtraCategory(0);
                  toggleAddPopup();
                }}
              />
            ) : (
              <BannerCardWrapper
                title={this.props.banner.current.title}
                startTime={format(new Date(this.props.banner.current.startTime), 'YYYY-MM-DD')}
                endTime={format(new Date(this.props.banner.current.endTime), 'YYYY-MM-DD')}
                status={this.props.banner.current.status}
                imageUri={this.props.banner.current.imageURI}
                alwaysActive={this.props.banner.current.alwaysActive}
                id={this.props.banner.current.id}
                onClick={() => {
                  updateAddMainOrExtraCategory(0);
                  toggleEditPopup();
                }}
              />
            )}
          </GridWrapper>
        </StatsSectionWrapper>
      </Page>
    );
  }
}
BannerPage.path = '/dashboard/banner';
const mapStateToProps = (state) => ({
  facebookPages: state.facebookPages,
  banner: state.banner,
});

export default connect(mapStateToProps, { getByPageId, updateCategoryArrangement })(
  BannerPage,
);

export { BannerPage };
