import styled from 'styled-components';

const FieldError = styled.div`
  // background: rgba(242, 69, 61, 0.2);
  color: #f2453d;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  border-radius: 3px;
  align-items: center;
  letter-spacing: 1px;
  font-weight: 600;
  padding: 1px 5px;
  display: flex;
  width: 100%;
  margin-top: 1px;
`;

export default FieldError;
