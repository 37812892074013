export default {
  report: {
    tag: 'REPORT',
    uri: 'Report',
  },
  order: {
    tag: 'ORDERS',
    uri: 'Orders',
  },
  product: {
    tag: 'PRODUCTS',
    uri: 'Product',
  },
  category: {
    tag: 'CATEGORIES',
    uri: 'Categories',
  },
  client: {
    tag: 'CLIENTS',
    uri: 'Clients',
  },
  promotion: {
    tag: 'PROMOTION',
    uri: 'Promotion',
  },
  voucher: {
    tag: 'VOUCHER',
    uri: 'Voucher',
  },
  image: {
    tag: 'IMAGE',
    uri: 'Image',
  },
  helpCenter: {
    tag: 'HELPCENTER',
    uri: 'HelpCenter',
  },
  campaign: {
    tag: 'CAMPAIGN',
    uri: 'Marketing/Campaigns',
  },
  table: {
    tag: 'TABLES',
    uri: 'Table',
  },
  tableCategory: {
    tag: 'TABLECATEGORIES',
    uri: 'TableCategory',
  },
  banner: {
    tag: 'BANNER',
    uri: 'banner'
  }
};
