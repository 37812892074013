import styled from 'styled-components';
import colors from '../helpers/colors';

const Paper = styled.div`
  color: ${colors.textColor};
  background: ${colors.white};
  border: 1px solid #E9EAF1;
  border-radius: 3px;
  box-sizing: border-box;
  width: 100%;
  min-width: 0;
  margin: 0 auto;

  @media(max-width: 400px){
    font-size: 80%;
  }
`;

export default Paper;
