import React, { useRef } from 'react';
import icons from 'app/shared/views/icons';
import { Input } from 'antd';

const inputStyles = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: '16px',
  display: 'flex',
  alignItems: 'center',
  color: '#748AA1',
  padding: '11px 20px',
  width: '100%',
  background: '#FFFFFF',
  border: 'none',
  borderRadius: '15px',
};

const ProductSearchInput = ({ style, outerStyle, ...props }) => {
  const myRef = useRef(null);

  const icon = <icons.Search strokeWidth={2} fill={'#748AA1'} width={'30px'} height={'30px'} />;
  return (
    <div style={{ ...outerStyle }} ref={myRef}>
      <Input
        type="text"
        start={icon}
        height={35}
        placeholder={'Search item...'}
        // onFocus={() => myRef.current.scrollIntoView({ behavior: 'smooth' })}
        style={{
          ...inputStyles,
          ...style,
        }}
        {...props}
      />
    </div>
  );
};

export default ProductSearchInput;
