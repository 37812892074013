import React, {useState} from 'react';
import styled from 'styled-components';
import shared from 'app/shared';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import crudTypes from '../../../config/crudTypes';
import { add } from '../../../actions/crudActions';

import addClientIllustration from '../../../assets/images/addClientIllustartion.svg';

const {
  views: { Modal, AddItemPopup, Button, Input, Icons },
  helpers: { colors },
} = shared;

const AddItemPopupWrapper = styled.div`
  width: 450px;
`;

const ErrorWrapper = styled.div`
    width: 100%;
    background: rgba(242,69,61, .2);
    color: #F2453D;
    margin-bottom: 10px;
    font-size: 11px;
    line-height: 18px;
    border-radius: 3px;
    align-items: center;
    letter-spacing: 1px;
    font-weight: bold;
    padding: 1px 5px;
    display: ${props => props.show === 'ERROR' ? 'flex' : 'none'};
`;

const WarningWrapper = styled.div`
    width: 100%;
    display: flex;
    background: rgba(255,152,0, .2);
    color: #FF9800;
    margin-bottom: 10px;
    font-size: 11px;
    line-height: 18px;
    border-radius: 3px;
    align-items: center;
    letter-spacing: 1px;
    font-weight: bold;
    padding: 1px 5px;
    margin-top: 10px;
`;
const AddClientPopup = props => {
  const [postStatus, setPostStatus] = useState('');
  const {toggle} = props;

  const changePostStatus = resetForm => status => {
    setPostStatus(status);
    if(status === 'OK') {
      resetForm();
      toggle();
    }
  };

  return (
    <Formik
    initialValues={{
      clientName: '',
      phone:  '',
      email: '',
      location:'',
    }}

      onSubmit={(values, { resetForm }) => {
        const { clientName, email, phone, location } = values;

        const credentials = clientName.split(' ');

        const name = credentials[0];

        const surname = credentials[1] || '';

        let newValues = {
          name: name,
          surname: surname,
          email: email,
          phone: phone,
          locations: [{
            locationName:location,
            locationId: 0,
            clientId: 0
          }],
          pageId: props.facebookPages.activePage.id,
        };
        props.add(crudTypes.client, newValues, changePostStatus(resetForm));
      }}
      validationSchema={Yup.object().shape({
        clientName: Yup.string().required('First Name is required'),
        phone: Yup.number().required('Phone number is required'),
        email: Yup.string().email('Email is invalid'),
        location: Yup.string().required('Location is required'),
      })}
    >
      {innerProps => {
        const { touched, errors, values, handleChange, handleSubmit } = innerProps;
        return (
          <Modal toggle={props.toggle} show={props.show}>
            <AddItemPopupWrapper>
              <AddItemPopup
                title={'Add New Client'}
                actions={[
                  () => (
                    <Button.Button
                      color={colors.blue}
                      text="Save"
                      onClick={() => handleSubmit()}
                    />
                  ),
                  () => (
                    <Button.WhiteButton
                      text="Cancel"
                      onClick={() => {
                        props.toggle();
                      }}
                    />
                  ),
                ]}
                illustration={addClientIllustration}
              >
                <ErrorWrapper show={postStatus}>
                  <Icons.XIcon fill={colors.red} style={{marginRight: 10}} width={16} />
                  <span>Error adding the client</span>
                </ErrorWrapper>
                <AddItemPopup.InputRow>
                  <Input labelText={'Full Name'} onChange={handleChange} id={'clientName'} value={values.clientName} autoComplete='off' />
                  {errors.clientName && touched.clientName ? <WarningWrapper> <Icons.WarningIcon fill={colors.yellow} style={{marginRight: 10}} width={16} /> {errors.clientName}</WarningWrapper> : null}
                </AddItemPopup.InputRow>
                <AddItemPopup.InputRow>
                  <Input labelText={'Email'} onChange={handleChange} id={'email'} value={values.email} autoComplete='off' />
                  {errors.email && touched.email ? <WarningWrapper> <Icons.WarningIcon fill={colors.yellow} style={{marginRight: 10}} width={16} /> {errors.email}</WarningWrapper> : null}
                </AddItemPopup.InputRow>
                <AddItemPopup.InputRow>
                  <Input labelText={'Phone'} onChange={handleChange} id={'phone'} value={values.phone} autoComplete='off' />
                  {errors.phone && touched.phone ? <WarningWrapper> <Icons.WarningIcon fill={colors.yellow} style={{marginRight: 10}} width={16} /> {errors.phone}</WarningWrapper> : null}
                </AddItemPopup.InputRow>
                <AddItemPopup.InputRow>
                  <Input labelText={'Location'} onChange={handleChange} id={'location'} value={values.location} autoComplete='off' />
                  {errors.location && touched.location ? <WarningWrapper> <Icons.WarningIcon fill={colors.yellow} style={{marginRight: 10}} width={16} /> {errors.location}</WarningWrapper> : null}
                </AddItemPopup.InputRow>
              </AddItemPopup>
            </AddItemPopupWrapper>
          </Modal>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => ({ clients: state.clients, facebookPages: state.facebookPages });

export default connect(
  mapStateToProps,
  { add },
)(AddClientPopup);

export { AddClientPopup };
