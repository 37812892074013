import Url from '../config/api';
import { setActivePageOk, updateActivePage } from './facebookAction';
import { buildAxiosInstance } from 'actions/common';

const { baseUrl } = Url;

const axiosClient = buildAxiosInstance(baseUrl);

const baseURI = `${baseUrl}/Pages`;
const visibilityURI = `${baseUrl}/Visibility`;

export const updatePageCurrency = (pageID, data, setStatus = null) => {
  return (dispatch, getState) => {
    axiosClient
      .put(`${baseURI}/UpdatePageCurreny/${pageID}`, JSON.stringify(data), {
        headers: { 'content-type': 'application/vnd.api+json' },
      })
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
          // const page = { ...getState().facebookPages.activePage, currency: data };
          // dispatch(setActivePageOk(page));
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
      });
  };
};

export const updateUserProfile = (pageId, data, setStatus = null) => {
  return (dispatch, getState) => {
    axiosClient
      .put(`${baseURI}/UpdateUserProfile/${pageId}`, data)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
          // const page = { ...getState().facebookPages.activePage, ...data };
          // dispatch(setActivePageOk(page));
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
      });
  };
};

export const setActiveBot = (data, setStatus = null) => {
  return (dispatch, getState) => {
    const pageID = getState().facebookPages.activePage.id;
    axiosClient
      .put(`${baseURI}/SetActiveBot/${pageID}`, JSON.stringify(data), {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
          const page = { ...getState().facebookPages.activePage, activeBot: data };
          dispatch(setActivePageOk(page));
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
      });
  };
};

export const updateChatbotSettings = (data, setStatus = null) => {
  return (dispatch, getState) => {
    const pageId = getState().facebookPages.activePage.id;
    axiosClient
      .put(`${baseURI}/UpdateChatbotSettings/${pageId}`, data)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
          const page = { ...getState().facebookPages.activePage, ...res.data };
          dispatch(setActivePageOk(page));
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
      });
  };
};

export const updateOpenHoursOnlineSettings = (openHours, timezone = 1, setStatus = null) => {
  return (dispatch, getState) => {
    const pageId = getState().facebookPages.activePage.id;
    axiosClient
      .put(`${baseURI}/update-online-opening-hours/${pageId}/${timezone}`, openHours)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
          dispatch({ type: 'OPEN_HOURS_UPDATED_OK', data: JSON.stringify(openHours) });
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
      });
  };
};

export const updatePageBanner = (url, setStatus = null) => {
  return (dispatch, getState) => {
    const pageId = getState().facebookPages.activePage.id;
    axiosClient
      .put(`${baseURI}/UpdatePageBanner/${pageId}`, { pageBannerURL: url })
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          dispatch(updateActivePage(pageId, setStatus));
          setStatus && setStatus('OK');
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
      });
  };
};

export const updateAggregatorPicture = (url, setStatus = null) => {
  return (dispatch, getState) => {
    const pageId = getState().facebookPages.activePage.id;
    axiosClient
      .put(`${baseURI}/UpdateAggregatorPicture/${pageId}`, { aggregatorPictureURL: url })
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          dispatch(updateActivePage(pageId, setStatus));
          setStatus && setStatus('OK');
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
      });
  };
};

export const getPageUsers = (role = null, page = 1, pagesize = 10000, setStatus = null) => {
  return (dispatch) => {
    axiosClient
      .get(`${baseUrl}/Auth/users-by-page/${page}/${pagesize}`, {
        params: { role },
        headers: {
          page: JSON.parse(localStorage.getItem('Page')),
        },
      })
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
          dispatch({ type: 'GET_ALL_PAGE_USERS', data: res.data.results });
        } else {
          setStatus && setStatus('ERROR');
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
        dispatch({ type: 'GET_ALL_PAGE_USERS_ERROR' });
      });
  };
};

export const addPageUser = (data, name, setStatus = null) => {
  return (dispatch) => {
    (!data.id
      ? axiosClient.post(`${baseUrl}/Auth/create-page-account`, {
          pageId: data.pageId,
          username: data.email,
          name: name,
          email: data.email,
          password: data.password,
          confirmPassword: data.confirmPassword,
          role: data.role,
        })
      : axiosClient.put(`${baseUrl}/Auth/update-page-account/${data.id}`, {
          id: data.id,
          pageId: data.pageId,
          username: data.email,
          name: name,
          email: data.email,
          password: data.password,
          confirmPassword: data.confirmPassword,
          role: data.role,
        })
    )
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
          dispatch({ type: 'PUT_PAGE_USER', data: res.data });
        } else {
          setStatus && setStatus('ERROR');
        }
      })
      .catch((error) => {
        setStatus && setStatus(error.response.data);
        dispatch({ type: 'PUT_PAGE_USER_ERROR' });
      });
  };
};
export const deletePageUser = (id, setStatus = null) => {
  return (dispatch) => {
    axiosClient
      .delete(`${baseUrl}/Auth/delete-page-account/${id}`)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
          dispatch({ type: 'DELETE_PAGE_USER', data: id });
        } else {
          setStatus && setStatus('ERROR');
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
        dispatch({ type: 'DELETE_PAGE_USER_ERROR' });
      });
  };
};

export const getNotificationSettings = (pageId, done = null) => (dispatch) => {
  axiosClient
    .get(`${baseURI}/GetNotificationSettings/${pageId}`)
    .then((res) => done && done(null, res.data))
    .catch((err) => done && done(err));
};

export const updateNotificationSettings = (pageId, notificationSettings, done = null) => (
  dispatch,
) => {
  axiosClient
    .put(`${baseURI}/UpdateNotificationSettings/${pageId}`, notificationSettings)
    .then((res) => done && done(null, res.data))
    .catch((err) => done && done(err));
};

export const getPageConfiguration = (pageId, key, done = null, axiosInstance = axiosClient) => (
  dispatch,
) => {
  axiosInstance
    .get(`${baseURI}/GetConfiguration/${pageId}/${key}`)
    .then((res) => {
      const value = res.status === 204 ? {} : res.data;
      dispatch({ type: 'SET_PAGE_CONFIGURATION', key, value });
      done && done(null, res.data);
    })
    .catch((err) => done && done(err));
};

export const setPageConfiguration = (pageId, key, value, done = null) => (dispatch) => {
  axiosClient
    .put(`${baseURI}/SetConfiguration/${pageId}/${key}`, value)
    .then((res) => {
      dispatch({ type: 'SET_PAGE_CONFIGURATION', key, value });
      done && done(null, res.data);
    })
    .catch((err) => done && done(err));
};

export const getBusinessNearby = (lat, lng, done = null) => (dispatch) => {
  axiosClient
    .post(`${baseURI}/get-business-nearby`, {
      lat,
      lng,
    })
    .then((res) => {
      dispatch({ type: 'GET_BUSINESS_NEARBY', data: res.data });
      done && done(null, res.data);
    })
    .catch((err) => done && done(err));
};

export const getAvailableAggregatorCategories = (done = null) => (dispatch) => {
  axiosClient
    .get(`${baseURI}/GetAvailableAggregatorCategories`)
    .then((res) => {
      dispatch({ type: 'GET_AVAILABLE_AGGREGATOR_CATEGORIES', data: res.data });
      done && done(null, res.data);
    })
    .catch((err) => done && done(err));
};

export const setPageAggregatorCategories = (pageId, aggregatorCategoryIds, done = null) => (
  dispatch,
) => {
  axiosClient
    .post(`${baseURI}/SetAggregatorCategories`, {
      pageId,
      aggregatorCategoryIds,
    })
    .then((res) => {
      dispatch(updateActivePage(pageId));
      done && done(null, res.data);
    })
    .catch((err) => done && done(err));
};

export const getAggregatorPages = (lat, lng, done = null) => (dispatch) => {
  axiosClient
    .get(`${baseURI}/GetAggregatorPages`)
    .then((res) => {
      dispatch({ type: 'GET_AGGREGATOR_PAGES_OK', data: res.data });
      done && done(null, res.data);
    })
    .catch((err) => done && done(err));
};

export const getAggregatorEvents = (lat, lng, done = null) => (dispatch) => {
  axiosClient
    .post(`${visibilityURI}/GetAggregatorEvents`, {
      filter: {
        filterCondition: null,
        filterParameters: [],
      },
      pageNumber: 1,
      pageSize: 10000,
      sorting: [],
    })
    .then((res) => {
      dispatch({ type: 'GET_AGGREGATOR_EVENTS_OK', data: res.data?.items });
      done && done(null, res.data?.items);
    })
    .catch((err) => done && done(err));
};
