import Url from '../config/api';
import normalizePhoneNumber from 'app/shared/helpers/phonenumber';
import { buildAxiosInstance } from 'actions/common';

const { baseUrl } = Url;

const baseURI = `${baseUrl}/Orders`;

const axiosClient = buildAxiosInstance(baseUrl);

export const refundOrder = (id, setStatus = null) => {
  return (dispatch) => {
    axiosClient
      .put(`${baseURI}/cancelOrder/${id}`)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
          dispatch({
            type: 'ORDER_REFUND_OK',
            data: res.data,
          });
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
        dispatch({ type: 'ORDER_REFUND_ERROR' });
      });
  };
};

export const updateOrderStatus = (orderId, orderStatus, setStatus = null, orderState) => {
  return (dispatch) => {
    axiosClient
      .put(`${baseUrl}/pok/Orders/${orderId}/status`, {status: orderStatus})
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          dispatch({ type: 'PUT_ORDERS_OK', data: res.data });
          if (orderStatus !== 'CANCELLED') {
            orderState.orderStatus = 1;
          } else {
            orderState.orderStatus = 5;
          }
          setStatus && setStatus('OK', res.data);
        } else {
          setStatus && setStatus('ERROR', null, res.data);
        }
      })
      .catch((err) => {
        dispatch({ type: 'ORDER_ERROR' });
        setStatus && setStatus('ERROR', null, err.data);
      });
  };
};

export const saveOrder = (order, paymentMethod, client, setStatus = null) => {
  // Note: a better aproach will be to use map state to entity
  // then passing the data as function args
  return (dispatch) => {
    const orderEntity = mapOrderToEntity({ order, paymentMethod, client });

    (!orderEntity.id
      ? axiosClient.post(`${baseURI}`, orderEntity)
      : axiosClient.put(`${baseURI}/${orderEntity.id}`, orderEntity)
    )
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          console.log(res.data);
          // dispatch({ type: 'ORDER_SAVED', data: { id: res.data.id } });
          dispatch({ type: 'PUT_ORDERS_OK', data: res.data });
          setStatus && setStatus('OK', res.data);
        } else {
          setStatus && setStatus('ERROR', null, res.data);
        }

        // done &&
        //   done(null, {
        //     ...order,
        //     id: res.data.id,
        //   });
      })
      .catch((err) => {
        dispatch({ type: 'ORDER_ERROR' });
        setStatus && setStatus('ERROR', null, err.data);
      });
  };
};

const mapOrderToEntity = ({ order, paymentMethod, client }) => {
  return {
    ...order,
    id: order.id,
    orderLocationCoords: order.orderLocationCoords,
    orderLocation: order.orderLocation,
    orderLocation2: order.addressInfo,
    customerDescription: order.description,
    clientName: client.firstName,
    clientLastName: client.lastName,
    clientPhone: normalizePhoneNumber(client.phone),
    totalValue: order.total,
    paymentMethod: paymentMethod?.methodId ?? 0,
    promotion: order.promotion,
    voucherCode: order.voucherCode,
    orderProducts: order.orderProducts.map((product) => ({
      productId: product.productId,
      qty: product.qty,
      price: product.price,
      name: product.name,
      toppings: product.toppings?.map((topping) => ({
        productId: topping.productId,
        qty: topping.qty,
        price: topping.price,
        name: topping.name,
      })),
    })),
  };
};
