export const geocodeByAddress = (google, address) => {
  const geocoder = new google.maps.Geocoder();
  const OK = google.maps.GeocoderStatus.OK;

  return new Promise((resolve, reject) => {
    geocoder.geocode({ address }, (results, status) => {
      if (status !== OK) {
        return reject(status);
      }
      resolve(results);
    });
  });
};

export const geocodeByLatLng = (google, location) => {
  const geocoder = new google.maps.Geocoder();
  const OK = google.maps.GeocoderStatus.OK;

  return new Promise((resolve, reject) => {
    geocoder.geocode({ location }, (results, status) => {
      if (status !== OK) {
        return reject(status);
      }
      resolve(results);
    });
  });
};

export const geocodeByPlaceId = (google, placeId) => {
  const geocoder = new google.maps.Geocoder();
  const OK = google.maps.GeocoderStatus.OK;

  return new Promise((resolve, reject) => {
    geocoder.geocode({ placeId }, (results, status) => {
      if (status !== OK) {
        return reject(status);
      }
      resolve(results);
    });
  });
};

export const setupAutoSuggest = (google, suggestInputRef, onPlaceSelected) => {
  const autoSuggest = new google.maps.places.Autocomplete(suggestInputRef.current);
  autoSuggest.setFields(['place_id', 'geometry', 'formatted_address', 'name']);
  autoSuggest.addListener('place_changed', () => {
    const place = autoSuggest.getPlace();
    onPlaceSelected(place);
  });
};

export const getCurrentLocationByIp = (apiKey) => {
  return fetch(`https://www.googleapis.com/geolocation/v1/geolocate?key=${apiKey}`, {
    method: 'POST',
  })
    .then((res) => res.json())
    .then((pos) => ({
      lat: () => pos.location.lat,
      lng: () => pos.location.lng,
    }));
};

export const getCurrentLocation = (apiKey) => {
  return new Promise((resolve, reject) => {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          resolve({
            lat: () => pos.coords.latitude,
            lng: () => pos.coords.longitude,
          });
        },
        () => {
          getCurrentLocationByIp(apiKey).then(resolve).catch(reject);
        },
      );
    } else {
      getCurrentLocationByIp(apiKey).then(resolve).catch(reject);
    }
  });
};

export const getLatLng = (geometry) => {
  return new Promise((resolve, reject) => {
    try {
      const location = geometry.location
        ? geometry.location
        : geometry.latLng
        ? geometry.latLng
        : geometry;

      const latLng = {
        lat: location.lat(),
        lng: location.lng(),
      };
      resolve(latLng);
    } catch (e) {
      reject(e);
    }
  });
};
