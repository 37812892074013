import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import _ from 'lodash';

import { startNewOrder } from 'actions/shoppingActions';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px;
`;
//  height: calc(100vh - 243px);

const ImageContainer = styled.div`
  background: #c4c4c4;
  border-radius: 2px;
  overflow: hidden;
  width: 100%;
  cursor: ${(props) => (props.isEventClickable ? 'pointer' : 'auto')};
`;

const Image = styled.img`
  width: 100%;
  height: calc(100vh - 425px);
  object-fit: contain;
  min-height: 150px;
  background: #ffffff;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #2a2e45;
  margin: 20px 10px 5px;
`;

const Date = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #3185fc;
  margin: 0 10px;
`;

const Description = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: #5c677b;
  margin: 5px 10px;
`;

const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

const PageEventCard = ({ event }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Wrapper key={event.id}>
      <ImageContainer isEventClickable={event.customUrl ? true : false}>
        <Image
          src={event.imagePath}
          alt={'event'}
          onClick={() => {
            if (!_.isEmpty(event.customUrl)) {
              dispatch(startNewOrder());
              if (isValidHttpUrl(event.customUrl)) {
                window.location.href = event.customUrl;
              } else {
                history.push(event.customUrl);
              }
            }
          }}
        />
      </ImageContainer>
      <div>
        <Title>{event.title}</Title>
        {event.date && <Date>{moment(event.date).format('HH:mm DD/MM/YYYY')}</Date>}
        <Description>{event.description}</Description>
      </div>
    </Wrapper>
  );
};

export default PageEventCard;
