import crudTypes from 'config/crudTypes';
import crudReducer from './crudReducer';
import arrayMove from 'array-move';

const crudProductReducer = crudReducer(crudTypes.product);
const initialState = {
  data: [],
  productNameSuggestions: [],
  current: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `UPDATE_PRODUCT_STATUS_OK`:
      return {
        ...state,
        data: state.data.map((p) => {
          if (p.id === action.data.id) {
            return action.data;
          }
          return p;
        }),
      };

    case `ADD_PRODUCT_NAME_SUGGESTIONS`:
      return {
        ...state,
        productNameSuggestions: action.data,
      };

    case 'UPDATE_PRODUCT_ARRANGEMENT': {
      const { categoryId, oldIndex, newIndex } = action.data;

      const updatedCategoryProducts = arrayMove(
        state.data.filter((x) => x.categoryId === categoryId),
        oldIndex,
        newIndex,
      );

      return {
        ...state,
        data: [
          ...state.data.filter((x) => x.categoryId !== categoryId),
          ...updatedCategoryProducts,
        ],
      };
    }

    default:
      return crudProductReducer(state, action);
  }
};
