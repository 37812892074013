import Url from '../config/api';
import { buildAxiosInstance } from 'actions/common';

const { baseUrl } = Url;

const baseURI = `${baseUrl}/Visibility`;

const axiosClient = buildAxiosInstance(baseUrl);

export const getCharacteristics = (setCharacteristics, setStatus = null) => {
  return (dispatch) => {
    setStatus && setStatus('LOADING');
    axiosClient
      .get(`${baseURI}/getCharacteristics`)
      .then((res) => {
        if (res.status === 200) {
          setStatus && setStatus('OK');
          setCharacteristics(res.data);
        } else {
          setStatus && setStatus('ERROR');
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
      });
  };
};

export const getPageCharacteristics = (setStatus = null) => {
  return (dispatch) => {
    setStatus && setStatus('LOADING');
    axiosClient
      .get(`${baseURI}/GetPageCharacteristics/${localStorage.getItem('Page')}`)
      .then((res) => {
        if (res.status === 200) {
          setStatus && setStatus('OK');
          dispatch({ type: 'SET_PAGE_CHARACTERISTICS', data: res.data });
        } else {
          setStatus && setStatus('ERROR');
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
      });
  };
};

export const setPageCharacteristics = (characteristics, setStatus = null) => {
  return (dispatch) => {
    axiosClient
      .post(`${baseURI}/SetPageCharacteristics`, {
        pageId: localStorage.getItem('Page'),
        characteristicsIds: [...characteristics],
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(getPageCharacteristics());
          setStatus && setStatus('OK');
        } else {
          setStatus && setStatus('ERROR');
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
      });
  };
};

export const setPageSocialLinks = (links, setStatus = null) => {
  return (dispatch) => {
    setStatus && setStatus('LOADING');
    axiosClient
      .put(`${baseUrl}/Pages/UpdateSocialLinks/${localStorage.getItem('Page')}`, { ...links })
      .then((res) => {
        if (res.status === 200) {
          setStatus && setStatus('OK');
          dispatch({ type: 'SET_PAGE_SOCIAL_LINKS', data: { ...links } });
        } else {
          setStatus && setStatus('ERROR');
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
      });
  };
};

export const savePageEvent = (pageEvent, done = null) => {
  return (dispatch) => {
    (!pageEvent.id
      ? axiosClient.post(`${baseURI}/createPageEvent`, { ...pageEvent })
      : axiosClient.put(`${baseURI}/UpdatePageEvent/${pageEvent.id}`, { ...pageEvent })
    )
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: 'SET_PAGE_EVENT', data: res.data.result || res.data });
          done && done(null, res.data.result || res.data);
        } else {
          done && done('ERROR');
        }
      })
      .catch(() => {
        done && done('ERROR');
      });
  };
};

export const playPausePageEvent = (id, setStatus = null) => {
  return (dispatch) => {
    axiosClient
      .put(`${baseURI}/UpdatePageEventStatus/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setStatus && setStatus('OK');
          dispatch({ type: 'TOGGLE_PAGE_EVENT', data: res.data });
        } else {
          setStatus && setStatus('ERROR');
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
      });
  };
};
