const initialState = {
  isLoading: false,
  data: [],
  currentPage: 1,
  pageCount: 0,
};


export default (state = initialState, action) => {
  switch(action.type) {
    case 'GET_ALL_USERS_OK': return  {
      data: action.data.results,
      currentPage: action.data.currentPage,
      pageCount: action.data.pageCount,
    };

    case 'LOCK_USER_OK': return {
      data: state.data.map(u => {
        if (u.id === action.data.userId) {
          return {
            ...u,
            lockoutEnabled: true,
          };
        }
        return u;
      })
    };

    case 'UNLOCK_USER_OK': return {
      data: state.data.map(u => {
        if (u.id === action.data.userId) {
          return {
            ...u,
            lockoutEnabled: false,
          };
        }
        return u;
      })
    };

    default:
      return state;
  }

};
