import React from 'react';
import styled from 'styled-components';
import Icons from '../../shared/views/icons';
import colors from '../../shared/helpers/colors';
import transition from '../../shared/helpers/transition';
import hexToRGBA from '../../shared/helpers/hexToRGBA';

const HelpCenterButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const ButtonWithIconWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: white; /*${(props) => hexToRGBA(props.color, 0.1) || ''}; */
  user-select: none;
  transition: ${transition};
  font-size: 16px;
  box-shadow: 0px 12px 16px rgba(200, 208, 216, 0.3);

  :hover {
    cursor: pointer;
    background-color: ${(props) => hexToRGBA(props.color, 0.3) || ''};
  }

  width: 40px;
  height: 62px;
  padding: 20px 7px;
`;

const HelpCenterBaseButton = ({ text, color, ...rest }) => (
  <ButtonWithIconWrapper color={color} {...rest}>
    <Icons.HelpCenter />
    <span style={{ color }}>{text}</span>
  </ButtonWithIconWrapper>
);
const HelpCenterButton = ({ refer, counter, ...props }) => (
  <HelpCenterButtonWrapper>
    <HelpCenterBaseButton color={colors.blue} {...props} />
  </HelpCenterButtonWrapper>
);

export default HelpCenterButton;
