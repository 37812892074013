export default input => {
  const inputWithoutSpaces = input.replace(/\D/g, '');
  const arrayInput = inputWithoutSpaces.split('');
  const inputMaks = arrayInput.reduce(
    (accumulator, currentValue) => {
      if (accumulator.count > 15) return accumulator;

      return {
        count: accumulator.count + 1,
        inputMask: `${accumulator.inputMask}${
          accumulator.count > 0 && accumulator.count % 4 === 0 ? ' ' : ''
        }${currentValue}`,
      };
    },
    { count: 0, inputMask: '' },
  );
  return inputMaks.inputMask;
};
