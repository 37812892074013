import React from 'react';
import styled from 'styled-components';

import colors from 'app/shared/helpers/colors';

const SettingsTitle = styled.div`
  font-size: 16px;
  color: ${colors.blue};
`;

const SettingsTitleWrapper = styled.div`
  margin-top: 2%;
  margin-bottom: 24px;
  font-weight: 500;
  width: 50%;
`;

export default ({ title, ...rest }) => (
  <SettingsTitleWrapper {...rest}>
    <SettingsTitle>{title}</SettingsTitle>
  </SettingsTitleWrapper>
);
