import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import shared from 'app/shared/';
import { paginate } from 'config/helpers/paginator';
import moment from 'moment';
import SettingsTitle from '../components/SettingsTitle';
import { scrollToAlert } from 'app/shared/views/alert';

import {
  addSubscription,
  pauseSubscription,
  reactivateLastSubscription,
  getSubscriptionInvoices,
  getActivePageSubscription,
} from 'actions/subscriptionActions';

const {
  views: {
    Toggle,
    Button,
    Table,
    Alert,
    Spinner,
    Icons,
    Paginator,
    FilterableTableStructure: { PaginatorWrapper },
  },
  helpers: { colors },
} = shared;

const SubscribeSettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.7% 30px;
`;

const SettingsHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;
const PaddleLinkWrapper = styled.a`
  margin-right: 30px;
  text-decoration: underline;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  display: inline-flex;
  align-items: center;
  color: ${(props) => props.color || colors.blue};
`;

const PaddleLink = ({ Icon, ...props }) => {
  return (
    <PaddleLinkWrapper {...props}>
      <Icon fill={props.color} style={{ marginRight: '7px' }} />
      {props.children}
    </PaddleLinkWrapper>
  );
};

const SettingsLabelWrapper = styled.div`
  display: flex;
  margin-bottom: 40px;
  flex: 1;
`;

const SettingAlert = styled(Alert)`
  width: 100%;
  margin-bottom: 30px;
  :hover {
    cursor: pointer;
  }
`;

const SubscriptionAlertMessage = styled.span`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 1px;
`;

const SubscriptionAlert = React.forwardRef(({ subscription, alert: fallbackAlert }, ref) => {
  if (subscription.willCancel) {
    const msg = (
      <SubscriptionAlertMessage>
        Your subscription was permanently cancelled on{' '}
        <strong>{moment.utc(subscription.canceledAt).format('DD.MM.YYYY')}</strong>. Effective
        Cancellation date:{' '}
        <strong>{moment.utc(subscription.canceledFrom).format('DD.MM.YYYY')}</strong>!
      </SubscriptionAlertMessage>
    );
    return <SettingAlert ref={ref} show={true} type={'serious_warning'} message={msg} />;
  } else if (subscription.willPause) {
    const msg = (
      <SubscriptionAlertMessage>
        Your subscription was paused on{' '}
        <strong>{moment.utc(subscription.pausedAt).format('DD.MM.YYYY')}</strong>. It will remain
        active until <strong>{moment.utc(subscription.pausedFrom).format('DD.MM.YYYY')}</strong>!
      </SubscriptionAlertMessage>
    );
    return <SettingAlert ref={ref} show={true} type={'warning'} message={msg} />;
  } else if (alert) {
    return <SettingAlert ref={ref} show={fallbackAlert.show} message={fallbackAlert.message} />;
  }
  return null;
});

const SettingsLabel = styled.div`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  color: ${colors.textColor};
  min-width: 195px;
`;

const SettingLabelValue = styled.div`
  color: ${colors.blue};
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;
`;

const TableWrapper = styled.div`
  width: fill-available;

  & thead th {
    padding-left: unset;
    padding-right: unset;
  }

  & tbody td {
    padding-left: unset;
    padding-right: unset;
  }
`;

const StatusValue = styled.p`
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) => (props.status === 'Paid' ? colors.green : colors.red)};
`;

const tableData = {
  header: [
    {
      label: 'ID',
      key: 'invoiceId',
      sortable: false,
    },
    {
      label: 'Date',
      key: 'paymentDate',
      sortable: true,
      renderer: ({ paymentDate }) => moment(paymentDate).format('YYYY-MM-DD'),
    },
    {
      label: 'Value',
      key: 'amount',
      sortable: true,
      renderer: ({ amount }) => `$${amount}`,
    },
    {
      label: 'Status',
      key: 'status',
      renderer: ({ status }) => <StatusValue status={status}>{status}</StatusValue>,
      sortable: false,
    },
    {
      label: 'ACTION',
      width: '70px',
      key: 'action',
      sortable: false,
      renderer: ({ onView }) => {
        return (
          <Button.Button
            style={{ fontWeight: 600 }}
            text="View"
            size={Button.ButtonSize.medium}
            color={colors.blue}
            onClick={onView}
          />
        );
      },
    },
  ],
};

const SubscribeSettings = ({
  user,
  activePage,
  subscription,
  getSubscriptionInvoices,
  reactivateLastSubscription,
  pauseSubscription,
  setExecuteOnSubmit,
  getActivePageSubscription,
}) => {
  const [toggle, setToggle] = useState(subscription && !subscription.willPause);
  const [sort, setSort] = useState({ key: 'paymentDate', order: 1 });
  const [invoices, setInvoices] = useState([]);
  const [openInvoicePerview, setOpenInvoicePreview] = useState('');
  const [alert, setAlert] = useState({ show: false, message: '' });
  const alertRef = useRef(alert);
  const [invoicesStatus, setInvoicesStatus] = useState('IDLE');
  getSubscriptionInvoices = useCallback(getSubscriptionInvoices, []);

  useEffect(() => {
    if (subscription != null && !subscription.willPause) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  }, [subscription]);

  useEffect(
    () => {
      if (sort) {
        const data = sortInvoicesBy([...invoices], sort.key, sort.order);
        setInvoices(data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sort],
  );

  useEffect(
    () => {
      if (activePage && activePage.id) {
        setInvoicesStatus('LOADING');
        getSubscriptionInvoices(activePage.id, (err, data) => {
          if (err) {
            setInvoicesStatus('ERROR');
            console.log(err);
            return;
          }
          setInvoicesStatus('OK');
          data.forEach((item) => {
            item.onView = () => {
              setOpenInvoicePreview(item.pdfUrl);
            };
          });

          data = sortInvoicesBy([...data], sort.key, sort.order);
          setInvoices(data);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getSubscriptionInvoices, activePage],
  );

  useEffect(() => {
    if (openInvoicePerview) {
      window.open(openInvoicePerview, '_blank');
      setOpenInvoicePreview('');
    }
  }, [openInvoicePerview]);

  const handleToggleChange = () => {
    if (!activePage || !activePage.id) return;

    const newToggle = !toggle;
    setToggle(newToggle);

    if (newToggle === (subscription !== null && !subscription.willPause)) {
      setExecuteOnSubmit(null);
    } else {
      const done = (message, err) => {
        getActivePageSubscription(activePage.id);
        if (err) {
          setToggle(!newToggle);
          setExecuteOnSubmit(null);
        } else {
          if (message) {
            setAlert({
              show: true,
              message,
            });
            scrollToAlert(alertRef);
          }
        }
      };

      if (newToggle) {
        // re-activate
        setExecuteOnSubmit(() => () => {
          reactivateLastSubscription(
            activePage.id,
            done.bind(null, 'You have successfully re-activated your subscription'),
          );
        });
      } else {
        // cancel
        setExecuteOnSubmit(() => () => {
          pauseSubscription(
            activePage.id,
            done.bind(
              null,
              null, //'You have paused your subscription, however it will remain active till the end of your current plan.'
            ),
          );
        });
      }
    }
  };

  const dataTableSort = (key) => {
    setSort((prevSort) => ({
      key,
      order: -1 * prevSort.order,
    }));
  };

  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line
  const [pageSize, setPageSize] = useState(5);
  const paginatedData = paginate(invoices, currentPage, pageSize);

  if (!subscription) return null;
  return (
    <SubscribeSettingsWrapper>
      <SettingsTitle title="Subscription Details" />
      <SettingsHeaderWrapper>
        <div>
          {!subscription.willCancel && subscription.updateUrl && (
            <PaddleLink
              Icon={Icons.CreditCard}
              color={colors.blue}
              onClick={() => {
                window.Paddle.Checkout.open({
                  override: subscription.updateUrl,
                  successCallback: () => {
                    setTimeout(() => {
                      window.location.href = '/dashboard';
                    }, 3000);
                  },
                });
              }}
            >
              Update Payment Info
            </PaddleLink>
          )}
          {!subscription.willCancel && subscription.cancelUrl && (
            <PaddleLink
              Icon={Icons.StopCircle}
              color={colors.red}
              onClick={() => {
                window.Paddle.Checkout.open({
                  override: subscription.cancelUrl,
                  successCallback: () => {
                    setTimeout(() => {
                      window.location.href = '/dashboard';
                    }, 3000);
                  },
                });
              }}
            >
              Cancel Subscription
            </PaddleLink>
          )}
        </div>
      </SettingsHeaderWrapper>
      <SubscriptionAlert ref={alertRef} subscription={subscription} alert={alert} />

      <SettingsLabelWrapper>
        <SettingsLabel>Member Since:</SettingsLabel>
        <SettingLabelValue>
          {moment(subscription.memberSince).format('MMMM DD, YYYY')}
        </SettingLabelValue>
      </SettingsLabelWrapper>

      {subscription && subscription.isTrialSubscription ? (
        <SettingsLabelWrapper>
          <SettingsLabel>
            Subscription
            <Icons.Info
              width={'10px'}
              height={'10px'}
              fill={colors.lightGrey}
              style={{ marginLeft: '10px' }}
            />
          </SettingsLabel>
          <SettingLabelValue style={{ color: colors.green }}>
            {subscription.planName}
          </SettingLabelValue>
        </SettingsLabelWrapper>
      ) : null}
      {subscription && !subscription.isTrialSubscription ? (
        <SettingsLabelWrapper>
          <SettingsLabel>
            Automatic Subscription
            <Icons.Info
              width={'10px'}
              height={'10px'}
              fill={colors.lightGrey}
              style={{ marginLeft: '10px' }}
            />
          </SettingsLabel>
          {!subscription.willCancel ? (
            <ActiveSubscriptionToggle isActive={toggle} onClick={handleToggleChange} />
          ) : (
            <ActiveSubscriptionToggle isActive={false} />
          )}
        </SettingsLabelWrapper>
      ) : null}
      {subscription && !subscription.isTrialSubscription ? (
        <SettingsLabelWrapper>
          <SettingsLabel>Subscription History</SettingsLabel>
          {invoicesStatus === 'LOADING' ? (
            <Spinner size={80} style={{ height: '100px' }} />
          ) : (
            <TableWrapper>
              <Table.TableView
                data={{
                  ...tableData,
                  values: paginatedData.current,
                  action: dataTableSort,
                }}
              />
              <PaginatorWrapper>
                <Paginator
                  changePage={setCurrentPage}
                  numberOfPages={paginatedData.pages}
                  page={paginatedData.currentPage}
                />
              </PaginatorWrapper>
            </TableWrapper>
          )}
        </SettingsLabelWrapper>
      ) : null}
    </SubscribeSettingsWrapper>
  );
};

const ActiveSubscriptionToggle = ({ isActive, onClick, ...props }) => (
  <Toggle
    show={!isActive}
    onClick={onClick}
    color={isActive ? colors.blue : colors.darkGrey}
    {...props}
  />
);

const sortInvoicesBy = (invoices, key, order) => {
  switch (key) {
    case 'paymentDate': {
      invoices.sort((a, b) => {
        return order * moment(b.paymentDate).diff(a.paymentDate);
      });
      return invoices;
    }

    case 'amount': {
      invoices.sort((a, b) => {
        return order * (b.amount - a.amount);
      });
      return invoices;
    }

    default:
      return invoices;
  }
};

const mapStateToProps = (state) => ({
  user: state.userDetails.user,
  activePage: state.facebookPages.activePage,
  subscription: state.subscriptions.activePageSubscription,
});

export default connect(mapStateToProps, {
  addSubscription,
  pauseSubscription,
  getSubscriptionInvoices,
  reactivateLastSubscription,
  getActivePageSubscription,
})(SubscribeSettings);
