import React from 'react';
import styled from 'styled-components';
import colors from '../../helpers/colors';

export const CartesianContext = React.createContext({
  xAxis: 0,
  yAxis: 0,
});

const XAxisText = styled.div`
  position: absolute;
  bottom: -52px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const XAxisTextElement = styled.span`
  color: ${colors.textColor};
  font-size: 14px;
  line-height: 21px;
  flex: 1;
`;

const YAxisText = styled.div`
  position: absolute;
  top: -11px;
  left: -30px;
  width: 24px;
  text-align: right;
  height: calc(100% + 21px);
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
`;

const YAxisTextElement = styled.span`
  color: ${colors.textColor};
  display: inline-block;
  font-size: 14px;
  line-height: 21px;
  margin-right: 6px;
`;

const LineChartWrapper = styled.div`
  position: relative;
  margin-bottom: 15px;
  z-index: 10;
`;

const LineChart = ({ children, xAxis = [], yAxis = [], height = 57, width = 200, ...rest }) => (
  <LineChartWrapper style={{ height, width }} {...rest}>
    <XAxisText>
      {xAxis.map((element, index) => (
        <XAxisTextElement key={`${element}-${index}`}>{element}</XAxisTextElement>
      ))}
    </XAxisText>
    <YAxisText>
      {yAxis.map((element, index) => (
        <YAxisTextElement key={`${element}-${index}`}>{element}</YAxisTextElement>
      ))}
    </YAxisText>
    <CartesianContext.Provider
      value={{
        xAxis: xAxis.length,
        yAxis: yAxis.length,
      }}
    >
      {children}
    </CartesianContext.Provider>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
      }}
    >
      {xAxis.map((element, index) => (
        <div key={`${element}-${index}`} style={{ flex: 1 }} />
      ))}
    </div>
  </LineChartWrapper>
);

export default LineChart;
