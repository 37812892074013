import React from 'react';
import colors from 'app/shared/helpers/colors';
import ReactLoading from 'react-loading';

export default (props) => {
  const { size = 80, color = colors.blue } = props;
  return (
    <div style={({ ...props.style }, { display: 'flex', height: '100%', width: '100%' })}>
      <div style={{ margin: 'auto' }}>
        <ReactLoading type={'bubbles'} color={color} height={size} width={size} />
      </div>
    </div>
  );
};
