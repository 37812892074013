import React from 'react';
import styled from 'styled-components';
import colors from '../helpers/colors';
import Icons from './icons';
import RadioGroup from './radioGroup';
import hexToRGBA from '../helpers/hexToRGBA';
import transition from '../helpers/transition';

const FilterBarWrapper = styled.div`
  height: 42px;
  border-bottom: 2px solid ${colors.blue};
  display: flex;
  justify-content: space-between;

  @media all and (max-width: 768px) { 
    display: none;
  }
`;

const FilterBar = ({ className, children }) => <FilterBarWrapper className={className}>{children}</FilterBarWrapper>;

FilterBar.Start = styled.div`
  padding-left: 20px;
  flex: 1;
`;

const EndWrapper = styled.div`
  padding-right: 8px;
`;

FilterBar.End = ({ children }) => <EndWrapper>{children}</EndWrapper>;

FilterBar.Components = {};

const ClearFiltersWrapper = styled.span`
  color: ${colors.textColor};

  :hover {
    cursor: pointer;
  }

  path {
    fill: ${colors.textColor};
  }
`;

const SpanWrapper = styled.span`
  @media all and (min-width: 768px) and (max-width: 1024px) { 
    display: none;
  }

`;

const undoIconStyle = {
  marginRight: '8px',
  width: 14,
  verticalAlign: 'sub'
}

FilterBar.Components.ClearFilters = props => (
  <ClearFiltersWrapper {...props}>
    <Icons.Undo style={undoIconStyle} />
      <SpanWrapper>
        Clear Filters
      </SpanWrapper>
  </ClearFiltersWrapper>
);

FilterBar.Components.RadioGroup = ({ updateSelectedElement, selectedElement, children }) => (
  <div>
    <RadioGroup updateSelectedElement={updateSelectedElement} selectedElement={selectedElement}>
      {children}
    </RadioGroup>
  </div>
);


const RadioElementPresentation = styled.span`
  font-size: 13px;
  display: inline-block;
  line-height: 22px;
  color: ${props => (props.isSelected ? colors.blue : colors.darkestGrey)};
  padding: 0 7px;
  margin-right: 6px;
  border-radius: 5px;
  background-color: ${props => (props.isSelected ? hexToRGBA(colors.blue, 0.1) : 'transparent')};
  transition: ${transition};

  :hover {
    cursor: pointer;
    color: ${colors.blue};
    background-color: ${hexToRGBA(colors.blue, 0.05)};
  }
`;

const RadioElement = ({ updateSelectedElement, isSelected, children }) => (
  <RadioElementPresentation isSelected={isSelected} onClick={updateSelectedElement}>
    {children}
  </RadioElementPresentation>
);

FilterBar.Components.RadioElement = ({ children, value, ...rest }) => (
  <RadioGroup.Element value={value} Element={RadioElement} {...rest}>
    {children}
  </RadioGroup.Element>
);

FilterBar.Components.Title = styled.span`
  font-size: 13px;
  line-height: 19px;
  color: ${colors.textColor};
`;


export default FilterBar;
