import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import shared from 'app/shared';
import text from '../static/texts';
import components from '../components';

const { title, subtitle, link, left, right } = text;

const {
  views: { Button, Input, AddItemPopup },
  helpers: { colors },
} = shared;

const {
  views: {
    Button: { SidedButton },
    Text: { MainExternalLink },
  },
} = components;

const {
  views: { MainView },
} = shared;

const leftStyle = {
  color: colors.veryDarkGrey,
  border: `1px solid ${colors.veryDarkGrey}`,
  background: 'white',
};

const NavButtonWrapper = styled.div`
  width: 100%;
  margin: 5% 0;
  display: flex;
`;

const StripeConfig = () => {
  return (
    <Formik
      initialValues={{
        publishableKey: 'test3',
        privateKey: 'test2',
      }}
      validationSchema={Yup.object().shape({
        publishableKey: Yup.string().required('Publishable key is required'),
        privateKey: Yup.string().required('Private key is required'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 500);
      }}
    >
      {props => {
        const { values, touched, errors, handleChange, handleSubmit } = props;
        return (
          <div>
            <MainView title={title} subtitle={subtitle}>
              <AddItemPopup.InputRow>
                <Input
                  labelText="Pubishable key"
                  value={values.publishableKey}
                  onChange={handleChange}
                  id="publishableKey"
                />
                {errors.publishableKey && touched.publishableKey ? (
                  <div>{errors.publishableKey}</div>
                ) : null}
              </AddItemPopup.InputRow>
              <AddItemPopup.InputRow>
                <Input
                  labelText="Private key"
                  value={values.privateKey}
                  onChange={handleChange}
                  id="privateKey"
                />
                {errors.privateKey && touched.privateKey ? <div>{errors.privateKey}</div> : null}
              </AddItemPopup.InputRow>
              <AddItemPopup.InputRow style={{textAlign: "center"}}>
                <Button.Button color={colors.green} text="Test" size={Button.ButtonSize.extraMedium} onClick={() => handleSubmit()} />
              </AddItemPopup.InputRow>
              <MainExternalLink>{link}</MainExternalLink>
              <NavButtonWrapper>
                <SidedButton text={left} innerStyle={leftStyle} />
                <SidedButton text={right} />
              </NavButtonWrapper>
            </MainView>
          </div>
        );
      }}
    </Formik>
  );
};

StripeConfig.path = '/stripe';

export default StripeConfig;
