import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import NotificationList from './notificationList';
import colors from 'app/shared/helpers/colors';
import Icons from 'app/shared/views/icons';
import Text from 'app/shared/views/text';

const {
  Link: { ExternalLink },
} = Text;

const NotificationDropdownBox = styled.div`
  background: #ffffff;
  border-radius: 5px;
  font-size: 14px;
  min-width: 250px;
  /* height: 250px; */
  z-index: 2;
  position: absolute;
  display: ${(props) => (props.show ? 'block' : 'none')};
  transition: 0.15s ease-in-out;
  margin-top: 10px;
  right: 219px;
  box-shadow: 0px 4px 7px rgba(39, 43, 52, 0.07);
`;

const DropdownHeader = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  width: 100%;
  color: ${colors.blue};
  border-bottom: 1px solid #e9eaf1;

  display: flex;
  align-items: center;
  text-indent: 20px;
`;

const DropdownFooter = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  width: 100%;
  color: ${colors.blue};
  border-top: 1px solid #e9eaf1;

  display: flex;
  align-items: center;
  text-indent: 20px;
  justify-content: space-between;
`;

const NotificationDropdown = ({ refer, show, data, markAsRead, markAllRead, toggle }) => {
  return (
    <NotificationDropdownBox ref={refer} show={show}>
      <DropdownHeader>
        <span>Recent Notifications</span>
      </DropdownHeader>
      <NotificationList data={data} markAsRead={markAsRead} toggle={toggle} />
      <DropdownFooter>
        <NavLink
          onClick={toggle}
          to="/dashboard/notifications"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          See All
          <Icons.Forward
            width={10}
            height={10}
            fill={colors.lightGrey}
            style={{ paddingLeft: '10px' }}
          />
        </NavLink>
        <ExternalLink
          onClick={() => {
            markAllRead();
            toggle();
          }}
          style={{ marginLeft: 0, marginRight: 10, fontSize: 12 }}
        >
          Clear All
        </ExternalLink>
      </DropdownFooter>
    </NotificationDropdownBox>
  );
};

export default NotificationDropdown;
