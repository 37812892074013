import React from 'react';
import styled from 'styled-components';
import { AddButton, RemoveButton } from 'app/shared/views/buttons';
import currencyFormatter from 'app/shared/helpers/currencyFormatter';
import _ from 'lodash';

const Toppings = (props) => {
  const {topping, localOrder, setLocalOrder, selectedProduct} = props;

  const addTopping = (item) => {
    const newLocalOrder = [...localOrder];
    const selectedOrderProduct = newLocalOrder[selectedProduct];
    const selectedTopping = selectedOrderProduct?.toppings?.find(top=>top.productId===item.id);
    if(selectedTopping){
      selectedTopping.qty += 1;
    }else{
      selectedOrderProduct.toppings?.length>0 
      ? selectedOrderProduct.toppings = _.concat(selectedOrderProduct.toppings, {...item, qty: 1, productId: item.id})
      : selectedOrderProduct.toppings = _.concat([], {...item, qty: 1, productId: item.id});
    }
    newLocalOrder[selectedProduct]=selectedOrderProduct;
    setLocalOrder([...newLocalOrder]);
  }

  const removeTopping = (item) => {
    const newLocalOrder = [...localOrder];
    const selectedOrderProduct = newLocalOrder[selectedProduct];
    const selectedTopping = selectedOrderProduct?.toppings?.find(top=>top.productId===item.id);
    if(selectedTopping?.qty>0){
      selectedTopping.qty -= 1;
    }
    newLocalOrder[selectedProduct]=selectedOrderProduct;
    setLocalOrder([...newLocalOrder]);
  }

      return (<OrderProductEntry key={topping?.id}>
        <Flex>
          <Flex1>
            <ProductImage
              alt="logo"
              src={topping?.imagePath}
              height="20"
              width="40"
            />
          </Flex1>
          <OrderProductName>{topping?.name}</OrderProductName>
        </Flex>
        <OrderProductActions style={{ flex: 1 }}>
          <PriceValue style={{justifyContent: 'flex-end' }}>
            {currencyFormatter(topping?.price)}
          </PriceValue>
          <RemoveButton
            onClick={()=>removeTopping(topping)}
            style={{ height: '24px', width: '24px', borderRadius: 9999, backgroundColor: '#E8E9F3' }}
          />
          <OrderProductQuantity>{localOrder[selectedProduct]?.toppings?.find(top=>top?.productId===topping.id)?.qty || 0}</OrderProductQuantity>
          <AddButton
            onClick={() => addTopping(topping)}
            style={{ height: '24px', width: '24px', borderRadius: 9999 }}
          />
        </OrderProductActions>
      </OrderProductEntry>);
}

const OrderProductEntry = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 15px;
  line-height: 17px;
`;

const OrderProductName = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
`;

const OrderProductActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const OrderProductQuantity = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #1D2036;

  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 20px;
`;

const PriceValue = styled.span`
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  min-width: 36px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  color: #2ECC71;
  min-width: 35px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;
const Flex1 = styled.div`
  flex: 1;
`;

const ProductImage = styled.img`
  border-radius: 3px;
  height: 20px;
  width: 40px;
  flex: 1;
  margin-right: 8px;
`;

export default Toppings;
