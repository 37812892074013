import axios from 'axios';
import Url from '../config/api';
import { api } from 'actions/shoppingActions';
const { baseUrl} = Url;

const baseURI = `${baseUrl}/PokCredentials`;

export const changePokSettings = (data, setStatus=null) => {
  return (dispatch) => {
    axios.post(baseURI, data)
      .then(res => {
        if(res.status === 200){
          setStatus && setStatus('OK');
          dispatch({
            type: 'CHANGE_POK_CONF_OK',
            data: res.data
          });
        }
        else{
          setStatus && setStatus('ERROR');
          dispatch({
            type: 'CHANGE_POK_CONF_ERROR'
          })
        }
      })
      .catch(()=>{
        setStatus && setStatus('ERROR');
        dispatch({
          type: 'CHANGE_POK_CONF_ERROR'
        })
      })
  }
}

export const getPokSettings = (setStatus=null) => {
  return dispatch => {
    setStatus && setStatus('LOADING');
    axios.get(baseURI)
      .then(res => {
        if(res.status === 200){
          setStatus && setStatus('OK');
          dispatch({
            type: 'GET_POK_CONF_OK',
            data: res.data
          })
        }
        else{
          setStatus && setStatus('ERROR');
          dispatch({
            type: 'GET_POK_CONF_ERROR'
          })
        }
      })
      .catch(()=>{
        setStatus && setStatus('ERROR');
        dispatch({
          type: 'GET_POK_CONF_ERROR'
        })
      })
  }
}

export const getPokSettingsByPageId = (pageId, setStatus=null) => {
  return dispatch => {
    setStatus && setStatus('LOADING');
    api.get(`${baseURI}/${pageId}`)
      .then(res => {
        if(res.status === 200){
          setStatus && setStatus('OK');
          dispatch({
            type: 'GET_POK_CRED_OK',
            data: res.data
          })
        }
        else{
          setStatus && setStatus('ERROR');
          dispatch({
            type: 'GET_POK_CRED_ERROR'
          })
        }
      })
      .catch(()=>{
        setStatus && setStatus('ERROR');
        dispatch({
          type: 'GET_POK_CONF_ERROR'
        })
      })
  }
}

export const clearPokSettings = (setStatus=null) => {
  return dispatch => {
    setStatus && setStatus('LOADING');
    axios.delete(baseURI)
      .then(res => {
        if(res.status === 200){
          setStatus && setStatus('OK');
          dispatch({
            type: 'REMOVE_POK_CONF_OK',
            data: res.data
          })
        }
        else{
          setStatus && setStatus('ERROR');
          dispatch({
            type: 'GET_POK_CONF_ERROR'
          })
        }
      })
      .catch(()=>{
        setStatus && setStatus('ERROR');
        dispatch({
          type: 'GET_POK_CONF_ERROR'
        })
      })
  }
}
