import React from 'react';
import styled, { keyframes } from 'styled-components';

const glitchWidth = '30px';
const glitchHeight = '23px';

const gapHorizontal = '20px';
const timeAnim = '2.25s';
const blendMode1 = 'none';
const blendMode2 = 'none';
const blendMode3 = 'none';
const blendColor1 = 'transparent';
const blendColor2 = 'transparent';
const blendColor3 = 'transparent';

const firstAnimatin = keyframes`
  0% {
    clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
  }
  10% {
    clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
  }
  20% {
    clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
  }
  30% {
    clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
  }
  40% {
    clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
  }
  50% {
    clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
  }
  60% {
    clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
  }
  70% {
    clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
  }
  80% {
    clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
  }
  90% {
    clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
  }
  100% {
    clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
  }
`;

const secondAnimation = keyframes`
  0% {
    clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
  }
  15% {
    clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
  }
  22% {
    clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
  }
  31% {
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
  }
  45% {
    clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
  }
  51% {
    clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
  }
  63% {
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
  }
  76% {
    clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
  }
  81% {
    clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
  }
  94% {
    clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
  }
  100% {
    clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
  }
`;

const thirdAnimation = keyframes`
  0% {
    clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
  }
  5% {
    clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
  }
  10% {
    clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
  }
  25% {
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
  }
  27% {
    clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
  }
  30% {
    clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
  }
  33% {
    clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
  }
  37% {
    clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
  }
  40% {
    clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
  }
  45% {
    clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
  }
  50% {
    clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
  }
  53% {
    clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
  }
  57% {
    clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
  }
  60% {
    clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
  }
  65% {
    clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
  }
  70% {
    clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
  }
  73% {
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
  }
  80% {
    clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
  }
  100% {
    clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
  }
`;

const fourthAnimation = keyframes`
  0% {
    clip-path: polygon(2% 0, 5% 0, 5% 100%, 2% 100%);
  }
  10% {
    clip-path: polygon(15% 0, 15% 0, 15% 100%, 15% 100%);
  }
  20% {
    clip-path: polygon(10% 0, 20% 0, 20% 100%, 10% 100%);
  }
  30% {
    clip-path: polygon(1% 0, 2% 0, 2% 100%, 1% 100%);
  }
  40% {
    clip-path: polygon(33% 0, 33% 0, 33% 100%, 33% 100%);
  }
  50% {
    clip-path: polygon(44% 0, 44% 0, 44% 100%, 44% 100%);
  }
  60% {
    clip-path: polygon(50% 0, 20% 0, 20% 100%, 50% 100%);
  }
  70% {
    clip-path: polygon(70% 0, 70% 0, 70% 100% 70%, 70% 100%);
  }
  80% {
    clip-path: polygon(80% 0, 80% 0, 80% 100% 80%, 80% 100%);
  }
  90% {
    clip-path: polygon(50% 0, 55% 0, 55% 100%, 50% 100%);
  }
  100% {
    clip-path: polygon(70% 0, 80% 0, 80% 100%, 70% 100%);
  }
`;

const fifthAnimation = keyframes`
  0% {
    clip-path: polygon(25% 0, 30% 0, 30% 100%, 25% 100%);
  }
  15% {
    clip-path: polygon(3% 0, 3% 0, 3% 100%, 3% 100%);
  }
  22% {
    clip-path: polygon(5% 0, 20% 0, 20% 100%, 5% 100%);
  }
  31% {
    clip-path: polygon(20% 0, 20% 0, 20% 100%, 20% 100%);
  }
  45% {
    clip-path: polygon(40% 0, 40% 0, 40% 100%, 40% 100%);
  }
  51% {
    clip-path: polygon(52% 0, 59% 0, 59% 100%, 52% 100%);
  }
  63% {
    clip-path: polygon(60% 0, 60% 0, 60% 100%, 60% 100%);
  }
  76% {
    clip-path: polygon(75% 0, 75% 0, 75% 100%, 75% 100%);
  }
  81% {
    clip-path: polygon(65% 0, 40% 0, 40% 100%, 65% 100%);
  }
  94% {
    clip-path: polygon(45% 0, 50% 0, 50% 100%, 45% 100%);
  }
  100% {
    clip-path: polygon(14% 0, 33% 0, 33% 100%, 14% 100%);
  }
`;

const sixthAnimation = keyframes`
  0% {
    clip-path: polygon(1% 0, 3% 0, 3% 100%, 1% 100%);
  }
  5% {
    clip-path: polygon(10% 0, 9% 0, 9% 100%, 10% 100%);
  }
  10% {
    clip-path: polygon(5% 0, 6% 0 6% 100%, 5% 100%);
  }
  25% {
    clip-path: polygon(20% 0, 20% 0, 20% 100%, 20% 100%);
  }
  27% {
    clip-path: polygon(10% 0, 10% 0, 10% 100%, 10% 100%);
  }
  30% {
    clip-path: polygon(30% 0, 25% 0, 25% 100%, 30% 100%);
  }
  33% {
    clip-path: polygon(15% 0, 16% 0, 16% 100%, 15% 100%);
  }
  37% {
    clip-path: polygon(40% 0, 39% 0, 39% 100%, 40% 100%);
  }
  40% {
    clip-path: polygon(20% 0, 21% 0, 21% 100%, 20% 100%);
  }
  45% {
    clip-path: polygon(60% 0, 55% 0, 55% 100%, 60% 100%);
  }
  50% {
    clip-path: polygon(30% 0, 31% 0, 31% 100%, 30% 100%);
  }
  53% {
    clip-path: polygon(70% 0, 69% 0, 69% 100%, 70% 100%);
  }
  57% {
    clip-path: polygon(40% 0, 41% 0, 41% 100%, 40% 100%);
  }
  60% {
    clip-path: polygon(80% 0, 75% 0, 75% 100%, 80% 100%);
  }
  65% {
    clip-path: polygon(50% 0, 51% 0, 51% 100%, 50% 100%);
  }
  70% {
    clip-path: polygon(90% 0, 90% 0, 90% 100%, 90% 100%);
  }
  73% {
    clip-path: polygon(60% 0, 60% 0, 60% 100%, 60% 100%);
  }
  80% {
    clip-path: polygon(100% 0, 99% 0, 99% 100%, 100% 100%);
  }
  100% {
    clip-path: polygon(70% 0, 71% 0, 71% 100%, 70% 100%);
  }
`;

const GlitchyImageWrapper = styled.div`
  position: relative;
  width: ${glitchWidth};
  max-width: 30px;
  height: ${glitchHeight};
  max-height: 23px;
  overflow: hidden;
  margin: 0 auto;

  :before {
    content: '';
    display: block;
    position: absolute;
    margin-left: 0;
    min-width: 100%;
    min-height: 100%;
    opacity: 0.2;
    background: url(${props => props.image});
  }
`;

const GlitchImgBase = styled.div`
  position: absolute;
  top: 0;
  left: -20px;
  width: 30px;
  height: 23px;
  background: url(${props => props.image}) no-repeat 50% 0;
  background-size: cover;
  transform: translate3d(0, 0, 0);
`;

const FirstGlitchImg = styled(GlitchImgBase)`
  background-color: ${blendColor1});
  background-blend-mode: ${blendMode1});
  transform: translate3d(${gapHorizontal},0,0);
  animation: ${firstAnimatin} ${timeAnim} infinite linear alternate;
`;

const SecondGlitchImg = styled(GlitchImgBase)`
  background-color: ${blendColor2});
  background-blend-mode: ${blendMode2});
  transform: translate3d(${gapHorizontal},0,0);
  animation: ${secondAnimation} ${timeAnim} infinite linear alternate;
`;

const ThirdGlitchImg = styled(GlitchImgBase)`
  background-color: ${blendColor3});
  background-blend-mode: ${blendMode3});
  transform: translate3d(${gapHorizontal},0,0);
  animation: ${thirdAnimation} ${timeAnim} infinite linear alternate;
`;

const FourthGlitchImg = styled(GlitchImgBase)`
  background-color: ${blendColor1});
  background-blend-mode: ${blendMode1});
  transform: translate3d(${gapHorizontal},0,0);
  animation: ${fourthAnimation} ${timeAnim} infinite linear alternate;
`;

const FifthGlitchImg = styled(GlitchImgBase)`
  background-color: ${blendColor2});
  background-blend-mode: ${blendMode2});
  transform: translate3d(${gapHorizontal},0,0);
  animation: ${fifthAnimation} ${timeAnim} infinite linear alternate;
`;

const SixthGlitchImg = styled(GlitchImgBase)`
  background-color: ${blendColor3});
  background-blend-mode: ${blendMode3});
  transform: translate3d(${gapHorizontal},0,0);
  animation: ${sixthAnimation} ${timeAnim} infinite linear alternate;
`;

const GlitchyImage = ({ image, ...rest }) => (
  <GlitchyImageWrapper image={image} {...rest}>
    <FirstGlitchImg image={image} />
    <SecondGlitchImg image={image} />
    <ThirdGlitchImg image={image} />
    <FourthGlitchImg image={image} />
    <FifthGlitchImg image={image} />
    <SixthGlitchImg image={image} />
  </GlitchyImageWrapper>
);

export default GlitchyImage;
