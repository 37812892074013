import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import currencyFormatter from 'app/shared/helpers/currencyFormatter';
import Card from 'app/shared/views/card';

import { AddButton, RemoveButton, GreenButton } from 'app/shared/views/buttons';
import Icons from 'app/shared/views/icons';

import { addProductToOrder, removeProductFromOrder } from 'actions/shoppingActions';

const ComboProd = ({ index, product, removeProduct }) => (
  <ComboProdWrapper>
    <ComboProdName>{`#${index + 1} ${product.name}`}</ComboProdName>
    <ComboTopping>
      <Ellipsis>
        {product.toppings?.map(
          (topping, index) => `${index !== 0 ? ', ' : ' '}${topping.name} (${topping.qty})`,
        )}
      </Ellipsis>
    </ComboTopping>
    <CloseIcon onClick={removeProduct} fill={'red'} width={'12px'} height={'12px'} />
  </ComboProdWrapper>
);

const ProductListItem = (props) => {
  const {
    product,
    productsInCart,
    qty,
    currency,
    promotions,
    addProductToOrder,
    removeProductFromOrder,
    canOrder,
    internalOrder,
  } = props;

  return (
    <ProductCardWrapper>
      <ProductDetails>
        <StyledCard canOrder={canOrder} onClick={() => props.setAddProduct(product)}>
          <StyledProductCard>
            <ProductName>
              {qty > 0 && <ProductOrderCount>{qty}x</ProductOrderCount>}
              <Name>{product.name}</Name>
            </ProductName>
            <ProductDescription>
              <Ellipsis>{product.description}</Ellipsis>
            </ProductDescription>
            <div style={{ display: 'flex' }}>
              <ProductPrice>
                {currencyFormatter(
                  product.discountValidation ? product.discount : product.price,
                  currency,
                )}
              </ProductPrice>
              <ProductPrice
                style={
                  product.discountValidation
                    ? { marginLeft: 20, color: '#748AA1', textDecoration: 'line-through' }
                    : { display: 'none' }
                }
              >
                {currencyFormatter(product.price)}
              </ProductPrice>
            </div>
          </StyledProductCard>
        </StyledCard>
        {canOrder && (
          <ProductActions style={internalOrder ? { display: 'flex' } : { display: 'none' }}>
            {product.isCombo ? (
              <GreenButton
                style={{
                  borderRadius: 12,
                  width: '100%',
                  height: 24,
                  backgroundColor: '#FFD037',
                }}
                text={
                  <span style={{ fontSize: 12, fontWeight: 600 }}>
                    Combo {qty ? `(${qty})` : null}
                  </span>
                }
                onClick={() => props.setAddProduct(product)}
              />
            ) : (
              <>
                <RemoveButton
                  style={{ width: 24, height: 24 }}
                  iconStyle={{ height: 'auto' }}
                  onClick={() => removeProductFromOrder(product, promotions)}
                  disabled={qty === 0}
                />
                <ProductOrderCount>{qty}</ProductOrderCount>
                <AddButton
                  style={{ width: 24, height: 24 }}
                  iconStyle={{ height: 'auto' }}
                  onClick={() => addProductToOrder({ ...product, toppings: [] }, promotions)}
                />
              </>
            )}
          </ProductActions>
        )}
        {product.imagePath && (
          <ProductImage src={product.imagePath} onClick={() => props.setAddProduct(product)} />
        )}
        <div
          style={
            product.discountValidation
              ? { position: 'absolute', right: '30px' }
              : { display: 'none' }
          }
        >
          <Icons.Discount />
        </div>
      </ProductDetails>
      {product.isCombo &&
        productsInCart?.map((comboProduct, index) => (
          <ComboProd
            key={comboProduct.toString() + index}
            index={index}
            product={comboProduct}
            removeProduct={() => {
              removeProductFromOrder(comboProduct, false);
            }}
          />
        ))}
      {/* <Line /> */}
    </ProductCardWrapper>
  );
};

const CloseIcon = styled(Icons.Cross)`
  width: 12px;
  height: 12px;
  :hover {
    cursor: pointer;
  }
`;

const ComboProdWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  align-items: center;
`;

const ComboProdName = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: #5c677b;
  margin-right: 4px;
  min-inline-size: max-content;
`;

const ComboTopping = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: #bfcadb;
  flex: 1;
  margin-right: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProductCardWrapper = styled.div`
  padding: 15px 0;
  margin: 0 0.875rem;
  border-bottom: 1px dashed #a5b4cc;
`;
const ProductDetails = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const StyledProductCard = styled.div`
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: -webkit-transform ease 500ms;
  transition: transform ease 500ms;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: calc(100% - 100px);
  & > * {
    margin: auto 0;
  }
`;

const StyledCard = styled(Card)`
  flex: 1;
  border: none;
  display: flex;
  flex-direction: row;
  max-width: 340px;
  width: 20%;
`;

const ProductImage = styled.div`
  border-radius: 4px;
  min-width: 120px;
  height: 70px;
  background: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const ProductName = styled.div`
  display: flex;
  color: #2a2e45;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  gap: 0.5rem;
`;

const ProductDescription = styled.div`
  color: #bfcadb;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  width: 100%;
`;

const Ellipsis = styled.div`
  display: block;
  white-space: nowrap;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Name = styled.div`
  display: block;
  white-space: nowrap;
  margin: 0 auto 0 0;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProductPrice = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: black;
`;

const ProductActions = styled.div`
  align-items: center;
  justify-content: space-between;
  margin: auto 0;
  min-width: 80px;
  width: 80px;
`;

const ProductOrderCount = styled.span`
  position: relative;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #007aff;
  display: flex;
  align-items: center;
  text-align: center;
`;

// const Line = styled.div`
//   margin-top: 1rem;
//   height: 1px;
//   background: linear-gradient(to right, transparent 50%, #a5b4cc 50%);
//   background-size: 9px 2px, 100% 2px;
// `;

export default connect(() => ({}), {
  addProductToOrder,
  removeProductFromOrder,
})(ProductListItem);
