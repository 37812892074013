import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import shared from 'app/shared';
import SettingsTitle from '../components/SettingsTitle';
import {
  getAvailableAggregatorCategories,
  setPageAggregatorCategories,
  updateAggregatorPicture,
} from 'actions/pageActions';
import CheckBox from 'app/shared/views/checkbox';
import { scrollToAlert } from 'app/shared/views/alert';
import _ from 'lodash';

const {
  views: { Alert, UploadInput },
  helpers: { colors },
} = shared;

const InlineFlexWrapper = styled.div`
  display: inline-flex;
  margin-top: 3%;
  width: 60%;
`;

const MainLabel = styled.div`
  display: flex;
  width: 35%;
  height: 100%;
  padding-right: 30px;
  font-size: 15px;
  color: ${colors.textColor};
`;

const AggregatorCategoryCheckbox = ({ categoryName, checked, onChange }) => (
  <div style={{ width: '100%', marginBottom: '10px' }}>
    <CheckBox text={categoryName} checked={checked} onChange={onChange} />
  </div>
);

const AggregatorSettings = ({ setExecuteOnSubmit, setHasChanges, children }) => {
  const dispatch = useDispatch();

  const activePage = useSelector((state) => state.facebookPages.activePage);

  const [aggregatorCategories, setAggregatorCategories] = useState([]);
  const [selectedAggregatorCategories, setSelectedAggregatorCategories] = useState({});

  const [alert, setAlert] = useState({ show: false, message: '' });
  const alertRef = useRef(null);

  useEffect(() => {
    let alertTimer = setTimeout(() => setAlert({ show: false, message: '' }), 5000);
    return () => {
      clearTimeout(alertTimer);
    };
  }, [alert]);

  useEffect(() => {
    dispatch(
      getAvailableAggregatorCategories((err, data) => {
        if (!err) {
          const initialAggregatorCategories = _.chain(activePage.aggregatorCategories)
            .filter((x) => x.name !== 'All')
            .keyBy('id')
            .mapValues((x) => true)
            .value();
          setSelectedAggregatorCategories(initialAggregatorCategories);
          setAggregatorCategories(data.filter((x) => x.name !== 'All'));
        }
      }),
    );
  }, [activePage.aggregatorCategories, dispatch]);

  useEffect(() => {
    setExecuteOnSubmit(() => () => {
      const aggregatorCategoryIds = _.entries(selectedAggregatorCategories)
        .filter(([id, selected]) => !!selected)
        .map(([id, selected]) => id);
      dispatch(
        setPageAggregatorCategories(activePage.id, aggregatorCategoryIds, (err) => {
          if (!err) {
            setAlert({
              show: true,
              message: 'You have successfully changed your aggregator settings!',
              type: 'success',
            });
            setHasChanges(false);
          } else {
            setAlert({
              show: true,
              message: `Could not change your aggregator settings: ${err}`,
              type: 'error',
            });
          }
          scrollToAlert(alertRef);
        }),
      );
    });
  }, [setExecuteOnSubmit, activePage.id, dispatch, selectedAggregatorCategories, setHasChanges]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '0.7% 30px',
        paddingBottom: '30px',
      }}
    >
      <SettingsTitle title="Aggregator Settings" />
      <Alert ref={alertRef} {...alert} />

      <InlineFlexWrapper style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
        {/* <SettingsTitle label="Upload Header Image" style={{ display: 'flex', width: '100%' }} /> */}
        <MainLabel>Upload Aggregator Picture</MainLabel>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <span
            style={{
              width: '35%',
              fontSize: '12px',
              lineHeight: '22px',
              color: '#748AA1',
              paddingRight: '30px',
            }}
          >
            Image format (jpeg, png). File size (max. 2mb). Prefered Aspect Ratio (7:5)
          </span>
          <UploadInput
            uploadOriginal
            height={100}
            imagePath={activePage && activePage.aggregatorPictureURL}
            uriGenerator={(uri) => {
              dispatch(
                updateAggregatorPicture(uri, (status) => {
                  if (status === 'OK') {
                    setAlert({
                      show: true,
                      message: 'You have successfully changed your aggregator picture!',
                      type: 'success',
                    });
                    setHasChanges(false);
                  } else {
                    setAlert({
                      show: true,
                      message: `Could not change your aggregator picture!`,
                      type: 'error',
                    });
                  }
                }),
              );
            }}
            style={{
              width: '142px',
              height: '102px',
              cursor: 'pointer',
            }}
          />
        </div>
      </InlineFlexWrapper>

      <InlineFlexWrapper>
        <MainLabel>Specify which aggregator categories your restaurant may belong to</MainLabel>
        <div style={{ width: '65%', marginTop: '-2px' }}>
          {aggregatorCategories.map((cat) => (
            <AggregatorCategoryCheckbox
              categoryName={cat.name}
              checked={selectedAggregatorCategories[cat.id]}
              onChange={(e) =>
                setSelectedAggregatorCategories({
                  ...selectedAggregatorCategories,
                  [cat.id]: e.target.checked,
                })
              }
            />
          ))}
        </div>
      </InlineFlexWrapper>
      <InlineFlexWrapper>
        <MainLabel />
        {children}
      </InlineFlexWrapper>
    </div>
  );
};

export default AggregatorSettings;
