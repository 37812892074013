import React from 'react';
import styled from 'styled-components';
import { RemoveAllButton } from 'app/shared/views/buttons';

const Badge = styled.div`
  display: flex;
  padding: 5px 15px;

  height: 30px;
  background: ${(props) => (props.isActive ? '#3185FC' : '#F9FAFC')};
  border-radius: 15px;
  color: ${(props) => (props.isActive ? '#fff' : '#000')};

  align-items: center;
  padding-right: 5px;
`;

const Name = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;

  flex: 1;
  margin-right: 8px;
`;

const ProductWrapper = styled.div``;

const Product = ({ name, remove, isSelected, ...props }) => {
  return (
    <ProductWrapper>
      <Badge {...props} isActive={isSelected}>
        <Name>{name}</Name>
        <RemoveAllButton
          onClick={remove}
          style={{
            backgroundColor: isSelected ? '#FF3A44' : '#E8E9F3',
            fill: isSelected ? '#fff' : '#2A2E45',
          }}
        />
      </Badge>
    </ProductWrapper>
  );
};

export default Product;
