import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderDescription } from 'actions/shoppingActions';
import styled from 'styled-components';
import Input from 'app/shared/views/input';
import Drawer from 'react-drag-drawer';
import Icons from 'app/shared/views/icons';
import { BlackButton } from 'app/shared/views/buttons';

const OrderDetailsInput = styled(Input.InputArea)`
  background-color: #f9fafc;
  border-radius: 10px;
  font-weight: normal;
  font-size: 14px;
  color: #5c677b;
  min-height: 65px;
  border: 1px solid black;
  margin: 15px 10px;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 90%;
  ::placeholder {
    color: black;
  }
`;

const HeaderCol = styled.div`
  z-index: 999 !important;
  position: sticky !important;
  top: 0 !important;
  background-color: #ffffff !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  align-items: center !important;
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 26px;
  color: #2a2e45;
  flex: 1;
  padding: 16px !important;
`;

const Body = styled.div`
  height: calc(
    var(--vh, 1vh) * 100 - ${({ headerHeight }) => (headerHeight ? headerHeight : 66)}px
  );
  display: flex;
  flex-direction: column;
`;

const InnerRowWrapper = styled.div`
  display: flex !important;
  flex-direction: column !important;
  background-color: #ffffff !important;
  position: relative !important;
  z-index: 999 !important;
  bottom: 0 !important;
  width: 100vw !important;
  max-width: 500px !important;
  margin-top: auto !important;
  height: 350px !important;
  overflow: hidden !important;
  opacity: ${({ isClosing }) => (isClosing ? 0.5 : 1)};
  border-radius: 20px 20px 0 0;
`;

const ButtonText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: center;
  width: 100%;
`;

const ButtonInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
`;

const stopEventBubbling = (e) => {
  e.stopPropagation();
};

const OrderDescription = (props) => {
  const { show, setShow } = props;

  const childRef = useRef(null);
  const headerRef = useRef(null);
  const dispatch = useDispatch();
  const order = useSelector((state) => state.shopping.order);
  const [error, setError] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(66);

  useEffect(() => {
    if (!headerRef?.current) return;
    setHeaderHeight(headerRef?.current.clientHeight);
  }, []);

  useEffect(() => {
    if (order.description?.length > 125) {
      setError({
        message: 'Max 125 characters allowed in Order Details!',
      });
    } else {
      setError(false);
    }
  }, [order.description]);

  useEffect(() => {
    let errorTimer = setTimeout(() => setError(''), 5000);
    return () => {
      clearTimeout(errorTimer);
    };
  }, [error]);

  useEffect(() => {
    if (!childRef.current) return;
    childRef.current.ontouchstart = stopEventBubbling;
    childRef.current.ontouchmove = stopEventBubbling;
    childRef.current.ontouchend = stopEventBubbling;
  }, [show, childRef]);

  return (
    <Drawer
      containerElementClass={'drawerContainerElementClass'}
      modalElementClass={'drawerModalElementClass'}
      open={show}
      onRequestClose={() => {
        setShow(false);
      }}
      onDrag={({ newPosition }) => setIsClosing(newPosition > 75)}
      getContainer={() => document.getElementById('modal-container')}
    >
      <InnerRowWrapper isClosing={isClosing}>
        <HeaderCol ref={headerRef}>
          <div
            style={{ position: 'relative', left: '43%', top: '10px', cursor: 'pointer' }}
            onClick={() => setShow(false)}
          >
            <Icons.CheckIcon />
          </div>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Header>Add Message</Header>
            <div>
              <h3 style={{ paddingBottom: '5px', marginLeft: '18px' }}>
                Special request, allergies?
              </h3>
              <p style={{ fontSize: '14px', marginLeft: '18px' }}>
                Attach a message to the order to let the restaurant know any details about your
                order
              </p>
            </div>
          </div>
        </HeaderCol>
        <Body ref={childRef} headerHeight={headerHeight}>
          <div
            style={{
              position: 'sticky',
              bottom: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '1rem 0',
            }}
          >
            <OrderDetailsInput
              height="74"
              placeholder={'Order Details'}
              value={order.description}
              onChange={(e) => {
                dispatch(setOrderDescription(e.target.value));
              }}
            />
            <BlackButton
              onClick={() => setShow(false)}
              style={{
                width: '90%',
                minHeight: '60px',
                height: 60,
                fontSize: 16,
                fontWeight: 'bold',
                borderRadius: '10px',
                margin: '7.5px',
                display: order.totalValue ? 'flex' : 'none',
              }}
              text={
                <ButtonText>
                  <ButtonInfo>
                    <p style={{ padding: '3px' }}>Add Description</p>
                  </ButtonInfo>
                </ButtonText>
              }
            />
          </div>
        </Body>
      </InnerRowWrapper>
    </Drawer>
  );
};

export default OrderDescription;
