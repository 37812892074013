import React from 'react';

import RadioValidator from '../validators/radio.validator';
import ChildrenValidator from '../validators/children.validator';

const RadioGroupContext = React.createContext({
  updateSelectedElement: () => {},
  selectedElement: null,
});

const RadioGroup = ({ children, updateSelectedElement, selectedElement}) => (
  <React.Fragment>
    <RadioGroupContext.Provider value={{ updateSelectedElement, selectedElement }}>
      {children}
    </RadioGroupContext.Provider>
  </React.Fragment>
);

RadioGroup.propTypes = {
  ...RadioValidator.RadioGroupSchema,
  ...{ children: ChildrenValidator.ChildrenRule },
};

RadioGroup.Element = ({ Element, children, value, ...rest }) => (
  <RadioGroupContext.Consumer>
    {({ updateSelectedElement, selectedElement }) => (
      <Element
        isSelected={selectedElement === value}
        updateSelectedElement={() => updateSelectedElement(value)}
        {...rest}
      >
        {children}
      </Element>
    )}
  </RadioGroupContext.Consumer>
);

RadioGroup.Element.propTypes = {
  ...RadioValidator.RadioElementSchema,
  ...{ children: ChildrenValidator.ChildrenRule },
};

export default RadioGroup;
