import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button } from 'app/shared/views/buttons';
import { Row, Col, Input } from 'antd';
import StarButton from './StarButton';
import Icon from '../../../../shared/views/icons';
import { submitWaiterReview } from 'actions/shoppingActions';
import './index.css';

const { TextArea } = Input;

const Header = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #2a2e45;
  width: 100%;
  text-align: center;
`;

const WaiterReview = (props) => {
  const { showReview, setShowReview, showTextSubmit, submitWaiterReview, tableId } = props;
  const [starRating, setStarRating] = React.useState(0);
  const [reviewMessage, setReviewMessage] = React.useState('');
  const [reviewSubmitted, setReviewSubmitted] = React.useState(false);

  const onSubmit = () => {
    submitWaiterReview(starRating, reviewMessage, tableId, setReviewSubmitted);

    setStarRating(0);
    setReviewMessage('');
  };
  const onCancel = () => {
    if (typeof setShowReview === 'function') {
      setShowReview(false);
    }
    setStarRating(0);
    setReviewMessage('');
    setReviewSubmitted(false);
  };

  return (
    <Row
      style={{
        display: 'block',
        backgroundColor: '#F9FAFC',
        zIndex: 9999,
        position: starRating > 0 || reviewSubmitted || showTextSubmit ? 'fixed' : 'relative',
        bottom: showReview ? 0 : '-300px',
        transition: 'bottom 0.3s linear',
        width: '100%',
        maxWidth: 500,
      }}
    >
      <Col
        span={24}
        style={{
          padding: '12px 12px 0',
          display: starRating > 0 || showTextSubmit || reviewSubmitted ? 'block' : 'none',
        }}
      >
        <Button
          text={<Icon.Cross fill="#fff" width={'8px'} height={'8px'} />}
          onClick={onCancel}
          style={{
            float: 'right',
            height: 24,
            width: 24,
            background: 'rgb(0, 0, 0)',
            border: 'none',
            fontSize: 16,
            fontWeight: 'bold',
            fontHeight: 17,
            borderRadius: 20,
            color: '#FFFFFF',
            boxShadow: 'rgba(200, 208, 216, 0.3) 0px 12px 16px',
          }}
        />
      </Col>
      <Col span={24} style={{ marginTop: 8, padding: '0 12px' }}>
        <Header>{reviewSubmitted ? 'Review Submitted' : 'Rate the Waiter'}</Header>
      </Col>
      <Col
        span={24}
        style={{
          textAlign: 'center',
          padding: 12,
          display: reviewSubmitted ? 'none' : 'block',
        }}
      >
        <div style={{ display: 'inline-flex' }}>
          {[1, 2, 3, 4, 5].map((i) => (
            <StarButton key={i} isActive={i <= starRating} onClick={() => setStarRating(i)} />
          ))}
        </div>
      </Col>
      <Col
        span={24}
        style={{
          marginTop: 30,
          marginBottom: 6,
          textAlign: 'center',
          display: reviewSubmitted ? 'block' : 'none',
          padding: '0 12px',
        }}
      >
        <Icon.SuccessIcon2 height={'80px'} width={'80px'} />
      </Col>
      <Col
        span={24}
        style={{
          marginBottom: 30,
          textAlign: 'center',
          padding: '0 12px',
          display: reviewSubmitted ? 'block' : 'none',
        }}
      >
        Thank you for your feedback
      </Col>
      <Col span={24}>
        <Row
          style={{
            display: starRating > 0 || showTextSubmit || reviewSubmitted ? 'block' : 'none',
          }}
        >
          <Col
            span={24}
            style={{
              padding: '0 12px',
              margin: '12px 0',
              display: reviewSubmitted ? 'none' : 'block',
            }}
          >
            <TextArea
              rows={4}
              style={{ borderRadius: 4, borderColor: '#ffffff' }}
              placeholder={'Review for Waiter'}
              onChange={(e) => setReviewMessage(e.target.value)}
              value={reviewMessage}
            />
          </Col>
          <Button
            text={'Submit Review'}
            onClick={onSubmit}
            style={{
              display: reviewSubmitted ? 'none' : 'inline-flex',
              width: '100%',
              minHeight: 60,
              height: 60,
              border: 'none',
              borderRadius: 0,
              fontSize: 16,
              fontWeight: 'bold',
              color: '#FFFFFF',
              backgroundColor: '#2ECC71',
            }}
          />
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { submitWaiterReview })(WaiterReview);
