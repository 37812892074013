const initialState = {
    data: null
}

export default (state = initialState, action) => {
    switch(action.type){
        case 'GET_STRIPE_CONF_OK':
            return {
                data : {...action.data}
            }
        case 'GET_STRIPE_CONF_ERROR':
            return {
                ...initialState
            }
        case 'CHANGE_STRIPE_CONF_OK':
            return {
                data: {...action.data}
            }
        case 'REMOVE_STRIPE_CONF_OK':
            return {
                data: {}
            }
        default : 
            return {
                ...state
            }
    }
}