import _ from 'lodash';

const PRINTED_ORDER_IDS_KEY = 'printedOrderIds';

export const getPrintedOrderIds = () => {
  return _.compact(JSON.parse(localStorage.getItem(PRINTED_ORDER_IDS_KEY) ?? '[]'));
};

export const appendPrintedOrderId = (printedOrderId) => {
  const printedOrderIds = _.compact(_.union(getPrintedOrderIds(), [printedOrderId]));
  localStorage.setItem(PRINTED_ORDER_IDS_KEY, JSON.stringify(printedOrderIds));
};
