import decodeJwt from 'jwt-decode';

export const isAuthenticated = () => {
  const token = localStorage.getItem('authToken');
  return token != null;
};

export const getRole = () => {
  const token = localStorage.getItem('authToken');
  if (token == null) return false;
  const jwtInfo = decodeJwt(token);
  return jwtInfo.Role;
};

export const isBartender = () => getRole() === 'PageBartender';

export const isAdmin = () => getRole() === 'PageAdmin';
