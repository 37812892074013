import React from 'react';
import styled from 'styled-components';

import { Formik } from 'formik';
import * as Yup from 'yup';

import shared from 'app/shared';

import FormButton from '../components/MainButton';
import FieldError from '../components/FieldError';

const {
  views: {
    Input,
    CheckBox,
    Text: {
      Link: { AppLink },
    }
  },
} = shared;

const SignInForm = (props) => {

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().required('Email is required'),
        password: Yup.string().required('Password is required'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        if (typeof props.onSubmit === 'function') {
          await props.onSubmit(values);
        }
        setSubmitting(false);
      }}
    >
      {({ values, touched, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <FormWrapper
           onKeyPress={(e) => {
             if (e.key === 'Enter'){
               handleSubmit()
             }
           }}>
            <InputWrapper>
              <Input
                labelText="Email"
                name="email"
                type="text"
                height={38}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.email && errors.email && (
                <FieldError>
                  {errors.email}
                </FieldError>
              )}
            </InputWrapper>
            <InputWrapper>
              <Input
                labelText="Password"
                name="password"
                type="password"
                height={38}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.password && errors.password && (
                <FieldError>
                  {errors.password}
                </FieldError>
              )}
            </InputWrapper>

            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
              <RememberMeCheckbox
                name="remember"
                text="Remember Me"
                checked={values.remember}
                click={handleChange}
              />
              <AppLink to="/forgotPassword">Forgot Password?</AppLink>
            </div>

            <FormButton
              text="Sign In"
              onClick={handleSubmit}
              disabled={isSubmitting}
              style={{ marginTop: '20px' }}
            />
          </FormWrapper>
        )
      }
    </Formik>
  )
};

const FormWrapper = styled.div`

`;

const InputWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin: 5px 0;
`;

const RememberMeCheckbox = styled(CheckBox)`
  font-size: 12px;
  line-height: 22px;
  color: #595959;
`;

export default SignInForm;
