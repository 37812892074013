import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
// import { toast } from 'react-toastify';

import { confirmEmail } from 'actions/authActions';
import useQueryParams from 'hooks/useQueryParams';

import AuthLayout from '../auth.layout';
import AllSet from '../components/AllSet';

const ActivateAccountPage = ({ confirmEmail }) => {
  const history = useHistory();
  const queryParams = useQueryParams();

  useEffect(() => {
    const confirmCode = queryParams.get('confirmationcode');
    confirmEmail(confirmCode);
  }, [confirmEmail, queryParams]);

  return (
    <AuthLayout>
      <ActivateAccountWrapper>
        <AllSet
          title={'Good Job'}
          message={[
            'Your email has been successfully confirmed.',
            'Click the button below to access Orderoo.',
          ]}
          actionText="Sign In"
          onAction={() => {
            history.push('/signin');
          }}
        />
      </ActivateAccountWrapper>
    </AuthLayout>

  );
};


const ActivateAccountWrapper = styled.div`
  margin-top: 20px;
`;

export default connect(state => ({

}), {
  confirmEmail
})(ActivateAccountPage);
