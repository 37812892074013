import { createSelector } from 'reselect';

const initialState = {
  connectedToHub: 'IDLE',
  connectionHub: null,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CONNECT_TO_HUB_OK':
      return {
        ...state,
        connectedToHub: 'OK',
        connectionHub: action.data,
      };

    case 'CONNECT_TO_HUB_WAIT':
      return {
        ...state,
        connectedToHub: 'WAIT',
      };

    case 'CONNECT_TO_HUB_ERROR':
      return {
        ...state,
        connectedToHub: 'ERROR',
      };

    case 'UPDATE_NOTIFICATION_BY_ID':
      return {
        ...state,
        data: [...state.data.filter((x) => x.id !== action.data.id), action.data],
      };

    case 'GET_NOTIFICATIONS_OK':
      return {
        ...state,
        data: action.data,
      };

    case 'MARK_AS_READ_OK':
      return {
        ...state,
        data: state.data.map((x) => (x.id === action.id ? { ...x, status: 'READ' } : x)),
      };

    case 'MARK_ALL_READ_OK':
      return {
        ...state,
        data: state.data.map((x) => ({ ...x, status: 'READ' })),
      };
    default:
      return state;
  }
};

const selectNotifications = (state) => state.notifications.data;

export const countOrderNotifications = createSelector(selectNotifications, (notifications) => {
  const unreadOrderNotifications = notifications.filter((notification) => {
    return notification.status === 'UNREAD' && notification.notificationType === 0;
  });
  return unreadOrderNotifications.length;
});

export const getFirstUnreadOrderNotification = createSelector(
  selectNotifications,
  (notifications) => {
    const unreadOrderNotifications = notifications.filter((notification) => {
      return notification.status === 'UNREAD' && notification.notificationType === 0;
    });
    return unreadOrderNotifications.length > 0
      ? unreadOrderNotifications.reduce((a, b) => {
          return a.creationDate < b.creationDate ? a : b;
        })
      : null;
  },
);

export const countCallWaiterNotifications = createSelector(selectNotifications, (notifications) => {
  const unreadOrderNotifications = notifications.filter((notification) => {
    return notification.status === 'UNREAD' && notification.notificationType === 2;
  });
  return unreadOrderNotifications.length;
});

export const getFirstUnreadCallWaiterNotification = createSelector(
  selectNotifications,
  (notifications) => {
    const unreadOrderNotifications = notifications.filter((notification) => {
      return notification.status === 'UNREAD' && notification.notificationType === 2;
    });
    return unreadOrderNotifications.length > 0
      ? unreadOrderNotifications.reduce((a, b) => {
          return a.creationDate < b.creationDate ? a : b;
        })
      : null;
  },
);

export const countgetBillNotifications = createSelector(selectNotifications, (notifications) => {
  const unreadOrderNotifications = notifications.filter((notification) => {
    return notification.status === 'UNREAD' && notification.notificationType === 3;
  });
  return unreadOrderNotifications.length;
});

export const getFirstgetBillNotification = createSelector(selectNotifications, (notifications) => {
  const unreadOrderNotifications = notifications.filter((notification) => {
    return notification.status === 'UNREAD' && notification.notificationType === 3;
  });
  return unreadOrderNotifications.length > 0
    ? unreadOrderNotifications.reduce((a, b) => {
        return a.creationDate < b.creationDate ? a : b;
      })
    : null;
});

export const getUnreadOrderNotifications = createSelector(selectNotifications, (notifications) =>
  notifications.filter(
    (notification) => notification.status === 'UNREAD' && notification.notificationType === 0,
  ),
);

// const notifications = props.notifications.data.filter(notification => {
//     return notification.status === 'UNREAD' && notification.notificationType === 'ORDER'
//   });
