import axios from 'axios';
import logout from 'app/shared/helpers/logout';
import Url from 'config/api';

const baseUrl = `${Url.baseUrl}/Auth`;
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

export default () => {
  axios.interceptors.request.use((config) => {
    if (undefined === config.headers.Authorization) {
      let token = localStorage.getItem('authToken');

      if (undefined !== token) {
        token = `Bearer ${token}`;
        config.headers.Authorization = token;
      }
    }

    if (undefined === config.headers.Page) {
      let activePage = JSON.parse(localStorage.getItem('Page'));
      config.headers.Page = activePage || '';
    }

    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      const originalRequest = err.config;

      if (err.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers['Authorization'] = 'Bearer ' + token;
              return axios(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }
        if (!localStorage.getItem('refreshToken')) {
          logout();
          return Promise.reject(err);
        }
        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise(function (resolve, reject) {
          axios
            .post(
              `${baseUrl}/refresh-token`,
              JSON.stringify(localStorage.getItem('refreshToken')),
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              },
            )
            .then(({ data }) => {
              const token = JSON.parse(data.token);
              const refreshTokenVar = data.refreshToken;
              originalRequest.headers['Authorization'] = 'Bearer ' + token.auth_token;
              localStorage.setItem('authToken', token.auth_token);
              localStorage.setItem('refreshToken', refreshTokenVar.token);
              processQueue(null, token.auth_token);
              resolve(axios(originalRequest));
            })
            .catch((err) => {
              processQueue(err, null);
              reject(err);
            })
            .then(() => {
              isRefreshing = false;
            });
        });
      }

      return Promise.reject(err);
    },
  );
};
