import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import moment from 'moment';
import shared from 'app/shared';
import downloadUrl from 'app/shared/helpers/downloadUrl';
import crudTypes from 'config/crudTypes';
import { Prompt } from 'react-router-dom';

import qrCodePlaceholder from '../assets/qrcode.svg';

const {
  views: {
    Text: {
      Link: { Link },
    },
    Input,
  },
} = shared;

const QrCodeImg = styled.img`
  width: 30px;
  height: 30px;
`;

const SaveLink = styled(Link)`
  :hover {
    cursor: pointer;
  }
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #2ecc71;
`;

const QrCodeLink = styled(Link)`
  :hover {
    cursor: pointer;
  }
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #3185fc;
`;

const DeleteLink = styled(Link)`
  :hover {
    cursor: pointer;
  }
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #ff3a44;
`;

const EditTable = ({ table, tableCategory, edit, remove, updateStatus }) => {
  const [tableName, setTableName] = useState(table.name);

  const downloadQrCode = (table) => {
    downloadUrl(table.qrPath, `${table.name} QR code.svg`, 'image/svg');
  };

  return (
    <Row
      type="flex"
      style={{ alignItems: 'center', padding: '20px 0px 20px 20px', margin: 0 }}
      justify="center"
      className={'rowBackground'}
    >
      <Prompt
        when={tableName !== table.name}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <Col span={2} style={{ textAlign: 'center' }}>
        <QrCodeImg alt="qrcode" src={qrCodePlaceholder} />
      </Col>
      <Col span={3}>{table.id}</Col>
      <Col span={7}>
        <div style={{ width: '90%' }}>
          <Input value={tableName} onChange={(e) => setTableName(e.target.value)} />
        </div>
      </Col>
      <Col span={6} style={{ textAlign: 'center' }}>
        {moment(table.lastModified).format('YYYY-MM-DD HH:mm')}
      </Col>
      <Col
        span={6}
        style={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
        }}
      >
        <SaveLink
          href="download"
          target="_blank"
          onClick={(e) => {
            e.preventDefault();
            edit(table.id, crudTypes.table, { ...table, name: tableName }, updateStatus);
          }}
          disabled={tableName === table.name}
        >
          Save
        </SaveLink>
        <QrCodeLink
          href="download"
          target="_blank"
          onClick={(e) => {
            e.preventDefault();
            downloadQrCode(table);
          }}
        >
          Download
        </QrCodeLink>
        <DeleteLink
          target="_blank"
          onClick={(e) => {
            e.preventDefault();
            if (window.confirm(`Are you sure you want to delete ${tableCategory.name}?`)) {
              remove(table.id, crudTypes.table, updateStatus);
            }
          }}
        >
          Delete
        </DeleteLink>
      </Col>
    </Row>
  );
};

export default EditTable;
