import OnlineShoppingPage from './views/OnlineShopping.page';

OnlineShoppingPage.path = '/restaurant/:pageName';
OnlineShoppingPage.global_path = '/:pageName';

export default {
  views: {
    OnlineShoppingPage,
  },
};
