import React from 'react';
import { connect } from 'react-redux';
import AutoPrintReceipt from 'app/shared/views/printReceipt/AutoPrintReceipt';

export const Shtypshkronja = ({ printOrderIds }) => {
  return (
    <>
      {printOrderIds.map((orderId) => (
        <AutoPrintReceipt key={orderId} orderId={orderId} />
      ))}
    </>
  );
};

const mapStateToProps = (state) => ({
  printOrderIds: state.orders.print,
});
export default connect(mapStateToProps, {})(Shtypshkronja);

/*
import React from 'react';
import { connect } from 'react-redux';
import { getUnreadOrderNotifications } from 'reducers/notificationReducer';
import { getInternalOrderIds } from 'reducers/orderReducer';
import _ from 'lodash';
import { getPrintedOrderIds } from 'app/shared/views/printReceipt/util';
import AutoPrintReceipt from 'app/shared/views/printReceipt/AutoPrintReceipt';
import { ORDER_STATUS } from 'app/orders/constants';

export const Shtypshkronja = ({ notifications, orders, internalOrderIds }) => {
  let orderIds = notifications.map(
    (n) =>
      orders.find((o) => o.orderCounter === n.itemId && ORDER_STATUS[o.orderStatus] === 'Paid')?.id,
  );
  orderIds = [...orderIds, ...internalOrderIds];
  orderIds = _.difference(_.compact(orderIds), getPrintedOrderIds());
  console.log('print orderIds', orderIds);
  return (
    <>
      {orderIds.map((orderId) => (
        <AutoPrintReceipt key={orderId} orderId={orderId} />
      ))}
    </>
  );
};

const mapStateToProps = (state) => ({
  notifications: getUnreadOrderNotifications(state),
  orders: state.orders.data,
  internalOrderIds: getInternalOrderIds(state),
});
export default connect(mapStateToProps, {})(Shtypshkronja);
*/
