import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const CategoryWrapper = styled(Link)`
  background-color: ${({ selected }) => (selected ? '#3185fc' : '#ffffff')};
  color: ${({ selected }) => (selected ? '#ffffff' : '#1D2036')};
  border-radius: 15px;
  padding: 4px 16px;
  margin: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  min-inline-size: fit-content;
  align-self: center;
  :hover {
    color: ${({ selected }) => (selected ? '#ffffff' : '#3185fc')};
  }
`;

const Category = ({ category, selectedCategory }) => {
  const myRef = useRef(null);

  useEffect(() => {
    if (category?.id === selectedCategory) {
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [category, selectedCategory]);

  return (
    <CategoryWrapper
      ref={myRef}
      selected={category?.id === selectedCategory}
      to={`/${category?.name}`}
    >
      {category?.name}
    </CategoryWrapper>
  );
};

export default Category;
