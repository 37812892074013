import React, { useState } from 'react';
import styled from 'styled-components';
import shared from 'app/shared';
import { edit } from '../../../actions/crudActions';
import { connect } from 'react-redux';


const {
  views: { Card, Text, Button },
  helpers: { colors },
} = shared;

const {
  Paragraph: { P },
} = Text;

const BannerCardWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 380px;
  min-width: 132px !important;
  height: 100%;
  justify-content: space-between;
  border-radius: 3px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 2;
`;

const Label = styled(P)`
  font-size: 11px;
  line-height: 22px;
  justify-content: flex-start;
  color: ${colors.textColor};
`;

const Value = styled(P)`
  font-size: 12px;
  line-height: 22px;
`;

const BannerStatus = styled(Value)`
  color: ${colors.green};
  font-weight: bold;
  font-size: 14px;
  justify-content: center;
`;

const Ellipsis = styled.div`
  font-weight: bold;
`;

const BannerCard = ({ imageUri, period, startTime, endTime, status, title, uri, id, alwaysActive, ...rest }) => {
  const [activeStat, setActiveStatus] = useState(status);
  let inactive = {
    imageUri: imageUri,
    period: period,
    startTime: startTime,
    endTime: endTime,
    title: title,
    alwaysActive: 'inactive'
  };

  let active = {
    imageUri: imageUri,
    period: period,
    startTime: startTime,
    endTime: endTime,
    title: title,
    alwaysActive: 'active',
  };
  const activeButton = (e) => {
    e.stopPropagation()
    setActiveStatus('Active');
    edit(id, {uri: 'Banner'}, active);
  }
  const inActiveButton = (e) => {
    e.stopPropagation();
    setActiveStatus('Inactive');
    edit(id, { uri: 'Banner' }, inactive);
  }
  return (
    <div>
      <BannerCardWrapper {...rest}>
        <img src={imageUri} alt="banner" style={{ height: '78px' }} />
        <Row style={{ marginBottom: 5 }}>
          <Ellipsis>{title}</Ellipsis>

          <BannerStatus style={activeStat === 'Inactive' ? { color: 'red' } : { color: 'green' }}>
            {activeStat}
          </BannerStatus>
        </Row>
        <Row>
          <Label>From:</Label>
          <Label>{startTime}</Label>
        </Row>
        <Row>
          <Label>To:</Label>
          <Label>{endTime}</Label>
        </Row>
        <Row>
          {activeStat === 'Inactive' ? (
            <Button.PlayButton onClick={(e) => activeButton(e)} />
          ) : (
            <Button.PauseButton onClick={(e) => inActiveButton(e)} />
          )}
        </Row>
      </BannerCardWrapper>
    </div>
  );};

  const mapStateToProps = (state) => ({
    facebookPages: state.facebookPages,
    banner: state.banner,
  });

  export default connect(mapStateToProps, { edit })(BannerCard);
