import crudTypes from 'config/crudTypes';
import crudReducer from './crudReducer';
import arrayMove from 'array-move';

const crudCategoryReducer = crudReducer(crudTypes.category);
const initialState = {
  data: [],
  current: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_PRODUCT_ARRANGEMENT': {
      const { categoryId, oldIndex, newIndex } = action.data;

      return {
        ...state,
        data: state.data.map((c) => {
          if (c.id === categoryId) {
            return {
              ...c,
              magProducts: arrayMove(c.magProducts, oldIndex, newIndex),
            };
          }
          return c;
        }),
      };
    }

    case 'UPDATE_CATEGORY_ARRANGEMENT': {
      const { categoryType, oldIndex, newIndex } = action.data;

      return {
        ...state,
        data: [
          ...state.data.filter((x) => x.categoryType !== categoryType),
          ...arrayMove(
            state.data.filter((x) => x.categoryType === categoryType),
            oldIndex,
            newIndex,
          ),
        ],
      };
    }

    default:
      return crudCategoryReducer(state, action);
  }
};
