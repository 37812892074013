import React from 'react';
import currencyFormatter from 'app/shared/helpers/currencyFormatter';
import moment from 'moment';
import { TableButton } from 'app/marketing/components/buttons';

import SmsInbox from './views/smsInbox';
import Campaigns from './views/campaigns';

export const CHARACTER_LIMIT = 140;

export const MARKETING_TABLE_HEADS = [
  {
    label: 'FULL NAME',
    key: 'fullName',
    sortable: true,
  },
  {
    label: 'ORDERS',
    key: 'orderCount',
    renderer: ({ orderCount }) => {
      return <TableButton text={orderCount} />;
    },
    sortable: true,
  },
  {
    label: 'REVENUE',
    key: 'totalSum',
    renderer: ({ totalSum }) => `${currencyFormatter(totalSum)}`,
    sortable: true,
  },
  {
    label: 'LAST ORDER',
    key: 'lastOrderDate',
    renderer: ({ lastOrderDate }) =>
      lastOrderDate ? moment(lastOrderDate).format('YYYY-MM-DD') : 'N/A',
    sortable: true,
  },
  {
    label: 'EMAIL',
    key: 'email',
    sortable: true,
    renderer: ({ email }) => email,
  },
  {
    label: 'PHONE',
    key: 'phone',
    sortable: true,
    renderer: ({ phone }) => phone,
  },
];

export const MARKETING_TABS = [
  {
    text: 'SMS Inbox',
    value: 'inbox',
    component: SmsInbox,
  },
  {
    text: 'SMS Campaigns',
    value: 'campaigns',
    component: Campaigns,
  },
];

export const CAMPAIGN_STATUS = {
  0: 'Draft',
  1: 'Completed',
  2: 'Ready To Send',
  Draft: 0,
  Completed: 1,
  ReadyToSend: 2,
};
