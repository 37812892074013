import React from 'react';
import colors from 'app/shared/helpers/colors';
import ClipLoader from 'react-spinners/ClipLoader';

export default (props) => {
  const { size = 80, color = colors.black } = props;
  return (
    <div style={({ ...props.style }, { display: 'flex', height: '100%', width: '100%' })}>
      <div style={{ margin: 'auto' }}>
        <ClipLoader size={size} color={color} />
      </div>
    </div>
  );
};
