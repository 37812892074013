const paragraph=  'We\'ll need some permissions to manage your Page' +
  '\'s messages to automate your replies. That will open Facebook but don' +
  '\'t worry! You will be back right after granting all requested permissions.';

const title = 'Connect Facebook Account';

const subtitle = 'Sign In with Facebook to set up your first bot and Orderoo dashboard';

const btnText = 'Sign In with Facebook';

export default{
  title,
  paragraph,
  subtitle,
  btnText
}
