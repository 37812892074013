import React from 'react';
import shared from 'app/shared';
import text from './text';

const{LocalLabel} = text;
const{ views:{ Input, }, } = shared;

const LabeledInput = (props) => (
  <div style={{width: '80%', margin: '5% auto'}}>
    <LocalLabel  text={props.text} />
    <Input />
  </div>
);

export default LabeledInput;
