const title = 'Stripe Configuration';

const subtitle='Configure your Stripe account';

const link = 'Create Stripe Account';

const Public = 'Publishable Key';

const Secret = 'Private Key';

const mainButton = 'Test';

const left = 'Back';

const right = 'Continue';

export default {title, subtitle, link, Public, Secret, mainButton, left, right};
