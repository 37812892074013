import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import shared from '../../../../app/shared';
import FilterBar from '../../../../app/shared/views/filterBar';
import DatePicker from '../../../../app/shared/views/dateRangeFilter';
// import { format } from 'date-fns';

import { getAllUsers, lockUser, unlockUser } from 'actions/adminActions';

import { paginate } from 'config/helpers/paginator';
import { sortData, getSortState } from 'config/helpers/sorter';
import { filterData } from 'config/helpers/searcher';
// import { genericFilter } from 'app/shared/helpers/filterFunctions';
import SelectOption from '../../../../app/shared/views/selectOption';
import SearchInput from 'app/shared/views/searchInput';

const {
  views: { Table, Button, Paginator, Page, FilterableTableStructure },
  helpers: { resetScroll, colors },
} = shared;

const {
  ActionBarWrapper,
  SearchWrapper,
  TableWrapper,
  PaginatorWrapper,
} = FilterableTableStructure;

const SelectFilterWrapper = styled.div`
  width: 150px;
`;

const FilterBarWrapper = styled.div`
  margin-bottom: 20px;
`;

const filerBy = {
  PeriodOptions: [
    { value: 'today', label: 'Today' },
    { value: 'yesterday', label: 'Yesterday' },
    { value: 'last-week', label: 'Last Week' },
    { value: 'last-month', label: 'Last Month' },
    { value: 'total', label: 'Total' },
  ],
  StatusOptions: [
    { value: '0', label: 'All' },
    { value: '1', label: 'Locked' },
    { value: '2', label: 'Unlocked' },
  ],
};

const header = [
  // {
  //   label: 'ID',
  //   key: 'id',
  //   sortable: true,
  //   renderer: ({ id }) => id,
  // },
  // {
  //   label: 'NAME',
  //   key: 'name',
  //   sortable: true,
  //   renderer: ({ name }) => name,
  // },
  {
    label: 'EMAIL',
    key: 'email',
    sortable: true,
    renderer: ({ email }) => email,
  },
  // {
  //   label: 'PHONE NUMBER',
  //   key: 'phoneNumber',
  //   sortable: true,
  //   renderer: ({ phoneNumber }) => phoneNumber,
  // },
  // {
  //   label: 'REGISTERED',
  //   key: 'created',
  //   renderer: ({ registered }) => format(registered, 'YYYY-MM-DD'),
  //   sortable: true,
  // },
  // {
  //   label: 'LOCATION',
  //   key: 'location',
  //   renderer: ({ location }) => location,
  //   sortable: true,
  // },
  {
    label: 'EMAIL CONFIRMED',
    key: 'status',
    sortable: true,
    renderer: (item) => {
      return item.emailConfirmed ? 'Yes' : 'No';
    },
  },
];

// const userStatus = {
//   LOCKED: 'LOCKED',
//   UNLOCKED: 'UNLOCKED',
// };

// const statusBadge = (status) => {
//   const labelColors = {
//     [userStatus.UNLOCKED]: colors.green,
//     [userStatus.LOCKED]: colors.red
//   };

//   return (
//     <Button.Button
//       style={{ fontWeight: 600 }}
//       text={status}
//       size={Button.ButtonSize.large}
//       color={labelColors[status]}
//       style={{
//         pointerEvents: 'none',
//       }}
//     />
//   );
// };

const initialState = {
  selectedPeriod: { value: 'total', label: 'Total' },
  selectedStatus: filerBy.StatusOptions[0],
  customDate: {
    startDate: null,
    endDate: null,
  },
  sort: {
    key: 'created',
    order: -1,
  },
  search: '',
};

class AdminUsersPage extends Component {
  state = initialState;

  componentWillMount() {
    this.props.getAllUsers();
  }

  componentDidMount() {
    resetScroll();
  }

  updateSelectedPeriod = (option) => {
    if (option === 'custom') {
      option = { value: 'custom', label: 'Custom' };
    } else {
      this.setState({
        customDate: {
          startDate: null,
          endDate: null,
        },
      });
    }
    this.setState({
      selectedPeriod: option,
      currentPage: 1,
    });
  };

  updateSelectedStatus = (option) => {
    this.setState({
      selectedStatus: option,
      currentPage: 1,
    });
  };

  updateCustomDate = (customDate) => {
    this.setState({
      customDate: {
        startDate: customDate.startDate,
        endDate: customDate.endDate,
      },
      currentPage: 1,
    });
  };

  clearFilters = () => {
    this.setState(initialState);
  };

  updatePaginatorPage = (currentPage) => {
    this.props.getAllUsers(currentPage);
  };

  setSearchValue = (val) => {
    this.setState({ search: val, currentPage: 1 });
  };

  updateUserStatus = (user) => {
    if (user.lockoutEnabled) {
      this.props.unlockUser(user.id);
    } else {
      this.props.lockUser(user.id);
    }
  };

  render() {
    const {
      state: { selectedPeriod, selectedStatus, customDate, currentPage, pageSize, sort, search },
      updateSelectedPeriod,
      updateSelectedStatus,
      updateCustomDate,
      clearFilters,
      updatePaginatorPage,
      updateUserStatus,
    } = this;

    // const timeFilteredData = genericFilter(
    //   selectedPeriod.value,
    //   this.props.users.data,
    //   customDate,
    //   'registered',
    //   null,
    // );

    const timeFilteredData = this.props.users.data;

    const searchData = filterData(timeFilteredData, search);
    const sortedData = sortData(searchData, sort);
    const paginatedData = paginate(sortedData, currentPage, pageSize);

    const updateSortState = (key) => {
      this.setState((prevState) => ({
        sort: getSortState(prevState.sort, key),
      }));
    };

    const getTableData = (headers, data) => {
      headers = [
        ...headers,
        {
          label: 'ACTION',
          width: '70px',
          key: 'actions',
          sortable: true,
          renderer: (user) => {
            return (
              <Button.Button
                style={{ fontWeight: 600 }}
                text={user.lockoutEnabled ? 'Unlock' : 'Lock'}
                size={Button.ButtonSize.medium}
                color={colors.blue}
                onClick={() => updateUserStatus(user)}
              />
            );
          },
        },
      ];

      return {
        action: updateSortState,
        header: headers,
        values: data,
      };
    };

    return (
      <Page>
        <FilterBarWrapper>
          <FilterBar>
            <FilterBar.Components.Title style={{ marginTop: '10px' }}>
              Filter by:
            </FilterBar.Components.Title>
            <FilterBar.Components.Title style={{ marginTop: '10px' }}>
              Period
            </FilterBar.Components.Title>
            <SelectFilterWrapper>
              <SelectOption
                options={filerBy.PeriodOptions}
                onChange={(option) => {
                  updateSelectedPeriod(option);
                }}
                isSearchable={'false'}
                name={'period'}
                value={selectedPeriod}
              />
            </SelectFilterWrapper>
            <SelectFilterWrapper>
              <SelectOption
                options={filerBy.StatusOptions}
                onChange={updateSelectedStatus}
                isSearchable={'false'}
                name={'subStatus'}
                value={selectedStatus}
              />
            </SelectFilterWrapper>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <DatePicker
                selectedElement={selectedPeriod}
                updateSelectedElement={updateSelectedPeriod}
                updateCustomDate={updateCustomDate}
                customDate={customDate}
                clearFilters={clearFilters}
              />
              <div style={{ marginLeft: 20 }}>
                <FilterBar.Components.ClearFilters onClick={clearFilters} />
              </div>
            </div>
          </FilterBar>
        </FilterBarWrapper>
        <ActionBarWrapper>
          <SearchWrapper>
            <SearchInput search={search} onSearchChanged={(x) => this.setSearchValue(x)} />
          </SearchWrapper>
        </ActionBarWrapper>
        <TableWrapper>
          <Table.TableView data={getTableData(header, paginatedData.current)} />
        </TableWrapper>
        <PaginatorWrapper>
          <Paginator
            changePage={updatePaginatorPage}
            numberOfPages={this.props.users.pageCount}
            page={this.props.users.currentPage}
          />
        </PaginatorWrapper>
      </Page>
    );
  }
}

AdminUsersPage.path = '/admin/users';

export default connect(state => ({
  users: state.adminUsers,
}), {
  getAllUsers,
  lockUser,
  unlockUser,
})(AdminUsersPage);
