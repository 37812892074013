import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import shared from 'app/shared';
import downloadUrl from 'app/shared/helpers/downloadUrl';
// import BotIllustration from 'app/chatbot/views/botIllustration';
import { updateChatbotSettings, updateOpenHoursOnlineSettings } from 'actions/pageActions';
import AreaCoverageSetup from 'components/AreaCoverageSetup';
import DeliveryHours from '../components/DeliveryHours';
import SettingsTitle from '../components/SettingsTitle';
import qrCodePlaceholder from '../assets/qrcode.svg';
import { useIsMount } from '../hooks';
import { scrollToAlert } from 'app/shared/views/alert';

const {
  views: {
    Text: {
      Link: { Link },
    },
    // Toggle,
    Input,
    Alert,
    // CheckBox,
  },
  helpers: { colors },
} = shared;

const QrCodeImg = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 15px;
`;

const InlineFlexWrapper = styled.div`
  display: inline-flex;
  margin-top: 3%;
  width: 100%;
`;

const MainLabel = styled.div`
  display: flex;
  height: 100%;
  width: 20%;
  font-size: 15px;
  color: ${colors.textColor};
  min-width: 160px;
`;

const MainOption = styled.div`
  flex: 1;
`;

const OnlineOrderSettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.7% 30px;
`;

const QrCodeLink = styled(Link)`
  :hover {
    cursor: pointer;
  }
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #3185fc;
`;

const QrWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const downloadQrCode = (page) => {
  downloadUrl(page.onlineOrderQRPath, `${page.pageName} QR code.svg`, 'image/svg');
};

const ChatbotSettings = ({ activePage, setHasChanges, setExecuteOnSubmit, children, ...props }) => {
  const { updateChatbotSettings, updateOpenHoursOnlineSettings } = props;
  const [activeBot] = useState(activePage?.activeBot);
  const [minimumOrderValue, setMinimumOrderValue] = useState(activePage?.minimumOrderValue);
  const [geoRegions, setGeoRegions] = useState(activePage?.geoRegions);
  const [businessAddress, setBusinessAddress] = useState(null);
  const [deliveryHours, setDeliveryHours] = useState(() => {
    if (!activePage) return [];
    try {
      return activePage.openHours;
    } catch (e) {
      return [];
    }
  });
  const [timeZone, setTimeZone] = useState(1);

  const [alert, setAlert] = useState({ show: false });
  const alertRef = useRef(null);
  const isMount = useIsMount();

  useEffect(() => {
    if (!isMount) {
      const hasChanges = deliveryHours.some((hours) => !activePage.openHours.includes(hours));
      if (hasChanges) {
        setHasChanges(true);
      }
    }
  }, [setHasChanges, deliveryHours, activePage.openHours, isMount]);

  useEffect(() => {
    if (!isMount) {
      setHasChanges(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimumOrderValue, geoRegions, setHasChanges]);

  useEffect(() => {
    setBusinessAddress({
      address: activePage.location,
      pos: activePage.locationCoords,
    });
  }, [activePage]);

  useEffect(() => {
    setExecuteOnSubmit(() => () => {
      setAlert({ show: false });
      const onDone = (status) => {
        if (status === 'OK') {
          setHasChanges(false);
        }

        setAlert({
          show: true,
          message:
            status === 'OK'
              ? 'You have successfully changed your online settings!'
              : 'Could not change your online settings!',
          type: status === 'OK' ? 'success' : 'error',
        });
        scrollToAlert(alertRef);
      };

      updateOpenHoursOnlineSettings(deliveryHours, timeZone, (status) => {
        if (status === 'ERROR') {
          return onDone(status);
        }
        updateChatbotSettings(
          {
            activeBot,
            minimumOrderValue,
            geoRegions,
            location: businessAddress.address,
            locationCoords: businessAddress.pos,
          },
          onDone,
        );
      });
    });
  }, [
    timeZone,
    activeBot,
    minimumOrderValue,
    geoRegions,
    businessAddress,
    deliveryHours,
    setExecuteOnSubmit,
    updateChatbotSettings,
    updateOpenHoursOnlineSettings,
    setHasChanges,
  ]);

  return (
    <OnlineOrderSettingsWrapper>
      <div style={{ width: '100%' }}>
        {/* <SettingsTitle title="Orderoo Chatbot"/>*/}
        <div style={{ marginTop: '15px' }}>
          <Alert ref={alertRef} {...alert} />
        </div>
        {/* <InlineFlexWrapper>
          <MainLabel>Enable Orderoo Chatbot</MainLabel>
          <MainOption>
            <Toggle
              toggle={() => {
                setActiveBot(!activeBot);
              }}
              show={!activeBot}
              color={activeBot ? colors.blue : colors.grey}
            />
          </MainOption>
        </InlineFlexWrapper> */}
        {/* Delivery hours here */}
        <SettingsTitle title="Area Coverage" />
        <InlineFlexWrapper style={{ flexDirection: 'column' }}>
          <MainLabel>Enter Restaurants Address</MainLabel>
          <MainOption>
            <AreaCoverageSetup
              activePage={activePage}
              geoRegions={geoRegions}
              setGeoRegions={setGeoRegions}
              setBusinessAddress={setBusinessAddress}
              businessAddress={businessAddress}
            />
          </MainOption>
        </InlineFlexWrapper>
        <InlineFlexWrapper>
          <MainLabel>Minimum Order Value</MainLabel>
          <MainOption>
            <Input
              height={38}
              value={minimumOrderValue}
              onChange={(e) => setMinimumOrderValue(e.target.value)}
            />
          </MainOption>
        </InlineFlexWrapper>
        <InlineFlexWrapper>
          <MainLabel>Setup Delivery Hours</MainLabel>
          <MainOption>
            <DeliveryHours
              deliveryHours={deliveryHours}
              setDeliveryHours={setDeliveryHours}
              setTimeZone={setTimeZone}
            />
          </MainOption>
        </InlineFlexWrapper>
        <InlineFlexWrapper style={{ paddingBottom: '20px' }}>
          <MainLabel />
          {children}
        </InlineFlexWrapper>
      </div>
      {/* <div style={{ position: 'absolute', right: 0 }}>
        <BotIllustration state={activeBot ? 'awake' : 'sleep'} />
      </div> */}
      <InlineFlexWrapper>
        <MainLabel>Online Order QR Code</MainLabel>
        <MainOption>
          <QrWrapper>
            <QrCodeImg alt="qrcode" src={qrCodePlaceholder} />
            <QrCodeLink
              href="download"
              target="_blank"
              onClick={(e) => {
                e.preventDefault();
                downloadQrCode(activePage);
              }}
            >
              Download
            </QrCodeLink>
          </QrWrapper>
        </MainOption>
      </InlineFlexWrapper>
    </OnlineOrderSettingsWrapper>
  );
};

const mapStateToProps = (state) => ({
  activePage: state.facebookPages.activePage,
});

export default connect(mapStateToProps, {
  updateChatbotSettings,
  updateOpenHoursOnlineSettings,
})(ChatbotSettings);
