import React from 'react';
import styled from 'styled-components';
import Bill from './bill';
import orderooWhiteLogo from 'assets/images/logo-white.svg';
import Icons from '../../../../shared/views/icons';
import { GreenButton } from 'app/shared/views/buttons';
import currencyFormatter from 'app/shared/helpers/currencyFormatter';

const GetBillWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  max-width: 502px;
  margin: auto;
  height: calc(var(--vh, 1vh) * 100);

  @media (min-width: 501px) {
    border: 1px #e8e9f3 solid;
  }
`;

const StyledHeader = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  background-image: url(${(props) => props.cover});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;
  padding: 20px;

  display: flex;
  flex-direction: column;
  min-height: 160px;
  position: relative;
  &:after {
    background: #ffffff;
    clip-path: url(#clip);
    display: block;
    height: 30px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    content: '';
    transform: scale(1, -1);
  }
`;

const LogoWrapper = styled.div`
  padding-bottom: 15px;
`;

const Name = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding: 25px 0;
`;

const TableName = styled.div`
  border-radius: 20px;
  padding: 0 8px;
  height: 28px;
  margin-left: 12px;
  color: #1d2036;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 26px;

  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 1);
`;

const OrdersWrapper = styled.div`
  width: 100%;
  min-height: 400px;
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: #2a2e45;
  padding: 20px;
  position: sticky;
  top: 0px;
  background: #fff;
`;

const ActionButtons = styled.div``;

const TotalRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #e8e9f3;
  margin: 0 20px;
  padding: 20px 0;
`;

const TotalLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #1d2036;
`;
const TotalValue = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #2ecc71;
`;

const GetBill = ({ bills, tableInfo, pageInfo, startNewOrder }) => {
  const showLogo = pageInfo.isSubscribed;
  return (
    <>
      <GetBillWrapper>
        <StyledHeader cover={pageInfo.bannerURL}>
          <LogoWrapper style={{ display: showLogo ? 'flex' : 'none' }}>
            <img src={orderooWhiteLogo} alt="orderoo-logo" height="18px" />
          </LogoWrapper>
          <Name>
            {pageInfo.pageName}
            <TableName>{tableInfo.name}</TableName>
          </Name>
        </StyledHeader>
        <OrdersWrapper>
          <Header>Billing Information</Header>
          {bills?.orders?.map((bill, index) => {
            if (bill.orderStatus === 1) {
              return (
                <Bill key={bill.toString() + index} order={bill} currency={pageInfo.currency} />
              );
            } else {
              return null;
            }
          })}
        </OrdersWrapper>
        <ActionButtons>
          <TotalRow>
            <TotalLabel>Total:</TotalLabel>
            <TotalValue>
              {currencyFormatter(
                bills?.orders?.reduce(
                  (acc, bill) => (bill.orderStatus === 1 ? (acc = acc + bill.totalValue) : acc),
                  0,
                ),

                pageInfo.currency,
              )}
            </TotalValue>
          </TotalRow>
          <GreenButton
            onClick={() => {
              startNewOrder();
              window.location.reload();
            }}
            style={{
              width: '100%',
              minHeight: '60px',
              height: 60,
              fontSize: 16,
              fontWeight: 'bold',
              color: '#FFFFFF',
              borderRadius: 0,
            }}
            text="New Order"
          />
        </ActionButtons>
      </GetBillWrapper>
      <Icons.Wave />
    </>
  );
};
export default GetBill;
