export const PAYMENT_METHODS = {
  ON_DELIVERY: {
    methodId: 0,
    methodText: 'Cash',
  },
  WITH_CARD: {
    methodId: 2,
    methodText: 'Card',
  },
  // PAYPAL: {
  //   methodId: 3,
  //   methodText: 'Paypal',
  // },
};
