import React from 'react';
import shared from 'app/shared';
import { Redirect } from 'react-router-dom';
import texts from '../statics/texts';
import PageList from './buttons/pageButtonList';
import { hrStyle /* MainLinkWrapper */ } from '../styles/styles';
import { connect } from 'react-redux';
import { getFacebookPages, setActivePage } from 'actions/facebookAction';
import { getUserDetails } from 'actions/userActions';

const {
  views: {
    MainView,
    Hr,
    Button: { MainButton },
    Text: {
      Paragraph: { MainParagraph },
    },
  },
} = shared;

const { title, subtitle, paragraph, btnText } = texts;

class FacebookPage extends React.Component {
  state = {
    getFBStatus: '',
    getUserStatus: '',
  };

  setGetFBStatus = (status) => {
    this.setState({ getFBStatus: status });
  };

  setGetUserStatus = (status) => {
    this.setState({ getUserStatus: status });
  };

  componentWillMount() {
    this.getPages();
  }

  componentWillUnmount() {}

  getPages = () => {
    this.props.getFacebookPages(this.setGetFBStatus);
    this.props.getUserDetails(this.setGetUserStatus);
  };

  createNewFacebookPage = () => {
    window.open('https://www.facebook.com/pages/create/', '_blank');
  };

  render() {
    return (
      <MainView title={title} subtitle={subtitle}>
        <div style={{ marginTop: '3.5%' }}>
          <PageList
            facebookPages={this.props.facebookPages}
            setActivePage={this.props.setActivePage}
          />

          {/* <MainLinkWrapper>
                Add New Page
              </MainLinkWrapper> */}
        </div>

        <Hr style={hrStyle} text={'Or'} />

        <MainParagraph style={{ width: '65%', fontSize: 14, letterSpacing: 'normal' }}>
          {paragraph}
        </MainParagraph>

        {this.props.facebookPages.data.length > 0 ? (
          <div style={{ marginTop: '3.5%' }}>
            <MainButton onClick={this.createNewFacebookPage} text={btnText} />
          </div>
        ) : (
          <div style={{ marginTop: '3.5%' }}>
            <MainButton
              text={'Log in with another account'}
              onClick={() => {
                localStorage.clear();
                window.location.reload();
              }}
            />
          </div>
        )}

        {this.props.activePage != null && this.props.activePage.id != null ? (
          <Redirect to={'/dashboard'} />
        ) : null}
      </MainView>
    );
  }
}

FacebookPage.path = '/facebook';

const mapStateToProps = (state) => ({
  facebookPages: state.facebookPages,
  activePage: state.facebookPages.activePage,
});

export default connect(mapStateToProps, {
  getFacebookPages,
  getUserDetails,
  setActivePage,
})(FacebookPage);

export { FacebookPage };
