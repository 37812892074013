import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import admin from 'app/admin';
import dashboard from 'app/dashboard';
import orders from 'app/orders';
import clients from 'app/clients';
import products from 'app/products';
import categories from 'app/categories';
import shared from 'app/shared';
import chatBot from 'app/chatbot';
import identity from 'app/identity';
import facebook from 'app/facebook';
import stripe from 'app/stripe';
import marketing from 'app/marketing';
import finished from 'app/finished';
import settings from 'app/settings';
import upgrade from 'app/upgrade';
import notifications from 'app/notification';
import banner from 'app/banner';
import guestCheckout from 'app/guestCheckout';
import HelpCenter from 'app/helpCenter/views/helpCenterPanel';
import NewCampaignPage from 'app/marketing/views/newCampaign.page';
import auth from 'app/auth';
import shoppingGuest from 'app/shoppingGuest';
import shopping from 'app/shopping';
import aggregator from 'app/aggregator';
import { isBartender, isAdmin } from 'auth';
import { isMarketplaceDomain } from 'app/shared/helpers/aggregator';
import bill from 'app/finalBill';
import impersonate from 'app/impersonate';

const {
  views: {
    AdminDashboardPage,
    AdminClientsPage,
    AdminViewClientPage,
    AdminDetailsPage,
    AdminHelpCenterPage,
    AdminMessengerPage,
    AdminUsersPage,
  },
} = admin;

const {
  views: { DashboardPage },
} = dashboard;

const {
  views: { SettingsPage },
} = settings;

const {
  views: { FacebookPage },
} = facebook;

const {
  views: { OrdersPage, EditOrder, AddOrder },
} = orders;

const {
  views: { ClientsPage, EditClient },
} = clients;

const {
  views: { ProductsPage, EditProduct },
} = products;


const {
  views: { BannerPage },
} = banner;

const {
  views: { CategoriesPage, EditCategory },
} = categories;

const {
  views: { AdminLayout },
} = shared;

const {
  views: { CentralAdminLayout },
} = shared;

const {
  views: { ChatBotPage },
} = chatBot;

const {
  hoc: { ProtectedRoute, GuestRoute },
} = identity;

const {
  views: { StripeConfig },
} = stripe;

const {
  views: { MarketingPage },
} = marketing;

const {
  views: { Restrict: UpgradeRestrict },
} = upgrade;

const { views: Finished } = finished;

const {
  views: { NotificationsPage },
} = notifications;

const {
  views: { GuestCheckoutPage },
} = guestCheckout;

const {
  views: { SignInPage, SignUpPage, ForgotPassPage, ResetPassPage, ActivateAccountPage },
} = auth;

const {
  views: { GuestShoppingPage },
} = shoppingGuest;

const {
  views: { OnlineShoppingPage },
} = shopping;

const {
  views: { AggregatorPage },
} = aggregator;

const {
  views: { FinalBill },
} = bill;

const {
  views: { Impersonate },
} = impersonate;

export default () =>
  isMarketplaceDomain() ? (
    <Switch>
      <Route exact path={FinalBill.path} component={FinalBill} />
      <Route exact path={AggregatorPage.path} component={AggregatorPage} />
    </Switch>
  ) : (
    <div>
      <Route
        render={(info) => {
          console.log(info.location.pathname);
        }}
      />
      <Switch>
        <Route exact path={FinalBill.path} component={FinalBill} />
        <Route exact path={OnlineShoppingPage.path} component={OnlineShoppingPage} />
        <Route exact path={GuestShoppingPage.path} component={GuestShoppingPage} />
        <Route
          exact
          path={GuestCheckoutPage.path + '/:checkout_id'}
          component={GuestCheckoutPage}
        />
        <Route exact path={Impersonate.path} component={Impersonate} />
        <Route
          render={() => (
            <>
              <GuestRoute
                guestRender={() => (
                  <Switch>
                    {/* TODO: aggregator will be here */}
                    <Redirect exact from='/' to={SignInPage.path} />
                    <Route exact path={SignInPage.path} component={SignInPage} />
                    <Route exact path={SignUpPage.path} component={SignUpPage} />
                    <Route exact path={ForgotPassPage.path} component={ForgotPassPage} />
                    <Route exact path={ResetPassPage.path} component={ResetPassPage} />
                    <Route exact path={ActivateAccountPage.path} component={ActivateAccountPage} />
                    <Redirect from='/admin' to={SignInPage.path} />
                    <Redirect from='/dashboard' to={SignInPage.path} />
                    <Route
                      exact
                      path={OnlineShoppingPage.global_path}
                      component={OnlineShoppingPage}
                    />
                  </Switch>
                )}
              />
              <ProtectedRoute
                path={'/'}
                protectedRender={({ location }) => {
                  return (
                    <>
                      <ToastContainer
                        position='top-center'
                        newestOnTop={true}
                        pauseOnFocusLoss={true}
                        transition={Slide}
                      />
                      <Switch>
                        <Route path={FacebookPage.path} component={FacebookPage} />
                        <Route path={Finished.path} component={Finished} />

                        <Route
                          path='/admin'
                          render={() => (
                            <CentralAdminLayout location={location}>
                              <HelpCenter />
                              <Switch>
                                <Route
                                  path={AdminDashboardPage.path}
                                  component={AdminDashboardPage}
                                />
                                <Route
                                  exact
                                  path={AdminDetailsPage.path + '/:id'}
                                  component={AdminDetailsPage}
                                />
                                <Route
                                  exact
                                  path={AdminViewClientPage.path + '/:id'}
                                  component={AdminViewClientPage}
                                />
                                <Route path={AdminClientsPage.path} component={AdminClientsPage} />
                                <Route
                                  path={AdminHelpCenterPage.path}
                                  component={AdminHelpCenterPage}
                                />
                                <Route
                                  path={AdminMessengerPage.path}
                                  component={AdminMessengerPage}
                                />
                                <Route path={AdminUsersPage.path} component={AdminUsersPage} />
                                <Route
                                  path='/admin'
                                  render={() => <Redirect to={AdminDashboardPage.path} />}
                                />
                              </Switch>
                            </CentralAdminLayout>
                          )}
                        />

                        {isBartender() ? (
                          <Route
                            render={() => (
                              <AdminLayout isBartender={true} location={location}>
                                <HelpCenter />
                                <Switch>
                                  <Redirect exact from='/' to={OrdersPage.path} />
                                  <Route path={UpgradeRestrict.path} component={UpgradeRestrict} />
                                  <Route path={OrdersPage.path} component={OrdersPage} />
                                  <Route path={AddOrder.path} component={AddOrder} />
                                  <Route path={EditOrder.path} component={EditOrder} />
                                  <Route path={ClientsPage.path} component={ClientsPage} />
                                  <Route path={EditClient.path} component={EditClient} />
                                  <Route path={ProductsPage.path} component={ProductsPage} />
                                  <Route path={EditProduct.path} component={EditProduct} />
                                  <Route path={CategoriesPage.path} component={CategoriesPage} />
                                  <Route path={EditCategory.path} component={EditCategory} />
                                  <Route
                                    path={NotificationsPage.path}
                                    component={NotificationsPage}
                                  />

                                  <Route
                                    path='/admin'
                                    render={() => {
                                      // alert('There was no match: ' + JSON.stringify(location));
                                      return <Redirect to={AdminDashboardPage.path} />;
                                    }}
                                  />
                                  <Route
                                    path='/dashboard'
                                    render={() => {
                                      // alert('There was no match: ' + JSON.stringify(location));
                                      return <Redirect to={OrdersPage.path} />;
                                    }}
                                  />

                                  <Redirect
                                    exact
                                    from={OnlineShoppingPage.global_path}
                                    to={OnlineShoppingPage.path}
                                  />
                                </Switch>
                              </AdminLayout>
                            )}
                          />
                        ) : (
                          <Route
                            render={() => (
                              <AdminLayout isAdmin={isAdmin()} location={location}>
                                <HelpCenter />
                                <Switch>
                                  <Redirect exact from='/' to={DashboardPage.path} />
                                  <Route path={UpgradeRestrict.path} component={UpgradeRestrict} />
                                  <Route path={StripeConfig.path} component={StripeConfig} />
                                  <Route
                                    exact
                                    path={DashboardPage.path}
                                    component={DashboardPage}
                                  />
                                  <Route
                                    path={MarketingPage.path + '/campaigns/:id'}
                                    component={NewCampaignPage}
                                  />
                                  <Route
                                    path={MarketingPage.path + '/:tab?'}
                                    component={MarketingPage}
                                  />
                                  <Route path={OrdersPage.path} component={OrdersPage} />
                                  <Route path={AddOrder.path} component={AddOrder} />
                                  <Route path={EditOrder.path} component={EditOrder} />
                                  <Route path={SettingsPage.path} component={SettingsPage} />
                                  <Route path={ClientsPage.path} component={ClientsPage} />
                                  <Route path={EditClient.path} component={EditClient} />
                                  <Route path={ProductsPage.path} component={ProductsPage} />
                                  <Route path={EditProduct.path} component={EditProduct} />
                                  <Route path={CategoriesPage.path} component={CategoriesPage} />
                                  <Route path={EditCategory.path} component={EditCategory} />
                                  <Route path={ChatBotPage.path} component={ChatBotPage} />
                                  <Route path={BannerPage.path} component={BannerPage} />
                                  <Route
                                    path={NotificationsPage.path}
                                    component={NotificationsPage}
                                  />

                                  <Route
                                    path='/admin'
                                    render={() => {
                                      // alert('There was no match: ' + JSON.stringify(location));
                                      return <Redirect to={AdminDashboardPage.path} />;
                                    }}
                                  />
                                  <Route
                                    path='/dashboard'
                                    render={() => {
                                      // alert('There was no match: ' + JSON.stringify(location));
                                      return <Redirect to={DashboardPage.path} />;
                                    }}
                                  />
                                  <Redirect
                                    exact
                                    from={OnlineShoppingPage.global_path}
                                    to={OnlineShoppingPage.path}
                                  />
                                </Switch>
                              </AdminLayout>
                            )}
                          />
                        )}
                      </Switch>
                    </>
                  );
                }}
              />
            </>
          )}
        />
      </Switch>
    </div>
  );
