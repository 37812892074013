import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import shared from 'app/shared';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { addPageUser } from '../../../actions/pageActions';
import userRoles from 'app/shared/helpers/userRoles';
import Select from 'react-select';

import addUserIllustration from '../../../assets/images/addUserIllustration.svg';

const {
  views: { Modal, AddItemPopup, Button, Input, Icons, Alert },
  helpers: { colors },
} = shared;

const AddItemPopupWrapper = styled.div`
  width: 450px;
`;

const WarningWrapper = styled.div`
  width: 100%;
  display: flex;
  background: rgba(255, 152, 0, 0.2);
  color: #ff9800;
  margin-bottom: 10px;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  align-items: center;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 1px 5px;
  margin-top: 10px;
`;

const RoleSelect = styled(Select)`
  font-family: Inter;
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;

  & .role__control {
    border-color: #e1e6ec;
  }

  & .role__single-value {
    color: #434343;
    font-weight: normal;
  }
`;

const LabelWrapper = styled.div`
  padding-left: 10px;
`;

const Label = styled.label`
  color: ${colors.textColor};
  font-size: 12px;
  line-height: 23px;
  font-weight: 500;
`;

const Separator = styled.hr`
  border: 0;
  border-top: 1px solid #e1e6ec;
`;

const userRoleOptions = userRoles.map((x) => ({ value: x.id, label: x.name }));
const userRoleOptionsNoMaster = userRoleOptions.filter((x) => x.label !== 'Master');
const getUserRoleOption = (role) => userRoleOptions.find((x) => x.value === role);

const AddPageUserPopup = (props) => {
  const [postStatus, setPostStatus] = useState('');
  const { toggle, show = false } = props;

  const changePostStatus = (resetForm) => (status) => {
    setPostStatus(status);
    if (status === 'OK') {
      resetForm();
      toggle();
    }
  };

  const postUser = (data, name, resetForm) => {
    data.pageId = JSON.parse(localStorage.getItem('Page'));
    props.addPageUser(data, name, changePostStatus(resetForm));
  };

  useEffect(() => {
    let updateStatusTimer = setTimeout(() => setPostStatus(null), 5000);
    return () => {
      clearTimeout(updateStatusTimer);
    };
  }, [postStatus]);

  const restaurantName = JSON.parse(localStorage.getItem('Pages'))[0].shortPageName;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        id: props.userDetails ? props.userDetails.id : '',
        name: props.userDetails ? props.userDetails.name : '',
        email: props.userDetails ? props.userDetails.email : '',
        password: props.userDetails ? props.userDetails.password : '',
        confirmPassword: props.userDetails ? props.userDetails.confirmPassword : '',
        role: props.userDetails
          ? props.userDetails.role
          : userRoles.find((x) => x.name === 'Waiter').id,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Full name is required'),
        email: Yup.string().required('Email is required'),
        password: Yup.string().required('Password is required'),
        confirmPassword: Yup.string()
          .required('Re-type password is required')
          .oneOf([Yup.ref('password'), null], 'Passwords must match'),
      })}
      onSubmit={(values, { resetForm }) => {
        const name = values.name.concat('.', restaurantName);
        postUser(values, name, resetForm);
      }}
    >
      {(innerProps) => {
        const { touched, errors, values, setFieldValue, handleChange, handleSubmit } = innerProps;

        return (
          <Modal toggle={toggle} show={show}>
            <AddItemPopupWrapper>
              <AddItemPopup
                title={props.userDetails ? 'Edit User' : 'Add New User'}
                actions={[
                  () => <Button.Button color={colors.blue} text={'Save'} onClick={handleSubmit} />,
                  () => (
                    <Button.WhiteButton
                      text={'Cancel'}
                      onClick={() => {
                        props.toggle();
                      }}
                    />
                  ),
                ]}
                illustration={addUserIllustration}
              >
                <Alert show={postStatus === 'OK'} message="Update successful!" />
                <Alert
                  type="error"
                  show={postStatus === 'ERROR'}
                  message={`Error ${values.ID ? 'editing' : 'adding'} the user!`}
                />
                <Alert
                  type="error"
                  show={postStatus && postStatus !== 'ERROR' && postStatus !== 'OK'}
                  message={postStatus}
                />
                <AddItemPopup.InputRow style={{ margin: '16px 0' }}>
                  <LabelWrapper>
                    <Label>Role</Label>
                  </LabelWrapper>
                  <RoleSelect
                    classNamePrefix="role"
                    className="selectWrapper"
                    labelText="Role"
                    value={getUserRoleOption(values.role)}
                    isSearchable={true}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      setFieldValue('role', selectedOption.value);
                    }}
                    options={userRoleOptionsNoMaster}
                  />
                </AddItemPopup.InputRow>
                <Separator />
                <AddItemPopup.InputRow style={{ margin: '16px 0' }}>
                  <Input
                    id={'name'}
                    name={'name'}
                    labelText={'Full Name'}
                    onChange={handleChange}
                    value={values.name}
                    autoComplete="off"
                  />
                  {errors.name && touched.name ? (
                    <WarningWrapper>
                      <Icons.WarningIcon
                        fill={colors.yellow}
                        style={{ marginRight: 10 }}
                        height={16}
                      />
                      {errors.name}
                    </WarningWrapper>
                  ) : null}
                </AddItemPopup.InputRow>
                <AddItemPopup.InputRow style={{ margin: '16px 0' }}>
                  <Input
                    id={'email'}
                    name={'email'}
                    labelText={'Email'}
                    onChange={handleChange}
                    value={values.email}
                    autoComplete="off"
                  />
                  {errors.email && touched.email ? (
                    <WarningWrapper>
                      <Icons.WarningIcon
                        fill={colors.yellow}
                        style={{ marginRight: 10 }}
                        height={16}
                      />
                      {errors.email}
                    </WarningWrapper>
                  ) : null}
                </AddItemPopup.InputRow>
                <AddItemPopup.InputRow style={{ margin: '16px 0' }}>
                  <Input
                    id={'password'}
                    name={'password'}
                    labelText={'Password'}
                    onChange={handleChange}
                    value={values.password}
                    autoComplete="off"
                  />
                  {errors.password && touched.password ? (
                    <WarningWrapper>
                      <Icons.WarningIcon
                        fill={colors.yellow}
                        style={{ marginRight: 10 }}
                        height={16}
                      />
                      {errors.password}
                    </WarningWrapper>
                  ) : null}
                </AddItemPopup.InputRow>
                <AddItemPopup.InputRow style={{ margin: '16px 0' }}>
                  <Input
                    id={'confirmPassword'}
                    name={'confirmPassword'}
                    labelText={'Re-Type Password'}
                    onChange={handleChange}
                    value={values.confirmPassword}
                    autoComplete="off"
                  />
                  {errors.confirmPassword && touched.confirmPassword ? (
                    <WarningWrapper>
                      <Icons.WarningIcon
                        fill={colors.yellow}
                        style={{ marginRight: 10 }}
                        height={16}
                      />
                      {errors.confirmPassword}
                    </WarningWrapper>
                  ) : null}
                </AddItemPopup.InputRow>
              </AddItemPopup>
            </AddItemPopupWrapper>
          </Modal>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => ({ categories: state.categories });

export default connect(mapStateToProps, { addPageUser })(AddPageUserPopup);
