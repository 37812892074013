import React, { useState, useEffect, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import useQueryParams from 'hooks/useQueryParams';

import {
  initTableOrderShopping,
  addProductToOrder,
  removeProductFromOrder,
  updateComboProduct,
  setOrderDescription,
  saveTableOrder,
  startNewOrder,
  callWaiter,
  requestBill,
  getBill,
  getOrderById,
  api,
  checkNumberVerificationStatus,
  setClientInfo,
  getFirstProduct,
} from 'actions/shoppingActions';

import { BlackButton } from 'app/shared/views/buttons';
import ProductModal from 'app/shared/views/shopping/components/productComponent/ProductModal';
import VerifyPhoneNumber from 'app/shared/views/verifyPhoneNumber';
import OrderInfo from 'app/shared/views/shopping/components/orderDetails';

import ProductSearch from './components/ProductSearch';
import ShoppingHeader from './components/Header';
import ErrorMessage from 'app/shared/views/shopping/components/ErrorMessage';

import HorizontalScrollableSpace from 'app/shared/views/horizontalScrollableSpace';
import OrderStatus from './components/OrderStatus';
import WaiterReview from './components/waiterReview';
import GetBill from './components/getBill';
import OrderReview from './components/OrderReview';
import { ORDER_STATUS } from 'app/orders/constants';

import { useDebounce } from 'app/shared/helpers/hooks';
import ProductLayout from 'app/shared/views/shopping/components/productComponent';
import Categories from 'app/shared/views/shopping/components/Categories';
import { AutoSizer, WindowScroller } from 'react-virtualized';
import NotSubscribed from 'app/shared/views/shopping/components/NotSubscribed';
import OrderPreview from 'app/shared/views/shopping/components/OrderPreview';
import ClipLoader from 'app/shared/views/clipLoader';
import Url from 'config/api';

const { ProductCard, ProductList } = ProductLayout;

export function totalProductQuantity(orderProducts) {
  if (orderProducts.length === 0) return 0;
  console.log(orderProducts.reduce((prevProd, currProd) => prevProd.qty + currProd.qty, 0));
}

const GuestShoppingPage = ({
                             match,
                             order,
                             initTableOrderShopping,
                             addProductToOrder,
                             removeProductFromOrder,
                             updateComboProduct,
                             setOrderDescription,
                             saveTableOrder,
                             startNewOrder,
                             callWaiter,
                             requestBill,
                             getBill,
                             getOrderById,
                             checkNumberVerificationStatus,
                             clientInfo,
                             setClientInfo,
                             getFirstProduct,
                           }) => {
  const [scrollableArea, setScrollableArea] = useState(null);
  const { tableCode } = match.params;
  const [showReview, setShowReview] = useState(false);
  const [bills, setBills] = useState(false);
  const [showOrderReview, setShowOrderReview] = useState(false);
  const [showOrderPreview, setShowOrderPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [extraCategories, setExtraCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [pageInfo, setPageInfo] = useState(null);
  const [iframeConfig, setIframeConfig] = useState({});
  const [tableInfo, setTableInfo] = useState(null);
  const [search, setSearch] = useState(null);
  const [orderCompleted, setOrderCompleted] = useState(false);
  const [error, setError] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 300);
  const [showPokModal, setShowPokModal] = useState(false);
  const [pokErrorModal, setPokErrorModal] = useState(false);
  const [connectionRef, setConnectionRef] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [confirmedPaymentMethod, setConfirmedPaymentMethod] = useState(0);
  const [pokUrl, setPokUrl] = useState('');
  const paymentMethod = useSelector((state) => state.shopping.paymentMethod);

  const [addProduct, setAddProduct] = useState(false);

  const [showVerifyNumber, setShowVerifyNumber] = useState(false);

  const [hideBanner, setHideBanner] = useState(false);
  const [portraitProductImage, setPortraitProductImage] = useState(false);

  const [disableTableOrder, setDisableTableOrder] = useState(false);
  const [isInternalOrder, setIsInternalOrder] = useState(false);

  const isTableOrderActive = isInternalOrder || !disableTableOrder;

  const queryParams = useQueryParams();
  const preselectedProduct = queryParams.get('preselect');

  useEffect(() => {
    window.open(`${Url.tableOrderRedirectUrl}/qr/${tableCode}`, '_self');
  }, [tableCode]);

  useEffect(() => {
    if (!preselectedProduct) return;
    // search and get first product matching name
    getFirstProduct(preselectedProduct, (err, product) => {
      if (!err) {
        setAddProduct({ ...product });
      }
    });
  }, [preselectedProduct, getFirstProduct, pageInfo]);

  useEffect(() => {
    if (debouncedSearchTerm == null) return;
    api.get('/Categories/Search', { params: { search: debouncedSearchTerm } }).then((res) => {
      const categories = res.data;
      setCategories(categories);
    });
  }, [debouncedSearchTerm]);

  useEffect(() => {
    match.params.hideBanner === 'true' ? setHideBanner(true) : setHideBanner(false);
  }, [match.params.hideBanner]);

  useEffect(() => {
    match.params.internalOrder === 'true' ? setIsInternalOrder(true) : setIsInternalOrder(false);
  }, [match.params.internalOrder]);

  const waitForConfirmation = useCallback(
    (orderId) => {
      const timer = setInterval(() => {
        getOrderById(orderId, (err, data) => {
          if (err) {
            return;
          }

          const status = ORDER_STATUS[data.orderStatus];
          if (status !== 'Pending') {
            clearInterval(timer);
          }
        });
      }, 5000);
    },
    [getOrderById],
  );

  const onSave = useCallback(async () => {
    order.total = order.totalValue;
    if (order.orderProducts.length === 0) {
      setError('Add products before placing an order!');
      setIsValid(false);
      return;
    }

    if (!pageInfo.isOpen) {
      setError('Restaurant has yet to open!');
      return;
    }

    if (!showOrderPreview && !showOrderReview) {
      setShowOrderPreview(true);
      return;
    }

    if (!showOrderReview) {
      setShowOrderReview(true);
      return;
    }

    if (error) {
      return;
    }

    setIsLoading(true);


    if (confirmedPaymentMethod === 3 && order.pokPaymentConfirmed === false) {
      setShowPokModal(true);
    } else {
      saveTableOrder(order, tableInfo.id, pageInfo.id, paymentMethod.methodId, isInternalOrder, (err, data) => {
        if (!err) {
          setPokUrl(data.confirmationUrl);
          setOrderCompleted(true);
        } else {
          toast.error(err);
        }
        setIsLoading(false);
      });
    }
  }, [
    order,
    showOrderReview,
    showOrderPreview,
    paymentMethod,
    error,
    tableInfo,
    confirmedPaymentMethod,
    saveTableOrder,
    isInternalOrder,
    pageInfo,
  ]);

  const onCallWaiter = useCallback(() => {
    callWaiter(pageInfo.id, tableInfo.id);
  }, [pageInfo, tableInfo, callWaiter]);

  const onGetBill = useCallback(() => {
    requestBill(pageInfo.id, tableInfo.id);
    getBill(setBills);
  }, [pageInfo, tableInfo, requestBill, getBill]);

  useEffect(() => {
    order?.id && waitForConfirmation(order.id);
    order?.id && setOrderCompleted(true);
  }, [waitForConfirmation, order.id]);

  useEffect(() => {
    setIsLoading(true);
    initTableOrderShopping(
      tableCode,
      (
        err,
        { jwtInfo, pageInfo, tableInfo, categories, extraCategories, lastOrder, iframeConfig } = {},
      ) => {
        if (err) {
          console.log(err); // something went wrong
          return;
        } else {
          setPageInfo({ ...pageInfo, isList: true });
          setIframeConfig(iframeConfig);
          setTableInfo(tableInfo);
          setCategories(categories);
          setExtraCategories(extraCategories);
          if (categories.length) {
            setSelectedCategory(categories[0]);
          }
          if (ORDER_STATUS[lastOrder.orderStatus] === 'Waiting for Confirmation') {
            setOrderCompleted(true);
          }

          // override param
          if (iframeConfig.hideBanner) {
            setHideBanner(true);
          }
          if (iframeConfig.portraitProductImage) {
            setPortraitProductImage(true);
          }
          if (
            iframeConfig.disableTableOrder ||
            !pageInfo.pageService.find((x) => x.name === 'TableOrder' && x.isActive === true)
          ) {
            setDisableTableOrder(true);
          }
        }
        setIsLoading(false);
      },
    );
  }, [initTableOrderShopping, tableCode]);


  useEffect(() => {
    setSelectedCategory(categories[0]);
  }, [categories]);

  useEffect(() => {
    if (order.description.length < 126 || order.description === null) {
      setError(false);
    } else {
      setError('Max 125 characters allowed!');
    }
  }, [order]);

  useEffect(() => {
    if (!scrollableArea) return;
    if (hideBanner) {
      scrollableArea.scrollTo(0, 0);
    } else if (scrollableArea.scrollTop > 196) {
      scrollableArea.scrollTo(0, 196);
    }
  }, [selectedCategory, hideBanner, scrollableArea]);

  if (pageInfo && !pageInfo.isSubscribed) {
    return (
      <GuestShoppingWrapper>
        <NotSubscribed />
      </GuestShoppingWrapper>
    );
  }

  if (isLoading) {
    return (
      <div style={{ height: '100vh' }}>
        <ClipLoader size={80} style={{ height: '100vh', width: '100vw' }} />
      </div>
    );
  }

  if (
    (orderCompleted && !bills) ||
    ORDER_STATUS[order?.orderStatus] === 'Pending'
  ) {
    return (
      <OrderStatus
        order={order}
        tableInfo={tableInfo}
        pageInfo={pageInfo}
        callWaiter={onCallWaiter}
        getBill={onGetBill}
        isInternalOrder={isInternalOrder}
        enableWaiterFunctionalities={iframeConfig.enableWaiterFunctionalities}
        startNewOrder={() => {
          startNewOrder();
          // setOrderCompleted(false);
        }}
      />
    );
  }

  if (bills) {
    return (
      <GetBill
        tableInfo={tableInfo}
        pageInfo={pageInfo}
        bills={bills}
        startNewOrder={() => {
          startNewOrder();
        }}
      />
    );
  }

  return (
    <GuestShoppingWrapper>
      <GuestShoppingScrollableWrapper
        hasProducts={!!order.totalValue}
        ref={(ref) => setScrollableArea(ref)}
        style={{ position: 'relative' }}
      >
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          pauseOnHover
        />
        <VerifyPhoneNumber
          show={showVerifyNumber}
          toggle={() => setShowVerifyNumber(false)}
          setClientPhone={(phone) => {
            setClientInfo({
              ...clientInfo,
              phone: phone,
            });
          }}
          onConfirm={onSave}
        />
        <WaiterReview
          showReview={showReview}
          setShowReview={setShowReview}
          showTextSubmit={true}
          tableId={tableInfo.id}
        />
        {pageInfo ? (
          <Helmet
            defer={false}
            title={pageInfo.pageName}
            meta={[
              { name: pageInfo.pageName, content: pageInfo.pageName },
              {
                name: 'viewport',
                content:
                  'width=device-width,minimal-ui,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=0',
              },
              { property: 'title', content: pageInfo.pageName },
              { property: 'og:title', content: pageInfo.pageName },
              { property: 'og:image', content: pageInfo.bannerURL },
              { property: 'description', content: pageInfo.pageName },
              { property: 'og:description', content: pageInfo.pageName },
            ]}
          >
            {iframeConfig?.customScript !== undefined && (
              <script>{iframeConfig?.customScript}</script>
            )}
          </Helmet>
        ) : null}
        {!hideBanner && (
          <ShoppingHeader
            pageInfo={pageInfo}
            tableInfo={tableInfo}
            callWaiter={onCallWaiter}
            getBill={onGetBill}
            setShowReview={() => setShowReview(true)}
            enableWaiterFunctionalities={iframeConfig.enableWaiterFunctionalities}
          />
        )}
        <div
          style={{
            position: 'sticky',
            background: '#fff',
            zIndex: 11,
            top: 0,
          }}
        >
          <div
            style={{
              padding: pageInfo.isList ? 0 : '20px',
            }}
          >
            <ProductSearch
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              style={
                pageInfo.isList
                  ? {
                    borderRadius: 7,
                    width: '95%',
                    marginLeft: '2.5%',
                    height: 50,
                    borderBottom: '1px solid #F9FAFC',
                  }
                  : {}
              }
            />
          </div>
          <Categories
            selectedCategory={selectedCategory}
            categories={categories}
            order={order}
            setSelectedCategory={setSelectedCategory}
          />
        </div>

        {selectedCategory &&
          (pageInfo.isList ? (
            <WindowScroller scrollElement={scrollableArea ?? window}>
              {(windowScrollerProps) => (
                <AutoSizer disableHeight={true}>
                  {({ width }) => (
                    <ProductList
                      key={`${selectedCategory.id}-${search}`}
                      category={selectedCategory}
                      categories={categories}
                      pageInfo={pageInfo}
                      setAddProduct={setAddProduct}
                      order={order}
                      search={search}
                      windowScrollerProps={{ ...windowScrollerProps, width }}
                      canOrder={isTableOrderActive}
                    />
                  )}
                </AutoSizer>
              )}
            </WindowScroller>
          ) : (
            <HorizontalScrollableSpace gap={10} key={selectedCategory.id}>
              {selectedCategory.magProducts.map((product) => (
                <ProductCard
                  key={product.id}
                  product={product}
                  productsInCart={((order, p) =>
                    order.orderProducts.reduce((acc, op) => {
                      op.id === p.id && acc.push(op);
                      return acc;
                    }, []))(order, product)}
                  categories={categories}
                  currency={pageInfo.currency}
                  description={product.description}
                  qty={((order, p) => {
                    const op = order.orderProducts.reduce((acc, op) => {
                      op.id === p.id && acc.push(op);
                      return acc;
                    }, []);
                    return op.length > 1 ? op.length : op[0]?.qty || 0;
                  })(order, product)}
                  addProduct={addProductToOrder}
                  removeProduct={removeProductFromOrder}
                  isCombo={product.isCombo}
                  setAddProduct={setAddProduct}
                />
              ))}
            </HorizontalScrollableSpace>
          ))}
        {!!addProduct && (
          <ProductModal
            key={addProduct?.id}
            show={!!addProduct}
            product={addProduct}
            orderProducts={order.orderProducts?.filter(
              (p) => p.categoryId === addProduct?.categoryId,
            )}
            pageInfo={pageInfo}
            toggle={() => setAddProduct(false)}
            categories={extraCategories}
            currency={pageInfo.currency}
            portraitProductImage={portraitProductImage}
            canOrder={isTableOrderActive}
          />
        )}
        {!pageInfo.isList && (
          <>
            <Divider />
            <GuestShoppingSection>
              <OrderInfo
                order={order}
                addProduct={addProductToOrder}
                removeProduct={removeProductFromOrder}
                currency={pageInfo.currency}
                setAddProduct={setAddProduct}
                magProducts={selectedCategory?.magProducts}
              />
            </GuestShoppingSection>
          </>
        )}
        <div
          style={{
            width: '100%',
            bottom: order.totalValue ? '1rem' : '-10vh',
            position: 'sticky',
            display: 'flex',
            flexDirection: 'column',
            transition: 'bottom 0.3s linear',
            zIndex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0.5rem 0',
          }}
        >
          <ErrorMessage message={error} style={{ padding: '10px 30px' }} />
          <BlackButton
            onClick={() => {
              onSave();
            }}
            style={{
              minHeight: '60px',
              height: 60,
              fontSize: 16,
              fontWeight: 'bold',
              color: '#FFFFFF',
              borderRadius: '7px',
              display: order.totalValue ? 'flex' : 'none',
              width: '95%',
            }}
            text={
              <TotalUL>
                <ButtonText>
                  <ButtonInfo>
                    <AllQty>
                      {order.orderProducts.legth !== 0 &&
                        order.orderProducts.reduce(
                          (accumulator, currProd) => accumulator + currProd.qty,
                          0,
                        )}
                    </AllQty>
                    <p style={{ padding: '3px' }}>View Order</p>
                  </ButtonInfo>
                  <p style={{ marginRight: '30px', padding: '3px' }}>{order.totalValue}</p>
                </ButtonText>
              </TotalUL>
            }
          />
        </div>
      </GuestShoppingScrollableWrapper>

      {showOrderPreview && (
        <OrderPreview
          show={order.orderProducts}
          pageInfo={pageInfo}
          setShow={setShowOrderPreview}
          onProceed={onSave}
          // internalOrder={internalOrder}
        />
      )}
      {showOrderReview && (
        <OrderReview
          pokUrl={pokUrl}
          setShow={setShowOrderReview}
          order={order}
          pageInfo={pageInfo}
          setOrderDescription={setOrderDescription}
          currency={pageInfo.currency}
          collectClientName={iframeConfig.collectClientName}
          collectPhoneOnTable={iframeConfig.collectPhoneOnTable}
          error={error}
          setError={setError}
          connectionRef={connectionRef}
          internalOrder={isInternalOrder}
          onSave={onSave}
          setShowPokModal={setShowPokModal}
          showPokModal={showPokModal}
          isValid={isValid}
          setConnectionRef={setConnectionRef}
          pokErrorModal={pokErrorModal}
          setPokErrorModal={setPokErrorModal}
          confirmedPaymentMethod={confirmedPaymentMethod}
          setConfirmedPaymentMethod={setConfirmedPaymentMethod}
          paymentMethod={paymentMethod}
          hideDetailsInput
        />
      )}
    </GuestShoppingWrapper>
  );
};

const ButtonText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  width: 100%;
`;

const ButtonInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
`;

const AllQty = styled.p`
  border: 2px solid white;
  border-radius: 50%;
  padding: 0.25rem 0.4rem;
  margin-right: 5px;
`;

const TotalUL = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -1px;
  width: 100%;
`;

const GuestShoppingWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  max-width: 502px;
  margin: auto;
  height: calc(var(--vh, 1vh) * 100);
  @media (min-width: 501px) {
    border: 1px #e8e9f3 solid;
  }
`;

const GuestShoppingScrollableWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: calc(var(--vh, 1vh) * 100${({ hasProducts }) => hasProducts && '- 60px'});
  transition: height 0.5s linear;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
`;

const Divider = styled.div`
  background-color: #f9fafc;
  min-height: 2px;
  width: 100%;
`;

const GuestShoppingSection = styled.div`
  height: 100%;
  margin: 20px;
`;

export default connect(
  (state) => ({
    order: state.shopping.order,
    clientInfo: state.shopping.client,
  }),
  {
    initTableOrderShopping,
    addProductToOrder,
    removeProductFromOrder,
    updateComboProduct,
    setOrderDescription,
    saveTableOrder,
    startNewOrder,
    callWaiter,
    requestBill,
    getBill,
    getOrderById,
    checkNumberVerificationStatus,
    setClientInfo,
    getFirstProduct,
  },
)(GuestShoppingPage);
