import React from 'react';
import ProductCategory from './ProductCategory';
import HorizontalScrollableSpace from 'app/shared/views/horizontalScrollableSpace';

export const Categories = React.memo(
  ({ selectedCategory, categories, order, setSelectedCategory }) => (
    <HorizontalScrollableSpace
      key={'filteredCategories'}
      gap={10}
      style={{
        paddingTop: 20,
        height: 70,
        paddingBottom: 20,
      }}
    >
      {categories.length ? (
        categories
          .filter((category) => category.categoryType === 0)
          .map((category) => (
            <ProductCategory
              key={category.id}
              name={category.name}
              isSelected={category.id === selectedCategory?.id}
              count={((order, c) => {
                return (order.orderProducts || [])
                  .filter((p) => p.categoryId === c.id)
                  .reduce((count, p) => count + p.qty, 0);
              })(order, category)}
              onClick={() => {
                setSelectedCategory(category);
              }}
            />
          ))
      ) : (
        <div style={{ width: '100%', textAlign: 'center' }}>No Products</div>
      )}
    </HorizontalScrollableSpace>
  ),
  (a, b) =>
    a.selectedCategory === b.selectedCategory &&
    a.categories === b.categories &&
    a.order === b.order,
);

export default Categories;
