import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import colors from 'app/shared/helpers/colors';
import icons from 'app/shared/views/icons';
import DotSpinner from 'app/shared/views/DotSpinner';

import moment from 'moment';

const BadgeIcon = styled.div`
  position: relative;
  padding-right: 8px;

  & > * {
    background-color: ${(props) => props.bg || colors.white};
  }
`;

const BadgeText = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Badge = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: 30px;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  padding: 4px;
`;

export const TableBadge = ({ text, style, ...props }) => {
  const styles = {
    background: '#F9FAFC',
    color: '#1D2036',
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: '13px',
    ...style,
  };

  return (
    <Badge style={styles} {...props}>
      <BadgeIcon bg={colors.blue}>
        <icons.Table width="10px" height="12px" />
      </BadgeIcon>
      <BadgeText>{text}</BadgeText>
    </Badge>
  );
};

export const RingBadge = ({ text, textLoading, style, onClick, ...props }) => {
  const [isRinging, setIsRinging] = useState(false);

  const styles = {
    background: '#FFD037',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '11px',
    lineHeight: '13px',
    ...style,
  };

  useEffect(() => {
    if (isRinging) {
      let t = setTimeout(() => {
        setIsRinging(false);
        clearTimeout(t);
      }, 5000);
    }
  }, [isRinging]);

  const handleClick = (e) => {
    setIsRinging(true);
    if (typeof onClick === 'function') {
      onClick(e);
    }
  };

  return (
    <Badge style={styles} onClick={handleClick} {...props}>
      <BadgeIcon>
        <icons.Ring width="10px" height="12px" fill={'#FFD037'} />
      </BadgeIcon>
      {!isRinging && <BadgeText>{text}</BadgeText>}
      {isRinging && <DotSpinner size={8} color="#fff" style={{ marginLeft: '12px' }} />}
    </Badge>
  );
};

export const GetBillBadge = ({ text, textLoading, style, onClick, ...props }) => {
  const [isRinging, setIsRinging] = useState(false);

  const styles = {
    background: '#ffffff',
    color: '#1d2036',
    fontWeight: 700,
    fontSize: '11px',
    lineHeight: '13px',
    ...style,
  };

  useEffect(() => {
    if (isRinging) {
      let t = setTimeout(() => {
        setIsRinging(false);
        clearTimeout(t);
      }, 5000);
    }
  }, [isRinging]);

  const handleClick = (e) => {
    setIsRinging(true);
    if (typeof onClick === 'function') {
      onClick(e);
    }
  };

  return (
    <Badge style={styles} onClick={handleClick} {...props}>
      <BadgeIcon bg={'#3185fc'}>
        <icons.Bill width="18px" height="18px" fill={'#ffffff'} />
      </BadgeIcon>
      {!isRinging && <BadgeText>{text}</BadgeText>}
      {isRinging && <DotSpinner size={8} color="#fff" style={{ marginLeft: '12px' }} />}
    </Badge>
  );
};

export const StatusBadge = ({ isOpen, openHours, style, ...props }) => {
  const today = moment().format('ddd').toLowerCase();
  const todaysOpeningHours = openHours.find((hours) => hours.day === today);
  // calculate opening time in utc than display opening time in local time
  const timeNow = moment.utc().format('HH:mm').toString();

  const badgeText = isOpen
    ? 'Open'
    : !todaysOpeningHours || !todaysOpeningHours.enabled
    ? 'Closed'
    : moment(timeNow, 'HH:mm').isBefore(moment(todaysOpeningHours.openingHour1, 'HH:mm'))
    ? todaysOpeningHours.openingHour1
      ? 'Opens at ' + moment.utc(todaysOpeningHours.openingHour1, 'HH:mm').local().format('HH:mm')
      : 'Closed'
    : todaysOpeningHours.openingHour2
    ? 'Opens at ' + moment.utc(todaysOpeningHours.openingHour2, 'HH:mm').local().format('HH:mm')
    : 'Closed';

  return (
    <div>
      <p
        style={{
          fontStyle: 'normal',
          fontWeight: '300',
          fontSize: '14px',
          lineHeight: '14px',
          paddingTop: '2px',
          color: '#748AA1',
          ...style,
        }}
      >
        {badgeText}
      </p>
    </div>
  );
};

export const StringTime = ({ openHours, style, ...props }) => {
  const today = moment().format('ddd').toLowerCase();
  const todaysOpeningHours = openHours.find((hours) => hours.day === today);

  function openHour(openHour) {
    const startHour = Number(openHour.openingHour1.substr(0, 2));
    if (startHour <= 11) {
      return 'AM';
    } else return 'PM';
  }

  function closeHour(closeHour) {
    let closingHour;

    if (closeHour.closingHour2 !== '') {
      closingHour = Number(closeHour.closingHour2.substr(0, 2));
    } else {
      closingHour = Number(closeHour.closingHour1.substr(0, 2));
    }
    if (closingHour <= 11) {
      return 'AM';
    } else {
      return 'PM';
    }
  }

  let stringTime;
  if (todaysOpeningHours !== undefined) {
    stringTime = `Open ${todaysOpeningHours.openingHour1}${openHour(todaysOpeningHours)} - ${
      todaysOpeningHours.closingHour1
    }${closeHour(todaysOpeningHours)}`;
  } else {
    stringTime = 'Not Opening Today';
  }

  return (
    <div>
      <p
        style={{
          fontStyle: 'normal',
          fontWeight: '300',
          fontSize: '14px',
          lineHeight: '14px',
          paddingLeft: '10px',
          paddingTop: '2px',
          color: '#748AA1',
        }}
      >
        {stringTime}
      </p>
    </div>
  );
};

export const TimeBadge = ({ text, style, ...props }) => {
  return (
    <div>
      <p
        style={{
          fontStyle: 'normal',
          fontWeight: '300',
          fontSize: '14px',
          lineHeight: '14px',
          paddingLeft: '10px',
          paddingTop: '2px',
          color: '#748AA1',
        }}
      >
        Delivery in {text}
      </p>
    </div>
  );
};

export const MinValueBadge = ({ text, style, ...props }) => {
  return (
    <div>
      <p
        style={{
          fontStyle: 'normal',
          fontWeight: '300',
          fontSize: '14px',
          lineHeight: '14px',
          paddingLeft: '10px',
          paddingTop: '2px',
          color: '#748AA1',
        }}
      >
        {text}
      </p>
    </div>
  );
};
