import React, { useRef, useEffect, useState, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Icons from '../../../shared/views/icons';
import {
  setClientInfo,
  setOrderTotal,
  setOrderValidVoucher,
  setOrderVoucherCode,
} from 'actions/shoppingActions';
import PaymentMethods from 'app/shared/views/shopping/components/PaymentMethod';
import { Button, Modal, Result } from 'antd';
import ErrorMessage from 'app/shared/views/shopping/components/ErrorMessage';
import { BlackButton } from 'app/shared/views/buttons';
import ApplyVoucherModal from 'app/shared/views/shopping/components/ApplyVoucherModal';
import { getAllVouchers, getClientPromotions } from 'actions/crudActions';
import crudTypes from 'config/crudTypes';

const TotalUL = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -1px;
  width: 100%;
`;

const AllQty = styled.p`
  border: 2px solid white;
  border-radius: 50%;
  padding: 0.25rem 0.4rem;
  margin-right: 5px;
`;

const ButtonText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  width: 100%;
`;

const ButtonInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
`;

const HeaderCol = styled.div`
  z-index: 999 !important;
  position: sticky !important;
  top: 0 !important;
  background-color: #ffffff !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  align-items: center !important;
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: #2a2e45;
  flex: 1;
  padding: 16px !important;
`;

const ProductName = styled.div`
  font-size: 12px;
  color: #1d2036;
  flex: 1;
  line-height: 20px;
`;

const ProductPrice = styled.div`
  font-size: 12px;
  color: black;
  flex: 1;
  font-weight: 600;
  text-align: right;

  &:hover {
    ${(props) => `${props.hover}`}
  }
`;

const InnerRowWrapper = styled.div`
  display: flex !important;
  flex-direction: column !important;
  background-color: #ffffff !important;
  position: relative !important;
  z-index: 999 !important;
  bottom: 0 !important;
  width: 100vw !important;
  max-width: 500px !important;
  margin-top: auto !important;
  overflow: hidden !important;
  height: 100vh;
`;

const stopEventBubbling = (e) => {
  e.stopPropagation();
};

const Options = styled.div`
  margin-top: 0.5rem;
`;

const OptionDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
  height 80%;
  margin-left: 12.3px;
  border-radius: 10px;
  margin-bottom: 1rem;
  background-color: #f9fafc;
  cursor: pointer;
  padding: 20px 7px 20px 7px;
  justify-content: space-between;
`;

const Body = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const OrderReview = (props) => {
  const {
    show,
    setShow,
    order,
    // setOrderDescription,
    // currency,
    // clientInfo,
    // setClientInfo,
    // collectClientName,
    // collectPhoneOnTable,
    error,
    // setError,
    // hideDetailsInput,
    onSave,
    pageInfo,
    setShowPokModal,
    showPokModal,
    pokErrorModal,
    setPokErrorModal,
    internalOrder,
    confirmedPaymentMethod,
    pokUrl,
    paymentMethod,
    setConfirmedPaymentMethod,
    isValid,
  } = props;
  const [showPaymentMethods, setShowPaymentMethods] = useState(false);
  const [applingVoucher, setApplingVoucher] = useState(false);
  const [promotionValue, setPromotionValue] = useState(0);
  const [clientPromotions, setClientPromotions] = useState([]);
  const pokData = useSelector((state) => state.pokSettings.data);
  const vouchers = useSelector((state) => state.vouchers);
  const validVoucher = useSelector((state) => state.shopping.order.validVoucher);
  const promotion = useSelector((state) => state.shopping.promotion);
  const clientInfo = useSelector((state) => state.shopping.client);
  const dispatch = useDispatch();

  const childRef = useRef(null);

  const displayPaymentMethods = () => {
    setShowPaymentMethods(true);
  };

  useEffect(() => dispatch(getAllVouchers(crudTypes.voucher, pageInfo.id)), [
    dispatch,
    pageInfo.id,
  ]);

  useEffect(() => {
    if (!childRef.current) return;
    childRef.current.ontouchstart = stopEventBubbling;
    childRef.current.ontouchmove = stopEventBubbling;
    childRef.current.ontouchend = stopEventBubbling;
  }, [show, childRef]);

  useEffect(() => {
    getClientPromotions(crudTypes.client, clientInfo.phone)
      .then(function (res) {
        setClientPromotions(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [clientInfo]);

  useEffect(
    useCallback(() => {
      dispatch(setOrderValidVoucher(null));
      dispatch(setOrderVoucherCode(''));
    }, [dispatch]),
    [],
  );

  const CheckRecurring = useCallback(() => {
    let isRecurring = false;
    let currentPromo;
    if (clientPromotions.length !== 0) {
      currentPromo = clientPromotions.filter((promo) => promo.promotionId === promotion.id);
      if (currentPromo.length === 0 && promotion !== null) {
        isRecurring = true;
      }
      if (!promotion.recurring && currentPromo.map((count) => count.promoCount) >= 1) {
        return isRecurring;
      }
      if (
        currentPromo.map((count) => count.promoCount) < promotion.recurringCount ||
        promotion.recurringCount === 0
      ) {
        isRecurring = true;
      }
    } else isRecurring = true;
    return isRecurring;
  }, [clientPromotions, promotion]);

  const Promotions = useCallback(
    (total) => {
      let promotionValue = 0;

      let orderCount = 0;
      order.orderProducts.map((order) => {
        return (orderCount += order.qty);
      });

      if (!promotion) {
        return null;
      } else if (total) {
        if (promotion.isActive === true) {
          if (promotion.promotionType === 0) {
            if (promotion.discountType === 0 && orderCount >= promotion?.promotionCount.count) {
              promotionValue = promotion?.discountValue.value;
            }
            if (promotion.discountType === 1 && orderCount >= promotion?.promotionCount.count) {
              promotionValue = (total * promotion?.discountPercentage.percentage) / 100;
            }
          }
          if (promotion.promotionType === 1) {
            if (promotion.discountType === 0 && total >= promotion?.promotionValue.value) {
              promotionValue = promotion?.discountValue.value;
            }
            if (promotion.discountType === 1 && total >= promotion?.promotionValue.value) {
              promotionValue = (total * promotion?.discountPercentage.percentage) / 100;
            }
          }
          if (CheckRecurring()) {
            dispatch(setOrderTotal(promotionValue));
          }
          return promotionValue;
        } else return null;
      }
      return promotionValue;
    },
    [CheckRecurring, dispatch, order.orderProducts, promotion],
  );

  useEffect(() => setPromotionValue(Promotions(order.totalValue)), [Promotions, order.totalValue]);

  useEffect(() => {
    if (pokUrl !== '') {
      window.open(pokUrl, '_blank');
    }
  })

  return (
    <div>
      <InnerRowWrapper>
        <PaymentMethods
          show={showPaymentMethods}
          toggle={() => setShowPaymentMethods(false)}
          internalOrder={internalOrder}
          selectCash={() => (paymentMethod.methodId = 0)}
          selectPok={() => (paymentMethod.methodId = 3)}
          pokData={pokData}
          onSubmit={() => onSave()}
          SetShowModal={() => setShowPokModal(showPokModal)}
          isValid={isValid}
          confirmPaymentMethodCash={() => setConfirmedPaymentMethod(0)}
          confirmPaymentMethodPok={() => setConfirmedPaymentMethod(3)}
        />
        <HeaderCol>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <h2 onClick={() => setShow(false)} style={{ margin: '20px', cursor: 'pointer' }}>
              Back
            </h2>
            <Header>Checkout</Header>
          </div>
        </HeaderCol>
        <Options>
          <OptionDiv
            onClick={() => {
              displayPaymentMethods();
            }}
          >
            <div style={{ display: 'flex', gap: '0.85rem' }}>
              <Icons.PaymentMethodV2 />
              <div>
                <h2 style={{ marginBottom: '0.8rem' }}>Choose a payment method</h2>
                <p style={{ fontSize: '12px' }}>
                  {confirmedPaymentMethod === 3
                    ? 'Online payment'
                    : confirmedPaymentMethod === 0 && 'Cash on delivery'}
                </p>
              </div>
            </div>
            <div style={{ marginTop: '10px' }}>
              <Icons.Vector />
            </div>
          </OptionDiv>
        </Options>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          ref={childRef}
        >
          <Body>
            <div
              style={{
                paddingLeft: '10px',
                backgroundColor: '#F9FAFC',
                width: '95%',
                marginLeft: '12.3px',
                borderRadius: '10px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  margin: '1.7rem 0 1.3rem 0',
                  // backgroundColor: '#F9FAFC',
                }}
              >
                <Icons.AddressDescription />
                <div style={{ margin: '0 0 0 10px' }}>
                  <h2 style={{ marginBottom: '10px' }}>Receipt</h2>
                  <p style={{ fontSize: '12px' }}>Including VAT</p>
                </div>
              </div>
              <div
                style={{
                  padding: '0px 2rem 2rem 2rem',
                  borderTop: '1px solid #F9FAFC',
                  // backgroundColor: '#F9FAFC',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <ProductName>Item subtotal:</ProductName>
                  <ProductPrice style={{ fontWeight: 600 }}>
                    {order.totalValue % 1 === 0
                      ? (order.totalValue = parseInt(order.totalValue, 10))
                      : order.totalValue}
                    {' ' + pageInfo.currency}
                  </ProductPrice>
                </div>

                {vouchers.data.filter((x) => x.showOnOnline).length !== 0 &&
                  promotionValue === null && (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <ProductName>Voucher:</ProductName>
                        <ProductPrice
                          style={
                            validVoucher
                              ? {}
                              : { fontWeight: 600, textDecoration: 'underline', color: 'blue' }
                          }
                          hover={`cursor: pointer;`}
                          onClick={() => setApplingVoucher((prevState) => !prevState)}
                        >
                          {validVoucher
                            ? validVoucher.profitType === 1
                              ? `-${validVoucher.profitValue}%`
                              : `-${validVoucher.profitValue} ALL`
                            : 'Apply Voucher'}
                        </ProductPrice>
                      </div>
                      {applingVoucher && (
                        <ApplyVoucherModal
                          order={order}
                          show={applingVoucher}
                          toggle={() => {
                            setApplingVoucher(false);

                            for (let voucher of vouchers.data) {
                              if (!voucher.showOnOnline) continue;
                              const validCode = voucher.voucherCodes.find(
                                (code) => code.code === order.voucherCode && code.isActive,
                              );
                              if (validCode) {
                                dispatch(setOrderValidVoucher(voucher, promotionValue));
                                return;
                              }
                            }

                            dispatch(setOrderValidVoucher(null));
                          }}
                        />
                      )}
                    </>
                  )}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: '1rem',
                  }}
                >
                  <ProductName style={{ fontWeight: 600 }}>Total:</ProductName>
                  <ProductPrice style={{ fontWeight: 600 }}>
                    {order.total % 1 === 0
                      ? (order.total = parseInt(order.total, 10))
                      : order.total}
                    {' ' + pageInfo.currency}
                  </ProductPrice>
                </div>
              </div>
            </div>
          </Body>
          <div
            style={{
              width: '100%',
              bottom: order.totalValue ? '1rem' : '-10vh',
              display: 'flex',
              transition: 'bottom 0.3s linear',
              zIndex: 9998,
              justifyContent: 'center',
              marginTop: '1rem',
            }}
          >
            <ErrorMessage message={error} style={{ padding: '10px 30px' }} />
              <BlackButton
                onClick={() => {
                  onSave();
                }}
                style={{
                  minHeight: '60px',
                  height: 60,
                  fontSize: 16,
                  fontWeight: 'bold',
                  color: '#FFFFFF',
                  borderRadius: '7px',
                  display: order.totalValue ? 'flex' : 'none',
                  width: '95%',
                }}
                text={
                  <TotalUL>
                    <ButtonText>
                      <ButtonInfo>
                        <AllQty>
                          {order.orderProducts.legth !== 0 &&
                            order.orderProducts.reduce(
                              (accumulator, currProd) => accumulator + currProd.qty,
                              0,
                            )}
                        </AllQty>
                        <p style={{ padding: '3px' }}>Confirm order</p>
                      </ButtonInfo>
                      <p style={{ marginRight: '30px', padding: '3px' }}>{order.total}</p>
                    </ButtonText>
                  </TotalUL>
                }
              />
          </div>
        </div>
        <div id="modal-container">
          <Modal
            width="250"
            height="540"
            getContainer={() => document.getElementById('modal-container')}
            visible={pokErrorModal}
            footer={null}
            onCancel={() => setPokErrorModal(false)}
            centered
            maskClosable={true}
            destroyOnClose={true}
            closable={false}
          >
            <Result
              status="error"
              subTitle="Sorry We Could Not Process The Pok Payment Right Now"
            />
            <Button
              style={{
                marginLeft: '195px',
              }}
              onClick={() => setPokErrorModal(false)}
            >
              OK
            </Button>
          </Modal>
        </div>
      </InnerRowWrapper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  clientInfo: state.shopping.client,
});

export default connect(mapStateToProps, {
  setClientInfo,
})(OrderReview);
