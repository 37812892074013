import * as React from 'react';
import { useReactToPrint } from 'react-to-print';
import { GreenButton, WhiteButton } from '../../views/buttons';

import ComponentToPrint from './ComponentToPrint';

const pageStyle = `
  @page {
    // size: 80mm 50mm;
    margin: 0mm;
  },

  @media all {
    .page-break {
      display: none;
    }
  }

  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }
`;

const PrintReceipt = (props) => {
  const componentRef = React.useRef(null);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    removeAfterPrint: true,
    pageStyle: pageStyle,
  });
  return (
    <div style={{ marginRight: 22 }}>
      <WhiteButton onClick={handlePrint} text={'Print Receipt'} />
      <div style={{ display: 'none' }}>
        <ComponentToPrint ref={componentRef} {...props} />
      </div>
    </div>
  );
};

export const ConfirmOrder = (props) => {
  const { text, autoPrint, onClick } = props;
  const componentRef = React.useRef(null);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    removeAfterPrint: true,
    pageStyle: pageStyle,
  });
  return (
    <>
      <GreenButton
        text={text}
        onClick={() => {
          onClick();
          if (autoPrint) {
            handlePrint();
          }
        }}
      />
      <div style={{ display: 'none' }}>
        <ComponentToPrint ref={componentRef} {...props} />
      </div>
    </>
  );
};

export default PrintReceipt;
