import styled, { keyframes } from 'styled-components';

const dotFlashing = keyframes`
  0% {
    background-color: #3185fc;
    background-color: ${(props) => props.color};
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
`;

const DotSpinner = styled.div`
  position: relative;
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  border-radius: 5px;
  background-color: ${(props) => props.color};
  color: ${(props) => props.color};
  animation: ${dotFlashing} 1s infinite linear alternate;
  animation-delay: 0.5s;

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: -${(props) => `${props.size / 2 + props.size}px`};
    width: ${(props) => `${props.size}px`};
    height: ${(props) => `${props.size}px`};
    border-radius: 5px;
    background-color: ${(props) => props.color};
    color: ${(props) => props.color};
    animation: ${dotFlashing} 1s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: ${(props) => `${props.size / 2 + props.size}px`};
    width: ${(props) => `${props.size}px`};
    height: ${(props) => `${props.size}px`};
    border-radius: 5px;
    background-color: ${(props) => props.color};
    color: ${(props) => props.color};
    animation: ${dotFlashing} 1s infinite alternate;
    animation-delay: 1s;
  }
`;

export default DotSpinner;
