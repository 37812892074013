import React, {useState, useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import text from 'app/shared/views/text';
import RadioGroup from 'app/shared/views/radioGroup';
import RadioElement from 'app/shared/views/radioElement';
import Spinner from 'app/shared/views/spinner';
import colors from 'app/shared/helpers/colors';
import transition from 'app/shared/helpers/transition';
import Modal from 'app/shared/views/modal';
import {useReducerThunkDispatch} from "app/shared/helpers/hooks";
import {GradientButton} from "app/shared/views/buttons";


import { getSmsCreditPayLink } from 'actions/marketingActions';

const {
  H5,
} = text;

const initialState = {
  smsCreditPayLinkStatus: 'idle',
  smsCreditPayLink: '',
  waitingForFulfillment: false,
};

const reducer = (state, action) => {
  switch(action.type) {
    case 'SMS_CREDIT_PAY_LINK_LOADING':
      return {...state, smsCreditPayLinkStatus: 'loading'};
    case 'SMS_CREDIT_PAY_LINK_OK':
      return {...state, smsCreditPayLinkStatus: 'loaded', smsCreditPayLink: action.data};
    case 'SMS_CREDIT_PAY_LINK_ERROR':
      return {...state, smsCreditPayLinkStatus: 'error'};
    case 'CHECKOUT_COMPLETE':
      return {...state, waitingForFulfillment: true};
    case 'PAYMENT_FULFILLED':
      return {...initialState}
    default:
      return state;
  }
}


const CREDIT_PLANS = [
  {
    name: "$1",
    description: "100 Credits",
    quantity: 100
  },
  {
    name: "$5",
    description: "500 Credits",
    quantity: 500
  },
  {
    name: "$10",
    description: "1000 Credits",
    quantity: 1000
  },
];

const updateActivePageInfo = (updatedPage) => ({
  type: 'SET_ACTIVE_PAGE_OK',
  data: updatedPage
});

const BuyCreditsPopup = ({ show, toggle, activePage, updateActivePageInfo, connectionHub }) => {
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [state, dispatch] = useReducerThunkDispatch(reducer, initialState);
  const refreshIfNotFulfilledTimer = useRef(null);

  useEffect(() => {
    const handler = (updatedPage) => {
      if(updatedPage.id !== activePage.id)
        return;
      updateActivePageInfo(updatedPage);
      clearTimeout(refreshIfNotFulfilledTimer.current);
      dispatch({type: 'PAYMENT_FULFILLED'});
      toggle();
    };

    connectionHub && connectionHub.on('SMS_CREDITS_PAYMENT_FULFILLED', handler);

    return () => {
      connectionHub && connectionHub.off('SMS_CREDITS_PAYMENT_FULFILLED', handler);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(state.smsCreditPayLinkStatus === 'loaded') {
      const Paddle = window.Paddle;
      const passthrough = JSON.stringify({ pageId: activePage.id, });
      Paddle.Checkout.open({
        override: state.smsCreditPayLink,
        passthrough,
        successCallback: () => {
          dispatch({type: 'CHECKOUT_COMPLETE'});
          refreshIfNotFulfilledTimer.current = setTimeout(() => {
            window.location.reload();
          }, 5000);
        },
      });
    }
    // eslint-disable-next-line
  }, [state.smsCreditPayLinkStatus]);

  const onBuyClick = () => {
    const plan = CREDIT_PLANS[selectedPlan];
    if(state.smsCreditPayLinkStatus !== 'loading')
      dispatch(getSmsCreditPayLink(plan.quantity));
  }

  return (
    <Modal show={show} toggle={toggle}>
      <ContentWrapper>
        <ContentTitle>Buy Credits</ContentTitle>
        <SubtitleWrapper>
          <Subtitle>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </Subtitle>
        </SubtitleWrapper>
        {state.waitingForFulfillment ?
          <Spinner size={30}/>
          :
          <>
          <SectionTitle>Choose Plan</SectionTitle>
          <PlanSelectedWrapper>
          <RadioGroup
          selectedElement={selectedPlan}
          updateSelectedElement={plan => setSelectedPlan(plan)}
          >
          <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
          {CREDIT_PLANS.map((plan, index) =>
            <RadioGroup.Element
              key={index}
              Element={PlanButton}
              text={plan.name}
              description={plan.description}
              value={index}/>
          )}
          </div>
          </RadioGroup>
          </PlanSelectedWrapper>
          {state.smsCreditPayLinkStatus === 'loading' ?
            <BuyButton
            onClick={onBuyClick}
            text={<Spinner size={20} color={colors.white} style={{width: 'unset'}}/>}
            width={'100%'}
            disabled
            />
            : <BuyButton
            onClick={onBuyClick}
            text={'Buy'}
            width={'100%'}
            />
          }
          </>
        }

      </ContentWrapper>
    </Modal>
  )
};

const {
  Paragraph: { P }
} = text;

export const BuyButton = styled(GradientButton)`
  background: linear-gradient(90deg, #006CCB 0%, #65BAFF 100%);
  box-shadow: 0px 12px 16px rgba(200, 208, 216, 0.3);
  width: ${props => props.width ? props.width : '260px' } !important;
  height: ${props => props.height ? props.height : '36px' } !important;
  border-radius: 3px;
`;

export const Title = styled.h1`
  color: ${colors.blue};
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
  display: flex;
  align-items: center;
`;

export const Subtitle = styled(P)`
  color: #748AA1;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  display: flex;
  align-items: center;
  max-width: 500px;
`;

export const FeatureList = styled.ul`
  color: inherit;
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 25px;
  list-style: decimal;

  & > li {
    line-height: 27px;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 10px;
  width: 450px;
  padding: 32px 50px 32px 50px;
  box-sizing: border-box;
`;

const ContentTitle = styled(Title)`
  font-size: 24px;
  line-height: 22px;
  padding-bottom: 25px;
  justify-content: center
`;

const SectionTitle = styled(Title)`
  margin-top: 50px;
  margin-bottom: 30px;
  font-size: 22px;
  line-height: 22px;
  justify-content: center
`;

const SubtitleWrapper = styled.div`
  text-align: center;
  padding-bottom: 10px;
`;

const PlanSelectedWrapper = styled.div`
  margin: 30px 0 50px;
`;

const PlanButtonWrapper = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGrey};
  box-sizing: border-box;
  width: 100%;
  height: 46px;
  border-radius: 3px;
  display: inline-flex;
  transition: ${transition};
  align-items: center;
  margin-bottom: 10px;

  :hover {
    cursor: pointer;

    /*
    .radio-element {
      border: 1px solid ${colors.blue};
      cursor: pointer;
    }
    */
  }
`;

const RadioElementWrapper = styled.div`
  padding: 0 10px;
  display: flex;
  align-items: center;
`;

const Price = styled(H5)`
  color: ${colors.blue};
  margin-left: 20px;
`;

const Description = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  width: 100%;
  padding: 0 20px;
`

const PlanButton = ({
  updateSelectedElement = () => {},
  isSelected = false,
  text = '',
  description='',
  ...rest
}) => (
  <PlanButtonWrapper onClick={updateSelectedElement} isSelected={isSelected} {...rest}>
    <RadioElementWrapper>
      <RadioElement isSelected={isSelected} />
    </RadioElementWrapper>
    <Price>{text}</Price>
    <Description>{description}</Description>
  </PlanButtonWrapper>
);

const mapStateToProps = state => ({
  activePage: state.facebookPages?.activePage,
  connectionHub: state.notifications.connectionHub
});

export default connect(
  mapStateToProps,
  {updateActivePageInfo}
)(BuyCreditsPopup);
