import React from 'react';

import Input from 'app/shared/views/input'
import { Button } from 'app/shared/views/buttons';
import icons from 'app/shared/views/icons';

import colors from 'app/shared/helpers/colors';
import './InputStepper.css';

const iconProps = {
  width: '12px',
  height: '17px',
  fill: colors.veryDarkGrey,
};

const leftButtonProps = {
  style: {
    width: '30px',
    height: '32px',
    borderRight: '1px solid #e1e6ec',
    borderRadius: 0,
  },
};

const rightButtonProps = {
  style: {
    width: '30px',
    height: '32px',
    borderLeft: '1px solid #e1e6ec',
    borderRadius: 0,
  },
};

const InputStepper = ({ value, onChange, min, max, ...rest }) => {
  const inputRef = React.createRef(null);

  const onIncrement = () => {
    if (inputRef.current) {
      let val = parseInt(inputRef.current.value, 10);
      if (typeof max === 'number' && val >= max) return;
      val = val * 1 + 1;
      inputRef.current.value = val.toString();
      onChange && onChange(val);
    }
  };

  const onDecrement = () => {
    if (inputRef.current) {
      let val = parseInt(inputRef.current.value, 10);
      if (typeof min === 'number' && val <= min) return;
      val = val - 1;
      inputRef.current.value = val;
      onChange && onChange(val);
    }
  };

  return (
    <Input.Input
      {...rest}
      value={value}
      height={32}
      start={<Button onClick={onDecrement} {...leftButtonProps} text={<icons.Minus {...iconProps} />} />}
      end={<Button onClick={onIncrement} {...rightButtonProps} text={<icons.Plus {...iconProps} />} />}
      inputRef={inputRef}
      style={{
        padding: 0,
        textAlign: 'center',
        width: '55px',
      }}
      onChange={(e)=>onChange && onChange(e.target.value)}
      type={'number'}
    />
  );
};

export default InputStepper;

