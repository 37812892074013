import React from 'react';
import shared from 'app/shared';
import styled from 'styled-components';

const {
  views: { Input, Icons },
  helpers: { colors },
} = shared;

const SearchInputWrapper = styled(Input)`
  padding: 0px 15px;
  &::placeholder {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 14px;
    color: #748aa1;
  }
`;

const SearchInput = ({ search, onSearchChanged }) => (
  <SearchInputWrapper
    start={<Icons.Search style={{ marginTop: '1px' }} />}
    placeholder={'Search'}
    height={36}
    onChange={(e) => onSearchChanged(e.target.value)}
    value={search}
  />
);

const AutocompleteInputWrapper = styled(Input)`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  display: flex;
  align-items: center;
  padding-right: 12px;

  input::placeholder {
    color: ${colors.darkestGrey};
  }
`;

export const AutocompleteInput = ({ iconProps, ...props }) => {
  const end = (
    <Icons.Search fill={'#000'} width={'19px'} height={'19px'} style={{ display: 'flex' }} />
  );
  return (
    <AutocompleteInputWrapper
      end={end}
      placeholder={'Search...'}
      style={{
        color: props.selected ? colors.oceanBlue : colors.textColor,
      }}
      {...props}
    />
  );
};

export default SearchInput;
