import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ScrollButton } from 'app/shared/views/buttons';

// &:first-child {
//   @media only screen and (min-width: 901px) {
//     margin-left: 80px;
//   }
//   @media only screen and (max-width: 900px) {
//     margin-left: 0;
//   }
// }

//margin-left: 80px;

const HorizontalScrollableSpaceWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: auto;
  position: relative;
  scroll-behavior: smooth;

  @media only screen and (min-width: 901px) {
    margin: ${({ iframeOpenedCheck }) => (iframeOpenedCheck ? '20px 40px 0' : '20px 100px 0')};
  }

  @media only screen and (max-width: 900px) {
    padding: 20px 20px 0;
  }

  @media only screen and (min-width: 901px) {
    margin-right: ${(props) => props.gap || 30}px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  & > * {
    flex: 0 0 auto;

    @media only screen and (min-width: 901px) {
      margin-right: ${(props) => props.gap || 30}px;
    }
    @media only screen and (max-width: 900px) {
      margin-right: ${(props) => props.gap || 20}px;
    }

    &:last-child {
      position: relative;
      &::after {
        position: absolute;
        content: '';
        right: -40px;
        width: 40px;
        height: 100%;
        padding-right: ${(props) => props.gap || '0'}px;
      }
    }
  }
`;

const Button = styled(ScrollButton)`
  position: absolute;
  height: 60px;
  padding: 0px;

  min-height: 50px;
  width: 20px;
  top: ${(props) => props.buttonTopLevel};

  @media only screen and (max-width: 499px) {
    display: none;
  }

  @media only screen and (min-width: 500px) {
    display: ${(props) => (props.scrollWidth > 502 ? 'flex' : 'none')};
  }
  padding-bottom: 2px;
`;

const HorizontalScrollableSpace = (props) => {
  const hSSref = useRef(null);
  const buttonTopLevel = props.isEventButton ? '42.5%' : '36%';

  const [scrollWidth, setScrollWidth] = useState(hSSref.current?.scrollWidth);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // eslint-disable-next-line
  const [iframeOpenedCheck, setIframeOpenedCheck] = useState(props.openedIframe);

  useEffect(() => {
    setIframeOpenedCheck(props.openedIframe);
  }, [props.openedIframe]);

  useEffect(() => {
    setScrollWidth(hSSref.current?.scrollWidth);
    setWindowWidth(window.innerWidth);
  }, [hSSref, scrollWidth]);

  const scroll = (scrollOffset) => {
    hSSref.current.scrollTo({
      top: 0,
      left: (hSSref.current.scrollLeft += scrollOffset),
      behavior: 'smooth',
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      {scrollWidth > windowWidth ? (
        <>
          <Button
            scrollWidth={hSSref.current?.scrollWidth}
            buttonTopLevel={buttonTopLevel}
            onClick={() => scroll(-160)}
          />
          <Button
            scrollWidth={hSSref.current?.scrollWidth}
            buttonTopLevel={buttonTopLevel}
            onClick={() => scroll(160)}
            style={{
              right: 0,
              transform: 'rotate(180deg)',
            }}
          />
        </>
      ) : null}
      <HorizontalScrollableSpaceWrapper
        ref={hSSref}
        {...props}
        iframeOpenedCheck={iframeOpenedCheck}
      >
        {props.children}
      </HorizontalScrollableSpaceWrapper>
    </div>
  );
};

export default HorizontalScrollableSpace;
