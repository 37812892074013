import SignInPage from './views/SignIn.page';
import SignUpPage from './views/SignUp.page';
import ForgotPassPage from './views/ForgotPass.page';
import ResetPassPage from './views/ResetPass.page';
import ActivateAccountPage from './views/ActivateAcc.page';

SignInPage.path = '/signin';
SignUpPage.path = '/register';
ForgotPassPage.path = '/forgotPassword';
ResetPassPage.path = '/verifyforgotpassword';
ActivateAccountPage.path = '/verifyemail';

export default {
  views: {
    SignInPage,
    SignUpPage,
    ForgotPassPage,
    ResetPassPage,
    ActivateAccountPage,
  }
};
