import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
//import product_image from '../../../../assets/images/product_image.png';
import Modal from './components/modal';
import PrintReceipt, { ConfirmOrder } from '../printReceipt';
import { Button, WhiteButton } from '../../views/buttons';
import { markAsRead } from 'actions/notificationAction';
import { updateOrderStatus } from 'actions/orderActions';
import CurrencyFormatter from '../../../shared/helpers/currencyFormatter';
import {
  countOrderNotifications,
  getFirstUnreadOrderNotification,
} from 'reducers/notificationReducer';
import { requestDelivery, orderReady } from 'actions/deliveryActions';
import { IsDeliveryActive } from 'selectors/pageServiceSelector';
import './style.css';
import colors from '../../helpers/colors';
import Icons from '../../views/icons';
import { ORDER_STATUS } from 'app/orders/constants';

const RedButton = styled(Button)`
  background-color: ${colors.white};
  border: 1px solid ${colors.red};
`;

const OrderDetailsModal = (props) => {
  const location = useLocation();
  const {
    notification,
    orders,
    facebookActivePage,
    pageConfiguration,
    updateOrderStatus,
    markAsRead,
    countOrderNotifications,
    products,
    isDeliveryActive,
    requestDelivery,
    orderReady,
  } = props;

  const orderDetails =
    notification &&
    orders.data &&
    orders.data.find((x) => {
      return x.orderCounter === notification.itemId;
    });
  const isTableOrder = !!orderDetails?.tableId;

  const waitingConfirmation =
    ORDER_STATUS[orderDetails?.orderStatus] === 'Waiting for Confirmation';

  const confirmationActions = waitingConfirmation
    ? [
      () => (
        <ConfirmOrder
          text={'Accept'}
          order={orderDetails}
          notification={notification}
          restaurantName={facebookActivePage?.pageName}
          totalValue={orderDetails && CurrencyFormatter(orderDetails.totalValue)}
          autoPrint={facebookActivePage?.autoPrintReceipt}
          onClick={() => {
            updateOrderStatus(
              orderDetails.id,
              orderDetails.tableId == null ? 'REQUESTED' : 'ACCEPTED',
              null,
              orderDetails,
            );
            markAsRead(notification.id);
          }}
          receiptConfiguration={pageConfiguration.receiptConfiguration}
        />
      ),
      () => (
        <RedButton
          text={'Reject'}
          color={colors.red}
          onClick={() => {
            updateOrderStatus(orderDetails.id, 'CANCELLED', null, orderDetails);
            markAsRead(notification.id);
          }}
        />
      ),
    ]
    : [];

  let data = [];
  const getImageURL = (productId) =>
    (products.data.find((x) => x.id === productId) || {}).imagePath || null;

  orderDetails &&
  orderDetails.orderProducts &&
  orderDetails.orderProducts.forEach((element, index) => {
    data.push({
      key: index,
      image: getImageURL(element.productId), // || product_image,
      product: element.name,
      quantity: element.qty,
      value: element.price,
      discount: element.discount,
      toppings: element.toppings?.map((topping) => ({
        key: topping.id,
        image: getImageURL(topping.productId), // || product_image,
        product: topping.name,
        quantity: topping.qty,
        value: topping.price,
      })),
    });
  });

  const Footer = () => (
    <Flex
      style={{
        padding: '16px 35px',
        background: '#f9f9f9',
        borderTop: '1px solid #F4F4F4',
      }}
    >
      <Total>Total:</Total>
      <Flex1>
        <ProductValue style={{ textAlign: 'right' }}>
          {orderDetails && CurrencyFormatter(orderDetails.totalValue)}
        </ProductValue>
      </Flex1>
    </Flex>
  );

  return (
    <Modal
      orderId={notification && notification.itemId}
      orderDetails={orderDetails}
      orderCounter={orderDetails && orderDetails.orderCounter}
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            text={`${isTableOrder ? 'Table' : 'Online'} Order Number: ${
              orderDetails && orderDetails.orderCounter
            }`}
            onClick={() => {
              const win = window.open(`/order/editorder/${orderDetails.orderCounter}`, '_blank');
              win.focus();
              markAsRead(notification && notification.id);
            }}
            style={{
              width: 'unset',
              minWidth: 200,
              textAlign: 'left',
              height: 'unset',
              display: 'inline-block',
            }}
          />
          {isDeliveryActive &&
            !orderDetails?.tableId &&
            (orderDetails && !orderDetails.requireDelivery ? (
              <Button
                text={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      width: '100%',
                      alignItems: 'center',
                    }}
                  >
                    <Icons.BikerHelmet
                      fill={orderDetails?.requireDelivery ? '#666666' : '#FF9800'}
                    />
                    Request Delivery
                  </div>
                }
                onClick={() => requestDelivery(orderDetails?.id)}
                disabled={orderDetails?.requireDelivery}
                color={colors.yellow}
                style={{
                  width: 'unset',
                  minWidth: 180,
                  textAlign: 'center',
                  alignItems: 'center',
                  display: 'inline-block',
                  height: '100%',
                }}
              />
            ) : (
              <Button
                text={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      width: '100%',
                      alignItems: 'center',
                    }}
                  >
                    <Icons.Cloche fill={orderDetails?.orderStatus ? colors.green : '#FF9800'} />
                    {orderDetails?.orderStatus !== 2 ? 'Order ready' : 'Done'}
                  </div>
                }
                onClick={() => orderReady(orderDetails?.id)}
                disabled={orderDetails?.orderStatus === 2}
                color={colors.green}
                style={{
                  width: 'unset',
                  minWidth: 180,
                  textAlign: 'center',
                  alignItems: 'center',
                  display: 'inline-block',
                  height: '100%',
                }}
              />
            ))}
        </div>
      }
      notificationId={notification && notification.id}
      countNotifications={countOrderNotifications}
      dateTime={orderDetails && Date.parse(orderDetails.orderDate)}
      tableName={orderDetails?.tableName}
      waiterName={orderDetails?.waiterName}
      address={orderDetails && orderDetails.orderLocation}
      addressDetails={orderDetails && orderDetails.orderLocation2}
      phone={orderDetails && orderDetails.clientPhone}
      visible={orderDetails && !location.pathname.includes('/order/editorder')}
      note={orderDetails && orderDetails.customerDescription}
      markAsRead={markAsRead}
      actions={[
        ...confirmationActions,
        () => <WhiteButton text={'Close'} onClick={() => markAsRead(notification.id)} />,
        () => (
          <PrintReceipt
            order={orderDetails}
            restaurantName={facebookActivePage && facebookActivePage.pageName}
            totalValue={orderDetails && CurrencyFormatter(orderDetails.totalValue)}
            receiptConfiguration={pageConfiguration.receiptConfiguration}
          />
        ),
      ]}
    >
      <TableRow style={{ margin: '0 16px 0 44px' }}>
        <Flex2 style={{ display: 'flex', flexDirection: 'row' }}>
          <Label>Products:</Label>
        </Flex2>
        <Flex1 style={{ width: '25%' }}>
          <Label>Quantity:</Label>
        </Flex1>
        <Flex1 style={{ width: '25%' }}>
          <Label>Value:</Label>
        </Flex1>
      </TableRow>
      <Children>
        {data?.map((product, index) => (
          <div className={'rowBackground'} key={index}>
            <TableRow>
              <Flex2 style={{ display: 'flex', flexDirection: 'row' }}>
                {product.image && (
                  <Flex1>
                    <ProductImage alt="logo" src={product.image} height="20" width="40" />
                  </Flex1>
                )}
                <ProductName>{product.product}</ProductName>
              </Flex2>
              <Flex1 style={{ width: '25%' }}>
                <ProductQuantity>{product.quantity}</ProductQuantity>
              </Flex1>
              <Flex1>
                <ProductValue>{CurrencyFormatter(product.quantity * product.value)}</ProductValue>
              </Flex1>
            </TableRow>
            {product.toppings?.map((topping, index) => (
              <TableRow key={index}>
                <Flex2 style={{ display: 'flex', flexDirection: 'row' }}>
                  <ProductName style={{ paddingLeft: 64, fontWeight: 500 }}>
                    {topping.product}
                  </ProductName>
                </Flex2>
                <Flex1 style={{ width: '25%' }}>
                  <ProductQuantity>{topping.quantity}</ProductQuantity>
                </Flex1>
                <Flex1>
                  <ProductValue>{CurrencyFormatter(topping.quantity * topping.value)}</ProductValue>
                </Flex1>
              </TableRow>
            ))}
          </div>
        ))}
        {!isTableOrder && (
          <div className={'rowBackground'}>
            <TableRow>
              <Flex2 style={{ display: 'flex', flexDirection: 'row' }}>
                <ProductName>Delivery Fee</ProductName>
              </Flex2>
              <Flex1 style={{ width: '25%' }}></Flex1>
              <Flex1>
                <ProductValue>{CurrencyFormatter(orderDetails?.deliveryFee)}</ProductValue>
              </Flex1>
            </TableRow>
            <TableRow>
              <Flex2 style={{ display: 'flex', flexDirection: 'row' }}>
                <ProductName>Promotion Discount</ProductName>
              </Flex2>
              <Flex1 style={{ width: '25%' }}></Flex1>
              <Flex1>
                <ProductValue>{CurrencyFormatter(orderDetails?.discount)}</ProductValue>
              </Flex1>
            </TableRow>
          </div>
        )}
      </Children>
      <Footer />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  orders: state.orders,
  products: state.products,
  pageConfiguration: state.pageConfiguration,
  facebookActivePage: state.facebookPages.activePage,
  countOrderNotifications: countOrderNotifications(state),
  notification: getFirstUnreadOrderNotification(state),
  isDeliveryActive: IsDeliveryActive(state),
});

export default connect(mapStateToProps, {
  markAsRead,
  updateOrderStatus,
  requestDelivery,
  orderReady,
})(OrderDetailsModal);

const Flex = styled.div`
  display: flex;
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 4px;
`;

const Flex1 = styled.div`
  flex: 1;
`;
const Flex2 = styled.div`
  flex: 2;
`;

const ProductImage = styled.img`
  border-radius: 3px;
  height: 20px;
  width: 40px;
  flex: 1;
`;

const ProductName = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #3f3d56;
  flex: 3;
`;
const ProductQuantity = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  color: #1d2036;
  padding-left: 16px;
`;

const ProductValue = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  color: #2ecc71;
  padding-right: 8px;
  text-align: right;
`;

const Label = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  flex: 1;
  align-items: left;
  color: #748aa1;
  margin-bottom: 10px;
`;

const Total = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #1d2036;
  text-align: left;
  padding-left: 64px;
  flex: 1;
`;

const Children = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin: 0 16px 0 35px;
  &::-webkit-scrollbar {
    display: block !important;
  }
`;
