import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import personImage from 'assets/images/person_image.png';
import AdminNavigation from './adminNavigation';
import CentralAdminTopBar from './centralAdminTopBar';
import MobileTopBar from './topBar.Mobile';
import mediaQueries from '../helpers/mediaQueries';
import { setLocalUser, getUserDetails } from 'actions/userActions';
// import { getAll } from 'actions/crudActions';
// import { getNotifications } from 'actions/notificationAction';

const CentralAdminLayoutWrapper = styled.div`
  display: flex;
  position: relative;
`;

const MobileTopBarWrapper = styled.div`
  margin-bottom: 10px;
  display: none;

  ${mediaQueries.mobile(`
    display: block;
  `)}
`;

const ContentWrapper = styled.div`
  flex-grow: 2;
  margin: 24px 45px 40px 270px;
  max-width: calc(100vw - 315px);

  @media only screen and (max-width: ${mediaQueries.sizes.phone}px) {
    margin: 10px 15px 40px;
    max-width: calc(100vw - 30px);
  }
`;

const CentralAdminLayout = (props) => {
  const [showNavigation, toggleNavigation] = useState(false);
  const { setLocalUser, getUserDetails } = props;

  useEffect(() => {
    (async () => {
      const user = localStorage.getItem('User');
      const isAdmin = localStorage.getItem('isAdmin');
      if (isAdmin === null) {
        props.history.push('/');
      }
      if (user !== null) {
        setLocalUser();
      } else {
        getUserDetails(() => {
          setLocalUser();
        });
      }
    })();
  }, [props.history, setLocalUser, getUserDetails]);

  return (
    <CentralAdminLayoutWrapper>
      <AdminNavigation
        show={showNavigation}
        toggleNavigation={() => toggleNavigation(!showNavigation)}
      />
      <ContentWrapper>
        <CentralAdminTopBar personImage={personImage} />
        <MobileTopBarWrapper>
          <MobileTopBar toggleNavigation={() => {toggleNavigation(!showNavigation);}} />
        </MobileTopBarWrapper>
        {props.children}
      </ContentWrapper>
    </CentralAdminLayoutWrapper>
  );
};

const mapStateToProps = state => ({
  facebookPages: state.facebookPages,
  categories: state.categories,
  products: state.products,
  orders: state.orders,
  clients: state.clients,
  notifications: state.notifications
})

export default connect(mapStateToProps, {
  setLocalUser,
  getUserDetails,
})(
  withRouter(CentralAdminLayout)
);
