import styled from 'styled-components';
import Card from './card';

const PaginatorWrapper = styled.div`
  margin-top: 45px;
  display: flex;
  justify-content: center;
`;

const TableWrapper = styled(Card)`
  margin-top: 25px;
  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }

  @media all and (max-width: 768px) { 
    display: none;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;

  @media all and (min-width: 768px) and (max-width: 1024px) {
     width: 30%;
  }

  @media all and (max-width: 768px) { 
    width: 50%;
  }
`;

const ActionBarWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media all and (max-width: 768px) { 
    justify-content: flex-end;
  }
`;

const ToggleWrapper = styled.div`
  margin-right: 85px;
  @media all and (min-width: 768px) and (max-width: 1024px) {
    margin-right: 30px;
    margin-left: 30px;
  }
`;

const StatsSectionWrapper = styled.div`
  margin-top: 30px;
`;

export default {
  PaginatorWrapper,
  TableWrapper,
  SearchWrapper,
  ActionBarWrapper,
  ToggleWrapper,
  StatsSectionWrapper,
};
