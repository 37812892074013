import React from 'react';
import styled from 'styled-components';

import { Formik } from 'formik';
import * as Yup from 'yup';

import shared from 'app/shared';

import FormButton from './MainButton';
import FieldError from './FieldError';

const {
  views: {
    Input,
  },
} = shared;

const ResetPassForm = (props) => {

  return (
    <Formik
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().required('Password is required'),
        confirmPassword: Yup.string().required("Please confirm your password")
          .when("password", {
            is: password => (password && password.length > 0 ? true : false),
            then: Yup.string().oneOf([Yup.ref("password")], "Password doesn't match")
          })
      })}
      onSubmit={async (values, { setSubmitting }) => {
        if (typeof props.onSubmit === 'function') {
          await props.onSubmit(values);
        }
        setSubmitting(false);
      }}
    >
      {({ values, touched, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <FormWrapper>
          <InputWrapper>
            <Input
              labelText="New Password"
              name="password"
              type="password"
              height={38}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.password && errors.password && (
              <FieldError>
                {errors.password}
              </FieldError>
            )}
          </InputWrapper>

          <InputWrapper>
            <Input
              labelText="Confirm New Password"
              name="confirmPassword"
              type="password"
              height={38}
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.confirmPassword && errors.confirmPassword && (
              <FieldError>
                {errors.confirmPassword}
              </FieldError>
            )}
          </InputWrapper>

          <FormButton
              text="Confirm"
              onClick={handleSubmit}
              disabled={isSubmitting}
              style={{ marginTop: '20px' }}
            />
        </FormWrapper>
      )}
    </Formik>
  )
};

const FormWrapper = styled.div`

`;

const InputWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin: 5px 0;
`;

export default ResetPassForm;
