import React, { useState, useEffect, useCallback } from 'react';
import { withRouter, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import Select from 'react-select';
import { format } from 'date-fns';
import * as Yup from 'yup';
import styled from 'styled-components';
import shared from 'app/shared';
import Icon from '../../shared/views/icons';
import hexToRGBA from '../../shared/helpers/hexToRGBA';
import { edit, remove, getAll, getById } from 'actions/crudActions';
import { toggleProductStatus } from 'actions/productActions';
import { linkProductTopping, unLinkProductTopping } from 'actions/categoryAction';
import crudTypes from '../../../config/crudTypes';
import { CSVFileExporter } from 'app/shared/helpers/CSVFileExporter';
import ProductCsvMapper from './productCsvMapper';
import currencyFormatter from 'app/shared/helpers/currencyFormatter';
import { Input } from 'antd';

const {
  views: {
    Button,
    Page,
    UploadInput,
    AddItemPopup,
    Icons,
    Toggle,
    CheckBox,
    Alert,
    Text: {
      Link: { Link },
    },
  },
  helpers: { colors },
} = shared;

const EditProductHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
`;

const ProductHeaderActions = styled.div``;

const EditProductTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: ${colors.black};
  margin-right: 10px;

  & > * {
    margin-right: 22px;
  }

  @media all and (max-width: 768px) {
    display: none;
  }
`;

const EditProductDate = styled.div`
  font-size: 12px;
  color: ${colors.grey};

  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }
  @media all and (max-width: 768px) {
    display: none;
  }
`;

const StatusWrapper = styled.div`
  font-size: 12px;
  color: ${colors.grey};
  display: flex;
  align-items: center;

  & > * {
    padding-left: 15px;
  }
`;

// const ProductsRevenue = styled.label`
//   font-size: 24px;
//   line-height: 23px;
//   flex: 1;
//   color: ${colors.green};
//   margin-right: 5px;
// `;

const EditProductBody = styled.div`
  background: ${colors.white};
  padding: 25px 30px;
  margin-top: 44px;
  display: flex;
  flex-direction: column;
  border: 1px solid #e9eaf1;
  box-sizing: border-box;
  border-radius: 3px;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    flex-direction: column;
    padding-bottom: 30px;
  }

  @media all and (max-width: 768px) {
    flex-direction: column;
    padding-bottom: 30px;
  }
`;

const EditProductDetail = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  & > *:first-child {
    flex: 0 240px;
  }

  /* padding-right: 55px; */
  @media all and (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
    padding-right: 0;
  }
  @media all and (max-width: 768px) {
    width: 100%;
    padding-right: 0;
  }
`;

const ChooseToppingWrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 10px;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    padding-right: 0;
  }
  @media all and (max-width: 768px) {
    padding-right: 0;
  }
`;

const ToppingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: ${(props) => (props.isActive ? 'unset' : 'none')};
  opacity: ${(props) => (props.isActive ? '100%' : '50%')};
`;
const ToppingWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const EditProductLabel = styled.div`
  margin-bottom: 30px;
  font-size: ${(props) => `${props.fontSize}px`};
  line-height: 22px;
  color: ${colors.blue};

  @media all and (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
    margin-bottom: 10px;
  }
  @media all and (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;
const ProductStatistics = styled.div`
  width: 100%;
  margin-top: 10px;
  /* padding-left: 55px; */

  @media all and (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
    padding-left: 0;
    margin-top: 10px;
  }
  @media all and (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    margin-top: 10px;
  }
`;

const ProductStatBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  height: 43px;
  text-align: center;
  background-color: ${hexToRGBA(colors.blue, 0.2)};
  border-radius: 3px;
  color: ${colors.blue};
  border: 1px solid #2c98f0;
`;

const Label = styled.label`
  color: ${colors.textColor};
  font-size: 12px;
  line-height: 23px;
  font-weight: 500;
`;

const LabelWrapper = styled.div`
  padding-left: 10px;
`;

const ProductStatisticsButtons = styled.div`
  display: flex;

  & > * {
    width: 240px;
    padding-right: 25px;
  }
`;

const WarningWrapper = styled.div`
  width: 100%;
  display: flex;
  background: rgba(255, 152, 0, 0.2);
  color: #ff9800;
  margin-bottom: 10px;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  align-items: center;
  letter-spacing: 1px;
  font-weight: bold;
  padding-left: 5px;
  margin-top: 10px;
`;

const ErrorWrapper = styled.div`
  min-height: 24px;
  padding: 5px;
  width: 100%;
  background: rgba(242, 69, 61, 0.2);
  color: #f2453d;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  align-items: center;
  letter-spacing: 1px;
  font-weight: bold;
  display: ${(props) => (props.show === 'ERROR' ? 'flex' : 'none')};
`;

const NotificationWrapper = styled.div`
  flex-basis: 100%;
  margin-top: 32px;
`;

const EditProduct = (props) => {
  const [updateStatus, setUpdateStatus] = useState('');
  const [deleteStatus, setDeleteStatus] = useState('');

  //const [uri, setUri] = useState();
  const str = window.location.href;
  const id = parseInt(str.substring(str.lastIndexOf('/') + 1).trim());
  const filteredProduct = (props.products && props.products.data.filter((x) => x.id === id)) || [];
  const activeProduct = filteredProduct.length > 0 ? filteredProduct[0] : null;
  const { getAll, getById, facebookPages } = props;

  const getOptions = useCallback(
    () =>
      (props.categories &&
        props.categories.data.length > 0 &&
        props.categories.data.map((category) => ({
          value: category.id,
          label: category.name,
        }))) ||
      [],
    [props.categories],
  );

  const getCategoryName = useCallback(
    (categoryId) => getOptions().find((c) => c.value === categoryId)?.label,
    [getOptions],
  );

  const checkIfCategoryTypeisCombo = useCallback(
    (categoryId) => {
      return props.categories.data.find((c) => c.id === categoryId)?.categoryType;
    },
    [props.categories],
  );

  useEffect(() => {
    getAll(crudTypes.product);
    getAll(crudTypes.category);
  }, [getAll]);

  useEffect(() => {
    let updateStatusTimer = setTimeout(() => setUpdateStatus(''), 5000);
    return () => {
      clearTimeout(updateStatusTimer);
    };
  }, [updateStatus]);
  useEffect(() => {
    let deleteStatusTimer = setTimeout(() => setDeleteStatus(''), 5000);
    return () => {
      clearTimeout(deleteStatusTimer);
    };
  }, [deleteStatus]);

  const changeUpdateStatus = (status) => {
    if (activeProduct) getById(crudTypes.category, activeProduct.categoryId);
    setUpdateStatus(status);
  };

  const handleStatusChange = () => {
    if (activeProduct) {
      props.toggleProductStatus(activeProduct.id);
    }
  };

  const deleteProd = () => {
    if (window.confirm(`Are you sure you want to delete ${activeProduct.name}?`)) {
      props.remove(activeProduct.id, crudTypes.product, () => {
        getAll(crudTypes.category);
        props.history.push('/dashboard/products');
      });
    }
  };

  const exportCSV = () => {
    if (activeProduct) {
      CSVFileExporter(`product-${activeProduct.id}.csv`, ProductCsvMapper([activeProduct]));
    }
  };
  return (
    activeProduct && (
      <Formik
        enableReinitialize
        initialValues={{
          name: (activeProduct && activeProduct.name) || '',
          imagePath: (activeProduct && activeProduct.imagePath) || '',
          price: (activeProduct && activeProduct.price) || '',
          description: (activeProduct && activeProduct.description) || '',
          isCombo: (activeProduct && activeProduct.isCombo) || false,
          hiddenForClients: (activeProduct && activeProduct.hiddenForClients) || false,
          fractionalQuantity: (activeProduct && activeProduct.productType === 1) || false,
          category: {
            value: (activeProduct && activeProduct.categoryId) || 0,
            label: (activeProduct && getCategoryName(activeProduct.categoryId)) || '',
          },
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required('Product Name is required')
            .test('len', 'Max 45 characters allowed', (val) => val.length < 46),
          price: Yup.number().required('Price is required').positive('Price must be over 0').min(1),
          description: Yup.string().test(
            'len',
            'Max 125 characters allowed',
            (val) => val?.length < 126 || val === null || val === undefined,
          ),
          category: Yup.object()
            .shape({
              label: Yup.string(),
              value: Yup.number(),
            })
            .required('Category is required'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          const editProduct = {
            ...activeProduct,
          };

          editProduct.name = values.name;
          editProduct.imagePath = values.imagePath;
          editProduct.price = values.price;
          editProduct.description = values.description;
          editProduct.categoryId = Number(values.category.value);
          editProduct.categoryName = values.category.label;
          editProduct.isCombo = values.isCombo;
          editProduct.hiddenForClients = values.hiddenForClients;
          editProduct.productType = values.fractionalQuantity ? 1 : 0;
          props.edit(activeProduct.id, crudTypes.product, editProduct, changeUpdateStatus);
          setSubmitting(true);
        }}
      >
        {(innerProps) => {
          const { values, touched, errors, handleChange, handleSubmit, setFieldValue } = innerProps;
          return (
            <Page>
              <Prompt
                when={innerProps.dirty}
                message="You have unsaved changes. Are you sure you want to leave?"
              />
              <EditProductHeaderWrapper>
                <EditProductTitle>
                  <Link onClick={props.history.goBack} style={{ marginRight: '10px' }}>
                    <Icon.BackProducts fill={colors.blue} />
                  </Link>
                  <span>Edit Product</span>
                  <EditProductDate>
                    Date Created:{' '}
                    {activeProduct && format(new Date(activeProduct.creationDate), 'MM-DD-YYYY')}
                  </EditProductDate>
                  {/* <ProductsRevenue>
                  {`$${(activeProduct && activeProduct.revenue) || 0}`}
                </ProductsRevenue> */}
                </EditProductTitle>

                <ProductHeaderActions>
                  <Button.WhiteButton
                    color={colors.textColor}
                    text={'Export CSV'}
                    style={{ marginRight: 22 }}
                    onClick={exportCSV}
                  />
                  <Button.Button
                    color={colors.blue}
                    text={'Save'}
                    onClick={() => handleSubmit()}
                    disabled={!innerProps.dirty}
                  />
                  <Button.Button
                    color={colors.red}
                    text={'Delete'}
                    style={{ marginLeft: 22 }}
                    onClick={() => deleteProd()}
                  />
                </ProductHeaderActions>
              </EditProductHeaderWrapper>
              <NotificationWrapper>
                <Alert show={updateStatus === 'OK'} message="Update successful!" />
                <ErrorWrapper show={updateStatus}>
                  <Icons.XIcon fill={colors.red} style={{ marginRight: 10 }} width={16} />
                  <span>Error editing the product</span>
                </ErrorWrapper>
                <ErrorWrapper show={deleteStatus}>
                  <Icons.XIcon fill={colors.red} style={{ marginRight: 10 }} />
                  <span>Error deleting the product</span>
                </ErrorWrapper>
              </NotificationWrapper>
              <EditProductBody>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                  }}
                >
                  <EditProductLabel fontSize={16}>
                    {`${
                      checkIfCategoryTypeisCombo(activeProduct.categoryId) ? 'Extra ' : ''
                    }Product Details`}
                  </EditProductLabel>
                  {activeProduct && (
                    <StatusWrapper>
                      <Toggle
                        show={activeProduct.disabled}
                        toggle={handleStatusChange}
                        showLabel={!activeProduct.disabled ? 'Active' : 'Inactive'}
                        color={!activeProduct.disabled ? colors.blue : colors.darkGrey}
                      />
                    </StatusWrapper>
                  )}
                </div>
                <EditProductDetail>
                  <AddItemPopup.InputRow style={{ paddingRight: '20px' }}>
                    <UploadInput
                      labelText={'Upload Image'}
                      imagePath={values.imagePath}
                      uriGenerator={(uri) => setFieldValue('imagePath', uri)}
                    />
                  </AddItemPopup.InputRow>
                  <div>
                    <AddItemPopup.InputRow style={{ flexBasis: '240px', paddingRight: '20px' }}>
                      <LabelWrapper>
                        <Label>Product Name</Label>
                      </LabelWrapper>
                      <Input
                        style={{ height: 40 }}
                        value={values.name}
                        onChange={handleChange}
                        id={'name'}
                        autoComplete="off"
                      />
                      {errors.name && touched.name ? (
                        <WarningWrapper>
                          <Icons.WarningIcon
                            fill={colors.yellow}
                            style={{ marginRight: 10 }}
                            width={16}
                          />
                          {errors.name}
                        </WarningWrapper>
                      ) : null}
                    </AddItemPopup.InputRow>
                    <AddItemPopup.InputRow style={{ flexBasis: '240px', paddingRight: '20px' }}>
                      <LabelWrapper>
                        <Label>Category Name</Label>
                      </LabelWrapper>
                      <Select
                        options={getOptions()}
                        onChange={(val) => setFieldValue('category', val)}
                        isSearchable={'false'}
                        style={{ width: '-webkit-fill-available' }}
                        name={'category'}
                        value={values.category}
                      />
                      {errors.category && touched.category ? (
                        <WarningWrapper>
                          <Icons.WarningIcon
                            fill={colors.yellow}
                            style={{ marginRight: 10 }}
                            width={16}
                          />
                          {errors.category}
                        </WarningWrapper>
                      ) : null}
                    </AddItemPopup.InputRow>
                  </div>
                  <div style={{ flex: 1 }}>
                    <AddItemPopup.InputRow style={{ width: '490px', display: 'flex' }}>
                      <div style={{ marginRight: 27 }}>
                        <LabelWrapper>
                          <Label>Price</Label>
                        </LabelWrapper>
                        <Input
                          style={{ height: 40 }}
                          addonBefore={facebookPages?.activePage?.currency}
                          value={values.price}
                          onChange={handleChange}
                          id={'price'}
                          autoComplete="off"
                          type={'number'}
                          min={1}
                          step={0.01}
                        />
                      </div>
                      {errors.price && touched.price ? (
                        <WarningWrapper>
                          <Icons.WarningIcon
                            fill={colors.yellow}
                            style={{ marginRight: 10 }}
                            width={16}
                          />
                          {errors.price}
                        </WarningWrapper>
                      ) : null}
                    </AddItemPopup.InputRow>
                    {!checkIfCategoryTypeisCombo(activeProduct.categoryId) && (
                      <AddItemPopup.InputRow style={{ width: '100%', maxWidth: '550px' }}>
                        <LabelWrapper>
                          <Label>Description</Label>
                        </LabelWrapper>
                        <Input
                          style={{ height: 40 }}
                          labelText={'Description'}
                          value={values.description}
                          onChange={handleChange}
                          id={'description'}
                          autoComplete="off"
                        />
                        {errors.description && touched.description ? (
                          <WarningWrapper>
                            <Icons.WarningIcon
                              fill={colors.yellow}
                              style={{ marginRight: 10 }}
                              width={16}
                            />
                            {errors.description}
                          </WarningWrapper>
                        ) : null}
                      </AddItemPopup.InputRow>
                    )}
                  </div>
                </EditProductDetail>
                <EditProductLabel
                  fontSize={16}
                  style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-between' }}
                >
                  <Toggle
                    show={!values.fractionalQuantity}
                    toggle={() => {
                      const toggledFractionalQuantity = !values.fractionalQuantity;
                      props.edit(
                        activeProduct.id,
                        crudTypes.product,
                        {
                          ...activeProduct,
                          productType: toggledFractionalQuantity ? 1 : 0,
                        },
                        changeUpdateStatus,
                      );
                    }}
                    showLabel={<span style={{ fontSize: 16 }}>Fractional Quantity</span>}
                    color={values.fractionalQuantity ? colors.blue : colors.darkGrey}
                  />
                </EditProductLabel>
                <Toggle
                  show={values.hiddenForClients}
                  toggle={() => {
                    props.edit(
                      activeProduct.id,
                      crudTypes.product,
                      {
                        ...activeProduct,
                        hiddenForClients: !values.hiddenForClients,
                      },
                      changeUpdateStatus,
                    );
                  }}
                  showLabel={
                    <span style={{ fontSize: 16 }}>Show this product on client iframe</span>
                  }
                  color={!values.hiddenForClients ? colors.blue : colors.darkGrey}
                />
                {!checkIfCategoryTypeisCombo(activeProduct.categoryId) && (
                  <ChooseToppingWrapper>
                    <EditProductLabel
                      fontSize={16}
                      style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Toggle
                        show={!values.isCombo}
                        toggle={() => {
                          props.edit(
                            activeProduct.id,
                            crudTypes.product,
                            {
                              ...activeProduct,
                              isCombo: !values.isCombo,
                            },
                            changeUpdateStatus,
                          );
                        }}
                        showLabel={<span style={{ fontSize: 16 }}>Is this product a combo?</span>}
                        color={values.isCombo ? colors.blue : colors.darkGrey}
                      />
                    </EditProductLabel>
                    <EditProductLabel
                      fontSize={12}
                      style={{
                        color: '#748AA1',
                        marginBottom: 8,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      Choose Toppings
                    </EditProductLabel>
                    <ToppingsWrapper isActive={activeProduct.isCombo}>
                      {props.categories?.data.map(
                        (cat) =>
                          cat.categoryType === 1 && (
                            <ToppingWrapper key={cat.id}>
                              <CheckBox
                                checked={
                                  activeProduct.toppings.filter((t) => t.categoryId === cat.id)
                                    .length > 0
                                }
                                onClick={() => {
                                  activeProduct.toppings.filter((t) => t.categoryId === cat.id)
                                    .length > 0
                                    ? props.unLinkProductTopping(
                                        cat.id,
                                        activeProduct.id,
                                        facebookPages?.activePage.id,
                                        () => getAll(crudTypes.product),
                                      )
                                    : props.linkProductTopping(
                                        cat.id,
                                        activeProduct.id,
                                        facebookPages?.activePage.id,
                                        () => getAll(crudTypes.product),
                                      );
                                }}
                                text={cat.name}
                              />
                            </ToppingWrapper>
                          ),
                      )}
                    </ToppingsWrapper>
                  </ChooseToppingWrapper>
                )}
                <ProductStatistics>
                  <EditProductLabel fontSize={16}>Product Statistics</EditProductLabel>
                  <ProductStatisticsButtons>
                    {/* <div>
                      <LabelWrapper>
                        <Label>Clients</Label>
                      </LabelWrapper>
                      <ProductStatBox>{`${activeProduct && activeProduct.clients}`}</ProductStatBox>
                    </div> */}
                    <div>
                      <LabelWrapper>
                        <Label>Orders</Label>
                      </LabelWrapper>
                      <ProductStatBox>{`${
                        activeProduct && activeProduct.totalQuantity
                      }`}</ProductStatBox>
                    </div>
                    <div>
                      <LabelWrapper>
                        <Label>Revenue</Label>
                      </LabelWrapper>
                      <ProductStatBox>
                        {activeProduct && activeProduct.revenue
                          ? `${currencyFormatter(activeProduct && activeProduct.revenue)}`
                          : currencyFormatter(0)}
                      </ProductStatBox>
                    </div>
                  </ProductStatisticsButtons>
                </ProductStatistics>
              </EditProductBody>
            </Page>
          );
        }}
      </Formik>
    )
  );
};

EditProduct.path = '/dashboard/product/editproduct';

const mapStateToProps = (state) => ({
  facebookPages: state.facebookPages,
  products: state.products,
  categories: state.categories,
});

export default withRouter(
  connect(mapStateToProps, {
    edit,
    remove,
    toggleProductStatus,
    getAll,
    getById,
    linkProductTopping,
    unLinkProductTopping,
  })(EditProduct),
);
export { EditProduct };
