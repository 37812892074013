import React from 'react';
import styled from 'styled-components';
import logo from 'assets/icons/logo.svg';
import mediaQueries from 'app/shared/helpers/mediaQueries';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (min-width: 901px) {
    padding-top: 40px;
    padding-right: ${(props) => (props.openedIframe ? 40 : 100)}px;
    padding-left: ${(props) => (props.openedIframe ? 40 : 100)}px;
    padding-bottom: 25px;
  }

  @media only screen and (max-width: 900px) {
    padding: 20px 20px 25px 20px;
  }
`;

const Logo = styled.img`
  ${mediaQueries.mobile(`
    width: 104px;
    height: 20px;
  `)}
`;

const NavbarLink = styled.a`
  display: flex;
  align-items: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #1d2036;
  margin-left: 15px;

  @media only screen and (max-width: 375px) {
    margin-left: 10px;
  }
`;

const PhoneNumber = styled.span`
  font-size: 12px;
  margin-left: 5px;
  color: #3185fc;

  @media only screen and (max-width: 375px) {
    font-size: 10px;
  }
`;

const TopNavbarWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TopNavbar = () => (
  <TopNavbarWrapper>
    {/* <NavbarLink href={'#'}>Online Orders</NavbarLink>
    <NavbarLink href={'#'}>Table Orders</NavbarLink>
    <NavbarLink href={'#'}>Takeaway</NavbarLink>
    <NavbarLink href={'#'}>Reservations</NavbarLink>
    <NavbarLink href={'#'}>POS</NavbarLink>
    <NavbarLink href={'#'}>CRM</NavbarLink>
    <NavbarLink href={'#'}>Marketing</NavbarLink>
    <NavbarLink href={'#'}>About</NavbarLink>
    <NavbarLink href={'#'}>Contact</NavbarLink> */}
    <NavbarLink href="tel:+355696031515">
      <PhoneNumber>Call: 0696031515</PhoneNumber>
    </NavbarLink>
    {/* <NavbarLink href={'https://business.orderoo.ai'}>Business</NavbarLink> */}
  </TopNavbarWrapper>
);

export default ({ openedIframe }) => (
  <HeaderWrapper openedIframe={openedIframe}>
    <a href="/">
      <Logo src={logo} width={136} alt={'Orderoo Logo'} />
    </a>
    <TopNavbar />
  </HeaderWrapper>
);
