export default (type) => {
  const initialState = {
    data: [],
    current: {},
  };

  return (state = initialState, action) => {
    switch (action.type) {
      case `GET_ALL_${type.tag}_OK`:
        if (action.totalPages !== undefined) {
          state.current.totalPages = action.totalPages;
        }
        return {
          ...state,
          data: [...action.data],
          current: { ...state.current },
        };
      case `GET_${type.tag}_BY_ID_OK`:
        return {
          ...state,
          data: [...state.data],
          current: { ...action.data },
        };
      case `POST_${type.tag}_OK`:
        return {
          ...state,
          data: [...state.data, action.data],
          current: { ...state.current },
        };
      case `GET_ALL_${type.tag}`:
        return {
          ...state,
          data: [...state.data, action.data],
          current: { ...state.current },
        };
      case `GET_${type.tag}_BY_PAGE_ID`:
        return {
          ...state,
          data: [...state.data, action.data],
          current: { ...state.current },
        };
      case `PUT_${type.tag}_OK`: {
        const newData = [...state.data];
        const itemIndex = state.data.findIndex((x) => x.id === action.data.id);
        if (itemIndex < 0) {
          newData.push(action.data);
        } else {
          newData[itemIndex] = action.data;
        }

        return {
          ...state,
          data: newData,
          current: { ...state.current },
        };
      }
      case `DELETE_${type.tag}_OK`:
        return {
          ...state,
          data: [...state.data.filter((x) => x.id !== action.data)],
          current: {},
        };
      default:
        return state;
    }
  };
};
