import React from 'react';
import styled from 'styled-components';
import shared from 'app/shared';

import coverImage from 'assets/images/intro-chatbot.png';

const {
  helpers: { colors },
  views: { Text },
} = shared;

const ConversationBase = styled.div`
  border-radius: 15px;
  box-sizing: border-box;
  padding: 12px;
`;

const TextConversation = styled(ConversationBase)`
  min-width: 188px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  height: 44px;
  border-radius: 999px;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: center;
`;

const BorderButton = styled.div`
  border: 2px solid ${props => props.color};
  color: ${props => props.color};
  display: inline-block;
  text-align: center;
  border-radius: 99px;
  padding: 2px 20px;
`;

const BorderButtonWrapper = styled.div`
  margin: 0px 6.5px;
`;

const OptionButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const OptionButtonsWrapper = styled.div`
  margin-top: 10px;
`;

const AvatarColors = {
  pink: {
    backgroundColor: '#D192DB',
    textColor: '#6F5177',
  },
  green: {
    backgroundColor: '#97DE9A',
    textColor: '#4F8752',
  },
};

const PersonAvatar = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 99px;
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  background-color: ${props => props.color.backgroundColor};
  color: ${props => props.color.textColor};
`;

const MeAvatar = () => <PersonAvatar color={AvatarColors.pink}>N</PersonAvatar>;
const BusinessAvatar = () => <PersonAvatar color={AvatarColors.green}>N</PersonAvatar>;

const MeTextConversation = styled(TextConversation)`
  color: ${colors.white};
  background-color: ${colors.blue};
`;

const BusinessTextConversation = styled(TextConversation)`
  color: ${colors.textColor};
  background-color: ${colors.white};
`;

const Direction = {
  left: 'left',
  right: 'right',
};

const ConversationRowContent = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction === Direction.right ? 'row-reverse' : 'row')};
`;

const ConversationRowWrapper = styled.div`
  margin-bottom: 38px;
`;

const MessageWrapper = styled.div`
  padding: 0 100px 0 100px;
`;

const ButtonColors = {
  blue: {
    textColor: colors.white,
    backgroundColor: colors.blue,
  },
  grey: {
    textColor: colors.darkestGrey,
    backgroundColor: '#F9F9F9',
  },
};

const Button = styled.div`
  height: 24px;
  line-height: 24px;
  border-radius: 5px;
  font-size: 10px;
  color: ${props => props.color.textColor};
  background-color: ${props => props.color.backgroundColor};
  text-align: center;
  display: block;
`;

const Card = styled(ConversationBase)`
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGrey};
  width: 214px;
  padding: 0;
`;

const CardContent = styled.div`
  padding: 12px;
`;

const CardWithImage = ({ children, image }) => (
  <Card>
    <div
      style={{ background: `url(${image})`, backgroundSize: 'cover', height: 115 }}
      height={115}
    />
    <div>{children}</div>
  </Card>
);

const ConversationRow = ({ children, direction, avatar: Avatar, ...rest }) => (
  <ConversationRowContent direction={direction} {...rest}>
    <Avatar />
    <MessageWrapper>{children}</MessageWrapper>
  </ConversationRowContent>
);

const MeConversationRow = ({ children, options, ...rest }) => (
  <ConversationRow direction={Direction.right} avatar={() => <MeAvatar />} {...rest}>
    {children}
  </ConversationRow>
);
const BusinessConversationRow = ({ children, options, ...rest }) => (
  <ConversationRow direction={Direction.left} avatar={() => <BusinessAvatar />} {...rest}>
    {children}
  </ConversationRow>
);

const Conversation = () => (
  <div>
    <ConversationRowWrapper>
      <BusinessConversationRow>
        <CardWithImage image={coverImage}>
          <CardContent style={{ paddingTop: 15 }}>
            <Text.H6>Hello Nick! How can i help you?</Text.H6>
            <div style={{ padding: '17px 0 5px' }}>
              <Button color={ButtonColors.blue}>Make an Order</Button>
            </div>
            <Button color={ButtonColors.grey}>Ask a Question</Button>
          </CardContent>
        </CardWithImage>
      </BusinessConversationRow>
    </ConversationRowWrapper>
    <ConversationRowWrapper>
      <MeConversationRow>
        <MeTextConversation>Make an Order</MeTextConversation>
      </MeConversationRow>
    </ConversationRowWrapper>
    <ConversationRowWrapper>
      <BusinessConversationRow>
        <div style={{ display: 'flex' }}>
          <Card>
            <CardContent>
              <div style={{ marginBottom: 12 }}>
                <Text.H6>Pizza</Text.H6>
              </div>
              <Button color={ButtonColors.blue}>Pizza ✓</Button>
            </CardContent>
          </Card>
          <div style={{ padding: '0 15px' }}>
            <Card>
              <CardContent>
                <div style={{ marginBottom: 12 }}>
                  <Text.H6>Pasta</Text.H6>
                </div>
                <Button color={ButtonColors.grey}>Pasta ✓</Button>
              </CardContent>
            </Card>
          </div>
          <Card>
            <CardContent>
              <div style={{ marginBottom: 12 }}>
                <Text.H6>Meat</Text.H6>
              </div>
              <Button color={ButtonColors.grey}>Meat ✓</Button>
            </CardContent>
          </Card>
        </div>
      </BusinessConversationRow>
    </ConversationRowWrapper>
    <ConversationRowWrapper>
      <MeConversationRow>
        <MeTextConversation>Mozarella ✓</MeTextConversation>
      </MeConversationRow>
    </ConversationRowWrapper>
    <ConversationRowWrapper>
      <BusinessConversationRow>
        <BusinessTextConversation>Please enter the quentity (ex: 3)</BusinessTextConversation>
      </BusinessConversationRow>
    </ConversationRowWrapper>
    <ConversationRowWrapper>
      <MeConversationRow>
        <MeTextConversation>3</MeTextConversation>
      </MeConversationRow>
    </ConversationRowWrapper>
    <ConversationRowWrapper>
      <BusinessConversationRow>
        <BusinessTextConversation>Do you want to add another product?</BusinessTextConversation>
        <OptionButtonsWrapper>
          <OptionButtonsContainer>
            <BorderButtonWrapper>
              <BorderButton color={colors.blue}>Yes</BorderButton>
            </BorderButtonWrapper>
            <BorderButtonWrapper>
              <BorderButton color={colors.blue}>No</BorderButton>
            </BorderButtonWrapper>
          </OptionButtonsContainer>
        </OptionButtonsWrapper>
      </BusinessConversationRow>
    </ConversationRowWrapper>
    <ConversationRowWrapper>
      <MeConversationRow>
        <MeTextConversation>No</MeTextConversation>
      </MeConversationRow>
    </ConversationRowWrapper>
    <ConversationRowWrapper>
      <BusinessConversationRow>
        <BusinessTextConversation>
          Do you have any comment about your order?
        </BusinessTextConversation>
        <OptionButtonsWrapper>
          <OptionButtonsContainer>
            <BorderButtonWrapper>
              <BorderButton color={colors.blue}>Yes</BorderButton>
            </BorderButtonWrapper>
            <BorderButtonWrapper>
              <BorderButton color={colors.blue}>No</BorderButton>
            </BorderButtonWrapper>
          </OptionButtonsContainer>
        </OptionButtonsWrapper>
      </BusinessConversationRow>
    </ConversationRowWrapper>
    <ConversationRowWrapper>
      <MeConversationRow>
        <MeTextConversation>No pepper</MeTextConversation>
      </MeConversationRow>
    </ConversationRowWrapper>
    <ConversationRowWrapper>
      <BusinessConversationRow>
        <BusinessTextConversation>Please write your address.</BusinessTextConversation>
      </BusinessConversationRow>
    </ConversationRowWrapper>
    <ConversationRowWrapper>
      <MeConversationRow>
        <MeTextConversation>Stephen R. Gregg Park</MeTextConversation>
      </MeConversationRow>
    </ConversationRowWrapper>
    <ConversationRowWrapper>
      <BusinessConversationRow>
        <BusinessTextConversation>Please write your phone number.</BusinessTextConversation>
      </BusinessConversationRow>
    </ConversationRowWrapper>
    <ConversationRowWrapper>
      <MeConversationRow>
        <MeTextConversation>+1 913 218 ****</MeTextConversation>
      </MeConversationRow>
    </ConversationRowWrapper>
    <ConversationRowWrapper>
      <BusinessConversationRow>
        <BusinessTextConversation>Please write your Email.</BusinessTextConversation>
      </BusinessConversationRow>
    </ConversationRowWrapper>
    <ConversationRowWrapper>
      <MeConversationRow>
        <MeTextConversation>nickwhite@gmail.com</MeTextConversation>
      </MeConversationRow>
    </ConversationRowWrapper>
    <ConversationRowWrapper>
      <BusinessConversationRow>
        <BusinessTextConversation>Order Card</BusinessTextConversation>
      </BusinessConversationRow>
    </ConversationRowWrapper>
    <ConversationRowWrapper>
      <BusinessConversationRow>
        <BusinessTextConversation>Proceed to payment?</BusinessTextConversation>
        <OptionButtonsWrapper>
          <OptionButtonsContainer>
            <BorderButtonWrapper>
              <BorderButton color={colors.blue}>Yes</BorderButton>
            </BorderButtonWrapper>
            <BorderButtonWrapper>
              <BorderButton color={colors.blue}>No</BorderButton>
            </BorderButtonWrapper>
          </OptionButtonsContainer>
        </OptionButtonsWrapper>
      </BusinessConversationRow>
    </ConversationRowWrapper>
    <ConversationRowWrapper>
      <MeConversationRow>
        <MeTextConversation>Yes</MeTextConversation>
      </MeConversationRow>
    </ConversationRowWrapper>
    <ConversationRowWrapper>
      <BusinessConversationRow>
        <BusinessTextConversation>Please select your payment method.</BusinessTextConversation>
        <OptionButtonsWrapper>
          <OptionButtonsContainer>
            <BorderButtonWrapper>
              <BorderButton color={colors.blue}>Cash</BorderButton>
            </BorderButtonWrapper>
            <BorderButtonWrapper>
              <BorderButton color={colors.blue}>Credit/Debit Card</BorderButton>
            </BorderButtonWrapper>
          </OptionButtonsContainer>
        </OptionButtonsWrapper>
      </BusinessConversationRow>
    </ConversationRowWrapper>
    <ConversationRowWrapper>
      <MeConversationRow>
        <MeTextConversation>Credit/Debit Card</MeTextConversation>
      </MeConversationRow>
    </ConversationRowWrapper>
    <ConversationRowWrapper>
      <BusinessConversationRow>
        <BusinessTextConversation>Payment Card</BusinessTextConversation>
      </BusinessConversationRow>
    </ConversationRowWrapper>
  </div>
);

export default Conversation;
