import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Card from 'app/shared/views/card';
import Text from 'app/shared/views/text';
import Input from 'app/shared/views/input';
import Icons from 'app/shared/views/icons';
import { BaseButton } from 'app/shared/views/buttons';
import LocationPicker from 'app/shared/views/locationPicker';
import colors from 'app/shared/helpers/colors';
// import currencyFormatter from 'app/shared/helpers/currencyFormatter';
import * as moment from 'moment';

const AreaCoverageSetupWrapper = styled.div`
  margin: 0 auto;
`;

const AreaCoverageMapWrapper = styled.div`
  margin: 10px 0;
  height: 600px;

  & > div {
    height: 100%;
  }
`;

const ZoneInfoList = styled.div`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
`;

const ZoneName = styled.div`
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.textColor};
  white-space: nowrap;
`;

const ZoneInfoWrapper = styled(Card)`
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 15px;
  box-sizing: border-box;
  margin-top: 20px;
  ${(props) => (props.hover ? `background: ${colors.lightGrey};` : '')}
  :hover {
    background: ${colors.lightGrey};
  }

  & > * {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 30px;
  }
`;

const ZoneInfoInput = styled(Input.Input)`
  margin: 0 15px;

  & input {
    padding: 5px;
    font-size: 15px;
  }
`;

const ProductAddBtn = (props) => (
  <Icons.Add fill={colors.blue} width="16px" height="16px" {...props} />
);

const ProductRemoveBtn = (props) => (
  <Icons.Remove fill={colors.textColor} width="16px" height="16px" {...props} />
);
const ZoneInfo = ({ name, zone, onChangeZone, onDeleteZone, activePage, ...props }) => {
  const { deliveryFee, deliveryFeeExemptValue, deliveryTime } = zone;
  const [deliveryTimeMins, setDeliveryTimeMins] = useState(() => {
    if (deliveryTime !== undefined && deliveryTime !== null && deliveryTime !== '') {
      const time = moment(deliveryTime, 'HH:mm:ss');
      const mins = time.hours() * 60 + time.minutes();
      return mins;
    }
  });

  const increaseFee = () => {
    const newValue = zone.deliveryFee + 1;
    onChangeZone({ ...zone, deliveryFee: newValue });
  };
  const decreaseFee = () => {
    const newValue = zone.deliveryFee - 1 < 0 ? zone.deliveryFee : zone.deliveryFee - 1;
    onChangeZone({ ...zone, deliveryFee: newValue });
  };

  const increaseFeeExempt = () => {
    const newValue = zone.deliveryFeeExemptValue + 1;
    onChangeZone({ ...zone, deliveryFeeExemptValue: newValue });
  };
  const decreaseFeeExempt = () => {
    const newValue =
      zone.deliveryFeeExemptValue - 1 < 0
        ? zone.deliveryFeeExemptValue
        : zone.deliveryFeeExemptValue - 1;
    onChangeZone({ ...zone, deliveryFeeExemptValue: newValue });
  };

  // const increaseTime = () => {
  //   const time = moment.duration(zone.deliveryTime).add(1, 'minute');
  //   const timeStr = moment.utc(time.asMilliseconds()).format('HH:mm:ss');
  //   onChangeZone({ ...zone, deliveryTime: timeStr });
  // };
  // const decreaseTime = () => {
  //   const time = moment.duration(zone.deliveryTime).subtract(1, 'minute');
  //   const timeStr = moment.utc(time.asMilliseconds()).format('HH:mm:ss');
  //   if (time < moment.duration(0)) return;
  //   onChangeZone({ ...zone, deliveryTime: timeStr });
  // };

  const updateDeliveryTime = (deliveryTimeMins) => {
    setDeliveryTimeMins(deliveryTimeMins);
    const deliveryTime = moment()
      .startOf('day')
      .add(deliveryTimeMins, 'minutes')
      .format('HH:mm:ss');
    onChangeZone({ ...zone, deliveryTime: deliveryTime });
  };

  return (
    <ZoneInfoWrapper {...props}>
      <ZoneName>{name}</ZoneName>

      <div>
        <span style={{ marginRight: '20px', whiteSpace: 'nowrap' }}>Delivery Fee:</span>
        <ProductRemoveBtn onClick={decreaseFee} />
        <ZoneInfoInput
          start={<span style={{ marginLeft: '5px' }}>{activePage.currency}</span>}
          height={24}
          value={deliveryFee}
          onChange={(e) => {
            onChangeZone({ ...zone, deliveryFee: e.target.value });
          }}
        />
        <ProductAddBtn onClick={increaseFee} />
      </div>

      <div>
        <span style={{ marginRight: '20px', whiteSpace: 'nowrap' }}>Fee Exempt:</span>
        <ProductRemoveBtn onClick={decreaseFeeExempt} />
        <ZoneInfoInput
          start={<span style={{ marginLeft: '5px' }}>{activePage.currency}</span>}
          height={24}
          value={deliveryFeeExemptValue}
          onChange={(e) => {
            onChangeZone({ ...zone, deliveryFeeExemptValue: e.target.value });
          }}
        />
        <ProductAddBtn onClick={increaseFeeExempt} />
      </div>

      <div>
        <span style={{ marginRight: '20px', whiteSpace: 'nowrap' }}>Delivery Time:</span>
        <ZoneInfoInput
          start={<span style={{ marginLeft: '5px' }}>Min</span>}
          height={24}
          value={deliveryTimeMins ? deliveryTimeMins.toString() : ''}
          onChange={(e) => {
            const { value } = e.target;
            if (value !== '') {
              const mins = parseInt(value, 10);
              if (isNaN(mins)) return;
              updateDeliveryTime(mins);
            }
          }}
        />
      </div>
      <Text.Link.Link onClick={onDeleteZone} color={colors.red}>
        <Icons.Delete />
      </Text.Link.Link>
    </ZoneInfoWrapper>
  );
};

const AddressInputWrapper = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    flex: 1;
  }
`;

const AddressInput = styled(Input.Input)`
  background-color: ${colors.lightGrey};
  opacity: 0.5;
  border-radius: 3px;

  & > input {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;

    &:focus {
      border: 1px solid ${colors.blue};
    }

    &::placeholder {
      color: ${colors.grey};
    }
  }
`;

const AddressInputBtn = styled(BaseButton)`
  height: 34px;
  width: 36px;
  padding: 7px;
  background-color: #f1bf3c;
  border-radius: 3px;
  margin-left: 15px;
  justify-content: center;
`;

const addZoneButtonStyles = {
  width: '100%',
  justifyContent: 'center',
};

const AddZoneButton = ({ addingZone, setAddingZone }) =>
  addingZone ? (
    <ZoneInfoWrapper hover>
      <ZoneName style={addZoneButtonStyles}>
        Define your new delivery zone by clicking on the map
      </ZoneName>
    </ZoneInfoWrapper>
  ) : (
    <ZoneInfoWrapper style={{ cursor: 'pointer' }} onClick={() => setAddingZone(true)}>
      <ZoneName style={addZoneButtonStyles}>Add New Zone</ZoneName>
    </ZoneInfoWrapper>
  );

const AreaCoverageSetup = ({
  activePage = {},
  geoRegions,
  setGeoRegions,
  setBusinessAddress,
  businessAddress,
}) => {
  const onChangeGeoRegion = (i, updatedGeoRegion) => {
    setGeoRegions(geoRegions.map((g, _i) => (i === _i ? updatedGeoRegion : g)));
  };

  const onDeleteGeoRegion = (i) => {
    setGeoRegions(geoRegions.filter((g, _i) => i !== _i));
  };

  const onZoneAdded = (restrictedArea) => {
    const geoRegion = {
      deliveryFee: 0,
      deliveryFeeExemptValueValue: 0,
      restrictedArea: restrictedArea,
    };
    setGeoRegions([...geoRegions, geoRegion]);
    setAddingZone(false);
  };

  const [addingZone, setAddingZone] = useState(false);
  const [hoveredGeoRegion, setHoveredGeoRegion] = useState(null);
  const [address, setAddress] = useState('');
  const [resetLocation, setResetLocation] = useState(false);
  const [location, setLocation] = useState(null);
  const addressInputRef = useRef(null);
  useEffect(() => {
    if (location && location.address) {
      setAddress(location.address);
    }
    setBusinessAddress && setBusinessAddress(location);
  }, [location, setBusinessAddress]);

  useEffect(() => {
    if (businessAddress) {
      setLocation(businessAddress);
    }
  }, [businessAddress]);

  return (
    <AreaCoverageSetupWrapper>
      <AddressInputWrapper>
        <AddressInput
          type="text"
          value={address}
          placeholder="Input address"
          height="35"
          inputRef={addressInputRef}
          onChange={(e) => {
            setAddress(e.target.value);
          }}
        />
        <AddressInputBtn
          onClick={() => {
            setResetLocation(true);
          }}
        >
          <Icons.Pick fill={colors.white} />
        </AddressInputBtn>
      </AddressInputWrapper>
      <AreaCoverageMapWrapper>
        <LocationPicker
          // theme="silver"
          location={location}
          suggestInputRef={addressInputRef}
          onLocationPicked={(location) => {
            setResetLocation(false);
            setLocation(location);
          }}
          resetToCurrent={resetLocation}
          zones={geoRegions}
          addZone={addingZone}
          onZoneAdded={onZoneAdded}
          hoveredZone={hoveredGeoRegion}
          setHoveredZone={setHoveredGeoRegion}
        />
      </AreaCoverageMapWrapper>
      <ZoneInfoList zones={geoRegions}>
        {geoRegions.map((geoRegion, i) => (
          <ZoneInfo
            key={i}
            name={`Zone ${i + 1}`}
            zone={geoRegion}
            hover={hoveredGeoRegion === i}
            onChangeZone={(g) => onChangeGeoRegion(i, g)}
            onDeleteZone={() => onDeleteGeoRegion(i)}
            onMouseEnter={() => setHoveredGeoRegion(i)}
            onMouseLeave={() => setHoveredGeoRegion(null)}
            activePage={activePage}
          />
        ))}
        <AddZoneButton addingZone={addingZone} setAddingZone={setAddingZone} />
      </ZoneInfoList>
    </AreaCoverageSetupWrapper>
  );
};
export default AreaCoverageSetup;
