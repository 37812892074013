import React from 'react';
import shared from 'app/shared';
import colors from 'app/shared/helpers/colors';
import styled from 'styled-components';

const{
  views: {
    Text: { Link: { ExternalLink, }, Label: {Label,}},
  }
} = shared;


const ExternalLinkWrapper = styled.div`
  text-align: center;
  margin: 10% 0;
  text-decoration: underline;
  font-size: 150%;
`;

const labelStyle = {
  padding: '1% 2%',
  color: colors.veryDarkGrey,
  fontWeight: 500
};

const MainExternalLink = props => (
  <ExternalLinkWrapper>
    <ExternalLink>{props.children}</ExternalLink>
  </ExternalLinkWrapper>
);

const LocalLabel = ({text}) => (
  <Label outerStyle={labelStyle} text={text}/>
);

export default{
  MainExternalLink,
  LocalLabel,
};
