import React from 'react';
import styled from 'styled-components';
import shared from 'app/shared';
import StatefulButton from './statefulButton';

const {
  views: {
    Button: { RawButton },
  },
} = shared;

const TitleButton = styled(RawButton)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 75%;
  color: #748aa1;
  text-align: start;
  border-radius: 5% 0 0 5%;
  padding: 5%;
  background-color: #f9f9f9;
  background-repeat: no-repeat;
  padding-left: 25%;
`;

const ButtonContainer = styled.div`
  padding: 0;
  margin: 2.7% auto 0 auto;
  display: flex;
  width: 60%;
  font-size: 100%;

  @media (max-width: 960px) {
    width: 70%;
    font-size: 127.5%;
  }
`;

const pageButton = ({ title, pagePicture, ...rest }) => (
  <ButtonContainer>
    <TitleButton
      text={title}
      style={{ backgroundImage: `url(${pagePicture})`, backgroundSize: 'contain' }}
    />
    <StatefulButton {...rest} />
  </ButtonContainer>
);

export default pageButton;
