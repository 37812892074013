import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import Card from '../../views/card';
import Icons from '../../views/icons';
import Colors from '../../helpers/colors';

import ToggleValidator from '../../validators/toggle.validator';
import { BlackButton } from 'app/shared/views/buttons';

import hexToRGBA from '../../helpers/hexToRGBA';
import isEscape from '../../helpers/isEscape';
import slideFromTopAnimation from '../../helpers/slideFromTopAnimation';
import transition from '../../helpers/transition';
// import whatsappImage from '../../../../assets/images/whatsapp-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSms } from '@fortawesome/free-solid-svg-icons';

import InputPhoneNumber from './inputPhoneNumber';
import InputCode from './inputCode';
import { getVerificationCode, verifyCode } from 'actions/shoppingActions';

const OverlayWrapper = styled.div`
  background: ${hexToRGBA('#2A2E45', 0.8)};
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  transition: ${transition};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.show ? 1 : 0)};
  top: 0;
  left: 0;
  z-index: 9999;
  -webkit-transform: translate3d(0, 0, 0);
  padding: 0 !important;
`;

const Error = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  background: rgba(242, 69, 61, 0.2);
  color: #f2453d;
  margin-top: 10px;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  align-items: center;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 1px 5px;
  margin-left: 20px;
  margin-right: 20px;
`;

const CustomCard = styled(Card)`
  border-radius: 4px;
  box-shadow: 0px 16px 28px rgba(63, 86, 104, 0.1);
  position: relative;
  width: 100%;
  overflow: hidden;
  border: 0;
  display: flex;
  flex-direction: column;
`;

const CloseIcon = styled(Icons.Cross)`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 999;
  :hover {
    cursor: pointer;
  }
  background-color: #ffffff;
  border-radius: 999px;
`;

const ContentWrapper = styled.div`
  transition: 0.4s ease-in-out;
  position: relative;
  opacity: ${(props) => (props.show ? 1 : 0)};
  animation: ${(props) => (props.show ? slideFromTopAnimation : '')} 0.4s ease-in-out;
  width: calc(100% - 40px);
  max-width: 374px;
  z-index: 100;
`;

// const PhoneImage = styled.div`
//   background: url(${(props) => props.src});
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: cover;
//   height: 164px;
//   width: 170px;
//   margin: 30px 0;
//   align-self: center;
// `;

const Header = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  color: #1d2036;
  margin-left: 20px;
  margin-right: 20px;
`;

const SubHeader = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: #8f92a7;
  margin: 8px 20px 0;
`;

const enumValue = (name) => Object.freeze({ toString: () => name });

const Steps = Object.freeze({
  INPUT_NUMBER: enumValue('Steps.INPUT_NUMBER'),
  INPUT_CODE: enumValue('Steps.INPUT_CODE'),
  CONFIRMED: enumValue('Steps.CONFIRMED'),
});

const VERIFICATION_CODE_NUM_DIGITS = 4;

const VerifyPhoneNumber = (props) => {
  const dispatch = useDispatch();
  const client = useSelector((state) => state.shopping.client);

  const { show, toggle, setClientPhone, onConfirm } = props;
  const [step, setStep] = useState(Steps.INPUT_NUMBER);
  const [phoneNumber, setPhoneNumber] = useState(undefined);
  const [code, setCode] = useState(undefined);

  const [error, setError] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const inputCodeRef = useRef();

  useEffect(() => {
    setStep(Steps.INPUT_NUMBER);
    setStatus(undefined);
  }, [show]);

  useEffect(() => {
    client.phone && setPhoneNumber(client.phone);
  }, [client]);

  useEffect(() => {
    if (status === 0) {
      setError(undefined);
      setStep(Steps.INPUT_CODE);
    }

    if (status === 1) {
      setError(undefined);
      // setStep(Steps.CONFIRMED);
      setStep(Steps.INPUT_NUMBER);
      setClientPhone(phoneNumber);
      onConfirm && onConfirm();
      toggle();
    }

    if (status === 3) {
      setError('Please check the number!');
    }
  }, [status, onConfirm, phoneNumber, setClientPhone, toggle]);

  useEffect(() => {
    document.addEventListener('keyup', toggleOnEscape);
    return function cleanup() {
      document.removeEventListener('keyup', toggleOnEscape);
    };
  });

  const toggleOnEscape = (event) => {
    if (event.defaultPrevented) return;
    if (isEscape(event) && show) toggle();
  };

  const onNext = () => {
    if (step === Steps.INPUT_NUMBER) {
      dispatch(
        getVerificationCode(phoneNumber, (status) => {
          setStatus(status);
          setTimeout(() => {
            inputCodeRef.current && inputCodeRef.current.focus();
          }, 100);
        }),
      );
    } else if (step === Steps.INPUT_CODE) {
      if (code?.length === VERIFICATION_CODE_NUM_DIGITS) {
        dispatch(verifyCode(phoneNumber, code, setStatus));
      } else {
        setError('Please fill in the code!');
      }
    } else if (step === Steps.CONFIRMED) {
      setStep(Steps.INPUT_NUMBER);
      setClientPhone(phoneNumber);
      toggle();
    }
  };

  return (
    <OverlayWrapper show={show} onClick={toggle}>
      <ContentWrapper show={show} onClick={(event) => event.stopPropagation()}>
        <CustomCard style={{ maxHeight: '90vh', overflowY: 'scroll' }}>
          {/* <PhoneImage src={whatsappImage} /> */}
          <FontAwesomeIcon
            icon={faSms}
            size="lg"
            style={{
              height: 164,
              width: 170,
              alignSelf: 'center',
              margin: '30px 0',
              color: 'black',
            }}
          />
          <CloseIcon fill={'#2A2E45'} onClick={toggle} width={'8px'} height={'8px'} />
          <Header>
            {step === Steps.CONFIRMED ? 'Phone Number Confirmed' : 'Verify Phone Number via SMS'}
          </Header>
          <SubHeader>
            {step === Steps.CONFIRMED
              ? 'Thank you for yout patience. You have succesfully confirmed your number. Now you can continue your order.'
              : 'In order for us to remember and also contact you when your food is ready we encourage you to give us your phone number.'}
          </SubHeader>
          {error && <Error>{error}</Error>}
          {step === Steps.INPUT_NUMBER && (
            <InputPhoneNumber
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              error={error}
            />
          )}
          {step === Steps.INPUT_CODE && (
            <InputCode
              ref={inputCodeRef}
              code={code}
              setCode={setCode}
              setError={setError}
              numDigits={VERIFICATION_CODE_NUM_DIGITS}
            />
          )}
          {step === Steps.CONFIRMED && (
            <Icons.SuccessIcon
              style={{ margin: '40px auto' }}
              width={'26px'}
              height={'26px'}
              fill={Colors.green}
            />
          )}
          <BlackButton
            // disabled={}
            text={
              step === Steps.INPUT_NUMBER
                ? 'Next'
                : step === Steps.INPUT_CODE
                ? 'Confirm'
                : 'Continue Ordering'
            }
            onClick={() => onNext()}
            style={{ width: '100%', minHeight: 40, borderRadius: 0 }}
          />
        </CustomCard>
      </ContentWrapper>
    </OverlayWrapper>
  );
};

VerifyPhoneNumber.propTypes = {
  ...ToggleValidator.ToggleSchema,
};

export default VerifyPhoneNumber;
