import MessageToSection from 'app/marketing/components/messageTo';
import MessageInputSection from 'app/marketing/components/campaignMessageInput';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import shared from 'app/shared';
import { CAMPAIGN_STATUS } from 'app/marketing/constants';
import _ from 'lodash';
import { connect } from 'react-redux';
import { add, edit } from 'actions/crudActions';
import crudTypes from 'config/crudTypes';
import { withRouter, useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';

const {
  views: { Input },
  helpers: { colors },
} = shared;

const MessageWindowWrapper = styled.div`
  width: 50%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

const CardHeader = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  height: 67px;
  width: 100%;
  color: ${colors.textColor};
`;

const MessageWindowHeader = styled(CardHeader)`
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CreditUsageWrapper = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
`;

const CampaignNameInput = styled((props) => (
  <Input
    value={''}
    height={26}
    onChange={() => {}}
    autoComplete="off"
    placeholder={'Campaign name...'}
    {...props}
  />
))`
  & input {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #434343;
    &::placeholder {
      opacity: 0.5;
    }
  }
`;

const CreditUsage = () => (
  <CreditUsageWrapper>
    <span style={{ color: colors.blue }}>87</span> out of{' '}
    <span style={{ color: colors.blue }}>100 Credits</span> will be used in this campaign.
  </CreditUsageWrapper>
);

const extractOriginalMessage = (campaign) => {
  const campaignMessage = campaign.smsCampaignMessages[0];
  return campaignMessage.message.replace(
    RegExp(`http.*?${campaignMessage.hash}`, 'g'),
    '{{messenger_link}}',
  );
};

const MessageWindow = ({ clients, selected, setSelected, add, edit, campaign }) => {
  const [campaignName, setCampaignName] = useState(campaign?.name ?? '');
  const [message, setMessage] = useState(campaign ? extractOriginalMessage(campaign) : '');
  const [status, setStatus] = useState(campaign?.status ?? CAMPAIGN_STATUS.Draft);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const params = useParams();
  const campaignId = params.id;
  const updatedCampaign = {
    name: campaignName,
    recipients: _(selected)
      .pickBy()
      .map((s, id) => parseInt(id))
      .value(),
    message: message,
    status: status,
  };
  const lastUpdatedCampaign = useRef(JSON.stringify(updatedCampaign));

  useEffect(() => {
    const send = updatedCampaign.status !== CAMPAIGN_STATUS.Draft;
    const timeout = setTimeout(
      () => {
        if (JSON.stringify(updatedCampaign) === lastUpdatedCampaign.current) return;
        lastUpdatedCampaign.current = JSON.stringify(updatedCampaign);

        const done = (status, data, error) => {
          if (status === 'OK') {
            if (send) {
              toast.success('Campaign was successfully scheduled for sending!');
              history.push('/dashboard/marketing/campaigns');
            } else {
              toast.info('Campaign draft autosaved!');
              if (campaignId === 'new') {
                history.replace(`/dashboard/marketing/campaigns/${data.id}`);
              }
            }
          } else {
            toast.error(<pre>{error.title + '\n' + _.values(error.errors).join('\n')}</pre>);
          }
          console.log(data, error);
          setIsSubmitting(false);
        };

        setIsSubmitting(true);
        if (campaignId === 'new') {
          add(crudTypes.campaign, updatedCampaign, done);
        } else {
          edit(campaignId, crudTypes.campaign, updatedCampaign, done);
        }
      },
      send ? 0 : 1000,
    );

    return () => clearTimeout(timeout);
  }, [updatedCampaign, add, edit, history, campaignId]);

  return (
    <MessageWindowWrapper>
      <MessageWindowHeader>
        <CampaignNameInput value={campaignName} onChange={(e) => setCampaignName(e.target.value)} />
        <CreditUsage />
      </MessageWindowHeader>
      <MessageToSection clients={clients} selected={selected} setSelected={setSelected} />
      <MessageInputSection
        message={message}
        setMessage={setMessage}
        onSave={() => setStatus(CAMPAIGN_STATUS.ReadyToSend)}
        isSubmitting={isSubmitting}
      />
    </MessageWindowWrapper>
  );
};

const mapStateToProps = (state, ownProps) => {
  const campaignId = parseInt(ownProps.match?.params?.id);
  if (campaignId) {
    return {
      campaign: state.campaigns.data.find((c) => c.id === campaignId),
    };
  }
  return {};
};

export default withRouter(connect(mapStateToProps, { add, edit })(MessageWindow));
