import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Icons from 'app/shared/views/icons';
import { BlackButton } from 'app/shared/views/buttons';
import OrderDescription from '../components/OrderDescription';

const HeaderCol = styled.div`
  z-index: 999 !important;
  position: sticky !important;
  top: 0 !important;
  background-color: #ffffff !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  align-items: center !important;
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: #2a2e45;
  flex: 1;
  padding: 16px !important;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ProductRow = styled.div`
  background-color: ${(props) => (props.index % 2 === 0 ? '#F9FAFC' : '#FFFFFF')};
  padding: 0 20px;
`;

const NormalProductWrapper = styled.div`
  padding: 8px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  border-bottom: 1px dashed;
`;

const ProductName = styled.h2`
  font-size: 14px;
  font-weight: bold;
  color: #1d2036;
`;

const ProductQty = styled.h2`
  font-weight: bold;
  font-size: 14px;
  color: #007aff;
`;

const ProductPrice = styled.div`
  font-size: 14px;
  color: black;
  flex: 1;
  font-weight: 600;
`;

const ProductDescription = styled.div`
  font-size: 12px;
  color: #748aa1;
  font-weight: 300;
`;

const InnerRowWrapper = styled.div`
  display: flex !important;
  flex-direction: column !important;
  background-color: #ffffff !important;
  position: relative !important;
  z-index: 999 !important;
  bottom: 0 !important;
  width: 100% !important;
  height: 100vh !important;
  max-width: 500px !important;
  margin-top: auto !important;
  overflow: auto !important;
  opacity: ${({ isClosing }) => (isClosing ? 0.7 : 1)};
`;

const ButtonText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  width: 100%;
`;

const ButtonInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
`;

const AllQty = styled.p`
  border: 2px solid white;
  border-radius: 50%;
  padding: 0.25rem 0.4rem;
  margin-right: 5px;
`;

const AddMesssage = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  margin-bottom: 30px;
  cursor: pointer;
`;

const stopEventBubbling = (e) => {
  e.stopPropagation();
};

const OrderPreview = (props) => {
  const { show, setShow, pageInfo, onProceed, internalOrder } = props;

  const childRef = useRef(null);
  const headerRef = useRef(null);
  const order = useSelector((state) => state.shopping.order);
  const [error, setError] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(66);
  const [showOrderDetail, setShowOrderDetail] = useState(false);

  useEffect(() => {
    if (!headerRef?.current) return;
    setHeaderHeight(headerRef?.current.clientHeight);
  }, []);

  useEffect(() => {
    if (order.description?.length > 125) {
      setError({
        message: 'Max 125 characters allowed in Order Details!',
      });
    } else {
      setError(false);
    }
  }, [order.description]);

  const preview = () => {
    setShowOrderDetail(true);
  };

  useEffect(() => {
    let errorTimer = setTimeout(() => setError(''), 5000);
    return () => {
      clearTimeout(errorTimer);
    };
  }, [error]);

  useEffect(() => {
    if (!childRef.current) return;
    childRef.current.ontouchstart = stopEventBubbling;
    childRef.current.ontouchmove = stopEventBubbling;
    childRef.current.ontouchend = stopEventBubbling;
  }, [show, childRef]);

  const onSubmit = () => {
    if (onProceed) {
      onProceed();
    }
  };

  return (
    <>
      {showOrderDetail === true && (
        <OrderDescription
          show={order.orderProducts}
          setShow={setShowOrderDetail}
          pageInfo={pageInfo}
          onProceed={onSubmit}
        />
      )}
      <div open={show}>
        <InnerRowWrapper>
          <HeaderCol ref={headerRef}>
            <div style={{ width: '100%' }}>
              <h2 onClick={() => setShow(false)} style={{ margin: '20px', cursor: 'pointer' }}>
                Back
              </h2>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Header>Order Items</Header>
              </div>
            </div>
          </HeaderCol>
          <Body
            ref={childRef}
            headerHeight={headerHeight}
            style={
              internalOrder
                ? { minHeight: '20.5rem', maxHeight: '20.5rem' }
                : { minHeight: '20.5rem', maxHeight: '20.5rem' }
            }
          >
            <div style={{ width: '100%', overflowY: 'auto', flex: '1' }}>
              {order?.orderProducts.map((product, index) => (
                <ProductRow
                  style={{ backgroundColor: 'white' }}
                  index={index}
                  key={product.toString() + index}
                >
                  <NormalProductWrapper>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.5rem',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <ProductQty>x{product.qty}</ProductQty>
                        <ProductName>{product.name}</ProductName>
                      </div>
                      <ProductDescription>{product.description}</ProductDescription>
                      <ProductPrice>
                        {product.price % 1 === 0
                          ? (product.price = parseInt(
                              product.discountValidation ? product.discount : product.price,
                              10,
                            )) * product.qty
                          : product.discountValidation
                          ? product.discount
                          : product.price * product.qty}
                        {' ' + pageInfo.currency}
                      </ProductPrice>
                    </div>
                    <img
                      src={product.imagePath}
                      alt="product img"
                      style={{
                        width: '200px',
                        height: '70px',
                        borderRadius: '10px',
                        minWidth: '90px',
                        minHeight: '90px',
                      }}
                    />
                  </NormalProductWrapper>
                  {product.isCombo &&
                    product.toppings?.map((topping, index) => (
                      <NormalProductWrapper index={index} key={product.toString() + index}>
                        <ProductName style={{ paddingLeft: 8 }}>{topping.name}</ProductName>
                        <ProductQty style={{ paddingRight: 4 }}>{topping.qty}</ProductQty>
                        <ProductPrice>
                          {topping.price % 1 === 0
                            ? (topping.price = parseInt(topping.price, 10)) * topping.qty
                            : topping.price * topping.qty}
                          {' ' + pageInfo.currency}
                        </ProductPrice>
                      </NormalProductWrapper>
                    ))}
                </ProductRow>
              ))}
            </div>
          </Body>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '1.5rem',
              gap: '2rem',
            }}
          >
            <div
              style={{
                borderRadius: '7px',
                backgroundColor: '#F9FAFC',
                // height: !internalOrder ? '8.5%' : 0,
                width: '95%',
                alignContent: 'center',
                justifyContent: 'center',
                display: 'flex',
                padding: '20px 0 5px 0',
              }}
            >
              <AddMesssage onClick={preview}>
                <div style={{ padding: '12px 10px' }}>
                  <Icons.AddressDescription />
                </div>
                <div>
                  <h3 style={{ padding: '6px' }}>Add a message for the restaurant</h3>
                  {order.description === '' ? (
                    <p style={{ padding: '6px', fontSize: '12px' }}>
                      Write any specific detail regarding your order
                    </p>
                  ) : (
                    <p style={{ padding: '6px', fontSize: '12px' }}>{order.description}</p>
                  )}
                </div>
              </AddMesssage>
              <div style={{ marginTop: '20px', marginRight: '7px' }}>
                <Icons.Vector />
              </div>
            </div>
            <BlackButton
              onClick={() => {
                setShow(false);
                onSubmit();
              }}
              style={{
                width: '95%',
                minHeight: '60px',
                height: 60,
                fontSize: 16,
                fontWeight: 'bold',
                borderRadius: '7px',
                display: order.totalValue ? 'flex' : 'none',
              }}
              text={
                <ButtonText>
                  <ButtonInfo>
                    <AllQty>
                      {order.orderProducts.legth !== 0 &&
                        order.orderProducts.reduce(
                          (accumulator, currProd) => accumulator + currProd.qty,
                          0,
                        )}
                    </AllQty>
                    <p style={{ padding: '3px' }}>Go to checkout</p>
                  </ButtonInfo>
                  <p style={{ marginRight: '30px', padding: '3px' }}>{order.totalValue}</p>
                </ButtonText>
              }
            />
          </div>
        </InnerRowWrapper>
      </div>
    </>
  );
};

export default OrderPreview;
