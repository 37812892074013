import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import shared from 'app/shared';
import { Table } from 'antd';
import Icons from '../../shared/views/icons';
import { getPageUsers, deletePageUser } from '../../../actions/pageActions';
import AddPageUserPopUp from '../components/AddPageUserPopUp';
import StarRatings from 'react-star-ratings';
import SettingsTitle from '../components/SettingsTitle';
import ReviewPopUp from '../components/ReviewPopUp';
import userRoles from '../../shared/helpers/userRoles';
import moment from 'moment';

const {
  views: {
    Button: { WhiteButton, RemoveAllButton, EditButton },
  },
  helpers: { colors },
} = shared;

const TableOrderSettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.7% 0;
`;

const UserName = styled.div`
  padding-left: 30px;
`;
const UserEmail = styled.div``;
const ActionWrapper = styled.div`
  display: flex;
  button {
    margin-left: 4px;
  }
`;
const Footer = styled.div`
  display: flex;
  height: 44px;
  margin-top: 32px;
  padding-left: 30px;
`;

const ButtonText = styled.div`
  display: flex;
  align-items: center;
  background: #3185fc;
  border-radius: 25px;
  height: 32px;
  justify-content: center;
`;
const Wrapper = styled.div`
  width: 100%;
`;

const RoleWrapper = styled.div`
  background: #3185fc;
  border-radius: 18px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  justify-content: center;
  color: #ffffff;
  max-width: 100px;
`;

const ReviewCount = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;

  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;

  color: #3185fc;
  flex-grow: 1;
  padding-left: 16px;
`;

const UsersSettings = ({ users, getPageUsers, deletePageUser, activePage }) => {
  // const OnlineOrder = activePage?.pageService.find(pS=>pS.id===1).isActive
  const TableOrder = activePage?.pageService.find((pS) => pS.id === 2).isActive;
  // const Reservation = activePage?.pageService.find(pS=>pS.id===3).isActive

  const [showAddUserPopup, setShowAddUserPopup] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [editUser, setEditUser] = useState('');
  const toggleAddPopup = () => {
    setShowAddUserPopup(!showAddUserPopup);
    setEditUser('');
  };

  useEffect(() => {
    getPageUsers();
  }, [getPageUsers]);

  const getRole = (roleId) => userRoles.find((uR) => uR.id === roleId);

  const columns = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      render: (name, record) => <UserName>{name}</UserName>,
    },
    {
      title: '',
      dataIndex: 'email',
      key: 'email',
      width: '25%',
      render: (email, record) => <UserEmail>{email}</UserEmail>,
    },
    {
      title: '',
      dataIndex: 'role',
      key: 'role',
      width: '15%',
      render: (role, record) => (
        <Wrapper>
          <RoleWrapper style={{ backgroundColor: getRole(role)?.color }}>
            {getRole(role)?.name}
          </RoleWrapper>
        </Wrapper>
      ),
    },
    {
      title: '',
      dataIndex: 'reviewRatingAverage',
      key: 'reviewRatingAverage',
      width: '30%',
      render: (reviewRatingAverage, record) =>
        TableOrder ? (
          <Wrapper
            style={{ display: 'flex', cursor: 'pointer', paddingLeft: 8 }}
            onClick={() => setShowReview(record.id)}
          >
            <StarRatings
              rating={reviewRatingAverage ? reviewRatingAverage : 0}
              starRatedColor="#FFD037"
              numberOfStars={5}
              starDimension="20px"
              starSpacing="4px"
              name="rating"
            />
            <ReviewCount>{`${record.waiterReviews.length} Reviews`}</ReviewCount>
          </Wrapper>
        ) : null,
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'editAction',
      width: '10%',
      render: (id, record) => (
        <ActionWrapper>
          <EditButton
            onClick={() => {
              setEditUser(id);
              setShowAddUserPopup(true);
            }}
          />
          <RemoveAllButton
            onClick={() => {
              if (window.confirm(`Are you sure you want to delete ${record.name}`)) {
                deletePageUser(id);
              }
            }}
          />
        </ActionWrapper>
      ),
    },
  ];

  const filteredUsers = users.filter((x) => getRole(x.role).name !== 'Client');
  const sortedUsers = [...filteredUsers].sort((a, b) => moment(a.created) - moment(b.created));

  return (
    <TableOrderSettingsWrapper>
      <AddPageUserPopUp
        show={showAddUserPopup}
        toggle={toggleAddPopup}
        userDetails={users.find((x) => x.id === editUser)}
      />
      <ReviewPopUp userId={showReview} users={users} toggle={() => setShowReview(false)} />
      <SettingsTitle title="Manage Users" style={{ padding: '0 30px' }} />
      <Table
        showHeader={false}
        name={'usersTable'}
        size={'default'}
        rowKey={(row, index) => row.id}
        rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
        bordered={false}
        columns={columns}
        dataSource={sortedUsers}
        pagination={false}
        loading={users ? false : true}
      />
      <Footer>
        <WhiteButton
          text={
            <ButtonText>
              <Icons.Add
                fill={colors.white}
                style={{
                  width: 20,
                  height: 20,
                  backgroundColor: 'transparent',
                  borderRadius: 3,
                  marginLeft: 20,
                }}
              />
              <span style={{ color: colors.white, marginLeft: 12, marginRight: 20 }}>
                Add New User
              </span>
            </ButtonText>
          }
          style={{ border: 'none', minWidth: 200, display: 'flex' }}
          onClick={toggleAddPopup}
        />
      </Footer>
    </TableOrderSettingsWrapper>
  );
};

const mapStateToProps = (state) => ({
  activePage: state.facebookPages.activePage,
  users: state.users.data,
});

export default connect(mapStateToProps, { getPageUsers, deletePageUser })(UsersSettings);
