import buttons from './views/buttons';
import Text from './views/text';
import LabeledInput from './views/input.labeled';

const  {
  SidedButton, MainGreenButton,
} = buttons;

export default{
  views: {
    Button: {SidedButton, MainGreenButton},
    Text,
    LabeledInput
  },
};
