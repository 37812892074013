import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

import ComponentToPrint from './ComponentToPrint';
import CurrencyFormatter from 'app/shared/helpers/currencyFormatter';
import { appendPrintedOrderId, getPrintedOrderIds } from 'app/shared/views/printReceipt/util';
import _ from 'lodash';

const pageStyle = `
  @page {
    // size: 80mm 50mm;
    margin: 0mm;
  },

  @media all {
    .page-break {
      display: none;
    }
  }

  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }
`;

const AutoPrintReceipt = ({ pageConfiguration, orders, orderId, activePage }) => {
  const activeOrder = orders.find((x) => x.id === orderId);
  const componentRef = React.useRef(null);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    removeAfterPrint: true,
    pageStyle: pageStyle,
    onAfterPrint: () => {
      appendPrintedOrderId(orderId);
    },
  });

  const isTableWhitelisted = React.useCallback(
    (tableId) => {
      const {
        autoprintTableWhitelist,
        autoprintTableWhitelistEnabled,
      } = pageConfiguration.receiptConfiguration;

      // non-table orders are whitelisted
      if (tableId == null) return true;

      // if autoprintTableWhitelist feature is disabled, all tables are whitelisted
      if (!(autoprintTableWhitelistEnabled ?? false)) return true;

      // sanity checks
      // if autoprintTableWhitelist is null (or undefined) or not an array, fallback to printing by default
      if (autoprintTableWhitelist == null || !Array.isArray(autoprintTableWhitelist)) return true;

      return _.includes(autoprintTableWhitelist, tableId);
    },
    [pageConfiguration],
  );

  useEffect(() => {
    const isPrinted = _.includes(getPrintedOrderIds(), orderId);
    if (activeOrder && !isPrinted && isTableWhitelisted(activeOrder.tableId)) {
      handlePrint();
    }
  }, [handlePrint, activeOrder, orderId, isTableWhitelisted]);

  return (
    <div style={{ display: 'none' }}>
      {activeOrder && (
        <ComponentToPrint
          ref={componentRef}
          order={activeOrder}
          restaurantName={activePage?.pageName}
          totalValue={activeOrder && CurrencyFormatter(activeOrder.totalValue)}
          receiptConfiguration={pageConfiguration.receiptConfiguration}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  orders: state.orders.data,
  activePage: state.facebookPages.activePage,
  tables: state.tables,
  pageConfiguration: state.pageConfiguration,
});
export default connect(mapStateToProps, {})(AutoPrintReceipt);
