import React from 'react';

/**
 * TODO: Add smil support here
 * TODO: Check https://leunen.me/fakesmile/index.html and https://caniuse.com/#feat=svg-smil
 * https://css-tricks.com/smil-is-dead-long-live-smil-a-guide-to-alternatives-to-smil-features/
 * @type {number}
 */

const smoothing = 0.2;

const position = (point, width) => point.position * width;
const value = (point, height) => (1 - point.value) * height;

const line = (pointA, pointB) => {
  const lengthX = pointB[0] - pointA[0];
  const lengthY = pointB[1] - pointA[1];
  return {
    length: Math.sqrt(lengthX ** 2 + lengthY ** 2),
    angle: Math.atan2(lengthY, lengthX),
  };
};

const controlPoint = (height, current, previous, next, reverse) => {
  const p = previous || current;
  const n = next || current;
  const o = line(p, n);
  const angle = o.angle + (reverse ? Math.PI : 0);
  const length = o.length * smoothing;
  const x = current[0] + Math.cos(angle) * length;
  let y = current[1] + Math.sin(angle) * length;

  if (y < 0) {
    y = 0;
  } else if (y > 216) {
    y = 216;
  }

  return [x, y];
};

const bezierCommand = (height, point, i, a) => {
  const cps = controlPoint(height, a[i - 1], a[i - 2], point);
  const cpe = controlPoint(height, point, a[i - 1], a[i + 1], true);
  return `C ${cps[0]},${cps[1]} ${cpe[0]},${cpe[1]} ${point[0]},${point[1]}`;
};

const svgPath = (points, command, height) =>
  points.reduce(
    (acc, point, i, a) =>
      i === 0 ? `M ${point[0]},${point[1]}` : `${acc} ${command(height, point, i, a)}`,
    '',
  );

const SmoothedLine = ({ data, width = 230, height = 57 }) => (
  <div className="line" style={{ width, height }}>
    <svg width={width} height={height}>
      <defs>
        <linearGradient id="linear" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor="#006CCB" />
          <stop offset="100%" stopColor="#65BAFF" />
        </linearGradient>
      </defs>

      <path
        style={{ transition: '.3s ease-in-out' }}
        d={svgPath(
          data.map(item => [position(item, width), value(item, height)]),
          bezierCommand,
          height,
        )}
        fill="none"
        strokeWidth="3"
        stroke="url(#linear)"
      />
    </svg>
  </div>
);

export default SmoothedLine;
