import React from 'react';
import styled from 'styled-components';
import shared from 'app/shared';
import Select from 'react-select';
import _ from 'lodash';

const {
  views: {
    Text: {
      Paragraph: { Paragraph },
    },
    Icons,
  },
  helpers: { colors },
} = shared;

const MainWrapper = styled.div`
  display: flex;
  border-top: 1px solid ${colors.lightGrey};
  height: 118px;
  cursor: text;
`;

const ParagraphWrapper = styled.div`
  display: inline-flex;
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  padding-top: 21px;
  padding-left: 30px;
`;

class MessageToSection extends React.Component {
  constructor(props) {
    super(props);
    this.section = React.createRef();
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    try {
      const valueContainer = this.section.current.select.controlRef.firstChild;
      valueContainer.scrollTop = valueContainer.scrollHeight;
    } catch(e) {
      console.log(e);
    }
  }

  render() {
    const { selected, setSelected, clients } = this.props;

    const selectedClients = clients.filter(({ id }) => selected[id]);
    const value = selectedClients.map(mapClientToSelectOption);
    const allSelected = !_.isEmpty(selected) && _(selected).every();
    const options = clients.map(mapClientToSelectOption);
    return (
      <div>
        <MainWrapper>
          <ParagraphWrapper>
            <Paragraph>To: </Paragraph>
          </ParagraphWrapper>
          <Select
            ref={this.section}
            isMulti
            placeholder=""
            value={allSelected ? { label: 'All Customers', value: '*' } : value}
            options={allSelected ? [] : options}
            onChange={(options) => setSelected(_.map(options, 'value'))}
            isSearchable={true}
            styles={selectStyles}
            components={{ MultiValue }}
          />
        </MainWrapper>
      </div>
    );
  }
}

const mapClientToSelectOption = (client) => ({
  label: client.fullName,
  value: client.id,
});

const selectStyles = {
  container: (styles) => ({
    ...styles,
    width: '100%',
    height: '100%',
    paddingTop: '16px',
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: 'unset',
    border: 'none',
    boxShadow: 'none',
    width: '100%',
    height: '100%',
  }),
  valueContainer: (styles) => ({
    ...styles,
    alignContent: 'start',
    width: '100%',
    height: '100%',
    overflow: 'auto',
  }),
  option: (styles) => {
    return {
      ...styles,
    };
  },
  placeholder: (styles) => ({
    ...styles,
    top: '10px',
  }),
  menu: (styles) => ({
    ...styles,
    maxWidth: '300px',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: () => ({ display: 'none' }),
};

const MultiValue = (props) => (
  <NameButton
    key={props.data.value}
    name={props.data.label}
    onClickExecute={props.removeProps.onClick}
  />
);

const ButtonWrapper = styled.div`
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
  height: 29px;
  min-height: 12px;
  min-width: 50px;
  display: flex;
  border: 1px solid ${colors.lightGrey};
  color: ${colors.veryDarkGrey};
  align-items: center;
  border-radius: 3px;
  padding: 0 8px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const NameButton = ({ name, onClickExecute }) => (
  <ButtonWrapper onClick={onClickExecute}>
    <Icons.Cross style={{ marginRight: '8px' }} />
    <Paragraph style={{ color: colors.veryDarkGrey }}>{name}</Paragraph>
  </ButtonWrapper>
);

export default MessageToSection;
