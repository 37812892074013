import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { connect } from 'react-redux';
import shared from 'app/shared';
import AddOrderPopup from './addOrderPopup';
import EditOrder from './editOrder';
import { getAll, getAllV2, getAllPromotions, createObjectFromState } from 'actions/crudActions';
import { requestDelivery, orderReady } from 'actions/deliveryActions';
import { IsDeliveryActive } from 'selectors/pageServiceSelector';
import crudTypes from '../../../config/crudTypes';
import { paginateNoOp } from 'config/helpers/paginator';
import { getSortState, sortData } from 'config/helpers/sorter';
import { filterData } from 'config/helpers/searcher';
import MobileFilter from '../../shared/views/mobileFilter/mobileFilter';
import { CSVFileExporter } from 'app/shared/helpers/CSVFileExporter';
import SimpleFilterBar from './filterBar';
import OrderCsvMapper from './orderCsvMapper';
import currencyFormatter from 'app/shared/helpers/currencyFormatter';
import { getClientFullName } from 'app/shared/helpers/client';

import { ORDER_STATUS, ORDER_PAYMENT_METHODS } from '../constants';
import SearchInput from 'app/shared/views/searchInput';
import AddInternalOrderModal from './AddInternalOrder';
import { searchCategories } from 'actions/categoryAction';

const {
  views: { Table, Button, Paginator, Page, Card, FilterableTableStructure },
  helpers: { resetScroll, colors },
} = shared;

const {
  StatsSectionWrapper,
  ActionBarWrapper,
  SearchWrapper,
  TableWrapper,
  PaginatorWrapper,
} = FilterableTableStructure;

const initialState = {
  filters: {
    selectedPeriod: 'today',
    selectedPaymentStatus: 'all',
    selectedOrderSource: 'all',
  },
  customDate: { startDate: null, endDate: null },
  pageSize: 10,
  currentPage: 1,
  sort: {
    key: 'orderDate',
    order: -1,
  },
  search: '',
  filteredOrders: '',
  redirect: false,
  showEditPopup: false,
  addNewOrderModalVisible: false,

  filter: {
    filterCondition: null,
    filterParameters: [],
  },
  pageNumber: 1,
  sorting: [
    {
      columnName: 'name',
      direction: 'asc',
    },
  ],
};

const TableWrapperTablet = styled(Card)`
  display: none;
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGrey};
  border-radius: 3px;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: block;
    margin-top: 25px;
  }
  @media all and (max-width: 768px) {
    display: block;
    margin-top: 25px;
  }
`;

const ButtonWrapper = styled.div`
  @media all and (max-width: 768px) {
    div:nth-child(2) {
      display: none;
    }
  }
`;

const PaymentPaidWrapper = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 58px;
  color: #2ecc71;
`;

const PaymentPendingWrapper = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 58px;
  color: ${colors.orange};
`;

const PaymentCancelledWrapper = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 58px;
  color: #ff3a44;
`;

class OrdersPage extends React.Component {
  state = initialState;

  componentWillMount() {
    resetScroll();
    this.props.getAllV2(crudTypes.order, 'OrderDate', createObjectFromState(this.state));
    this.props.getAllPromotions(crudTypes.promotion, this.props.activePage.id, this.state);
    this.props.getAll(crudTypes.category, this.setGetStatus);
  }

  updateSelectedPeriod = (selectedPeriod) => {
    if (selectedPeriod !== 'custom') {
      this.setState({
        customDate: {
          startDate: null,
          endDate: null,
        },
      });
    }
    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, selectedPeriod },
        currentPage: 1,
      }),
      () => {
        this.props.getAllV2(crudTypes.order, 'OrderDate', createObjectFromState(this.state));
      },
    );
  };

  updateCustomDate = (customDate) => {
    this.setState(
      {
        customDate: { startDate: customDate.startDate, endDate: customDate.endDate },
        currentPage: 1,
      },
      () => {
        this.props.getAllV2(crudTypes.order, 'OrderDate', createObjectFromState(this.state));
      },
    );
  };

  toggleAddPopup = () => this.setState({ showEditPopup: !this.state.showEditPopup });

  clearFilters = () => {
    this.setState(
      {
        filters: initialState.filters,
        customDate: { startDate: null, endDate: null },
        currentPage: 1,
      },
      () => {
        this.props.getAllV2(crudTypes.order, 'OrderDate', createObjectFromState(this.state));
      },
    );
  };

  updatePaginatorPage = (currentPage) => {
    this.setState({ currentPage }, () => {
      this.props.getAllV2(crudTypes.order, 'OrderDate', createObjectFromState(this.state));
    });
  };

  updateSortState = (key) => {
    this.setState(
      (prevState) => ({
        sort: getSortState(prevState.sort, key),
      }),
      () => {
        this.props.getAllV2(crudTypes.order, 'OrderDate', createObjectFromState(this.state));
      },
    );
  };

  updateSelectedOrderSource = (key) => {
    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          selectedOrderSource: key,
        },
        currentPage: 1,
      }),
      () => {
        this.props.getAllV2(crudTypes.order, 'OrderDate', createObjectFromState(this.state));
      },
    );
  };

  updateSelectedPaymentStatus = (key) => {
    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          selectedPaymentStatus: key,
        },
        currentPage: 1,
      }),
      () => {
        this.props.getAllV2(crudTypes.order, 'OrderDate', createObjectFromState(this.state));
      },
    );
  };

  getTableData = (values = []) => ({
    action: this.updateSortState,
    header: [
      {
        label: 'BILL NO.',
        key: 'orderCounter',
        renderer: ({ orderCounter }) => orderCounter,
        sortable: true,
      },
      {
        label: 'CLIENT',
        key: 'client',
        renderer: (order) => {
          const { tableName, clientPhone, clientEmail, orderStatus } = order;
          let hiddenPhoneNumber;
          if (clientPhone && orderStatus !== 1) {
            hiddenPhoneNumber = clientPhone.slice(0, -3) + '***';
          }
          else {
            hiddenPhoneNumber = clientPhone;
          }
          const clientName = getClientFullName(order);
          return clientName || hiddenPhoneNumber || clientEmail || tableName;
        },
        sortable: false,
      },
      {
        label: 'SOURCE',
        key: 'source',
        renderer: ({ source, tableName }) => {
          const mapSource = {
            'online-order': 'Online',
            'table-order': 'Table',
            'mobile-order': 'Mobile',
            'internal-order': 'Internal',
            'marketplace-order': 'Marketplace',
            'pok-order': 'POK',
          };
          let sourceLabel =
            source === 'internal-order' ? mapSource[source] : tableName || mapSource[source];

          return (
            <Button.Button
              text={sourceLabel}
              size={Button.ButtonSize.medium}
              style={{
                backgroundColor: tableName ? '#2ECC71' : colors.blue,
                bordeRadius: 3,
                fontWeight: 'bold',
                fontSize: '12px',
                lineHeight: '14px',
                textAlign: 'center',
                color: colors.white,
                width: 100,
              }}
            />
          );
        },
        sortable: true,
      },
      {
        label: 'QUANTITY',
        key: 'orderProducts',
        renderer: ({ orderProducts }) => {
          const qty = (orderProducts || []).reduce((qty, order) => qty + (order.qty || 0), 0);
          return (
            <Button.Button
              text={qty}
              size={Button.ButtonSize.medium}
              style={{
                backgroundColor: colors.lightBlue,
                fontWeight: 'bold',
                fontSize: '12px',
                lineHeight: '14px',
                textAlign: 'center',
                color: colors.blue,
              }}
            />
          );
        },
        sortable: true,
      },
      {
        label: 'VALUE',
        key: 'totalValue',
        renderer: ({ totalValue }) => `${currencyFormatter(totalValue)}`,
        sortable: true,
      },
      {
        label: 'DELIVERY FEE',
        key: 'deliveryFee',
        renderer: ({ deliveryFee }) => `${currencyFormatter(deliveryFee)}`,
        sortable: true,
      },
      {
        label: 'PAYMENT METHOD',
        key: 'paymentMethod',
        renderer: ({ paymentMethod }) => ORDER_PAYMENT_METHODS[paymentMethod],
        sortable: true,
      },
      {
        label: 'STATUS',
        key: 'orderStatus',
        renderer: ({ orderStatus }) =>
          orderStatus === 0 || orderStatus === 4 || orderStatus === 6 ? (
            <PaymentPendingWrapper>{ORDER_STATUS[orderStatus]}</PaymentPendingWrapper>
          ) : orderStatus === 2 ? (
            // TODO: Status to display Delivering and delivery driver app will update to delivered status
            <PaymentPaidWrapper>Paid</PaymentPaidWrapper>
          ) : orderStatus === 1 ? (
            <PaymentPaidWrapper>{ORDER_STATUS[orderStatus]}</PaymentPaidWrapper>
          ) : (
            <PaymentCancelledWrapper>{ORDER_STATUS[orderStatus]}</PaymentCancelledWrapper>
          ),
        sortable: true,
      },
      {
        label: 'DATE',
        key: 'orderDate',
        sortable: true,
        renderer: ({ orderDate }) => orderDate && moment(orderDate).format('YYYY-MM-DD HH:mm'),
      },
      {
        label: 'ACTIONS',
        width: '150px',
        key: 'id',
        sortable: true,
        renderer: ({ id, orderCounter, requireDelivery, tableId, orderStatus }) => {
          return (
            <div style={{ width: '100%', display: 'flex' }}>
              <NavLink to={`${EditOrder.path}/${orderCounter}`}>
                <Button.Button
                  style={{ fontWeight: 600 }}
                  text={'View'}
                  size={Button.ButtonSize.medium}
                  color={colors.blue}
                />
              </NavLink>
            </div>
          );
        },
      },
    ],
    values: values,
  });

  getTableDataTablet = (values = []) => ({
    action: this.updateSortState,
    header: [
      {
        label: 'BILL NO.',
        key: 'orderCounter',
        renderer: ({ orderCounter }) => orderCounter,
        sortable: false,
      },
      {
        label: 'QUANTITY',
        key: 'orderProducts',
        renderer: ({ orderProducts }) => {
          const qty = (orderProducts || []).reduce((qty, order) => qty + (order.qty || 0), 0);
          return (
            <Button.Button
              text={qty}
              size={Button.ButtonSize.medium}
              style={{
                backgroundColor: colors.lightBlue,
                fontWeight: 'bold',
                fontSize: '12px',
                lineHeight: '14px',
                textAlign: 'center',
                color: colors.blue,
              }}
            />
          );
        },
        sortable: true,
      },
      {
        label: 'VALUE',
        key: 'totalValue',
        renderer: ({ totalValue }) => `${currencyFormatter(totalValue)}`,
        sortable: true,
      },
      {
        label: 'ACTION',
        width: '70px',
        key: 'id',
        sortable: true,
        renderer: ({ orderCounter }) => {
          return (
            <NavLink to={`${EditOrder.path}/${orderCounter}`}>
              <Button.Button
                style={{ fontWeight: 600 }}
                text={'View'}
                size={Button.ButtonSize.medium}
                color={colors.blue}
              />
            </NavLink>
          );
        },
      },
    ],
    values: values,
  });

  toggle = () => this.setState({ toggleValue: !this.state.toggleValue });

  setSearchValue = (val) => {
    this.setState({ search: val, currentPage: 1 });
  };

  createExportCSV = (data) => {
    return () => {
      CSVFileExporter('orders.csv', OrderCsvMapper(data));
    };
  };

  render() {
    const {
      state: {
        filters: { selectedPeriod, selectedPaymentStatus, selectedOrderSource },
        currentPage,
        pageSize,
        showEditPopup,
        search,
        sort,
        customDate,
        addNewOrderModalVisible,
      },
      updateSelectedPeriod,
      clearFilters,
      updatePaginatorPage,
      updateCustomDate,
      toggleAddPopup,
      createExportCSV,
      updateSelectedPaymentStatus,
      updateSelectedOrderSource,
    } = this;

    const searchData = filterData(
      this.props.orders.data.filter((x) => this.props.orders.data.includes(x)) || [],
      search,
    );

    const sortedData = sortData(searchData, sort);
    const paginatedData = paginateNoOp(sortedData, currentPage, pageSize);

    const hasOnlineOrder =
      this.props.activePage &&
      !!this.props.activePage.pageService.find(
        (x) => x.name === 'OnlineOrder' && x.isActive === true,
      );
    const hasTableOrder =
      this.props.activePage &&
      !!this.props.activePage.pageService.find(
        (x) => x.name === 'TableOrder' && x.isActive === true,
      );

    let firstClients = [];

    let group = paginatedData.current.reduce((r, a) => {
      r[a.clientId] = [...(r[a.clientId] || []), a];
      return r;
    }, {});

    for (const key in group) {
      if (group[key].length === 1) firstClients.push(group[key][0]);
    }

    for (const id in paginatedData.current)
      for (const fid in firstClients) {
        if (paginatedData.current[id].id === firstClients[fid].id)
          paginatedData.current[id].firstClient = true;
      }
    return (
      <Page>
        {toggleAddPopup && <AddOrderPopup show={showEditPopup} toggle={toggleAddPopup} />}
        <SimpleFilterBar
          selectedPeriod={selectedPeriod}
          updateSelectedPeriod={updateSelectedPeriod}
          clearFilters={clearFilters}
          updateCustomDate={updateCustomDate}
          customDate={customDate}
          selectedPaymentStatus={selectedPaymentStatus}
          updateSelectedPaymentStatus={updateSelectedPaymentStatus}
          selectedOrderSource={selectedOrderSource}
          updateSelectedOrderSource={updateSelectedOrderSource}
          showOrderSource={hasOnlineOrder && hasTableOrder}
        />
        <MobileFilter
          selectedPeriod={selectedPeriod}
          updateSelectedPeriod={updateSelectedPeriod}
          clearFilters={clearFilters}
        />
        <StatsSectionWrapper>
          <ActionBarWrapper style={{ justifyContent: 'space-between' }}>
            <ButtonWrapper>
              <Button.Button
                color={colors.blue}
                text={'Add New'}
                style={{ marginRight: 5 }}
                onClick={() => this.setState({ addNewOrderModalVisible: !addNewOrderModalVisible })}
              />
              <Button.WhiteButton
                color={colors.textColor}
                style={{ marginRight: 5 }}
                text={'Export CSV'}
                onClick={() =>
                  getAllV2(
                    crudTypes.order,
                    'OrderDate',
                    createObjectFromState({ ...this.state, noPagination: true }),
                  )((orders) => {
                    createExportCSV(sortData(orders.data, sort))();
                  })
                }
              />
            </ButtonWrapper>
            <SearchWrapper>
              <SearchInput search={search} onSearchChanged={(x) => this.setSearchValue(x)} />
            </SearchWrapper>
          </ActionBarWrapper>
          <TableWrapper>
            <Table.TableView data={this.getTableData(paginatedData.current)} />
          </TableWrapper>
          <TableWrapperTablet>
            <Table.TableView data={this.getTableDataTablet(paginatedData.current)} />
          </TableWrapperTablet>
          <PaginatorWrapper>
            <Paginator
              changePage={updatePaginatorPage}
              numberOfPages={this.props.orders.current.totalPages || 1}
              page={paginatedData.currentPage}
            />
          </PaginatorWrapper>
        </StatsSectionWrapper>
        <AddInternalOrderModal
          show={addNewOrderModalVisible}
          toggle={() => this.setState({ addNewOrderModalVisible: !addNewOrderModalVisible })}
        />
      </Page>
    );
  }
}

/*const orderStatusFilter = (data = [], selectedPaymentStatus) => {
  switch (selectedPaymentStatus) {
    case 'pending':
      return data.filter((item) => [0, 4].includes(item.orderStatus));
    case 'paid':
      return data.filter((item) => item.orderStatus === 1 || item.orderStatus === 2);
    case 'cancelled':
      return data.filter((item) => item.orderStatus === 5);
    default:
      return data;
  }
};

const orderSourceFilter = (data = [], selectedOrderSource) => {
  switch (selectedOrderSource) {
    case 'table':
      console.table(
        'data filtered',
        data.filter((item) => item.tableName != null),
      );
      return data.filter((item) => item.tableName != null);
    case 'online':
      return data.filter((item) => item.tableName == null);
    default:
      return data;
  }
};*/

OrdersPage.path = '/dashboard/orders';

const mapStateToProps = (state) => {
  return {
    orders: {
      data: state.orders.data.map((el) => ({
        fullName: el.clientName + ' ' + el.clientLastName,
        ...el,
      })),
      current: state.orders.current,
    },
    activePage: state.facebookPages?.activePage,
    isDeliveryActive: IsDeliveryActive(state),
    // promotions: state.promotions
  };
};

export default connect(mapStateToProps, {
  getAll,
  getAllV2,
  getAllPromotions,
  requestDelivery,
  orderReady,
  searchCategories,
})(OrdersPage);

export { OrdersPage };
