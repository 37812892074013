import moment from 'moment';

const getLocation = (locations) =>
{
    if(!locations || locations.length === 0 || !locations[0] || !locations[0].locationName)
        return '';
    return locations[0].locationName;
};

const ClientCsvMapper = clients => clients.map(c =>
    ({
        // 'First name': c.name,
        // 'Last name': c.surname,
        'Phone': c.phone || '',
        'Email': c.email || '',
        'Location': getLocation(c.locations),
        'Number of orders': c.orderCount,
        'Revenue': `$${c.totalSum}`,
        'Date Created': moment(c.creationDate).format('YYYY-MM-DD HH:mm')
    }));

export default ClientCsvMapper;
