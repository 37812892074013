import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import shared from 'app/shared';
import moment from 'moment';
import { CAMPAIGN_STATUS } from '../constants';

const {
  views: { Card, Text, Icons },
  helpers: { colors, transition },
} = shared;

const {
  Link: { Link },
} = Text;

const CampaignCardWrapper = styled(Card)`
  padding: 20px;
  transition: ${transition};
  transform: translateY(0);

  :hover {
    cursor: pointer;
    transform: translateY(-5px);
  }

  ${(props) =>
    props.isDraft &&
    css`
      color: ${colors.grey};

      & ${Value}, & ${Title} {
        color: ${colors.grey};
      }
    `}
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  white-space: nowrap;
`;

const RowItem = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(50% - 5px);
  line-height: 17px;
  color: ${(props) => props.color ?? colors.textColor};
`;

const Title = styled(RowItem)`
  color: #3185fc;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
`;

const DeleteLink = styled(Link)`
  margin-left: 10px;
`;

const Subtitle = styled(RowItem)`
  display: flex;
  flex-shrink: 0;
  font-size: 10px;
`;

const Label = styled(RowItem)`
  font-size: 14px;
`;

const Value = styled(RowItem)`
  font-size: 14px;
  font-weight: bold;
`;

const CampaignCard = ({ campaign, onDelete }) => {
  const { id, name, linkClicks, smsSent, createDate, status } = campaign;

  const deleteCampaign = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (window.confirm(`Delete campaign titled '${name}'?`)) {
      onDelete(id);
    }
  };

  const isDraft = status === CAMPAIGN_STATUS.Draft;
  return (
    <NavLink to={`/dashboard/marketing/campaigns/${id}`}>
      <CampaignCardWrapper isDraft={isDraft}>
        <Row style={{ marginBottom: 18 }}>
          <Title>{name}</Title>
          <Subtitle>
            Date Sent: {moment(createDate).format('DD.MM.YYYY')}
            {isDraft && (
              <DeleteLink as="span" onClick={deleteCampaign} color={colors.red}>
                <Icons.Delete />
              </DeleteLink>
            )}
          </Subtitle>
        </Row>
        <Row>
          <Label>SMS Sent:</Label>
          <Value color={colors.blue}>{smsSent}</Value>
        </Row>
        <Row>
          <Label>Link Clicks:</Label>
          <Value color={colors.green}>{linkClicks}</Value>
        </Row>
      </CampaignCardWrapper>
    </NavLink>
  );
};

export default CampaignCard;
