import React from 'react';
import styled from 'styled-components';
import StarRatings from 'react-star-ratings';
import moment from 'moment';
import './ReviewComponent.css';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

export const Comment = styled.div`
    flex: 1;
    margin: 16px 0;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #5C677B;
`;
export const Date = styled.div`
    flex: 1;
    text-align: right;

    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #BFCADB;

    padding-bottom: 20px;
    border-bottom: 1px solid #E8E9F3;
`;

const Review = ({rating, comment, date}) => {
  
  return <Container >
        <StarRatings
            rating={rating}
            starRatedColor="#FFD037"
            numberOfStars={5}
            starDimension="20px"
            starSpacing="4px"
            name='rating'
        />
        <Comment>{comment}</Comment> 
        <Date>{`Date Posted: ${moment(date).format('MMM DD, yyyy')}`}</Date> 
  </Container>
};

export default Review;
