import { format, isWithinRange,  differenceInDays, differenceInMilliseconds, differenceInMonths, addMilliseconds, differenceInHours }
from 'date-fns';
import {datesFromFilter} from './filterFunctions'

export const genericFilter = (filter, genericData, customDates, dateField, sumByField) => {
  const dates = datesFromFilter(filter, genericData, customDates, dateField);
  const startDate = dates.startDate;
  const endDate = dates.endDate;
  let divider = 4;

  const aggregate = (genericData, first, step, dateField, sumByField) => {
    let res = [];
    const filterData = (current) => {
      res.push(
        genericData
        .filter(d => {
          return isWithinRange(d[dateField], current, addMilliseconds(current, step))
        })
        .reduce((a, b) => sumByField ? a + b[sumByField] : a + 1, 0)
      );
    }
    while(divider-- > 0){
      filterData(first);
      first = addMilliseconds(first, step);
    }
    return res;
  }

  const calculateDTF = (filter, dtFrom, dtTo) => {
    if (filter === 'today' || filter === 'yesterday'){
      return "h:mm A";
    }
    if (filter === 'last-week'){
      return "dd";
    }
    if(filter === 'last-month'){
      return "DD MMM";
    }
    if (differenceInHours(dtTo, dtFrom) <= 24){
      return "h:mm A"
    }
    if (differenceInDays(dtTo, dtFrom) < divider){
      return "dd h:mm A";
    }
    if (differenceInMonths(dtTo, dtFrom) > divider){
      return "MMM YY";
    }
    return "DD/MM/YY";
  }

  const formatLabels = (first, step, dateFormat) => {
    const roundMinutes = (date, i) => {
      if(i === divider-1){
        return date;
      }
      var coeff = 1000 * 60 * 1;
      var rounded = new Date(Math.round(date.getTime() / coeff) * coeff);
      return rounded;
    }

    var res = [];
    for(let i=0; i<divider; i++){
      var label = format(roundMinutes(first), dateFormat);
      if(divider !== 7){ //is not selected 'last-week'
        label += " - " + format(roundMinutes(addMilliseconds(first, step), i), dateFormat)
      }
      res.push(label);
      first = addMilliseconds(first, step);
    }
    return res;
  }

  if (filter === 'last-week'){
    divider = 7;
  }

  if (filter === 'total') {
    divider = 3;
  }

  const step = differenceInMilliseconds(endDate, startDate) / divider;
  const labels = formatLabels(startDate, step, calculateDTF(filter, startDate, endDate));
  const data = aggregate(genericData, startDate, step, dateField, sumByField);

  return {
    labels,
    data
  };
}
