import React from 'react';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

import logo from 'assets/icons/logo.svg';
import authBackground from 'assets/images/backgroundAuth.png';

import image from 'app/shared/views/image';
import mediaQueries from 'app/shared/helpers/mediaQueries';

const {
  Image: { ImageWrapper },
} = image;

const AuthLayout = ({ children, ...rest }) => {
  return (
    <AuthLayoutContainer {...rest}>
      <AuthLayoutContent>
        <ImageWrapper align="left">
          <img alt={'logo'} src={logo} width={160} />
        </ImageWrapper>
        {children}
        <Copyright>Orderoo 2021. All rights reserved.</Copyright>
      </AuthLayoutContent>
      <AuthCover alt="cover" src={authBackground} />

      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        pauseOnHover
      />
    </AuthLayoutContainer>
  );
};

const AuthLayoutContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: strech;
`;

const AuthLayoutContent = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  width: 40%;
  padding: 50px 5%;
  padding-top: 20px;
  // max-width: 500px;
  overflow-y: scroll;

  ${mediaQueries.tablet(`
    width: 45%;
    padding: 60px 40px;
  `)}

  ${mediaQueries.mobile(`
    width: 100%;
    padding: 50px;
    max-width: unset;
  `)}
`;

const Copyright = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #748aa1;
  padding-top: 30px;
`;

const AuthCover = styled.div`
  flex: 1;
  background: url(${authBackground});
  background-repeat: no-repeat;
  background-size: cover;
}
  ${mediaQueries.mobile(`
    display: none;
  `)}
`;

export default AuthLayout;
