import React, { useState, useCallback } from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import styled from 'styled-components';

import { Formik } from 'formik';
import * as Yup from 'yup';

import shared from 'app/shared';

import FormButton from '../components/MainButton';
import FieldError from '../components/FieldError';

const {
  views: {
    Input,
  },
} = shared;

const SignInForm = (props) => {
  const [token, setToken] = useState('');
  const [generateToken, setGenerateToken] = useState(true);

  const handleVerify = useCallback((token) => {
    setToken(token);
    setGenerateToken(false);
  }, []);


  return (
    <div>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          businessName: '',
          // businessTin: '',
          password: '',
          confirmPassword: '',
          reCaptchaToken: '',
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required('First Name is required'),
          lastName: Yup.string().required('Last Name is required'),
          email: Yup.string().email('Email is invalid').required('Email is required'),
          phoneNumber: Yup.string().required('Phone number is required'),
          businessName: Yup.string().required('Business name is required'),
          // businessTin: Yup.string().required('Business Tin is required'),
          password: Yup.string().required('Password is required'),
          confirmPassword: Yup.string()
            .required('Please confirm your password')
            .when('password', {
              is: (password) => (password && password.length > 0 ? true : false),
              then: Yup.string().oneOf([Yup.ref('password')], "Password doesn't match"),
            }),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          values.reCaptchaToken = token;
          if (typeof props.onSubmit === 'function') {
            await props.onSubmit(values);
          }
          setSubmitting(false);
        }}
      >
        {({ values, touched, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <FormWrapper>
            <InputWrapper style={{ width: 'calc(50% - 5px)', marginRight: '10px' }}>
              <Input
                labelText="First Name"
                name="firstName"
                type="text"
                height={38}
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.firstName && errors.firstName && <FieldError>{errors.firstName}</FieldError>}
            </InputWrapper>
            <InputWrapper style={{ width: 'calc(50% - 5px)' }}>
              <Input
                labelText="Last Name"
                name="lastName"
                type="text"
                height={38}
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.lastName && errors.lastName && <FieldError>{errors.lastName}</FieldError>}
            </InputWrapper>
            <InputWrapper>
              <Input
                labelText="Email"
                name="email"
                type="text"
                height={38}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.email && errors.email && <FieldError>{errors.email}</FieldError>}
            </InputWrapper>
            <InputWrapper>
              <Input
                labelText="Phone Number"
                name="phoneNumber"
                type="text"
                height={38}
                value={values.phoneNumber}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.phoneNumber && errors.phoneNumber && (
                <FieldError>{errors.phoneNumber}</FieldError>
              )}
            </InputWrapper>
            <InputWrapper>
              <Input
                labelText="Business Name"
                name="businessName"
                type="text"
                height={38}
                value={values.businessName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.businessName && errors.businessName && (
                <FieldError>{errors.businessName}</FieldError>
              )}
            </InputWrapper>

            {/* <InputWrapper style={{ width: 'calc(50% - 5px)' }}>
              <Input
                labelText="Business Tin"
                name="businessTin"
                type="text"
                height={38}
                value={values.businessTin}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.businessTin && errors.businessTin && (
                <FieldError>
                  {errors.businessTin}
                </FieldError>
              )}
            </InputWrapper> */}

            <InputWrapper>
              <Input
                labelText="Password"
                name="password"
                type="password"
                height={38}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.password && errors.password && <FieldError>{errors.password}</FieldError>}
            </InputWrapper>

            <InputWrapper>
              <Input
                labelText="Confirm Password"
                name="confirmPassword"
                type="password"
                height={38}
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.confirmPassword && errors.confirmPassword && (
                <FieldError>{errors.confirmPassword}</FieldError>
              )}
            </InputWrapper>

            <FormButton
              text="Register"
              onClick={handleSubmit}
              disabled={isSubmitting}
              style={{ marginTop: '20px' }}
            />
          </FormWrapper>
        )}
      </Formik>
      {generateToken && <GoogleReCaptcha onVerify={handleVerify} />}
    </div>
  );
};

const FormWrapper = styled.div`

`;

const InputWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin: 5px 0;
`;

export default SignInForm;
