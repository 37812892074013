import PropTypes from 'prop-types';

const TableSchema = {
  data: PropTypes.shape({
    header: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.isRequired,
        sortable: PropTypes.bool.isRequired,
        key: PropTypes.string.isRequired,
        renderer: PropTypes.func,
      }),
    ).isRequired,
    values: PropTypes.array,
  }),
  isEmpty: PropTypes.bool,
};

export default { TableSchema };
