import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import shared from 'app/shared';
import SettingsTitle from '../components/SettingsTitle';
import { setPageConfiguration } from 'actions/pageActions';
import CheckBox from 'app/shared/views/checkbox';
import { scrollToAlert } from 'app/shared/views/alert';
import { TreeSelect } from 'antd';

const {
  views: { Alert },
  helpers: { colors },
} = shared;

const InlineFlexWrapper = styled.div`
  display: inline-flex;
  margin-top: 3%;
  width: 60%;
`;

const MainLabel = styled.div`
  display: flex;
  width: 35%;
  height: 100%;
  padding-right: 30px;
  font-size: 15px;
  color: ${colors.textColor};
`;

const ReceiptSettings = ({
  activePage,
  pageConfiguration,
  setPageConfiguration,
  setExecuteOnSubmit,
  setHasChanges,
  children,
}) => {
  const [alert, setAlert] = useState({ show: false, message: '' });
  const alertRef = useRef(null);
  const [receiptSettings, setReceiptSettings] = useState(pageConfiguration.receiptConfiguration);
  const [localNoPrint, setLocalNoPrint] = useState(localStorage.getItem('localNoPrint') ?? 'false');

  useEffect(() => {
    let alertTimer = setTimeout(() => setAlert({ show: false, message: '' }), 5000);
    return () => {
      clearTimeout(alertTimer);
    };
  }, [alert]);

  useEffect(() => {
    setExecuteOnSubmit(() => () => {
      localStorage.setItem('localNoPrint', localNoPrint);
      setPageConfiguration(activePage.id, 'receiptConfiguration', receiptSettings, (err) => {
        if (!err) {
          setAlert({
            show: true,
            message: 'You have successfully changed your receipt settings!',
            type: 'success',
          });
          setHasChanges(false);
        } else {
          setAlert({
            show: true,
            message: `Could not change your receipt settings: ${err}`,
            type: 'error',
          });
        }
        scrollToAlert(alertRef);
      });
    });
  }, [
    activePage,
    setExecuteOnSubmit,
    setHasChanges,
    receiptSettings,
    setPageConfiguration,
    localNoPrint,
  ]);

  const autoprintTableWhitelistTreeData = activePage?.tableCategories?.map((tc) => ({
    title: tc.name,
    value: `tc${tc.id}`,
    key: `tc${tc.id}`,
    children: tc.tables?.map((t) => ({
      title: t.name,
      value: t.id,
      key: t.id,
    })),
  }));

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '0.7% 30px',
        paddingBottom: '30px',
      }}
    >
      <SettingsTitle title="Receipt Settings" />
      <Alert ref={alertRef} {...alert} />

      <InlineFlexWrapper>
        <MainLabel>Receipt</MainLabel>
        <div style={{ width: '65%', marginTop: '-2px' }}>
          <div style={{ width: '100%', marginBottom: '10px' }}>
            <div>
              <CheckBox
                text={'Print Restaurant Name'}
                checked={receiptSettings.printRestaurantName}
                onChange={(e) =>
                  setReceiptSettings({
                    ...receiptSettings,
                    printRestaurantName: e.target.checked,
                  })
                }
              />
            </div>
          </div>
          <div style={{ width: '100%', marginBottom: '10px' }}>
            <div>
              <CheckBox
                text={'Print Order Values'}
                checked={receiptSettings.printOrderValues}
                onChange={(e) =>
                  setReceiptSettings({
                    ...receiptSettings,
                    printOrderValues: e.target.checked,
                  })
                }
              />
            </div>
          </div>
          <div style={{ width: '100%', marginBottom: '10px' }}>
            <div>
              <CheckBox
                text={'Print Product Description'}
                checked={receiptSettings.printProductDescription}
                onChange={(e) =>
                  setReceiptSettings({
                    ...receiptSettings,
                    printProductDescription: e.target.checked,
                  })
                }
              />
            </div>
          </div>
          <div style={{ width: '100%', marginBottom: '10px' }}>
            <div>
              <CheckBox
                text={'Disable printing for this browser'}
                checked={localNoPrint === 'true'}
                onChange={(e) => setLocalNoPrint(e.target.checked ? 'true' : 'false')}
              />
            </div>
          </div>
          <div style={{ width: '100%', marginBottom: '10px' }}>
            <div>
              <CheckBox
                text={'Only allow printing orders from selected tables'}
                checked={receiptSettings.autoprintTableWhitelistEnabled ?? false}
                onChange={(e) =>
                  setReceiptSettings({
                    ...receiptSettings,
                    autoprintTableWhitelistEnabled: e.target.checked,
                  })
                }
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <TreeSelect
                disabled={!(receiptSettings.autoprintTableWhitelistEnabled ?? false)}
                treeData={autoprintTableWhitelistTreeData}
                value={receiptSettings.autoprintTableWhitelist ?? []}
                onChange={(value) =>
                  setReceiptSettings({
                    ...receiptSettings,
                    autoprintTableWhitelist: value,
                  })
                }
                treeCheckable={true}
                showCheckedStrategy={TreeSelect.SHOW_CHILD}
                searchPlaceholder={'Please select'}
                style={{
                  width: '100%',
                }}
              />
            </div>
          </div>
        </div>
      </InlineFlexWrapper>
      <InlineFlexWrapper>
        <MainLabel />
        {children}
      </InlineFlexWrapper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activePage: state.facebookPages.activePage,
  pageConfiguration: state.pageConfiguration,
});

export default connect(mapStateToProps, {
  setPageConfiguration,
})(ReceiptSettings);
