import React from 'react';
import { Checkbox } from 'antd';
import 'antd/dist/antd.css';
import colors from '../helpers/colors';

const CheckBox = (props) => (
  <Checkbox checked={props.checked} options={[{ color: colors.yellow }]} onChange={props.click} {...props}>
    <span
      style={{
        color: colors.textColor,
      }}
    >
      {props.text}
    </span>
  </Checkbox>
);

export default CheckBox;
