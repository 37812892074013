const initialState = {
  data: [],
}

export default (state = initialState, action) => {
  const types = {
    'GET_SUBSCRIPTIONS_OK':
      { data: action.data },
  }

  return types[action.type] || state;
}
