import crudTypes from 'config/crudTypes';
import crudReducer from './crudReducer';
import { createSelector } from 'reselect';

const crudOrderReducer = crudReducer(crudTypes.order);
const initialState = {
  print: [],
  data: [],
  current: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `ORDER_PRINT`:
      return { ...state, print: [...state.print, action.data] };
    case `ORDER_REFUND_OK`:
      return {
        ...state,
        data: state.data.map((order) => {
          if (order.id === action.data.id) {
            return action.data;
          }
          return order;
        }),
      };
    case `REQUIRE_DELIVERY_OK`:
      return {
        ...state,
        data: state.data.map((order) => {
          if (order.id === action.data) {
            return { ...order, requireDelivery: true };
          }
          return order;
        }),
      };

    default:
      return crudOrderReducer(state, action);
  }
};

const selectOrders = (state) => state.orders.data;

export const getInternalOrderIds = createSelector(selectOrders, (orders) =>
  orders.filter((order) => order.source === 'internal-order').map((order) => order.id),
);
