import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import crudTypes from './config/crudTypes';
import logIn from './reducers/logInReducer';
import facebookPages from './reducers/facebookReducer';
import pageConfiguration from './reducers/pageConfigurationReducer';
import userDetails from './reducers/userDetailsReducer';
import crudReducer from './reducers/crudReducer';
import crudReducerTOP from 'reducers/crudReducerTOP';
import notificationReducer from './reducers/notificationReducer';
import stripeSettingsReducer from './reducers/stripeSettingsReducer';
import productReducer from './reducers/productReducer';
import categoryReducer from './reducers/categoryReducer';
import checkoutReducer from './reducers/checkoutReducer';
import shoppingReducer from './reducers/shoppingReducer';
import subscriptionReducer from './reducers/subscriptionReducer';
import adminClientsReducer from './reducers/adminClientsReducer';
import adminUsersReducer from './reducers/adminUsersReducer';
import adminTopClientsReducer from './reducers/adminTopClientsReducer';
import orderReducer from './reducers/orderReducer';
import adminSubInvoiceReducer from './reducers/adminSubInvoiceReducer';
import adminOrdersReducer from './reducers/adminOrdersReducer';
import adminPagesReducer from './reducers/adminPagesReducer';
import adminSubscriptionsReducer from './reducers/adminSubscriptionsReducer';
import campaignsReducer from './reducers/campaignsReducer';
import usersReducer from './reducers/usersReducer';
import aggregatorReducer from './reducers/aggregatorReducer';
import crudReducerV2 from 'reducers/crudReducerV2';

import reportReducer from './reducers/reportReducer';
import topReducer from './reducers/topReducer';
import pokSettingsReducer from 'reducers/pokSettingsReducer';
// Combine all reducers.
const appReducer = combineReducers({
  logIn: logIn,
  facebookPages: facebookPages,
  pageConfiguration: pageConfiguration,
  userDetails: userDetails,
  orders: orderReducer,
  topOrders: crudReducerTOP(crudTypes.order),
  clients: crudReducer(crudTypes.client),
  topClients: crudReducerTOP(crudTypes.client),
  categories: categoryReducer,
  categoriesV2: crudReducerV2(crudTypes.category),
  products: productReducer,
  topProducts: crudReducerTOP(crudTypes.product),
  promotions: crudReducer(crudTypes.promotion),
  vouchers: crudReducer(crudTypes.voucher),
  banner: crudReducer(crudTypes.banner),
  notifications: notificationReducer,
  stripeSettings: stripeSettingsReducer,
  pokSettings: pokSettingsReducer,
  checkout: checkoutReducer,
  shopping: shoppingReducer,
  subscriptions: subscriptionReducer,
  adminOrders: adminOrdersReducer,
  adminPages: adminPagesReducer,
  adminClients: adminClientsReducer,
  adminUsers: adminUsersReducer,
  adminSubscriptions: adminSubscriptionsReducer,
  adminTopClients: adminTopClientsReducer,
  adminSubInvoices: adminSubInvoiceReducer,
  campaigns: campaignsReducer,
  users: usersReducer,
  tables: crudReducer(crudTypes.table),
  tableCategories: crudReducer(crudTypes.tableCategory),
  aggregator: aggregatorReducer,
  reports: reportReducer,
  top: topReducer,
});

const initialState = appReducer({}, {});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === 'DESTROY_SESSION') {
    state = initialState;
  }

  return appReducer(state, action);
};

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
