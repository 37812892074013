import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import FilterBar from 'app/shared/views/filterBar';
import DatePicker from 'app/shared/views/dateRangeFilter';

import Inputs from 'app/shared/views/input';
import colors from 'app/shared/helpers/colors';

const FilterBarWrapper = styled.div`
  margin-bottom: 20px;

  & > :first-child {
    height: unset;
    min-height: 42px;
  }
`;

const FilterBarContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-betweeen;
  flex-wrap: wrap;

  & > * {
    padding-right: 20px;
  }
`;

const FilterBarLeft = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
const FilterBarRight = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap-reverse;
`;

const FilterBarItem = styled.div`
  /* margin-left: 20px; */
  display: inline-flex;
  align-items: flex-start;
`;

const FilterBarItemName = styled(FilterBar.Components.Title)`
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #748AA1;
`;

const FilterBarInput = styled(Inputs.Input)`
  width: 70px;
  height: 26px;
  display: inline-flex;
  margin-left: 8px;
  margin-top: -1px;

  & > input {
    padding: 0 8px;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: ${colors.darkestGrey};

    ::placeholder {
      font-size: 12px;
      letter-spacing: 0.7;
      color: ${colors.grey};
    }
  }
`;

const FilterBarInputLabel = styled.label`
  color: ${colors.textColor};
  font-size: 12px;
  line-height: 23px;
  font-weight: 500;
`;

const FilterBarInputWrapper = styled.div`
  display: flex;
  margin-left: 10px;
  margin-top: -3px;
`;

const FilterBarDatePicker = styled(DatePicker)`
  margin-top: -5px;
`;

// const FilterBarSelect = styled(SelectOption)`
//   margin-top: -10px;
//   width: ${props => props.width ? `${props.width}px` : null};
// `;

// const FilterBarSelectStyles = {
//   control: (styles) => ({
//     ...styles,
//     backgroundColor: 'unset',
//     border: 'none',
//     boxShadow: 'none',
//     fontSize: '13px',
//     lineHeight: '16px',
//     color: colors.darkestGrey,
//   }),
//   option: (styles, { data, isDisabled, isFocused, isSelected }) => {
//     return {
//       ...styles,
//       fontSize: '13px',
//       lineHeight: '16px',
//     };
//   },
//   indicatorSeparator: (styles) => ({display:'none'}),
// };


// const DateTimePeriodOptions = [
//   { value: 'today', label: 'Today' },
//   { value: 'yesterday', label: 'Yesterday' },
//   { value: 'last-week', label: 'Last Week' },
//   { value: 'last-month', label: 'Last Month' },
//   { value: 'total', label: 'Total' },
// ];

// const OrderCountRangeOptions = [
//   { value: '5-20', label: '5-20' },
//   { value: '20-50', label: '20-50' },
// ];

// const RevenueRangeOptions = [
//   { value: '50-100', label: '$50-$200'}
// ];

const MarketingFilterBar = (props) => {
  const {
    orderMin,
    orderMax,
    revenueMin,
    revenueMax,
    customDate,
    updateCustomDate,
    updateFilters,
    clearFilters,
  } = props;

  const filterInputChanged = e => {
    let val = parseInt(e.target.value, 10);
    if (isNaN(val)) {
      if (e.target.value === '') {
        val = '';
      } else {
        return;
      }
    }

    updateFilters({
      [e.target.name]: val,
    });
  };

  return (
    <FilterBarWrapper>
      <FilterBar>
        <FilterBarLeft>
          <FilterBar.Components.Title style={{ width: '80px', height: '25px' }}>Filter by:</FilterBar.Components.Title>
          <FilterBarContent>
            <FilterBarItem>
              <FilterBarItemName>Orders:</FilterBarItemName>
              {/* <FilterBarSelect
                width="90"
                placeholder="Filter"
                autosize={true}
                customStyles={FilterBarSelectStyles}
                options={OrderCountRangeOptions}
                onChange={option => { updateOrderCountFilter(option.value) }}
                isSearchable={'false'}
                name={'orderCount'}
                value={OrderCountRangeOptions.find(option => option.value === selectedOrderCount) || null}
              /> */}

              <FilterBarInputWrapper>
                <FilterBarInputLabel>from</FilterBarInputLabel>
                <FilterBarInput name="orderMin" value={orderMin} onChange={filterInputChanged} placeholder="ex. 10" />
              </FilterBarInputWrapper>
              <FilterBarInputWrapper>
                <FilterBarInputLabel>to</FilterBarInputLabel>
                <FilterBarInput name="orderMax" value={orderMax} onChange={filterInputChanged} placeholder="ex. 50" />
              </FilterBarInputWrapper>
            </FilterBarItem>
            <FilterBarItem>
              <FilterBarItemName>Revenue:</FilterBarItemName>
              {/* <FilterBarSelect
                width="100"
                placeholder="Filter"
                customStyles={FilterBarSelectStyles}
                options={RevenueRangeOptions}
                onChange={option => { updateRevenueFilter(option.value) }}
                isSearchable={'false'}
                name={'revenue'}
                value={RevenueRangeOptions.find(option => option.value === selectedRevenue) || null}
              /> */}

              <FilterBarInputWrapper>
                <FilterBarInputLabel>from</FilterBarInputLabel>
                <FilterBarInput name="revenueMin" value={revenueMin} onChange={filterInputChanged} placeholder="ex. $100" />
              </FilterBarInputWrapper>
              <FilterBarInputWrapper>
                <FilterBarInputLabel>to</FilterBarInputLabel>
                <FilterBarInput name="revenueMax" value={revenueMax} onChange={filterInputChanged} placeholder="ex. $500" />
              </FilterBarInputWrapper>
            </FilterBarItem>
          </FilterBarContent>
        </FilterBarLeft>

        <FilterBarRight>
          <FilterBarContent>
            <FilterBarItem>
              <FilterBarDatePicker
                updateCustomDate={updateCustomDate}
                customDate={customDate}
                clearFilters={clearFilters}
              />
            </FilterBarItem>
          </FilterBarContent>
          <FilterBarItem>
            <div style={{ marginLeft: '20px', marginTop: '2px' }}>
              <FilterBar.Components.ClearFilters onClick={clearFilters} />
            </div>
          </FilterBarItem>
        </FilterBarRight>
      </FilterBar>
    </FilterBarWrapper>
  );
};

MarketingFilterBar.props = {
  selectedPeriod: PropTypes.object,
  updateSelectedPeriod: PropTypes.func,
  clearFilters: PropTypes.func,
  updateCustomDate: PropTypes.func,
};

MarketingFilterBar.defaultProps = {
  updateSelectedPeriod: () => {},
  clearFilters: () => {},
  updateCustomDate: () => {},
};

export default MarketingFilterBar;
