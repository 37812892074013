import store from 'store';

export default () => {
  localStorage.removeItem('User');
  localStorage.removeItem('Pages');
  localStorage.removeItem('Page');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('authToken');
  store.dispatch({ type: 'DESTROY_SESSION' });
  //// window.location.href = '/signin';
};
