import styled from 'styled-components';
import colors from '../helpers/colors';

const Card = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGrey};
  border-radius: 3px;
`;

export default Card;
