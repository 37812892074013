import crudTypes from 'config/crudTypes';
import crudReducer from './crudReducer';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { SMS_STATUS } from 'app/marketing/smsStatus';

const crudCampaignReducer = crudReducer(crudTypes.campaign);
const initialState = {
  data: [],
  current: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return crudCampaignReducer(state, action);
  }
};

const selectClients = (state) => state.clients.data;
const selectActivePageId = (state) => state.facebookPages.activePage?.id;
export const selectConversations = createSelector(
  [selectClients, selectActivePageId],
  (clients, activePageId) =>
    clients
      .map((client) => client.smsConversations ?? [])
      .flat()
      .filter((conversation) => conversation.pageId === activePageId),
);

export const selectSmsMessages = createSelector(selectConversations, (conversations) =>
  conversations.map((c) => c.smsMessages ?? []).flat(),
);

export const selectSmsCampaignMessages = createSelector(selectConversations, (conversations) =>
  conversations
    .map((c) => c.smsCampaignMessages ?? [])
    .flat()
    .filter((m) => [SMS_STATUS.Delivered, SMS_STATUS.Sent].includes(m.status)),
);

export const getTotalLinkClicks = createSelector(selectSmsCampaignMessages, (smsCampaignMessages) =>
  _.sumBy(smsCampaignMessages, (smsCampaignMessage) => smsCampaignMessage.linkClickedCount),
);

export const selectAllSms = createSelector(
  [selectSmsMessages, selectSmsCampaignMessages],
  (smsMessages, smsCampaignMessages) => [...smsMessages, ...smsCampaignMessages],
);
