//import { ORDER_STATUS, ORDER_PAYMENT_METHODS } from '../constants';
import moment from 'moment';
import { partialNumberFormatter } from 'app/shared/helpers/currencyFormatter';

const OrderCsvMapper = (orders) =>
  orders.map((o) => ({
    //
    // 'First Name': o.clientName,
    // 'Last Name': o.clientLastName,
    //Table: o.tableName || '',
    //Waiter: o.waiterName || '',
    'Cel Number': o.clientPhone || '',
    //Email: o.clientEmail || '',
    'Order Number': o.orderCounter,
    'Order Date': moment(o.orderDate).format('YYYY-MM-DD HH:mm'),
    'Order Source': o.source || '',
    //'Order Status': ORDER_STATUS[o.orderStatus],
    //'Payment Method': ORDER_PAYMENT_METHODS[o.paymentMethod],
    //Address: o.orderLocation,
    //'Address Details': o.orderLocation2,
    'Products & Quantity & Price': o.orderProducts
      .map(
        ({ name, qty, price }) =>
          `${name}, ${qty} pcs, ${partialNumberFormatter(qty * price)} (${partialNumberFormatter(price)} each)`,
      )
      .join('; '),
    //'Order details': o.customerDescription,
    Subtotal: `${partialNumberFormatter(o.totalValue - o.deliveryFee)}`,
    //'Delivery Fee': `${currencyFormatter(o.deliveryFee)}`,
    //Total: `${currencyFormatter(o.totalValue)}`,
  }));

export default OrderCsvMapper;
