export default (type) => {
  const initialState = {
    data: [],
    current: {},
  };

  return (state = initialState, action) => {
    switch (action.type) {
      case `GET_ALL_${type.tag}_V2_OK`:
        if (action.totalPages !== undefined) {
          state.current.totalPages = action.totalPages
        }
        return {
          ...state,
          data: [...action.data],
          current: { ...state.current },
        };
      default:
        return state;
      }
    };
  };
  