import React, { Component } from 'react';
import styled from 'styled-components';
import shared from 'app/shared';

import { MARKETING_TABS } from '../constants';

import StatisticsBar from 'app/marketing/components/statisticsBar';
import BuyCreditsPopup from 'app/marketing/views/buyCreditsPopup';
import { Redirect, withRouter } from 'react-router-dom';

const {
  views: { Page, TabBar },
} = shared;

const MarketingViewWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 25px;
`;

const HeaderBarWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const MarketingTabBar = styled(TabBar)`
  flex: 1;
`;

class MarketingPage extends Component {
  state = {
    buyCreditsPopupOpen: false,
  };

  updateCurrentTab = (value) => {
    this.props.history.replace('/marketing/' + value);
  };

  toggleBuyCreditsPopup = () => {
    this.setState((prevState) => ({
      buyCreditsPopupOpen: !prevState.buyCreditsPopupOpen,
    }));
  };

  render() {
    const {
      state: { buyCreditsPopupOpen },
      props: { match },
    } = this;

    let currentTab = MARKETING_TABS.find((t) => t.value === match.params?.tab);
    if (!currentTab) {
      return <Redirect to={'/dashboard/marketing/' + MARKETING_TABS[0].value} />;
    }
    const { component: CurrentTabView } = currentTab;

    return (
      <Page>
        <BuyCreditsPopup show={buyCreditsPopupOpen} toggle={this.toggleBuyCreditsPopup} />
        <StatisticsBar openBuyCreditsPopup={this.toggleBuyCreditsPopup} />
        <HeaderBarWrapper>
          <MarketingTabBar
            tabs={MARKETING_TABS}
            selectedElement={currentTab.value}
            updateSelectedElement={this.updateCurrentTab}
          />
        </HeaderBarWrapper>
        <MarketingViewWrapper>
          <CurrentTabView />
        </MarketingViewWrapper>
      </Page>
    );
  }
}

export default withRouter(MarketingPage);
