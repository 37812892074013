import React from 'react';
import styled, { css } from 'styled-components';
import hexToRGBA from '../helpers/hexToRGBA';
import transition from '../helpers/transition';
import mediaQueries from '../helpers/mediaQueries';
import colors from '../helpers/colors';
import icons from 'app/shared/views/icons';

export const BaseButton = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: ${(props) =>
    hexToRGBA(props.disabled ? colors.darkestGrey : props.color, 0.1) || ''};
  user-select: none;
  transition: ${transition};
  font-size: 16px;

  ${/* sc-selector */ mediaQueries.mobile(`
    font-size: 14px;
  `)}

  ${/* sc-selector */ mediaQueries.tablet(`
    font-size: 14px;
  `)}

  :hover {
    cursor: pointer;
    background-color: ${(props) =>
      hexToRGBA(props.disabled ? colors.darkestGrey : props.color, 0.3) || ''};
  }
`;

const ButtonWithIconWrapper = styled(BaseButton)`
  min-width: 176px;
  height: 36px;
  padding-right: 8px;

  // ${mediaQueries.mobile(`
  //   min-width: 120px;
  //   height: 25px;
  // `)}

  // ${mediaQueries.tablet(`
  //   min-width: 36px;
  //   height: 36px;
  // `)}
`;

const IconWrapper = styled.div`
  margin: 0 11px 0 8px;

  ${mediaQueries.mobile(`
    margin: 0 3px 0 6px;
  `)}
`;

const SpanWrapper = styled.span``;

export const ButtonWithIcon = ({ Icon, text, color, ...rest }) => (
  <ButtonWithIconWrapper color={color} {...rest}>
    <IconWrapper>
      <Icon fill={color} />
    </IconWrapper>
    <SpanWrapper style={{ color }}>{text}</SpanWrapper>
  </ButtonWithIconWrapper>
);

export const ButtonSize = {
  big: {
    height: 33,
    width: 117,
    fontSize: 16,
  },
  extraMedium: {
    height: 41,
    width: 103,
    fontSize: 16,
  },
  medium: {
    height: 24,
    width: 70,
    fontSize: 12,
  },
  small: {
    height: 18,
    width: 36,
    fontSize: 12,
  },
};

const ButtonWrapper = styled(BaseButton)`
  text-align: center;
  ${(props) =>
    (props.loading &&
      css`
        pointer-events: none;
        background-color: ${props.color};
      `) ||
    (props.disabled &&
      css`
        pointer-events: none;
        opacity: 0.6;
      `)};
  position: relative;

  &:after {
    width: 100%;
    height: 100%;
    content: '';
    background: #f1f1f1;
    display: block;
    position: absolute;
    // padding: 50%;
    opacity: 0;
    transition: all 0.5s;
  }

  &:active:after {
    padding: 0;
    margin: 0;
    opacity: 0.7;
    transition: 0s;
  }
`;

const GradientButtonWrapper = styled(ButtonWrapper)`
  background-image: linear-gradient(90deg, #006ccb 0%, #006ccb 75%, #65baff 100%);
  background-size: 400% 100%;
  background-position: top 0% right 0%;
  transition: background-position 0.25s ease-in;

  :hover {
    background-position: top 0% right 100%;
  }
`;

export const GradientButton = ({ color, text, size = ButtonSize.big, style = {}, ...rest }) => (
  <GradientButtonWrapper
    style={{
      height: size.height,
      width: size.width,
      // background: `linear-gradient(90deg, #006CCB, #65BAFF)`,
      color: colors.white,
      ...style,
    }}
    {...rest}
  >
    <span style={{ color, width: '100%', fontSize: size.fontSize, fontWeight: style.fontWeight }}>
      {text}
    </span>
  </GradientButtonWrapper>
);

export const Button = ({
  color,
  disabled,
  text,
  children,
  size = ButtonSize.big,
  style = {},
  ...rest
}) => (
  <ButtonWrapper
    style={{ height: size.height, width: size.width, ...style }}
    color={color}
    disabled={disabled}
    {...rest}
  >
    <span
      style={{
        color: disabled ? colors.darkestGrey : color,
        width: '100%',
        fontSize: size.fontSize,
        textAlign: '-webkit-center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {text}
    </span>
  </ButtonWrapper>
);

export const RawButton = ({ text, ...rest }) => (
  <div {...rest}>
    <span>{text}</span>
  </div>
);

export const MainButton = styled(RawButton)`
  position: relative;
  background-color: ${colors.lightBlue};
  color: ${colors.blue};
  border-radius: 3%;
  padding: 2%;
  margin: 0 auto;

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 100%;
  line-height: 80%;

  display: flex;

  width: 40.5%;
  align-items: center;
  text-align: center;

  :active {
    opacity: 0.6;
  }

  span {
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 1024px) {
    display: block;
    width: 80%;
    font-size: 150%;
    padding: 4%;
  }

  cursor: pointer;
`;

export const WhiteButton = styled(Button).attrs((props) => ({
  color: props.color || colors.textColor,
}))`
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGrey};

  @media all and (max-width: 768px) {
    display: none;
  }
`;

export const GreenButton = styled(Button)`
  background: #2ecc71;
  border-radius: 3px;
  border: none;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
`;

export const BlackButton = styled(Button)`
  background: black;
  border: none;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
`;

const ButtonForIcons = styled.div`
  height: 40px;
  background: #3185fc;
  box-shadow: 0px 12px 16px rgba(200, 208, 216, 0.3);
  border-radius: 20px;
  border: none;
  font-weight: bold;
  font-size: 16px;
  line-height: 17px;
  color: #fff;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const IconButton = ({ icon, ...rest }) => {
  if (typeof icon === 'string') {
    const IconComponent = icons[icon];
    icon = <IconComponent />;
  } else if (typeof icon === 'object') {
    if (!React.isValidElement(icon)) {
      const IconComponent = icons[icon.name];
      icon = <IconComponent {...icon} />;
    }
  }
  return <ButtonForIcons {...rest}>{icon}</ButtonForIcons>;
};

const StyledIconButton = styled(IconButton)`
  border-radius: 15px;
  box-shadow: none;
  width: 30px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:after {
    background: rgba(111, 148, 182, 0.1);
    transition: all 0.5s;
    position: absolute;
    width: 160%;
    height: 160%;
    border-radius: 50%;
    content: '';
    opacity: 0;
    pointer-events: none;
  }

  &:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: all 0s;
  }
`;

export const PlayButton = ({ style, ...rest }) => {
  const iconProps = {
    name: 'Play',
    /*width: '28px',
    height: '28px',
    fill: '#BFCADB',*/
  };

  const styles = {
    ...style,
  };

  return <StyledIconButton style={styles} icon={iconProps} {...rest} />;
};

export const PauseButton = ({ style, ...rest }) => {
  const iconProps = {
    name: 'Pause',
    /*width: '28px',
    height: '28px',
    fill: '#BFCADB',*/
  };

  const styles = {
    ...style,
  };

  return <StyledIconButton style={styles} icon={iconProps} {...rest} />;
};

export const AddButton = ({ style, iconStyle, ...rest }) => {
  const iconProps = {
    name: 'Plus',
    width: '10px',
    height: '10px',
    fill: 'white',
    ...iconStyle,
  };

  const styles = {
    backgroundColor: 'black',
    border: '2px solid white',
    marginRight: '4px',
    ...style,
  };

  return <StyledIconButton style={styles} icon={iconProps} {...rest} />;
};

export const RemoveButton = ({ style, iconStyle, disabled = false, ...rest }) => {
  const iconProps = {
    name: 'Minus',
    width: '10px',
    height: '10px',
    fill: 'white',
    ...iconStyle,
  };

  return (
    <StyledIconButton
      style={{ ...style, backgroundColor: 'black', border: '2px solid white', marginLeft: '4px' }}
      icon={iconProps}
      {...rest}
    />
  );
};

export const RemoveAllButton = ({ style, ...rest }) => {
  const iconProps = {
    name: 'Cross',
    width: '8px',
    height: '8px',
    fill: '#fff',
    ...style,
  };

  const styles = {
    borderRaius: '999px',
    backgroundColor: colors.red,
    width: '24px',
    height: '24px',
    ...style,
  };

  return <StyledIconButton style={styles} icon={iconProps} {...rest} />;
};

export const EditButton = ({ style, ...rest }) => {
  const iconProps = {
    name: 'Edit',
    width: '16px',
    height: '16px',
    fill: '#fff',
  };

  const styles = {
    borderRaius: '999px',
    backgroundColor: colors.oceanBlue,
    width: '24px',
    height: '24px',
    ...style,
  };

  return <StyledIconButton style={styles} icon={iconProps} {...rest} />;
};

export const ScrollButton = ({ style, ...rest }) => {
  const iconProps = {
    name: 'Chevron',
    width: '10px',
    height: '10px',
    fill: colors.oceanBlue,
    ...style,
  };
  const styles = {
    borderRadius: ' 0px 4px 4px 0px',
    backgroundColor: '#D6E7FE',
    width: '16px',
    maxHeight: '100%',
    zIndex: 9,
    ...style,
  };

  return <StyledIconButton style={styles} icon={iconProps} {...rest} />;
};
