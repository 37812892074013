import React from 'react';
import moment from 'moment';
import Modal from '../../../views/modal';
import styled from 'styled-components';
import colors from '../../../helpers/colors';
import Text from '../../../views/text';
import OrderStatus from './orderStatus';
import { getClientFullName } from 'app/shared/helpers/client';

const NotificationModal = (props) => {
  const {
    title,
    visible,
    orderId,
    orderDetails,
    dateTime,
    tableName,
    waiterName,
    address,
    addressDetails,
    phone,
    note,
    children,
    actions,
    countNotifications,
  } = props;

  const fullName = getClientFullName(orderDetails);

  return (
    <Modal
      show={visible || false}
      toggle={() => {}}
      showCloseIcon={false}
      backCards={countNotifications}
    >
      <AddItemPopupWrapper>
        <Header height={80}>
          <Title>
            <>
              {title}
              {countNotifications > 1 && (
                <NotificationRedLabel> {countNotifications} </NotificationRedLabel>
              )}
            </>
          </Title>
        </Header>
        <Content>
          <OrderStatus activeOrder={orderDetails} />
          <TopLabelWrapper>{orderId ? `Order Details` : 'Call from:'}</TopLabelWrapper>
          <Flex>
            <Label>Date/Time Placed:</Label>
            <Details>{moment(dateTime).format('DD.MM.YYYY hh:mm A')}</Details>
          </Flex>
          {tableName && (
            <Flex>
              <Label>Table:</Label>
              <Details>{tableName}</Details>
            </Flex>
          )}
          {waiterName && (
            <Flex>
              <Label>Waiter:</Label>
              <Details>{waiterName}</Details>
            </Flex>
          )}
          {fullName && (
            <Flex>
              <Label>Client Name:</Label>
              <Details>{fullName}</Details>
            </Flex>
          )}
          {phone && (
            <Flex>
              <Label>Client Phone Number:</Label>
              <Details>{phone}</Details>
            </Flex>
          )}
          {!tableName && (
            <Flex>
              <Label>Client Address:</Label>
              <Details>{address}</Details>
            </Flex>
          )}
          {!tableName && (
            <Flex>
              <Label>Address Details:</Label>
              <Details>{addressDetails}</Details>
            </Flex>
          )}

          {orderId && (
            <Flex>
              <Label>Note:</Label>
              <Details>{note}</Details>
            </Flex>
          )}
        </Content>
        {/* <Children> */}
        {children}
        {/* </Children> */}

        {/* <Footer style={orderId ? { marginTop: 54 } : {}}> */}
        <Footer>
          {actions.map((Item, i) => (
            <Item key={i} />
          ))}
        </Footer>
      </AddItemPopupWrapper>
    </Modal>
  );
};

export default NotificationModal;

const AddItemPopupWrapper = styled.div`
  width: 504px;
  height: 592px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  padding: 13px 35px;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(244, 244, 244, 0.5);
  height: ${(props) => props.height ?? 'unset'};
`;

const Footer = styled.div`
  padding: 16px 32px;
  border-top: 1px solid ${colors.fog};
  justify-content: center;
  display: flex;
  flex-direction: row;
  // margin-top: 18px;
  padding-bottom: 20px;
  & > * {
    margin-left: 20px;
  }
`;

const Content = styled.div`
  padding: 20px 35px 0 35px;
`;

const Title = styled(Text.H4)`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #3185fc;
  width: 100%;
`;

const Flex = styled.div`
  display: flex;
`;

const TopLabelWrapper = styled.div`
  margin: 5px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #3f3d56;
  margin-bottom: 15px;
`;

const Label = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  flex: 1;
  align-items: left;
  color: #748aa1;
  margin-bottom: 10px;
`;

const Details = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  flex: 2;
  align-items: left;
  color: #3f3d56;
`;

const NotificationRedLabel = styled.div`
  height: 22px;
  width: 22px;
  text-align: center;
  background-color: #ff3a44;
  border-radius: 50%;
  display: inline-block;
  float: right;
  color: #ffffff;
`;
