import React from 'react';
import styled from 'styled-components';

import colors from 'app/shared/helpers/colors';

export const SettingsLabelWrapper = styled.div`
  display: inline-flex;
`;

export const SettingsLabel = styled.div`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  color: ${colors.textColor};
  min-width: 150px;
`;

export const SettingLabelValue = styled.div`
  color: ${colors.blue};
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 23px;
`;

export default ({ label, value, ...rest}) => (
  <SettingsLabelWrapper {...rest}>
    <SettingsLabel>{label}</SettingsLabel>
    <SettingLabelValue>{value}</SettingLabelValue>
  </SettingsLabelWrapper>
);
