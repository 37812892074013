import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button } from './buttons';
import Icons from './icons';
import colors from '../helpers/colors';
import MobileTopBarValidator from '../validators/mobileTopBar.validator';

const TopBarWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MobileTopBar = ({ toggleNavigation, userDetails }) => (
  <TopBarWrapper>
    <Icons.Hamburger fill={colors.blue} onClick={toggleNavigation} />
    <Button color={colors.blue} text={userDetails.user ? userDetails.user.name : null} />
  </TopBarWrapper>
);

MobileTopBar.propTypes = MobileTopBarValidator.MobileTopBarSchema;

const mapStateToProps = (state) => ({
  userDetails: state.userDetails,
});
export default connect(mapStateToProps)(MobileTopBar);
