import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { forgotPassword } from 'actions/authActions';

import shared from 'app/shared';

import AuthLayout from '../auth.layout';
import PageHeader from '../components/PageHeader';
import ForgotPassForm from '../components/ForgotPassForm.js';

const {
  views: {
    Text: {
      Link: { AppLink }
    },
  },
} = shared;

const ForgotPassPage = ({ forgotPassword }) => {
  const onSubmit = (values) => {
    return new Promise((resolve) => {
      forgotPassword(values.email, (err) => {
        if (!err) {
          toast.success('A reset link has been sent to your email address.');
        }
        resolve();
      });
    });
  };

  return (
    <AuthLayout>
      <ForgotPassWrapper>
        <PageHeader>Reset password</PageHeader>
        <ForgotPassForm onSubmit={onSubmit} />
        <ForgotPassNav>
          Nevermind, i remember now!&nbsp;
          <AppLink to="/signin">Sign In</AppLink>
        </ForgotPassNav>
      </ForgotPassWrapper>
    </AuthLayout>
  );
};

export default connect(state => ({
}), {
  forgotPassword
})(ForgotPassPage);


const ForgotPassWrapper = styled.div`
  width: 100%;
`;

const ForgotPassNav = styled.div`
  margin-top: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  & a {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
`;

