import React from 'react';
import shared from 'app/shared';
import styled from 'styled-components';

const {
  views: {
    Button,
  },
  helpers: { colors }
} = shared;

export const StyledButton = styled(Button.Button).attrs(() => ({
  size: {
    width: 205,
    height: 36,
    fontSize: 12
  },
  color: colors.blue
}))`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: 14px;
  display: flex;
  align-items: center;
`;

export const TableButton = (props) =>
<Button.Button
  size={Button.ButtonSize.medium}
  style={{
    backgroundColor: colors.lightBlue,
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    color: colors.blue,
  }}
  {...props}
/>;

export default StyledButton;
