import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import shared from 'app/shared';
import { Formik} from 'formik';
import * as Yup from 'yup';
import {add} from '../../../actions/crudActions';
import crudTypes from '../../../config/crudTypes';

import addCategoryIllustration from '../../../assets/images/addCategoryIllustration.svg';

const {
  views: { Modal, AddItemPopup, Button, Input, Icons, InputArea, Alert },
  helpers: { colors },
} = shared;

const AddItemPopupWrapper = styled.div`
  width: 450px;
`;

const ErrorWrapper = styled.div`
    width: 100%;
    background: rgba(242,69,61, .2);
    color: #F2453D;
    margin-bottom: 10px;
    font-size: 11px;
    line-height: 18px;
    border-radius: 3px;
    align-items: center;
    letter-spacing: 1px;
    font-weight: bold;
    padding: 1px 5px;
    display: ${props => props.show ? 'flex' : 'none'};
`;

const WarningWrapper = styled.div`
    width: 100%;
    display: flex;
    background: rgba(255,152,0, .2);
    color: #FF9800;
    margin-bottom: 10px;
    font-size: 11px;
    line-height: 18px;
    border-radius: 3px;
    align-items: center;
    letter-spacing: 1px;
    font-weight: bold;
    padding: 1px 5px;
    margin-top: 10px;
`;

const ContactForm = (props) => {
  const [postStatus, setPostStatus] = useState('');
  const {toggle, show = false } = props;

//   const submitEmail = (data, resetForm) => {
//     data.pageId = JSON.parse(localStorage.getItem("Page"));
//     props.add(crudTypes.category, data, changePostStatus(resetForm));
//   }

 const changePostStatus = setSubmitting => status => {
    setPostStatus(status);
    setSubmitting(status === "SUBMITTING");
  }

  return (
    <Formik
      initialValues={{
        email: '',
        message: '',
        subject: props.title
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email().required('Email is required'),
        message: Yup.string().required('Message is required'),
      })}
      onSubmit={(values, {setSubmitting}) => {
          changePostStatus(setSubmitting)("SUBMITTING");
          props.add(crudTypes.helpCenter, values, changePostStatus(setSubmitting));
      }}
    >
      {innerProps => {
        const { touched, errors, values, handleChange, handleSubmit, isSubmitting } = innerProps;
        return (
          <div>
            <Modal toggle={toggle} show={show}>
              <AddItemPopupWrapper>
                <AddItemPopup
                  title={props.title}
                  actions={
                      postStatus === "OK" ?
                      [
                        () => <Button.Button color={colors.green} text={"Close"} onClick={() => { props.toggle(); }} />,
                      ]
                      :
                      [
                        () => <Button.Button color={colors.blue} text={"Submit"} onClick={handleSubmit} disabled={isSubmitting} />,
                        () => <Button.WhiteButton text={"Cancel"} onClick={() => { props.toggle(); }} disabled={isSubmitting} />,
                      ]
                  }
                  illustration={addCategoryIllustration}
                >
                  <Alert show={postStatus === "OK"} message={"Your message was successfully submitted to our Help Center."} />
                  <ErrorWrapper show={postStatus === "ERROR"}>
                    <Icons.XIcon fill={colors.red} style={{marginRight: 10}} height={16} />
                    <span>Error sending your message</span>
                  </ErrorWrapper>
                  <AddItemPopup.InputRow>
                    <Input
                      id={"email"}
                      labelText={"Your Email address"}
                      onChange={handleChange}
                      value={values.email}
                      autoComplete='on'
                      disabled={isSubmitting}
                    />
                    {errors.email && touched.email ? (
                      <WarningWrapper> <Icons.WarningIcon fill={colors.yellow} style={{marginRight: 10}} height={16} /> {errors.email}</WarningWrapper>
                    ) : null}
                  </AddItemPopup.InputRow>
                  <AddItemPopup.InputRow>
                    <InputArea
                      id={"message"}
                      labelText={"Your message goes here"}
                      onChange={handleChange}
                      value={values.message}
                      autoComplete='off'
                      rows={10}
                      disabled={isSubmitting}
                    />
                    {errors.message && touched.message ? (
                      <WarningWrapper> <Icons.WarningIcon fill={colors.yellow} style={{marginRight: 10}} height={16} /> {errors.message}</WarningWrapper>
                    ) : null}
                  </AddItemPopup.InputRow>
                </AddItemPopup>
              </AddItemPopupWrapper>
            </Modal>
          </div>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => ({});

export default connect(
 mapStateToProps,
 {add}
)(ContactForm);

