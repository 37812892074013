import React from 'react';
import styled from 'styled-components';

import colors from '../helpers/colors';
import hexToRGBA from '../helpers/hexToRGBA';
import text from './text';
import icons from './icons';

const {
  Paragraph: { P },
} = text;

const AlertColors = {
  error: colors.red,
  warning: colors.orange,
  serious_warning: colors.red,
  success: colors.green,
  pending: colors.orange,
};

const AlertIcons = {
  error: icons.ErrorIcon,
  warning: icons.WarningIcon,
  serious_warning: icons.WarningIcon,
  success: icons.SuccessIcon,
  pending: icons.Clock,
};

const AlertWrapper = styled.div`
  border-left: 3px solid ${(props) => AlertColors[props.type]};
  min-height: 24px;

  background-color: ${(props) => hexToRGBA(AlertColors[props.type], 0.2)};
  color: ${(props) => AlertColors[props.type]};

  display: ${(props) => (props.show ? 'flex' : 'none')};
  align-items: center;
`;

const AlertMessage = styled(P)`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: inherit;
`;

const AlertIconWrapper = styled.div`
  padding: 15px;
  display: flex;
`;

export default React.forwardRef(({ type = 'success', show, message, className }, ref) => {
  const AlertIcon = AlertIcons[type];

  return (
    <AlertWrapper ref={ref} show={show} type={type} className={className}>
      <AlertIconWrapper>
        <AlertIcon fill={AlertColors[type]} width="10px" height="10px" />
      </AlertIconWrapper>
      <AlertMessage>{message}</AlertMessage>
    </AlertWrapper>
  );
});

export const scrollToAlert = (alertRef) => {
  setTimeout(() => {
    alertRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  }, 10);
};
