const initialState = {
  showUpgradeModal: false,
  activePageSubscription: null,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_UPGRADE_MODAL':
      return { ...state, showUpgradeModal: action.data };

    case 'GET_ACTIVE_PAGE_SUBSCRIPTION_OK':
      return { ...state, activePageSubscription: action.data };

    case 'GET_SUBSCRIPTIONS_OK': {
      return { ...state, data: action.data };
    }

    case 'SET_GUEST_CHECKOUT_ID_OK': {
      return { ...state, guestCheckoutId: action.data };
    }

    default:
      return state;
  }
};
