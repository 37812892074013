import React, { useState, useEffect } from 'react';
import shared from 'app/shared';
import { connect } from 'react-redux';
import _ from 'lodash';

import styled from 'styled-components';

import { getAllPaginated, remove } from 'actions/crudActions';
import crudTypes from 'config/crudTypes';

import CampaignCard from './campaignCard';
import SearchInput from 'app/shared/views/searchInput';
import Button from '../components/buttons';
import { filterData } from 'config/helpers/searcher';
import { NavLink } from 'react-router-dom';
import 'actions/campaignActions';
import { toast } from 'react-toastify';
import { CAMPAIGN_STATUS } from '../constants';
import { selectSmsCampaignMessages } from 'reducers/campaignsReducer';
import noRecordsImage from 'assets/images/no_records.svg';

const {
  views: {
    Text: { H4 },
    Grid,
    FilterableTableStructure: { SearchWrapper, ActionBarWrapper },
  },
  helpers: { colors },
} = shared;

const ViewWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CampaignSectionTitle = styled(H4)`
  color: ${colors.blue};
  height: 22px;
  line-height: 22px;
`;

const CampaignSectionTitleWrapper = styled.div`
  border-bottom: 2px solid ${colors.blue};
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

const CampaignSectionWrapper = styled.div`
  padding-top: 30px;
`;

const CampaignSection = ({ title, campaigns, onDelete }) =>
  campaigns &&
  campaigns.length > 0 && (
    <>
      <CampaignSectionTitleWrapper>
        <CampaignSectionTitle>{title}</CampaignSectionTitle>
      </CampaignSectionTitleWrapper>
      <Grid>
        {campaigns.map((campaign) => (
          <Grid.Section
            key={campaign.id}
            sizes={{
              desktop: 4,
              tablet: 4,
            }}
          >
            <CampaignCard campaign={campaign} onDelete={onDelete} />
          </Grid.Section>
        ))}
      </Grid>
    </>
  );

const EmptyImage = styled.img`
  display: block;
  margin: 100px auto;
`;

const MarketingCampaigns = ({ getAllPaginated, remove, campaigns, smsCampaignMessages }) => {
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    getAllPaginated(crudTypes.campaign, (s) => console.log(s));
  }, [getAllPaginated]);

  const deleteCampaign = (id) => {
    remove(id, crudTypes.campaign, (s) => {
      if (s === 'OK') {
        toast.info('Campaign deleted!');
      } else {
        toast.error('Could not delete campaign!');
      }
    });
  };

  const filteredCampaigns = filterData(campaigns || [], searchValue);
  const enhancedCampaigns = filteredCampaigns.map((campaign) => {
    const messages = smsCampaignMessages.filter((m) => m.campaignId === campaign.id);
    const linkClicks = _.sumBy(messages, (m) => m.linkClickedCount);
    return {
      smsSent: messages.length,
      linkClicks,
      ...campaign,
    };
  });
  const completedCampaigns = enhancedCampaigns.filter(
    (c) => c.status === CAMPAIGN_STATUS.Completed,
  );
  const sendingCampaigns = enhancedCampaigns.filter(
    (c) => c.status === CAMPAIGN_STATUS.ReadyToSend,
  );
  const draftCampaigns = enhancedCampaigns.filter((c) => c.status === CAMPAIGN_STATUS.Draft);
  const isEmpty = _.isEmpty(enhancedCampaigns);
  return (
    <ViewWrapper>
      <ActionBarWrapper>
        <NavLink to={'/dashboard/marketing/campaigns/new'} style={{ textDecoration: 'none' }}>
          <Button text={'Create New Campaign'} />
        </NavLink>
        <SearchWrapper>
          <SearchInput search={searchValue} onSearchChanged={setSearchValue} />
        </SearchWrapper>
      </ActionBarWrapper>
      <CampaignSectionWrapper>
        <CampaignSection title={'Completed'} campaigns={completedCampaigns} />
        <CampaignSection title={'Sending'} campaigns={sendingCampaigns} />
        <CampaignSection title={'Drafts'} campaigns={draftCampaigns} onDelete={deleteCampaign} />
        {isEmpty && <EmptyImage src={noRecordsImage} alt={'No records'} />}
      </CampaignSectionWrapper>
    </ViewWrapper>
  );
};

const mapStateToProps = (state) => ({
  campaigns: state.campaigns.data,
  smsCampaignMessages: selectSmsCampaignMessages(state),
});

export default connect(mapStateToProps, { getAllPaginated, remove })(MarketingCampaigns);
