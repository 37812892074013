import React, {useState} from 'react';
import styled from 'styled-components';
import colors from '../../helpers/colors';
import Icons from '../icons';
import AdminClientFilterPopUp from './adminClientFilterPopUp';
import FilterBar from '../filterBar';

const FilterBarWrapper = styled.div`
  border-bottom: 2px solid ${colors.blue};
  align-items: center;
  display: flex;
  justify-content: space-between;

  @media all and (min-width: 768px) {
      display: none;
  }

  padding-bottom: 10px;
  padding-top: 10px;
`;

const FilterButton = styled.div`
    width: 40px;
    height: 40px;
    border: 1px solid #E9EAF1;
    box-sizing: border-box;
    border-radius: 3px;
    justify-content: center;
    background-color: #ffffff;
    align-items: center;
    display: inline-flex;
    align-self: center;
`;

const FilterButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const TextWrapper = styled.span`
    color: #748AA1;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    padding-right: 5px;
`;

const AdminClientMobileFilter = props => {
  const [show, setShow] = useState(false);

  const toggle = () => {
      setShow(!show)
  }

  return (
        <div>
            <FilterBarWrapper>
                <FilterBar.End>
                    <FilterBar.Components.ClearFilters onClick={props.clearFilters} style={{fontSize: 13}} />
                </FilterBar.End>
                <FilterButtonWrapper>
                  <TextWrapper>
                    {props.selectedPeriod.value.toUpperCase()}
                  </TextWrapper>
                  <FilterButton onClick={ () => {toggle()}}>
                      <Icons.FilterIcon style={{ display: 'inline-flex'}} />
                  </FilterButton>
                </FilterButtonWrapper>
            </FilterBarWrapper>
            <AdminClientFilterPopUp
              show={show}
              toggle={toggle}
              selectedPeriod={props.selectedPeriod}
              updateSelectedPeriod={props.updateSelectedPeriod}>
            </AdminClientFilterPopUp>
        </div>
    )
}

export default AdminClientMobileFilter;
