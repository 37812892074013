import crudTypes from 'config/crudTypes';
import crudReducer from './crudReducer';

const crudReportReducer = crudReducer(crudTypes.report);
const initialState = {
  revenue: [],
  clients: [],
  orders: [],
  total: {
    clientCount: 0,
    orderCount: 0,
    revenue: 0
  },
};


function createTotalFromData(data) {
  let total = {
    clientCount: 0,
    orderCount: 0,
    revenue: 0
  };

  for(const item of data) {
    total.clientCount += item.clientCount
    total.orderCount += item.orderCount
    total.revenue += item.revenue
  }

  return total
}

function orderByDay(data, type) {
  let items = []
  for(const item of data) {
    items.push({value: item[type], timestamp: +new Date(item.orderDate)})
  }
  return items
}

export default (state = initialState, action) => {
  switch (action.type) {
    case `REPORT_OK`:
      return {
        ...state,
        revenue: orderByDay(action.data, 'revenue'),
        orders: orderByDay(action.data, 'orderCount'),
        clients: orderByDay(action.data, 'clientCount'),
        total: createTotalFromData(action.data)
      };
    default:
      return crudReportReducer(state, action)
  }
};
