import React, { useEffect, useMemo, useState } from 'react';
import shared from 'app/shared';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import styled from 'styled-components';

import { getAll, getAllPaginated } from 'actions/crudActions';
import crudTypes from 'config/crudTypes';
import { filterData } from 'config/helpers/searcher';
import { paginate } from 'config/helpers/paginator';
import { getSortState, sortData } from 'config/helpers/sorter';

import { MARKETING_TABLE_HEADS } from '../constants';
import { applyCustomFilters } from '../helpers/applyFilters';
import FilterBar from '../components/filterBar';
import SearchInput from 'app/shared/views/searchInput';
import MessageWindow from 'app/marketing/components/campaignMessageWindow';
import { withRouter } from 'react-router-dom';
import { CAMPAIGN_STATUS } from 'app/marketing/constants';

const {
  views: {
    Table,
    Button: { WhiteButton },
    FilterableTableStructure: { SearchWrapper, TableWrapper, PaginatorWrapper, ActionBarWrapper },
    Paginator,
    Card,
  },
} = shared;

const BackButton = styled(WhiteButton).attrs({
  size: {
    width: 117,
    height: 36,
    fontSize: 16,
  },
})``;

const CampaignsTableWrapper = styled(TableWrapper)`
  margin-top: unset;
  min-height: 100%;
  border-left: 0;
  border-top: 0;
  border-bottom: 0;
  border-radius: 0;
  width: ${(props) => (props.collapsed ? '50%' : '100%')};
  & th {
    line-height: 66px;
  }
  & td {
    line-height: 57px;
  }

  & thead * {
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
  }
`;

const CardWrapper = styled(Card)`
  width: 100%;
  display: flex;
  height: 660px;
  margin-top: 30px;
`;

const NewCampaignPage = ({ clients, campaign, getAll, getAllPaginated, ...props }) => {
  const [state, setState] = useState({
    sort: {
      key: 'id',
      order: 1,
    },
    currentPage: 1,
    pageSize: 10,
    filters: {
      search: '',
      orderMin: '',
      orderMax: '',
      revenueMin: '',
      revenueMax: '',
      customDate: {
        startDate: null,
        endDate: null,
      },
    },
  });

  const updateFilters = (changedFilter) => {
    setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        ...changedFilter,
      },
    }));
  };

  const updateCustomDate = (customDate) => {
    if (customDate) {
      setState((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          customDate: {
            startDate: customDate.startDate,
            endDate: customDate.endDate,
          },
        },
      }));
    }
  };

  const setSearchValue = (value) => {
    setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        search: value,
      },
      currentPage: 1,
    }));
  };

  const clearFilters = () => {
    setState((prevState) => ({
      ...prevState,
      filters: {
        search: '',
        orderMin: '',
        orderMax: '',
        revenueMin: '',
        revenueMax: '',
        customDate: {
          startDate: null,
          endDate: null,
        },
      },
    }));
  };

  useEffect(() => {
    getAll(crudTypes.client);
    getAllPaginated(crudTypes.campaign);
  }, [getAll, getAllPaginated]);

  const useValues = () => {
    const { filters } = state;

    let values = clients || [];
    values = useMemo(() => values.map((c) => ({
      ...c,
      lastOrderDate: c.orders
        .map((o) => o.orderCreatedDate)
        .reduce((a, b) => (moment(a) > moment(b) ? a : b), 0),
    })), [values]);

    values = useMemo(() => {
      const filtered = values.filter(applyCustomFilters(filters));
      return filterData(filtered, filters.search);
    }, [filters, values]);

    return values;
  };

  const updatePaginationPage = (page) => {
    setState((prevState) => ({
      ...prevState,
      currentPage: page,
    }));
  };

  const updateSort = (key) => {
    key !== 'all' &&
      setState((prevState) => ({
        ...prevState,
        sort: getSortState(prevState.sort, key),
      }));
  };

  const idArrayToObject = (ids, initValue = false) =>
    _(ids)
      .mapKeys()
      .mapValues(() => initValue)
      .value();

  const useSelected = (items, initialSelected = []) => {
    const [isSelected, setIsSelected] = useState(idArrayToObject(initialSelected, true));

    const itemIds = _.map(items, 'id');
    const itemsUnselected = idArrayToObject(itemIds);
    const setSelected = (ids) => {
      if (Array.isArray(ids)) {
        setIsSelected(_.assign({}, isSelected, itemsUnselected, idArrayToObject(ids, true)));
      } else {
        setIsSelected({ ...isSelected, ...ids });
      }
    };

    const selected = _(isSelected).pick(itemIds).defaults(itemsUnselected).value();
    return [selected, setSelected];
  };

  const { filters, currentPage, pageSize, sort } = state;

  const shouldDisplay = true;

  let headers = MARKETING_TABLE_HEADS;
  headers = shouldDisplay ? [...headers.slice(0, 4)] : headers;

  const campaignRecipients = campaign
    ? campaign.smsCampaignMessages.map((m) => parseInt(m.smsConversation.clientId))
    : [];

  const values = useValues();
  const [selected, setSelected] = useSelected(values, campaignRecipients);
  const sortedValues = sortData(values, sort);
  const paginatedValues = paginate(sortedValues, currentPage, pageSize);

  return (
    <>
      <ActionBarWrapper style={{ marginBottom: '30px' }}>
        <BackButton text={'Go Back'} onClick={() => props.history.goBack()} />
        <SearchWrapper>
          <SearchInput search={filters.search} onSearchChanged={(v) => setSearchValue(v)} />
        </SearchWrapper>
      </ActionBarWrapper>
      {campaign && campaign.status !== CAMPAIGN_STATUS.Draft ? (
        <h1>WIP will show campaign statistics here</h1>
      ) : (
        <>
          <FilterBar
            {...filters}
            updateCustomDate={updateCustomDate}
            updateFilters={updateFilters}
            clearFilters={clearFilters}
          />
          <CardWrapper>
            <CampaignsTableWrapper collapsed={shouldDisplay}>
              <Table.SelectTableView
                selected={selected}
                setSelected={setSelected}
                data={{
                  header: headers,
                  values: paginatedValues.current,
                  action: updateSort,
                }}
              />
            </CampaignsTableWrapper>
            {shouldDisplay && (
              <MessageWindow clients={clients} selected={selected} setSelected={setSelected} />
            )}
          </CardWrapper>
          <PaginatorWrapper>
            <Paginator
              changePage={updatePaginationPage}
              numberOfPages={paginatedValues.pages}
              page={paginatedValues.currentPage}
            />
          </PaginatorWrapper>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const campaignId = parseInt(ownProps.match?.params?.id);
  return {
    clients: state.clients.data,
    campaign: campaignId ? state.campaigns.data.find((c) => c.id === campaignId) : undefined,
  };
};

export default withRouter(connect(mapStateToProps, { getAll, getAllPaginated })(NewCampaignPage));
