import React from 'react';
import styled from 'styled-components';
import currencyFormatter from 'app/shared/helpers/currencyFormatter';
import colors from 'app/shared/helpers/colors';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 20px 10px 0;
  cursor: pointer;

  @media only screen and (max-width: 700px) {
    width: calc(100vw - 60px);
  }
  @media only screen and (min-width: 701px) and (max-width: 900px) {
    width: calc(50vw - 40px);
  }
  @media only screen and (min-width: 901px) {
    width: calc(50vw - 120px);
  }
  @media only screen and (min-width: 1200px) {
    width: calc(100vw / 3 - 100px);
  }
`;
const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  position: relative;
  width: 100%;
`;
const Image = styled.img`
  border-radius: 2px;
  width: 140px;
  min-width: 140px;
  height: 102px;
  object-fit: cover;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const PageName = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #2a2e45;
`;
const Categories = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #bfcadb;
  flex: 1;
  display: block;
  display: -webkit-box;
  margin: 0 auto;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const CardInfoLabel = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.004em;
  color: #5c677b;
`;
const CardInfoValue = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: ${({ color }) => (color ? color : '#2ecc71')};
  padding-left: 4px;
`;

const DEFAULT_IMAGE =
  'https://orderoo-photo-container-prod.s3.eu-central-1.amazonaws.com/default_image.png';

export default ({
  pageName,
  aggregatorPictureURL,
  minimumOrderValue,
  currency,
  deliveryTimeRange,
  aggregatorCategories,
  pageServices,
  isOpen,
  onClick,
}) => {
  let categories = aggregatorCategories
    .filter((x) => x.name !== 'All')
    .map((x) => x.name)
    .join(', ');
  const hasOnlineOrder = !!pageServices.find(
    (x) => x.name === 'OnlineOrder' && x.isActive === true,
  );
  return (
    <Container onClick={onClick}>
      <Image src={aggregatorPictureURL ?? DEFAULT_IMAGE} alt={pageName} />
      <InnerContainer>
        <Row>
          <PageName>{pageName}</PageName>
        </Row>
        <Row style={{ marginTop: 4 }}>
          <Categories>{categories}</Categories>
          {isOpen ? (
            <CardInfoValue>Open</CardInfoValue>
          ) : (
            <CardInfoValue color={colors.red}>Closed</CardInfoValue>
          )}
        </Row>
        {hasOnlineOrder && (
          <>
            <Row style={{ marginTop: 8, inlineSize: 'max-content' }}>
              <CardInfoLabel>Min Order: </CardInfoLabel>
              <CardInfoValue>{currencyFormatter(minimumOrderValue, currency)}</CardInfoValue>
            </Row>
            <Row style={{ marginTop: 4, inlineSize: 'max-content' }}>
              <CardInfoLabel>Delivery Time: </CardInfoLabel>
              <CardInfoValue>{deliveryTimeRange ?? 'N/A'}</CardInfoValue>
            </Row>
          </>
        )}
      </InnerContainer>
    </Container>
  );
};
