import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import colors from 'app/shared/helpers/colors';
import Icons from 'app/shared/views/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faConciergeBell } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

const NotificationListWrapper = styled.div`
  width: fill-available;
  overflow: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
  flex-wrap: wrap;
`;

const ListElementWrapper = styled.li`
  display: flex;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  list-style: none;
  font-weight: 500;
  padding: 8px 16px 8px 20px;
  color: ${(props) => props.color};
`;

const IconWrapper = styled.div`
  padding-right: 16px;
`;

const NotificationBody = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  flex: 1;
  padding: 0 10px 0 0;
`;

const NotificationTime = styled.span`
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
`;

const NotificationItem = ({ icon: Icon, title, time, iconColor, textColor, onClick, to }) => (
  <NavLink to={to}>
    <ListElementWrapper color={textColor} onClick={onClick}>
      <IconWrapper>
        <Icon width={'18px'} height={'18px'} fill={iconColor} />
      </IconWrapper>
      <NotificationBody>{title}</NotificationBody>
      <NotificationTime>{time}</NotificationTime>
    </ListElementWrapper>
  </NavLink>
);

const getTableName = (tables, tableId) => {
  const table = tables.find((x) => x.id === tableId);
  return table?.name ?? `Table ${tableId}`;
};

const NotificationItemTypes = {
  CLIENT: (props) => (
    <NotificationItem
      {...props}
      icon={Icons.ClientNotificationIcon}
      title="New client added"
      to={`/dashboard/client/editclient/${props.itemId}`}
    />
  ),
  ORDER: (props) => (
    <NotificationItem
      {...props}
      icon={Icons.Orders}
      title={`New order ${props.itemId} added`}
      to={`/dashboard/order/editorder/${props.itemId}`}
    />
  ),
  GET_BILL: (props) => {
    const tableName = getTableName(props.tables, props.itemId);
    return (
      <NotificationItem
        {...props}
        icon={Icons.Bill}
        title={`${tableName} requesting the bill`}
        to={'#'}
      />
    );
  },
  WAITER: (props) => {
    const tableName = getTableName(props.tables, props.itemId);
    return (
      <NotificationItem
        {...props}
        icon={() => <FontAwesomeIcon icon={faConciergeBell} size="lg" />}
        title={`${tableName} requesting the waiter`}
        to={'#'}
      />
    );
  },
};

const notificationType = {
  0: 'ORDER',
  1: 'CLIENT',
  2: 'WAITER',
  3: 'GET_BIL',
};

const NotificationList = ({ data, markAsRead, toggle, tables, getAll, ...rest }) => {
  return (
    <NotificationListWrapper {...rest}>
      {data &&
        data.map((notification) => {
          const NotificationItem = NotificationItemTypes[notificationType[notification.notificationType]];
          if (!NotificationItem) return null;
          return (
            <NotificationItem
              key={notification.id}
              textColor={notification.status === 'UNREAD' ? colors.blue : colors.grey}
              iconColor={notification.status === 'UNREAD' ? colors.blue : colors.grey}
              time={moment(notification.creationDate).format('hh:mm A')}
              onClick={() => {
                markAsRead(notification.id);
                toggle();
              }}
              itemId={notification.itemId}
              tables={tables}
            />
          );
        })}
    </NotificationListWrapper>
  );
};

const mapStateToProps = (state) => ({
  tables: (state.facebookPages.activePage?.tableCategories ?? []).map((x) => x.tables).flat(),
});

export default connect(mapStateToProps)(NotificationList);
