import React from 'react';
import styled from 'styled-components';

const NotSubscribed = () => {
  const NoSubMessage = styled.div`
    text-align: center;
    margin: 30px auto;
    line-height: 1.5;
  `;

  return (
    <NoSubMessage>
      <p>Thank you for trying Orderoo!</p>
      <p>
        This business has no subscription active, and you can't make an order. We are sorry{' '}
        <span role="img" aria-label="sadface">
          &#128532;
        </span>
      </p>
    </NoSubMessage>
  );
};

export default NotSubscribed;
