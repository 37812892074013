import React, { useState } from 'react';
import styled from 'styled-components';
import shared from 'app/shared';
import { connect } from 'react-redux';
import { CHARACTER_LIMIT } from '../constants';
import Spinner from 'app/shared/views/spinner';
import { sendSms } from 'actions/marketingActions';
import Button from 'app/marketing/components/buttons';
import { toast } from 'react-toastify';

const SUCCESS_MESSAGE = 'Your SMS message was successfully sent.';
const ERROR_MESSAGE = 'Your SMS message could not be delivered.';

const {
  views: {
    Icons,
    InputArea,
    Text: {
      Link: { Link },
    },
  },
  helpers: { colors },
} = shared;

const StyledInputArea = styled(InputArea)`
  position: relative;
  overflow: auto;
  display: flex;
  border-radius: 0;
  border-top: 1px solid #e1e6ec;
  border-right: 0;
  border-bottom: 1px solid #e1e6ec;
  border-left: 0;
  height: 100%;
`;
// height: 532px;
// height: 175px;

const StyledLink = styled(Link)`
  display: inline-flex;
  font-size: 12px;
`;

const AddMessengerLink = ({ onClick }) => (
  <StyledLink onClick={onClick}>
    <Icons.Link style={{ marginRight: '5px' }} />
    Add Messenger Link
  </StyledLink>
);

const SendButton = styled(Button).attrs(() => ({
  size: {
    width: 70,
    height: 24,
    fontSize: 12,
  },
}))`
  margin: 0 30px;
`;

const Placeholder = styled.div`
  color: #bfc4d1;
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  user-select: none;
`;

const InputAreaEndBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 4px 15px 15px 15px;
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 59px;
`;

const MessageInputSection = ({ clientId, sendSms, rows = 7, placeholder, sendLabel = 'Send' }) => {
  const [requestStatus, setRequestStatus] = useState('idle');
  const [inputText, setInputText] = useState('');
  const characterCount = `${CHARACTER_LIMIT - inputText.length} Characters Left`;

  const updateText = (text) => {
    if (text.length <= CHARACTER_LIMIT) setInputText(text);
  };

  const addMessengerLink = () => {
    const newMsgContent = inputText + `{{messenger_link}}`;
    updateText(newMsgContent);
  };

  const handleSubmit = () => {
    sendSms(clientId, inputText, (status, err, res) => {
      setRequestStatus(status);
      console.log(err, res);
      if (status === 'success') {
        toast.success(SUCCESS_MESSAGE);
        updateText('');
      } else if (status === 'error') {
        toast.error(ERROR_MESSAGE);
      }
    });
  };

  const isSubmitting = requestStatus === 'loading';
  const emptyInputs = inputText === '';

  const sendButtonText = isSubmitting ? (
    <>
      <div>
        <Spinner size={12} color={colors.white} style={{ width: 'unset' }} />
      </div>
    </>
  ) : (
    sendLabel
  );

  return (
    <>
      <StyledInputArea
        placeholder={placeholder}
        value={inputText}
        onChange={(e) => updateText(e.target.value)}
        rows={rows}
        end={
          <InputAreaEndBar>
            <Placeholder>{characterCount}</Placeholder>
          </InputAreaEndBar>
        }
        disabled={isSubmitting}
      />
      <div>
        <ActionBar>
          <AddMessengerLink onClick={() => addMessengerLink()} />
          <SendButton
            size={{
              width: 100,
            }}
            text={sendButtonText}
            onClick={handleSubmit}
            loading={isSubmitting ? 'yes' : undefined}
            disabled={emptyInputs}
          />
        </ActionBar>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  sendSms,
})(MessageInputSection);
