import React from 'react';
import { connect } from 'react-redux';
import ProductListItem from './ProductListItem';
import { List, InfiniteLoader, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import colors from 'app/shared/helpers/colors';
import {
  addProductToOrder,
  removeProductFromOrder,
  getProductsByCategory,
} from 'actions/shoppingActions';
import Spinner from 'app/shared/views/spinner';

const STATUS_LOADING = 1;

class ProductList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 101,
      defaultHeight: 101,
    });
    this.state = {
      magProducts: {},
    };

    this._isRowLoaded = this._isRowLoaded.bind(this);
    this._loadMoreRows = this._loadMoreRows.bind(this);
    this._rowRenderer = this._rowRenderer.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.order !== this.props.order ||
      this.props.order.total > 0 ||
      prevProps.scrollElement !== this.props.scrollElement
    ) {
      this.cache.clearAll();
      this.listRef.forceUpdateGrid();
    }
  }

  _rowRenderer({ index, key, style, parent }) {
    const {
      categories,
      pageInfo,
      addProductToOrder,
      removeProductFromOrder,
      promotions,
      setAddProduct,
      order,
      canOrder = true,
      internalOrder,
    } = this.props;
    const { magProducts } = this.state;

    const product = magProducts[index];

    const content =
      !product || product === STATUS_LOADING ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <Spinner size={'40px'} color={colors.black} />
        </div>
      ) : (
        <ProductListItem
          product={product}
          categories={categories}
          currency={pageInfo.currency}
          description={product.description}
          promotions={promotions}
          addProduct={() => {
            addProductToOrder(product, promotions);
          }}
          removeProduct={() => {
            removeProductFromOrder(product, promotions);
          }}
          isCombo={product.isCombo}
          setAddProduct={setAddProduct}
          internalOrder={internalOrder}
          productsInCart={((order, p) =>
            order.orderProducts.reduce((acc, op) => {
              op.id === p.id && acc.push(op);
              return acc;
            }, []))(order, product)}
          qty={((order, p) => {
            const op = order.orderProducts.reduce((acc, op) => {
              op.id === p.id && acc.push(op);
              return acc;
            }, []);
            return op.length > 1 ? op.length : op[0]?.qty || 0;
          })(order, product)}
          canOrder={canOrder}
        />
      );

    return (
      <CellMeasurer cache={this.cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
        {({ registerChild }) => (
          <div style={style} ref={registerChild}>
            {content}
          </div>
        )}
      </CellMeasurer>
    );
  }

  _isRowLoaded({ index }) {
    const { magProducts } = this.state;
    return !!magProducts[index];
  }

  _loadMoreRows({ startIndex, stopIndex }) {
    const { magProducts } = this.state;
    const { getProductsByCategory, category, search } = this.props;
    for (let i = startIndex; i <= stopIndex; i++) {
      magProducts[i] = STATUS_LOADING;
    }

    getProductsByCategory(startIndex, stopIndex, category.id, search, (err, data) => {
      if (!err) {
        for (let i = 0; i < data.length; i++) {
          magProducts[startIndex + i] = data[i];
        }
        this.setState({ lastLoad: { startIndex, stopIndex } });
      }
    });
  }

  render() {
    const { category, windowScrollerProps } = this.props;
    // const { height, isScrolling, onChildScroll, scrollTop, width } = windowScrollerProps;
    const rowCount = category?.productCount ?? 1;
    return (
      <InfiniteLoader
        isRowLoaded={this._isRowLoaded}
        loadMoreRows={this._loadMoreRows}
        rowCount={rowCount}
        minimumBatchSize={64}
      >
        {({ onRowsRendered, registerChild }) => (
          <List
            style={{ outline: 'none' }}
            ref={(ref) => {
              registerChild(ref);
              this.listRef = ref;
            }}
            deferredMeasurementCache={this.cache}
            autoHeight
            height={windowScrollerProps?.height || 500}
            width={windowScrollerProps?.width}
            onRowsRendered={onRowsRendered}
            rowCount={rowCount}
            rowHeight={this.cache.rowHeight}
            rowRenderer={this._rowRenderer}
            scrollTop={windowScrollerProps?.scrollTop}
            onScroll={windowScrollerProps?.onChildScroll}
            isScrolling={windowScrollerProps?.isScrolling}
          />
        )}
      </InfiniteLoader>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  addProductToOrder,
  removeProductFromOrder,
  getProductsByCategory,
})(ProductList);
