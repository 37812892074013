const initialState = {
    data: []
}
export default (state = initialState, action) => {
    switch(action.type) {
        case 'GET_ALL_PAGE_USERS':
      return {
        data: [...action.data],
      };
      case 'PUT_PAGE_USER':
        return {
          data: [...state.data.filter(x => x.id !== action.data.id), action.data],
        };
      case 'DELETE_PAGE_USER':
        return {
          data: [ ...state.data.filter(x => x.id !== action.data) ],
        };
      default:
        return state
    }
}
