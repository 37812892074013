import React from 'react';
import styled from 'styled-components';
import shared from 'app/shared';
import { Formik, FieldArray } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import addOrderPopup from '../../../assets/images/addOrderPopup.svg';
import { Autocomplete } from './autocomplete';
import crudTypes from '../../../config/crudTypes';
import { add, getAll } from 'actions/crudActions';
import hexToRGBA from 'app/shared/helpers/hexToRGBA';
import { compose } from 'recompose';
import { GoogleApiWrapper } from 'google-maps-react';
import Config from 'config';
import currencyFormatter from 'app/shared/helpers/currencyFormatter';
import _ from 'lodash';

const MAX_SHOWN_ADDRESSES = 3;

const {
  views: { Modal, AddItemPopup, Button, Input, Icons },
  helpers: { colors, transition },
} = shared;

const AddOrderWrapper = styled.div`
  display: flex;
  padding-right: 30px;
  border-bottom: 1px solid #e9eaf1;
`;

const ClientDetailsWrapper = styled.div`
  border-right: 1px solid #e9eaf1;
`;

const AddOrderLabel = styled.div`
  font-size: 24px;
  padding: 30px 30px;
  color: ${colors.blue};
  font-weight: 500;
`;

const ClientDetailsBodyWrapper = styled.div`
  margin: 30px;
`;

const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 1px solid #bebebe;
`;

const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;

  &:hover ~ ${RadioButtonLabel} {
    background: #bebebe;

    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      background: #eeeeee;
    }
  }

  &:checked + ${RadioButtonLabel} {
    background: ${colors.blue};
    border: 1px solid ${colors.blue};

    &::after {
      content: '';
      display: block;
      margin: auto auto;
      border-radius: 50%;
      width: 50%;
      height: 50%;
      margin-top: 25%;
      margin-left: 25%;
      box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
      background: white;
    }
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
`;

const RadioButtonText = styled.label`
  color: ${colors.darkGrey};
  font-size: 12px;
  line-height: 23px;
  font-weight: 500;
  padding-left: 10px;
`;

const OrderDetailsWrapper = styled.div``;

const OrderDetailsHeaderWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

const ImgWrapper = styled.div`
  margin-right: 60px;
  display: inline-flex;
`;

const OrderDetailsBody = styled.div`
  background: ${colors.white};
  margin-top: 44px;
  display: flex;
  max-height: 400px;
`;

const OrderDetailsItems = styled.div`

  padding-left: 30px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 3px;
    background-color: ${colors.blue};
    color: ${colors.blue};
  }
`;

const SingleProductRow = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 17px;
`;

const SingleInputRow = styled.div`
  width: ${(props) => `${props.width}%`};
  margin-right: ${(props) => `${props.marginRight}px`};
  margin-top: ${(props) => `${props.marginTop}px`};
  position: relative;
`;

const AddProductLabel = styled.span`
  margin-bottom: 30px;
  font-size: ${(props) => `${props.fontSize}px`};
  line-height: 22px;
  color: ${colors.blue};

  :hover {
    cursor: ${(props) => `${props.cursor}`};
  }
`;

const TotalLabelWrapper = styled.div`
  width: auto;
  text-align: right;
  margin-right: 50px;
  margin-bottom: 20px;
`;

const TotalLabel = styled.label`
  color: ${(props) => (props.textColor ? props.textColor : colors.darkGrey)};
  padding-right: 10px;
`;

const AddOrderFooter = styled.div`
  margin: 25px 30px;
  display: flex;
  text-align: right;
  flex-flow: row-reverse;
`;

const ErrorWrapper = styled.div`
  background: rgba(242, 69, 61, 0.2);
  color: #f2453d;
  margin-bottom: 10px;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  align-items: center;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 1px 5px;
  display: ${(props) => (props.show === 'ERROR' ? 'flex' : 'none')};
  width: 90%;
  margin-left: 30px;
`;

const WarningWrapper = styled.div`
  width: 100%;
  display: flex;
  background: rgba(255, 152, 0, 0.2);
  color: #ff9800;
  margin-bottom: 10px;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  align-items: center;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 1px 5px;
  margin-top: 10px;
`;

const RemoveProductWrapper = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${hexToRGBA(colors.red, 0.1)};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  transition: ${transition};

  :hover {
    background-color: ${hexToRGBA(colors.red, 0.3)};
  }
`;
const { useState } = React;

const AddOrderPopup = (props) => {
  const [locations, setLocations] = useState([]);
  const [customAddress, setCustomAddress] = useState(true);
  const [postStatus, setPostStatus] = useState('');
  const [postError, setPostError] = useState('');
  const [popupKey, setPopupKey] = useState(Math.random());
  const { toggle, google, geoRegions } = props;

  const changePostStatus = (resetForm) => (status, data, error) => {
    setPostStatus(status);
    setPostError(error);
    if (status === 'OK') {
      resetForm();
      setPopupKey(Math.random());
      toggle();
    }
  };

  return (
    <Formik
      initialValues={{
        id: 0,
        clientName: '',
        clientLastName: '',
        clientId: '',
        clientPhone: '',
        clientEmail: '',
        orderLocation: '',
        orderLocationCoords: null,
        deliveryFee: 0,
        orderProducts: [
          {
            item: '',
            price: '',
            qty: '',
            productId: '',
          },
        ],
        address: '',
      }}
      validationSchema={Yup.object().shape({
        clientName: Yup.string().required('First Name is required'),
        clientLastName: Yup.string().required('Last Name is required'),
        clientPhone: Yup.number().required('Phone number is required'),
        clientEmail: Yup.string().email('Email is invalid').nullable(),
        address: Yup.string().required('Order address location is required'),
      })}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        const newValues = { ...values };
        newValues.orderProducts.forEach((product) => delete product.item);
        newValues.orderLocation = newValues.address;
        delete newValues.address;

        props.add(crudTypes.order, newValues, (status, data, error) => {
          if (!newValues.clientId) {
            props.getAll(crudTypes.client);
          }
          changePostStatus(resetForm)(status, data, error);
        });
      }}
    >
      {(innerProps) => {
        const { values, touched, errors, handleChange, handleSubmit, setFieldValue } = innerProps;

        const getLocationZone = (location) => {
          const pt = new google.maps.LatLng(
            location?.locationCoords?.lat,
            location?.locationCoords?.lng,
          );
          return geoRegions?.find((z) =>
            google.maps.geometry.poly.containsLocation(
              pt,
              new google.maps.Polygon({ paths: z.restrictedArea }),
            ),
          );
        };

        const handleLocationChange = (location) => {
          setFieldValue('address', location.locationName);
          setFieldValue('orderLocationCoords', location.locationCoords);
          const zone = getLocationZone(location);
          if (zone && zone.deliveryFee) {
            setFieldValue('deliveryFee', zone?.deliveryFee ?? 0);
          }
          setCustomAddress(false);
        };

        const handleClientAutocomplete = (privateValue) => {
          if (privateValue === null) return;
          values.clientId = privateValue.id;
          values.fullName = `${privateValue.name} ${privateValue.surname}`;

          const location = privateValue.locations?.[0];
          setFieldValue('clientName', privateValue.name);
          setFieldValue('clientLastName', privateValue.surname);
          setFieldValue('clientPhone', privateValue.phone || null);
          setFieldValue('clientEmail', privateValue.email || null);

          if (location) {
            handleLocationChange(location);
          } else {
            setFieldValue('address', '');
            setFieldValue('orderLocationCoords', null);
            setFieldValue('deliveryFee', 0);
            setCustomAddress(true);
          }

          setLocations([...privateValue.locations]);
        };

        const total =
          _.sumBy(values.orderProducts, (op) => +(op.qty ?? 1) * +(op.price ?? 0)) +
          values.deliveryFee;
        return (
          <div>
            <Modal key={popupKey} toggle={props.toggle} show={props.show}>
              <AddOrderWrapper>
                <ClientDetailsWrapper>
                  <AddOrderLabel> Client Details</AddOrderLabel>
                  <ClientDetailsBodyWrapper>
                    <AddItemPopup.InputRow style={{ position: 'relative' }}>
                      <Autocomplete
                        index={0}
                        id={'clientName'}
                        name={'clientName'}
                        onChange={handleChange}
                        value={values.clientName}
                        labelText={'First Name'}
                        suggestions={props.clients.data}
                        suggestionField={'fullName'}
                        finalValue={handleClientAutocomplete}
                        labelField='name'
                      />
                      {errors.clientName && touched.clientName ? (
                        <WarningWrapper>
                          <Icons.WarningIcon
                            fill={colors.yellow}
                            style={{ marginRight: 10 }}
                            width={16}
                          />
                          {errors.clientName}
                        </WarningWrapper>
                      ) : null}
                    </AddItemPopup.InputRow>
                    <AddItemPopup.InputRow style={{ position: 'relative' }}>
                      <Autocomplete
                        index={0}
                        id={'clientLastName'}
                        name={'clientLastName'}
                        onChange={handleChange}
                        value={values.clientLastName}
                        labelText={'Last Name'}
                        suggestions={props.clients.data}
                        suggestionField={'fullName'}
                        finalValue={handleClientAutocomplete}
                        labelField='surname'
                      />
                      {errors.clientName && touched.clientLastName ? (
                        <WarningWrapper>
                          <Icons.WarningIcon
                            fill={colors.yellow}
                            style={{ marginRight: 10 }}
                            width={16}
                          />
                          {errors.clientLastName}
                        </WarningWrapper>
                      ) : null}
                    </AddItemPopup.InputRow>
                    <AddItemPopup.InputRow>
                      <Input
                        labelText={'Phone'}
                        onChange={handleChange}
                        value={values.clientPhone ?? ''}
                        id={'clientPhone'}
                        autoComplete='off'
                      />
                      {errors.clientPhone && touched.clientPhone ? (
                        <WarningWrapper>
                          {' '}
                          <Icons.WarningIcon
                            fill={colors.yellow}
                            style={{ marginRight: 10 }}
                            width={16}
                          />{' '}
                          {errors.clientPhone}
                        </WarningWrapper>
                      ) : null}
                    </AddItemPopup.InputRow>
                    <AddItemPopup.InputRow>
                      <Input
                        labelText={'Email'}
                        onChange={handleChange}
                        value={values.clientEmail ?? ''}
                        id={'clientEmail'}
                        autoComplete='off'
                      />
                      {errors.clientEmail && touched.clientEmail ? (
                        <WarningWrapper>
                          {' '}
                          <Icons.WarningIcon
                            fill={colors.yellow}
                            style={{ marginRight: 10 }}
                            width={16}
                          />{' '}
                          {errors.clientEmail}
                        </WarningWrapper>
                      ) : null}
                    </AddItemPopup.InputRow>
                    <RadioButtonText>Address History</RadioButtonText>
                    {locations.slice(0, MAX_SHOWN_ADDRESSES).map((location) => {
                      return (
                        <Item key={location.locationName}>
                          <RadioButton
                            type={'radio'}
                            name={'radio'}
                            value={location.locationName}
                            checked={values.address === location.locationName}
                            onChange={() => {
                              handleLocationChange(location);
                            }}
                          />
                          <RadioButtonLabel />
                          <RadioButtonText>{location.locationName}</RadioButtonText>
                        </Item>
                      );
                    })}
                    <Item>
                      <RadioButton
                        id={'checkbox1'}
                        type={'radio'}
                        name={'radio'}
                        value={'Custom'}
                        checked={customAddress === true}
                        onChange={() => {
                          setFieldValue('address', '');
                          setFieldValue('orderLocationCoords', null);
                          setFieldValue('deliveryFee', 0);
                          setCustomAddress(true);
                        }}
                      />
                      <RadioButtonLabel />
                      <RadioButtonText>Custom</RadioButtonText>
                    </Item>
                  </ClientDetailsBodyWrapper>
                </ClientDetailsWrapper>
                <OrderDetailsWrapper>
                  <OrderDetailsHeaderWrapper>
                    <AddOrderLabel> Order Details</AddOrderLabel>
                    <ImgWrapper>
                      <img src={addOrderPopup} alt={'stripe'} />
                    </ImgWrapper>
                  </OrderDetailsHeaderWrapper>
                  <ErrorWrapper show={postStatus}>
                    <Icons.XIcon fill={colors.red} style={{ marginRight: 10 }} />
                    {postError instanceof String && postError.length > 0 ? (
                      <span>Error adding the order: {postError}</span>
                    ) : (
                      <span>Error adding the order</span>
                    )}
                  </ErrorWrapper>
                  <OrderDetailsBody>
                    <OrderDetailsItems>
                      <FieldArray name={'orderProducts'}>
                        {(arrayHelpers) => (
                          <div>
                            {values.orderProducts.map((orderItem, index) => (
                              <div key={index}>
                                <SingleProductRow>
                                  <SingleInputRow width={100} marginRight={20}>
                                    <Autocomplete
                                      index={index}
                                      name={`orderProducts[${index}].item`}
                                      finalValue={(privateValue) => {
                                        if (privateValue === null) return;
                                        const { name, price, id } = privateValue;
                                        arrayHelpers.replace(index, {
                                          item: name,
                                          price,
                                          productId: id,
                                          id: 0,
                                          qty: 1,
                                        });
                                      }}
                                      suggestions={props.products.data}
                                      selected={orderItem}
                                      labelText={'Item'}
                                      labelField={'item'}
                                    />
                                  </SingleInputRow>
                                  <SingleInputRow width={20} marginRight={29}>
                                    <Input
                                      name={`orderProducts[${index}].qty`}
                                      onChange={(e) => {
                                        const val = Number(e.target.value.replace('-', ''));
                                        if (isNaN(val) || val === 0) return;
                                        setFieldValue(`orderProducts[${index}].qty`, val);
                                      }}
                                      value={orderItem.qty}
                                      labelText={index === 0 && 'Quantity'}
                                      type={'number'}
                                      min={1}
                                    />
                                  </SingleInputRow>
                                  <SingleInputRow width={20} marginRight={29}>
                                    <Input
                                      name={`orderProducts[${index}].price`}
                                      value={orderItem.price}
                                      labelText={index === 0 && 'Price'}
                                      type={'text'}
                                      enableReinitialize='true'
                                      readOnly={true}
                                    />
                                  </SingleInputRow>
                                  <SingleInputRow marginTop={20} marginRight={20}>
                                    <RemoveProductWrapper
                                      onClick={() => {
                                        arrayHelpers.remove(index);
                                      }}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      <Icons.Cancel
                                        width={'10px'}
                                        height={'10px'}
                                        fill={colors.red}
                                      />
                                    </RemoveProductWrapper>
                                  </SingleInputRow>
                                </SingleProductRow>
                              </div>
                            ))}
                            <AddProductLabel
                              fontSize={12}
                              cursor={'pointer'}
                              onClick={() =>
                                arrayHelpers.push({
                                  item: '',
                                  price: '',
                                  qty: '',
                                  orderId: 0,
                                  id: 0,
                                })
                              }
                            >
                              + Add Product
                            </AddProductLabel>
                            <SingleProductRow>
                              <SingleInputRow width={100} marginRight={20} />
                              <SingleInputRow width={20} marginRight={29} />
                              <SingleInputRow width={20} marginRight={29}>
                                <Input
                                  name={'deliveryFee'}
                                  value={values.deliveryFee}
                                  onChange={(e) => {
                                    const val = Number(e.target.value.replace('-', ''));
                                    if (isNaN(val) || val === 0) return;
                                    setFieldValue('deliveryFee', val);
                                  }}
                                  labelText={'Delivery Fee'}
                                  type={'number'}
                                  enableReinitialize='true'
                                />
                              </SingleInputRow>
                              <SingleInputRow marginTop={20} marginRight={20}>
                                <RemoveProductWrapper style={{ visibility: 'hidden' }}>
                                  <Icons.Cancel width={'10px'} height={'10px'} fill={colors.red} />
                                </RemoveProductWrapper>
                              </SingleInputRow>
                            </SingleProductRow>
                            <TotalLabelWrapper>
                              <TotalLabel>Tot.</TotalLabel>
                              <TotalLabel textColor={colors.blue}>
                                {currencyFormatter(total)}
                              </TotalLabel>
                            </TotalLabelWrapper>
                          </div>
                        )}
                      </FieldArray>
                    </OrderDetailsItems>
                  </OrderDetailsBody>
                  <div style={{ width: '92%', paddingBottom: 20, paddingLeft: 30 }}>
                    {customAddress ? (
                      <AddItemPopup.InputRow>
                        <Input
                          labelText={'Address'}
                          onChange={customAddress ? handleChange : null}
                          id={'address'}
                          name='address'
                          disabled={!customAddress}
                          autoComplete='off'
                        />
                        {errors.address && touched.address ? (
                          <WarningWrapper>
                            {' '}
                            <Icons.WarningIcon
                              fill={colors.yellow}
                              style={{ marginRight: 10 }}
                              width={16}
                            />{' '}
                            {errors.address}
                          </WarningWrapper>
                        ) : null}
                      </AddItemPopup.InputRow>
                    ) : null}
                    <AddItemPopup.InputRow>
                      <Input
                        labelText={'Order Details'}
                        onChange={handleChange}
                        id={'description'}
                        autoComplete='off'
                      />
                    </AddItemPopup.InputRow>
                  </div>
                </OrderDetailsWrapper>
              </AddOrderWrapper>
              <AddOrderFooter>
                <Button.Button color={colors.blue} text={'Place Order'} onClick={handleSubmit} />
                <Button.WhiteButton
                  color={colors.textColor}
                  text={'Cancel'}
                  style={{ marginRight: 22 }}
                  onClick={() => {
                    props.toggle();
                  }}
                />
              </AddOrderFooter>
            </Modal>
          </div>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  products: state.products,
  clients: state.clients,
  geoRegions: state.facebookPages.activePage?.geoRegions,
});

const withRedux = connect(mapStateToProps, { add, getAll });
const withGoogleMaps = GoogleApiWrapper({
  apiKey: Config.map.googleMapsKey,
  libraries: ['places', 'geometry', 'drawing'],
});

export default compose(withRedux, withGoogleMaps)(AddOrderPopup);
