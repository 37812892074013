import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { toast } from 'react-toastify';
import React, { useState } from 'react';

// Libraries
import _ from 'lodash';
import { Flex, Box, Text } from '@chakra-ui/core';
import { Tabs } from 'antd';
import styled from 'styled-components';

// Assets
import productImage from 'assets/images/product_image.png';

// Helpers
import { CSVFileExporter } from 'app/shared/helpers/CSVFileExporter';
import currencyFormatter from 'app/shared/helpers/currencyFormatter';
import shared from 'app/shared';

// Config
import { filterData } from 'config/helpers/searcher';
import { getSortState, sortData } from 'config/helpers/sorter';
import { paginateNoOp } from 'config/helpers/paginator';

// API Calls
import { getAll, getAllV2, getCategoriesV2 } from 'actions/crudActions';
import { updateProductArrangement, searchCategories } from 'actions/categoryAction';
import crudTypes from 'config/crudTypes';

// Child Components
import AddProductCard from './addProductCard';
import AddProductPopup from './addProductPopup';
import EditProduct from './editProduct';
import ProductCard from './productCard';
import ProductCsvMapper from './productCsvMapper';

import AddCategoryCard from '../../categories/views/addCategoryCard';
import AddCategoryPopup from '../../categories/views/addCategoryPopup';
import SearchInput from 'app/shared/views/searchInput';

const { TabPane } = Tabs;

const {
  views: {
    Table,
    Button,
    Paginator,
    Page,
    Toggle,
    Card,
    FilterableTableStructure,
    Grid,
    UploadCSVInput,
    Text: {
      H4,
      Link: { Link },
    },
  },
  helpers: { resetScroll, colors, transition },
} = shared;

const {
  ActionBarWrapper,
  PaginatorWrapper,
  SearchWrapper,
  StatsSectionWrapper,
  TableWrapper,
  ToggleWrapper,
} = FilterableTableStructure;

const GridWrapper = styled.div`
  padding-top: 30px;
`;

const cardWrapper = (Element) => styled(Element)`
  transition: ${transition};
  transform: translateY(0);

  :hover {
    cursor: pointer;
    transform: translateY(-5px);
  }
`;

const TableWrapperTablet = styled(Card)`
  display: none;
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGrey};
  border-radius: 3px;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: block;
    margin-top: 25px;
  }
  @media all and (max-width: 768px) {
    display: block;
    margin-top: 25px;
  }
`;

const ButtonWrapper = styled.div`
  @media all and (max-width: 768px) {
    display: none;
  }
`;

const CategorySectionTitle = styled(H4)`
  color: ${colors.blue};
  height: 22px;
  line-height: 22px;
`;

const CategorySectionWrapper = styled.div`
  border-bottom: 2px solid ${colors.blue};
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

const StatusInActive = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 58px;
  color: #2ecc71;
`;
const StatusActive = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 58px;
  color: #ff3a44;
`;

const Ellipsis = styled.div`
  display: block;
  display: -webkit-box;
  margin: 0 auto;
  font-size: 12px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// State
const initialState = {
  filters: {
    selectedPeriod: 'today',
    selectedPaymentStatus: 'all',
    selectedOrderSource: 'all',
  },
  customDate: { startDate: null, endDate: null },
  sort: {
    key: 'id',
    order: 1,
  },
  currentPage: 1,
  extraCatCurrentPage: 1,
  pageSize: 10,
  toggleValue: true,
  search: '',
  redirect: false,
  getProductStatus: '',
  selectedCategoryType: '0',
  items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 100],
  uploadCsvKey: _.uniqueId(),
  filter: {
    filterCondition: null,
    filterParameters: [],
  },
  pageNumber: 1,
  sorting: [
    {
      columnName: 'categoryType',
      direction: 'asc',
    },
  ],
};

const CategorySection = ({ title, ...rest }) => (
  <CategorySectionWrapper {...rest}>
    <CategorySectionTitle>{title}</CategorySectionTitle>
  </CategorySectionWrapper>
);

const ProductCardWrapper = cardWrapper(ProductCard);
const AddProductCardWrapper = cardWrapper(AddProductCard);

const AddCategoryCardWrapper = cardWrapper(AddCategoryCard);

const NoCategories = () => {
  const [showAddCategoryPopup, setShowAddCategoryPopup] = useState(false);

  const toggleAddCategoryPopup = () => {
    setShowAddCategoryPopup(!showAddCategoryPopup);
  };

  return (
    <Flex p={50} as={Card}>
      <AddCategoryPopup show={showAddCategoryPopup} toggle={toggleAddCategoryPopup} />
      <Box width={1 / 2}>
        <Text fontFamily="Inter" fontSize={24} mb={20} color={colors.blue} fontWeight={600}>
          You haven't created a category yet.
        </Text>
        <Text fontFamily="Inter" fontSize={12} mb={20} fontWeight={600}>
          In order to add all your products, you must first create all your categories.
        </Text>
        <Link onClick={toggleAddCategoryPopup}>Create Category</Link>
      </Box>
      <Box>
        <Box as={AddCategoryCardWrapper} onClick={toggleAddCategoryPopup} minW={200} />
      </Box>
    </Flex>
  );
};

const SortableGrid = sortableContainer(({ children }) => {
  return <Grid>{children}</Grid>;
});

SortableGrid.Section = sortableElement(({ children, sizes }) => {
  return <Grid.Section sizes={sizes}>{children}</Grid.Section>;
});

class ProductsPage extends React.Component {
  state = initialState;
  pageId;

  componentWillMount() {
    resetScroll();
    this.loadData();
    this.props.getAll(crudTypes.category);
  }

  setGetProductsStatus = (status) => {
    this.setState({ getProductsStatus: status });
  };

  loadData = () => {
    this.pageId = this.props.facebookPages.activePage.id;
    this.props.getCategoriesV2(crudTypes.category, this.pageId, this.state);
  };

  updateSortState = (key) => {
    this.setState((prevState) => ({
      sort: getSortState(prevState.sort, key),
    }));
  };

  getTableData = (values = []) => ({
    action: this.updateSortState,
    header: [
      {
        label: 'NAME',
        key: 'name',
        sortable: true,
        renderer: ({ name }) => <Ellipsis>{name}</Ellipsis>,
      },
      {
        label: 'PRICE',
        key: 'price',
        renderer: ({ price }) => `${currencyFormatter(price)}`,
        sortable: true,
      },
      {
        label: 'CATEGORY',
        key: 'categoryName',
        renderer: ({ categoryName }) => categoryName,
        sortable: true,
      },
      {
        label: 'STATUS',
        key: 'disabled',
        sortable: true,
        renderer: ({ disabled }) =>
          disabled ? (
            <StatusActive>Inactive</StatusActive>
          ) : (
            <StatusInActive>Active</StatusInActive>
          ),
      },
      {
        label: 'DESCRIPTION',
        key: 'description',
        renderer: ({ description }) => <Ellipsis>{description}</Ellipsis>,
        sortable: true,
      },
      {
        label: 'ACTION',
        width: '70px',
        key: 'id',
        sortable: true,
        renderer: ({ id }) => {
          return (
            <NavLink to={`${EditProduct.path}/${id}`}>
              <Button.Button
                style={{ fontWeight: 600 }}
                text={'View'}
                size={Button.ButtonSize.medium}
                color={colors.blue}
              />
            </NavLink>
          );
        },
      },
    ],
    values: values,
  });

  getTableDataExtraProduct = (values = []) => ({
    action: this.updateSortState,
    header: [
      {
        label: 'NAME',
        key: 'name',
        sortable: true,
        renderer: ({ name }) => name,
      },
      {
        label: 'PRICE',
        key: 'price',
        renderer: ({ price }) => `${currencyFormatter(price)}`,
        sortable: true,
      },
      {
        label: 'CATEGORY',
        key: 'categoryName',
        renderer: ({ categoryName }) => categoryName,
        sortable: true,
      },
      {
        label: 'STATUS',
        key: 'disabled',
        sortable: true,
        renderer: ({ disabled }) =>
          disabled ? (
            <StatusActive>Inactive</StatusActive>
          ) : (
            <StatusInActive>Active</StatusInActive>
          ),
      },
      {
        label: 'ACTION',
        width: '70px',
        key: 'id',
        sortable: true,
        renderer: ({ id }) => {
          return (
            <NavLink to={`${EditProduct.path}/${id}`}>
              <Button.Button
                style={{ fontWeight: 600 }}
                text={'View'}
                size={Button.ButtonSize.medium}
                color={colors.blue}
              />
            </NavLink>
          );
        },
      },
    ],
    values: values,
  });

  getTableDataTablet = (values = []) => ({
    action: this.updateSortState,
    header: [
      {
        label: 'NAME',
        key: 'name',
        sortable: true,
        renderer: ({ name }) => name,
      },
      {
        label: 'PRICE',
        key: 'price',
        renderer: ({ price }) => `${currencyFormatter(price)}`,
        sortable: true,
      },
      {
        label: 'ACTION',
        width: '70px',
        key: 'id',
        sortable: true,
        renderer: ({ id }) => {
          return (
            <NavLink to={`${EditProduct.path}/${id}`}>
              <Button.Button
                style={{ fontWeight: 600 }}
                text={'View'}
                size={Button.ButtonSize.medium}
                color={colors.blue}
              />
            </NavLink>
          );
        },
      },
    ],
    values: values,
  });

  toggleAddPopup = () => this.setState({ showEditPopup: !this.state.showEditPopup });

  updatePaginatorPage = (currentPage) => {
    this.setState({ currentPage }, () => {
      this.props.getCategoriesV2(crudTypes.category, this.pageId, {
        ...this.state,
        pageNumber: currentPage,
      });
    });
  };

  updateExtraPaginatorPage = (extraCatCurrentPage) => {
    this.setState({ extraCatCurrentPage }, () => {
      this.props.getCategoriesV2(crudTypes.category, this.pageId, {
        ...this.state,
        pageNumber: extraCatCurrentPage,
      });
    });
  };

  setSearchValue = (val) => {
    this.setState({ search: val, currentPage: 1, extraCatCurrentPage: 1 });
  };

  updateSelectedCategoryType = (selectedCategoryType) => {
    this.setState({ selectedCategoryType });

    if (selectedCategoryType === '1') {
      this.setState(
        Object.assign(this.state.filter, {
          filterCondition: 'categoryType==1',
          filterParameters: [true],
        }),
      );
      this.loadData();
    } else {
      this.setState(
        Object.assign(this.state.filter, {
          filterCondition: 'categoryType==0',
          filterParameters: [true],
        }),
      );
      this.loadData();
    }
  };

  toggle = () => this.setState({ toggleValue: !this.state.toggleValue });

  createExportCSV = (data) => {
    return () => {
      CSVFileExporter('products.csv', ProductCsvMapper(data));
    };
  };

  onSortEnd = ({ oldIndex, newIndex, collection: categoryId }) => {
    this.props.updateProductArrangement({
      categoryId,
      oldIndex,
      newIndex,
    });
  };

  render() {
    const {
      state: {
        currentPage,
        extraCatCurrentPage,
        pageSize,
        toggleValue,
        sort,
        search,
        showEditPopup,
        preselectCategory,
        selectedCategoryType,
        uploadCsvKey,
      },
      updatePaginatorPage,
      updateExtraPaginatorPage,
      updateSelectedCategoryType,
      toggle,
      toggleAddPopup,
      createExportCSV,
    } = this;

    const data = [];
    let categoryTypes = {};

    /* eslint-disable no-unused-expressions */
    this.props.categories.data.map((d) => {
      d.magProducts && d.magProducts.length > 0 ? data.push(...d.magProducts) : [];
      categoryTypes[d.id] = d.categoryType;
      return null;
    });

    const noGridView =
      this.props.categories.data.length > 10000 || this.props.categories.data.length > 1000;

    const searchData = filterData(data, search);
    const sortedData = sortData(searchData, sort);

    const mainCategoryData = sortedData.filter((x) => categoryTypes[x.categoryId] === 1);
    const mainCategoryPaginatedData = paginateNoOp(mainCategoryData, currentPage, pageSize);

    const extraCategoryData = sortedData.filter((x) => categoryTypes[x.categoryId] === 0);
    const extraCategoryPaginatedData = paginateNoOp(
      extraCategoryData,
      extraCatCurrentPage,
      pageSize,
    );

    let groupedByMainCategory = [];

    if (!noGridView) {
      groupedByMainCategory = this.props.categories.data
        .filter((x) => x.categoryType === 1)
        .reduce((categories, category) => {
          return [
            ...categories,
            {
              id: category.id,
              name: category.name,
              show: _.isEmpty(search) || !_.isEmpty(category.magProducts), // don't show empty cats when searching
              products: category.magProducts,
            },
          ];
        }, []);
    }

    groupedByMainCategory.map((category) => {
      category.products.sort((p1, p2) =>
        p1.sortIndex > p2.sortIndex ? 1 : p1.sortIndex < p2.sortIndex ? -1 : 0,
      );
      return 0;
    });

    let groupedByExtraCategory = [];
    if (!noGridView) {
      groupedByExtraCategory = this.props.categories.data
        .filter((x) => x.categoryType === 0)
        .reduce((categories, category) => {
          return [
            ...categories,
            {
              id: category.id,
              name: category.name,
              show: _.isEmpty(search) || !_.isEmpty(category.magProducts), // don't show empty cats when searching
              products: category.magProducts,
            },
          ];
        }, []);
    }
    return (
      <Page>
        <AddProductPopup
          show={showEditPopup}
          toggle={toggleAddPopup}
          preselectCategory={preselectCategory}
          categoryType={
            this.props.categories.data.find((cat) => cat.id === preselectCategory)?.categoryType
          }
        />
        <StatsSectionWrapper>
          <ActionBarWrapper>
            <ButtonWrapper>
              <Button.Button
                color={colors.blue}
                text={'Add New'}
                style={{ marginRight: 22 }}
                onClick={() => {
                  this.setState({
                    preselectCategory: null,
                  });
                  toggleAddPopup();
                }}
              />
              <Button.WhiteButton
                color={colors.textColor}
                text={'Export CSV'}
                onClick={createExportCSV(sortedData)}
                style={{ marginRight: 22 }}
              />
              <UploadCSVInput
                key={uploadCsvKey}
                done={(err, data) => {
                  if (!err) {
                    this.props.getAll(crudTypes.product);
                    this.props.getAll(crudTypes.category, () => toast.success('Import successful'));
                  } else {
                    toast.error(err.response.data);
                  }
                  this.setState({ uploadCsvKey: _.uniqueId() });
                }}
                height={33}
              />
            </ButtonWrapper>
            {!noGridView && (
              <ToggleWrapper>
                <Toggle
                  show={toggleValue}
                  toggle={toggle}
                  showLabel={'Grid'}
                  hiddenLabel={'List'}
                />
              </ToggleWrapper>
            )}
            <SearchWrapper>
              <SearchInput search={search} onSearchChanged={(x) => this.setSearchValue(x)} />
            </SearchWrapper>
          </ActionBarWrapper>
          {toggleValue === true && !noGridView ? (
            <GridWrapper style={{ paddingTop: 8 }}>
              <Tabs defaultActiveKey={selectedCategoryType} onChange={updateSelectedCategoryType}>
                <TabPane tab="Main Categories" key={'0'}>
                  {_.isEmpty(groupedByExtraCategory) && <NoCategories />}
                  {groupedByExtraCategory.map(
                    (category) =>
                      category.show && (
                        <div key={category.id}>
                          <CategorySection title={category.name} />
                          <SortableGrid axis={'xy'} onSortEnd={this.onSortEnd} distance={2}>
                            {category.products.map((product, index) => (
                              <SortableGrid.Section
                                disabled={!_.isEmpty(search)}
                                key={product.id}
                                value={product.id}
                                index={index}
                                collection={category.id}
                                sizes={{
                                  desktop: 2,
                                  tablet: 4,
                                }}
                              >
                                <NavLink to={`${EditProduct.path}/${product.id}`}>
                                  <ProductCardWrapper
                                    photo={product.imagePath || productImage}
                                    price={currencyFormatter(product.price)}
                                    sales={product.totalQuantity}
                                    title={product.name}
                                    disabled={product.disabled}
                                  />
                                </NavLink>
                              </SortableGrid.Section>
                            ))}
                            <Grid.Section
                              sizes={{
                                desktop: 2,
                                tablet: 4,
                              }}
                            >
                              <AddProductCardWrapper
                                onClick={() => {
                                  this.setState({
                                    preselectCategory: category.id,
                                  });
                                  toggleAddPopup();
                                }}
                              />
                            </Grid.Section>
                          </SortableGrid>
                        </div>
                      ),
                  )}
                  <PaginatorWrapper>
                    <Paginator
                      changePage={updatePaginatorPage}
                      numberOfPages={this.props.categories.current.totalPages || 1}
                      page={mainCategoryPaginatedData.currentPage}
                    />
                  </PaginatorWrapper>
                </TabPane>
                <TabPane tab="Extra Categories" key={'1'}>
                  {_.isEmpty(groupedByMainCategory) && <NoCategories />}
                  {groupedByMainCategory.map(
                    (category) =>
                      category.show && (
                        <div key={category.id}>
                          <CategorySection title={category.name} />
                          <SortableGrid axis={'xy'} onSortEnd={this.onSortEnd} distance={2}>
                            {category.products.map((product, index) => (
                              <SortableGrid.Section
                                disabled={!_.isEmpty(search)}
                                key={product.id}
                                value={product.id}
                                index={index}
                                collection={category.id}
                                sizes={{
                                  desktop: 2,
                                  tablet: 4,
                                }}
                              >
                                <NavLink to={`${EditProduct.path}/${product.id}`}>
                                  <ProductCardWrapper
                                    photo={product.imagePath || productImage}
                                    price={currencyFormatter(product.price)}
                                    sales={product.totalQuantity}
                                    title={product.name}
                                    disabled={product.disabled}
                                  />
                                </NavLink>
                              </SortableGrid.Section>
                            ))}
                            <Grid.Section
                              sizes={{
                                desktop: 2,
                                tablet: 4,
                              }}
                            >
                              <AddProductCardWrapper
                                onClick={() => {
                                  this.setState({
                                    preselectCategory: category.id,
                                  });
                                  toggleAddPopup();
                                }}
                              />
                            </Grid.Section>
                          </SortableGrid>
                        </div>
                      ),
                  )}
                  <PaginatorWrapper>
                    <Paginator
                      changePage={updatePaginatorPage}
                      numberOfPages={this.props.categories.current.totalPages || 1}
                      page={mainCategoryPaginatedData.currentPage}
                    />
                  </PaginatorWrapper>
                </TabPane>
              </Tabs>
            </GridWrapper>
          ) : (
            <div>
              <Tabs
                defaultActiveKey={selectedCategoryType}
                onChange={(key) => updateSelectedCategoryType(key)}
              >
                <TabPane tab="Main Categories" key={'0'}>
                  <TableWrapper>
                    <Table.TableView data={this.getTableData(mainCategoryPaginatedData.current)} />
                  </TableWrapper>
                  <TableWrapperTablet>
                    <Table.TableView
                      data={this.getTableDataTablet(mainCategoryPaginatedData.current)}
                    />
                  </TableWrapperTablet>
                  <PaginatorWrapper>
                    <Paginator
                      changePage={updatePaginatorPage}
                      numberOfPages={this.props.categories.current.totalPages || 1}
                      page={mainCategoryPaginatedData.currentPage}
                    />
                  </PaginatorWrapper>
                </TabPane>
                <TabPane tab="Extra Categories" key={'1'}>
                  <TableWrapper>
                    <Table.TableView
                      data={this.getTableDataExtraProduct(extraCategoryPaginatedData.current)}
                    />
                  </TableWrapper>
                  <TableWrapperTablet>
                    <Table.TableView
                      data={this.getTableDataTablet(extraCategoryPaginatedData.current)}
                    />
                  </TableWrapperTablet>
                  <PaginatorWrapper>
                    <Paginator
                      changePage={updateExtraPaginatorPage}
                      numberOfPages={this.props.categories.current.totalPages || 1}
                      page={mainCategoryPaginatedData.currentPage}
                    />
                  </PaginatorWrapper>
                </TabPane>
              </Tabs>
            </div>
          )}
        </StatsSectionWrapper>
      </Page>
    );
  }
}

ProductsPage.path = '/dashboard/products';

const mapStateToProps = (state) => ({
  facebookPages: state.facebookPages,
  categoriesV2: state.categoriesV2,
  categories: state.categories,
});

export default connect(mapStateToProps, {
  getAll,
  getAllV2,
  getCategoriesV2,
  updateProductArrangement,
  searchCategories,
})(ProductsPage);
export { ProductsPage };
