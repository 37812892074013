import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';
import shared from 'app/shared';
import StatCard from './statCard';
import currencyFormatter from 'app/shared/helpers/currencyFormatter';

const {
  views: { Icons, RadioGroup },
  helpers: { colors },
} = shared;

const StatsWrapper = styled.div`
  flex-direction: column;
  justify-content: space-around;
`;

const ChartWrapper = styled.div`
  width: 100%;
  height: 350px;
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGrey};
  border-radius: 3px;
  padding: 16px;
  flex-shrink: 1;
  padding-right: 10;
  flex-grow: 1;

  @media all and (max-width: 1024px) {
    width: 100%;
  }
  @media all and (max-width: 768px) {
    display: none;
  }
`;

const StatsCardWrapper = styled.div`
  display: block;
  margin-bottom: 20px;
  flex: 1;
`;

const StatsCardColumn = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  @media all and (max-width: 1024px) {
    flex-direction: column;
  }
`;

const ChartLabelWrapper = styled.div`
  padding-left: 47px;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
`;

const getOptions = () => {
  return {
    legend: {
      display: false,
      position: 'top',
      fontSize: 20,
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
            color: '#EBEDF4',
          },
          scaleLabel: {
            display: true,
          },
          ticks: {
            min: 0,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
          },
        },
      ],
    },
  };
};


function toGraphData(chartData) {
  return {
    labels: chartData.map(x => new Date(x.timestamp).toLocaleString()),
    data: chartData.map(o => {
      return {
        y:o.value,
        x:o.timestamp
      }
    })
  }
}

class StatsSection extends Component {
  state = {
    graphData: {},
    totalClients: 0,
    totalOrders: 0,
    totalRevenues: 0,
    totalDeliveryFees: 0,
  };

  componentDidMount() {}

  render() {
    const options = getOptions();
    const { totalRevenue, totalClients, totalOrders, selectedElement, updateSelectedElement, chartData } = this.props;
    const graphData = toGraphData(chartData)
    return (
      <StatsWrapper>
        <RadioGroup selectedElement={selectedElement} updateSelectedElement={updateSelectedElement}>
          <StatsCardColumn>
            <StatsCardWrapper>
              <RadioGroup.Element
                Element={StatCard}
                title={`${currencyFormatter(totalRevenue)}`}
                subTitle={'Revenue'}
                Icon={Icons.Revenue}
                value={'revenue'}
              />
            </StatsCardWrapper>
            <StatsCardWrapper>
              <RadioGroup.Element
                Element={StatCard}
                title={totalClients}
                subTitle={'Clients'}
                Icon={Icons.Clients}
                value={'clients'}
              />
            </StatsCardWrapper>
            <StatsCardWrapper>
              <RadioGroup.Element
                Element={StatCard}
                title={totalOrders}
                subTitle={'Orders'}
                Icon={Icons.Orders}
                value={'orders'}
              />
            </StatsCardWrapper>
          </StatsCardColumn>
          <ChartWrapper ref={this.ref}>
            <ChartLabelWrapper>
              {' '}
              {selectedElement.replace(/^\w/, (c) => c.toUpperCase())}{' '}
            </ChartLabelWrapper>
            <Line
              ref="chart"
              data={{
                labels: [...(graphData.labels || [])],
                datasets: [
                  {
                    label: `${selectedElement}`,
                    fill: false,
                    backgroundColor: 'rgba(0, 108, 203,1)',
                    borderColor: 'rgba(0, 108, 203,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    data: [...(graphData.data || [])],
                  },
                ],
              }}
              options={options}
            />
          </ChartWrapper>
        </RadioGroup>
      </StatsWrapper>
    );
  }
}

export default StatsSection;
