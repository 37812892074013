import React from 'react';
import styled from 'styled-components';
import shared from 'app/shared';
import {connect} from 'react-redux';
import {selectAllSms, getTotalLinkClicks} from 'reducers/campaignsReducer';

const {
  views: {
    Icons,
    Text: {
      Link: { Link }
    }
  },
  helpers: { colors }
} = shared;


const StatisticsBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
  height: 75px;
  background: ${colors.white};
  color: ${colors.textColor};
  border: 1px solid ${colors.lightGrey};
  box-sizing: border-box;
  border-radius: 3px;
  padding: 30px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
`

const MetricLabel = styled.span`
  margin-right: 10px;
`;

const MetricValue = styled.span`
  font-weight: 800;
  color: ${props => props.color || colors.blue};
`;

const MetricWrapper = styled.div`
  display: inline-flex;
  margin-right: 40px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  font-size: 14px;
`;

export const Metric = ({label, value, valueColor, style}) => (
  <MetricWrapper style={style}>
    <MetricLabel>{label}:</MetricLabel>
    <MetricValue color={valueColor}>{value}</MetricValue>
  </MetricWrapper>
)

const CreditInfoWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 80px;
`
const CreditInfo = () => (
  <CreditInfoWrapper>
    <Icons.Info width={'13px'} height={'13px'} fill={'#B8C5D3'} style={{marginRight: '10px'}} />
    <span>1 Credit = $0.01</span>
  </CreditInfoWrapper>
)

const StyledLink = styled(Link)`
  display: inline-flex;
  font-size: 12px;
`

const BuyCreditsLink = (props) => (
  <StyledLink {...props}>
    <Icons.Coin style={{marginRight: '10px'}} />
    Buy Credits
  </StyledLink>
)

const StatisticsBar = ({smsCredits, smsMessages, openBuyCreditsPopup, totalLinkClicks}) => {
  const smsSent = smsMessages.length;

  return (
    <StatisticsBarWrapper>
      <div>
        <Metric label={'Available Credits'} value={smsCredits} />
        <Metric label={'SMS Sent'} value={smsSent} />
        <Metric label={'Link Clicks'} value={totalLinkClicks} valueColor={colors.green} />
      </div>
      <div>
        <CreditInfo />
        <BuyCreditsLink onClick={openBuyCreditsPopup} />
      </div>
    </StatisticsBarWrapper>
  )
}

const mapStateToProps = state => ({
  smsCredits: state.facebookPages.activePage?.smsCredits ?? 0,
  smsMessages: selectAllSms(state),
  totalLinkClicks: getTotalLinkClicks(state),
});
export default connect(mapStateToProps, {})(StatisticsBar);
