import * as React from "react";
import Icon from '../../../../shared/views/icons';

const StarButton = (props) => {
    const { isActive, onClick } = props;
    return (
            <Icon.Star 
                fill={isActive ? '#FFD037' : '#BFCADB'} 
                onClick={onClick}
                name={"StarButton"}
            />
        )
}

export default StarButton;
