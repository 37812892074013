import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import { NavLink, withRouter, Prompt } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import shared from 'app/shared';
import Icon from '../../shared/views/icons';
import hexToRGBA from '../../shared/helpers/hexToRGBA';
import { edit, remove, getAll, getClientOrders } from '../../../actions/crudActions';
import crudTypes from '../../../config/crudTypes';
import order from '../../orders';
import { paginateNoOp } from '../../../config/helpers/paginator';
import { getSortState, sortData } from '../../../config/helpers/sorter';
import { CSVFileExporter } from 'app/shared/helpers/CSVFileExporter';
import ClientCsvMapper from './clientCsvMapper';
import currencyFormatter from 'app/shared/helpers/currencyFormatter';

import { ORDER_STATUS } from 'app/orders/constants';

const {
  views: { EditOrder },
} = order;

const {
  views: {
    Button,
    Input,
    InputArea,
    Table,
    AddItemPopup,
    FilterableTableStructure,
    Card,
    Icons,
    Paginator,
    Alert,
    Text: {
      Link: { Link },
    },
  },
  helpers: { colors },
} = shared;

const { TableWrapper, PaginatorWrapper } = FilterableTableStructure;

const EditClientHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EditClientTitle = styled.div`
  font-size: 20px;
  color: ${colors.black};
  margin-left: 10px;
  margin-right: 10px;
  @media all and (max-width: 768px) {
    display: none;
  }
`;

const EditClientTitleFooter = styled.div`
  font-size: 20px;
  color: ${colors.black};
  margin-left: 10px;
  margin-right: 10px;
`;

const EditClientDate = styled.div`
  font-size: 12px;
  color: ${colors.grey};
  margin: 10px;
  flex: 1;
  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }
  @media all and (max-width: 768px) {
    display: none;
  }
`;

const EditClientBodyWrapper = styled.div`
  background: ${colors.white};
  margin-top: 44px;
  display: flex;
  margin-bottom: 45px;
  border: 1px solid #e9eaf1;
  box-sizing: border-box;
  border-radius: 3px;
  flex-direction: column;
  padding: 10px;
`;

const EditClientBody = styled.div`
  background: ${colors.white};
  padding: 25px 30px;
  display: flex;
  @media all and (min-width: 768px) and (max-width: 1024px) {
    flex-direction: column;
  }
  @media all and (max-width: 768px) {
    flex-direction: column;
  }
`;

const EditClientDetail = styled.div`
  width: 30%;
  padding-right: 55px;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    padding-right: 0px;
    margin-right: 10px;
    width: 100%;
  }
  @media all and (max-width: 768px) {
    padding-right: 0px;
    margin-right: 10px;
    width: 100%;
  }
`;

const EditClientLabel = styled.div`
  margin-bottom: 30px;
  font-size: ${(props) => `${props.fontSize}px`};
  line-height: 22px;
  color: ${colors.blue};
`;
const ClientStatisticLabel = styled.div`
  width: 100%;
  height: 43px;
  text-align: center;
  font-size: 24px;
  line-height: 23px;
  background-color: ${(props) => hexToRGBA(props.color, 0.2)};
  border-radius: 3px;
  color: ${(props) => props.color};
  padding-top: 10px;
  border: 1px solid ${(props) => props.color};
`;

const Label = styled.label`
  color: ${colors.textColor};
  font-size: 12px;
  line-height: 23px;
  font-weight: 500;
`;

const LabelWrapper = styled.div`
  padding-left: 10px;
`;

const TableWrapperTablet = styled(Card)`
  display: none;
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGrey};
  border-radius: 3px;
  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: block;
    margin-top: 25px;
  }

  @media all and (max-width: 768px) {
    display: block;
    margin-top: 25px;
  }
`;

const WarningWrapper = styled.div`
  width: 100%;
  display: flex;
  background: rgba(255, 152, 0, 0.2);
  color: #ff9800;
  margin-bottom: 10px;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  align-items: center;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 1px 5px;
  margin-top: 10px;
`;

const ErrorWrapper = styled.div`
  width: 100%;
  background: rgba(242, 69, 61, 0.2);
  color: #f2453d;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  align-items: center;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 1px 5px;
  display: ${(props) => (props.show === 'ERROR' ? 'flex' : 'none')};
`;

const PaymentPaidWrapper = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 58px;
  color: #2ecc71;
`;

const PaymentPendingWrapper = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 58px;
  color: ${colors.orange};
`;

const PaymentCancelledWrapper = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 58px;
  color: #ff3a44;
`;

const EditClient = (props) => {
  const str = window.location.href;
  const id = parseInt(str.substring(str.lastIndexOf('/') + 1).trim());
  const filteredCategory = (props.clients && props.clients.data.filter((x) => x.id === id)) || [];
  const activeClient = filteredCategory.length > 0 ? filteredCategory[0] : null;
  const [updateStatus, setUpdateStatus] = useState('');
  const [deleteStatus] = useState('');
  const [sort, setSort] = useState({ key: 'orderDate', order: -1 });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(5);
  const { getAll, getAllClientOrders } = props;

  useEffect(() => {
    getAllClientOrders(id, currentPage);
    if (!activeClient) {
      getAll(crudTypes.client);
    }
  }, [getAll, activeClient, getAllClientOrders, id, currentPage]);

  const changeUpdateStatus = (status) => {
    setUpdateStatus(status);
  };

  // const changeDeleteStatus = (status)=>{
  //   setDeleteStatus(status);
  // }

  const getTableData = () => {
    // const data = props.orders.data.filter((order) => order.clientId === activeClient.id);
    const data = props.orders.data;
    const sortedData = sortData(data, sort);
    const paginatedData = paginateNoOp([...sortedData], currentPage, pageSize);

    const tableData = {
      header: [
        {
          label: 'BILL NO.',
          key: 'orderCounter',
          renderer: ({ orderCounter }) => orderCounter,
          sortable: false,
        },
        {
          label: 'SOURCE',
          key: 'source',
          renderer: ({ source, tableName }) => {
            const mapSource = {
              'online-order': 'Online',
              'table-order': 'Table',
              'mobile-order': 'Mobile',
              'internal-order': 'Internal',
              'marketplace-order': 'Marketplace',
              'pok-order': 'POK',
            };
            let sourceLabel = tableName || mapSource[source];
            return (
              <Button.Button
                text={sourceLabel}
                size={Button.ButtonSize.medium}
                style={{
                  backgroundColor: tableName ? '#2ECC71' : colors.blue,
                  bordeRadius: 3,
                  fontWeight: 'bold',
                  fontSize: '12px',
                  lineHeight: '14px',
                  textAlign: 'center',
                  color: colors.white,
                }}
              />
            );
          },
          sortable: true,
        },
        {
          label: 'SOURCE',
          key: 'orderStatus',
          sortable: true,
          renderer: ({ orderStatus }) =>
            orderStatus === 0 ? (
              <PaymentPendingWrapper>{ORDER_STATUS[orderStatus]}</PaymentPendingWrapper>
            ) : orderStatus === 1 ? (
              <PaymentPaidWrapper>{ORDER_STATUS[orderStatus]}</PaymentPaidWrapper>
            ) : (
              <PaymentCancelledWrapper>{ORDER_STATUS[orderStatus]}</PaymentCancelledWrapper>
            ),
        },
        {
          label: 'QUANTITY',
          key: 'orderProducts',
          renderer: ({ orderProducts }) => {
            const qty = orderProducts.reduce((qty, order) => qty + order.qty, 0);
            return (
              <Button.Button
                text={qty}
                size={Button.ButtonSize.medium}
                style={{
                  backgroundColor: colors.lightBlue,
                  fontWeight: 'bold',
                  fontSize: '12px',
                  lineHeight: '14px',
                  textAlign: 'center',
                  color: colors.blue,
                }}
              />
            );
          },
          sortable: true,
        },
        {
          label: 'VALUE',
          key: 'totalValue',
          renderer: ({ totalValue }) => `${currencyFormatter(totalValue)}`,
          sortable: true,
        },
        {
          label: 'DATE CREATED',
          key: 'orderDate',
          sortable: true,
          renderer: ({ orderDate }) => moment(orderDate).format('YYYY-MM-DD HH:mm'),
        },
        {
          label: 'ACTION',
          width: '70px',
          key: 'orderCounter',
          sortable: true,
          renderer: ({ orderCounter }) => {
            return (
              <NavLink to={`${EditOrder.path}/${orderCounter}`}>
                <Button.Button
                  style={{ fontWeight: 600 }}
                  text={'View'}
                  size={Button.ButtonSize.medium}
                  color={colors.blue}
                />
              </NavLink>
            );
          },
        },
      ],
      values: paginatedData.current,
      action: (key) => {
        setSort(getSortState(sort, key));
      },
      paginatedData,
    };

    return tableData;
  };

 const deleteProd = () => {
   props.remove(activeClient.id, crudTypes.client);
 };

  const exportCSV = () => {
    if (activeClient) {
      CSVFileExporter(`client-${activeClient.id}.csv`, ClientCsvMapper([activeClient]));
    }
  };

  const tableData = getTableData();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        clientName: (activeClient && activeClient.fullName) || '',
        phone: (activeClient && activeClient.phone) || '',
        email: (activeClient && activeClient.email) || '',
        location:
          (activeClient && activeClient.locations[0] && activeClient.locations[0].locationName) ||
          '',
        location1:
          (activeClient && activeClient.locations[1] && activeClient.locations[1].locationName) ||
          '',
        location2:
          (activeClient && activeClient.locations[2] && activeClient.locations[2].locationName) ||
          '',
        clientId: id,
      }}
      validationSchema={Yup.object().shape({
        clientName: Yup.string().required('First Name is required'),
        phone: Yup.number().required('Phone number is required'),
        email: Yup.string().email('Email is invalid'),
        location: Yup.string().required('Adress is required'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        let newValues = { ...activeClient };

        newValues.fullName = values.clientName;
        newValues.id = id;
        delete newValues.name;
        delete newValues.surname;
        newValues.email = values.email;
        newValues.phone = values.phone;

        let locations = [];
        let localLocations = [values.location, values.location1, values.location2];

        localLocations.forEach((loc, index) => {
          if (!loc) return;

          if (newValues.locations[index]) {
            let loca = newValues.locations[index];
            loca.locationName = loc;

            locations.push(loca);
          } else {
            locations.push({
              clientId: activeClient.id,
              locationId: 0,
              locationName: loc,
            });
          }
        });

        newValues.locations = locations;

        props.edit(newValues.id, crudTypes.client, newValues, changeUpdateStatus);

        setSubmitting(true);
      }}
    >
      {(innerProps) => {
        const { values, touched, errors, handleChange, handleSubmit } = innerProps;
        return (
          <div>
            <Prompt
              when={innerProps.dirty}
              message="You have unsaved changes. Are you sure you want to leave?"
            />
            <EditClientHeaderWrapper>
              <Link onClick={props.history.goBack}>
                <Icon.BackProducts fill={colors.blue} />
              </Link>
              <EditClientTitle>Edit Client</EditClientTitle>
              <EditClientDate>
                Date Created:{' '}
                {`${activeClient && format(new Date(activeClient.creationDate), 'MM-DD-YYYY')}`}
              </EditClientDate>
              <Button.WhiteButton
                color={colors.textColor}
                text={'Export CSV'}
                style={{ marginRight: 22 }}
                onClick={exportCSV}
              />
              <Button.Button color={colors.blue} text={'Save'} onClick={handleSubmit} />

              <Button.Button
                  color={colors.red}
                  text={'Delete'}
                  style={{ marginLeft: 22 }}
                  onClick={() => deleteProd()}
                />
            </EditClientHeaderWrapper>
            <EditClientBodyWrapper>
              <Alert show={updateStatus === 'OK'} message="Update successful!" />
              <ErrorWrapper show={updateStatus}>
                <Icons.XIcon fill={colors.red} style={{ marginRight: 10 }} width={16} />
                <span>Error editing the client</span>
              </ErrorWrapper>
              <ErrorWrapper show={deleteStatus}>
                <Icons.XIcon fill={colors.red} style={{ marginRight: 10 }} width={16} />
                <span>Error deleting the client</span>
              </ErrorWrapper>
              <EditClientBody>
                <EditClientDetail>
                  <EditClientLabel fontSize={16}>Client Details </EditClientLabel>
                  <AddItemPopup.InputRow>
                    <Input
                      labelText={'Full Name'}
                      value={values.clientName}
                      onChange={handleChange}
                      id={'clientName'}
                      autoComplete="off"
                    />
                    {errors.clientName && touched.clientName ? (
                      <WarningWrapper>
                        {' '}
                        <Icons.WarningIcon
                          fill={colors.yellow}
                          style={{ marginRight: 10 }}
                          width={16}
                        />{' '}
                        {errors.clientName}
                      </WarningWrapper>
                    ) : null}
                  </AddItemPopup.InputRow>
                  <AddItemPopup.InputRow>
                    <Input
                      labelText={'Phone'}
                      value={values.phone}
                      onChange={handleChange}
                      id={'phone'}
                      autoComplete="off"
                    />
                    {errors.phone && touched.phone ? (
                      <WarningWrapper>
                        {' '}
                        <Icons.WarningIcon
                          fill={colors.yellow}
                          style={{ marginRight: 10 }}
                          width={16}
                        />{' '}
                        {errors.phone}
                      </WarningWrapper>
                    ) : null}
                  </AddItemPopup.InputRow>
                  <AddItemPopup.InputRow>
                    <Input
                      labelText={'Email'}
                      value={values.email}
                      onChange={handleChange}
                      id={'email'}
                      autoComplete="off"
                    />
                    {errors.email && touched.email ? (
                      <WarningWrapper>
                        {' '}
                        <Icons.WarningIcon
                          fill={colors.yellow}
                          style={{ marginRight: 10 }}
                          width={16}
                        />{' '}
                        {errors.email}
                      </WarningWrapper>
                    ) : null}
                  </AddItemPopup.InputRow>
                </EditClientDetail>
                <EditClientDetail>
                  <EditClientLabel fontSize={16}>Location </EditClientLabel>
                  <AddItemPopup.InputRow>
                    <InputArea
                      labelText={'Address 1'}
                      value={values.location ? values.location : ''}
                      onChange={handleChange}
                      id={'location'}
                      autoComplete="off"
                    />
                    {errors.location && touched.location ? (
                      <WarningWrapper>
                        <Icons.WarningIcon
                          fill={colors.yellow}
                          style={{ marginRight: 10 }}
                          width={16}
                        />{' '}
                        {errors.location}
                      </WarningWrapper>
                    ) : null}
                  </AddItemPopup.InputRow>
                  <AddItemPopup.InputRow style={{ display: 'none' }}>
                    <Input
                      labelText={'Address 2'}
                      value={values.location1 ? values.location1 : ''}
                      onChange={handleChange}
                      id={'location1'}
                      autoComplete="off"
                    />
                  </AddItemPopup.InputRow>
                  <AddItemPopup.InputRow style={{ display: 'none' }}>
                    <Input
                      labelText={'Address 3'}
                      value={values.location2 ? values.location2 : ''}
                      onChange={handleChange}
                      id={'location2'}
                      autoComplete="off"
                    />
                  </AddItemPopup.InputRow>
                </EditClientDetail>
                <EditClientDetail>
                  <EditClientLabel fontSize={16}>Statistics</EditClientLabel>
                  <LabelWrapper>
                    <Label>Orders</Label>
                  </LabelWrapper>
                  <ClientStatisticLabel color={colors.blue}>
                    {(activeClient && activeClient.orderCount) || '0'}
                  </ClientStatisticLabel>
                  <LabelWrapper>
                    <Label>Revenue</Label>
                  </LabelWrapper>
                  <ClientStatisticLabel color={colors.blue}>
                    {currencyFormatter(activeClient?.totalSum ?? 0)}
                  </ClientStatisticLabel>
                </EditClientDetail>
              </EditClientBody>
            </EditClientBodyWrapper>
            <EditClientHeaderWrapper style={{ paddingBottom: 20 }}>
              <EditClientTitleFooter> Order History </EditClientTitleFooter>
            </EditClientHeaderWrapper>
            <TableWrapper>
              <Table.TableView data={tableData} />
            </TableWrapper>
            <TableWrapperTablet>
              <Table.TableView data={tableData} />
            </TableWrapperTablet>
            <PaginatorWrapper>
              <Paginator
                changePage={setCurrentPage}
                numberOfPages={props.orders.current.totalPages}
                page={currentPage}
              />
            </PaginatorWrapper>
          </div>
        );
      }}
    </Formik>
  );
};

EditClient.path = '/dashboard/client/editclient';

const mapStateToProps = (state) => ({
  clients: state.clients,
  orders: state.orders,
});

export default withRouter(
  connect(mapStateToProps, { edit, remove, getAll, getAllClientOrders: getClientOrders })(
    EditClient,
  ),
);

export { EditClient };
