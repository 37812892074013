import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Wizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: props.initialStep,
      classes: {},
    };

    if (typeof props.getRef === 'function') {
      props.getRef(this);
    }
  }

  onStepChange = (stepChanged) => {
    // on step change
    if (this.props.onStepChange) {
      this.props.onStepChange(stepChanged);
    }
  }

  onFinish = () => {
    if (this.props.onFinish) {
      this.props.onFinish();
    }
  }

  get activeStep() {
    return this.state.activeStep;
  }

  set activeStep(value) {
    const { activeStep: currentActive } = this.state;
    const { totalSteps } = this.props;

    if (currentActive === value) return;
    if (value < 1) return;
    if (value > totalSteps) {
      this.onFinish();
    }

    this.setState({
      activeStep: value,
    }, () => {
      this.onStepChange({
        previousStep: currentActive,
        nextStep: value,
        totalSteps,
      });
    });
  }

  nextStep = () => {
    this.activeStep = this.state.activeStep + 1;
  }

  prevStep = () => {
    this.activeStep = this.activeStep - 1;
  }

  render() {
    const { totalSteps } = this.props;

    if (!this.activeStep) {
      return null;
    }

    return (
      <div className={this.props.className}>
        {
          this.props.children({
            totalSteps,
            activeStep: this.activeStep,
            nextStep: this.nextStep,
            prevStep: this.prevStep,
            setActive: (step) => this.activeStep = step,
          })
        }
      </div>
    );
  }
}

Wizard.propTypes = {
  children: PropTypes.func,
  initialStep: PropTypes.number,
  totalSteps: PropTypes.number.isRequired,
  onStepChange: PropTypes.func,
  onFinish: PropTypes.func,
  className: PropTypes.string,
  getRef: PropTypes.func,
};

Wizard.defaultProps = {
  initialStep: 1,
  className: '',
};

// const getHash = () => {
//   return decodeURI(window.location.hash).replace(/^#/, '');
// };

// const setHash = (hash) => {
//   window.location.hash = hash;
// };

export default Wizard;
