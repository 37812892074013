import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import shared from 'app/shared';
import Select from 'react-select';
import SettingsTitle from '../components/SettingsTitle';
import { getNotificationSettings, updateNotificationSettings } from 'actions/pageActions';
import CheckBox from 'app/shared/views/checkbox';
import { scrollToAlert } from 'app/shared/views/alert';
import { setPageConfiguration } from 'actions/pageActions';

const {
  views: { Alert, Spinner },
  helpers: { colors },
} = shared;

const InlineFlexWrapper = styled.div`
  display: inline-flex;
  margin-top: 3%;
  width: 60%;
`;

const MainLabel = styled.div`
  display: flex;
  width: 35%;
  height: 100%;
  padding-right: 30px;
  font-size: 15px;
  color: ${colors.textColor};
`;

const InLineLabel = styled.span`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  color: #748aa1;
`;

const PrioritySelect = styled(Select)`
  font-family: Inter;
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;

  & .priority__control {
    border-color: #e1e6ec;
  }

  & .priority__single-value {
    color: #434343;
    font-weight: normal;
  }
`;

const priorityOptions = [
  { value: 0, label: 'Normal' },
  { value: 1, label: 'High' },
];

const getPriorityOption = (priority) =>
  priorityOptions.find((o) => o.value === priority) ?? priorityOptions[0];

const NotificationSettingsSection = ({ label, sectionSettings, setSectionSettings }) => (
  <InlineFlexWrapper>
    <MainLabel>{label}</MainLabel>
    <div style={{ width: '65%', marginTop: '-2px' }}>
      <div style={{ width: '100%', marginBottom: '10px' }}>
        <div>
          <CheckBox
            text={'Enabled'}
            checked={sectionSettings.enabled}
            onChange={(e) =>
              setSectionSettings({
                ...sectionSettings,
                enabled: e.target.checked,
              })
            }
          />
        </div>
        <InLineLabel>Priority</InLineLabel>
        <PrioritySelect
          classNamePrefix="priority"
          className="selectWrapper"
          labelText="Priority"
          value={getPriorityOption(sectionSettings.priority)}
          isSearchable={false}
          isDisabled={!sectionSettings.enabled}
          onChange={(selectedOption) => {
            setSectionSettings({
              ...sectionSettings,
              priority: selectedOption.value,
            });
          }}
          options={priorityOptions}
        />
      </div>
    </div>
  </InlineFlexWrapper>
);

const NotificationSettings = ({
  activePage,
  getNotificationSettings,
  updateNotificationSettings,
  pageConfiguration,
  setPageConfiguration,
  setExecuteOnSubmit,
  setHasChanges,
  children,
}) => {
  const [alert, setAlert] = useState({ show: false, message: '' });
  const alertRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [notificationSettings, setNotificationSettings] = useState(null);
  const [pageAdminSettings, setPageAdminSettings] = useState(
    pageConfiguration.pageAdminConfiguration,
  );

  const hasOnlineOrder =
    activePage &&
    !!activePage.pageService.find((x) => x.name === 'OnlineOrder' && x.isActive === true);
  const hasTableOrder =
    activePage &&
    !!activePage.pageService.find((x) => x.name === 'TableOrder' && x.isActive === true);

  useEffect(() => {
    getNotificationSettings(activePage.id, (err, data) => {
      if (err) {
        setAlert({
          show: true,
          message: 'Could not retreive notification settings!',
          type: 'error',
        });
        setLoading(false);
      } else {
        setNotificationSettings(data);
        setLoading(false);
      }
    });
  }, [getNotificationSettings, activePage]);

  useEffect(() => {
    let alertTimer = setTimeout(() => setAlert({ show: false, message: '' }), 5000);
    return () => {
      clearTimeout(alertTimer);
    };
  }, [alert]);

  useEffect(() => {
    setExecuteOnSubmit(() => () => {
      updateNotificationSettings(activePage.id, notificationSettings, (err) => {
        if (!err) {
          setPageConfiguration(
            activePage.id,
            'pageAdminConfiguration',
            pageAdminSettings,
            (err) => {
              if (!err) {
                setAlert({
                  show: true,
                  message: 'You have successfully changed your notification settings!',
                  type: 'success',
                });
                setHasChanges(false);
              } else {
                setAlert({
                  show: true,
                  message: `Could not change your notification settings: ${err}`,
                  type: 'error',
                });
              }
              setAlert({
                show: true,
                message: 'You have successfully changed your notification settings!',
                type: 'success',
              });
              setHasChanges(false);
              scrollToAlert(alertRef);
            },
          );
        } else {
          setAlert({
            show: true,
            message: `Could not change your notification settings: ${err}`,
            type: 'error',
          });
        }
        scrollToAlert(alertRef);
      });
    });
  }, [
    activePage,
    setExecuteOnSubmit,
    setHasChanges,
    notificationSettings,
    updateNotificationSettings,
    pageAdminSettings,
    setPageConfiguration,
  ]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '0.7% 30px',
        paddingBottom: '30px',
      }}
    >
      <SettingsTitle title="Notification Settings" />
      <Alert ref={alertRef} {...alert} />

      {loading ? (
        <Spinner size={80} />
      ) : (
        <>
          {hasOnlineOrder && (
            <NotificationSettingsSection
              label={'Online Order Notifications'}
              sectionSettings={notificationSettings.onlineOrder}
              setSectionSettings={(settings) => {
                setNotificationSettings({
                  ...notificationSettings,
                  onlineOrder: {
                    ...notificationSettings.onlineOrder,
                    ...settings,
                  },
                });
                setHasChanges(true);
              }}
            />
          )}
          {hasTableOrder && (
            <>
              <NotificationSettingsSection
                label={'Table Order Notifications'}
                sectionSettings={notificationSettings.tableOrder}
                setSectionSettings={(settings) => {
                  setNotificationSettings({
                    ...notificationSettings,
                    tableOrder: {
                      ...notificationSettings.tableOrder,
                      ...settings,
                    },
                  });
                  setHasChanges(true);
                }}
              />
              <NotificationSettingsSection
                label={'Get Bill Notifications'}
                sectionSettings={notificationSettings.getBill}
                setSectionSettings={(settings) => {
                  setNotificationSettings({
                    ...notificationSettings,
                    getBill: {
                      ...notificationSettings.getBill,
                      ...settings,
                    },
                  });
                  setHasChanges(true);
                }}
              />
              <NotificationSettingsSection
                label={'Call Waiter Notifications'}
                sectionSettings={notificationSettings.callWaiter}
                setSectionSettings={(settings) => {
                  setNotificationSettings({
                    ...notificationSettings,
                    callWaiter: {
                      ...notificationSettings.callWaiter,
                      ...settings,
                    },
                  });
                  setHasChanges(true);
                }}
              />
            </>
          )}
          <NotificationSettingsSection
            label={'New Client Notifications'}
            sectionSettings={notificationSettings.newClient}
            setSectionSettings={(settings) => {
              setNotificationSettings({
                ...notificationSettings,
                newClient: {
                  ...notificationSettings.newClient,
                  ...settings,
                },
              });
              setHasChanges(true);
            }}
          />
        </>
      )}
      <InlineFlexWrapper>
        <MainLabel>Page Admin</MainLabel>
        <div style={{ width: '65%', marginTop: '-2px' }}>
          <div style={{ width: '100%', marginBottom: '10px' }}>
            <div>
              <CheckBox
                text={'Enable Notification Modal for Page Admin'}
                checked={pageAdminSettings.enableNotificationModal}
                onChange={(e) =>
                  setPageAdminSettings({
                    ...pageAdminSettings,
                    enableNotificationModal: e.target.checked,
                  })
                }
              />
            </div>
          </div>
        </div>
      </InlineFlexWrapper>
      <InlineFlexWrapper>
        <MainLabel />
        {children}
      </InlineFlexWrapper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activePage: state.facebookPages.activePage,
  pageConfiguration: state.pageConfiguration,
});

export default connect(mapStateToProps, {
  getNotificationSettings,
  updateNotificationSettings,
  setPageConfiguration,
})(NotificationSettings);
