import React from 'react';
import Alert from '../../alert';
import styled from 'styled-components';
import { ORDER_STATUS, ORDER_PAYMENT_METHODS } from 'app/orders/constants';

const OrderStatusWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 30px;
`;

const OrderStatus = ({ activeOrder }) => {
  if (!activeOrder) return null;
  const { orderStatus, paymentMethod } = activeOrder;
  const statusLabel = ORDER_STATUS[orderStatus];
  return (
    <OrderStatusWrapper>
      {orderStatus === 1 ? (
        <Alert
          show={true}
          type="success"
          message={`${statusLabel} with ${ORDER_PAYMENT_METHODS[paymentMethod]}`}
        />
      ) : orderStatus === 2 ? (
        <Alert
          show={true}
          type="success"
          message={`Paid with ${ORDER_PAYMENT_METHODS[paymentMethod]}`}
        />
      ) : orderStatus === 0 || orderStatus === 4 || orderStatus === 6 ? (
        <Alert show={true} type="pending" message={statusLabel} />
      ) : (
        <Alert show={true} type="error" message={statusLabel} />
      )}
    </OrderStatusWrapper>
  );
};

export default OrderStatus;
