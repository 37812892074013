import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { connect, useDispatch, useSelector } from 'react-redux';
import { setOrderLocation, setAddressInfo, setCurrentGeoRegion } from 'actions/shoppingActions';
import ErrorMessage from '../components/ErrorMessage';
import { BlackButton } from 'app/shared/views/buttons';
import Icons from 'app/shared/views/icons';
import LocationPicker from 'app/shared/views/locationPicker';
import _ from 'lodash';
import logo from 'assets/icons/new_logo.svg';
import { Input } from '../components/Input';
import { getPokSettingsByPageId } from 'actions/pokSettingsActions';

const LocationDetails = ({
  pageInfo,
  onProceed,
  onBack,
  setCurrentGeoRegion,
  absolutePosition = false,
  heightToRemove = 0,
}) => {
  const dispatch = useDispatch();
  const orderLocation = useSelector((state) => state.shopping.order.orderLocation);
  const geoRegion = useSelector((state) => state.shopping.geoRegion);
  const addressInfo = useSelector((state) => state.shopping.order.addressInfo);
  const constOrderLocation = useSelector((state) => state.shopping.order.orderLocation);
  const isInitialMount = useRef(true);

  const [address, setAddress] = useState('');
  const [constAddress, setConstAddress] = useState('');
  const [findMe, setFindMe] = useState(false);
  const [error, setError] = useState(null);
  const suggestInputRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (constOrderLocation && constOrderLocation.address && isInitialMount.current) {
      setConstAddress(constOrderLocation.address);
      isInitialMount.current = false;
    }
  }, [constOrderLocation]);

  useEffect(() => {
    if (findMe === true) {
      setFindMe(false);
    }
  }, [findMe]);

  useEffect(() => {
    if (orderLocation && orderLocation.address) {
      setAddress(orderLocation.address);
    }
  }, [orderLocation]);

  useEffect(() => {
    dispatch(getPokSettingsByPageId(pageInfo.id));
  }, [dispatch, pageInfo.id]);

  useEffect(() => {
    if (addressInfo?.length > 125) {
      setError({
        message: 'Max 125 characters allowed in Address Details!',
      });
    } else {
      setError(false);
    }
  }, [addressInfo]);

  const pinButton = () => {
    setAddress(constAddress);
    setFindMe(true);
  };

  const onSubmit = useCallback(() => {
    if (!orderLocation || !orderLocation.address) {
      setError({
        message: 'Please select your address location',
      });
    } else if (addressInfo?.length > 125) {
      setError({
        message: 'Max 125 characters allowed in Address Details!',
      });
    } else if (!geoRegion && !_.isEmpty(pageInfo.geoRegions)) {
      setError({
        message: 'Your are out of the area coverage',
      });
    } else {
      onProceed && onProceed();
    }
  }, [orderLocation, geoRegion, pageInfo, onProceed, addressInfo]);
  return (
    <LocationsDetailsWrapper absolute={absolutePosition} heightToRemove={heightToRemove}>
      <LocationPicker
        pageInfo={pageInfo}
        // theme="silver"
        location={orderLocation}
        resetToCurrent={findMe ? true : false}
        suggestInputRef={suggestInputRef}
        onLocationPicked={(location) => {
          dispatch(setOrderLocation(location));
        }}
        zones={pageInfo.geoRegions}
        setCurrentZone={setCurrentGeoRegion}
        containerStyles={{ width: '499px', position: 'relative' }}
      />

      {/* Location picker button */}

      {/* <LocationPickBtn
        isLoading={!orderLocation}
        onClick={() => {
          dispatch(setOrderLocation(null));
        }}
      >
        <Icons.Pick fill={'#FFFFFF'} width={'26px'} height={'26px'} />
      </LocationPickBtn> */}
      <StyledFooter absolute={absolutePosition}>
        <Info>
          {onBack && (
            <div onClick={onBack}>
              <Skip>Back</Skip>
            </div>
          )}
          <SkipStep>
            <img
              src={logo}
              alt="logo"
              style={{
                display: 'block',
                margin: 'auto',
                width: '80px',
                paddingTop: '25px',
                paddingBottom: '33px',
              }}
            />
          </SkipStep>
          <Title>Enter Address</Title>
          <Hint>Input your address or press the pin button!</Hint>
          <ErrorMessage
            show={!!error}
            message={error && error.message}
            style={{ padding: '10px 30px' }}
          />
          <LocationPickerInputWrapper>
            <div
              style={{
                width: '30px',
                height: '30px',
                position: 'absolute',
                left: '12px',
                bottom: '14px',
                zIndex: 1,
              }}
            >
              <Icons.LocationPin />
            </div>
            <LocationInput
              start={Icons.LocationPin}
              type="text"
              value={address}
              placeholder="Input address"
              height="54"
              inputRef={suggestInputRef}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
            <div
              style={{
                width: '30px',
                height: '30px',
                position: 'absolute',
                right: '12px',
                bottom: '10px',
                zIndex: 1,
              }}
              onClick={() => pinButton()}
            >
              <Icons.FindMe />
            </div>
          </LocationPickerInputWrapper>
          <LocationPickerInputWrapper style={address === '' ? { display: 'none' } : {}}>
            <div
              style={{
                width: '30px',
                height: '30px',
                position: 'absolute',
                left: '15px',
                bottom: '9px',
                zIndex: 1,
              }}
            >
              <Icons.AddressDescription />
            </div>
            <LocationDetailsInput
              placeholder="Address Details"
              height="54"
              value={addressInfo || undefined}
              onChange={(e) => {
                dispatch(setAddressInfo(e.target.value));
              }}
            />
          </LocationPickerInputWrapper>
        </Info>
      </StyledFooter>
      <FooterActionsWrapper>
        <BlackButton
          style={{
            width: '95%',
            minHeight: '60px',
            height: 60,
            fontSize: 16,
            fontWeight: 'bold',
            color: '#FFFFFF',
            borderRadius: '7px',
            marginBottom: '32px',
            marginLeft: '12.3px',
          }}
          text="Set address"
          onClick={onSubmit}
        />
      </FooterActionsWrapper>
    </LocationsDetailsWrapper>
  );
};

const LocationsDetailsWrapper = styled.div`
  height: calc(var(--vh, 1vh) * 100 - ${({ heightToRemove }) => heightToRemove}px);
  display: block;
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: space-between;
`;

const Hint = styled.h3`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  display: block;
  text-align: center;
  color: #748aa1;
  padding-bottom: 15px;
`;

const SkipStep = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Skip = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 17px;
  cursor: pointer;
  position: relative;
  top: 32px;
  left: 30px;
`;

const StyledFooter = styled.div`
  position: ${({ absolute }) => (absolute ? 'absolute' : 'fixed')};
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-around;
  z-index: 1;
  max-width: 500px;
  margin-top: auto;
  backgroudcolor: white;
`;

const Info = styled.div`
  height: auto;
  background: #ffffff;
  border-radius: 0px 0px 12px 12px;
`;

const LocationPickerInputWrapper = styled.div`
  display: block;
  align-items: center;
  position: relative;
  z-index: 11;
  margin-bottom: 10px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  color: black;
  display: block;
  text-align: center;
`;

const LocationInput = styled(Input)`
  background-color: #f9fafc;
  border-radius: 6px;
  margin: 10px;
  margin-bottom: 15px;
  padding-left: 10px;
`;
// eslint-disable-next-line

const LocationDetailsInput = styled(Input)`
  background-color: #f9fafc;
  border-radius: 6px;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 10px;
`;

const FooterActionsWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
`;

export default connect(() => ({}), {
  setCurrentGeoRegion,
})(LocationDetails);
