import React, { Component } from 'react';
import styled from 'styled-components';
import shared from 'app/shared';
import { NavLink } from 'react-router-dom';
import { format } from "date-fns";
import { sortData, getSortState } from '../../../../config/helpers/sorter';
import { paginate } from '../../../../config/helpers/paginator';

const {
    views: {Table, Button, Paginator, FilterableTableStructure, Card },
    helpers: { colors }
} = shared;

const {
    TableWrapper,
    PaginatorWrapper,
} = FilterableTableStructure;

const getPaymentMethodLabel = (trial) => {
    return trial ? "" : "Stripe";
}

const getStatusBadge = (trial) => {
    let index = trial ? 0 : 1;

    const labels = [
        "TRIAL",
        "PAID",        
    ];
    const labelColors = [
        colors.grey,
        colors.green,        
    ];
    return (
        <NavLink>
        <Button.Button
            style={{ fontWeight: 600 }}
            text={labels[index]}
            size={Button.ButtonSize.large}
            color={labelColors[index]}
        />
        </NavLink>
    )
}

const header = [
{
    label: 'ID',
    key: 'ID',
    sortable: false,
    renderer: ({ positionNo }) => positionNo,
},
{
    label: 'DATE',
    key: 'subscriptionStartDate',
    sortable: true,
    renderer: ({ subscriptionStartDate }) => format(subscriptionStartDate, 'YYYY-MM-DD'),
},
{
    label: 'PAYMENT METHOD',
    key: 'paymentMethod',
    sortable: true,
    renderer: ({ isTrialSubscription }) => {
    return(
        getPaymentMethodLabel(isTrialSubscription)
    )
    },    
},
{
    label: 'STATUS',
    key: 'status',
    sortable: true,
    renderer: ({ isTrialSubscription }) => {
    return (
        getStatusBadge(isTrialSubscription)
    );
    },    
},  
{
    label: 'ACTION',
    width: '70px',
    key: 'action',
    sortable: false,
    renderer: ({ pdfUrl }) => {
    return (
        pdfUrl &&
        <a target="_blank" href={pdfUrl} rel="noopener noreferrer">
        <Button.Button
            style={{ fontWeight: 600 }}
            text={"View"}
            size={Button.ButtonSize.medium}
            color={colors.blue}
        />
        </a>
    );
    },
}
]

const headerTablet = [
{
    label: 'ID',
    key: 'ID',
    sortable: false,
    renderer: ({ positionNo }) => positionNo,
},
{
    label: 'DATE',
    key: 'subscriptionStartDate',
    sortable: true,
    renderer: ({ subscriptionStartDate }) => format(subscriptionStartDate, 'YYYY-MM-DD'),
},
{
    label: 'ACTION',
    width: '70px',
    key: 'action',
    sortable: false,
    renderer: ({ pdfUrl }) => {
    return (
        pdfUrl &&
        <a target="_blank" href={pdfUrl} rel="noopener noreferrer">
        <Button.Button
            style={{ fontWeight: 600 }}
            text={"View"}
            size={Button.ButtonSize.medium}
            color={colors.blue}
        />
        </a>
    );
    },
}
]  

const TableTitleWrapper = styled.div`
  color: ${colors.blue};
  font-weight: bold;
  border-bottom: 2px solid ${colors.blue};
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

const TableWrapperTablet = styled(Card)`
  display: none;
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGrey};
  border-radius: 3px;
  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: block;
    margin-top: 25px;
  }

  @media all and (max-width: 768px){
    display: block;
    margin-top: 25px;
  }
`;

const initialState = {
    sort: {
      key: 'id',
      order: 1,
    },
    currentPage: 1,
    pageSize: 10
  };

class SubscriptionsSection extends Component{

    state = initialState;

    componentDidMount(){

    }

    render(){
        const {
            state: {
              currentPage,
              pageSize,
              sort    
            }
          } = this;  

        const getTopData = (data, invoices) => {
            data.forEach((item, index) => {
                item.positionNo = (currentPage * pageSize - pageSize) + ++index;
                let filtered = invoices.filter((invoice) => {
                    return invoice.subscriptionId === item.subscriptionId;
                })
                if(filtered.length > 0){
                    item.pdfUrl = filtered[0].pdfUrl;
                }
            });
     
            return data;
        }
          
        const updateSortState = key => {
        this.setState(prevState => ({
            sort: getSortState(prevState.sort, key),
        }));
        };

        const getTableData = (header, data, invoices) => {
            return {
                action: updateSortState,
                header: header,
                values: getTopData(data, invoices),
            }
        };

        const updatePaginatorPage = currentPage => {
            this.setState({ currentPage });
        }; 

        const sortedData = sortData(this.props.subs, sort);
        const paginatedData = paginate(sortedData, currentPage, pageSize);    

        return (
            <>
                <TableTitleWrapper>
                    Subscription History
                </TableTitleWrapper>
                <TableWrapper>
                    <Table.TableView data={getTableData(header, paginatedData.current, this.props.subInvoices)} />
                </TableWrapper>
                <TableWrapperTablet>
                    <Table.TableView data={getTableData(headerTablet, paginatedData.current, this.props.subInvoices)} />
                </TableWrapperTablet>
                <PaginatorWrapper>
                    <Paginator
                        changePage={updatePaginatorPage}
                        numberOfPages={paginatedData.pages}
                        page={paginatedData.currentPage}
                    />
                </PaginatorWrapper>
            </>
        );
    }
}

export default SubscriptionsSection