import GuestRoute from './hoc/guestRoute';
import ProtectedRoute from './hoc/protectedRoute';
import LoginPage from './views/login.page';

export default {
  hoc: {
    GuestRoute,
    ProtectedRoute,
  },
 views: {
   LoginPage,
 },
};
