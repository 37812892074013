import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import calling_waiter from '../../../../assets/images/calling_waiter.png';
import Modal from './components/modal';
import { GreenButton } from '../../views/buttons';
import { markAsRead } from 'actions/notificationAction';
import {
  countCallWaiterNotifications,
  getFirstUnreadCallWaiterNotification,
} from '../../../../reducers/notificationReducer';

const Image = styled.img`
  border-radius: 3px;
  display: flex;
  margin: 8px auto;
  margin-top: 42px;
  flex: 1;
`;

const CallWaiterModal = (props) => {
  const notification = props.notification;
  const table = notification && props.tables.find((x) => x.id === notification.itemId);
  return (
    <Modal
      visible={notification}
      title={'Calling Waiter...'}
      dateTime={Date.parse(notification && notification.creationDate)}
      countNotifications={props.countCallWaiterNotifications}
      tableName={table?.name}
      actions={[
        () => <GreenButton text={'Close'} onClick={() => props.markAsRead(notification.id)} />,
      ]}
    >
      <Image alt="calling_waiter" src={calling_waiter} height={212} />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  countCallWaiterNotifications: countCallWaiterNotifications(state),
  notification: getFirstUnreadCallWaiterNotification(state),
  tables: (state.facebookPages.activePage?.tableCategories ?? []).map((x) => x.tables).flat(),
});

export default connect(mapStateToProps, { markAsRead })(CallWaiterModal);
