import Config from '../config/api';
import {getAll} from "actions/crudActions";
import crudTypes from "config/crudTypes";
import { buildAxiosInstance } from 'actions/common';

const baseURL = `${Config.baseUrl}/Marketing`;

const axiosClient = buildAxiosInstance(baseURL);

export const getSmsCreditPayLink = (quantity) => async (dispatch) => {
  try {
    dispatch({ type: 'SMS_CREDIT_PAY_LINK_LOADING' });
    const res = await axiosClient.get(`${baseURL}/SmsCreditPayLink`, { params: { quantity } });
    dispatch({ type: 'SMS_CREDIT_PAY_LINK_OK', data: res.data });
  } catch (e) {
    console.log(e);
    dispatch({ type: 'SMS_CREDIT_PAY_LINK_ERROR', error: e });
  }
};

export const sendSms = (clientId, message, setStatus = null) => async (dispatch, getState) => {
  try {
    const pageId = getState().facebookPages.activePage.id;
    setStatus && setStatus('loading');
    const res = await axiosClient.get(`${baseURL}/SendSMS`, {
      params: {
        clientId,
        message,
      },
    });
    setStatus && setStatus('success', res);
    dispatch({ type: 'SEND_SMS_OK', clientId, message, data: res });
    // TODO: use action data, remove hardcoded 1 sms credit
    const activePage = getState().facebookPages.activePage;
    dispatch({
      type: 'SMS_CREDIT_BALANCE_UPDATED',
      pageId,
      data: { updatedSmsCredits: activePage.smsCredits - 1 },
    });
    dispatch(getAll(crudTypes.client));
  } catch (e) {
    console.log(e);
    setStatus && setStatus('error', e);
    dispatch({ type: 'SEND_SMS_ERROR', clientId, message, e });
  }
};
