import React from 'react';
import { connect } from 'react-redux';
import { setGuestCheckoutId } from '../../../actions/subscriptionActions';
import SignUpPage from '../../auth/views/SignUp.page';

const { useEffect } = React;

const GuestCheckoutPage = (props) => {
  const { match, setGuestCheckoutId } = props;

  useEffect(() => {
    match.params.checkout_id && setGuestCheckoutId(match.params.checkout_id);
  }, [match.params.checkout_id, setGuestCheckoutId]);

  return <SignUpPage></SignUpPage>;
};

GuestCheckoutPage.path = '/guest_checkout';

const mapStateToProps = (state) => ({
  guestCheckoutId: state.guestCheckoutId,
});

export default connect(mapStateToProps, {
  setGuestCheckoutId,
})(GuestCheckoutPage);

export { GuestCheckoutPage };
