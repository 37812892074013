const initialState = {
  receiptConfiguration: {
    printRestaurantName: true,
    printOrderValues: true,
    printProductDescription: false,
    autoprintTableWhitelistEnabled: false,
    autoprintTableWhitelist: [],
  },
  pageAdminConfiguration: {
    enableNotificationModal: true,
  },
  PUBLIC_onlineOrderIframeConfiguration: {
    hideBanner: false,
    collectClientName: false,
    portraitProductImage: false,
    customScript: null,
  },
  PUBLIC_tableOrderIframeConfiguration: {
    hideBanner: false,
    collectClientName: false,
    collectPhoneOnTable: false,
    enableWaiterFunctionalities: true,
    portraitProductImage: false,
    customScript: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PAGE_CONFIGURATION':
      const { key, value } = action;
      return {
        ...state,
        [key]: {
          ...state[key],
          ...value,
        },
      };

    default:
      return state;
  }
};
