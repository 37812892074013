import styled from 'styled-components';
import shared from 'app/shared';
import React from 'react';
import colors from 'app/shared/helpers/colors';

const{
  views:{
    Button: {
      MainButton,
    },
  },

} = shared;


const ButtonDivWrapper = styled.div`
  width: 50%;
  margin: 0 auto;
  display: inline-flex
`;

const ButtonWrapper = styled(MainButton)`
  margin: 0 auto;
  width: 30%;
  padding: 5%;
  font-size: 150%;
  font-weight: 500;
`;

const MainButtonOuterWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const MainButtonInnerWrapper = styled.div`
  width: 50%;
  margin: 0 auto;
`;

const SidedButton = ({innerStyle = null, text}) => {
  return (
    <ButtonDivWrapper>
      <ButtonWrapper style={innerStyle} text={text}/>
    </ButtonDivWrapper>
  );
};

const greenButtonStyle = {
  padding: '5%',
  fontSize: '150%',
  width: '35%',
  color: colors.green,
  backgroundColor: colors.lightGreen
};

const MainGreenButton = ({text}) => (
  <MainButtonOuterWrapper>
    <MainButtonInnerWrapper>
      <MainButton text={text} style={greenButtonStyle}/>
    </MainButtonInnerWrapper>
  </MainButtonOuterWrapper>
);

export default {SidedButton, MainGreenButton};
