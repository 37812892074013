// const shuffle = (array) => array.sort(() => Math.random() - 0.5);

const randomise = (array) => {
  var currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};

const shuffle = (array) => {
  // sort closed closed bussiness last
  let openBussiness = array.filter((business) => business.isOpen);
  let closedBussiness = array.filter((business) => !business.isOpen);
  return [...randomise(openBussiness), ...randomise(closedBussiness)];
};

export default shuffle;
