import React from 'react';
import shared from 'app/shared';
import styled from 'styled-components';
import finishedImage from '../../../assets/images/finished.svg';

const {
  views:{
    MainView,
  }
} = shared;

const ImgWrapper = styled.img`
  width: 100%;
  
`;

const Wrapper = styled.div`
  
  width: 80.97%;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  
  margin: 15% auto;
`;

class Finished extends React.Component{
  render(){
    return (
      <MainView title={'Setup Completed'} subtitle={'You will be redirected to Orderoo'}>
        <Wrapper>
          <ImgWrapper src={finishedImage}/>
        </Wrapper>

      </MainView>
    );
  }
}

Finished.path = '/finished';

export default Finished;
