import { keyframes } from 'styled-components';

export default keyframes`
  0% {
    transform: translateY(-20px);
  }
  
  50% {
    transform: translateY(-20px);
  }
  
  100% {
    transform: translateY(0);
  }
`;
