import React from 'react';
import styled from 'styled-components';

const Pill = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  margin-right: -10px;
  margin-left: 5px;
  text-align: center;
  vertical-align: middle;

  background-color: #ff3a44;
  color: #ffffff;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  text-align: center;
`;

const Badge = styled.div`
  display: block;
  padding: 5px 15px;

  height: 30px;
  background: ${(props) => (props.isActive ? '#000000' : '#F9FAFC')};
  border-radius: 5px;
  color: ${(props) => (props.isActive ? '#fff' : '#000')};
`;

const Name = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  white-space: nowrap;
`;

const CategoryWrapper = styled.div``;

const Category = ({ name, count = 0, isSelected, ...props }) => {
  return (
    <CategoryWrapper>
      <Badge {...props} isActive={isSelected}>
        <Name>{name}</Name>
        {count > 0 && <Pill>{count}</Pill>}
      </Badge>
    </CategoryWrapper>
  );
};

export default Category;
