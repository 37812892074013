import React, { useState, useEffect, createRef, useCallback } from 'react';
import styled from 'styled-components';
import colors from '../helpers/colors';
import ifDifferentFromNullRenderThis from '../helpers/ifDifferentFromNullRenderThis';
import UploadInputImage from './uploadInputImage';
import axios from 'axios';
import Config from '../../../config/api';
import FormData from 'form-data';
import Icons from './icons';
import _ from 'lodash';
import { Upload, Icon, message } from 'antd';

import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import flags from 'react-phone-number-input/flags';

const baseUri = `${Config.baseUrl}/Image`;
const { AWS_S3_PHOTO_CONTAINER } = Config;

const Label = styled.label`
  color: ${colors.textColor};
  font-size: 12px;
  line-height: 23px;
  font-weight: 500;
`;

const LabelInfo = styled.label`
  color: ${colors.lightGrey};
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  margin-left: 10px;
`;

const LabelWrapper = styled.div`
  padding-left: 10px;
`;

const InputField = styled.input`
  color: ${colors.darkestGrey};
  background: transparent;
  border: none;
  padding: 0;
  outline: none;
  font-size: 16px;
  line-height: 17px;
  width: 100%;
  height: 100%;
  user-select: auto;
  /* input::-webkit-outer-spin-button; */
  input::-webkit-inner-spin-button {
    display: none;
    appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type='number'] {
    appearance: textfield; /* Firefox */
  }
`;

const InputAreaField = styled.textarea`
  align-self: center;
  color: ${colors.darkestGrey};
  background: transparent;
  border: none;
  padding: 0;
  outline: none;
  font-size: 16px;
  line-height: 17px;
  width: 100%;
  height: 100%;
  user-select: auto;
  /* textarea::-webkit-outer-spin-button; */
  textarea::-webkit-inner-spin-button {
    display: none;
    appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
`;

const InputWrapper = styled.div`
  border: 1px solid #e1e6ec;
  border-radius: 3px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${(props) => (props.height ? `${props.height}px` : null)};
  background: ${colors.white};
  & > input {
    padding: 0 25px 0 10px;
  }

  & > textarea {
    padding: 11px 10px;
    resize: none;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {
    & > input {
      padding: 0 0 0 5px;
    }
  }
`;

const UploadInputWrapper = styled(InputWrapper)`
  & .mask {
    display: none;
  }
  &:hover {
    & .mask {
      display: flex;
      align-items: center;
      justify-content: center;

      background-color: #fff;
      opacity: 0.9;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
`;

const AntdInputLabel = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;

  color: #748aa1;
`;

const InputAreaWrapper = styled(InputWrapper)`
  flex-direction: column;
`;

const StartWrapper = styled.div``;
const EndWrapper = styled.div``;

const Input = React.forwardRef(
  (
    { start = null, end = null, labelText = null, height = 43, className = '', inputRef, ...rest },
    ref,
  ) => (
    <div>
      {ifDifferentFromNullRenderThis(
        labelText,
        <LabelWrapper>
          <Label>{labelText}</Label>
        </LabelWrapper>,
      )}
      <InputWrapper height={height} className={className}>
        {ifDifferentFromNullRenderThis(start, <StartWrapper>{start}</StartWrapper>)}
        <InputField ref={ref || inputRef} {...rest} />
        {ifDifferentFromNullRenderThis(end, <EndWrapper>{end}</EndWrapper>)}
      </InputWrapper>
    </div>
  ),
);

const getImageUri = (uuid, size = 'SIZE_2') => {
  return `${AWS_S3_PHOTO_CONTAINER}/${uuid}_${size}.jpeg`;
};

const UploadInput = ({
  setUri = null,
  labelText = null,
  setUriForUser = null,
  height = 125,
  imagePath = null,
  uriGenerator,
  uploadOriginal = false,
  ...rest
}) => {
  const [imageUri, setImageUri] = useState(imagePath);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputId = _.uniqueId();
  const inputRef = React.createRef();

  const triggerUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const uploadImage = (event) => {
    if (event.target.files.length === 0) return;

    let data = new FormData();
    data.append('Image', event.target.files[0], event.target.files[0].name);
    setIsLoading(true);

    axios
      .post(baseUri + (uploadOriginal ? '/upload-original' : ''), data, {
        headers: {
          accept: 'application/json',
          'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        },
      })
      .then((res) => {
        const completeUri = getImageUri(res.data.uuid, uploadOriginal ? 'ORIGINAL' : 'SIZE_2');
        uriGenerator(completeUri);
        setImageUri(completeUri);

        if (setUriForUser != null) setUriForUser(completeUri);
      })
      .catch((err) => setImageUri(imagePath))
      .finally(() => {
        setIsLoading(false);
      });
  };

  let imageDisplay;

  if (isLoading) {
    imageDisplay = <Icons.Loading width="40px" fill={colors.blue} />;
  } else if (imageUri === null) {
    imageDisplay = <UploadInputImage for="file" />;
  } else {
    imageDisplay = <img width="100%" src={imageUri} alt={'Uploaded Here'} />;
  }

  return (
    <div {...rest}>
      {ifDifferentFromNullRenderThis(
        labelText,
        <LabelWrapper>
          <Label>{labelText}</Label>
        </LabelWrapper>,
      )}
      <UploadInputWrapper
        height={height}
        style={{ padding: 0, justifyContent: 'center', overflow: 'hidden' }}
        onClick={triggerUpload}
      >
        <label htmlFor={fileInputId}>{imageDisplay}</label>
        {!isLoading && (
          <div className="mask">
            <Icons.Upload color={colors.blue} width={'29px'} height={'20px'} />
          </div>
        )}
        <input
          id={fileInputId}
          ref={inputRef}
          type={'file'}
          style={{ display: 'none' }}
          onChange={uploadImage}
        />
      </UploadInputWrapper>
    </div>
  );
};

const AntdUploadInput = ({ imageUrl = null, setImageUrl = () => {}, multiple = false }) => {
  const [loading, setLoading] = useState(false);
  const props = {
    name: 'image',
    action: baseUri,
    headers: {
      accept: 'application/json',
      'Accept-Language': 'en-US,en;q=0.8',
      // 'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
    },
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };
  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    console.log('inf1o', info);
    if (info.file.status === 'done') {
      console.log('info2', info);
      getBase64(info.file.originFileObj, (imageUrl) => {
        setImageUrl(getImageUri(info?.file?.response?.uuid));
        setLoading(false);
      });
    }
  };

  const UploadButton = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      <Icon type={loading ? 'loading' : 'upload'} />
      <AntdInputLabel>Upload Event Image</AntdInputLabel>
      <AntdInputLabel>700px : 760px</AntdInputLabel>
    </div>
  );

  return (
    <Upload
      {...props}
      listType="picture-card"
      showUploadList={false}
      action={baseUri}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      multiple={multiple}
      style={{ width: '100%', height: '100%' }}
      className={'antd-upload-input'}
    >
      {imageUrl ? (
        <>
          <img src={imageUrl} alt="avatar" style={{ width: '100%', height: '100%' }} />
        </>
      ) : (
        <UploadButton />
      )}
    </Upload>
  );
};
const baseUriCsv = `${Config.baseUrl}/Product/import-csv`;

const UploadCSVInput = ({ height = 33, done = null, ...rest }) => {
  const [isLoading, setIsLoading] = useState(false);
  const fileInputId = _.uniqueId();
  const inputRef = React.createRef();

  const triggerUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const uploadFile = (event) => {
    if (event.target.files.length === 0) return;

    let data = new FormData();
    data.append('csv', event.target.files[0], event.target.files[0].name);
    setIsLoading(true);

    axios
      .post(baseUriCsv, data, {
        headers: {
          accept: 'application/json',
          'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        },
      })
      .then((res) => done && done(null, res.data))
      .catch((err) => done && done(err))
      .finally(() => setIsLoading(false));
  };

  return (
    <div style={{ display: 'inline' }} {...rest}>
      <UploadInputWrapper
        height={height}
        style={{
          padding: 0,
          justifyContent: 'center',
          overflow: 'hidden',
          display: 'inline-flex',
          width: 117,
        }}
        onClick={triggerUpload}
      >
        <label htmlFor={fileInputId}>
          {isLoading ? <Icons.Loading width="40px" fill={colors.blue} /> : 'Import CSV'}
        </label>
        {!isLoading && (
          <div className="mask">
            <Icons.Upload color={colors.blue} width={'29px'} height={'20px'} />
          </div>
        )}
        <input
          id={fileInputId}
          ref={inputRef}
          type={'file'}
          style={{ display: 'none' }}
          onChange={uploadFile}
        />
      </UploadInputWrapper>
    </div>
  );
};

const InputArea = ({
  labelText = null,
  className = '',
  inputRef,
  onChange,
  max,
  rows = 1,
  end = null,
  ...rest
}) => {
  inputRef = inputRef || createRef();

  useEffect(() => {
    resizeArea(rows, inputRef.current);
  }, [inputRef, rows]);

  const handleInput = useCallback(
    (e) => {
      if (onChange) {
        onChange(e);
      }

      resizeArea(rows, inputRef.current);
    },
    [onChange, inputRef, rows],
  );

  return (
    <>
      {ifDifferentFromNullRenderThis(
        labelText,
        <LabelWrapper>
          <Label>{labelText}</Label>
          {max ? <LabelInfo>Max Characters. {max}</LabelInfo> : null}
        </LabelWrapper>,
      )}
      <InputAreaWrapper className={className}>
        <InputAreaField
          ref={inputRef}
          maxLength={max}
          rows={rows}
          onChange={handleInput}
          {...rest}
        />
        {ifDifferentFromNullRenderThis(end, <>{end}</>)}
      </InputAreaWrapper>
    </>
  );
};

const resizeArea = (rows, el) => {
  if (el) {
    rows = typeof rows !== 'number' ? parseInt(rows, 10) : rows;
    if (isNaN(rows)) return;

    el.style.height = '0';
    el.style.overflowY = 'hidden';
    const {
      borderBottomWidth,
      borderTopWidth,
      fontSize,
      lineHeight,
      paddingBottom,
      paddingTop,
    } = window.getComputedStyle(el);

    const lh = lineHeight === 'normal' ? parseFloat(fontSize) * 1.2 : parseFloat(lineHeight);
    const rowHeight =
      rows === 0
        ? 0
        : lh * rows +
          parseFloat(borderBottomWidth) +
          parseFloat(borderTopWidth) +
          parseFloat(paddingBottom) +
          parseFloat(paddingTop);

    const scrollHeight =
      el.scrollHeight + parseFloat(borderBottomWidth) + parseFloat(borderTopWidth);
    const height = Math.max(rowHeight, scrollHeight);
    el.style.height = `${height}px`;
  }
};

const PhoneNumberInput = (props) => {
  return (
    <PhoneInput
      {...props}
      flags={flags}
      // placeholder={placeholder} value={value} onChange={(phone) => onChange({ phone })}
    />
  );
};
export default {
  Input,
  InputArea,
  UploadInput,
  AntdUploadInput,
  UploadCSVInput,
  PhoneNumberInput,
};
