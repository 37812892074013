import React, { useEffect } from 'react';
import ClipLoader from 'app/shared/views/clipLoader';
import styled from 'styled-components';
import logout from 'app/shared/helpers/logout';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setActivePage, setLocalActivePages, getFacebookPages } from 'actions/facebookAction';

const LoadingWrapper = styled.div`
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: transparent;
  z-index: 999999;
`;

const Title = styled.h1`
  font-size: 2em;
  text-align: center;
  color: black;
  padding-top: 90px;
`;
const ImpersonatePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const url = new URL(window.location);
  const accessToken = url.searchParams.get('accessToken');
  const businessId = url.searchParams.get('businessId');

  useEffect(() => {
    logout();
    if (!accessToken) {
      return history.replace('/signin');
    }
    localStorage.setItem('authToken', accessToken);
    dispatch({
      type: 'LOGIN_USER_OK',
      token: accessToken,
    });
    localStorage.setItem('Page', JSON.stringify(businessId));
    setActivePage(businessId);
    window.location.replace('/dashboard');
    setLocalActivePages();
    getFacebookPages();
  }, [accessToken, dispatch, history, businessId]);

  return (
    <>
      <LoadingWrapper>
        <ClipLoader size={80} style={{ height: '100vh', width: '100vw' }} />
      </LoadingWrapper>
      <Title>Logging in</Title>
    </>
  );
};

export default ImpersonatePage;
