import crudTypes from '../../../config/crudTypes';
import * as signalR from '@microsoft/signalr';
import sound from '../../../assets/audio/sound.mp3';
import Config from '../../../config/api';
import { getNotifications } from 'actions/notificationAction';
import { dispatchSuccess } from 'actions/crudActions';
import { ORDER_STATUS } from 'app/orders/constants';
import { isAuthenticated } from 'auth';

const { signalRUrl } = Config;
const audio = new Audio(sound);

let unreadHighPriorityNotifications = false;
setInterval(() => {
  if (!isAuthenticated()) {
    unreadHighPriorityNotifications = false;
  }
}, 3000);

console.log(unreadHighPriorityNotifications);

const setupConnection = () => async (dispatch, getState) => {
  const fetchData = () => {
    dispatch(getNotifications());
  };
  dispatch({ type: 'CONNECT_TO_HUB_WAIT' });

  const connectionHub = new signalR.HubConnectionBuilder()
    .withUrl(signalRUrl, { accessTokenFactory: () => localStorage.getItem('authToken') })
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: () => 5000,
    })
    .configureLogging(signalR.LogLevel.Information)
    .build();

  connectionHub.disconnected = () => {
    setTimeout(() => connectionHub.start(), 5000); // Restart connection after 5 seconds.
  };

  connectionHub.onreconnected(() => {
    fetchData();
  });

  connectionHub.on('NEW_NOTIFICATION', (pageId) => {
    if (pageId === getState().facebookPages.activePage.id) {
      audio.play();
      fetchData();
    }
  });

  connectionHub.on('UNREAD_HIGHPRIO_NOTIFICATIONS', () => {
    unreadHighPriorityNotifications = true;
  });

  connectionHub.on('READ_HIGHPRIO_NOTIFICATIONS', () => {
    unreadHighPriorityNotifications = false;
  });

  connectionHub.on('ORDER_UPDATED', (updatedOrder) => {
    if (updatedOrder.pageId === getState().facebookPages.activePage.id) {
      dispatch({ ...dispatchSuccess(`PUT_${crudTypes.order.tag}`, { data: updatedOrder }) });
      if (ORDER_STATUS[updatedOrder.orderStatus] === 'Paid') {
        dispatch({ type: 'ORDER_PRINT', data: updatedOrder.id });
      }
    }
  });

  connectionHub.on('NOTIFICATION_UPDATED', (updatedNotification) => {
    if (updatedNotification.pageId === getState().facebookPages.activePage.id) {
      dispatch({ type: 'UPDATE_NOTIFICATION_BY_ID', data: updatedNotification });
    }
  });

  try {
    await connectionHub.start()
      .then(() => {
        // Successfully connected to the hub
        connectionHub.invoke("GetConnectionId")
          .then((connectionId) => {
            console.log("Connection ID: " + connectionId);
          })
          .catch((error) => {
            console.error("Error getting connection ID: " + error);
          });
      })
      .catch((error) => {
        console.error("Error connecting to the hub: " + error);
      });

    window.connectionHub = connectionHub;
    dispatch({ type: 'CONNECT_TO_HUB_OK', data: connectionHub });
  } catch (err) {
    console.log(err);
    dispatch({ type: 'CONNECT_TO_HUB_ERROR' });
  }
  return connectionHub;
};

export { setupConnection };
