import _ from 'lodash';

export default () => {
  if (_.isEmpty(process.env.REACT_APP_SMARTLOOK)) return;
  window.smartlook ||
    (function (d) {
      var o = (window.smartlook = function () {
          o.api.push(arguments);
        }),
        h = d.getElementsByTagName('head')[0];
      var c = d.createElement('script');
      o.api = [];
      c.async = true;
      c.type = 'text/javascript';
      c.charset = 'utf-8';
      c.src = 'https://rec.smartlook.com/recorder.js';
      h.appendChild(c);
    })(document);
  window.smartlook('init', process.env.REACT_APP_SMARTLOOK);
};
