import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import currencyFormatter from 'app/shared/helpers/currencyFormatter';
import { BlackButton, Button } from 'app/shared/views/buttons';
import Icons from 'app/shared/views/icons';
import LocationPicker from 'app/shared/views/locationPicker';
import { setOrderLocation } from 'actions/shoppingActions';

export const PAYMENT_STATUS = {
  WAITING_CONFIRMATION: 'WAITING_CONFIRMATION',
  ORDER_REJECTED: 'ORDER_REJECTED',
  PAYMENT_SUCCEEDED: 'PAYMENT_SUCCEEDED',
  PAYMENT_DECLINED: 'PAYMENT_DECLINED',
};

const BackButton = ({ style, ...props }) => (
  <Button
    style={{
      minHeight: '40px',
      height: 40,
      background: '#fff',
      border: 'none',
      fontSize: 16,
      fontWeight: 400,
      fontHeight: 17,
      borderRadius: 20,
      color: '#1D2036',
      boxShadow: 'rgba(200, 208, 216, 0.3) 0px 12px 16px',
      ...style,
    }}
    {...props}
  />
);

const PaymentDetailsWrapper = styled.div`
  display: flex !important;
  flex-direction: column !important;
  background-color: #ffffff !important;
  position: relative !important;
  bottom: 0 !important;
  width: 100vw !important;
  max-width: 500px !important;

  height: ${({ heightToRemove }) =>
    heightToRemove ? `calc(var(--vh, 1vh) * 100 - ${heightToRemove}px)` : '100% '} !important;
  overflow: hidden !important;
  margin: auto;
  @media (min-width: 500px) {
    border: 1px #e8e9f3 solid;
  }
`;

const StyledHeader = styled.div`
  width: 100%;
  position: sticky !important;
  top: 0 !important;
  background-color: #ffffff !important;
  display: flex !important;
  flex-direction: row;
  justify-content: center !important;
  align-items: center;
  padding: 0.5rem 20px 0;
  z-index: 10;
`;

const StyledBody = styled.div`
  overflow-y: auto;
  width: 100%;
  flex: 1;
`;

const SectionTitle = styled.span`
  align-items: center;

  font-family: Inter, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #1d2036;
`;

const Section = styled.div`
  padding: 20px;

  &:last-child {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`;

const StyledFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;
`;

const FooterActionsWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
  align-items: center;

  & > :nth-child(2n + 1) {
    ${(props) =>
      props.vertical
        ? `
      margin-bottom: 10px;
      width: 100% !important;
    `
        : `
      margin-right: 20px;
    `}
  }
`;

const PaymentStatusTitle = styled.span`
  font-family: Inter, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  flex: 2;
  color: ${(props) => props.color};
  width: 100%;
`;

const PaymentStatusSubtitle = styled.span`
  line-height: 22px;
  width: 100%;
  margin-top: 10px;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #5C677B;

  &::after {
    margin-left: 20px;
    font-family: Inter, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: right;
    content: '${(props) => props.value}';
    color: ${(props) => props.valueColor};
  }
`;

const OrderRow = styled.div`
  background-color: ${(props) => (props.index % 2 === 0 ? '#F9FAFC' : '#FFFFFF')};
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const ProductName = styled.div`
  flex: 2;
  margin-left: ${(props) => (props.isCombo ? '8px' : 0)};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #1d2036;
  line-height: 20px;
`;
const ProductTotal = styled.div`
  flex: 1;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1d2036;
`;

const ProductPrice = styled.div`
  flex: 1;
  text-align: right;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: black;
`;

const BillingInformation = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px 0.5rem 20px;
`;

const BillingInformationRow = styled.div`
  padding: 10px 0;
`;

const BillingInformationLabel = styled.div`
  flex: 1;
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 5px;
  color: black;
`;
const BillingInformationValue = styled.div`
  flex: 2;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #2a2e45;
`;

const OrderId = styled.p`
  font-weight: 300;
  font-size: 12px;
  color: #748aa1;
`;

const BreakLine = styled.div`
  width: 100%;
  height: 0;
  border: 0.5px dashed #a5b4cc;
  margin: 10px 0;
`;

const MapDetails = styled.div`
  height: 150px;
  @media (min-height: 705px) {
    height: 90px;
  }
`;

const OrderProcessingStatus = ({
  status,
  order,
  currency,
  reset,
  heightToRemove,
}) => {
  const pageInfoV1 = useSelector((state) => state.shopping.pageInfo);
  const paymentMethod = useSelector((state) => state.shopping.paymentMethod);
  const pageInfoV2 = useSelector((state) => state.facebookPages.activePage);
  let pageInfo = pageInfoV1;
  const dispatch = useDispatch();

  if (pageInfoV1 === undefined) pageInfo = pageInfoV2;

  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes + ' ' + ampm;
  }

  return (
    <PaymentDetailsWrapper heightToRemove={heightToRemove}>
      <MapDetails>
        <div
          style={{
            position: 'absolute',
            width: '50%',
            display: 'flex',
            justifyContent: 'space-between',
            zIndex: 999,
            margin: '10px 0 0 10px',
          }}
        >
          {status !== PAYMENT_STATUS.WAITING_CONFIRMATION && (
            <h3
              style={{ cursor: 'pointer', fontWeight: 700, fontSize: '16px', padding: '10px' }}
              onClick={() => {
                reset && reset();
              }}
            >
              Go Home
            </h3>
          )}
          <h3 style={{ cursor: 'pointer', fontWeight: 700, fontSize: '16px', padding: '10px' }}>
            {pageInfo.pageName}
          </h3>
        </div>
        <LocationPicker
          pageInfo={pageInfo}
          location={order.orderLocation}
          onLocationPicked={(location) => {
            dispatch(setOrderLocation(location));
          }}
          zones={pageInfo.geoRegions}
          containerStyles={{ position: 'absolute', height: '170px' }}
        />
      </MapDetails>
      <StyledHeader
        style={
          status === PAYMENT_STATUS.WAITING_CONFIRMATION
            ? { marginTop: 12 }
            : { flexDirection: 'column' }
        }
      >
        <PaymentStatusTitle color={'black'}>
          {pageInfo.pageName}
          <OrderId>Order ID: {order.id}</OrderId>
        </PaymentStatusTitle>
        <BreakLine />
      </StyledHeader>
      <BillingInformation>
        <BillingInformationRow
          span={24}
          style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 8 }}
        >
          <div>
            <BillingInformationLabel>Order status:</BillingInformationLabel>
            <BillingInformationValue
              style={
                status === PAYMENT_STATUS.PAYMENT_SUCCEEDED
                  ? { color: '#2ECC71' }
                  : { color: '#FF3A44' }
              }
            >
              {status === PAYMENT_STATUS.WAITING_CONFIRMATION && 'Waiting Confirmation'}
              {status === PAYMENT_STATUS.ORDER_REJECTED && 'Order Rejected'}
              {status === PAYMENT_STATUS.PAYMENT_SUCCEEDED &&
                'Order accepted at ' + formatAMPM(new Date())}
            </BillingInformationValue>
          </div>
          {status === PAYMENT_STATUS.PAYMENT_SUCCEEDED ? (
            <Icons.OrderAccepted />
          ) : (
            <Icons.OrderDeclined />
          )}
        </BillingInformationRow>
        <BillingInformationRow
          span={24}
          style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 8 }}
        >
          <div>
            <BillingInformationLabel>Order Preparation:</BillingInformationLabel>
            <BillingInformationValue style={{ color: '#748AA1' }}>
              In Process...
            </BillingInformationValue>
          </div>
          <Icons.OrderPreparation />
        </BillingInformationRow>
        <BillingInformationRow
          span={24}
          style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 8 }}
        >
          <div>
            <BillingInformationLabel style={{ color: '#748AA1' }}>
              Order Delivery:
            </BillingInformationLabel>
            <BillingInformationValue style={{ color: '#748AA1' }}>Waiting</BillingInformationValue>
          </div>
          <Icons.OrderDelivery />
        </BillingInformationRow>
        <BreakLine />
        <BillingInformationRow
          span={24}
          style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 8 }}
        >
          <div>
            <BillingInformationLabel>Deliver Address:</BillingInformationLabel>
            <BillingInformationValue>{order.orderLocation.address}</BillingInformationValue>
          </div>
          <Icons.LocationPin />
        </BillingInformationRow>
        <BillingInformationRow
          span={24}
          style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 8 }}
        >
          <div>
            <BillingInformationLabel>Payment Method:</BillingInformationLabel>
            {paymentMethod.methodId === 3 ? (
              <BillingInformationValue>POK</BillingInformationValue>
            ) : (
              <BillingInformationValue>Cash on delivery</BillingInformationValue>
            )}
          </div>
          <Icons.PaymentMethodV2 />
        </BillingInformationRow>
      </BillingInformation>
      <BreakLine />
      <StyledBody>
        <OrderRow
          style={{
            paddingBottom: 8,
            position: 'sticky',
            bottom: 0,
            backgroundColor: '#fff',
          }}
        >
          <OrderRow
            style={{
              borderTop: '1px solid #F9FAFC',
              padding: '10px 0',
              margin: '0 20px',
              width: 'calc(100% - 40px)',
              flexDirection: 'row',
            }}
          >
            <ProductName>Item subtotal:</ProductName>
            <ProductPrice>{currencyFormatter(order.totalValue, currency)}</ProductPrice>
          </OrderRow>
          <OrderRow
            style={{
              borderTop: '1px solid #F9FAFC',
              padding: '10px 0',
              margin: '0 20px',
              width: 'calc(100% - 40px)',
              flexDirection: 'row',
            }}
          >
          </OrderRow>
          <OrderRow
            style={{
              borderTop: '1px solid #F9FAFC',
              padding: '10px 0',
              margin: '0 20px',
              width: 'calc(100% - 40px)',
              flexDirection: 'row',
            }}
          >
            <ProductName>Delivery Fee:</ProductName>
            <ProductPrice>{currencyFormatter(order.deliveryFee, currency)}</ProductPrice>
          </OrderRow>
          <OrderRow style={{ flexDirection: 'row', width: 'calc(100% - 40px)' }}>
            <ProductTotal>Total:</ProductTotal>
            <ProductPrice style={{ fontWeight: 'bold' }}>
              {currencyFormatter(order.total, currency)}
            </ProductPrice>
          </OrderRow>
        </OrderRow>
      </StyledBody>
    </PaymentDetailsWrapper>
  );
};

export const AwaitingConfirmation = (props) => (
  <OrderProcessingStatus status={PAYMENT_STATUS.WAITING_CONFIRMATION} {...props} />
);

export const OrderCancelled = (props) => (
  <OrderProcessingStatus status={PAYMENT_STATUS.ORDER_REJECTED} {...props} />
);

export const PaymentSucceeded = (props) => {
  const { geoRegion } = props;
  const clientInfo = useSelector((state) => state.shopping.client);

  let deliveryMins = null;
  if (geoRegion && geoRegion.deliveryTime) {
    deliveryMins = moment.duration(geoRegion.deliveryTime, 'HH:mm:ss').asMinutes();
  }
  return (
    <OrderProcessingStatus
      status={PAYMENT_STATUS.PAYMENT_SUCCEEDED}
      {...props}
      clientInfo={clientInfo}
      deliveryMins={deliveryMins}
    />
  );
};

export const PaymentDeclined = ({ onRetry, changeToCash }) => {
  return (
    <PaymentDetailsWrapper>
      <StyledHeader style={{ flexDirection: 'column' }}>
        <PaymentStatusTitle color="#FF3A44" style={{ marginTop: '20px' }}>
          Payment Declined
        </PaymentStatusTitle>
        <PaymentStatusSubtitle>There was an issue with the payment process.</PaymentStatusSubtitle>
      </StyledHeader>
      <StyledBody>
        <Section>
          <SectionTitle style={{ marginBottom: '10px', fontWeight: 700 }}>
            Main Reasons
          </SectionTitle>
          <span style={{ display: 'block', fontSize: '14px', lineHeight: '26px' }}>
            Not enough credit in the bank account
          </span>
          <span style={{ display: 'block', fontSize: '14px', lineHeight: '26px' }}>
            Wrong credentials
          </span>
          <span style={{ display: 'block', fontSize: '14px', lineHeight: '26px' }}>
            Connection problems
          </span>
        </Section>
        <Section></Section>
      </StyledBody>
      <StyledFooter>
        <FooterActionsWrapper vertical>
          <BackButton
            style={{
              boxShadow: 'none',
              backgroundColor: 'transparent',
              color: '#3185FC',
              minHeight: '60px',
              height: 60,
            }}
            text="Try Again"
            onClick={onRetry}
          />
          <BlackButton
            onClick={changeToCash}
            style={{
              width: '100%',
              minHeight: '60px',
              height: 60,
              fontSize: 16,
              fontWeight: 'bold',
              color: '#FFFFFF',
              borderRadius: 0,
              bottom: 0,
              position: 'sticky',
            }}
            text="Pay on Delivery"
          />
        </FooterActionsWrapper>
      </StyledFooter>
    </PaymentDetailsWrapper>
  );
};
