const initialState = {
  token: null
}

export default (state = initialState, action) => {
    switch(action.type) {
      case 'LOGIN_USER_OK':
      return {
        ...state,
        token: action.token
      }
      default:
        return state
    }
}
