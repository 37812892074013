import React, { useState } from 'react';
import { connect } from 'react-redux';
import shared from 'app/shared';
import { Metric } from '../components/statisticsBar';
import styled from 'styled-components';
import { Autocomplete } from 'app/orders/views/autocomplete';
import { AutocompleteInput } from 'app/shared/views/searchInput';
import { CHARACTER_LIMIT } from 'app/marketing/constants';
import Spinner from 'app/shared/views/spinner';
import { sendSms } from 'actions/marketingActions';
import { toast } from 'react-toastify';

const SUCCESS_MESSAGE = 'Your SMS message was successfully sent.';
const ERROR_MESSAGE = 'Your SMS message could not be delivered.';

const {
  views: {
    Modal,
    AddItemPopup,
    Alert,
    Icons,
    InputArea,
    Text: {
      Link: { Link },
    },
    Button,
  },
  helpers: { colors },
} = shared;

const Title = styled.span`
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #2c98f0;
`;

const StyledLink = styled(Link)`
  display: inline-flex;
  font-size: 12px;
`;

const AddMessengerLink = ({ onClick, disabled = false }) => (
  <StyledLink onClick={onClick} disabled={disabled}>
    <Icons.Link style={{ marginRight: '5px' }} />
    Add Messenger Link
  </StyledLink>
);

const Placeholder = styled.div`
  color: #bfc4d1;
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  user-select: none;
`;

const InputAreaEndBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 4px 15px 15px 15px;
`;

const NewMessagePopup = ({ show, toggle, smsCredits, activePage, sendSms, ...props }) => {
  const [requestStatus, setRequestStatus] = useState('idle');
  const [selectedClient, setSelectedClient] = useState(null);
  const [inputText, setInputText] = useState('');
  const placeholder = `${CHARACTER_LIMIT - inputText.length} Characters Left`;

  const updateText = (text) => {
    if (text.length <= CHARACTER_LIMIT) setInputText(text);
  };

  const addMessengerLink = () => {
    const newMsgContent = inputText + `{{messenger_link}}`;
    updateText(newMsgContent);
  };

  const handleSubmit = () => {
    sendSms(selectedClient?.id, inputText, (status, err, res) => {
      setRequestStatus(status);
      console.log(err, res);
      if (status === 'success') {
        toast.success(SUCCESS_MESSAGE);
        setTimeout(() => toggle(), 2000);
        updateText('');
      }
    });
  };

  const isSubmitting = requestStatus === 'loading';
  const emptyInputs = inputText === '' || selectedClient == null;

  const sendButtonText = isSubmitting ? (
    <>
      <div>
        <Spinner size={40} />
      </div>
    </>
  ) : (
    'Send'
  );

  return (
    <Modal show={show} toggle={toggle}>
      <AddItemPopup
        title={<Title>Create Message</Title>}
        actions={[
          () => (
            <Button.Button
              color={colors.blue}
              text={sendButtonText}
              onClick={handleSubmit}
              loading={isSubmitting ? 'yes' : undefined}
              disabled={emptyInputs}
            />
          ),
          () => <Button.WhiteButton text={'Cancel'} onClick={toggle} disabled={isSubmitting} />,
        ]}
        style={{
          width: '450px',
        }}
        HeaderComponent={() => (
          <Metric label={'Available Credits'} value={smsCredits} style={{ marginRight: 0 }} />
        )}
        headerHeight={'80px'}
      >
        <Alert type="success" show={requestStatus === 'success'} message={SUCCESS_MESSAGE} />
        <Alert type="error" show={requestStatus === 'error'} message={ERROR_MESSAGE} />
        <AddItemPopup.InputRow>
          <Autocomplete
            CustomInput={AutocompleteInput}
            index={0}
            id={'clientName'}
            name={'clientName'}
            labelText={'To'}
            suggestions={props.clients.data}
            suggestionField={'fullName'}
            labelField={'fullName'}
            finalValue={setSelectedClient}
            selected={selectedClient}
            disabled={isSubmitting}
          />
        </AddItemPopup.InputRow>
        <AddItemPopup.InputRow>
          <InputArea
            labelText={'Content'}
            value={inputText}
            onChange={(e) => updateText(e.target.value)}
            rows={11}
            end={
              <InputAreaEndBar>
                <Placeholder>{placeholder}</Placeholder>
                <AddMessengerLink onClick={() => addMessengerLink()} />
              </InputAreaEndBar>
            }
            disabled={isSubmitting}
          />
        </AddItemPopup.InputRow>
      </AddItemPopup>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  smsCredits: state.facebookPages.activePage?.smsCredits ?? 0,
  clients: state.clients,
  activePage: state.facebookPages.activePage,
});
export default connect(mapStateToProps, { sendSms })(NewMessagePopup);
