import { useEffect, useRef } from 'react';
import colors from 'app/shared/helpers/colors';

export const POLYGON_OPTIONS = {
  strokeColor: colors.blue,
  strokeOpacity: 0.8,
  strokeWeight: 1,
  fillColor: colors.blue,
  fillOpacity: 0.2,
};

export const getVertex = function (latLng) {
  return {
    lat: latLng.lat(),
    lng: latLng.lng(),
  };
};

export const getVertices = function (path) {
  return path.map(getVertex);
};

export const ZonePolygon = ({
  google,
  map,
  name,
  paths,
  hover,
  onMouseOver,
  onMouseOut,
  onClick,
}) => {
  const polygonRef = useRef(new google.maps.Polygon());
  const markerRef = useRef(new google.maps.Marker());

  useEffect(() => {
    const polygon = polygonRef.current;
    const marker = markerRef.current;
    const mouseOverListener = google.maps.event.addListener(polygon, 'mouseover', onMouseOver || (_ => null));
    const mouseOutListener = google.maps.event.addListener(polygon, 'mouseout', onMouseOut || (_ => null));
    const onClickListener = google.maps.event.addListener(polygon, 'click', onClick || (_ => null));
    return () => {
      google.maps.event.removeListener(mouseOverListener);
      google.maps.event.removeListener(mouseOutListener);
      google.maps.event.removeListener(onClickListener);
      polygon.setMap(null);
      marker.setMap(null);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getBounds = function (path) {
      const bounds = new google.maps.LatLngBounds();
      path.forEach((latLng) => bounds.extend(latLng));
      return bounds;
    };

    const polygonOptions = hover
      ? {
          ...POLYGON_OPTIONS,
          fillOpacity: 0.6,
          strokeOpacity: 1,
          strokeWeight: 2.5,
        }
      : POLYGON_OPTIONS;

    const polygon = polygonRef.current;
    polygon.setOptions({
      ...polygonOptions,
      map,
      google,
      paths,
    });

    const marker = markerRef.current;
    marker.setOptions({
      map: map,
      position: getBounds(polygon.getPath()).getCenter(),
      label: {
        text: name,
        fontSize: hover ? '18px' : '16px',
        fontWeight: 'bold',
        fontFamily: "'Mulish', sans-serif",
        color: colors.blue,
      },
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        fillOpacity: 0,
        strokeWeight: 0,
      },
    });
  }, [map, google, paths, name, hover]);

  return null;
};

export default ZonePolygon;
