export const getSortState = (prevState = null, key = null) => {

    if(prevState === null || key === null)
        return{
            key: 'id',
            order: 1,
        }

    if(prevState.key === key)
        return {
            key: key,
            order: -1 * prevState.order
        }
    
    return{
        key: key,
        order: 1,
    }
}

export const sortData = (data = [], sortState = {key: 'id', order: 1}) => {

    const{key, order} = sortState;

    if(data.length === 0) return [];

    return data.slice().sort((firstElement, secondElement) => {

        if(firstElement[key] === undefined || firstElement[key] === null || secondElement[key] === undefined || secondElement[key] === null)
            return -1;

        if(!(isNaN(firstElement[key]) || isNaN(secondElement[key])))
            return (firstElement[key] - secondElement[key]) * order;
        
        const date1 = new Date(firstElement[key]);
        const date2 = new Date(secondElement[key]);

        if(!(date1.toString() === 'Invalid Date' || date2.toString() === 'Invalid Date'))
            return (date1 - date2) * order;

        return order * (firstElement[key].toString().toLowerCase() > secondElement[key].toString().toLowerCase() ? 1 : -1); 
    });
}