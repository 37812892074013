import Url from '../config/api';
import { buildAxiosInstance } from 'actions/common';

const { baseUrl } = Url;

const axiosClient = buildAxiosInstance(baseUrl);

const baseURI = `${baseUrl}/Notification`;

export const getNotifications = (setStatus = null) => {
  return (dipatch) => {
    axiosClient
      .get(`${baseURI}`)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
          dipatch({
            type: 'GET_NOTIFICATIONS_OK',
            data: res.data,
          });
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
        dipatch({ type: 'GET_NOTIFICATIONS_ERROR' });
      });
  };
};

export const markAsRead = (id, setStatus = null) => {
  return (dispatch, getState) => {
    const notification = getState().notifications.data.find((x) => x.id === id);
    if (notification && notification.status !== 'READ') {
      axiosClient
        .put(`${baseURI}/${id}`)
        .then((res) => {
          if (res.status >= 200 && res.status <= 300) {
            setStatus && setStatus('OK');
          }
        })
        .catch(() => {
          setStatus && setStatus('ERROR');
        });
    }

    dispatch({
      type: 'MARK_AS_READ_OK',
      id: id,
    });
  };
};

export const markAllRead = (setStatus = null) => {
  return (dispatch) => {
    dispatch({ type: 'MARK_ALL_READ_OK' });
    axiosClient
      .put(`${baseURI}/MarkAllRead`)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
      });
  };
};

export const createNotification = (pageId, itemId, notificationType, setStatus) => {
  return (dispatch) => {
    axiosClient
      .post(`${baseURI}/AddNotification`, {
        pageId,
        itemId,
        notificationType,
      })
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
          dispatch({
            type: 'NOTIFICATION_CREATED',
            data: res.data,
          });
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
      });
  };
};
