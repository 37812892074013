export const getClientFullName = (orderDetails) => {
  if (!orderDetails) return null;
  const { clientName, clientLastName } = orderDetails;
  if (!clientName) return clientLastName;
  if (!clientLastName) return clientName;
  return clientName + ' ' + clientLastName;
};

export const isTableClient = (client) => {
  const hasNoInfo =
    !client.name &&
    !client.surname &&
    !client.email &&
    (!client.phone || client.phone === '+null') &&
    !client.location;
  const isTableClient = client.fullName === 'Table Client' || hasNoInfo;
  return isTableClient;
};
