import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from 'app/shared/views/modal';
import RadioView from 'app/shared/views/radioView';

import ChoosePlanStep from './choosePlan';
import PaymentStep from './payment';

const UPGRADE_STEPS = ['choosePlan', 'payment'];

const UpgradeModal = ({ show, close }) => {
  const [step, setStep] = useState(0);
  const [chosenPlan, setChosenPlan] = useState({});

  useEffect(() => {
    setStep(0);
  }, [show]);

  const nextView = (chosenPlan) => {
    setStep(step + 1);
    setChosenPlan(chosenPlan);
  };

  return (
    <Modal show={show} toggle={close}>
      <ModalContent>
        <RadioView selectedElement={UPGRADE_STEPS[step]}>
          <RadioView.Element Element={ChoosePlanStep} value={UPGRADE_STEPS[0]} nextContent={nextView} />
          <RadioView.Element Element={PaymentStep} value={UPGRADE_STEPS[1]} chosenPlan={chosenPlan} />
        </RadioView>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  width: 450px;
  padding: 32px 50px 32px 50px;
  box-sizing: border-box;
`;

export default UpgradeModal;
