import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import shared from 'app/shared/';
import { Prompt } from 'react-router-dom';
import SubscribeSettings from './subscribe.settings';
import ProfileSettings from './profile.settings';
import ChatbotSettings from 'app/settings/views/online.settings';
import TableOrderSettings from 'app/settings/views/tableOrders.settings';
import UsersSettings from 'app/settings/views/users.settings';
import NotificationSettings from 'app/settings/views/notification.settings';
import ReceiptSettings from 'app/settings/views/receipt.settings';
import PokSettings from 'app/settings/views/pok.settings';
import IFrameSettings from 'app/settings/views/iframe.settings';
import AggregatorSettings from 'app/settings/views/aggregator.settings';
import {
  IsOnlineOrderActive,
  IsTableOrderActive,
  IsReservationActive,
  IsDeliveryActive,
} from 'selectors/pageServiceSelector';
import VisibilitySettings from 'app/settings/views/visibility.settings';

const {
  views: { Button, TabBar },
  helpers: { colors },
} = shared;

const SettingsBody = styled.div`
  margin-top: 2.455%;
  background: ${colors.white};
  display: flex;
  border: 1px solid #e9eaf1;
  box-sizing: border-box;
  border-radius: 3px;
  flex-direction: column;
`;

const initTabs = [
  {
    text: 'Profile',
    value: 'profile',
    component: ProfileSettings,
  },
  {
    text: 'POK',
    value: 'pok',
    component: PokSettings,
  },
  {
    text: 'Subscriptions',
    value: 'subscriptions',
    component: SubscribeSettings,
  },
  {
    text: 'Notifications',
    value: 'notifications',
    component: NotificationSettings,
  },
  {
    text: 'Receipt',
    value: 'receipt',
    component: ReceiptSettings,
  },
  {
    text: 'Digital Menu',
    value: 'iframe',
    component: IFrameSettings,
  },
  {
    text: 'Visibility',
    value: 'visibility',
    component: VisibilitySettings,
  },
];

const SettingsPage = (props) => {
  const { hasTableOrder } = props;
  const [tabs, setTabs] = useState([...initTabs]);

  const [hasChanges, setHasChanges] = useState(false);
  const [currentTab, updateCurrentTab] = useState(tabs[0].value);
  const [executeOnSubmit, setExecuteOnSubmit] = useState(null);

  useEffect(() => {
    const newTabs = [...initTabs];
    if (hasTableOrder) {
      newTabs.push({
        text: 'Table Orders',
        value: 'tableOrders',
        component: TableOrderSettings,
      });
    }
    newTabs.push(
      {
        text: 'Online Orders',
        value: 'chatbot',
        component: ChatbotSettings,
      },
      {
        text: 'Aggregator',
        value: 'aggregator',
        component: AggregatorSettings,
      },
      {
        text: 'Users',
        value: 'users',
        component: UsersSettings,
      },
    );
    setTabs(newTabs);
  }, [hasTableOrder]);

  const ActiveTab = tabs.find((t) => t.value === currentTab).component;
  const updateState = (value) => {
    let proceed = true;
    if (hasChanges) {
      proceed = window.confirm('You have unsaved changes. Are you sure you want to leave?');
    }

    if (proceed) {
      updateCurrentTab(value);
      setExecuteOnSubmit(null);
      setHasChanges(false);
    }
  };

  return (
    <div>
      <div style={{ display: 'inline-flex', width: '100%' }}>
        <div style={{ width: '90%' }}>
          <TabBar
            tabs={tabs}
            selectedElement={currentTab}
            updateSelectedElement={(value) => updateState(value)}
          />
        </div>
      </div>

      <SettingsBody>
        <ActiveTab setExecuteOnSubmit={setExecuteOnSubmit} setHasChanges={setHasChanges}>
          {executeOnSubmit && (
            <Button.Button
              color={colors.blue}
              text="Save"
              size={Button.ButtonSize.extraMedium}
              onClick={executeOnSubmit}
            />
          )}
        </ActiveTab>
        <Prompt
          when={hasChanges}
          message="You have unsaved changes. Are you sure you want to leave?"
        />
      </SettingsBody>
    </div>
  );
};

SettingsPage.path = '/dashboard/settings';

const mapStateToProps = (state) => ({
  hasOnlineOrder: IsOnlineOrderActive(state),
  hasTableOrder: IsTableOrderActive(state),
  hasReservation: IsReservationActive(state),
  hasDelivery: IsDeliveryActive(state),
});

export default connect(mapStateToProps, {})(SettingsPage);
