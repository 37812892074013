import React from 'react';
import { Route } from 'react-router-dom';
import { isAuthenticated } from 'auth';

const GuestRoute = ({ guestRender }) => {
  return (
    <Route
      render={() => {
        if (!isAuthenticated()) {
          return guestRender();
        }
        return null;
      }}
    />
  );
};

export default GuestRoute;
