import moment from 'moment';

export const formatTimespan = (timespan) => {
  const d = moment.duration(timespan);
  if (d.asSeconds() === 0) return '0s';
  const fz = (x) => (x.startsWith('0') ? '' : x);
  return (
    fz(Math.floor(d.asDays()) + 'D') +
    fz(d.hours() + 'h') +
    fz(d.minutes() + 'm') +
    fz(d.seconds() + 's')
  );
};

export default formatTimespan;
