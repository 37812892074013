import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import shared from '../../shared';
import colors from 'app/shared/helpers/colors';

const {
  views: { Input, },
} = shared;

const SuggestionWrapper = styled.div`
  background: white;
  border: 1px solid #e1e6ec;
  box-sizing: border-box;
  border-radius: 3px;
  margin-top: 5px;
  position: absolute;
  width: 100%;
  z-index: 999;
  overflow: hidden;
`;
const SuggestionUl = styled.ul``;

const SuggestionLi = styled.li`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: ${props => props.active ? 500 : 400};
  font-size: 14px;
  line-height: 14px;
  height: 30px;
  border-bottom: 1px solid #e9eaf1;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: ${props => props.active ? colors.blue : 'inherit'};
  color: ${props => props.active ? colors.white : colors.black};
  :hover {
    background-color: ${props => props.active ? colors.blue : colors.lightBlue}
  }
`;

const AutocompleteWrapper = styled.div`
  position: relative;
`;

const NoSuggestionsWrapper = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 23px;
  padding-left: 10px;
  color: ${colors.textColor};
  postition: absolute;
`;

export class Autocomplete extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array),
  };

  static defaultProperty = {
    suggestions: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      activeSuggestion: 0,
      filteredSuggestions: [],
      disabled: false,
      showSuggestions: false,
      userInput: null,
      suggestionId: '',
      suggestionPrice: '',
      suggestionField: props.suggestionField || 'name',
      dirty: false,
    };
  }

  onChange = e => {
    const { suggestions,  } = this.props;
    const userInput = e.currentTarget.value;
    const filteredSuggestions = suggestions.filter(
      suggestion => suggestion[this.state.suggestionField] && suggestion[this.state.suggestionField].toLowerCase().indexOf(userInput.toLowerCase()) > -1,
    );
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: userInput.length > 0,
      userInput: e.currentTarget.value,
      dirty: true,
    });
    if (this.props.onChange) {
      this.props.onChange(e);
    }
    if(this.props.finalValue) {
      this.props.finalValue(null);
    }
  };

  onSuggestionPicked = suggestion => {
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: null,
      dirty: false,
    });

    this.props.finalValue(suggestion);
  };

  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions, } = this.state;
    const mod = (a, b) => ((a % b) + b) % b;
    if (e.keyCode === 13) {
      this.onSuggestionPicked(filteredSuggestions[activeSuggestion]);
    } else if (e.keyCode === 38) {
      this.setState({ activeSuggestion: mod(activeSuggestion - 1, filteredSuggestions.length) });
    } else if (e.keyCode === 40) {
      this.setState({ activeSuggestion: mod(activeSuggestion + 1, filteredSuggestions.length) });
    } else if(e.keyCode === 27) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
      });
      e.preventDefault();
      e.stopPropagation();
    }
  };

  onKeyUp = e => {
    if (e.keyCode === 27) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
      });
      e.preventDefault();
      e.stopPropagation();
    }
  };

  render() {
    const {
      onChange,
      onSuggestionPicked,
      onKeyDown,
      onKeyUp,
      state: { filteredSuggestions, showSuggestions, userInput, suggestionField, activeSuggestion, dirty },
    } = this;
    let suggestionsListComponent;
    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <SuggestionWrapper>
            <SuggestionUl>
              {filteredSuggestions.map((suggestion, index) => {
                return (
                  <SuggestionLi key={suggestion.id} active={activeSuggestion === index} onClick={onSuggestionPicked.bind(null, suggestion)}>
                    {suggestion[suggestionField]}
                  </SuggestionLi>
                );
              })}
            </SuggestionUl>
          </SuggestionWrapper>
        );
      } else {
        suggestionsListComponent = (
          <NoSuggestionsWrapper>No Suggestions</NoSuggestionsWrapper>
        );
      }
    }

    const index = this.props.index;
    const { CustomInput=Input, selected, labelText, labelField, name, value } = this.props;
    return (
      <AutocompleteWrapper>
        <CustomInput
          name={name}
          type="search"
          onChange={onChange}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          disabled={this.props.disabled}
          labelText={index === 0 && labelText}
          value={value ?? userInput ?? selected?.[labelField] ?? ''}
          autoComplete="off"
          selected={selected && !dirty}
        />
        {suggestionsListComponent}
      </AutocompleteWrapper>
    );
  }
}
export default Autocomplete;
