import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Card from '../../card';

import toggleValidator from 'app/shared/validators/toggle.validator';

import hexToRGBA from 'app/shared/helpers/hexToRGBA';
import isEscape from 'app/shared/helpers/isEscape';
import transition from 'app/shared/helpers/transition';
import slideFromBottomAnimation from 'app/shared/helpers/slideFromBottomAnimation';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderVoucherCode } from 'actions/shoppingActions';
import { useRef } from 'react';
import { clearDecorators } from '@storybook/react/dist/client/preview';

const OverlayWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: center;
  background: ${hexToRGBA('#161929', 0.4)};
  width: 100%;
  height: 100%;
  position: fixed;
  transition: ${transition};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.show ? 1 : 0)};
  top: 0;
  left: 0;
  z-index: 9999;
  -webkit-transform: translate3d(0, 0, 0);
  padding: 0 !important;
`;

const CustomCard = styled(Card)`
  box-shadow: 0px 16px 28px rgba(63, 86, 104, 0.1);
  padding: 1rem;
  position: relative;
  overflow: hidden;
  height: 350px;
  border: 0;
  display: flex;
  flex-direction: column;
  border-radius: 20px 20px 0 0;
  z-index: 100;
`;

const ContentWrapper = styled.div`
  transition: 0.4s ease-in-out;
  position: relative;
  max-width: 502px;
  opacity: ${(props) => (props.show ? 1 : 0)};
  animation: ${(props) => (props.show ? slideFromBottomAnimation : '')} 0.4s ease-in-out;
  width: 100%;
  z-index: 100;
`;

const Header = styled.h1`
  font-weight: 800;
  margin: 2rem 0 3rem 0;
  font-size: 1.375rem;
  line-height: 1.65rem;
`;

const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #1d2036;
  margin-bottom: 0.5rem;
`;

const SubTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #748aa1;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
  border: 1px solid #000;
  border-radius: 6px;
  position: relative;
  height: ${(props) => (props.height ? `${props.height}px` : null)};
  background: #f9fafc;

  & > input {
    margin-left: 0.25rem;
    border-radius: inherit;
  }

  & > span {
    margin-right: 1rem;
  }
`;

const Input = styled.input`
  border: none;
  outline: none;
  box-sizing: border-box;
  background: #f9fafc;
  width: 95%;
  height: 54px;
`;

const ValidityLabel = styled.span`
  color: ${(props) => (props.valid ? '#2ECC71' : '#FF3A44')};
`;

const ApplyVoucherButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
  background-color: black;
  border-radius: 10px;
  width 95%;
  height: 60px;
  margin: auto;
  cursor: pointer;
`;

const ApplyVoucherModal = ({ order, show, toggle }) => {
  const dispatch = useDispatch();
  const [voucherCode, setVoucherCode] = useState(order.voucherCode);
  const [codeValidity, setCodeValidity] = useState(false);
  const vouchers = useSelector((state) => state.vouchers).data.filter(
    (voucher) => voucher.isActive && voucher.showOnOnline,
  );
  const inputRef = useRef(null);

  useEffect(() => {
    let timer = setTimeout(() => {
      inputRef && inputRef.current.focus();
    }, 400);

    document.addEventListener('keyup', toggleOnEscape);
    return function cleanup() {
      clearDecorators(timer);
      document.removeEventListener('keyup', toggleOnEscape);
    };
  });

  useEffect(() => {
    const regex = /ORD-\d{4}-\d{2}-\d{2}-\w{5,}/;

    if (regex.test(voucherCode)) {
      for (let voucher of vouchers) {
        const validCode = voucher.voucherCodes.find(
          (code) => code.code === voucherCode && code.isActive,
        );
        if (validCode) {
          setCodeValidity(true);
          return;
        }
      }
    }

    setCodeValidity(false);
  }, [voucherCode, vouchers, setCodeValidity]);

  useEffect(() => {
    codeValidity && dispatch(setOrderVoucherCode(voucherCode));
  }, [voucherCode, codeValidity, dispatch]);

  useEffect(() => {
    document.body.style.overflowY = show ? 'hidden' : 'unset';
    document.body.style.position = show ? 'fixed' : 'unset';
  }, [show]);

  function toggleOnEscape(event) {
    if (event.defaultPrevented) return;
    if (isEscape(event) && show) toggle();
  }

  return (
    <OverlayWrapper show={show} onClick={toggle}>
      <ContentWrapper show={show} onClick={(event) => event.stopPropagation()}>
        <CustomCard>
          <Header>Apply Voucher</Header>
          <Title>Insert the code or scan the qr code</Title>
          <SubTitle>
            Coupons usually have a limited time and can be used one or multiple times throughout
            their availability.
          </SubTitle>
          <InputWrapper>
            <Input
              ref={inputRef}
              value={voucherCode}
              onChange={(event) => setVoucherCode(event.target.value.trim())}
            />
            {voucherCode && (
              <ValidityLabel valid={codeValidity}>
                {codeValidity ? 'Valid' : 'Invalid'}
              </ValidityLabel>
            )}
          </InputWrapper>
          <ApplyVoucherButton onClick={toggle}>Apply Voucher</ApplyVoucherButton>
        </CustomCard>
      </ContentWrapper>
    </OverlayWrapper>
  );
};

ApplyVoucherModal.propTypes = {
  ...toggleValidator.ToggleSchema,
};

export default ApplyVoucherModal;
