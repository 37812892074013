import React from 'react';
import styled from 'styled-components';
import logo from 'assets/icons/logo.svg';
import shared from 'app/shared';
import mediaQueries from 'app/shared/helpers/mediaQueries';
import colors from 'app/shared/helpers/colors';

const FooterWrapper = styled.div`
  background: ${colors.primaryDark};
  margin-top: 85px;

  @media only screen and (min-width: 901px) {
    padding-top: 40px;
    padding-right: ${(props) => (props.openedIframe ? 40 : 100)}px;
    padding-left: ${(props) => (props.openedIframe ? 40 : 100)}px;
    padding-bottom: 25px;
  }

  @media only screen and (max-width: 900px) {
    padding: 20px 20px 25px 20px;
  }
`;

const Logo = styled.img`
  ${mediaQueries.mobile(`
    width: 104px;
    height: 20px;
  `)}
`;

const FooterMenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  @media only screen and (max-width: 740px) {
    flex-direction: column;
    align-items: start;
  }
`;

const FooterMenuList = styled.ul`
  margin-right: ${(props) => props.right || 0}px;
  margin-left: ${(props) => props.left || '0px'};

  @media only screen and (max-width: 768px) {
    margin-bottom: 40px;
  }

  @media only screen and (max-width: 740px) {
    margin-left: 0px;
  }
`;

const FooterMenuListItem = styled.li`
  display: flex;
  align-items: center;
  color: ${(props) => props.color || colors.white};
  font-size: ${(props) => props.size || 12}px;
  font-weight: ${(props) => props.weight || 400};
  margin-bottom: ${(props) => props.margin || '15'}px;
  padding-top: ${(props) => props.padding || 0}px;
  padding-bottom: ${(props) => props.padding || 0}px;
  &:hover {
    color: ${(props) => props.hover};
  }
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;

  @media only screen and (max-width: 530px) {
    font-size: ${(props) => props.mobileSize}px;
  }
`;

const CopyrightWrapper = styled.h2`
  color: ${colors.white};
  font-size: 10px;
  margin-top: 40px;
`;

const LogoWrapper = styled.div`
  margin-top: 15px;

  @media only screen and (max-width: 530px) {
    margin-top: 80px;
  }
`;

const {
  views: { Icons },
} = shared;

const FooterMenu = () => (
  <>
    <LogoWrapper>
      <a href="/">
        <Logo src={logo} width={136} alt={'Orderoo Logo'} />
      </a>
    </LogoWrapper>
    <FooterMenuWrapper>
      {/* <NavbarLink href={'#'}>Online Orders</NavbarLink>
    <NavbarLink href={'#'}>Table Orders</NavbarLink>
    <NavbarLink href={'#'}>Takeaway</NavbarLink>
    <NavbarLink href={'#'}>Reservations</NavbarLink>
    <NavbarLink href={'#'}>POS</NavbarLink>
    <NavbarLink href={'#'}>CRM</NavbarLink>
    <NavbarLink href={'#'}>Marketing</NavbarLink>
    <NavbarLink href={'#'}>About</NavbarLink>
    <NavbarLink href={'#'}>Contact</NavbarLink> 
    <NavbarLink href="tel:+355696031515">
      <PhoneNumber>Call: 0696031515</PhoneNumber>
    </NavbarLink>
    <ul></ul>
    <NavbarLink href={'https://business.orderoo.ai'}>Business</NavbarLink> */}
      <FooterMenuList right={150}>
        <FooterMenuListItem
          size={16}
          mobileSize={20}
          color={colors.primaryBlue}
          weight={600}
          margin={20}
        >
          Contact
        </FooterMenuListItem>
        <a href="tel:+355696031515">
          <FooterMenuListItem mobileSize={16} hover={colors.blue} padding={4}>
            <Icons.PhoneV1 right={5} />
            0696031515
          </FooterMenuListItem>
        </a>
        <a href="mailto:hello@orderoo.ai">
          <FooterMenuListItem mobileSize={16} hover={colors.blue} padding={4}>
            <Icons.EmailV1 right={5} /> hello@orderoo.ai
          </FooterMenuListItem>
        </a>
        <a href="https://www.google.com/maps/place/Orderoo/@41.3141211,19.8027673,15z/data=!4m2!3m1!1s0x0:0x9c4144902836efef?sa=X&ved=2ahUKEwj24Kiyuq70AhXBhv0HHabNBuEQ_BJ6BAg_EAM">
          <FooterMenuListItem mobileSize={16} hover={colors.blue} padding={4}>
            <Icons.AddressV1 right={5} /> Frang Bardhi, Kristal Center, Tirana
          </FooterMenuListItem>
        </a>
      </FooterMenuList>
      <FooterMenuList right={30}>
        <FooterMenuListItem
          size={16}
          mobileSize={20}
          color={colors.primaryBlue}
          weight={600}
          margin={20}
        >
          Social Media
        </FooterMenuListItem>
        <a href="https://www.linkedin.com/company/orderoo-ai/">
          <FooterMenuListItem mobileSize={16} hover={colors.blue}>
            <Icons.Linkedin right={5} />
            Linkedin
          </FooterMenuListItem>
        </a>
        <a href="https://instagram.com/orderoo.al?utm_medium=copy_link">
          <FooterMenuListItem mobileSize={16} hover={colors.blue}>
            <Icons.InstagramV2 right={5} />
            Instagram
          </FooterMenuListItem>
        </a>
        <a href="https://www.facebook.com/orderooai/">
          <FooterMenuListItem mobileSize={16} hover={colors.blue}>
            <Icons.FacebookV2 right={5} />
            Facebook
          </FooterMenuListItem>
        </a>
      </FooterMenuList>
      <FooterMenuList left={'auto'}>
        <FooterMenuListItem
          size={16}
          mobileSize={20}
          color={colors.primaryBlue}
          weight={600}
          margin={20}
        >
          Comming soon in:
        </FooterMenuListItem>
        {/* <a href="https://www.facebook.com/orderooai/"> */}
        <FooterMenuListItem mobileSize={16} hover={colors.blue}>
          <Icons.PlayStore right={5} />
          PlayStore
        </FooterMenuListItem>
        {/* </a>
        <a href="https://www.facebook.com/orderooai/"> */}

        <FooterMenuListItem mobileSize={16} hover={colors.blue}>
          <Icons.AppStore right={5} />
          AppStore
        </FooterMenuListItem>
        {/* </a> */}
      </FooterMenuList>
    </FooterMenuWrapper>
    <CopyrightWrapper>&copy; 2020, Orderoo, All Rights Reserved</CopyrightWrapper>
  </>
);

export default ({ openedIframe }) => (
  <FooterWrapper openedIframe={openedIframe}>
    <FooterMenu />
  </FooterWrapper>
);
