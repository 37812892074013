import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import _ from 'lodash';

import shared from 'app/shared';
// import EventCard from '../components/EventCard';
import PageCard from '../components/PageCard';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Categories from '../components/Categories';
import HorizontalScrollableSpace from '../components/HorizontalScrollableSpace';
import {
  getAggregatorPages,
  getAvailableAggregatorCategories,
  getAggregatorEvents,
} from 'actions/pageActions';

import { startNewOrder } from 'actions/shoppingActions';
import Shopping from './shopping';

import {
  Input,
  // Checkbox
} from 'antd';
import './style.css';

const {
  views: { Icons, Spinner },
  // eslint-disable-next-line
  helpers: { shuffle, isValidHttpUrl },
} = shared;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const EventImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${(props) => (props.isScrollable ? '0px' : '10px 20px 10px 0')};

  @media only screen and (max-width: 700px) {
    width: calc(100vw - 60px);
  }
  @media only screen and (min-width: 701px) and (max-width: 900px) {
    width: calc(50vw - 40px);
  }
  @media only screen and (min-width: 901px) {
    width: calc(50vw - 120px);
  }
  @media only screen and (min-width: 1200px) {
    width: calc(100vw / 3 - 100px);
  }
`;

const PageWrapper = styled.div`
  flex: 1;
  // transition: all 1s;
  max-width: ${({ openedIframe }) => (!!openedIframe ? 'calc(100vw - 502px)' : '100vw')};
  @media only screen and (max-width: 992px) {
    display: ${({ openedIframe }) => (!!openedIframe ? 'none' : 'block')};
  }

  overflow: hidden;
  overflow-y: scroll;
  height: calc(var(--vh, 1vh) * 100);
`;

// const HeaderContainer = styled.div`
//   position: sticky;
//   background: #fafafa;
//   z-index: 10;
// `;

const StickyWrapper = styled.div`
  position: sticky;
  top: -1px;
  padding-top: 10px;
  background: #fafafa;
  z-index: 20;
`;

const IFrameContainer = styled.div`
  width: 100%;
  height: calc(var(--vh,1vh) * 100);
  position: relative;
  max-width: 502px;
  flex: ${({ display }) => (!!display ? 1 : 0)} 0.5;
  // transition: all 1s;
  margin 0 auto;
`;

const CategorySectionHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (min-width: 901px) {
    margin: ${({ openedIframe }) => (openedIframe ? '20px 40px 0' : '20px 100px 0')};
  }

  @media only screen and (max-width: 900px) {
    padding: 20px 20px 0;
  }
`;

const CategorySectionHeader = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #2a2e45;
  margin-left: 0px;
`;

const ScrollableCategorySectionHeader = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #2a2e45;
`;

const CategorySectionSeeAll = styled(Link)`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #3185fc;
  cursor: pointer;
`;

const CategorySectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;

  @media only screen and (min-width: 901px) {
    padding: ${({ openedIframe }) => (openedIframe ? ' 0 40px' : ' 0 100px')};
  }
  @media only screen and (max-width: 900px) {
    padding: 0 20px;
  }
`;
const SearchWrapper = styled.div`
  @media only screen and (min-width: 901px) {
    padding: ${({ openedIframe }) => (openedIframe ? '0 40px' : '0 100px')};
  }

  @media only screen and (max-width: 900px) {
    padding: 0 20px;
  }
`;

const Search = styled(Input)`
  background: #ffffff;
  border-radius: 20px;

  box-shadow: none;
  & > input {
    border: 0px;
    border-radius: 0px;
    background-color: transparent;
    box-shadow: none;
    padding-right: 0 48px !important;
  }
  & > input:focus {
    box-shadow: none;
  }
`;

const CategoryContainer = styled.div`
  padding: 10px 0;
`;

const EventImage = styled.img`
  width: 100%;
  height: 238px;
  display: flex;
  border-radius: 2px;
  object-fit: cover;
  flex-wrap: nowrap;
  overflow: auto hidden;
  position: relative;
  scroll-behavior: smooth;
  cursor: pointer;
`;

const NoResultsMessage = styled.div`
  margin-top: 20px;
  @media only screen and (min-width: 901px) {
    padding: ${({ openedIframe }) => (openedIframe ? '0 40px' : '0 100px')};
  }

  @media only screen and (max-width: 900px) {
    padding: 0 20px;
  }
`;

// const FilterText = styled.span`
//   font-family: Inter;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   color: #5c677b;
// `;

// const CustomCheckbox = styled(Checkbox)`
//   & .ant-checkbox-checked::after {
//     border-color: ${(props) => props.backgroundColor};
//   }
//   & .ant-checkbox-checked .ant-checkbox-inner {
//     background-color: ${(props) => props.backgroundColor};
//     border-color: ${(props) => props.backgroundColor};
//   }
// `;

const getPageEventsRoute = (page, category) =>
  `/${category?.name.toLowerCase()}/${page?.shortPageName}/events`;

const getPageDeliveryOrMenuRoute = (page, category) => {
  const hasOnlineOrder = page?.pageServices.find(
    (x) => x.name === 'OnlineOrder' && x.isActive === true,
  );
  const service = hasOnlineOrder ? 'delivery' : 'menu';
  return `/${category?.name.toLowerCase()}/${page?.shortPageName}/${service}`;
};

const AggregatorPage = ({ match }) => {
  let history = useHistory();

  const ALL_CATEGORY = { id: 777, name: 'All' };
  // const ALL_CATEGORY = 777;
  // const PAGE_FILTERS = [
  //   { id: 0, name: 'Delivery', checked: false },
  //   { id: 1, name: 'Takeout', checked: false },
  //   { id: 2, name: 'Events', checked: false },
  //   { id: 3, name: 'Reservations', checked: false },
  // ];
  const dispatch = useDispatch();
  const pages = useSelector((state) => state.aggregator.pages);
  const categories = useSelector((state) => state.aggregator.categories);
  const events = useSelector((state) => state.aggregator.events);

  const [pageNameToShop, setPageNameToShop] = useState(null);

  // const [filters, setFilters] = useState([...PAGE_FILTERS]);

  const [selectedCategory, setSelectedCategory] = useState({ ...ALL_CATEGORY });
  const [searchString, setSearchString] = useState('');
  const [pagesToDisplay, setPagesToDisplay] = useState('');
  const [eventsToDisplay, setEventsToDisplay] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasResults, setHasResults] = useState(false);

  useEffect(() => {
    dispatch(getAvailableAggregatorCategories());
    dispatch(getAggregatorPages());
    dispatch(getAggregatorEvents());
  }, [dispatch]);

  useEffect(() => {
    searchString
      ? setPagesToDisplay([
          ...pages?.filter((p) => p.pageName?.toLowerCase().includes(searchString?.toLowerCase())),
        ])
      : setPagesToDisplay([...pages]);

    searchString
      ? setEventsToDisplay([
          ...events?.filter(
            (p) =>
              p.title?.toLowerCase().includes(searchString?.toLowerCase()) ||
              p.shortPageName?.includes(searchString?.toLowerCase()),
          ),
        ])
      : setEventsToDisplay([...events]);
  }, [searchString, pages, events]);

  useEffect(() => {
    if (pagesToDisplay.length !== 0 || eventsToDisplay.length !== 0) {
      setHasResults(true);
      setLoading(false);
    } else {
      setHasResults(false);
      setTimeout(() => setLoading(false), 5000);
    }
  }, [pagesToDisplay, eventsToDisplay]);

  useEffect(() => {
    const selectedBusiness = match.params.business
      ? pages.find((page) => page.shortPageName === match.params.business.toLowerCase())
      : null;

    const selectedCat = [{ id: 'events', name: 'events' }, ...categories].find(
      (cat) => cat.name.toLowerCase() === match.params.category?.toLowerCase(),
    );

    setPageNameToShop(selectedBusiness?.shortPageName);
    selectedCat && setSelectedCategory(selectedCat);

    // setFilters([
    //   ...PAGE_FILTERS.reduce((acc, filt) => {
    //     acc.push({
    //       ...filt,
    //       checked: match.params.category?.toLowerCase() === filt.name?.toLocaleLowerCase(),
    //     });
    //     return acc;
    //   }, []),
    // ]);
  }, [match.params, categories, pages]);

  let openedIframeHandler = () => {
    openedIframeHandler = !openedIframeHandler;
  };

  return (
    <Container>
      <PageWrapper openedIframe={!!pageNameToShop}>
        <Header openedIframe={!!pageNameToShop} />
        <StickyWrapper>
          <SearchWrapper openedIframe={!!pageNameToShop}>
            <Search
              allowClear
              size="large"
              placeholder="Search for a restaurant..."
              onChange={(e) => setSearchString(e.target.value)}
              suffix={!searchString && <Icons.Search2 fill={'#FFD037'} />}
            />
          </SearchWrapper>
          {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
          <div style={{ marginRight: 8 }}>
            <Icons.Sliders style={{ verticalAlign: 'middle', marginLeft: 12 }} />
            <FilterText style={{ margin: '0 16px' }}>View:</FilterText>
          </div>
          <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
            {filters.map((filter) => (
              <CustomCheckbox
                backgroundColor={'#2ECC71'}
                key={filter.id}
                checked={filter.checked}
                onChange={(e) => {
                  let index = filters.findIndex((f) => f.id === filter.id);
                  filters[index].checked = !filters[index].checked;
                  setFilters([...filters]);
                }}
              >
                <FilterText>{filter.name}</FilterText>
              </CustomCheckbox>
            ))}
          </div>
          <div
            style={{ marginLeft: 8, cursor: 'pointer' }}
            onClick={() => {
              setFilters([...PAGE_FILTERS]);
            }}
          >
            <FilterText style={{ margin: '0 16px' }}>Clear Filters</FilterText>
            <Icons.Revert style={{ verticalAlign: 'middle', marginRight: 12 }} />
          </div>
        </div> */}
          <Categories
            categories={categories}
            events={eventsToDisplay}
            pages={pagesToDisplay}
            selectedCategory={selectedCategory.id}
            openedIframe={!!pageNameToShop}
          />
        </StickyWrapper>
        {(selectedCategory.id === 'events' || selectedCategory.id === ALL_CATEGORY.id) &&
          eventsToDisplay.length !== 0 && (
            <CategoryContainer
              key={'events'}
              openedIframe={!!pageNameToShop}
              whiteBackground={true}
            >
              <CategorySectionHeaderWrapper openedIframe={!!pageNameToShop}>
                <CategorySectionHeader>Events</CategorySectionHeader>
                {selectedCategory.id !== 'events' && (
                  <CategorySectionSeeAll to={'/events'}>See All</CategorySectionSeeAll>
                )}
              </CategorySectionHeaderWrapper>
              {selectedCategory.id === 'events' ? (
                <CategorySectionContainer openedIframe={!!pageNameToShop}>
                  {eventsToDisplay.length === 0 && setHasResults(false)}
                  {shuffle(eventsToDisplay).map((event) => {
                    const page = pages.find((p) => p.id === event.pageId);
                    return (
                      <EventImageContainer key={event.description}>
                        <EventImage
                          src={event.imagePath}
                          alt={event.title}
                          onClick={() => {
                            dispatch(startNewOrder());
                            if (!_.isEmpty(event.customUrl)) {
                              if (isValidHttpUrl(event.customUrl)) {
                                window.location.href = event.customUrl;
                              } else {
                                history.push(event.customUrl);
                              }
                            } else {
                              history.push(getPageEventsRoute(page, selectedCategory));
                            }
                          }}
                        />
                      </EventImageContainer>
                      // <EventCard
                      //   key={event.id}
                      //   onClick={() => {
                      //     dispatch(startNewOrder());
                      //     if (!_.isEmpty(event.customUrl)) {
                      //       if (isValidHttpUrl(event.customUrl)) {
                      //         window.location.href = event.customUrl;
                      //       } else {
                      //         history.push(event.customUrl);
                      //       }
                      //     } else {
                      //       history.push(getPageEventsRoute(page, selectedCategory));
                      //     }
                      //   }}
                      //   title={event.title}
                      //   pageName={page?.pageName}
                      //   bannerURL={event.imagePath}
                      //   date={event.date}
                      //   style={{ width: 'calc(100vw / 3 - 100px)' }}
                      // />
                    );
                  })}
                </CategorySectionContainer>
              ) : (
                <HorizontalScrollableSpace isEventButton openedIframe={!!pageNameToShop}>
                  {eventsToDisplay.length !== 0 ? (
                    shuffle(eventsToDisplay).map((event) => {
                      const page = pages.find((p) => p.id === event.pageId);
                      return (
                        <EventImageContainer key={event.description}>
                          <EventImage
                            src={event.imagePath}
                            alt={event.title}
                            onClick={() => {
                              dispatch(startNewOrder());
                              if (!_.isEmpty(event.customUrl)) {
                                if (isValidHttpUrl(event.customUrl)) {
                                  window.location.href = event.customUrl;
                                } else {
                                  history.push(event.customUrl);
                                }
                              } else {
                                history.push(getPageEventsRoute(page, selectedCategory));
                              }
                            }}
                          />
                        </EventImageContainer>
                        // <EventImage src={event.imagePath} alt={event.title} />
                        // <EventCard
                        //   key={event.id}
                        //   onClick={() => {
                        //     dispatch(startNewOrder());
                        //     if (!_.isEmpty(event.customUrl)) {
                        //       if (isValidHttpUrl(event.customUrl)) {
                        //         window.location.href = event.customUrl;
                        //       } else {
                        //         history.push(event.customUrl);
                        //       }
                        //     } else {
                        //       history.push(getPageEventsRoute(page, selectedCategory));
                        //     }
                        //   }}
                        //   title={event.title}
                        //   pageName={page?.pageName}
                        //   bannerURL={event.imagePath}
                        //   date={event.date}
                        //   style={{ width: 'calc(100vw / 3 - 100px)' }}
                        // />
                      );
                    })
                  ) : (
                    <NoResultsMessage>Your search did not find any events</NoResultsMessage>
                  )}
                </HorizontalScrollableSpace>
              )}
            </CategoryContainer>
          )}
        {categories.map(
          (category) =>
            pagesToDisplay.reduce(
              (acc, p) =>
                p.aggregatorCategories.find((aC) => aC.id === category.id) ? [...acc, p] : acc,

              [],
            ).length !== 0 &&
            category.id !== ALL_CATEGORY.id &&
            (selectedCategory.id === category.id || selectedCategory.id === ALL_CATEGORY.id) && (
              <CategoryContainer key={category.id} openedIframe={!!pageNameToShop}>
                <CategorySectionHeaderWrapper openedIframe={!!pageNameToShop}>
                  <ScrollableCategorySectionHeader>{category.name}</ScrollableCategorySectionHeader>
                  {selectedCategory.id !== category.id && (
                    <CategorySectionSeeAll to={`/${category.name}`}>See All</CategorySectionSeeAll>
                  )}
                </CategorySectionHeaderWrapper>
                {/* {console.log(
                  _(pagesToDisplay)
                    .chain()
                    .includes((page) =>
                      page.aggregatorCategories.includes((aC) => aC.id === selectedCategory.id),
                    )
                    .value(),
                )} */}
                {selectedCategory.id === category.id ? (
                  <CategorySectionContainer openedIframe={!!pageNameToShop}>
                    {shuffle(pagesToDisplay).map(
                      (page) =>
                        page.aggregatorCategories.filter((aC) => aC.id === category.id).length !==
                          0 && (
                          <PageCard
                            {...page}
                            key={page.id}
                            onClick={() => {
                              history.push(getPageDeliveryOrMenuRoute(page, selectedCategory));
                              dispatch(startNewOrder());
                            }}
                          />
                        ),
                    )}
                  </CategorySectionContainer>
                ) : (
                  <HorizontalScrollableSpace openedIframe={!!pageNameToShop}>
                    {shuffle(pagesToDisplay).map(
                      (page) =>
                        page.aggregatorCategories.filter((aC) => aC.id === category.id).length !==
                          0 && (
                          <PageCard
                            {...page}
                            key={page.id}
                            onClick={() => {
                              history.push(getPageDeliveryOrMenuRoute(page, selectedCategory));
                              dispatch(startNewOrder());
                            }}
                          />
                        ),
                    )}
                  </HorizontalScrollableSpace>
                )}
              </CategoryContainer>
            ),
        )}
        {loading && <Spinner size={80} />}
        {!hasResults && <NoResultsMessage>Your search did not find any results</NoResultsMessage>}
        <Footer />
      </PageWrapper>
      <IFrameContainer display={pageNameToShop}>
        {pageNameToShop && (
          <Shopping
            pageName={pageNameToShop}
            close={() => {
              openedIframeHandler();
              history.push(`/${match.params.category}`);
              dispatch(startNewOrder());
            }}
          />
        )}
      </IFrameContainer>
    </Container>
  );
};

export default AggregatorPage;
