import React from 'react';
import shared from 'app/shared';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import productImage from 'assets/images/product_image.png';
import CategoryCard from './categoryCard';
import AddCategoryCard from './addCategoryCard';
import AddCategoryPopup from './addCategoryPopup';
import EditCategory from './editCategory';
import { getAll } from 'actions/crudActions';
import crudTypes from '../../../config/crudTypes';
import { updateCategoryArrangement } from 'actions/categoryAction';
import { paginate } from 'config/helpers/paginator';
import { getSortState, sortData } from 'config/helpers/sorter';
import { filterData } from 'config/helpers/searcher';
import { CSVFileExporter } from 'app/shared/helpers/CSVFileExporter';
import CategoryCsvMapper from './categoryCsvMapper';
import SearchInput from 'app/shared/views/searchInput';
import moment from 'moment';
import { Tabs } from 'antd';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import _ from 'lodash';

const { TabPane } = Tabs;

const {
  views: { Table, Button, Paginator, Page, Toggle, Card, FilterableTableStructure, Grid },
  helpers: { colors, transition },
} = shared;

const {
  StatsSectionWrapper,
  ToggleWrapper,
  ActionBarWrapper,
  SearchWrapper,
  TableWrapper,
  PaginatorWrapper,
} = FilterableTableStructure;

const initialState = {
  sort: {
    key: 'id',
    order: 1,
  },
  search: '',
  showAddPopup: false,
  currentPage: 1,
  extraCatCurrentPage: 1,
  pageSize: 10,
  toggleValue: true,
  getStatus: '',
  categoryType: 0,
  addMainOrExtraCategory: 0,
};

const GridWrapper = styled.div`
  padding-top: 30px;
`;

const TableWrapperTablet = styled(Card)`
  display: none;
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGrey};
  border-radius: 3px;
  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: block;
    margin-top: 25px;
  }
  @media all and (max-width: 768px) {
    display: block;
    margin-top: 25px;
  }
`;

const cardWrapper = (Element) => styled(Element)`
  transition: ${transition};
  transform: translateY(0);

  :hover {
    cursor: pointer;
    transform: translateY(-5px);
  }
`;

const ButtonWrapper = styled.div`
  @media all and (max-width: 768px) {
    display: none;
  }
`;

const CategoryTypeWrapper = styled.div`
  border-bottom: 2px solid ${colors.blue};
  margin-bottom: 28px;
`;
const CategoryTypeText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: ${colors.blue};
  margin: 10px 0;
`;

const AddCategoryCardWrapper = cardWrapper(AddCategoryCard);
const CategoryCardWrapper = cardWrapper(CategoryCard);

const SortableGrid = sortableContainer(({ children }) => {
  return <Grid>{children}</Grid>;
});

SortableGrid.Section = sortableElement(({ children, sizes }) => {
  return <Grid.Section sizes={sizes}>{children}</Grid.Section>;
});
class CategoriesPage extends React.Component {
  state = initialState;

  setGetStatus = (status) => {
    this.setState({ getStatus: status });
  };

  componentWillMount = () => {
    if (this.props.categories.data.length === 0)
      this.props.getAll(crudTypes.category, this.setGetStatus);
  };

  getTableData = (values = []) => ({
    action: this.updateSortState,
    header: [
      {
        label: 'CATEGORY NAME',
        key: 'name',
        sortable: true,
        renderer: ({ name }) => name,
      },
      {
        label: 'NUMBER OF PRODUCTS',
        key: 'productCount',
        sortable: true,
        renderer: ({ productCount }) => (
          <div style={{ textAlign: 'center', width: '35%' }}>
            <Button.Button
              text={`${productCount}`}
              style={{
                backgroundColor: colors.lightBlue,
                textAlign: 'center',
                width: '50%',
                minWidth: '50%',
                padding: '0 auto',
                margin: '0 auto',
                color: colors.blue,
                fontSize: '150%',
                fontWeight: 'bold',
              }}
            />
          </div>
        ),
      },
      {
        label: 'DATE CREATED',
        key: 'creationDate',
        sortable: true,
        renderer: ({ creationDate }) => moment(creationDate).format('YYYY-MM-DD HH:mm'),
      },
      {
        label: 'ACTION',
        width: '70px',
        key: 'id',
        sortable: true,
        renderer: ({ id }) => {
          return (
            <NavLink to={`${EditCategory.path}/${id}`} style={{ textDecoration: 'none' }}>
              <Button.Button
                style={{ fontWeight: 600 }}
                text={'View'}
                size={Button.ButtonSize.medium}
                color={colors.blue}
              />
            </NavLink>
          );
        },
      },
    ],
    values: values,
  });

  getTableDataTablet = (values = []) => ({
    action: this.updateSortState,
    header: [
      {
        label: 'CATEGORY NAME',
        key: 'name',
        sortable: true,
        renderer: ({ name }) => name,
      },
      {
        label: 'PRODUCT COUNT',
        key: 'productCount',
        sortable: true,
        renderer: ({ productCount }) => (
          <div style={{ textAlign: 'center', width: '35%' }}>
            <Button.Button
              text={`${productCount}`}
              style={{
                backgroundColor: '#F6D4FF',
                textAlign: 'center',
                width: '50%',
                minWidth: '50%',
                padding: '0 auto',
                margin: '0 auto',
                color: colors.purple,
                fontSize: '150%',
                fontWeight: 'bold',
              }}
            />
          </div>
        ),
      },
      {
        label: 'ACTION',
        width: '70px',
        key: 'id',
        sortable: true,
        renderer: ({ id }) => {
          return (
            <NavLink to={`${EditCategory.path}/${id}`} style={{ textDecoration: 'none' }}>
              <Button.Button
                style={{ fontWeight: 600 }}
                text={'View'}
                size={Button.ButtonSize.medium}
                color={colors.blue}
              />
            </NavLink>
          );
        },
      },
    ],
    values: values,
  });

  toggleAddPopup = () => this.setState({ showEditPopup: !this.state.showEditPopup });

  setSearchValue = (val) => {
    this.setState({ search: val, currentPage: 1, extraCatCurrentPage: 1 });
  };

  toggle = () => this.setState({ toggleValue: !this.state.toggleValue });

  updateSortState = (key) => {
    this.setState((prevState) => ({
      sort: getSortState(prevState.sort, key),
    }));
  };

  createExportCSV = (data) => {
    return () => {
      CSVFileExporter('categories.csv', CategoryCsvMapper(data));
    };
  };

  updatePaginatorPage = (currentPage) => {
    this.setState({ currentPage });
  };

  updateExtraPaginatorPage = (extraCatCurrentPage) => {
    this.setState({ extraCatCurrentPage });
  };

  updateCategoryType = (categoryType) => {
    this.setState({ categoryType });
  };

  updateAddMainOrExtraCategory = (addMainOrExtraCategory) => {
    this.setState({ addMainOrExtraCategory });
  };

  onSortEnd = ({ oldIndex, newIndex, collection: categoryType }) => {
    this.props.updateCategoryArrangement({
      categoryType,
      oldIndex,
      newIndex,
    });
  };

  render() {
    const {
      state: {
        currentPage,
        extraCatCurrentPage,
        pageSize,
        sort,
        search,
        toggleValue,
        showEditPopup,
        categoryType,
        addMainOrExtraCategory,
      },
      updatePaginatorPage,
      updateExtraPaginatorPage,
      updateCategoryType,
      updateAddMainOrExtraCategory,
      toggle,
      toggleAddPopup,
      createExportCSV,
    } = this;

    const data =
      (this.props.categories.data &&
        this.props.categories.data.length > 0 && [...this.props.categories.data]) ||
      [];

    const noGridView = this.props.categories.data.length > 1000;

    const searchData = filterData(data, search);
    const sortedData = sortData(searchData, sort);

    const mainCategoryData =
      (this.props.categories.data &&
        this.props.categories.data.length > 0 && [
          ...this.props.categories.data.filter((cat) => cat.categoryType === 0),
        ]) ||
      [];

    const mainCategoryDataSearchData = filterData(mainCategoryData, search);
    const mainCategoryDataSortedData = sortData(mainCategoryDataSearchData, sort);
    const mainCategoryDataPaginatedData = paginate(
      mainCategoryDataSortedData,
      currentPage,
      pageSize,
    );

    const extraCategoryData =
      (this.props.categories.data &&
        this.props.categories.data.length > 0 && [
          ...this.props.categories.data.filter((cat) => cat.categoryType === 1),
        ]) ||
      [];
    const extraCategorySearchData = filterData(extraCategoryData, search);
    const extraCategorySortedData = sortData(extraCategorySearchData, sort);
    const extraCategoryPaginatedData = paginate(
      extraCategorySortedData,
      extraCatCurrentPage,
      pageSize,
    );

    return (
      <Page>
        <AddCategoryPopup
          show={showEditPopup}
          toggle={toggleAddPopup}
          selectedCategoryType={addMainOrExtraCategory}
        />
        <StatsSectionWrapper>
          <ActionBarWrapper>
            <ButtonWrapper>
              <Button.Button
                color={colors.blue}
                text={'Add New'}
                onClick={() => {
                  updateAddMainOrExtraCategory(0);
                  toggleAddPopup();
                }}
                style={{ marginRight: 22 }}
              />
              <Button.WhiteButton
                color={colors.textColor}
                text={'Export CSV'}
                onClick={createExportCSV(sortedData)}
              />
            </ButtonWrapper>
            {!noGridView && (
              <ToggleWrapper>
                <Toggle
                  show={toggleValue}
                  toggle={toggle}
                  showLabel={'Grid'}
                  hiddenLabel={'List'}
                />
              </ToggleWrapper>
            )}
            <SearchWrapper>
              <SearchInput search={search} onSearchChanged={(x) => this.setSearchValue(x)} />
            </SearchWrapper>
          </ActionBarWrapper>
          {toggleValue === true && !noGridView ? (
            <GridWrapper>
              <CategoryTypeWrapper>
                <CategoryTypeText>Main Categories</CategoryTypeText>
              </CategoryTypeWrapper>
              <SortableGrid axis={'xy'} onSortEnd={this.onSortEnd} distance={2}>
                {searchData &&
                  searchData
                    .filter((x) => x.categoryType === 0)
                    .map((category, index) => (
                      <SortableGrid.Section
                        disabled={!_.isEmpty(search)}
                        key={category.id}
                        value={category.id}
                        index={index}
                        collection={category.categoryType}
                        sizes={{
                          desktop: 2,
                          tablet: 4,
                        }}
                      >
                        <NavLink
                          to={`${EditCategory.path}/${category.id}`}
                          style={{ textDecoration: 'none' }}
                        >
                          <CategoryCardWrapper
                            title={category.name}
                            totalProducts={category.productCount}
                            photo={(category && category.imagePath) || productImage}
                          />
                        </NavLink>
                      </SortableGrid.Section>
                    ))}

                <Grid.Section
                  sizes={{
                    desktop: 2,
                    tablet: 4,
                  }}
                >
                  <AddCategoryCardWrapper
                    onClick={() => {
                      updateAddMainOrExtraCategory(0);
                      toggleAddPopup();
                    }}
                  />
                </Grid.Section>
              </SortableGrid>

              <CategoryTypeWrapper>
                <CategoryTypeText>Extras Categories (Combo)</CategoryTypeText>
              </CategoryTypeWrapper>
              <SortableGrid axis={'xy'} onSortEnd={this.onSortEnd} distance={2}>
                {searchData &&
                  searchData
                    .filter((x) => x.categoryType === 1)
                    .map((category, index) => (
                      <SortableGrid.Section
                        disabled={!_.isEmpty(search)}
                        key={category.id}
                        value={category.id}
                        index={index}
                        collection={category.categoryType}
                        sizes={{
                          desktop: 2,
                          tablet: 4,
                        }}
                      >
                        <NavLink
                          to={`${EditCategory.path}/${category.id}`}
                          style={{ textDecoration: 'none' }}
                        >
                          <CategoryCardWrapper
                            title={category.name}
                            totalProducts={category.productCount}
                            photo={(category && category.imagePath) || productImage}
                          />
                        </NavLink>
                      </SortableGrid.Section>
                    ))}

                <Grid.Section
                  sizes={{
                    desktop: 2,
                    tablet: 4,
                  }}
                >
                  <AddCategoryCardWrapper
                    onClick={() => {
                      updateAddMainOrExtraCategory(1);
                      toggleAddPopup();
                    }}
                  />
                </Grid.Section>
              </SortableGrid>
            </GridWrapper>
          ) : (
            <div style={{ marginTop: 25 }}>
              <Tabs defaultActiveKey={categoryType} onChange={(key) => updateCategoryType(key)}>
                <TabPane tab="Main Categories" key={0}>
                  <TableWrapper style={{ margin: 0 }}>
                    <Table.TableView
                      data={this.getTableData(mainCategoryDataPaginatedData.current)}
                    />
                  </TableWrapper>
                  <TableWrapperTablet style={{ margin: 0 }}>
                    <Table.TableView
                      data={this.getTableDataTablet(mainCategoryDataPaginatedData.current)}
                    />
                  </TableWrapperTablet>
                  <PaginatorWrapper>
                    <Paginator
                      changePage={updatePaginatorPage}
                      numberOfPages={mainCategoryDataPaginatedData.pages}
                      page={mainCategoryDataPaginatedData.currentPage}
                    />
                  </PaginatorWrapper>
                </TabPane>
                <TabPane tab="Extra Categories" key={1}>
                  <TableWrapper style={{ margin: 0 }}>
                    <Table.TableView data={this.getTableData(extraCategoryPaginatedData.current)} />
                  </TableWrapper>
                  <TableWrapperTablet style={{ margin: 0 }}>
                    <Table.TableView
                      data={this.getTableDataTablet(extraCategoryPaginatedData.current)}
                    />
                  </TableWrapperTablet>
                  <PaginatorWrapper>
                    <Paginator
                      changePage={updateExtraPaginatorPage}
                      numberOfPages={extraCategoryPaginatedData.pages}
                      page={extraCategoryPaginatedData.extraCatCurrentPage}
                    />
                  </PaginatorWrapper>
                </TabPane>
              </Tabs>
            </div>
          )}
        </StatsSectionWrapper>
      </Page>
    );
  }
}

CategoriesPage.path = '/dashboard/categories';

const mapStateToProps = (state) => ({
  categories: state.categories,
});

export default connect(mapStateToProps, { getAll, updateCategoryArrangement })(CategoriesPage);

export { CategoriesPage };
