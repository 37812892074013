import React from 'react';
import styled from 'styled-components';
import FilterBar from 'app/shared/views/filterBar';
import DatePicker from 'app/shared/views/dateRangeFilter';
import SelectOption from 'app/shared/views/selectOption';
import colors from 'app/shared/helpers/colors';

const FilterByPeriodWrapper = styled.label`
  display: inline;
  flex: 1;
  min-width: 96px;
`;

const FilterBarItem = styled.div`
  /* margin-left: 20px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  height: 100%;
  flex: 1;
  padding-right: 8px;
`;

// const FilterBarItemName = styled(FilterBar.Components.Title)`
//   font-size: 13px;
//   line-height: 19px;
//   color: #748AA1;
// `;

const FilterBarSelect = styled(SelectOption)`
  width: ${(props) => (props.width ? `${props.width}px` : null)};
  min-width: 90px;
  flex: 1;
`;

const FilterBarContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-betweeen;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
`;

const FilterBarDatePicker = styled(DatePicker)`
  margin-top: -5px;
`;

const FilterBarSelectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'unset',
    border: 'none',
    boxShadow: 'none',
    fontSize: '13px',
    lineHeight: '16px',
    alignItems: 'flex-start',
    minHeight: '32px',
    color: colors.darkestGrey,
    marginTop: '-5px',
    cursor: 'pointer',
  }),
  option: (styles) => {
    return {
      ...styles,
      fontSize: '13px',
      lineHeight: '16px',
    };
  },
  indicatorSeparator: () => ({ display: 'none' }),
};

const PaymentStatusOptions = [
  { value: 'all', label: 'All' },
  { value: 'pending', label: 'Pending' },
  { value: 'paid', label: 'Paid' },
  { value: 'cancelled', label: 'Cancelled' },
];

const SourceOptions = [
  { value: 'all', label: 'All' },
  { value: 'online', label: 'Online' },
  { value: 'table', label: 'Table' },
];

const PeriodOptions = [
  { value: 'today', label: 'Today' },
  { value: 'yesterday', label: 'Yesterday' },
  { value: 'last-week', label: 'Last week' },
  { value: 'last-month', label: 'Last Month' },
  { value: 'total', label: 'Total' },
];

const SimpleFilterBar = ({
  selectedPeriod,
  updateSelectedPeriod = null,
  clearFilters,
  updateCustomDate,
  customDate,
  selectedPaymentStatus,
  updateSelectedPaymentStatus,
  selectedOrderSource,
  updateSelectedOrderSource,
  showOrderSource,
}) => {
  return (
    <FilterBar>
      <FilterBar.Start>
        <FilterBarContent>
          <FilterBarItem style={{ maxWidth: 216 }}>
            <FilterByPeriodWrapper htmlFor="Period">
              <FilterBar.Components.Title>Filter by Period</FilterBar.Components.Title>
            </FilterByPeriodWrapper>
            <FilterBarSelect
              placeholder="Filter by Period"
              customStyles={FilterBarSelectStyles}
              options={PeriodOptions}
              onChange={(option) => {
                updateSelectedPeriod(option.value);
              }}
              isSearchable={'false'}
              name={'Period'}
              value={PeriodOptions.find((option) => option.value === selectedPeriod) || null}
            />
          </FilterBarItem>
          <FilterBarItem style={{ maxWidth: 216 }}>
            <FilterByPeriodWrapper htmlFor="PaymentStatus">
              <FilterBar.Components.Title>Filter by Status</FilterBar.Components.Title>
            </FilterByPeriodWrapper>
            <FilterBarSelect
              placeholder="Filter By Status"
              customStyles={FilterBarSelectStyles}
              options={PaymentStatusOptions}
              onChange={(option) => {
                updateSelectedPaymentStatus(option.value);
              }}
              isSearchable={'false'}
              name={'PaymentStatus'}
              value={
                PaymentStatusOptions.find((option) => option.value === selectedPaymentStatus) ||
                null
              }
            />
          </FilterBarItem>
          {showOrderSource && (
            <FilterBarItem style={{ maxWidth: 216 }}>
              <FilterByPeriodWrapper htmlFor="OrderSource">
                <FilterBar.Components.Title>Filter by Source</FilterBar.Components.Title>
              </FilterByPeriodWrapper>
              <FilterBarSelect
                placeholder="Filter By Source"
                customStyles={FilterBarSelectStyles}
                options={SourceOptions}
                onChange={(option) => {
                  updateSelectedOrderSource(option.value);
                }}
                isSearchable={'false'}
                name={'OrderSource'}
                value={SourceOptions.find((option) => option.value === selectedOrderSource) || null}
              />
            </FilterBarItem>
          )}
        </FilterBarContent>
      </FilterBar.Start>
      <FilterBar.End>
        <FilterBarContent>
          <FilterBarItem>
            <FilterBarDatePicker
              selectedElement={selectedPeriod}
              updateSelectedElement={updateSelectedPeriod}
              updateCustomDate={updateCustomDate}
              customDate={customDate}
              clearFilters={clearFilters}
            />
          </FilterBarItem>
          <FilterBarItem>
            <div style={{ marginLeft: 20 }}>
              <FilterBar.Components.ClearFilters onClick={clearFilters} />
            </div>
          </FilterBarItem>
        </FilterBarContent>
      </FilterBar.End>
    </FilterBar>
  );
};

export default SimpleFilterBar;
