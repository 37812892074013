import React, { useState } from 'react';
import styled from 'styled-components';
import colors from '../../shared/helpers/colors';
import Icons from '../../shared/views/icons';
import mail from '../assets/mail.png';
import HelpCenterContactForm from './helpCenterContactForm';
import shared from 'app/shared';

const {
  views: {
    Page,
    Text: {
      Link: { ExternalLink },
    },
  },
} = shared;

const HelpCenterDropdownBox = styled.div`
  background: #ffffff;
  font-size: 14px;
  min-width: 265px;
  position: absolute;
  z-index: 11;
  bottom: 0;
  right: 0;
  display: ${(props) => (props.show ? 'block' : 'none')};
  transition: 0.15s ease-in-out;
  border: 1px solid ${colors.lightGrey};
  box-sizing: border-box;
  box-shadow: 0px 12px 16px rgba(200, 208, 216, 0.3);
  border-radius: 5px;
`;

const DropdownHeader = styled.div`
  padding: 0 20px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 57px;
  width: 100%;
  color: ${colors.blue};
  border-bottom: 1px solid #e9eaf1;

  display: flex;
  align-items: center;
  text-indent: 20px;
`;

const ListElementWrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  list-style: none;
  font-weight: 500;
  padding: 0 20px;
  min-height: 43px;
  border-bottom: 1px solid #e9eaf1;
`;

const ClickableListElementWrapper = styled(ListElementWrapper)`
  transition: 0.15s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: ${colors.lightGrey};
  }
`;

const HelpCenterItemBody = styled.span`
  flex: 1;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${(props) => props.color};
`;

const HelpCenterItem = ({ onClick, children, color, to }) => {
  let item = (
    <ClickableListElementWrapper onClick={onClick}>
      <HelpCenterItemBody color={color ? color : colors.veryDarkGrey}>
        {children}
      </HelpCenterItemBody>
      <Icons.Forward
        width={'10px'}
        height={'10px'}
        fill={colors.veryDarkGrey}
        style={{ position: 'relative', right: '0' }}
      />
    </ClickableListElementWrapper>
  );

  if (to) item = <ExternalLink href={to}>{item}</ExternalLink>;
  return item;
};

const ImgWrapper = styled.div`
  margin: 25px auto;
`;

const HelpCenterImage = ({ onClick, image }) => (
  <ListElementWrapper onClick={onClick}>
    <ImgWrapper>
      <img src={image} alt={'Contact Us'} />
    </ImgWrapper>
  </ListElementWrapper>
);

const HelpCenterDropdown = ({ show, toggle }) => {
  const [showContactForm, setShowContactForm] = useState(false);
  const [contactFormTitle, setContactFormTitle] = useState('');
  const [contactFormKey, setContactFormKey] = useState(Math.random());

  const openContactForm = (e) => {
    setContactFormKey(Math.random());
    setContactFormTitle(e.currentTarget.innerText);
    setShowContactForm(true);
    if (show) toggle();
  };

  const toggleContactForm = () => {
    setShowContactForm(!showContactForm);
  };

  return (
    <Page>
      <HelpCenterContactForm
        key={contactFormKey}
        title={contactFormTitle}
        show={showContactForm}
        toggle={toggleContactForm}
      />
      <HelpCenterDropdownBox show={show}>
        <DropdownHeader>
          <Icons.Conversations
            width={'26px'}
            height={'26px'}
            fill={colors.blue}
            style={{ marginRight: '13px' }}
          />{' '}
          Contact Us
        </DropdownHeader>
        <HelpCenterItem onClick={openContactForm}>Make a Suggestion</HelpCenterItem>
        <HelpCenterItem onClick={openContactForm}>Report a Bug</HelpCenterItem>
        <HelpCenterItem onClick={openContactForm}>Ask a Question</HelpCenterItem>
        <HelpCenterItem to={'https://orderoo.ai/faq'}>FAQ</HelpCenterItem>
        <HelpCenterImage cursor={'default'} image={mail} />
        <HelpCenterItem
          color={colors.blue}
          onClick={() => {
            toggle();
          }}
        >
          Minimize
        </HelpCenterItem>
      </HelpCenterDropdownBox>
    </Page>
  );
};

export default HelpCenterDropdown;
