import React from 'react';
import styled from 'styled-components';
import shared from 'app/shared';

const {
  views: { Card, Text, Icons },
  helpers: { colors },
} = shared;

const{
  Paragraph:{P,},
  H5,
} = Text;

const ProductCardWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 8px;
  min-width: 132px !important;
  height: 100%;
  justify-content: space-between;
`;

// const PhotoContainer = styled.div`
//   height: 130px;
//   background: url(${props => props.photo}) center center;
//   background-size: cover;
//   border-top-left-radius: 3px;
//   border-top-right-radius: 3px;
// `;

// const InfoWrapper = styled.div`
//   padding: 5px 10px 10px 10px;
//   border-top: 1px solid ${colors.lightGrey};
//   box-sizing: border-box;
// `;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled(H5)`
  color: ${colors.blue};
  font-weight: 600;
  line-height: 22px;
`;

const Label = styled(P)`
  font-size: 11px;
  line-height: 22px;
  color: ${colors.textColor};
`;

const Value = styled(P)`
  font-size: 12px;
  line-height: 22px;
`;

const TotalProducts = styled(Value)`
  color: ${colors.purple};
  font-weight: bold;
  font-size: 14px;
`;

const Ellipsis = styled.div`
  display: block;
  display: -webkit-box;
  margin: 0 auto;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProductCard = ({ title, totalProducts, photo, ...rest }) => (
  <ProductCardWrapper {...rest}>
    {/* <PhotoContainer photo={photo} /> */}
    {/* <InfoWrapper> */}
      <Row style={{ marginBottom: 5 }}>
        <Title>
          <Ellipsis>{title}</Ellipsis>
        </Title>
        <Icons.Forward style={{ width: 12, height: 12, marginBottom: 8 }} fill={colors.lightGrey} />
      </Row>
      <Row>
        <Label>Total Products</Label>
        <TotalProducts>{totalProducts}</TotalProducts>
      </Row>
    {/* </InfoWrapper> */}
  </ProductCardWrapper>
);

export default ProductCard;
