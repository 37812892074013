import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { signUp } from 'actions/authActions';
import shared from 'app/shared';
import Url from 'config/api';
import { buildAxiosWithoutToken } from 'actions/common';

import AuthLayout from '../auth.layout';
import PageHeader from '../components/PageHeader';
import SignUpForm from '../components/SignUpForm.js';
import AllSet from '../components/AllSet';

const baseUrl = `${Url.baseUrl}/Captcha`;

const axiosClient = buildAxiosWithoutToken(baseUrl);

const {
  views: {
    Text: {
      Link: { AppLink },
    },
  },
} = shared;

const SignUpPage = ({ signUp }) => {
  const [allSet, setAllSet] = useState(false);
  const [siteKey, setSiteKey] = useState('');

    useEffect(() => {
      axiosClient.get(`${baseUrl}`).then((res) => {
        setSiteKey(res.data.result);
      });
    }, []);


  const onSubmit = (values) => {
    return new Promise((resolve) => {
      signUp({ ...values }, (err) => {
        if (err) {
          const msg = err.message || 'Something went wrong! Please try again.';
          toast.error(msg);
        } else {
          setAllSet(true);
        }
        resolve();
      });
    });
  };

  return (
    siteKey && <GoogleReCaptchaProvider reCaptchaKey={siteKey} scriptProps={{ async: true }}>
      <AuthLayout>
        {allSet ? (
          <AllSet
            message={[
              'Profile successfully created!',
              'To activate your account please check your email.',
            ]}
            footnote={'If there is no new email in inbox, please check the junk folder.'}
          />
        ) : (
          <SignUpWrapper>
            <PageHeader style={{ marginBottom: '10px', marginTop: '-10px' }}>Sign up.</PageHeader>
            <SignUpForm onSubmit={onSubmit} />
            <SigupNav>
              Already have an account?&nbsp;
              <AppLink to='/signin'>Sign In</AppLink>
            </SigupNav>
          </SignUpWrapper>
        )}
      </AuthLayout>
    </GoogleReCaptchaProvider>
  );
};

const SignUpWrapper = styled.div`
  margin-top: 20px;
`;

const SigupNav = styled.div`
  margin-top: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  & a {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
`;


export default connect(state => ({}), {
  signUp,
})(SignUpPage);
