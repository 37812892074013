import React from 'react';
import styled from 'styled-components';
import shared from 'app/shared';

const {
  helpers: { colors },
} = shared;

const BodyWrapper = styled.div`
  width: 100%;
  height: 350px;
  padding: 10px;
`;

const Body = styled.div`
  height: 100%;
  display: flex;
  overflow: auto;
  flex-direction: column;
  justify-content: start;
`;

class MessageBodySection extends React.Component {
  constructor(props) {
    super(props);
    this.section = React.createRef();
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    this.section.current.scrollTop = this.section.current.scrollHeight;
  }

  render() {
    return (
      <BodyWrapper>
        <Body ref={this.section}>
          {this.props.messages.map((e) => {
            return (
              <MessageBodyButton
                key={`${e.campaignId === undefined ? 'inbox' : 'campaign'}-${e.id}`}
                text={e.message}
              />
            );
          })}
        </Body>
      </BodyWrapper>
    );
  }
}

const DivWrapper = styled.div`
  margin-bottom: 5px;
  text-align: center;
  background-color: ${colors.blue};
  max-width: 50%;
  min-width: 3%;
  word-wrap: break-word;
  white-space: normal;
  margin-left: auto;
  padding: 10px;
  border-radius: 25px;
`;

const pStyle = {
  color: 'white',
  fontWeight: '500',
  fontSize: '12px',
  lineHeight: '14px',
  wordWrap: 'break-word',
  whiteSpace: 'normal',
};

class MessageBodyButton extends React.Component {
  render() {
    return (
      <DivWrapper>
        <pre style={pStyle}>{this.props.text}</pre>
      </DivWrapper>
    );
  }
}

export default MessageBodySection;
