import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Table, Select } from 'antd';
import { withRouter, Prompt } from 'react-router-dom';
// import { connect } from 'react-redux';
import { useSelector, useDispatch } from 'react-redux';

import shared from 'app/shared';
import { Formik } from 'formik';
import styled from 'styled-components';
import { getAll } from 'actions/crudActions';
import { connect } from 'react-redux';
import { saveOrder, updateOrderStatus } from 'actions/orderActions';
import crudTypes from '../../../config/crudTypes';
import { CSVFileExporter } from 'app/shared/helpers/CSVFileExporter';
import { refundOrder } from 'actions/orderActions';
import { requestDelivery, orderReady } from 'actions/deliveryActions';
import OrderCsvMapper from './orderCsvMapper';
import currencyFormatter from 'app/shared/helpers/currencyFormatter';
import product_image from '../../../assets/images/product_image.png';
import PrintReceipt from '../../shared/views/printReceipt';
import ProductModal from './editProductModal/editProductModal';
import CurrencyFormatter from 'app/shared/helpers/currencyFormatter';
import { IsDeliveryActive } from 'selectors/pageServiceSelector';

import './style.css';
import { ORDER_STATUS, ORDER_PAYMENT_METHODS } from '../constants';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import Spinner from 'app/shared/views/spinner';
import { getClientFullName } from 'app/shared/helpers/client';

const { Option, OptGroup } = Select;

const {
  views: {
    Page,
    Button: {
      Button,
      WhiteButton,
      ButtonWithIcon,
      AddButton,
      RemoveButton,
      RemoveAllButton,
      GreenButton,
    },
    Icons,
    Alert,
    Text: {
      Link: { Link },
    },
  },
  helpers: { colors },
} = shared;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;
const Flex1 = styled.div`
  flex: 1;
`;

const DetailsWrapper = styled.div`
  padding: 24px 28px;
`;

const ProductImage = styled.img`
  border-radius: 3px;
  height: 20px;
  width: 40px;
  flex: 1;
  margin-left: 28px;
  margin-right: 20px;
  @media all and (max-width: 768px) {
    display: none;
  }
`;

const ProductName = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #3f3d56;
  flex: 3;
`;
const ProductQuantity = styled.div`
  font-family: Inter, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  color: #1d2036;
  text-align: center;
  margin: 0 8px;
`;

const ProductValue = styled.div`
  font-family: Inter, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  color: #2ecc71;
  padding-right: 28px;
  text-align: right;
`;

const Label = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  flex: 1;
  align-items: start;
  color: #748aa1;
  margin-bottom: 10px;
  width: 100%;
`;

const Details = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  flex: 3;
  align-items: start;
  color: #3f3d56;
`;

const OrderPaid = styled.div`
  height: 26px;
  min-width: 120px;
  padding-top: 2px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #2ecc71;
  border-radius: 18px;
  display: inline-block;
  float: right;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0025em;
`;

const OrderPending = styled.div`
  height: 26px;
  min-width: 120px;
  padding-top: 2px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: ${colors.orange};
  border-radius: 18px;
  display: inline-block;
  float: right;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0025em;
`;

const OrderCancelled = styled.div`
  height: 26px;
  min-width: 120px;
  padding-top: 2px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #ff3a44;
  border-radius: 18px;
  display: inline-block;
  float: right;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0025em;
`;

const OrderStatus = ({ activeOrder }) => {
  if (!activeOrder) return null;
  const { orderStatus, paymentMethod } = activeOrder;
  const statusLabel = ORDER_STATUS[orderStatus];
  return orderStatus === 1 || orderStatus === 2 ? (
    <OrderPaid>Paid with {ORDER_PAYMENT_METHODS[paymentMethod]}</OrderPaid>
  ) : orderStatus === 0 || orderStatus === 4 || orderStatus === 6 ? (
    <OrderPending>{statusLabel}</OrderPending>
  ) : (
    <OrderCancelled>{statusLabel}</OrderCancelled>
  );
};

const EditOrderWrapper = styled.div`
  background: ${colors.white};
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px solid #e9eaf1;
  margin-top: 40px;
`;

const OrderDetailsLabel = styled.div`
  flex: 1;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  padding-bottom: 30px;
  color: ${colors.oceanBlue};
  display: flex;
  align-items: center;
  & > * {
    margin-left: 10px;
  }
`;

const EditOrderHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media all and (max-width: 768px) {
    justify-content: space-between;
  }
`;

const EditOrderTitle = styled.div`
  font-size: 20px;
  color: ${colors.black};
  margin-left: 22px;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    margin-right: 22px;
  }
  @media all and (max-width: 768px) {
    display: none;
  }
`;

const EditOrderHeaderLabel = styled.div`
  font-size: 12px;
  color: ${colors.grey};
  & > * {
    margin-left: 30px;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }
  @media all and (max-width: 768px) {
    display: none;
  }
`;

const DateCreatedWrapper = styled.span`
  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }
`;

const ErrorWrapper = styled.div`
  min-height: 24px;
  padding: 5px;
  width: 100%;
  background: rgba(242, 69, 61, 0.2);
  color: #f2453d;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  align-items: center;
  letter-spacing: 1px;
  font-weight: bold;
  display: ${(props) => (props.show === 'ERROR' ? 'flex' : 'none')};
`;

const QuantityWraper = styled.span`
  width: 100%;
  display: inline-flex;
  align-items: center;
  margin: auto;
  justify-content: center;
`;

const Total = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #1d2036;
  text-align: left;
  padding-left: 90px;
`;

const NotificationWrapper = styled.div`
  flex-basis: 100%;
  margin-top: 32px;
`;

const CustomExpandIcon = (props) => {
  if ((props.record.toppings?.length || 0) < 1) return null;
  return (
    <Link onClick={(e) => props.onExpand(props.record, e)} style={{ border: 'none' }}>
      {props.expanded ? <Icons.Remove fill={colors.blue} /> : <Icons.Add fill={colors.blue} />}
    </Link>
  );
};

const EditOrder = (props) => {
  const dispatch = useDispatch();

  const orders = useSelector((state) => state.orders);
  const products = useSelector((state) => state.products);
  const categories = useSelector((state) => state.categories);
  const facebookActivePage = useSelector((state) => state.facebookPages.activePage);
  const pageConfiguration = useSelector((state) => state.pageConfiguration);
  const isDeliveryActive = useSelector(IsDeliveryActive);

  const str = window.location.href;
  const orderCounter = parseInt(str.substring(str.lastIndexOf('/') + 1).trim());
  const filteredOrder = orders?.data.filter((x) => x.orderCounter === orderCounter) || [];

  const activeOrder = filteredOrder.length > 0 ? filteredOrder[0] : null;
  const canEdit = activeOrder?.paymentMethod === 0;

  const fullName = getClientFullName(activeOrder);

  const [updateStatus, setUpdateStatus] = useState('');
  const [driverStatus, setDriverStatus] = useState('');
  const [addProduct, setAddProduct] = useState(false);

  useEffect(() => {
    let updateStatusTimer = setTimeout(() => setUpdateStatus(''), 5000);
    return () => {
      clearTimeout(updateStatusTimer);
    };
  }, [updateStatus]);

  useEffect(() => {
    let driverStatusTimer = setTimeout(() => setDriverStatus(''), 5000);
    return () => {
      clearTimeout(driverStatusTimer);
    };
  }, [driverStatus]);

  useEffect(() => {
    if (!activeOrder) {
      dispatch(getAll(crudTypes.order));
      dispatch(getAll(crudTypes.category));
    }
  }, [dispatch, activeOrder]);

  // useEffect(() => {
  //   if (!activeOrder) {
  //     getAll(crudTypes.order);
  //     getAll(crudTypes.product);
  //     getAll(crudTypes.category);
  //   }
  // }, [getAll, activeOrder]);


  const exportCSV = () => {
    if (activeOrder) {
      CSVFileExporter(`order-${activeOrder.orderCounter}.csv`, OrderCsvMapper([activeOrder]));
    }
  };
  const changeUpdateStatus = (status) => {
    setUpdateStatus(status);
  };

  return !activeOrder ? (
    <Spinner size={80} style={{ height: '100px' }} />
  ) : (
    <Page>
      <Formik
        enableReinitialize
        initialValues={{
          tableData: activeOrder ? [...activeOrder.orderProducts] : [],
        }}
        onSubmit={(values) => {
          const orderToBeSubmitted = { ...activeOrder };
          orderToBeSubmitted.orderProducts = values.tableData;
          dispatch(
            saveOrder(
              orderToBeSubmitted,
              activeOrder.paymentMethod,
              {
                firstName: activeOrder.clientName,
                lastName: activeOrder.clientLastName,
                phone: activeOrder.clientPhone,
              },
              changeUpdateStatus,
            ),
          );
        }}
        // validationSchema={Yup.object().shape({
        //   tableData: Yup.array()
        //     .min(1, 'Product Name is required')
        //     .required('Product Name is required'),
        // })}
      >
        {(innerProps) => {
          const { values, isValid, setFieldValue } = innerProps;
          const getImageURL = (productId) =>
            products.data.find((x) => x.id === productId)?.imagePath;

          const isCombo = (productId) => products.data.find((x) => x.id === productId)?.isCombo;

          const getProduct = (productId) => products.data.find((x) => x.id === productId);

          const columns = [
            {
              title: <Label style={{ marginLeft: 28 }}>Products:</Label>,
              dataIndex: 'product',
              key: 'product',
              width: '30%',
              shouldCellUpdate: true,
              render: (name, record) =>
                record.productId !== 0 ? (
                  <Flex>
                    <Flex1>
                      <ProductImage
                        alt="logo"
                        src={getImageURL(record.productId) || product_image}
                        height="20"
                        width="40"
                      />
                    </Flex1>
                    <ProductName>{record.name}</ProductName>
                  </Flex>
                ) : (
                  <Flex>
                    <Select
                      defaultValue={-1}
                      style={{ minWidth: 240 }}
                      onChange={(prodId) => {
                        const categoryProduct = categories.data.find((category) =>
                          category.magProducts.find((product) => product.id === prodId),
                        ).magProducts;
                        const tableDataRowIndex = values['tableData'].findIndex(
                          (x) => x.productId === 0,
                        );
                        let tableData = [...values['tableData']];
                        let tableDataRow = { ...values['tableData'][tableDataRowIndex] };

                        tableDataRow.productId = prodId;
                        tableDataRow.name = categoryProduct.find((item) => item.id === prodId).name;
                        tableDataRow.price = categoryProduct.find((item) => item.id === prodId).price;
                        tableData[tableDataRowIndex] = tableDataRow;
                        setFieldValue('tableData', tableData);
                      }}
                    >
                      <Option key={-1} value={-1}>
                        Select Product
                      </Option>
                      {categories?.data.map(
                        (cat) =>
                          cat.categoryType === 0 && (
                            <OptGroup key={`${cat.id}${cat.name}`} label={cat.name}>
                              {cat.magProducts &&
                                cat.magProducts.map(
                                  (prod) =>
                                    !innerProps.values.tableData.find(
                                      (element) => element.productId === prod.id,
                                    ) && (
                                      <Option key={prod.id} value={prod.id}>
                                        {prod.name}
                                      </Option>
                                    ),
                                )}
                            </OptGroup>
                          ),
                      )}
                    </Select>
                  </Flex>
                ),
            },
            {
              title: <Label style={{ textAlign: 'center' }}>Quantity:</Label>,
              dataIndex: 'quantity',
              key: 'quantity',
              width: '30%',
              render: (qty, record) => (
                <QuantityWraper>
                  {canEdit && isCombo(record.productId) ? (
                    <GreenButton
                      size={12}
                      text={'Edit'}
                      onClick={() => {
                        setAddProduct({ ...getProduct(record.productId), index: record.id });
                      }}
                      style={{
                        borderRadius: 15,
                        width: 80,
                        height: 20,
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: 12,
                        lineHeight: 0,
                      }}
                    />
                  ) : (
                    <>
                      {canEdit && (
                        <RemoveButton
                          disabled={record.productId === 0}
                          style={{ width: 24, height: 24 }}
                          onClick={() => {
                            const tableDataRowIndex = values['tableData'].findIndex(
                              (x) => x.id === record.id,
                            );
                            let tableData = [...values['tableData']];
                            let tableDataRow = { ...values['tableData'][tableDataRowIndex] };
                            if (tableDataRow.qty > 1) {
                              tableDataRow.qty--;
                            }
                            tableData[tableDataRowIndex] = tableDataRow;
                            setFieldValue('tableData', tableData);
                          }}
                        />
                      )}
                      <ProductQuantity>{record.qty}</ProductQuantity>
                      {canEdit && (
                        <AddButton
                          disabled={record.productId === 0}
                          style={{ width: 24, height: 24 }}
                          onClick={() => {
                            const tableDataRowIndex = values['tableData'].findIndex(
                              (x) => x.id === record.id,
                            );
                            let tableData = [...values['tableData']];
                            let tableDataRow = { ...values['tableData'][tableDataRowIndex] };
                            tableDataRow.qty++;
                            tableData[tableDataRowIndex] = tableDataRow;
                            setFieldValue('tableData', [...tableData]);
                          }}
                        />
                      )}
                    </>
                  )}
                </QuantityWraper>
              ),
            },
            {
              title: (
                <Label style={{ textAlign: 'right', paddingRight: 28, width: '100%' }}>
                  Value:
                </Label>
              ),
              dataIndex: 'value',
              key: 'value',
              width: '30%',
              render: (value, record) => (
                <ProductValue>{currencyFormatter(record.price * record.qty)}</ProductValue>
              ),
            },
            {
              title: '',
              dataIndex: 'id',
              key: 'deleteAction',
              width: '10%',
              render: (id, record) =>
                canEdit && (
                  <RemoveAllButton
                    onClick={() =>
                      setFieldValue(
                        'tableData',
                        values['tableData'].filter((x) => x.id !== record.id),
                      )
                    }
                  />
                ),
            },
          ];
          const footer = () => (
            <>
              {canEdit && (
                <Flex style={{ backgroundColor: '#FFF', height: 44 }}>
                  <WhiteButton
                    disabled={!!values.tableData.find((item) => item.productId === 0)}
                    text={
                      <Flex>
                        <Icons.Plus
                          fill={colors.white}
                          style={{
                            width: 40,
                            height: 24,
                            backgroundColor: colors.blue,
                            borderRadius: 3,
                            marginLeft: 4,
                          }}
                        />
                        <span style={{ color: colors.blue, marginLeft: 20, marginRight: 4 }}>
                          Add Product
                        </span>
                      </Flex>
                    }
                    style={{ marginLeft: 24, border: 'none', minWidth: 164, display: 'flex' }}
                    onClick={() => {
                      let tableData = _.concat(values.tableData, []);
                      tableData.push({
                        id: uuidv4(),
                        name: '',
                        productId: 0,
                        orderId: activeOrder.id,
                        price: 0,
                        qty: 1,
                      });
                      setFieldValue('tableData', [...tableData]);
                    }}
                  />
                </Flex>
              )}
              <Flex style={{ paddingTop: 12 }}>
                <Total style={{ width: '60%' }}>Subtotal:</Total>
                <ProductValue style={{ width: '30%' }}>
                  {currencyFormatter(activeOrder.partialTotal)}
                </ProductValue>
              </Flex>
              <Flex style={{ paddingTop: 12 }}>
                <Total style={{ width: '60%' }}>Delivery fee:</Total>
                <ProductValue style={{ width: '30%' }}>
                  {currencyFormatter(activeOrder.deliveryFee)}
                </ProductValue>
              </Flex>
              <Flex style={{ paddingTop: 12 }}>
                <Total style={{ width: '60%' }}>Total:</Total>
                <ProductValue style={{ width: '30%' }}>
                  {values.tableData.length > 0 &&
                    currencyFormatter(
                      values.tableData.reduce(() => {
                        // const toppingsSum =
                        //   product.toppings?.reduce((acc, obj) => acc + obj.price * obj.qty, 0) || 0;
                        // return acc + product.price * product.qty + toppingsSum;
                        return activeOrder.totalValue;
                      }, 0),
                    )}
                </ProductValue>
              </Flex>
            </>
          );

          return (
            <>
              <EditOrderHeaderWrapper>
                <Link onClick={props.history.goBack}>
                  <Icons.BackProducts fill={colors.blue} />
                </Link>
                <EditOrderTitle>Edit Order</EditOrderTitle>
                <EditOrderHeaderLabel style={{ flex: 1 }}>
                  <DateCreatedWrapper>
                    Order Number: {`${activeOrder && activeOrder.orderCounter}`}
                  </DateCreatedWrapper>
                </EditOrderHeaderLabel>
                <WhiteButton
                  color={colors.textColor}
                  text={'Export CSV'}
                  style={{ marginRight: 22 }}
                  onClick={exportCSV}
                />
                <PrintReceipt
                  order={activeOrder}
                  tableName={activeOrder?.tableName}
                  waiterName={activeOrder?.waiterName}
                  restaurantName={facebookActivePage?.pageName}
                  totalValue={activeOrder && CurrencyFormatter(activeOrder.totalValue)}
                  receiptConfiguration={pageConfiguration.receiptConfiguration}
                />
                {isDeliveryActive &&
                  !activeOrder?.tableId &&
                  activeOrder?.orderStatus !== 5 &&
                  (!activeOrder.requireDelivery ? (
                    <ButtonWithIcon
                      Icon={Icons.BikerHelmet}
                      color={colors.yellow}
                      text={activeOrder.requireDelivery ? 'Delivery Requested' : 'Request Delivery'}
                      onClick={() =>
                        !activeOrder.requireDelivery &&
                        dispatch(requestDelivery(activeOrder.id, setDriverStatus))
                      }
                      disabled={activeOrder.requireDelivery}
                      style={{
                        marginRight: '22px',
                        width: 'unset',
                        fontWeight: 'bold',
                      }}
                    />
                  ) : (
                    <ButtonWithIcon
                      Icon={Icons.Cloche}
                      color={colors.green}
                      text={activeOrder?.orderStatus !== 2 ? 'Order ready' : 'Done'}
                      disabled={activeOrder.orderStatus === 2}
                      onClick={() =>
                        activeOrder?.orderStatus !== 2 &&
                        dispatch(orderReady(activeOrder.id, setDriverStatus))
                      }
                      style={{
                        marginRight: '22px',
                        width: 'unset',
                        fontWeight: 'bold',
                      }}
                    />
                  ))}
                {activeOrder?.orderStatus === 1 || activeOrder?.orderStatus === 2 ? (
                  <Button
                    onClick={() => {
                      if (
                        window.confirm(
                          `Are you sure you want to ${
                            activeOrder.paymentMethod === 0 ? 'reject' : 'reject and refund'
                          } this order?`,
                        )
                      ) {
                        dispatch(refundOrder(activeOrder.id, changeUpdateStatus));
                      }
                    }}
                    text={activeOrder.paymentMethod === 0 ? 'Reject' : 'Reject and Refund'}
                    color={colors.red}
                    style={{
                      marginRight: '22px',
                      width: 'unset',
                      color: colors.white,
                      padding: '0 30px',
                      fontWeight: 'bold',
                    }}
                  />
                ) : null}
                {activeOrder && (activeOrder.orderStatus === 6 || activeOrder.orderStatus === 0) && (
                  <>
                    <Button
                      onClick={() => {
                        dispatch(
                          updateOrderStatus(
                            activeOrder.id,
                            'ACCEPTED',
                            changeUpdateStatus,
                            activeOrder,
                          ),
                        );
                      }}
                      text={'Accept'}
                      color={colors.green}
                      style={{
                        marginRight: '22px',
                        width: 'unset',
                        color: colors.white,
                        padding: '0 30px',
                        fontWeight: 'bold',
                      }}
                    />
                    <Button
                      onClick={() => {
                        if (window.confirm(`Are you sure you want to reject this order?`)) {
                          dispatch(
                            updateOrderStatus(activeOrder.id, 'CANCELLED', changeUpdateStatus, activeOrder),
                          );
                        }
                      }}
                      text={'Reject'}
                      color={colors.red}
                      style={{
                        marginRight: '22px',
                        width: 'unset',
                        color: colors.white,
                        padding: '0 30px',
                        fontWeight: 'bold',
                      }}
                    />
                  </>
                )}
                {canEdit && (
                  <Button
                    color={colors.blue}
                    text={'Save'}
                    onClick={innerProps.handleSubmit}
                    disabled={!isValid || !!values.tableData.find((item) => item.productId === 0)}
                  />
                )}
              </EditOrderHeaderWrapper>
              <NotificationWrapper>
                <Alert show={updateStatus === 'OK'} message="Update successful!" />
                <Alert show={driverStatus === 'OK'} message="Driver is on its way!" />
                <ErrorWrapper show={updateStatus}>
                  <Icons.XIcon fill={colors.red} style={{ marginRight: 10 }} width={16} />
                  <span>Error editing the order</span>
                </ErrorWrapper>
              </NotificationWrapper>
              <EditOrderWrapper>
                <Prompt
                  when={innerProps.dirty}
                  message="You have unsaved changes. Are you sure you want to leave?"
                />
                <DetailsWrapper>
                  <Flex>
                    <OrderDetailsLabel>Order Details</OrderDetailsLabel>
                    <OrderStatus activeOrder={activeOrder} />
                  </Flex>
                  <Flex>
                    <Label>Date/Time Placed:</Label>
                    <Details>
                      {activeOrder && moment(activeOrder.orderDate).format('DD.MM.YYYY hh:mm A')}
                    </Details>
                  </Flex>
                  {fullName && (
                    <Flex>
                      <Label>Client Name:</Label>
                      <Details>{fullName}</Details>
                    </Flex>
                  )}
                  {activeOrder?.clientPhone && (
                    <Flex>
                      <Label>Client Phone Number:</Label>
                      <Details>
                        {activeOrder.orderStatus === 1
                          ? activeOrder.clientPhone
                          : activeOrder.clientPhone.slice(0, -3) + '***'}
                      </Details>
                    </Flex>
                  )}
                  {activeOrder?.tableName ? (
                    <>
                      <Flex>
                        <Label>Table:</Label>
                        <Details>{activeOrder.tableName}</Details>
                      </Flex>
                      {activeOrder?.waiterName && (
                        <Flex>
                          <Label>Waiter:</Label>
                          <Details>{activeOrder.waiterName}</Details>
                        </Flex>
                      )}
                    </>
                  ) : (
                    <>
                      <Flex>
                        <Label>Client Address:</Label>
                        <Details>{activeOrder && activeOrder.orderLocation}</Details>
                      </Flex>
                      <Flex>
                        <Label>Address Details:</Label>
                        <Details>{activeOrder && activeOrder.orderLocation2}</Details>
                      </Flex>
                    </>
                  )}

                  <Flex>
                    <Label>Note:</Label>
                    <Details>{activeOrder && activeOrder.customerDescription}</Details>
                  </Flex>
                </DetailsWrapper>
                <Table
                  name={'tableData'}
                  size={'default'}
                  rowKey={(row) => row.id}
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? 'table-row-dark' : 'table-row-light'
                  }
                  bordered={false}
                  footer={() => footer()}
                  columns={columns}
                  dataSource={innerProps.values.tableData}
                  pagination={!innerProps.values.tableData}
                  loading={!innerProps.values.tableData}
                  expandIcon={(p) => <CustomExpandIcon {...p} />}
                  expandedRowRender={(record) =>
                    record.toppings.length > 0 &&
                    record.toppings.map((topping) => (
                      <div key={record.id} style={{ display: 'flex', flexDirection: 'column' }}>
                        <div key={topping.id} style={{ display: 'flex', flexDirection: 'row' }}>
                          <Flex style={{ width: '30%' }}>
                            <Flex1 style={{ paddingLeft: 8 }}>
                              <ProductImage
                                alt="logo"
                                src={getImageURL(topping.productId) || product_image}
                                height="20"
                                width="40"
                              />
                            </Flex1>
                            <ProductName>{topping.name}</ProductName>
                          </Flex>
                          <div style={{ flex: 1, width: '30%' }}>
                            <ProductQuantity>{topping.qty}</ProductQuantity>
                          </div>
                          <div style={{ width: '30%' }}>
                            <ProductValue>
                              {currencyFormatter(topping.qty * topping.price)}
                            </ProductValue>
                          </div>
                          <div style={{ width: '10%' }} />
                        </div>
                      </div>
                    ))
                  }
                  defaultExpandedRowKeys={innerProps.values.tableData.reduce((prodsId, prod) => {
                    if (prod.toppings?.length > 0) {
                      prodsId.push(prod.id);
                    }
                    return prodsId;
                  }, [])}
                />
                {!!addProduct && (
                  <ProductModal
                    key={addProduct?.id}
                    show={!!addProduct}
                    product={addProduct}
                    orderProducts={innerProps.values.tableData.filter(
                      (p) => p.productId === addProduct?.id,
                    )}
                    toggle={() => setAddProduct(false)}
                    categories={categories?.data}
                    updateComboProduct={(val) => {
                      setFieldValue(
                        'tableData',
                        _.concat(
                          values['tableData']?.filter((x) => x.productId !== val[0].productId),
                          val,
                        ),
                      );
                    }}
                  />
                )}
              </EditOrderWrapper>
            </>
          );
        }}
      </Formik>
    </Page>
  );
};



EditOrder.path = '/dashboard/order/editorder';

const mapStateToProps = (state) => ({
  clients: state.clients,
  products: state.products,
  orders: state.orders,
});

export default withRouter(connect(mapStateToProps, { getAll })(EditOrder));

export { EditOrder };
