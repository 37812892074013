import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { signIn } from 'actions/authActions';
import shared from 'app/shared';

import AuthLayout from '../auth.layout';
import SignInForm from '../components/SignInForm';
import PageHeader from '../components/PageHeader';

const {
  views: {
    Text: {
      Link: { AppLink },
    },
  },
} = shared;

const SignInPage = ({ signIn }) => {

  const onSubmit = ({ email, password }) => {
    return new Promise((resolve) => {
      signIn(email, password, (err, jwtInfo, isAdmin) => {
        if (err) {
          const msg = err.message || 'Wrong email or password!';
          toast.error(msg);
        } else if (isAdmin) {
          window.location.replace('/admin')
        } else {
          window.location.replace('/dashboard');
        }
        resolve();
      });
    });
  };

  return (
    <AuthLayout>
      <SignInWrapper>
        <PageHeader>Sign in.</PageHeader>
        <SignInForm onSubmit={onSubmit} />
        <SiginNav>
          New to Orderoo?&nbsp;
          <AppLink to="/register">Sign Up</AppLink>
        </SiginNav>
      </SignInWrapper>
    </AuthLayout>
  );
};

const SignInWrapper = styled.div``;

const SiginNav = styled.div`
  margin-top: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  & a {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
`;

export default connect((state) => ({}), {
  signIn,
})(SignInPage);
