import React, { Component } from 'react';
import Select, {components} from 'react-select';
import SelectOptionArrowDown from 'assets/icons/select_option.svg';

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={SelectOptionArrowDown} alt="SelectOptionArrowDown">
      </img>
    </components.DropdownIndicator>
  );
};

const selectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'unset',
    border: 'none',
    boxShadow: 'none'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
    };
  },
  indicatorSeparator: (styles) => ({display:'none'}),
};


class SelectOption extends Component {

  render() {
    const { className, customStyles, placeholder } = this.props;
    return (
      <Select
        className={className}
        options={this.props.options}
        onChange={this.props.onChange}
        isSearchable={this.props.isSearchable}
        name={this.props.name}
        value={this.props.value}
        placeholder={placeholder}
        styles={customStyles || selectStyles}
        components={{ DropdownIndicator }} />
    );
  }
}

export default SelectOption;