import moment from 'moment';
import currencyFormatter from 'app/shared/helpers/currencyFormatter';

const ProductCsvMapper = products => products.map(p => 
    ({
        'Product Name': p.name,
        'Price': `${currencyFormatter(p.price)}`,
        'Creation Date': moment(p.creationDate).format('YYYY-MM-DD HH:mm'),
        'Category Name': p.categoryName,
        'Description': p.description,
        'Clients': p.clients,
        'Orders': p.totalQuantity,
        'Revenue': `${currencyFormatter(p.revenue)}`
    }));

export default ProductCsvMapper;