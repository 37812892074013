import React, { useState } from 'react';
import styled from 'styled-components';
import Input from 'app/shared/views/input';
import './inputCode.css';

const Wrapper = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  flex: 1;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  display: flex;
  color: #1d2036;
  margin-bottom: 10px;
`;

const Body = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin: 5px 0;
`;

// eslint-disable-next-line
// const _old_InputCode = ({ setCode }) => {
//   return (
//     <Wrapper>
//       <Header>Input Phone Number</Header>
//       <Body>
//         <PinInput
//           name="token"
//           id="token"
//           length={6}
//           focus
//           type="numeric"
//           inputMode="number"
//           onChange={setCode}
//           autoSelect={true}
//           inputStyle={{
//             background: '#f9fafc',
//             borderRadius: 50,
//             fontFamily: 'Inter',
//             fontStyle: 'normal',
//             fontWeight: '500',
//             fontSize: 18,
//             alignItems: 'center',
//             textAlign: 'center',
//             color: '#1d2036',
//             border: 'none',
//             flex: 1,
//           }}
//           inputFocusStyle={{ border: '1px solid #D7E9FF' }}
//         />
//       </Body>
//     </Wrapper>
//   );
// };

const InputCode = ({ setCode, numDigits = 4 }, ref) => {
  const [codeValue, setCodeValue] = useState('');
  return (
    <Wrapper>
      <Header>Input Code</Header>
      <Body>
        <Input.Input
          inputRef={ref}
          type="text"
          pattern="[0-9]*"
          maxLength={numDigits}
          autoComplete="one-time-code"
          inputMode="numeric"
          value={codeValue}
          onChange={(event) => {
            if (event.target.value.length <= numDigits) {
              setCodeValue(event.target.value);
              setCode(event.target.value);
            }
          }}
        />
      </Body>
    </Wrapper>
  );
};

export default React.forwardRef(InputCode);
