import React, { useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import styled from 'styled-components';
import ShoppingLayout, { ShoppingContext } from './Context';
import { ORDER_STATUS } from 'app/orders/constants';
import { useParams, useHistory } from 'react-router-dom';
import OnlineShopping from './OnlineShopping';
import useQueryParams from 'hooks/useQueryParams';
import moment from 'moment';

import {
  getOrderById,
  saveOrder,
  updateOrderPaymentMethod,
  setPaymentMethod,
  startNewOrder,
} from 'actions/shoppingActions';

import shared from 'app/shared';

const {
  views: {
    Shopping: { PaymentDetails, UnitDetails, Constants },
    Icons,
  },
} = shared;
const { PAYMENT_METHODS } = Constants;
const { OrderCancelled, AwaitingConfirmation, PaymentSucceeded, PaymentDeclined } = PaymentDetails;

const steps = {
  PRODUCTS: 'products',
  LOCATION_DETAILS: 'location',
};

const ConfirmationWrapper = styled.div`
  position: absolute;
  background: #fff;
  z-index: 999998;
  min-height: 100vh;
  max-width: 502px;
  margin: auto;
  @media (min-width: 501px) {
    border: 1px #e8e9f3 solid;
  }
`;

const ServicesWrapper = styled.div`
  background: #f9fafc;
  padding: 17px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const InnerServiceWrapper = styled.div`
  background: #ffffff;
  border-radius: 2px;
  display: flex;
  overflow: hidden;
  flex: 1;
  margin: 0 50px;
  padding: 5px;
`;
const Service = styled.div`
  flex: 1;
  font-weight: ${({ selected }) => (selected ? '600' : 'normal')};
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: ${({ selected }) => (selected ? '#FFFFFF' : '#5C677B')};
  background: ${({ selected }) => (selected ? '#2ECC71' : '#FFFFFF')};
  border-radius: ${({ selected }) => (selected ? '2px' : '0')};
  cursor: pointer;
`;

const Visible = styled.div`
  display: ${({ visible }) => (visible ? '' : 'none')};
  height: 100%;
`;

const Triangle = styled(Icons.Triangle)`
  width: 18px;
  height: 18px;
`;

const CloseFrame = styled.div`
  position: relative;
  padding: 9px;
  cursor: pointer;
  width: 36px;
  height: 36px;
  background: #ffffff;
  border-radius: 2px;
  text-align: center;
  @media only screen and (min-width: 992px) {
    transform: rotate(180deg);
  }
  margin-right: 4px;
`;

const ShoppingPage = ({ pageName, close }) => {
  const params = useParams();
  const history = useHistory();

  const queryParams = useQueryParams();
  const preselectedProduct = queryParams.get('preselect');

  const dispatch = useDispatch();
  const order = useSelector((state) => state.shopping.order);
  const geoRegion = useSelector((state) => state.shopping.geoRegion);

  const [pageInfo, setPageInfo] = useState(null);
  const [waitingConfirmation, setWaitingConfirmation] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [declinedError, setDeclinedError] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [initialStep, setInitialStep] = useState(0);
  const [paymentSessionId, setPaymentSessionId] = useState(null);

  const stripe = useMemo(() => {
    if (pageInfo && pageInfo.publicPaymentKey) {
      return loadStripe(pageInfo.publicPaymentKey);
    }
    return null;
  }, [pageInfo]);

  const onInit = ({ pageInfo, lastOrder, setIsLoading }) => {
    setPageInfo(pageInfo);
    if (ORDER_STATUS[lastOrder.orderStatus] === 'Waiting for Confirmation') {
      setInitialStep(2);
      waitForConfirmation(setIsLoading, lastOrder.id);
    }
  };

  const waitForConfirmation = useCallback(
    (setIsLoading, orderId) => {
      setIsLoading(false);
      setWaitingConfirmation(true);
      const timer = setInterval(() => {
        dispatch(
          getOrderById(orderId, (err, data) => {
            if (err) {
              return;
            }

            const status = ORDER_STATUS[data.orderStatus];
            if (status !== 'Pending') {
              if (status === 'Cancelled') {
                setIsCancelled(true);
              } else {
                setWaitingConfirmation(false)
              }
              setWaitingConfirmation(false);
              clearInterval(timer);
            }
          }),
        );
      }, 5000);
    },
    [dispatch],
  );

  const onPlaceOrder = useCallback(
    (setIsLoading) => {
      setIsLoading(true);
      dispatch(
        saveOrder(pageInfo.id, false, (err, order) => {
          //false because it is not internal order
          if (err) {
            setIsLoading(false);
          } else {
            if (ORDER_STATUS[order.orderStatus] === 'Paid') {
              setDeclinedError(null);
              setPaymentSessionId(null);
              setSucceeded(true);
              setIsLoading(false);
            } else if (ORDER_STATUS[order.orderStatus] === 'Waiting for Confirmation') {
              waitForConfirmation(setIsLoading, order.id);
            } else {
              setIsLoading(false);
            }
          }
        }),
      );
    },
    [dispatch, pageInfo, waitForConfirmation],
  );

  const onRetry = useCallback(() => {
    setInitialStep(1);
    setDeclinedError(null);
  }, []);

  const changeToCash = useCallback(() => {
    if (!paymentSessionId) return;
    setPaymentMethod(PAYMENT_METHODS.ON_DELIVERY);
    dispatch(
      updateOrderPaymentMethod(paymentSessionId, PAYMENT_METHODS.ON_DELIVERY, (err) => {
        if (err) {
          return;
        }
        setSucceeded(true);
        setDeclinedError(null);
      }),
    );
  }, [paymentSessionId, dispatch]);

  const reset = () => {
    dispatch(startNewOrder());
    setWaitingConfirmation(false);
    setIsCancelled(false);
    setDeclinedError(false);
    setSucceeded(false);
  };

  const checkValidEvents = () =>
    pageInfo.pageEvents.filter(
      (activeEvent) =>
        activeEvent.active &&
        (moment().format(activeEvent.date) > moment().format() || activeEvent.date === null),
    );

  return (
    <ShoppingLayout pageName={pageName} onInit={onInit}>
      <ShoppingContext.Consumer>
        {({
          pageInfo,
          categories,
          search,
          setSearch,
          setIsLoading,
          extraCategories,
          iframeConfig,
          scrollableArea,
        }) => {
          const hasOnlineOrder = pageInfo.pageService.find(
            (x) => x.name === 'OnlineOrder' && x.isActive === true,
          );
          const services = [
            hasOnlineOrder ? 'delivery' : 'menu',
            checkValidEvents().length ? 'events' : 'noEvents',
          ];
          const selectedService = services.includes(params.service?.toLowerCase())
            ? params.service.toLowerCase()
            : 'event';

          return (
            <>
              {waitingConfirmation && (
                <ConfirmationWrapper>
                  <AwaitingConfirmation
                    order={order}
                    currency={pageInfo.currency}
                    geoRegion={geoRegion}
                  />
                </ConfirmationWrapper>
              )}
              <ServicesWrapper>
                <CloseFrame onClick={close}>
                  <Triangle />
                </CloseFrame>
                <InnerServiceWrapper>
                  {services.map((service) =>
                    service === 'noEvents' ? null : (
                      <Service
                        key={service}
                        selected={selectedService === service}
                        onClick={() =>
                          history.push(`/${params.category}/${params.business}/${service}`)
                        }
                      >
                        {service[0].toUpperCase() + service.slice(1)}
                      </Service>
                    ),
                  )}
                </InnerServiceWrapper>
              </ServicesWrapper>
              <Visible visible={selectedService === 'events'}>
                <UnitDetails pageInfo={pageInfo} />
              </Visible>
              <Visible visible={selectedService !== 'events'}>
                {succeeded ? (
                  <PaymentSucceeded
                    order={order}
                    currency={pageInfo.currency}
                    geoRegion={geoRegion}
                    reset={() => {
                      reset();
                      setSearch(null);
                    }}
                    heightToRemove={70}
                  />
                ) : declinedError ? (
                  <PaymentDeclined
                    onRetry={onRetry}
                    changeToCash={changeToCash}
                    reset={() => {
                      reset();
                      setSearch(null);
                    }}
                  />
                ) : isCancelled ? (
                  <OrderCancelled
                    order={order}
                    currency={pageInfo.currency}
                    geoRegion={geoRegion}
                    reset={() => {
                      reset();
                      setSearch(null);
                    }}
                  />
                ) : (
                  <OnlineShopping
                    pageInfo={pageInfo}
                    setIsLoading={setIsLoading}
                    categories={categories}
                    extraCategories={extraCategories}
                    search={search}
                    setSearch={setSearch}
                    iframeConfig={iframeConfig}
                    scrollableArea={scrollableArea}
                    order={order}
                    initialStep={initialStep}
                    steps={steps}
                    stripe={stripe}
                    onPlaceOrder={onPlaceOrder}
                    preselectedProduct={preselectedProduct}
                  />
                )}
              </Visible>
            </>
          );
        }}
      </ShoppingContext.Consumer>
    </ShoppingLayout>
  );
};

export default ShoppingPage;
