import React from 'react';
import styled from 'styled-components';
import Icons from 'app/shared/views/icons';
import { socialURL } from 'app/shared/helpers/aggregator';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: #f9fafc;
  justify-content: space-evenly;
`;

const Actionbutton = styled.div`
  background: #ffffff;
  border-radius: 2px;
  cursor: pointer;
  flex: 1;
  text-align: center;
  margin: 15px 10px;
  padding: 5px;
  :first-child {
    margin-left: 20px;
  }
  :last-child {
    margin-right: 20px;
  }
`;

const Socials = ({ socials }) => {
  return (
    <Wrapper>
      {socials['website'] && (
        <Actionbutton>
          <a href={socialURL(socials['website'])} target="_blank" rel="noopener noreferrer">
            <Icons.WorldWide />
          </a>
        </Actionbutton>
      )}
      {socials['facebook'] && (
        <Actionbutton>
          <a href={socialURL(socials['facebook'])} target="_blank" rel="noopener noreferrer">
            <Icons.Facebook />
          </a>
        </Actionbutton>
      )}
      {socials['instagram'] && (
        <Actionbutton>
          <a href={socialURL(socials['instagram'])} target="_blank" rel="noopener noreferrer">
            <Icons.Instagram />
          </a>
        </Actionbutton>
      )}
      {socials['youTube'] && (
        <Actionbutton>
          <a href={socialURL(socials['youTube'])} target="_blank" rel="noopener noreferrer">
            <Icons.Youtube />
          </a>
        </Actionbutton>
      )}
      {socials['phone'] && (
        <Actionbutton>
          <a href={`tel:${socials['phone']}`}>
            <Icons.Phone />
          </a>
        </Actionbutton>
      )}
    </Wrapper>
  );
};

export default Socials;
