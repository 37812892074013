import React, { useState, useEffect, useRef, createContext } from 'react';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import ClipLoader from 'app/shared/views/clipLoader';
import CountDown from 'app/shared/views/countDown';

import { initShopping, api } from 'actions/shoppingActions';
import { useDebounce } from 'app/shared/helpers/hooks';

const ShoppingWrapper = styled.div`
  background-color: #ffffff;
  max-width: 502px;
  margin: auto;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: scroll !important;
  @media (min-width: 501px) {
    border: 1px #e8e9f3 solid;
  }
`;

const LoadingWrapper = styled.div`
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  z-index: 999999;
`;

export const ShoppingContext = createContext({});

const ShoppingLayout = ({ initShopping, match, children, onInit }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [pageInfo, setPageInfo] = useState(null);
  const [iframeConfig, setIframeConfig] = useState({});
  const [categories, setCategories] = useState([]);
  const [extraCategories, setExtraCategories] = useState([]);
  const [search, setSearch] = useState(null);
  const [lastOrder, setLastOrder] = useState({});
  const [loadingOrder, setLoadingOrder] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 300);

  const [scrollableArea, setScrollableArea] = useState(useRef(null));

  useEffect(() => {
    if (debouncedSearchTerm == null) return;
    api.get('/Categories/Search', { params: { search: debouncedSearchTerm } }).then((res) => {
      const categories = res.data;
      setCategories(categories);
    });
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (match) {
      const { pageName } = match.params;
      setIsLoading(true);
      initShopping(
        pageName,
        (err, { jwtInfo, pageInfo, categories, extraCategories, lastOrder, iframeConfig } = {}) => {
          if (err) {
            console.log(err); // something went wrong
            return;
          } else {
            // Checks if all iframeConfig entries are empty arrays, return a default object if true.
            let config =
              Object.values(iframeConfig).filter((val) => Array.isArray(val) && val.length === 0)
                .length === Object.values(iframeConfig).length
                ? {
                    collectClientName: false,
                    customScript: null,
                    hideBanner: false,
                    portraitProductImage: false,
                  }
                : iframeConfig;

            setPageInfo(pageInfo);
            setIframeConfig(config);
            setCategories(categories);
            setExtraCategories(extraCategories);
            setLastOrder(lastOrder);
            setLoadingOrder(true);
          }
          onInit &&
            onInit({
              pageInfo,
              categories,
              setIsLoading,
              lastOrder,
            });
          setIsLoading(false);
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initShopping, match]);

  return (
    <ShoppingWrapper ref={(ref) => setScrollableArea(ref)}>
      {pageInfo ? (
        <Helmet
          defer={false}
          title={pageInfo.pageName}
          meta={[
            { name: pageInfo.pageName, content: pageInfo.pageName },
            {
              name: 'viewport',
              content:
                'width=device-width,minimal-ui,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=0',
            },
            { property: 'title', content: pageInfo.pageName },
            { property: 'og:title', content: pageInfo.pageName },
            { property: 'og:image', content: pageInfo.bannerURL },
            { property: 'description', content: pageInfo.pageName },
            { property: 'og:description', content: pageInfo.pageName },
          ]}
        >
          {iframeConfig?.customScript && <script>{iframeConfig?.customScript}</script>}
        </Helmet>
      ) : null}

      <ShoppingContext.Provider
        value={{
          pageInfo,
          categories,
          extraCategories,
          isLoading,
          setIsLoading,
          search,
          setSearch,
          iframeConfig,
          scrollableArea,
          lastOrder,
        }}
      >
        {isLoading ? (
          loadingOrder ? (
            <LoadingWrapper>
              <CountDown />
            </LoadingWrapper>
          ) : (
            <LoadingWrapper>
              <ClipLoader size={80} style={{ height: '100vh', width: '100vw' }} />
            </LoadingWrapper>
          )
        ) : (
          pageInfo && children
        )}
      </ShoppingContext.Provider>
      <ToastContainer
        position="bottom-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
      />
    </ShoppingWrapper>
  );
};

export default connect((state) => ({}), {
  initShopping,
})(ShoppingLayout);
