const phone = 768;
const tablet = 1024;

const queries = {
  phone: `@media (max-width: ${phone}px)`,
  tablet: `@media (min-width: ${phone}px) and (max-width: ${tablet}px)`,
  desktop: `@media (min-width: ${tablet}px)`,
};

export default {
  queries,
  mobile: css => `${queries.phone} {
      ${css}
  }`,
  tablet: css => `${queries.tablet} {
      ${css}
  }`,
  desktop: css => `${queries.desktop} {
      ${css}
  }`,
  sizes: {
    phone,
    tablet,
  },
};
