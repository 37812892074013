import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { resetPassword } from 'actions/authActions';
import useQueryParams from 'hooks/useQueryParams';

import shared from 'app/shared';

import AuthLayout from '../auth.layout';
import PageHeader from '../components/PageHeader';
import ResetPassForm from '../components/ResetPassForm.js';

const {
  views: {
    Text: {
      Link: { AppLink }
    }
  }
} = shared;

const ResetPassPage = ({ resetPassword }) => {
  const history = useHistory();
  const queryParams = useQueryParams();

  const onSubmit = (values) => {
    const confirmCode = queryParams.get('confirmationcode');

    return new Promise((resolve) => {
      resetPassword(
        confirmCode,
        values.password,
        values.confirmPassword,
        (err, data) => {
          if (err || data === false) {
            toast.error('This reset link is invalid!');
          } else {
            toast.success('Your password has been changed successfully!');
            setTimeout(() => {
              history.push('/signin');
            }, 3000);
          }
          resolve();
      });
    });
  };

  return (
    <AuthLayout>
      <ResetPassWrapper>
        <PageHeader>New Password</PageHeader>
        <ResetPassForm onSubmit={onSubmit} />
        <ResetPassNav>
          Nevermind, i remember now!&nbsp;
          <AppLink to="/signin">Sign In</AppLink>
        </ResetPassNav>
      </ResetPassWrapper>
    </AuthLayout>
  );
};

export default connect(state => ({
}), {
  resetPassword,
})(ResetPassPage);


const ResetPassWrapper = styled.div`

`;

const ResetPassNav = styled.div`
  margin-top: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  & a {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
`;
