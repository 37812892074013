import React, { useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import styled from 'styled-components';
import shared from 'app/shared';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { add } from '../../../actions/crudActions';
import crudTypes from '../../../config/crudTypes';

import addCategoryIllustration from '../../../assets/images/addCategoryIllustration.svg';

const {
  views: { Modal, AddItemPopup, Button, Input, Icons },
  helpers: { colors },
} = shared;

const AddItemPopupWrapper = styled.div`
  width: 450px;
`;

const ErrorWrapper = styled.div`
  width: 100%;
  background: rgba(242, 69, 61, 0.2);
  color: #f2453d;
  margin-bottom: 10px;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  align-items: center;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 1px 5px;
  display: ${(props) => (props.show === 'ERROR' ? 'flex' : 'none')};
`;

const WarningWrapper = styled.div`
  width: 100%;
  display: flex;
  background: rgba(255, 152, 0, 0.2);
  color: #ff9800;
  margin-bottom: 10px;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  align-items: center;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 1px 5px;
  margin-top: 10px;
`;

const AddCategoryPopup = (props) => {
  const [postStatus, setPostStatus] = useState('');
  const { toggle, show = false, selectedCategoryType } = props;

  const changePostStatus = (resetForm) => (status) => {
    setPostStatus(status);
    if (status === 'OK') {
      resetForm();
      toggle();
    }
  };

  const postCategory = (data, resetForm) => {
    data.pageId = JSON.parse(localStorage.getItem('Page'));
    props.add(crudTypes.category, data, changePostStatus(resetForm));
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        categoryType: selectedCategoryType,
        name: '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Category name is required')
        .test('len', 'Max 30 characters allowed', val => val?.length < 31),
        categoryType: Yup.number().oneOf([0, 1]).required('Category name is required'),
      })}
      onSubmit={(values, { resetForm }) => {
        postCategory(values, resetForm);
      }}
    >
      {(innerProps) => {
        const { touched, errors, values, handleChange, handleSubmit, setFieldValue } = innerProps;
        return (
          <Modal toggle={toggle} show={show}>
            <AddItemPopupWrapper>
              <AddItemPopup
                title={'Add New Category'}
                actions={[
                  () => <Button.Button color={colors.blue} text={'Save'} onClick={handleSubmit} />,
                  () => (
                    <Button.WhiteButton
                      text={'Cancel'}
                      onClick={() => {
                        props.toggle();
                      }}
                    />
                  ),
                ]}
                illustration={addCategoryIllustration}
              >
                <ErrorWrapper show={postStatus}>
                  <Icons.XIcon fill={colors.red} style={{ marginRight: 10 }} height={16} />
                  <span>Error adding the category</span>
                </ErrorWrapper>
                <AddItemPopup.InputRow>
                  <Select
                    id={'categoryType'}
                    labelText={'Category Type'}
                    options={[
                      { value: 0, label: 'Main Category'},
                      { value: 1, label: 'Extras Category (Combo)'},
                    ]}
                    onChange={val => setFieldValue('categoryType', val.value)}
                    isSearchable={false}
                    style={{ width: '-webkit-fill-available' }}
                    value={[
                      { value: 0, label: 'Main Category'},
                      { value: 1, label: 'Extras Category (Combo)'},
                    ].find(opt => opt.value === values.categoryType)}
                  />
                  {errors.categoryType && touched.categoryType ? (
                    <WarningWrapper>
                      <Icons.WarningIcon
                        fill={colors.yellow}
                        style={{ marginRight: 10 }}
                        height={16}
                      />
                      {errors.categoryType}
                    </WarningWrapper>
                  ) : null}
                </AddItemPopup.InputRow>
                <AddItemPopup.InputRow>
                  <Input
                    id={'name'}
                    labelText={'Category Name'}
                    onChange={handleChange}
                    value={values.name}
                    autoComplete="off"
                  />
                  {errors.name && touched.name ? (
                    <WarningWrapper>
                      {' '}
                      <Icons.WarningIcon
                        fill={colors.yellow}
                        style={{ marginRight: 10 }}
                        height={16}
                      />{' '}
                      {errors.name}
                    </WarningWrapper>
                  ) : null}
                </AddItemPopup.InputRow>
              </AddItemPopup>
            </AddItemPopupWrapper>
          </Modal>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => ({ categories: state.categories });

export default connect(mapStateToProps, { add })(AddCategoryPopup);
