import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import currencyFormatter from 'app/shared/helpers/currencyFormatter';
import { BlackButton } from 'app/shared/views/buttons';
import WaiterReview from '../components/waiterReview';
import WaiterFunctionality from '../components/WaiterFunctionality';
import Icons from 'app/shared/views/icons';
import { ORDER_STATUS } from 'app/orders/constants';
import { PAYMENT_METHODS } from 'app/shared/views/shopping/constants';

const OrderStatusWrapper = styled.div`
  display: flex !important;
  flex-direction: column !important;
  background-color: #ffffff !important;
  position: relative !important;
  bottom: 0 !important;
  width: 100vw !important;
  max-width: 500px !important;
  height: calc(var(--vh, 1vh) * 100) !important;
  overflow: hidden !important;
  margin: auto;
  @media (min-width: 500px) {
    border: 1px #e8e9f3 solid;
  }
`;
const StyledHeader = styled.div`
  width: 100%;
  position: sticky !important;
  top: 0 !important;
  background-color: #ffffff !important;
  display: flex !important;
  flex-direction: row;
  justify-content: center !important;
  align-items: center;
  padding: 30px 20px 0;
`;

const TableNameText = styled.div`
  width: 100%;
  margin-top: 10px;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #5c677b;
  line-height: 22px;
`;
const StyledBody = styled.div`
  overflow-y: auto;
  width: 100%;
  flex: 1;
`;

const OrderRow = styled.div`
  background-color: ${(props) => (props.index % 2 === 0 ? '#F9FAFC' : '#FFFFFF')};
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const ProductName = styled.div`
  flex: 2;
  margin-left: ${(props) => (props.isCombo ? '8px' : 0)};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #1d2036;
  line-height: 20px;
`;
const ProductTotal = styled.div`
  flex: 1;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1d2036;
`;
const ProductPrice = styled.div`
  flex: 1;
  text-align: right;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: black;
`;
const ActionButtons = styled.div`
  margin-top: auto;
  padding: 1rem;
`;

const PaymentStatusTitle = styled.span`
  font-family: Inter, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  flex: 2;
  color: ${(props) => props.color};
  width: 100%;
`;

const OrderId = styled.p`
  font-weight: 300;
  font-size: 12px;
  color: #748aa1;
`;

const BillingInformation = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px 0.5rem 20px;
`;

const BillingInformationRow = styled.div`
  padding: 10px 0;
`;

const BillingInformationLabel = styled.div`
  flex: 1;
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 5px;
  color: black;
`;
const BillingInformationValue = styled.div`
  flex: 2;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #2a2e45;
`;

const BreakLine = styled.div`
  width: 100%;
  height: 0;
  border: 0.5px dashed #a5b4cc;
  margin: 10px 0;
`;

const OrderStatus = ({
  pageInfo,
  order,
  callWaiter,
  getBill,
  startNewOrder,
  tableInfo,
  isInternalOrder,
  enableWaiterFunctionalities,
}) => {
  const [isRingingCallWaiter, setIsRingingCallWaiter] = useState(false);
  const [isRingingGetBill, setIsRingingGetBill] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const waitingConfirmation = ORDER_STATUS[order.orderStatus] === 'Pending';
  const paymentMethod = useSelector((state) => state.shopping.paymentMethod);
  const rejected = ORDER_STATUS[order.orderStatus] === 'Cancelled';

  useEffect(() => {
    if (isRingingCallWaiter) {
      let t = setTimeout(() => {
        setIsRingingCallWaiter(false);
        clearTimeout(t);
      }, 5000);
    }
  }, [isRingingCallWaiter]);

  useEffect(() => {
    if (isRingingGetBill) {
      let t = setTimeout(() => {
        setIsRingingGetBill(false);
        clearTimeout(t);
      }, 5000);
    }
  }, [isRingingGetBill]);

  return (
    <OrderStatusWrapper>
      <StyledHeader style={{ flexDirection: 'column' }}>
        <PaymentStatusTitle color={'black'}>
          {pageInfo.pageName}
          <OrderId>Order ID: {order.id}</OrderId>
        </PaymentStatusTitle>
        <TableNameText>{tableInfo?.name}</TableNameText>
        <BreakLine />
      </StyledHeader>
      <BillingInformation>
        <BillingInformationRow
          span={24}
          style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 8 }}
        >
          <div>
            <BillingInformationLabel>Order status:</BillingInformationLabel>
            <BillingInformationValue style={{ color: '#2A2E45' }}>
              {waitingConfirmation
                ? 'Pending'
                : rejected
                ? 'Order Rejected'
                : 'Order Confirmed'}
            </BillingInformationValue>
          </div>
          {rejected ? <Icons.OrderDeclined /> : <Icons.OrderAccepted />}
        </BillingInformationRow>
        <BreakLine />
        <BillingInformationRow
          span={24}
          style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 8 }}
        >
          <div>
            <BillingInformationLabel>Payment Method:</BillingInformationLabel>
            {paymentMethod.methodId.toString() === PAYMENT_METHODS.WITH_CARD.methodId.toString() ? (
              <BillingInformationValue>Pok</BillingInformationValue>
            ) : (
              <BillingInformationValue>Cash on delivery</BillingInformationValue>
            )}
          </div>
          <Icons.PaymentMethodV2 />
        </BillingInformationRow>
      </BillingInformation>
      <StyledBody>
        <OrderRow
          style={{
            paddingBottom: 8,
            position: 'sticky',
            bottom: 0,
            backgroundColor: '#fff',
          }}
        >
          <OrderRow
            style={{
              borderTop: '1px solid #F9FAFC',
              padding: '10px 0',
              margin: '0 20px',
              width: 'calc(100% - 40px)',
              flexDirection: 'row',
            }}
          >
            <ProductName>Item subtotal:</ProductName>
            <ProductPrice>{currencyFormatter(order.total, pageInfo.currency)}</ProductPrice>
          </OrderRow>
          <OrderRow
            style={{
              borderTop: '1px solid #F9FAFC',
              padding: '10px 0',
              margin: '0 20px',
              width: 'calc(100% - 40px)',
              flexDirection: 'row',
            }}
          >
          </OrderRow>
          <OrderRow style={{ flexDirection: 'row', width: 'calc(100% - 40px)' }}>
            <ProductTotal>Total:</ProductTotal>
            <ProductPrice style={{ fontWeight: 'bold' }}>
              {currencyFormatter(order.totalValue, pageInfo.currency)}
            </ProductPrice>
          </OrderRow>
        </OrderRow>
      </StyledBody>
      <WaiterReview
        showReview={showReview}
        setShowReview={setShowReview}
        showTextSubmit={true}
        tableId={tableInfo.id}
      />
      {order.orderStatus !== 6 && (
        <ActionButtons>
          {!isInternalOrder && enableWaiterFunctionalities && (
            <WaiterFunctionality
              getBill={getBill}
              callWaiter={callWaiter}
              setShowReview={setShowReview}
            />
          )}
          <BlackButton
            onClick={() => {
              startNewOrder();
              window.location.reload();
            }}
            style={{
              bottom: 0,
              position: 'sticky',
              width: '100%',
              minHeight: '60px',
              height: 60,
              fontSize: 16,
              fontWeight: 'bold',
              color: '#FFFFFF',
              borderRadius: 7,
            }}
            text="New Order"
          />
        </ActionButtons>
      )}
    </OrderStatusWrapper>
  );
};

export default OrderStatus;
