import React, { Component } from 'react';
import styled from 'styled-components';
import isEscape from '../helpers/isEscape';
import slideFromTopAnimation from '../helpers/slideFromTopAnimation';

import Card from './card';
import Icons from './icons';
import transition from '../helpers/transition';
import ChildrenValidator from '../validators/children.validator';
import ToggleValidator from '../validators/toggle.validator';
import hexToRGBA from '../helpers/hexToRGBA';

const OverlayWrapper = styled.div`
  &:first-child {
    background: linear-gradient(90deg, ${hexToRGBA('#1e7ae5', 0.5)} 0%, ${hexToRGBA(
    '#42bff5',
    0.5,
    )} 100%);
  };
  &:nth-child(2)  {
    background: linear-gradient(90deg, ${hexToRGBA('#1e7ae5', 0.5)} 0%, ${hexToRGBA(
    '#42bff5',
    0.5,
    )} 100%);
  };
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  transition: ${transition};
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  opacity: ${props => (props.show ? 1 : 0)};
  top: 0;
  left: 0;
  z-index: 9999;
`;

/**
 * TODO: Fix color by name
 */
const CustomCard = styled(Card)`
  border-radius: 6px;
  box-shadow: 0px 16px 28px rgba(63, 86, 104, 0.1);
  position: relative;
  width: 100%;
`;

const CloseIcon = styled(Icons.Close)`
  width: 42px;
  height: 42px;
  position: absolute;
  // top: -40px;
  // right: -40px;
  top: 0;
  right: 0;

  :hover {
    cursor: pointer;
  }
`;

const BackCard = styled.div`
    border-radius: 6px;
    box-shadow: 0px 16px 28px rgba(63,86,104,0.1);
    position: absolute;
    background-color: #F9F9F9;
    overflow: hidden;
    width: 472px;
    height: 520px;
    transition: .5s ease;
    z-index: -1;
    top: 0px;
    left: 17px;
`;
const BackCard2 = styled.div`
    border-radius: 6px;
    box-shadow: 0px 16px 28px rgba(63,86,104,0.1);
    position: absolute;
    background-color: #F9F9F9;
    overflow: hidden;
    height: 520px;
    transition: 1s ease;
    z-index: -1;
    width: 444px;
    top: 0px;
    left: 32px;
    z-index: -20;
  `;

  const ContentWrapper = styled.div`
  transition: 0.4s ease-in-out;
  transform: translateY(${props => (props.show ? 0 : '-20px')});
  max-height: 90vh;
  position: relative;
  opacity: ${props => (props.show ? 1 : 0)};
  animation: ${props => (props.show ? slideFromTopAnimation : '')} 0.4s ease-in-out;
  ${BackCard}{
    top: ${props => props.backCards > 1 ? '-16px' : '0px'}
  }
  ${BackCard2}{
    top: ${props => props.backCards > 2 ? '-32px' : '0px'}
  }
`;
class Overlay extends Component {
  componentDidMount = () => document.addEventListener('keyup', this.toggleOnEscape);

  componentWillUnmount = () => document.removeEventListener('keyup', this.toggleOnEscape);

  toggleOnEscape = event => {
    if (event.defaultPrevented) return;
    const { toggle, show } = this.props;

    if (isEscape(event) && show) toggle();
  };

  
  render() {
    const { children, show, toggle, showCloseIcon=true, backCards} = this.props;
    return (
      <OverlayWrapper show={show} onClick={toggle}>
        <ContentWrapper show={show} onClick={event => event.stopPropagation()} backCards={backCards}>
          <CustomCard>{children}</CustomCard>
          {backCards > 0 && <BackCard />}
          {backCards > 0 && <BackCard2 />}
          {showCloseIcon && <CloseIcon fill={"red"} onClick={toggle} />}
        </ContentWrapper>
      </OverlayWrapper>
    );
  }
}

Overlay.propTypes = {
  children: ChildrenValidator.ChildrenRule.isRequired,
  ...ToggleValidator.ToggleSchema,
};

export default Overlay;
