import React from 'react';
import styled from 'styled-components';
import shared from 'app/shared';
import { CHARACTER_LIMIT } from '../constants';
import Spinner from 'app/shared/views/spinner';
import Button from 'app/marketing/components/buttons';

const {
  views: {
    Icons,
    InputArea,
    Text: {
      Link: { Link },
    },
  },
  helpers: { colors },
} = shared;

const StyledInputArea = styled(InputArea)`
  overflow: auto;
  display: flex;
  border-radius: 0;
  border-top: 1px solid #e1e6ec;
  border-right: 0;
  border-bottom: 1px solid #e1e6ec;
  border-left: 0;
  height: 100%;

  & textarea {
    color: ${colors.textColor};
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 17px;

    padding: 40px 30px;
    &::placeholder {
      opacity: 0.5;
    }
  }
`;

const StyledLink = styled(Link)`
  display: inline-flex;
  font-size: 12px;
  margin-right: 30px;
`;

const AddMessengerLink = ({ onClick }) => (
  <StyledLink onClick={onClick}>
    <Icons.Link style={{ marginRight: '5px' }} />
    Add Messenger Link
  </StyledLink>
);

const SendCampaignButton = styled(Button).attrs(() => ({
  size: {
    width: 179,
    height: 24,
    fontSize: 12,
  },
}))`
  margin-right: 30px;
`;

const Placeholder = styled.div`
  color: #bfc4d1;
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  user-select: none;
`;

const InputAreaEndBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 21px 30px;
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 59px;
`;

const MessageInputSection = ({ message, setMessage, onSave, isSubmitting }) => {
  const [inputText, setInputText] = [message, setMessage];
  const characterCount = `${CHARACTER_LIMIT - inputText.length} Characters Left`;

  const updateText = (text) => {
    if (text.length <= CHARACTER_LIMIT) setInputText(text);
  };

  const addMessengerLink = () => {
    const newMsgContent = inputText + '{{messenger_link}}';
    updateText(newMsgContent);
  };

  const sendButtonText = isSubmitting ? (
    <>
      <div>
        <Spinner size={12} color={colors.white} style={{ width: 'unset' }} />
      </div>
    </>
  ) : (
    'Send Campaign'
  );

  return (
    <>
      <StyledInputArea
        rows={'no'}
        placeholder={'Create an offer...'}
        value={inputText}
        onChange={(e) => updateText(e.target.value)}
        end={
          <InputAreaEndBar>
            <Placeholder>{characterCount}</Placeholder>
          </InputAreaEndBar>
        }
        disabled={isSubmitting}
      />
      <div>
        <ActionBar>
          <AddMessengerLink onClick={() => addMessengerLink()} />
          <SendCampaignButton
            text={sendButtonText}
            onClick={onSave}
            loading={isSubmitting ? 'yes' : undefined}
          />
        </ActionBar>
      </div>
    </>
  );
};

export default MessageInputSection;
