import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import currencyFormatter from 'app/shared/helpers/currencyFormatter';
import { TimeBadge, StatusBadge, MinValueBadge, StringTime } from './Badge';
// import orderooWhiteLogo from 'assets/images/logo-white.svg';
import formatTimespan from 'app/shared/helpers/timespan';
import moment from 'moment';
import { Button } from 'app/shared/views/buttons';
import Icons from 'app/shared/views/icons';
const ShoppingHeader = ({
  pageInfo,
  callWaiter,
  getBill,
  onBack,
  showBackButton,
  hideStatus,
  ...props
}) => {
  const [deliveryTimeRange, setDeliveryTimeRange] = useState('N/A');

  useEffect(() => {
    const geoRegionsDeliveyTime = pageInfo.geoRegions.map((a) => moment.duration(a.deliveryTime));
    if (geoRegionsDeliveyTime.length === 0) {
    } else if (geoRegionsDeliveyTime.length === 1) {
      setDeliveryTimeRange(formatTimespan(geoRegionsDeliveyTime[0]));
    } else {
      setDeliveryTimeRange(
        `${formatTimespan(Math.min.apply(null, geoRegionsDeliveyTime))}-${formatTimespan(
          Math.max.apply(null, geoRegionsDeliveyTime),
        )}`,
      );
    }
  }, [pageInfo]);
  if (!pageInfo) return null;
  // const showLogo = pageInfo.isSubscribed;
  const minOrderValue = currencyFormatter(pageInfo.minimumOrderValue, pageInfo.currency, {
    minimumFractionDigits: 0,
  });

  return (
    <HeaderWrapper>
      <StyledHeader cover={pageInfo.bannerURL} {...props}>
        {/*<LogoWrapper style={{ display: showLogo ? 'flex' : 'none' }}>
          <img src={orderooWhiteLogo} alt="orderoo-logo" height="18px" />
        </LogoWrapper>*/}
        {onBack && (
          <Button
            onClick={onBack}
            style={{ width: 140 }}
            text={
              <div
                style={{
                  fontWeight: 600,
                  fontSize: 16,
                  color: '#FFFFFF',
                  width: '100%',
                  textAlign: 'left',
                  textShadow: '0 0 5px #000' /* Apply a text shadow */,
                }}
              >
                Back
              </div>
            }
          />
        )}
        <div
          style={{
            position: 'relative',
            width: '30px',
            top: '45px',
            left: '70%',
          }}
        >
          {pageInfo.pageService[3].isActive ? <Icons.OrderooDriver /> : null}
        </div>
      </StyledHeader>
      <Body>
        <Name>{pageInfo.pageName}</Name>
        <p style={{ position: 'relative', left: '25px', bottom: '20px', width: '50px' }}>
          {pageInfo.pageCategory}
        </p>
        <div style={{ display: 'flex' }}>
          <Icons.OpeningHours />
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <StringTime openHours={pageInfo.openHours} />
            <StatusBadge
              key="statusBadge"
              isOpen={pageInfo.isOpen}
              openHours={pageInfo.openHours}
            />
          </div>
        </div>
        <div style={{ display: 'flex', paddingTop: '10px' }}>
          <Icons.DeliveryTimeV2 />
          <TimeBadge key="timeBadge" text={deliveryTimeRange} />
        </div>
        <div
          style={{
            display: 'flex',
            paddingTop: '10px',
            paddingBottom: '20px',
          }}
        >
          <Icons.MinimumOrder />
          <MinValueBadge key="minValueBadge" text={` Minimum order value of ${minOrderValue}`} />
        </div>
      </Body>
      {/* <Line /> */}
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  background-color: #fff;
`;

const StyledHeader = styled.div`
  background-image: url(${(props) => props.cover});
  // linear-gradient(180deg, rgba(49, 133, 252, 0.1) 0%, rgba(49, 133, 252, 0.5) 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;
  padding: 20px;

  display: flex;
  flex-direction: column;
  min-height: 140px;
`;

const Body = styled.div`
  margin: 0 1rem;
  border-bottom: 1px dashed;
`;

// const LogoWrapper = styled.div`
//   padding-bottom: 15px;
// `;

// const Line = styled.div`
//   height: 1px;
//   background: linear-gradient(to right, transparent 50%, #a5b4cc 50%);
//   background-size: 9px 2px, 100% 2px;
// `;

const Name = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: black;
  padding: 25px 25px 25px 9px;
  margin-top: auto;
`;

export default ShoppingHeader;
