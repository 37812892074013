import React from 'react';
import styled from 'styled-components';
import shared from 'app/shared/';
import { useDispatch } from 'react-redux';
import { playPausePageEvent } from '../../../actions/visibilitySettingsActions';

const {
  views: {
    Button: { PlayButton, PauseButton },
  },
} = shared;

const EventImage = styled.div`
  background: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 60px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
  background: #f9fafc;
  border: 1px solid #e1e6ec;
  box-shadow: ${({ isHighlighted }) => (isHighlighted ? '0px 0px 3px 2px #2ECC71' : 'none')};
  border-radius: 3px;
  margin: 0 20px 20px 0;
  min-height: 170px;
  :hover {
    cursor: pointer;
    transform: translateY(-5px);
    border: 1px solid #3185fc;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 5px 0 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: auto 0 10px 0;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: #5c677b;
`;

const Status = styled.div`
  font-weight: 500;
  font-size: 10px;
  color: ${({ isActive }) => (isActive ? '#2ECC71' : '#FF3A44')};
`;

const Text = styled.div`
  font-weight: 500;
  font-size: 10px;
  color: #748aa1;
`;
const CreateEvent = styled.div`
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #3185fc;
  margin: auto;
`;

const EventCard = ({
  id,
  image,
  isActive,
  isExpired,
  title,
  date,
  onClick,
  createEvent,
  isHighlighted,
}) => {
  const dispatch = useDispatch();

  const statusLabel = isExpired ? 'Expired' : isActive ? 'Active' : 'Inactive';
  return (
    <Container onClick={() => onClick()} isHighlighted={isHighlighted}>
      {createEvent ? (
        <CreateEvent>Create Event</CreateEvent>
      ) : (
        <>
          <EventImage src={image} />
          <Row>
            <Title>{title}</Title>
            <Status isActive={!isExpired && isActive}>{statusLabel}</Status>
          </Row>
          {date && (
            <Row>
              <Text>Date</Text>
              <Text>{date}</Text>
            </Row>
          )}
          <ButtonContainer>
            {isActive ? (
              <PauseButton
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(playPausePageEvent(id));
                }}
                style={{ backgroundColor: 'unset' }}
              />
            ) : (
              <PlayButton
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(playPausePageEvent(id));
                }}
                style={{ backgroundColor: 'unset' }}
              />
            )}
          </ButtonContainer>
        </>
      )}
    </Container>
  );
};

export default EventCard;
