import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import HelpCenterButton from './helpCenterButton';
import HelpCenterDropdown from './helpCenterDropdown';
import { connect } from 'react-redux';

const HelpCenterWrapper = styled.div`
  position: fixed;
  bottom: 30px;
  right: 0;
  z-index: 9999;
`;

const HelpCenter = (props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = useCallback(() => {
    setShowDropdown(!showDropdown);
  }, [showDropdown]);

  const outsideClickHandler = useCallback(
    (e) => {
      if (!showDropdown || !dropdownRef) {
        return;
      }

      const domNode = dropdownRef.current;
      if (!domNode || !domNode.contains(e.target)) {
        toggleDropdown();
      }
    },
    [showDropdown, toggleDropdown, dropdownRef],
  );

  useEffect(() => {
    document.addEventListener('click', outsideClickHandler);
    return () => {
      document.removeEventListener('click', outsideClickHandler);
    };
  }, [outsideClickHandler]);

  return (
    <HelpCenterWrapper>
      <HelpCenterButton onClick={toggleDropdown} />
      <div ref={dropdownRef}>
        <HelpCenterDropdown show={showDropdown} toggle={toggleDropdown} />
      </div>
    </HelpCenterWrapper>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(HelpCenter);
