import Icons from '../views/icons';

export const normalMenu = {
  navBar: [
    {
      Icon: Icons.Dashboard,
      to: '/dashboard',
      text: 'Dashboard',
      exact: true,
    },
    {
      Icon: Icons.Orders,
      to: '/dashboard/orders',
      text: 'Orders',
    },
    {
      Icon: Icons.Clients,
      to: '/dashboard/clients',
      text: 'Clients',
    },
    {
      Icon: Icons.Products,
      to: '/dashboard/products',
      text: 'Products',
    },
    {
      Icon: Icons.Categories,
      to: '/dashboard/categories',
      text: 'Categories',
    },
    {
      Icon: Icons.Banner,
      to: '/dashboard/banner',
      text: 'Banners',
    },
  ],
};

export const bartenderMenu = {
  navBar: [
    {
      Icon: Icons.Orders,
      to: '/dashboard/orders',
      text: 'Orders',
    },
    {
      Icon: Icons.Products,
      to: '/dashboard/products',
      text: 'Products',
    },
    {
      Icon: Icons.Categories,
      to: '/dashboard/categories',
      text: 'Categories',
    },
  ],
};
