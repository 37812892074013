const title = 'Select Page';
const subtitle = 'Choose the Page you want to connect with Orderoo';
const paragraph = 'If you do not have an exsisting page, you can create a new one ny clicking the button below';
const btnText = 'Create Facebook Page';

export default{
  title, subtitle, paragraph, btnText,
};

export const list = [
  {
    title: 'Page One',
    rest: null
  },
  {
    title: 'Page Two',
    rest: null
  },
  {
    title: 'Page Three',
    rest: null
  }
  ,
  {
    title: 'Page Three',
    rest: null
  }
  ,
  {
    title: 'Page Three',
    rest: null
  }
  ,
  {
    title: 'Page Three',
    rest: null
  }
];
