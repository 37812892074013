import { PAYMENT_METHODS } from 'app/shopping/views/constants';
import _ from 'lodash';
import parsePhoneNumber from 'libphonenumber-js';
import normalizePhoneNumber from 'app/shared/helpers/phonenumber';

const initialState = {
  paymentMethod: PAYMENT_METHODS.ON_DELIVERY,
  client: {
    firstName: '',
    lastName: '',
    phone: '',
  },
  order: {
    total: 0,
    totalValue: 0,
    deliveryFee: 0,
    deliveryFeeExempt: false,
    pokConnectionId: '',
    discount: 0,
    pokPaymentConfirmed: false,
    taxFee: 0,
    orderLocation: {},
    addressInfo: '',
    description: '',
    orderProducts: [],
    promotion: null,
    voucherCode: '',
    validVoucher: null,
  },
  lastOrder: {
    id: 0,
    orderStatus: 0,
  },
  geoRegion: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'START_NEW_INTERNAL_ORDER':
      return {
        ...initialState,
      };
    case 'START_NEW_ORDER':
      return {
        ...state,
        order: {
          total: 0,
          totalValue: 0,
          deliveryFee: 0,
          discount: state.order.discount,
          pokPaymentConfirmed: false,
          pokConnectionId: '',
          deliveryFeeExempt: false,
          taxFee: 0,
          orderLocation: { ...state.order.orderLocation },
          addressInfo: state.order.addressInfo,
          description: '',
          orderProducts: [],
          offerId: 0,
          promotion: state.promotions,
          voucherCode: '',
          validVoucher: null,
        },
        lastOrder: {
          id: 0,
          orderStatus: 0,
        },
        geoRegion: null,
      };
    case 'GET_ORDER_OK':
      return {
        ...state,
        order: { ...state.order, ...action.data.order },
        client: { ...state.client, ...action.data.client },
      };

    case 'PAGE_INFO_OK':
      return {
        ...state,
        pageInfo: action.data,
      };
    case 'UPDATE_COMBO_PRODUCT': {
      const productToAdd = action.data.product;
      let { orderProducts } = state.order;

      orderProducts = orderProducts.filter((obj) => obj.id !== productToAdd[0].id);
      orderProducts = _.concat(orderProducts, productToAdd);

      const totalValueNotPromoted = getTotal(orderProducts);

      const totalValue = totalValueNotPromoted;

      return {
        ...state,
        order: { ...state.order, orderProducts, totalValueNotPromoted, totalValue },
      };
    }
    case 'ORDER_ADD_PRODUCT': {
      let exists = false;
      const productToAdd = action.data;
      const promotions = action.promotions;

      const orderProducts = state.order.orderProducts.map((p) => {
        if (p.id === productToAdd.id) {
          exists = true;

          return {
            ...p,
            qty: p.qty + 1,
          };
        }
        return p;
      });

      if (!exists) {
        orderProducts.push({
          ...productToAdd,
          qty: 1,
        });
      }

      const totalValueNotPromoted = getTotal(orderProducts);

      const totalValue = Promotions(totalValueNotPromoted, promotions, orderProducts);

      const total = getTotal(totalValue, state.order.deliveryFee, state.order.taxFee);

      const promotionId = PromotionId(promotions);

      return {
        ...state,
        order: {
          ...state.order,
          orderProducts: orderProducts,
          total: total,
          totalValue: totalValue,
          promotion: { id: promotionId },
        },
      };
    }
    case 'ORDER_REMOVE_PRODUCT': {
      const one = action.one;
      const productToRemove = action.data;
      const promotions = action.promotions;

      const orderProducts = state.order.orderProducts.reduce((products, p) => {
        //if is Combo
        if (p.isCombo && p.index === productToRemove.index && p.id === productToRemove.id) {
          // do not return combo prod to remove
        } else if (!p.isCombo && p.id === productToRemove.id) {
          // if not combo
          if (one && p.qty > 1) {
            products.push({
              ...p,
              qty: p.qty - 1,
            });
          }
        } else {
          products.push(p);
        }
        return products;
      }, []);

      const totalValueNotPromoted = getTotal(orderProducts);

      const totalValue = Promotions(totalValueNotPromoted, promotions, orderProducts);

      const total = getTotal(totalValue, state.order.deliveryFee, state.order.taxFee);

      const promotionId = PromotionId(promotions);

      return {
        ...state,
        order: {
          ...state.order,
          orderProducts: orderProducts,
          total: total,
          totalValue: totalValue,
          promotion: { id: promotionId },
        },
      };
    }

    case 'ORDER_SAVED': {
      return { ...state, order: { ...state.order, ...action.data } };
    }

    case 'SET_ORDER_VOUCHER_CODE': {
      return { ...state, order: { ...state.order, voucherCode: action.data } };
    }

    case 'SET_ORDER_VALID_VOUCHER': {
      const { validVoucher, promotionDiscount } = action.data;
      return {
        ...state,
        order: {
          ...state.order,
          validVoucher: validVoucher,
          total: getTotal(
            state.order.orderProducts,
            state.order.deliveryFee,
            state.order.taxFee,
            validVoucher,
            promotionDiscount,
          ),
        },
      };
    }

    case 'SET_ORDER_LOCATION': {
      return { ...state, order: { ...state.order, orderLocation: action.data } };
    }

    case 'SET_PAYMENT_METHOD': {
      return { ...state, paymentMethod: action.data };
    }

    case 'SET_PAYMENT_CARD': {
      return { ...state, paymentCard: action.data };
    }

    case 'SET_CLIENT_INFO': {
      const phone = action.data?.phone || '';
      const parsedPhone = parsePhoneNumber(phone);
      const formattedPhone = normalizePhoneNumber(parsedPhone?.format('E.164') || phone);
      return {
        ...state,
        client: {
          ...state.client,
          ...action.data,
          phone: formattedPhone,
        },
      };
    }

    case 'SET_ADDRESS_INFO': {
      return { ...state, order: { ...state.order, addressInfo: action.data } };
    }

    case 'SET_ORDER_DESCRIPTION': {
      return { ...state, order: { ...state.order, description: action.data } };
    }

    case 'SET_PROMOTION_OK': {
      return { ...state, promotion: { ...action.data } };
    }

    case 'SET_CURRENT_GEOREGION': {
      const geoRegion = action.data;
      const deliveryFee =
        geoRegion?.deliveryFeeExemptValue <= state.order.totalValue &&
        geoRegion.deliveryFeeExemptValue
          ? 0
          : geoRegion?.deliveryFee ?? 0;
      const deliveryFeeExempt =
        geoRegion?.deliveryFeeExemptValue <= state.order.totalValue &&
        geoRegion.deliveryFeeExemptValue
          ? true
          : false;

      return {
        ...state,
        geoRegion,
        order: {
          ...state.order,
          deliveryFee,
          deliveryFeeExempt,
          total: state.order.totalValue + deliveryFee + state.order.taxFee,
        },
      };
    }

    case 'SET_ORDER_TOTAL': {
      return {
        ...state,
        order: {
          ...state.order,
          // total: state.order.totalValue + state.geoRegion.deliveryFee + state.order.taxFee,
          total: getTotal(
            state.order.orderProducts,
            state.order.deliveryFee,
            state.order.taxFee,
            state.order.validVoucher,
            action.data,
          ),
        },
      };
    }

    default:
      return state;
  }
};

const getTotal = (
  orderProducts,
  deliveryFee = 0,
  taxFee = 0,
  validVoucher = null,
  promotionDiscount = 0,
) => {
  let total = 0;
  Object.values(orderProducts).forEach((prod) => {
    if (prod.discountValidation) total += prod.qty * prod.discount;
    else total += prod.qty * prod.price;
    if (prod.isCombo) {
      prod.toppings.forEach((topping) => (total += topping.qty * topping.price));
    }
  });

  let result = total + deliveryFee + taxFee - promotionDiscount;

  if (!validVoucher) return result;

  let voucherDiscount =
    validVoucher.profitType === 0
      ? validVoucher.profitValue
      : total * (validVoucher.profitValue / 100);

  return result - voucherDiscount;
};

const PromotionId = (promotions) => {
  let promotionId = 0;
  if (promotions !== undefined) {
    if (promotions.length > 0) {
      promotions.map((promotion) => {
        if (promotion.isActive) {
          return (promotionId = promotion.id);
        }
        return promotionId;
      });
    }
  }
  return promotionId;
};

const Promotions = (total, promotions, orderProducts) => {
  // const discountType = {
  //   0: 'Value Based',
  //   1: 'Percentage Based',
  //   2: 'Product Based',
  // };

  let orderCount = 0;
  orderProducts.map((order) => {
    return (orderCount += order.qty);
  });

  if (promotions !== undefined) {
    if (promotions.length > 0) {
      promotions.map((promotion) => {
        if (promotion.isActive) {
          if (promotion.promotionType === 0) {
            if (
              promotion.discountType === 0 &&
              orderCount >= promotion?.promotionCount.count &&
              total > promotion?.discountValue.value
            ) {
              total -= promotion?.discountValue.value;
            }
            if (promotion.discountType === 1 && orderCount >= promotion?.promotionCount.count) {
              total = total - (total * promotion?.discountPercentage.percentage) / 100;
            }
          }
        }
        return total;
      });
    }
  }

  return total;
};
