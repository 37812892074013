import * as PropTypes from 'prop-types';

const stringOrNumber = PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired;

const RadioGroupSchema = {
  selectedElement: stringOrNumber,
  updateSelectedElement: PropTypes.func.isRequired,
};

const RadioElementSchema = {
  value: stringOrNumber,
};

export default { RadioGroupSchema, RadioElementSchema };
