import React from 'react';
import styled from 'styled-components';

import welcome from 'assets/images/welcome.svg';

import shared from 'app/shared';

const {
  views: {
    Card,
    Text,
  },
  helpers: { colors },
} = shared;

const{
  H1:{H1,},
  Paragraph:{P,},
} = Text;

const Title = styled(H1)`
  color: ${colors.blue};
  margin-top: 15px;
  margin-bottom: 30px;
`;

const WelcomeWrapper = styled(Card)`
  padding: 30px 60px 60px 60px;
  display: flex;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10%;
`;

const Description = styled(P)`
  color: ${colors.textColor};
  margin-bottom: 27px;
`;

const Illustration = styled.img`
  min-width: 288px;
`;

const Welcome = () => (
  <WelcomeWrapper>
    <TextWrapper>
      <Title>Welcome to Orderoo Dashboard!</Title>
      <Description>
        There is no any data at the moment. In order to start your Orderoo experience, first create
        categories and products and than you are ready to roll.
      </Description>
      <ol>
        <li>
          <Text.Link>1. Add Categories</Text.Link>
        </li>
        <li>
          <Text.Link>2. Add Products in Each Category</Text.Link>
        </li>
      </ol>
    </TextWrapper>
    <div>
      <Illustration src={welcome} />
    </div>
  </WelcomeWrapper>
);

export default Welcome;
