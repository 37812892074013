import Fuse from 'fuse.js';

export const filterData = (data = [], key = '', extraKeys = []) => {
  if (data.length === 0 || key.trim() === '') return data;

  const options = {
    findAllMatches: true,
    threshold: 0.31,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 0,
    keys: [...Object.keys(data[0]), ...extraKeys],
  };

  const fuse = new Fuse(data, options);
  return fuse.search(key);
};
