import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { updateComboProduct } from 'actions/shoppingActions';
import _ from 'lodash';
import Card from '../../../card';
import Icons from '../../../icons';
import HorizontalScrollableSpace from '../../../horizontalScrollableSpace';

import ToggleValidator from '../../../../validators/toggle.validator';
import { AddButton, RemoveButton, BlackButton } from 'app/shared/views/buttons';

import currencyFormatter from 'app/shared/helpers/currencyFormatter';

import hexToRGBA from '../../../../helpers/hexToRGBA';
import isEscape from '../../../../helpers/isEscape';
import slideFromTopAnimation from '../../../../helpers/slideFromTopAnimation';

import Product from './Product';
import Toppings from './Toppings';

import product_image from '../../../../../../assets/images/product_image.png';
import transition from 'app/shared/helpers/transition';
const OverlayWrapper = styled.div`
  background: ${hexToRGBA('#161929', 0.4)};
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: end;
  width: 100%;
  height: 100%;
  position: fixed;
  transition: ${transition};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.show ? 1 : 0)};
  top: 0;
  left: 0;
  z-index: 9999;
  -webkit-transform: translate3d(0, 0, 0);
  padding: 0 !important;
`;

/**
 * TODO: Fix color by name
 */
const CustomCard = styled(Card)`
  box-shadow: 0px 16px 28px rgba(63, 86, 104, 0.1);
  position: relative;
  width: 100%;
  margin-top: 70px;
  overflow: hidden;
  height: 500px;
  border: 0;
  display: flex;
  flex-direction: column;
  border-radius: 20px 20px 0 0;
  transition: height 0.3s ease-in-out;
`;

const CloseIcon = styled(Icons.Cross)`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 8px;
  right: 8px;
  color: black;
  z-index: 999;
  :hover {
    cursor: pointer;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
`;

const ContentWrapper = styled.div`
  transition: 0.4s ease-in-out;
  position: relative;
  opacity: ${(props) => (props.show ? 1 : 0)};
  animation: ${(props) => (props.show ? slideFromTopAnimation : '')} 0.4s ease-in-out;
  width: 100vw;
  max-width: 500px;
  z-index: 100;
`;

const ProductImage = styled.div`
  background: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: ${({ portraitProductImage }) => (portraitProductImage ? '45vh' : '200px')};
  height: 400px;
`;

const ProductActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  border-radius: 6px;
  width: 30%;
  min-height: 40px;
  margin-bottom: 10px;
  padding: 7px;
`;

const ProductOrderCount = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: white;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0 10px;
  min-width: 20px;
  justify-content: center;
`;

const ProductHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  min-height: 50px;
`;

const ProductName = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #1d2036;
`;

const ProductPrice = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: black;
  margin-top: 6px;
`;

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 20px;
  margin-bottom: ${(props) => (props.isCombo ? '0' : '40px')};
`;

const ProductIngredientsDetails = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;

  color: #8f92a7;
`;

const TotalUL = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -1px;
  width: 100%;
`;
const TotalLI = styled.li`
  flex-grow: 1;
  flex-basis: auto;
  margin: 0.25em 0;
  padding: 0 1em;
  text-align: center;
`;

const ProductModal = (props) => {
  const {
    product,
    show,
    toggle,
    categories,
    orderProducts,
    portraitProductImage,
    canOrder = true,
    promotions,
    pageInfo,
  } = props;
  const dispatch = useDispatch();
  const [localOrder, setLocalOrder] = useState([{ ...product, index: 0, toppings: [], qty: 1 }]);
  const [totalValue, setTotalValue] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(0);

  useEffect(() => {
    let productOrderDetails;
    productOrderDetails =
      orderProducts?.filter((obj) => obj.id === product.id).length > 0
        ? [...orderProducts?.filter((obj) => obj.id === product.id)]
        : [{ ...product, index: 0, toppings: [], qty: 1 }];
    setLocalOrder(productOrderDetails);

    const selProd = productOrderDetails.findIndex((prod) => prod.index === product.index);
    selProd > -1 && setSelectedProduct(selProd);
    // eslint-disable-next-line
  }, [product]);

  useEffect(() => {
    let total = 0;
    localOrder.forEach((prd) => {
      if (prd.discountValidation) total += prd.qty * prd.discount;
      else total += prd.qty * prd.price;
      if (prd.isCombo) {
        prd.toppings.map((topping) => (total += topping.qty * topping.price));
      }
    });
    setTotalValue(total);
  }, [localOrder]);

  useEffect(() => {
    document.addEventListener('keyup', toggleOnEscape);
    return function cleanup() {
      document.removeEventListener('keyup', toggleOnEscape);
    };
  });

  useEffect(() => {
    document.body.style.overflowY = show ? 'hidden' : 'unset';
    document.body.style.position = show ? 'fixed' : 'unset';
  }, [show]);

  const toggleOnEscape = (event) => {
    if (event.defaultPrevented) return;
    if (isEscape(event) && show) toggle();
  };

  return (
    <OverlayWrapper show={show} onClick={toggle}>
      <ContentWrapper show={show} onClick={(event) => event.stopPropagation()}>
        <CustomCard style={{ maxHeight: '90vh', overflowY: 'scroll' }}>
          {product?.imagePath ? (
            <ProductImage
              src={product?.imagePath || product_image}
              portraitProductImage={portraitProductImage}
            />
          ) : (
            <div style={{ height: 40, background: '#F9FAFC' }} />
          )}
          <CloseIcon fill={'#2A2E45'} onClick={toggle} width={'8px'} height={'8px'} />
          <ProductHeader>
            <ProductName>{product?.name}</ProductName>
            <div style={{ display: 'flex' }}>
              <ProductPrice>
                {currencyFormatter(
                  product.discountValidation ? product?.discount : product?.price,
                  props.currency,
                )}
              </ProductPrice>
              <ProductPrice
                style={
                  product.discountValidation
                    ? { marginLeft: 20, color: '#748AA1', textDecoration: 'line-through' }
                    : { display: 'none' }
                }
              >
                {currencyFormatter(product.price)}
              </ProductPrice>
            </div>
          </ProductHeader>
          <ProductDetails isCombo={product?.isCombo}>
            <ProductIngredientsDetails>{product?.description}</ProductIngredientsDetails>
          </ProductDetails>
          {product?.isCombo && (
            <ProductDetails isCombo={product.isCombo} style={{ padding: '5px 0' }}>
              <HorizontalScrollableSpace gap={10}>
                {localOrder.length ? (
                  localOrder.map((item, index) => (
                    <Product
                      key={index}
                      name={`${item.name} ${index + 1}`}
                      isSelected={index === selectedProduct}
                      onClick={() => {
                        setSelectedProduct(index);
                      }}
                      remove={() => {
                        localOrder.length > 1 &&
                          setLocalOrder(_.remove(localOrder, (i, ind) => ind !== index));
                        index > 0 && setSelectedProduct(index--);
                      }}
                    />
                  ))
                ) : (
                  <span style={{ width: '100%', textAlign: 'center' }}>No Products added</span>
                )}
              </HorizontalScrollableSpace>
            </ProductDetails>
          )}

          {product?.isCombo && (
            <ProductDetails
              style={{
                maxHeight: 240,
                minHeight: 120,
                overflowY: 'scroll',
                overflowX: 'hidden',
                marginTop: 20,
                paddingBottom: 20,
              }}
              isCombo={product.isCombo}
            >
              {[...new Set(product.toppings?.map((t) => t.categoryId))].sort().map((catId) => (
                <ExtraCategoryWrapper key={catId}>
                  <Name>{categories?.find((cat) => cat.id === catId)?.name}</Name>
                  {categories
                    ?.find((cat) => cat.id === catId)
                    ?.magProducts.map((item) => (
                      <Toppings
                        key={item.id}
                        topping={item}
                        selectedProduct={selectedProduct}
                        localOrder={localOrder}
                        setLocalOrder={setLocalOrder}
                        currency={props.currency}
                        disabled={localOrder.length < 1}
                        canOrder={canOrder}
                      />
                    ))}
                </ExtraCategoryWrapper>
              ))}
            </ProductDetails>
          )}
          {canOrder && (
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <ProductActions>
                <RemoveButton
                  onClick={() => {
                    if (product?.isCombo) {
                      if (localOrder.length > 1) {
                        setLocalOrder(_.dropRight(localOrder, 1));
                      }
                    } else {
                      if (localOrder[0].qty > 0) {
                        setLocalOrder([{ ...localOrder[0], qty: localOrder[0].qty - 1 }]);
                      }
                    }
                  }}
                  style={{ backgroundColor: 'rgb(232, 233, 243)', height: '24px', width: '24px' }}
                  disabled={localOrder.length <= 1 && localOrder[0].qty <= 1}
                />
                <ProductOrderCount>
                  {localOrder.length === 1 ? localOrder[0].qty : localOrder.length}
                </ProductOrderCount>
                <AddButton
                  onClick={() => {
                    if (product?.isCombo) {
                      setLocalOrder(
                        _.concat(localOrder, {
                          ...product,
                          index: localOrder[localOrder.length - 1]
                            ? localOrder[localOrder.length - 1].index + 1
                            : 0,
                          toppings: [],
                          qty: 1,
                        }),
                      );
                    } else {
                      localOrder.length < 1
                        ? setLocalOrder([{ ...product, index: 0, toppings: [], qty: 1 }])
                        : setLocalOrder([{ ...localOrder[0], qty: localOrder[0].qty + 1 }]);
                    }
                  }}
                  style={{ height: '24px', width: '24px' }}
                />
              </ProductActions>
              <BlackButton
                disabled={localOrder.length < 1}
                onClick={() => {
                  dispatch(updateComboProduct(localOrder, promotions));
                  toggle();
                }}
                style={{
                  width: '60%',
                  minHeight: 60,
                  borderRadius: 5,
                  marginBottom: '10px',
                }}
                text={
                  <TotalUL>
                    <TotalLI>
                      Add to order{' '}
                      <span style={{ marginLeft: '10%' }}>
                        {totalValue === 0
                          ? product.discountValidation
                            ? product?.discount
                            : product?.price
                          : totalValue}{' '}
                        {pageInfo.currency}
                      </span>
                    </TotalLI>
                  </TotalUL>
                }
              />
            </div>
          )}
        </CustomCard>
      </ContentWrapper>
    </OverlayWrapper>
  );
};

ProductModal.propTypes = {
  // children: ChildrenValidator.ChildrenRule.isRequired,
  ...ToggleValidator.ToggleSchema,
};

const ExtraCategoryWrapper = styled.div`
  // display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
`;
const Name = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #1d2036;
`;

export default ProductModal;
