import React, { useState, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import shared from 'app/shared/';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  getPokSettings,
  clearPokSettings,
  changePokSettings,
} from 'actions/pokSettingsActions';
import pokView from '../../../assets/images/pokView.svg';
import SettingsTitle from '../components/SettingsTitle';
import { scrollToAlert } from 'app/shared/views/alert';
import { confirmPokCredentials } from 'actions/shoppingActions';
import { Modal, Result } from 'antd';

const {
  views: { Button, AddItemPopup, Input, Spinner, Alert },
  helpers: { colors },
} = shared;

const Wrapper = styled.div`
  padding: 0.7% 30px;
`;

const InlineFlexWrapper = styled.div`
  display: flex;
  margin-top: 3%;
  width: 100%;
`;

const MainLabel = styled.div`
  display: flex;
  font-size: 15px;
  margin-right: 32px;
  color: ${colors.textColor};
`;

const ImageWrapper = styled.div`
  height: 100%;
  margin-left: 3%;
  // width: 10%;
`;

const PokSettings = (props) => {
  const [submitFlag, setSubmitFlag] = useState(false);
  const { getPokSettings } = props;

  const [pokSettingsStatus, setPokSettingsStatus] = useState('IDLE');
  const [pokAlert, setPokAlert] = useState({ show: false, message: '' });
  const [isVisible, setIsVisible] = useState(false);
  const alertRef = useRef(null);

  const setStatus = (status) => {
    setPokAlert({
      show: true,
      message: status === 'OK' ? 'Update successful!' : 'Action failed!',
      type: status === 'OK' ? 'success' : 'error',
    });
    scrollToAlert(alertRef);
  };

  useEffect(() => {
    let alertTimer = setTimeout(() => setPokAlert({ show: false, message: '' }), 5000);
    return () => {
      clearTimeout(alertTimer);
    };
  }, [setPokAlert]);

  useEffect(() => {
    getPokSettings(setPokSettingsStatus);
  }, [getPokSettings]);

  if (pokSettingsStatus === 'LOADING') return <Spinner size={80} style={{ height: '100px' }} />;

  return (
    <Wrapper>
      <div style={{ flex: 1 }}>
        <SettingsTitle>POK Configuration</SettingsTitle>
        <Alert ref={alertRef} {...pokAlert} />
        <InlineFlexWrapper>
          <MainLabel>Add POK Credentials</MainLabel>
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Formik
              enableReinitialize
              initialValues={{
                keyId:
                  (props.pokSettings.data && props.pokSettings.data.keyId) || '',
                keySecret:
                  (props.pokSettings.data && props.pokSettings.data.keySecret) || '',
                merchantId:
                  (props.pokSettings.data && props.pokSettings.data.merchantId) || '',
              }}
              validationSchema={Yup.object().shape({
                keyId: Yup.string().required('Key Id is required'),
                keySecret: Yup.string().required('Key Secret is required'),
                merchantId: Yup.string().required('Merchant Id is required'),
              })}
              onSubmit={(values, { setSubmitting }) => {
                // setTimeout(() => {
                //     alert(JSON.stringify(values, null, 2));
                //     setSubmitting(false);
                // }, 500);

                props.changePokSettings(
                  {
                    keyId: values.keyId,
                    keySecret: values.keySecret,
                    merchantId: values.merchantId,
                    id: 0,
                  },
                  setStatus,
                );
              }}
            >
              {(innerProps) => {
                const { values, touched, errors, handleChange, handleSubmit } = innerProps;
                if (!submitFlag) {
                  props.setExecuteOnSubmit(() => {
                    return handleSubmit;
                  });
                  setSubmitFlag(true);
                }
                return (
                  <div>
                    <AddItemPopup.InputRow>
                      <Input
                        labelText='Key ID'
                        value={values.keyId}
                        onChange={handleChange}
                        id='keyId'
                      />
                      <Alert
                        type='error'
                        show={errors.keyId && touched.keyId}
                        message={errors.keyId}
                      />
                    </AddItemPopup.InputRow>
                    <AddItemPopup.InputRow>
                      <Input
                        labelText='Key Secret'
                        value={values.keySecret}
                        onChange={handleChange}
                        id='keySecret'
                      />
                      <Alert
                        type='error'
                        show={errors.keySecret && touched.keySecret}
                        message={errors.keySecret}
                      />
                    </AddItemPopup.InputRow>
                    <AddItemPopup.InputRow>
                      <Input
                        labelText='Merchant ID'
                        value={values.merchantId}
                        onChange={handleChange}
                        id='merchantId'
                      />
                      <Alert
                        type='error'
                        show={errors.merchantId && touched.merchantId}
                        message={errors.merchantId}
                      />
                    </AddItemPopup.InputRow>
                    <AddItemPopup.InputRow style={{ textAlign: 'left' }}>
                      <Button.Button
                        color={colors.red}
                        text='Clear'
                        size={Button.ButtonSize.extraMedium}
                        onClick={() => props.clearPokSettings(setStatus)}
                        style={{ marginRight: 8 }}
                      />
                      <Button.Button
                        color={colors.blue}
                        text='Save'
                        size={Button.ButtonSize.extraMedium}
                        onClick={ () => {
                          let credentials = { keyId: values.keyId, keySecret: values.keySecret };
                          confirmPokCredentials(credentials).then((data) => {
                            if (data.data) {
                              handleSubmit();
                            } else {
                              setIsVisible(true)
                            }
                          });
                        }
                        }
                      />
                    </AddItemPopup.InputRow>
                    <Modal
                      visible={isVisible}
                      onCancel={() => setIsVisible(false)}
                      footer={null}
                      centered
                      maskClosable={true}
                      destroyOnClose={true}
                      closable={false}
                    >
                      <Result
                        status="error"
                        subTitle='Wrong POK Credentials Please Check And Enter Again'
                      />
                    </Modal>
                  </div>
                );
              }}
            </Formik>
          </div>
        </InlineFlexWrapper>
      </div>
      <ImageWrapper>
        <img style={{
          height: '120px',
          width: '120px'
        }} src={pokView} alt={'POK Logo'} />
      </ImageWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  pokSettings: state.pokSettings,
});

export default connect(mapStateToProps, {
  changePokSettings,
  getPokSettings,
  clearPokSettings,
})(PokSettings);

export { PokSettings };
