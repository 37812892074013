import { keyframes } from 'styled-components';

export default keyframes`
  0% {
    transform: translateY(100%);
  }
  
  100% {
    transform: translateY(0);
  }
`;
