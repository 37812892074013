import React from 'react';
import styled from 'styled-components';
import Input from '../input';

const Wrapper = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  flex: 1;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  display: flex;
  color: #1d2036;
  margin-bottom: 10px;
`;

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

const InputPhoneNumber = ({ phoneNumber, setPhoneNumber }) => (
  <Wrapper>
    <Header>Input Phone Number</Header>
    <Body>
      <Input.PhoneNumberInput
        placeholder={'XXXXXXXXX'}
        defaultCountry={'AL'}
        value={phoneNumber}
        onChange={(number) => setPhoneNumber(number)}
      />
    </Body>
  </Wrapper>
);

export default InputPhoneNumber;
