import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { connect } from 'react-redux';
import shared from 'app/shared';
import { getNotifications, markAsRead } from 'actions/notificationAction'

import NotificationList from './notificationList';

const {
  views: {
    Page,
    SimpleFilterBar,
    Text,
  },
  helpers: {
    colors,
  }
} = shared;


const NotificationsSectionTitle = styled(Text.H4)`
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: ${colors.textColor};
  border-bottom: 1px solid ${colors.lightGrey};
  padding-bottom: 10px;
  padding-left: 5px;
`;

const NotificationsSectionWrapper = styled.div`
  padding: 20px 0;
`;

const NotificationsSectionList = styled(NotificationList)`
  & li {
    padding-left: 5px;
    padding-right: 5px;
  }
`;

const NotificationsSection = ({ title, items, markAsRead, ...rest }) => (
  <NotificationsSectionWrapper {...rest}>
    <NotificationsSectionTitle>{title}</NotificationsSectionTitle>
    <NotificationsSectionList data={items} markAsRead={markAsRead} />
  </NotificationsSectionWrapper>
);

const initialState = {
  filters: {
    selectedPeriod: 'today',
  },
  customDate: {startDate: null, endDate: null},
};

class NotificationsPage extends React.Component {
  state = {
    ...initialState,
    sections: {},
  };

  componentWillMount() {
    this.groupNotificationsByDate();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.notifications !== this.props.notifications) {
      this.groupNotificationsByDate();
    }
  }

  groupNotificationsByDate() {
    const { notifications: { data } } = this.props;

    const groupedByDate = data.reduce((grouped, item) => {
      const dt = moment(item.creationDate).format('YYYY-MM-DD');
      grouped[dt] = grouped[dt] || [];
      grouped[dt].push(item);
      return grouped;
    }, {});

    Object.values(groupedByDate).forEach(items => {
      items.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));
    });

    const today =  moment().startOf('day');
    const yesterday = today.clone().subtract(1, 'day');

    const notificationSections = Object.keys(groupedByDate).sort((a, b) => new Date(b) - new Date(a)).map(dt => {
      let title = '';
      if (moment(dt).startOf('day').isSame(today)) {
        title = 'Today';
      } else if (moment(dt).startOf('day').isSame(yesterday)) {
        title = 'Yesterday';
      } else {
        title = dt;
      }

      return {
        title,
        date: dt,
        items: groupedByDate[dt],
      };
    });

    this.setState({
      sections: notificationSections,
    });
  }

  updateSelectedPeriod = selectedPeriod => {
    this.setState({ filters: { selectedPeriod } });
  };

  updateCustomDate = customDate => {
    this.setState({customDate: {startDate: customDate.startDate, endDate: customDate.endDate}})
  }

  clearFilters = () => {
    this.setState({
      filters: initialState.filters,
      customDate: initialState.customDate,
    });
  };

  render() {
    const {
      filters: {
        selectedPeriod
      },
      customDate,
      sections,
    } = this.state;

    const filteredSections = dateTimeFilter(selectedPeriod, customDate, sections);

    return (
      <Page>
        <SimpleFilterBar
          selectedPeriod={selectedPeriod}
          customDate={customDate}
          updateSelectedPeriod={this.updateSelectedPeriod}
          clearFilters={this.clearFilters}
          updateCustomDate={this.updateCustomDate}
        />
          {
            filteredSections.map(section =>
              <NotificationsSection
                key={section.title}
                title={section.title}
                items={section.items}
                markAsRead={this.props.markAsRead}
              />
            )
          }
      </Page>
    );
  }
};

const dateTimeFilter = (selectedPeriod, customDates, sections) => {
  if (['today', 'yesteday'].includes(selectedPeriod)) {
    return sections.filter(section => section.title.toUpperCase() === selectedPeriod.toUpperCase());
  }

  const yesteday = moment().subtract(1, 'day').endOf('day');
  const lastWeek = yesteday.clone().subtract(1, 'week').startOf('day');
  const lastMonth = yesteday.clone().subtract(1, 'month').startOf('day');

  switch (selectedPeriod) {
    case 'today':
    case 'yesterday':  
      return sections.filter(section => section.title.toUpperCase() === selectedPeriod.toUpperCase());

    case 'last-week':
      return sections.filter(section => moment(section.date).isBetween(lastWeek, yesteday));

    case 'last-month':
      return sections.filter(section => moment(section.date).isBetween(lastMonth, yesteday));

    case 'custom':
      return sections.filter(section => moment(section.date).isBetween(
        moment(customDates.startDate).startOf('day'),
        moment(customDates.endDate).endOf('day'),
        undefined,
        '[]'
      ));

    case 'total':
      return sections;

    default:
      return sections;
  }
};

const mapStateToProps = state => ({
  notifications: state.notifications,
});

export default connect(
  mapStateToProps,
  {
    getNotifications,
    markAsRead
  }
)(NotificationsPage);
