import React, { useEffect, useMemo } from 'react';
import colors from 'app/shared/helpers/colors';
import styled from 'styled-components';
import moment from 'moment';
import { SMS_STATUS } from 'app/marketing/smsStatus';

const ConversationsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 292px;
  border-right: 1px solid ${colors.lightGrey};
`;

const CardHeader = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  height: 67px;
  border-bottom: 1px solid ${colors.lightGrey};
  width: 100%;
  color: ${colors.textColor};
`;

const ConversationsListHeader = styled(CardHeader)`
  padding: 20px;
`;

const ConversationsListEntries = styled.div`
  overflow: auto;
`;

const ConversationListEntryWrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.lightGrey};
  background-color: ${(props) => (props.active ? colors.blue : 'inherit')};
  cursor: pointer;
  height: 58px;

  &:hover {
    background-color: ${(props) => (props.active ? colors.blue : '#F9FAFC')};
  }
`;

const ConversationName = styled.span`
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  font-size: 12px;
  color: ${(props) => (props.active ? colors.white : colors.black)};
`;

const ConversationLastUpdated = styled.span`
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: ${(props) => (props.active ? colors.white : colors.textColor)};
`;

const ConversationListEntry = ({ name, lastUpdate, unread, onClick, active = false }) => {
  return (
    <ConversationListEntryWrapper active={active} onClick={onClick}>
      <ConversationName active={active}>{name}</ConversationName>
      <ConversationLastUpdated active={active}>{lastUpdate}</ConversationLastUpdated>
    </ConversationListEntryWrapper>
  );
};
const getLastSmsDate = (smsConversation) => {
  if (!smsConversation) return null;
  let dates = [
    ...smsConversation.smsMessages,
    ...smsConversation.smsCampaignMessages.filter((m) =>
      [SMS_STATUS.Delivered, SMS_STATUS.Sent].includes(m.status),
    ),
  ]
    .filter((m) => m.createDate != null)
    .map((d) => moment(d.createDate));
  return dates.reduce((a, b) => (a > b ? a : b), null);
};

const ConversationsList = ({ selectedClientId, setSelectedClientId = null, clients = [] }) => {
  const conversations = useMemo(
    () =>
      clients
        .filter((c) => c.smsConversations && c.smsConversations.length > 0)
        .map((c) => ({
          id: c.id,
          name: c.fullName,
          lastUpdate: getLastSmsDate(c.smsConversations[0]),
          unread: 0,
        }))
        .filter((c) => c.lastUpdate)
        .sort((a, b) => b.lastUpdate - a.lastUpdate),
    [clients],
  );

  useEffect(() => {
    conversations[0] && setSelectedClientId(conversations[0].id);
  }, [conversations, setSelectedClientId]);

  return (
    <ConversationsListWrapper>
      <ConversationsListHeader>Conversations</ConversationsListHeader>
      {conversations && (
        <ConversationsListEntries>
          {conversations.map((c) => (
            <ConversationListEntry
              onClick={() => setSelectedClientId && setSelectedClientId(c.id)}
              active={c.id === selectedClientId}
              key={c.id}
              name={c.name}
              lastUpdate={c.lastUpdate?.format('ddd hh:mm A')}
              unread={c.numUnread}
            />
          ))}
        </ConversationsListEntries>
      )}
    </ConversationsListWrapper>
  );
};

export default ConversationsList;
