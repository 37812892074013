import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';

import moment from 'moment-timezone';
import Select from 'react-select';

import CheckBox from 'app/shared/views/checkbox';
import colors from 'app/shared/helpers/colors';

const DeliveryHours = ({ deliveryHours, setDeliveryHours, setTimeZone }) => {
  const [timezoneSelect, setTimezoneSelect] = useState(() => {
    const options = moment.tz.names().reduce((tz, tzName) => {
      const include = ['Europe', 'America'].some((continent) => {
        return tzName.startsWith(continent);
      });
      if (include) {
        tz.push({ value: tzName, label: `${tzName} ${moment.tz(tzName).format('Z')}` });
      }
      return tz;
    }, []);

    return {
      options,
      value: null,
    };
  });

  const updateDeliveryHours = useCallback(
    (dayDeliveryHours, day, key, time) => {
      let newData = deliveryHours.find((entry) => entry.day === day) ?? dayDeliveryHours;
      newData[key] = time;

      deliveryHours.find((entry) => entry.day === day)
        ? setDeliveryHours((prevState) => [...prevState])
        : setDeliveryHours((prevState) => [...prevState, newData]);
    },
    [setDeliveryHours, deliveryHours],
  );

  const [allClosed, setAllClosed] = useState(false);

  useEffect(() => {
    const enabledHours = deliveryHours.filter((day) => day.enabled === true);
    setAllClosed(enabledHours.length === 0);
  }, [deliveryHours]);

  useEffect(() => {
    if (timezoneSelect.value === null) {
      const guess = moment.tz.guess();
      const value = timezoneSelect.options.find((opt) => {
        return opt.value === guess;
      });
      if (value) {
        setTimeZone(getUtcOffset(value.value));

        setTimezoneSelect({
          ...timezoneSelect,
          value,
        });
      }
    }
  }, [timezoneSelect, updateDeliveryHours, setTimeZone]);

  const daysOfWeek = useMemo(() => {
    let days = moment.weekdays();
    days.push(days.shift());
    return days;
  }, []);
  const daysOfWeekShort = useMemo(() => {
    let days = moment.weekdaysShort();
    days.push(days.shift());
    return days.map((d) => d.toLowerCase());
  }, []);

  const updateDayCheckClicked = (dayDeliveryHours, checked) => {
    let selectedDay =
      deliveryHours.find((entry) => entry.day === dayDeliveryHours.day) ?? dayDeliveryHours;

    if (!checked) {
      selectedDay.enabled = false;
    } else {
      selectedDay.enabled = true;
    }
    deliveryHours.find((entry) => entry.day === dayDeliveryHours.day)
      ? setDeliveryHours((prevState) => [...prevState])
      : setDeliveryHours((prevState) => [...prevState, selectedDay]);
  };

  const updateAllDaysCheckClicked = (deliveryHours, checked) => {
    if (checked) {
      deliveryHours.map((day) => (day.enabled = false));
    } else {
      deliveryHours.map((day) => (day.enabled = true));
    }
    setDeliveryHours((prevState) => [...prevState]);
  };

  const getUtcOffset = (timeZoneName) => {
    try {
      const now = moment.utc();

      return moment(now).tz(timeZoneName).utcOffset() / 60;
    } catch (e) {
      return 0;
    }
  };

  return (
    <OnlineDeliveryHours>
      <OnlineDeliveryHoursEntry>
        <OnlineDeliveryHoursHeader>Days</OnlineDeliveryHoursHeader>
        <OnlineDeliveryHoursHeader>First Period</OnlineDeliveryHoursHeader>
        <OnlineDeliveryHoursHeader></OnlineDeliveryHoursHeader>
        <OnlineDeliveryHoursHeader>Second Period</OnlineDeliveryHoursHeader>
      </OnlineDeliveryHoursEntry>

      {daysOfWeekShort.map((day, index) => {
        let tempDay = deliveryHours.find((entry) => entry.day === day);
        tempDay = tempDay ?? {
          pageId: 0,
          day: day,
          enabled: false,
          openingHour1: '',
          closingHour1: '',
          openingHour2: '',
          closingHour2: '',
        };
        const key1 = 'openingHour1';
        const key2 = 'closingHour1';
        const key3 = 'openingHour2';
        const key4 = 'closingHour2';

        let isChecked = tempDay.enabled;

        return (
          <OnlineDeliveryHoursEntry key={day}>
            <div>
              <DayCheckbox
                text={<span className="label">{daysOfWeek[index]}</span>}
                checked={isChecked}
                onChange={(e) => updateDayCheckClicked(tempDay, e.target.checked)}
              />
            </div>
            <div>
              <DeliveryTimePicker
                value={tempDay.openingHour1}
                onChange={(e) => updateDeliveryHours(tempDay, tempDay.day, key1, e.target.value)}
                type={'time'}
                disabled={!isChecked}
              />
              <DeliveryHoursSep separator=":" />
              <DeliveryTimePicker
                value={tempDay.closingHour1}
                onChange={(e) => updateDeliveryHours(tempDay, tempDay.day, key2, e.target.value)}
                type={'time'}
                disabled={!isChecked}
              />
            </div>
            <div>
              <DeliveryHoursSep separator="-" />
            </div>
            <div>
              <DeliveryTimePicker
                value={tempDay.openingHour2}
                onChange={(e) => updateDeliveryHours(tempDay, tempDay.day, key3, e.target.value)}
                type={'time'}
                disabled={!isChecked}
              />
              <DeliveryHoursSep separator=":" />
              <DeliveryTimePicker
                value={tempDay.closingHour2}
                onChange={(e) => updateDeliveryHours(tempDay, tempDay.day, key4, e.target.value)}
                type={'time'}
                disabled={!isChecked}
              />
            </div>
          </OnlineDeliveryHoursEntry>
        );
      })}

      <OnlineDeliveryHoursEntry>
        <DayCheckbox
          text={<span className="label">Close All</span>}
          checked={allClosed}
          onChange={(e) => updateAllDaysCheckClicked(deliveryHours, e.target.checked)}
        />
      </OnlineDeliveryHoursEntry>

      <div style={{ display: 'flex', flexDirection: 'column', marginTop: 32 }}>
        <OnlineDeliveryHoursHeader>TimeZone</OnlineDeliveryHoursHeader>

        <TimezoneSelect
          value={timezoneSelect.value}
          options={timezoneSelect.options}
          placeholder="Select Timezone"
          onChange={(val) => {
            // updateDeliveryHours('timeZone', getUtcOffset(val.value));

            setTimezoneSelect({
              ...timezoneSelect,
              value: val,
            });
          }}
        />
      </div>
    </OnlineDeliveryHours>
  );
};

const OnlineDeliveryHours = styled.div`
  width: 100%;
  font-weight: normal;
  font-size: 15px;
  color: ${colors.textColor};
`;

const OnlineDeliveryHoursEntry = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;

  & > * {
    &:nth-child(2n + 1) {
      min-width: 100px;
    }

    &:nth-child(2n) {
      display: flex;
      width: 30%;
    }

    &:nth-child(3) {
      display: flex;
      justify-content: center;
      width: 20%;
    }

    &:first-child {
      width: 20%;
    }
  }
`;

const OnlineDeliveryHoursHeader = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: ${colors.textColor};
`;

const DeliveryTimePicker = styled.input`
  min-width: 112px;
  font-size: 13px;
  line-height: 23px;
  color: ${colors.textColor};
  padding: 2px 6px;
`;

const DayCheckbox = styled(CheckBox)`
  & .label {
    font-size: 14px;
    line-height: 22px;
    color: ${colors.darkestGrey};
  }
`;

const DeliveryHoursSep = styled.span`
  &::before {
    content: '${(props) => props.separator}';
    font-size: 13px;
    line-height: 23px;
    color: ${colors.textColor};
    margin: 0 10px;
    vertical-align: -25%
  }
`;

const TimezoneSelect = styled(Select)`
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  margin: 15px 0;
  max-width: 420px;

  & .currency__control {
    border-color: #e1e6ec;
  }

  & .currency__single-value {
    color: #434343;
    font-weight: normal;
  }
`;

export default DeliveryHours;
