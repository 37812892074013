const initialState = {
    data: null
}

export default (state=initialState, action) => {
    switch(action.type){
        case 'GET_CHECKOUT_DETAILS_OK':
            return{
                data: {...action.data},
            }
        default: 
            return {...state}
    }
}