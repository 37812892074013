import React from 'react';
import styled from 'styled-components';

import Inputs from 'app/shared/views/input';

const StyledInput = styled(Inputs.Input)`
  background: #f9fafc;
  border-radius: 20px;
  border: none;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #748aa1;
`;

const inputStyles = {
  padding: '11px 20px',
};

export const Input = ({ style, ...props }) => {
  return (
    <StyledInput
      height={35}
      style={{
        ...inputStyles,
        ...style,
      }}
      {...props}
    />
  );
};

const StyledInputArea = styled(Inputs.InputArea)`
  background: #f9fafc;
  border-radius: 20px;
  border: none;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #748aa1;
  min-height: 86px;
`;

const inputAreaStyles = {
  padding: '15px 0',
  margin: '0 20px',
  height: '100%',
  borderTop: '1px solid #ffffff',
  width: '-webkit-fill-available',
};

export const InputArea = ({ style, ...props }) => {
  return (
    <StyledInputArea
      height={35}
      style={{
        ...inputAreaStyles,
        ...style,
      }}
      {...props}
    />
  );
};

export default Input;
