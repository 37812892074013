import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import shared from 'app/shared';
import { addTables, removeTables } from 'actions/tableActions';
import { getAll, remove, edit } from 'actions/crudActions';
import crudTypes from 'config/crudTypes';
import { downloadAndZip } from 'app/shared/helpers/downloadUrl';

import SettingsLabel from '../components/SettingsLabel';
import InputStepper from '../components/InputStepper';
import EditTable from '../components/EditTable';
import AddTableCategoryPopup from '../components/AddTableCategoryPopup';

import { Row, Col, Tabs } from 'antd';
import { scrollToAlert } from 'app/shared/views/alert';
const { TabPane } = Tabs;

const {
  views: {
    Alert,
    Button: { Button, WhiteButton },
    Text: {
      Link: { Link },
    },
    Icons,
  },
  helpers: { colors },
} = shared;

const TableOrderSettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
`;

const RowFlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
`;

const InlineFlexWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 5px 0;
`;

const QrCodeLink = styled(Link)`
  :hover {
    cursor: pointer;
  }
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #3185fc;
`;

const DeleteLink = styled(Link)`
  :hover {
    cursor: pointer;
  }
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #ff3a44;
`;

const TableCategoryName = styled.div`
  font-weight: 600;
  fontsize: 14px;
  color: #2a2e45;
`;

const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const QRCodeLabel = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #748aa1;
`;

const QRCodeCount = styled.div`
  font-weight: bold;
  font-size: 12px;
  color: #3185fc;
`;

const FlexSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TableWrapper = styled.div``;

const Header = styled.div`
  width: 100%;
  background: #f9fafc;
  border-radius: 2px 2px 0px 0px;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #3185fc;
  padding: 12px 30px;
`;

const CategoryLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #3185fc;
  padding: 10px 0 0 0;
`;

const TableOrderSettings = ({ tableCategories, addTables, removeTables, edit, getAll, remove }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [numberOfTables, setNumberOfTables] = useState(0);
  const [alert, setAlert] = useState({ show: false, message: '' });
  const alertRef = useRef(null);

  const [showAddCategoryPopup, setShowAddCategoryPopup] = useState(false);
  useEffect(() => {
    getAll(crudTypes.tableCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedCategory === null && tableCategories?.length > 0) {
      setSelectedCategory(tableCategories[0].id.toString());
      setNumberOfTables(tableCategories[0].tables.length);
    }
  }, [tableCategories, selectedCategory]);

  useEffect(() => {
    let alertTimer = setTimeout(() => setAlert({ show: false, message: '' }), 5000);
    return () => {
      clearTimeout(alertTimer);
    };
  }, [alert]);

  const onGenerate = () => {
    const currentNumberOfTables = tableCategories.find(
      (cat) => cat.id.toString() === selectedCategory,
    )?.tables.length;
    if (numberOfTables > currentNumberOfTables) {
      addTables(numberOfTables - currentNumberOfTables, selectedCategory, updateStatus);
    } else if (numberOfTables < currentNumberOfTables) {
      removeTables(currentNumberOfTables - numberOfTables, selectedCategory, updateStatus);
    }
  };

  const downloadAll = () => {
    const tableQrCodes = tableCategories
      .find((cat) => cat.id.toString() === selectedCategory)
      .tables.map((table) => ({
        url: table.qrPath,
        filename: `${table.name} QR code.svg`,
        mime: 'image/svg',
      }));

    downloadAndZip(tableQrCodes, 'QR Codes.zip');
  };

  const updateStatus = (status) => {
    setAlert({
      show: true,
      message: status === 'OK' ? 'Update successful!' : 'Action failed!',
      type: status === 'OK' ? 'success' : 'error',
    });
    scrollToAlert(alertRef);
    getAll(crudTypes.tableCategory);
  };

  return (
    <TableOrderSettingsWrapper>
      <Header> QR Codes </Header>
      <Alert ref={alertRef} {...alert} />
      <Tabs
        tabPosition={'left'}
        activeKey={selectedCategory}
        onChange={(key) => {
          key !== '-1' && setSelectedCategory(key);
          key !== '-1' &&
            setNumberOfTables(
              tableCategories.find((cat) => cat.id.toString() === key)?.tables.length,
            );
        }}
      >
        {tableCategories?.map((tableCategory, index) => (
          <TabPane
            tab={
              <TabContent>
                <TableCategoryName>{tableCategory.name}</TableCategoryName>
                <FlexSpaceBetween>
                  <QRCodeLabel>QR Codes</QRCodeLabel>
                  <QRCodeCount>{tableCategory.tables.length}</QRCodeCount>
                </FlexSpaceBetween>
              </TabContent>
            }
            key={tableCategory.id}
          >
            <div style={{ overflowY: 'scroll', height: 'calc(100vh - 240px)', paddingRight: 24 }}>
              <RowFlexWrapper>
                <CategoryLabel>{tableCategory.name}</CategoryLabel>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    minWidth: 120,
                    justifyContent: 'space-between',
                  }}
                >
                  <QrCodeLink
                    target="_blank"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowAddCategoryPopup(tableCategory);
                    }}
                  >
                    Rename
                  </QrCodeLink>
                  <DeleteLink
                    target="_blank"
                    onClick={(e) => {
                      e.preventDefault();
                      if (
                        window.confirm(`Are you sure you want to delete ${tableCategory.name}?`)
                      ) {
                        remove(tableCategory.id, crudTypes.tableCategory, updateStatus);
                      }
                    }}
                  >
                    Delete
                  </DeleteLink>
                </div>
              </RowFlexWrapper>
              <InlineFlexWrapper>
                <SettingsLabel label="Set Number of Tables:" />
                <InputStepper
                  min={0}
                  value={numberOfTables}
                  onChange={setNumberOfTables}
                  style={{ width: '100px' }}
                />
                <Button
                  text="Generate QR Codes"
                  color={colors.white}
                  height={32}
                  onClick={onGenerate}
                  style={{
                    width: '195px',
                    height: '32px',
                    marginLeft: '20px',
                    fontSize: '14px',
                    backgroundColor: '#3185FC',
                  }}
                />
              </InlineFlexWrapper>

              <InlineFlexWrapper
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
              >
                <SettingsLabel label="QR Codes Generated" />
                <QrCodeLink
                  href="download"
                  target="_blank"
                  onClick={(e) => {
                    e.preventDefault();
                    downloadAll();
                  }}
                  style={{
                    alignSelf: 'flex-end',
                  }}
                >
                  Download All
                </QrCodeLink>
              </InlineFlexWrapper>
              <TableWrapper>
                <Row
                  type="flex"
                  style={{
                    alignItems: 'center',
                    padding: '20px 0px 20px 20px',
                    margin: 0,
                    borderBottom: '1px solid #E8EAF0',
                  }}
                  justify="center"
                >
                  <Col span={5}>
                    <div style={{ fontWeight: 600, fontSize: 12, color: '#748AA1' }}>
                      QR CODE ID
                    </div>
                  </Col>
                  <Col span={7}>
                    <div style={{ fontWeight: 600, fontSize: 12, color: '#748AA1' }}>
                      VISIBLE NAME
                    </div>
                  </Col>
                  <Col span={6}>
                    <div
                      style={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: '#748AA1',
                        textAlign: 'center',
                      }}
                    >
                      LAST MODIFIED
                    </div>
                  </Col>
                  <Col span={6}>
                    <div
                      style={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: '#748AA1',
                        textAlign: 'center',
                      }}
                    >
                      ACTIONS
                    </div>
                  </Col>
                </Row>
                {tableCategories[index]?.tables.map((t) => (
                  <EditTable
                    key={t.id}
                    table={t}
                    tableCategory={tableCategory}
                    edit={edit}
                    remove={remove}
                    updateStatus={updateStatus}
                  />
                ))}
              </TableWrapper>
            </div>
          </TabPane>
        ))}

        <TabPane
          tab={
            <WhiteButton
              text={
                <div style={{ display: 'flex' }}>
                  <Icons.Add
                    fill={colors.blue}
                    style={{
                      width: 40,
                      height: 24,
                      borderRadius: 3,
                    }}
                  />
                  <span style={{ color: colors.blue, marginRight: 4 }}>Add New</span>
                </div>
              }
              style={{ border: 'none', width: '100%', height: '100%', display: 'flex' }}
              onClick={() => setShowAddCategoryPopup(!showAddCategoryPopup)}
            />
          }
          key="-1"
        />
      </Tabs>
      <AddTableCategoryPopup
        show={showAddCategoryPopup}
        toggle={() => setShowAddCategoryPopup(!showAddCategoryPopup)}
      />
    </TableOrderSettingsWrapper>
  );
};

const mapStateToProps = (state) => ({
  tableCategories: state.tableCategories.data,
});

export default connect(mapStateToProps, {
  addTables,
  removeTables,
  getAll,
  remove,
  edit,
})(TableOrderSettings);
