import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setOrderLocation,
  setOrderTotal,
  setOrderValidVoucher,
  setOrderVoucherCode,
} from 'actions/shoppingActions';
import styled from 'styled-components';
import * as Yup from 'yup';
import Icons from 'app/shared/views/icons';
import currencyFormatter from 'app/shared/helpers/currencyFormatter';
import { Elements } from '@stripe/react-stripe-js';
import PaymentPanel from './PaymentPanel';
import ErrorMessage from './ErrorMessage';
import { BlackButton } from 'app/shared/views/buttons';
import { Button, Modal, Result } from 'antd';
import { PAYMENT_METHODS } from '../constants';
import { getClientPromotions } from 'actions/crudActions';
import crudTypes from 'config/crudTypes';
import LocationPicker from 'app/shared/views/locationPicker';
import PaymentMethods from './PaymentMethod';
import ApplyVoucherModal from './ApplyVoucherModal';
import LocationDetails from '.././steps/LocationDetails';

const stripeElementsOptions = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Inter:400,500',
    },
  ],
};

const HeaderCol = styled.div`
  position: sticky !important;
  top: 0 !important;
  background-color: #ffffff !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin-top: 20px;
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  color: #2a2e45;
  flex: 1;
  padding: 0 1rem !important;
`;

const Body = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
`;

const ProductName = styled.div`
  font-size: 12px;
  color: #1d2036;
  flex: 1;
  line-height: 20px;
`;

const ProductPrice = styled.div`
  font-size: 12px;
  color: black;
  flex: 1;
  font-weight: 600;
  text-align: right;

  &:hover {
    ${(props) => `${props.hover}`}
  }
`;

const InnerRowWrapper = styled.div`
  display: flex !important;
  flex-direction: column !important;
  background-color: #ffffff !important;
  position: relative !important;
  z-index: 999 !important;
  bottom: 0 !important;
  width: 100vw !important;
  max-width: 500px !important;
  margin-top: auto !important;
  overflow: auto;

  opacity: ${({ isClosing }) => (isClosing ? 0.7 : 1)};
`;

const ButtonText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  width: 100%;
`;

const ButtonInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
`;

const AllQty = styled.p`
  border: 2px solid white;
  border-radius: 50%;
  padding: 0.25rem 0.4rem;
  margin-right: 5px;
`;

const Options = styled.div`
  margin-top: 0rem;
`;

const OptionDiv = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  background-color: #f9fafc;
  cursor: pointer;
  padding: 20px 7px 20px 7px;
  justify-content: space-between;
`;

const stopEventBubbling = (e) => {
  e.stopPropagation();
};

const OrderReview = (props) => {
  const {
    show,
    setShow,
    pageInfo,
    stripe,
    setPaymentCard,
    collectClientName,
    internalOrder,
    onProceed,
    showPokModal,
    setShowPokModal,
    initialStep,
    setCurrentStep,
    pokUrl,
  } = props;

  const childRef = useRef(null);
  const headerRef = useRef(null);
  const dispatch = useDispatch();
  const order = useSelector((state) => state.shopping.order);
  const clientInfo = useSelector((state) => state.shopping.client);
  const paymentMethod = useSelector((state) => state.shopping.paymentMethod);
  const paymentCard = useSelector((state) => state.shopping.paymentCard);
  const promotion = useSelector((state) => state.shopping.promotion);
  const vouchers = useSelector((state) => state.vouchers);
  const validVoucher = useSelector((state) => state.shopping.order.validVoucher);
  const [applingVoucher, setApplingVoucher] = useState(false);
  const [clientPromotions, setClientPromotions] = useState([]);
  const [error, setError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(66);
  const pokData = useSelector((state) => state.pokSettings.data);
  const [pokErrorModal, setPokErrorModal] = useState(false);
  const [promotionValue, setPromotionValue] = useState(0);
  const [showPaymentMethods, setShowPaymentMethods] = useState(false);
  const [confirmedPaymentMethod, setConfirmedPaymentMethod] = useState(0);
  const [showLocation, setShowLocation] = useState(false);
  const [openPokModal, setOpenPokModal] = useState(false);

  useEffect(() => {
    getClientPromotions(crudTypes.client, clientInfo.phone)
      .then(function (res) {
        setClientPromotions(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [clientInfo]);

  useEffect(() => {
    if (!headerRef?.current) return;
    setHeaderHeight(headerRef?.current.clientHeight);
  }, []);

  useEffect(
    useCallback(() => {
      dispatch(setOrderValidVoucher(null));
      dispatch(setOrderVoucherCode(''));
    }, [dispatch]),
    [],
  );

  useEffect(() => {
    if (order.description?.length > 125) {
      setError({
        message: 'Max 125 characters allowed in Order Details!',
      });
    } else {
      setError(false);
    }
  }, [order.description]);

  useEffect(() => {
    if (order.totalValue === 0) {
      setCurrentStep(initialStep || 0);
      setShow(false);
    }
  }, [order, setCurrentStep, initialStep, setShow, onProceed]);

  useEffect(() => {
    if (pokUrl !== '' && openPokModal) {
        window.open(pokUrl, '_blank');
      setOpenPokModal(false);
    }
  }, [setOpenPokModal, openPokModal, pokUrl]);

  useEffect(() => {
    const clientInfoSchema = Yup.object().shape(
      collectClientName
        ? {
            firstName: Yup.string().required('First name is required'),
            lastName: Yup.string().required('Last name is required'),
            phone: Yup.string().matches(
              /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
              'Phone number is not valid',
            ),
          }
        : {
            phone: Yup.string().matches(
              /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
              'Phone number is not valid',
            ),
          },
    );

    try {
      clientInfoSchema.validateSync(clientInfo);
      setIsValid(true);
    } catch (err) {
      setIsValid(false);
    }
  }, [clientInfo, collectClientName]);

  useEffect(() => {
    let errorTimer = setTimeout(() => setError(''), 5000);
    return () => {
      clearTimeout(errorTimer);
    };
  }, [error]);

  useEffect(() => {
    if (!childRef.current) return;
    childRef.current.ontouchstart = stopEventBubbling;
    childRef.current.ontouchmove = stopEventBubbling;
    childRef.current.ontouchend = stopEventBubbling;
  }, [show, childRef]);

  const onBack = useCallback(() => {
    setShow(false);
  }, [setShow]);

  const onSubmit = useCallback(() => {
    if (order.description.length > 125) {
      setError({
        message: 'Max 125 characters allowed in Order Details!',
      });
    } else {
      if (!isValid) {
        setError({ message: 'Please enter your contact info' });
        return;
      }

      if (paymentMethod.methodId.toString() === PAYMENT_METHODS.WITH_CARD.methodId.toString()) {
        if (paymentCard === undefined || paymentCard === null) {
          setError({ message: 'Please enter your payment info' });
          return;
        }
      }

      if (
        (order.totalValue < pageInfo.minimumOrderValue && !internalOrder) ||
        order.totalValue === 0
      ) {
        setError({
          message: `Your total order value must be at least ${currencyFormatter(
            pageInfo.minimumOrderValue,
            pageInfo.currency,
          )}`,
        });
        return;
      }
      if (onProceed) {
        onProceed();
      }
    }
  }, [pageInfo, order, onProceed, isValid, paymentCard, paymentMethod, internalOrder]);

  const CheckRecurring = useCallback(() => {
    let isRecurring = false;
    let currentPromo;
    if (clientPromotions.length !== 0) {
      currentPromo = clientPromotions.filter((promo) => promo.promotionId === promotion.id);
      if (currentPromo.length === 0 && promotion !== null) {
        isRecurring = true;
      }
      if (!promotion.recurring && currentPromo.map((count) => count.promoCount) >= 1) {
        return isRecurring;
      }
      if (
        currentPromo.map((count) => count.promoCount) < promotion.recurringCount ||
        promotion.recurringCount === 0
      ) {
        isRecurring = true;
      }
    } else isRecurring = true;
    return isRecurring;
  }, [clientPromotions, promotion]);

  const Promotions = useCallback(
    (total) => {
      let promotionValue = 0;

      let orderCount = 0;
      order.orderProducts.map((order) => {
        return (orderCount += order.qty);
      });

      if (!promotion) {
        return null;
      } else if (total) {
        if (promotion.isActive === true) {
          if (promotion.promotionType === 0) {
            if (promotion.discountType === 0 && orderCount >= promotion?.promotionCount.count) {
              promotionValue = promotion?.discountValue.value;
            }
            if (promotion.discountType === 1 && orderCount >= promotion?.promotionCount.count) {
              promotionValue = (total * promotion?.discountPercentage.percentage) / 100;
            }
          }
          if (promotion.promotionType === 1) {
            if (promotion.discountType === 0 && total >= promotion?.promotionValue.value) {
              promotionValue = promotion?.discountValue.value;
            }
            if (promotion.discountType === 1 && total >= promotion?.promotionValue.value) {
              promotionValue = (total * promotion?.discountPercentage.percentage) / 100;
            }
          }
          if (CheckRecurring()) {
            dispatch(setOrderTotal(promotionValue));
          }
          return promotionValue;
        } else return null;
      }
      return promotionValue;
    },
    [CheckRecurring, dispatch, order.orderProducts, promotion],
  );

  useEffect(() => setPromotionValue(Promotions(order.totalValue)), [Promotions, order.totalValue]);

  const displayPaymentMethods = () => {
    setShowPaymentMethods(true);
  };

  return (
    <>
      {showLocation === true ? (
        <div
          style={{
            position: 'fixed',
            zIndex: '1000',
            height: '100vh',
            maxWidth: '500px',
            width: '100vw',
          }}
        >
          <LocationDetails
            pageInfo={pageInfo}
            onProceed={() => setShowLocation(false)}
            onBack={() => setShowLocation(false)}
          />
        </div>
      ) : (
        <div
          containerElementClass={'drawerContainerElementClass'}
          modalElementClass={'drawerModalElementClass'}
          open={show}
          style={{ position: 'sticky', top: '0', zIndex: '1000' }}
        >
          <div style={{ height: '120px', position: 'relative' }}>
            <LocationPicker
              pageInfo={pageInfo}
              location={order.orderLocation}
              onLocationPicked={(location) => {
                dispatch(setOrderLocation(location));
              }}
              zones={pageInfo.geoRegions}
              containerStyles={{ position: 'absolute', height: '170px' }}
            />
            <h2
              onClick={() => onBack()}
              style={{ margin: '20px', cursor: 'pointer', position: 'absolute' }}
            >
              Back
            </h2>
          </div>
          <InnerRowWrapper>
            <PaymentMethods
              show={showPaymentMethods}
              toggle={() => setShowPaymentMethods(false)}
              internalOrder={internalOrder}
              selectCash={() => (paymentMethod.methodId = 0)}
              selectPok={() => (paymentMethod.methodId = 3)}
              pokData={pokData}
              setError={() => setError({ message: 'Please enter your contact info' })}
              onSubmit={() => onSubmit()}
              SetShowModal={() => setShowPokModal(showPokModal)}
              isValid={isValid}
              confirmPaymentMethodCash={() => setConfirmedPaymentMethod(0)}
              confirmPaymentMethodPok={() => setConfirmedPaymentMethod(3)}
            />
            <HeaderCol ref={headerRef}>
              <div style={{ width: '100%', marginBottom: '1rem' }}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <Header>Checkout</Header>
                  </div>
                </div>
              </div>
            </HeaderCol>
            <Elements stripe={stripe} options={stripeElementsOptions}>
              <PaymentPanel
                clientInfo={clientInfo}
                setPaymentCard={setPaymentCard}
                collectClientName={collectClientName}
                disabledPaymentMethods={
                  pageInfo.hasStripeConfiguration ? [] : [PAYMENT_METHODS.WITH_CARD.methodId]
                }
              />
            </Elements>
            <Options>
              <OptionDiv
                style={{
                  width: '95%',
                  height: '40%',
                  marginLeft: '12.3px',
                  borderRadius: '10px',
                }}
                onClick={() => setShowLocation(true)}
              >
                <div style={{ display: 'flex', gap: '0.85rem' }}>
                  <Icons.LocationPin />
                  <div>
                    <h2 style={{ marginBottom: '0.8rem' }}>Choose a delivery address</h2>
                    <p style={{ fontSize: '12px', width: '200px' }}>
                      {order.orderLocation.address}
                    </p>
                  </div>
                </div>
                <div style={{ marginTop: '10px' }}>
                  <Icons.Vector />
                </div>
              </OptionDiv>
              <OptionDiv
                style={{
                  width: '95%',
                  height: '40%',
                  marginLeft: '12.3px',
                  borderRadius: '10px',
                }}
                onClick={() => {
                  displayPaymentMethods();
                }}
              >
                <div style={{ display: 'flex', gap: '0.85rem' }}>
                  <Icons.PaymentMethodV2 />
                  <div>
                    <h2 style={{ marginBottom: '0.8rem' }}>Choose a payment method</h2>
                    <p style={{ fontSize: '12px' }}>
                      {confirmedPaymentMethod === 3
                        ? 'Online payment'
                        : confirmedPaymentMethod === 0 && 'Cash on delivery'}
                    </p>
                  </div>
                </div>
                <div style={{ marginTop: '10px' }}>
                  <Icons.Vector />
                </div>
              </OptionDiv>
            </Options>
            <Body ref={childRef} headerHeight={headerHeight}>
              <div
                style={{
                  paddingLeft: '10px',
                  backgroundColor: '#F9FAFC',
                  width: '95%',
                  marginLeft: '12.3px',
                  borderRadius: '10px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    margin: '1.7rem 0 1.3rem 0',
                    // backgroundColor: '#F9FAFC',
                  }}
                >
                  <Icons.AddressDescription />
                  <div style={{ margin: '0 0 0 10px' }}>
                    <h2 style={{ marginBottom: '10px' }}>Receipt</h2>
                    <p style={{ fontSize: '12px' }}>Including VAT</p>
                  </div>
                </div>
                <div
                  style={{
                    padding: '0px 2rem 2rem 2rem',
                    borderTop: '1px solid #F9FAFC',
                    // backgroundColor: '#F9FAFC',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <ProductName>Item subtotal:</ProductName>
                    <ProductPrice style={{ fontWeight: 600 }}>
                      {order.totalValue % 1 === 0
                        ? (order.totalValue = parseInt(order.totalValue, 10))
                        : order.totalValue}
                      {' ' + pageInfo.currency}
                    </ProductPrice>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      // marginBottom: 16,
                    }}
                  >
                    <ProductName>Delivery Fee:</ProductName>
                    <ProductPrice style={{ fontWeight: 600 }}>
                      {order.deliveryFeeExempt
                        ? 'FREE'
                        : order.deliveryFee % 1 === 0
                        ? (order.deliveryFee = parseInt(order.deliveryFee, 10))
                        : order.deliveryFee}

                      {order.deliveryFeeExempt ? '' : ' ' + pageInfo.currency}
                    </ProductPrice>
                  </div>

                  {vouchers.data.filter((x) => x.showOnOnline).length !== 0 &&
                    promotionValue === null && (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            // marginBottom: 16,
                          }}
                        >
                          <ProductName>Voucher:</ProductName>
                          <ProductPrice
                            style={
                              validVoucher
                                ? {}
                                : { fontWeight: 600, textDecoration: 'underline', color: 'blue' }
                            }
                            hover={`cursor: pointer;`}
                            onClick={() => setApplingVoucher((prevState) => !prevState)}
                          >
                            {validVoucher
                              ? validVoucher.profitType === 1
                                ? `-${validVoucher.profitValue}%`
                                : `-${validVoucher.profitValue} ALL`
                              : 'Apply Voucher'}
                          </ProductPrice>
                        </div>
                        {applingVoucher && (
                          <ApplyVoucherModal
                            order={order}
                            show={applingVoucher}
                            toggle={() => {
                              setApplingVoucher(false);

                              for (let voucher of vouchers.data) {
                                if (!voucher.showOnOnline) continue;
                                const validCode = voucher.voucherCodes.find(
                                  (code) => code.code === order.voucherCode && code.isActive,
                                );
                                if (validCode) {
                                  dispatch(setOrderValidVoucher(voucher, promotionValue));
                                  return;
                                }
                              }

                              dispatch(setOrderValidVoucher(null));
                            }}
                          />
                        )}
                      </>
                    )}

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: 16,
                    }}
                  >
                    {promotionValue && CheckRecurring() ? (
                      <>
                        <ProductName>Promotion:</ProductName>
                        <ProductPrice style={{ fontWeight: 600 }}>
                          {'- ' + promotionValue + ` ` + pageInfo.currency}
                        </ProductPrice>
                      </>
                    ) : null}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <ProductName style={{ fontWeight: 600 }}>Total:</ProductName>
                    <ProductPrice style={{ fontWeight: 600 }}>
                      {order.total % 1 === 0
                        ? (order.total = parseInt(order.total, 10))
                        : order.total}
                      {' ' + pageInfo.currency}
                    </ProductPrice>
                  </div>
                </div>
              </div>
              <div id="modal-container">
                <Modal
                  width="250"
                  height="540"
                  getContainer={() => document.getElementById('modal-container')}
                  visible={pokErrorModal}
                  footer={null}
                  onCancel={() => setPokErrorModal(false)}
                  centered
                  maskClosable={true}
                  destroyOnClose={true}
                  closable={false}
                >
                  <Result
                    status="error"
                    subTitle="Sorry We Could Not Process The Pok Payment Right Now"
                  />
                  <Button
                    style={{
                      marginLeft: '195px',
                    }}
                    onClick={() => setPokErrorModal(false)}
                  >
                    OK
                  </Button>
                </Modal>
              </div>
            </Body>
            <ErrorMessage
              message={error && error.message}
              show={!!error}
              style={{ padding: '10px 30px' }}
            />
              <BlackButton
                onClick={() => {
                    onSubmit();
                }}
                style={{
                  width: '95%',
                  marginLeft: '2.5%',
                  minHeight: '60px',
                  // position: 'fixed',
                  bottom: '10px',
                  height: 60,
                  fontSize: 16,
                  fontWeight: 'bold',
                  color: '#FFFFFF',
                  borderRadius: '10px',
                  display: order.totalValue ? `${applingVoucher ? 'none' : 'flex'}` : 'none',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                text={
                  <ButtonText>
                    <ButtonInfo>
                      <AllQty>
                        {order.orderProducts.legth !== 0 &&
                          order.orderProducts.reduce(
                            (accumulator, currProd) => accumulator + currProd.qty,
                            0,
                          )}
                      </AllQty>
                      <p style={{ padding: '3px' }}>
                        Confirm order
                      </p>
                    </ButtonInfo>
                    <p style={{ marginRight: '30px', padding: '3px' }}>{order.total}</p>
                  </ButtonText>
                }
              />
          </InnerRowWrapper>
        </div>
      )}
    </>
  );
};

export default OrderReview;
