import React from 'react';
import styled from 'styled-components';
import colors from '../helpers/colors';
import transition from '../helpers/transition';

const RadioElementWrapper = styled.div`
  width: 20px;
  height: 20px;
  // border: 2px solid ${colors.lightGrey};
  border: 1px solid #FFFFFF;
  transition: ${transition};
  position: relative;
  border-radius: 9999px;
  box-sizing: border-box;

  :hover {
    border: 1px solid ${colors.blue};
    cursor: pointer;
  }
`;

const Circle = styled.div`
  border-radius: 9999px;
  box-sizing: border-box;
  background-color: transparent;
  border: 2px solid #e9eaf1;
  width: 20px;
  height: 20px;
  transition: ${transition};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const BlueCircle = styled.div`
  border-radius: 9999px;
  box-sizing: border-box;
  background-color: ${colors.blue};
  width: 10px;
  height: 10px;
  transition: ${transition};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const RadioElement = ({
  updateSelectedElement = () => {},
  isSelected = false,
  reference,
  ...rest
}) => (
  <RadioElementWrapper
    className={'radio-element'}
    ref={reference}
    {...rest}
    onClick={updateSelectedElement}
  >
    <Circle>{isSelected && <BlueCircle />}</Circle>
  </RadioElementWrapper>
);

export default RadioElement;
