import React from 'react';
import { Route } from 'react-router-dom';
import { isAuthenticated } from 'auth';

const ProtectedRoute = ({ path, protectedRender }) => {
  return (
    <Route
      path={path}
      render={(props) => {
        if (isAuthenticated()) {
          return protectedRender(props);
        }
        return null;
      }}
    />
  );
};

export default ProtectedRoute;
