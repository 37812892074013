import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import shared from 'app/shared';
import SettingsTitle from '../components/SettingsTitle';
import { setPageConfiguration } from 'actions/pageActions';
import { IsOnlineOrderActive, IsTableOrderActive } from 'selectors/pageServiceSelector';
import CheckBox from 'app/shared/views/checkbox';
import { scrollToAlert } from 'app/shared/views/alert';

const {
  views: { Alert, InputArea },
  helpers: { colors },
} = shared;

const InlineFlexWrapper = styled.div`
  display: inline-flex;
  margin-top: 3%;
  width: 60%;
`;

const MainLabel = styled.div`
  display: flex;
  width: 35%;
  height: 100%;
  padding-right: 30px;
  font-size: 15px;
  color: ${colors.textColor};
`;

const IFrameSettings = ({ setExecuteOnSubmit, setHasChanges, children }) => {
  const dispatch = useDispatch();

  const activePage = useSelector((state) => state.facebookPages.activePage);
  const pageConfiguration = useSelector((state) => state.pageConfiguration);
  const hasOnlineOrder = useSelector(IsOnlineOrderActive);
  const hasTableOrder = useSelector(IsTableOrderActive);

  const [alert, setAlert] = useState({ show: false, message: '' });
  const alertRef = useRef(null);
  const [onlineOrderIframeSettings, setOnlineOrderIframeSettings] = useState(
    pageConfiguration.PUBLIC_onlineOrderIframeConfiguration,
  );
  const [tableOrderIframeSettings, setTableOrderIframeSettings] = useState(
    pageConfiguration.PUBLIC_tableOrderIframeConfiguration,
  );

  useEffect(() => {
    let alertTimer = setTimeout(() => setAlert({ show: false, message: '' }), 5000);
    return () => {
      clearTimeout(alertTimer);
    };
  }, [alert]);

  useEffect(() => {
    setExecuteOnSubmit(() => () => {
      dispatch(
        setPageConfiguration(
          activePage.id,
          'PUBLIC_onlineOrderIframeConfiguration',
          onlineOrderIframeSettings,
          (err) => {
            if (!err) {
              dispatch(
                setPageConfiguration(
                  activePage.id,
                  'PUBLIC_tableOrderIframeConfiguration',
                  tableOrderIframeSettings,
                  (err) => {
                    if (!err) {
                      setAlert({
                        show: true,
                        message: 'You have successfully changed your iframe settings!',
                        type: 'success',
                      });
                      scrollToAlert(alertRef);
                      setHasChanges(false);
                    } else {
                      setAlert({
                        show: true,
                        message: `Could not change your iframe settings: ${err}`,
                        type: 'error',
                      });
                      scrollToAlert(alertRef);
                    }
                  },
                ),
              );
            } else {
              setAlert({
                show: true,
                message: `Could not change your iframe settings: ${err}`,
                type: 'error',
              });
              scrollToAlert(alertRef);
            }
          },
        ),
      );
    });
  }, [
    activePage,
    setExecuteOnSubmit,
    setHasChanges,
    onlineOrderIframeSettings,
    tableOrderIframeSettings,
    dispatch,
  ]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '0.7% 30px',
        paddingBottom: '30px',
      }}
    >
      <SettingsTitle title="IFrame Settings" />
      <Alert ref={alertRef} {...alert} />

      {hasOnlineOrder && (
        <InlineFlexWrapper>
          <MainLabel>Online Order IFrame</MainLabel>
          <div style={{ width: '65%', marginTop: '-2px' }}>
            <div style={{ width: '100%', marginBottom: '10px' }}>
              <div>
                <CheckBox
                  text={'Hide Banner'}
                  checked={onlineOrderIframeSettings.hideBanner}
                  onChange={(e) =>
                    setOnlineOrderIframeSettings({
                      ...onlineOrderIframeSettings,
                      hideBanner: e.target.checked,
                    })
                  }
                />
              </div>
            </div>
            <div style={{ width: '100%', marginBottom: '10px' }}>
              <div>
                <CheckBox
                  text={'Collect Client Name'}
                  checked={onlineOrderIframeSettings.collectClientName}
                  onChange={(e) =>
                    setOnlineOrderIframeSettings({
                      ...onlineOrderIframeSettings,
                      collectClientName: e.target.checked,
                    })
                  }
                />
              </div>
            </div>
            <div style={{ width: '100%', marginBottom: '10px' }}>
              <div>
                <CheckBox
                  text={'Choose Products First'}
                  checked={onlineOrderIframeSettings.chooseProductsFirst}
                  onChange={(e) =>
                    setOnlineOrderIframeSettings({
                      ...onlineOrderIframeSettings,
                      chooseProductsFirst: e.target.checked,
                    })
                  }
                />
              </div>
            </div>
            <div style={{ width: '100%', marginBottom: '10px' }}>
              <div>
                <CheckBox
                  text={'Portrait Product Image'}
                  checked={onlineOrderIframeSettings.portraitProductImage}
                  onChange={(e) =>
                    setOnlineOrderIframeSettings({
                      ...onlineOrderIframeSettings,
                      portraitProductImage: e.target.checked,
                    })
                  }
                />
              </div>
            </div>
            <div style={{ width: '100%', marginBottom: '10px' }}>
              <div>
                <CheckBox
                  text={'Disable Online Order'}
                  checked={onlineOrderIframeSettings.disableOnlineOrder}
                  onChange={(e) =>
                    setOnlineOrderIframeSettings({
                      ...onlineOrderIframeSettings,
                      disableOnlineOrder: e.target.checked,
                    })
                  }
                />
              </div>
            </div>
            <div style={{ width: '100%', marginBottom: '10px' }}>
              <div>
                <CheckBox
                  text={'Hide Status'}
                  checked={onlineOrderIframeSettings.hideStatus}
                  onChange={(e) =>
                    setOnlineOrderIframeSettings({
                      ...onlineOrderIframeSettings,
                      hideStatus: e.target.checked,
                    })
                  }
                />
              </div>
            </div>
            <div style={{ width: '100%', marginBottom: '10px' }}>
              <div>
                <InputArea
                  labelText={'Custom Javascript/CSS'}
                  placeholder={"Don't include <script>"}
                  value={onlineOrderIframeSettings.customScript}
                  onChange={(e) =>
                    setOnlineOrderIframeSettings({
                      ...onlineOrderIframeSettings,
                      customScript: e.target.value,
                    })
                  }
                  rows={11}
                />
              </div>
            </div>
          </div>
        </InlineFlexWrapper>
      )}
      {hasTableOrder && (
        <InlineFlexWrapper>
          <MainLabel>Table Order IFrame</MainLabel>
          <div style={{ width: '65%', marginTop: '-2px' }}>
            <div style={{ width: '100%', marginBottom: '10px' }}>
              <div>
                <CheckBox
                  text={'Hide Banner'}
                  checked={tableOrderIframeSettings.hideBanner}
                  onChange={(e) =>
                    setTableOrderIframeSettings({
                      ...tableOrderIframeSettings,
                      hideBanner: e.target.checked,
                    })
                  }
                />
              </div>
            </div>
            <div style={{ width: '100%', marginBottom: '10px' }}>
              <div>
                <CheckBox
                  text={'Collect Client Name'}
                  checked={tableOrderIframeSettings.collectClientName}
                  onChange={(e) =>
                    setTableOrderIframeSettings({
                      ...tableOrderIframeSettings,
                      collectClientName: e.target.checked,
                    })
                  }
                />
              </div>
            </div>
            <div style={{ width: '100%', marginBottom: '10px' }}>
              <div>
                <CheckBox
                  text={'Enable Waiter Functionalities'}
                  checked={tableOrderIframeSettings.enableWaiterFunctionalities}
                  onChange={(e) =>
                    setTableOrderIframeSettings({
                      ...tableOrderIframeSettings,
                      enableWaiterFunctionalities: e.target.checked,
                    })
                  }
                />
              </div>
            </div>
            <div style={{ width: '100%', marginBottom: '10px' }}>
              <div>
                <CheckBox
                  text={'Portrait Product Image'}
                  checked={tableOrderIframeSettings.portraitProductImage}
                  onChange={(e) =>
                    setTableOrderIframeSettings({
                      ...tableOrderIframeSettings,
                      portraitProductImage: e.target.checked,
                    })
                  }
                />
              </div>
            </div>
            <div style={{ width: '100%', marginBottom: '10px' }}>
              <div>
                <CheckBox
                  text={'Disable Table Order'}
                  checked={tableOrderIframeSettings.disableTableOrder}
                  onChange={(e) =>
                    setTableOrderIframeSettings({
                      ...tableOrderIframeSettings,
                      disableTableOrder: e.target.checked,
                    })
                  }
                />
              </div>
            </div>
            <div style={{ width: '100%', marginBottom: '10px' }}>
              <div>
                <CheckBox
                  text={'Require Phone Number'}
                  checked={tableOrderIframeSettings.collectPhoneOnTable}
                  onChange={(e) =>
                    setTableOrderIframeSettings({
                      ...tableOrderIframeSettings,
                      collectPhoneOnTable: e.target.checked,
                    })
                  }
                />
              </div>
            </div>
            <div style={{ width: '100%', marginBottom: '10px' }}>
              <div>
                <InputArea
                  labelText={'Custom Javascript/CSS'}
                  placeholder={"Don't include <script>"}
                  value={tableOrderIframeSettings.customScript}
                  onChange={(e) =>
                    setTableOrderIframeSettings({
                      ...tableOrderIframeSettings,
                      customScript: e.target.value,
                    })
                  }
                  rows={11}
                />
              </div>
            </div>
          </div>
        </InlineFlexWrapper>
      )}
      <InlineFlexWrapper>
        <MainLabel />
        {children}
      </InlineFlexWrapper>
    </div>
  );
};

export default IFrameSettings;
