import React from 'react';

import shared from 'app/shared';

const {
  views: {
    Button: { Button, ButtonSize },
  },
  helpers: {
    colors,
    hexToRGBA,
  }
} = shared;

const MainButton = ({ text, style = {}, disabled, onClick, ...rest }) => {
  return <Button
    text={text}
    size={ButtonSize.large}
    style={{
      backgroundColor: !disabled ? '#3185FC' : hexToRGBA('#3185FC', 0.6),
      color: colors.white,
      width: '100%',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '18px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      ...style,
    }}
    onClick={e => {
      if (disabled || typeof onClick !== 'function') return;
      onClick(e);
    }}
    {...rest}
  />
};

export default MainButton;
