export default (type) => {
    const initialState = {
      data: [],
      current: {}
    };
  
    return (state = initialState, action) => {
      switch (action.type) {
        case `GET_ALL_TOP_${type.tag}_OK`:
          return {
            ...state,
            data: [...action.data],
            current: { ...state.current },
          };
        default:
          return state;
      }
    };
  };
  