import React from 'react';
import styled from 'styled-components';
import RadioGroup from './radioGroup';
import colors from '../helpers/colors';
import hexToRGBA from '../helpers/hexToRGBA';
import transition from '../helpers/transition';

const TabBarWrapper = styled.div`
  height: 53px;
  border-bottom: 1px solid ${colors.lightGrey};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media all and (max-width: 768px) {
  height: 33px;
  }
`;

const Start = styled.div`
  display: inline-flex;
  flex-direction: row;
  width: 100%;
`;

const End = styled.div``;

const TabWrapper = styled.div`
  background-color: ${props => (props.isSelected ? hexToRGBA(colors.blue, 0.1) : 'transparent')};
  transition: ${transition};
  border-bottom: ${props =>
    props.isSelected ? `3px solid ${colors.blue}` : '3px solid transparent'};
  font-size: 16px;
  color: ${props => (props.isSelected ? colors.blue : colors.textColor)};
  line-height: 53px;
  width: 200px;
  text-align: center;

  :hover {
    background-color: ${hexToRGBA(colors.blue, 0.05)};
    cursor: pointer;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {
    width: 140px;
  }

  @media all and (max-width: 768px) {
    display: none;
  }
`;

const TabWrapperMobile = styled.div`
  background-color: ${props => (props.isSelected ? colors.blue : 'transparent')};
  transition: ${transition};
  font-size: 12px;
  border-radius: 3px 0px 0px 3px;
  border-color: ${colors.blue};
  height: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #2C98F0;
  justify-content: center;
  box-sizing: border-box;
  flex-grow: 1;
  color: ${props => (props.isSelected ? colors.white : colors.blue)};
  @media all and (min-width: 768px){
    display: none;
  }
`;

const Tab = ({ text, updateSelectedElement = () => {}, isSelected = false }) => (
  <>
    <TabWrapper onClick={updateSelectedElement} isSelected={isSelected}>
      {text}
    </TabWrapper>
    <TabWrapperMobile onClick={updateSelectedElement} isSelected={isSelected}>
      {text}
    </TabWrapperMobile>
  </>
);

const TabBar = ({ tabs, selectedElement, updateSelectedElement, className }) => (
  <TabBarWrapper className={className}>
    <Start>
      <RadioGroup selectedElement={selectedElement} updateSelectedElement={updateSelectedElement}>
        {tabs.map(item => (
          <RadioGroup.Element key={item.value} Element={Tab} text={item.text} value={item.value} />
        ))}
      </RadioGroup>
    </Start>
    <End />
  </TabBarWrapper>
);

export default TabBar;
