import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// import LocationPicker from 'app/shared/views/locationPicker';
import { GreenButton } from 'app/shared/views/buttons';
import PageEventCard from './PageEventCarouselItem';
import Socials from './Socials';
import { Carousel } from 'antd';
import moment from 'moment';
import './Carousel.css';

const Wrapper = styled.div`
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

// Event Location Shower

// const AreaCoverageMapWrapper = styled.div`
//   height: 150px;
//   & > div {
//     height: 100%;
//   }
// `;

const EventSocialsStickyContainer = styled.div`
  width: 100%;
  bottom: 0;
  border-radius: 0;
  max-width: 500px;
`;

const Details = ({ pageInfo }) => {
  // const [pageLocation, setPageLocation] = useState(null);
  const [socialLinks, setSocialLinks] = useState([]);
  const [autoplay, setAutoplay] = useState(true);
  useEffect(() => {
    if (pageInfo?.locationCoords) {
      // setPageLocation({
      //   pos: pageInfo.locationCoords,
      //   address: pageInfo.location,
      // });
    }
    pageInfo?.socialLinks && setSocialLinks(pageInfo.socialLinks);
  }, [pageInfo]);

  return (
    <>
      <Wrapper>
        {/* {pageLocation && (
          <AreaCoverageMapWrapper>
            <LocationPicker pageInfo={pageInfo} location={pageLocation} zoom={13} />
          </AreaCoverageMapWrapper>
        )} */}
        <Carousel autoplay={autoplay}>
          {pageInfo.pageEvents?.map((event) =>
            moment(event.date).format() > moment().format() && event.active === true ? (
              <div
                key={event.id}
                onMouseDown={() => setAutoplay(false)}
                onTouchStart={() => setAutoplay(false)}
              >
                <PageEventCard event={event} />
              </div>
            ) : null,
          )}
        </Carousel>
        <EventSocialsStickyContainer>
          <Socials socials={socialLinks} />
          {socialLinks['phone'] && (
            <a href={`tel:${socialLinks['phone']}`}>
              <GreenButton
                style={{
                  width: '100%',
                  height: 60,
                }}
                text={'Call Now'}
              />
            </a>
          )}
        </EventSocialsStickyContainer>
      </Wrapper>
    </>
  );
};

export default Details;
