import styled from 'styled-components';
import text from 'app/shared/views/text';
import { GradientButton } from 'app/shared/views/buttons';
import colors from 'app/shared/helpers/colors'

const {
  Paragraph: { P }
} = text;


export const UpgradeButton = styled(GradientButton)`
  background: linear-gradient(90deg, #006CCB 0%, #65BAFF 100%);
  box-shadow: 0px 12px 16px rgba(200, 208, 216, 0.3);
  width: ${props => props.width ? props.width : '260px' } !important;
  height: ${props => props.height ? props.height : '36px' } !important;
  border-radius: 3px;
`;

export const Title = styled.h1`
  color: ${colors.blue};
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
  display: flex;
  align-items: center;
`;

export const Subtitle = styled(P)`
  color: #748AA1;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  display: flex;
  align-items: center;
  max-width: 500px;
`;

export const FeatureList = styled.ul`
  color: inherit;
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 25px;
  list-style: decimal;

  & > li {
    line-height: 27px;
  }
`;
