import Url from '../config/api';
import { buildAxiosInstance } from 'actions/common';

const { baseUrl } = Url;
const baseURI = `${baseUrl}/Delivery`;

const axiosClient = buildAxiosInstance(baseUrl);

export const dispatchDriver = (setIsCallingDriver = null) => {
  setIsCallingDriver(true);
  return (dispatch) => {
    axiosClient
      .post(`${baseURI}/dispatch-driver`)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setIsCallingDriver && setIsCallingDriver(false);
          dispatch({
            type: 'DISPATCH_DRIVER_OK',
            data: res.data,
          });
        }
      })
      .catch(() => {
        setIsCallingDriver && setIsCallingDriver('ERROR');
        dispatch({ type: 'DISPATCH_DRIVER_ERROR' });
      });
  };
};

export const requestDelivery = (orderId, setStatus = null) => {
  return (dispatch) => {
    axiosClient
      .post(`${baseURI}/require-delivery/${orderId}`)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
          dispatch({
            type: 'REQUIRE_DELIVERY_OK',
            data: orderId,
          });
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
        dispatch({ type: 'REQUIRE_DELIVEY_ERROR' });
      });
  };
};

export const orderReady = (orderId, setStatus = null) => {
  return (dispatch) => {
    axiosClient
      .post(`${baseURI}/order-ready/${orderId}`)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
          dispatch({
            type: 'ORDER_READY_OK',
            data: orderId,
          });
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
        dispatch({ type: 'ORDER_READY_ERROR' });
      });
  };
};
