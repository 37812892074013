import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import { AddButton, RemoveButton, GreenButton } from 'app/shared/views/buttons';

import ToggleValidator from '../../../shared/validators/toggle.validator';
import currencyFormatter from '../../../shared/helpers/currencyFormatter';
import hexToRGBA from '../../../shared/helpers/hexToRGBA';
import isEscape from '../../../shared/helpers/isEscape';
import slideFromTopAnimation from '../../../shared/helpers/slideFromTopAnimation';
import transition from '../../../shared/helpers/transition';

import Product from '../../../shared/views/shopping/components/productComponent/Product';
import Toppings from './Toppings';
import Card from '../../../shared/views/card';
import Icons from '../../../shared/views/icons';
import HorizontalScrollableSpace from '../../../shared/views/horizontalScrollableSpace';

import product_image from '../../../../assets/images/product_image.png';

const OverlayWrapper = styled.div`
  background: ${hexToRGBA('#2A2E45', 0.8)};
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  transition: ${transition};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.show ? 1 : 0)};
  top: 0;
  left: 0;
  z-index: 9999;
  -webkit-transform: translate3d(0, 0, 0);
  padding: 0 !important;
`;

/**
 * TODO: Fix color by name
 */
const CustomCard = styled(Card)`
  border-radius: 20px;
  box-shadow: 0px 16px 28px rgba(63, 86, 104, 0.1);
  position: relative;
  width: 100%;
  overflow: hidden;
  border: 0;
  display: flex;
  flex-direction: column;
`;

const CloseIcon = styled(Icons.Cross)`
  width: 42px;
  height: 42px;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 999;
  :hover {
    cursor: pointer;
  }
`;

const ContentWrapper = styled.div`
  transition: 0.4s ease-in-out;
  position: relative;
  opacity: ${(props) => (props.show ? 1 : 0)};
  animation: ${(props) => (props.show ? slideFromTopAnimation : '')} 0.4s ease-in-out;
  width: calc(100% - 40px);
  max-width: 374px;
  z-index: 100;
`;

const ProductImage = styled.div`
  background: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 150px;
`;

const ProductActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  background-color: #f9fafc;
  min-height: 40px;
`;

const ProductOrderCount = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 17px;
  color: #1d2036;
  display: flex;
  align-items: center;
  text-align: center;
`;

const ProductHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  border: 1px solid #f9fafc;
  min-height: 50px;
`;

const ProductName = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #1d2036;
`;

const ProductPrice = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #2ecc71;
`;

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 20px;
  margin-bottom: ${(props) => (props.isCombo ? '0' : '40px')};
`;

const ProductIngredientsHeader = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  padding: 10px 0;
  color: #1d2036;
`;

const ProductIngredientsDetails = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;

  color: #8f92a7;
`;

const TotalLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1d2036;
`;
const TotalValue = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #2ecc71;
`;

const ProductModal = (props) => {
  const { product, updateComboProduct, show, toggle, categories, orderProducts } = props;
  const [localOrder, setLocalOrder] = useState([{ ...product, index: 0, toppings: [], qty: 1 }]);
  const [totalValue, setTotalValue] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(0);

  useEffect(() => {
    let productOrderDetails;
    orderProducts?.filter((obj) => obj.productId === product.id).length > 0
      ? (productOrderDetails = _.cloneDeep(
          orderProducts?.filter((obj) => obj.productId === product.id),
        ))
      : (productOrderDetails = [{ ...product, index: 0, toppings: [], qty: 1 }]);

    setLocalOrder(_.cloneDeep(productOrderDetails));
    const selProd = productOrderDetails.findIndex((prod) => prod.id === product.index);
    selProd > -1 && setSelectedProduct(selProd);
  }, [orderProducts, product]);

  useEffect(() => {
    let total = 0;
    localOrder.forEach((prd) => {
      total += prd.qty * prd.price;
      if (prd.toppings?.length > 0) {
        prd.toppings.map((topping) => (total += topping.qty * topping.price));
      }
    });
    setTotalValue(total);
  }, [localOrder]);

  useEffect(() => {
    document.addEventListener('keyup', toggleOnEscape);
    return function cleanup() {
      document.removeEventListener('keyup', toggleOnEscape);
    };
  });

  useEffect(() => {
    document.body.style.overflowY = show ? 'hidden' : 'unset';
    document.body.style.position = show ? 'fixed' : 'unset';
  }, [show]);

  const toggleOnEscape = (event) => {
    if (event.defaultPrevented) return;
    if (isEscape(event) && show) toggle();
  };

  return (
    <OverlayWrapper show={show} onClick={toggle}>
      <ContentWrapper show={show} onClick={(event) => event.stopPropagation()}>
        <CustomCard>
          <ProductImage src={product?.imagePath || product_image} />
          <CloseIcon fill={'white'} onClick={toggle} width={'14px'} height={'14px'} />
          <ProductActions>
            <RemoveButton
              onClick={() => {
                if (localOrder.length > 1) {
                  setLocalOrder(_.dropRight(localOrder, 1));
                }
              }}
              style={{ marginLeft: '25%', backgroundColor: 'rgb(232, 233, 243)' }}
            />
            <ProductOrderCount>
              {localOrder.length === 1 ? localOrder[0].qty : localOrder.length}
            </ProductOrderCount>
            <AddButton
              onClick={() => {
                setLocalOrder(
                  _.concat(localOrder, {
                    ...product,
                    index: localOrder[localOrder.length - 1]
                      ? localOrder[localOrder.length - 1].id + 1
                      : 0,
                    id: localOrder[localOrder.length - 1]
                      ? localOrder[localOrder.length - 1].id + 1
                      : 0,
                    toppings: [],
                    qty: 1,
                    productId: product.id,
                  }),
                );
              }}
              style={{ marginRight: '25%' }}
            />
          </ProductActions>
          <ProductHeader>
            <ProductName>{product?.name}</ProductName>
            <ProductPrice>{currencyFormatter(product?.price)}</ProductPrice>
          </ProductHeader>
          <ProductDetails isCombo={product?.isCombo}>
            <ProductIngredientsHeader>Description</ProductIngredientsHeader>
            <ProductIngredientsDetails>{product?.description}</ProductIngredientsDetails>
          </ProductDetails>
          {product?.isCombo && (
            <ProductDetails isCombo={product.isCombo} style={{ padding: '5px 0' }}>
              <ProductIngredientsHeader style={{ padding: '10px 20px' }}>
                Customise Products
              </ProductIngredientsHeader>
              <HorizontalScrollableSpace gap={10}>
                {localOrder.length ? (
                  localOrder.map((item, index) => (
                    <Product
                      scrollIntoView={() => {}}
                      key={index}
                      name={`${item.name} ${index + 1}`}
                      isSelected={index === selectedProduct}
                      onClick={() => {
                        setSelectedProduct(index);
                      }}
                      remove={() => {
                        localOrder.length > 1 &&
                          setLocalOrder(_.remove(localOrder, (i, ind) => ind !== index));
                        index > 0 && setSelectedProduct(index--);
                      }}
                    />
                  ))
                ) : (
                  <span style={{ width: '100%', textAlign: 'center' }}>No Products added</span>
                )}
              </HorizontalScrollableSpace>
            </ProductDetails>
          )}
          {product?.isCombo && localOrder.length > 0 && (
            <ProductDetails
              style={{ maxHeight: 240, overflowY: 'scroll', marginTop: 20, paddingBottom: 20 }}
              isCombo={product.isCombo}
            >
              {[
                ...new Set(
                  categories
                    .find((cat) => cat.id === product.categoryId)
                    .magProducts.find((prod) => prod.id === product.id)
                    .toppings?.map((t) => t.categoryId),
                ),
              ]
                .sort()
                .map((catId) => (
                  <ExtraCategoryWrapper key={catId}>
                    <Name>{categories?.find((cat) => cat.id === catId)?.name}</Name>
                    {categories
                      ?.find((cat) => cat.id === catId)
                      ?.magProducts.map((item) => (
                        <Toppings
                          key={item.id}
                          topping={item}
                          selectedProduct={selectedProduct}
                          localOrder={localOrder}
                          setLocalOrder={setLocalOrder}
                        />
                      ))}
                  </ExtraCategoryWrapper>
                ))}
            </ProductDetails>
          )}
          <ProductActions>
            <TotalLabel>Total:</TotalLabel>
            <TotalValue>{currencyFormatter(totalValue)}</TotalValue>
          </ProductActions>
          <GreenButton
            text={'Add Products'}
            onClick={() => {
              updateComboProduct(localOrder);
              toggle();
            }}
            style={{ width: '100%', height: 40, borderRadius: 0 }}
          />
        </CustomCard>
      </ContentWrapper>
    </OverlayWrapper>
  );
};

ProductModal.propTypes = {
  // children: ChildrenValidator.ChildrenRule.isRequired,
  ...ToggleValidator.ToggleSchema,
};

export default ProductModal;

const ExtraCategoryWrapper = styled.div`
  // display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
`;
const Name = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #1d2036;
`;
