import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Input, DatePicker } from 'antd';
import shared from 'app/shared';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { getAll, edit, remove, getByPageId } from '../../../actions/crudActions';
import { getProductNameSuggestions } from '../../../actions/productActions';
import crudTypes from '../../../config/crudTypes';
import moment from 'moment';

const {
  views: { Modal, AddItemPopup, Button, UploadInput, Icons },
  helpers: { colors },
} = shared;

const { RangePicker } = DatePicker;

const AddItemPopupWrapper = styled.div`
  width: 450px;
`;

const ErrorWrapper = styled.div`
  width: 100%;
  background: rgba(242, 69, 61, 0.2);
  color: #f2453d;
  margin-bottom: 10px;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  align-items: center;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 1px 5px;
  display: ${(props) => (props.show === 'ERROR' ? 'flex' : 'none')};
`;

const WarningWrapper = styled.div`
  width: 100%;
  display: flex;
  background: rgba(255, 152, 0, 0.2);
  color: #ff9800;
  margin-bottom: 10px;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  align-items: center;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 1px 5px;
  margin-top: 10px;
`;

const LabelWrapper = styled.div`
  padding-left: 10px;
`;

const Label = styled.label`
  color: ${colors.textColor};
  font-size: 12px;
  line-height: 23px;
  font-weight: 500;
`;

const EditBanner = (props) => {
  const [uri, setUri] = useState(props.banner.current.imageURI);
  const [postStatus, setPostStatus] = useState();
  const [startTime, setStartTimeStatus] = useState();
  const [endTime, setEndTimeStatus] = useState();
  const [period, setPeriodStatus] = useState();
  const [id, setIdStatus] = useState()
  const [uploadKey, setUploadKey] = useState(Math.random());
  const { toggle, getAll } = props;

  const changePostStatus = (resetForm) => (status) => {
    setPostStatus(status);
    if (status === 'OK') {
      getAll(crudTypes.category);
      setUri(props.banner.current.imageURI);
      setPeriodStatus(props.banner.current.period);
      resetForm();
      setUploadKey(Math.random());
      toggle();
    }
  };

   useEffect(() => {
     setUri(props.banner.current.imageURI);
     setStartTimeStatus(props.banner.current.startTime);
     setEndTimeStatus(props.banner.current.endTime);
     setPeriodStatus(props.banner.current.period);
     setIdStatus(props.banner.current.id);
   }, [props.banner]);

  if (!props.show) {
    return null;
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{
        title: props.banner.current.title || '',
        startTime: startTime,
        endTime: endTime,
        period: period,
        imageURI: uri,
        alwaysActive: props.alwaysActive || '',
        uri: `/restaurant/${props.facebookPages?.activePage?.pageName}`,
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string()
          .required('Banner Title is required')
          .test('len', 'Max 45 characters allowed', (val) => val?.length < 46),
      })}
      onSubmit={(values, { resetForm }) => {
        let newValues;
        if (uri) {
          newValues = {
            title: values.title,
            period: period,
            imageURI: uri,
            startTime: startTime,
            endTime: endTime,
            uri: `/restaurant/${props.facebookPages?.activePage?.pageName}`,
            pageId: props.facebookPages?.activePage?.id,
          };
        } else {
          newValues = {
            period: period,
            title: values.title,
            startTime: startTime,
            endTime: endTime,
            uri: `/restaurant/${props.facebookPages?.activePage?.pageName}`,
            pageId: props.facebookPages?.activePage?.id,
          };
        }
        props.edit(
          id,
          crudTypes.banner,
          newValues,
          changePostStatus(resetForm),
        );
      }}
    >
      {(innerProps) => {
        const { touched, errors, values, handleChange, handleSubmit } = innerProps;
        return (
          <Modal toggle={props.toggle} show={props.show}>
            <AddItemPopupWrapper>
              <AddItemPopup
                title={'Edit Banner'}
                actions={[
                  () => (
                    <Button.Button
                      color={colors.red}
                      text={'Delete Banner'}
                      onClick={() => {
                        props.remove(id, crudTypes.banner);
                        props.toggle()}}
                    />
                  ),
                  () => (
                    <Button.Button
                      color={colors.blue}
                      text={'Save'}
                      onClick={() => handleSubmit()}
                    />
                  ),
                  () => (
                    <Button.WhiteButton
                      text={'Cancel'}
                      onClick={() => {
                        props.toggle();
                      }}
                    />
                  ),
                ]}
              >
                <ErrorWrapper show={postStatus}>
                  <Icons.XIcon fill={colors.red} style={{ marginRight: 10 }} width={16} />
                  <span>Error editing the Banner</span>
                </ErrorWrapper>
                <AddItemPopup.InputRow>
                  <UploadInput
                    labelText={'Upload Image'}
                    key={uploadKey}
                    imagePath={uri}
                    uriGenerator={(uri) => {
                      setUri(uri);
                    }}
                  />
                </AddItemPopup.InputRow>
                <AddItemPopup.InputRow style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <LabelWrapper>
                    <Label>Title</Label>
                  </LabelWrapper>
                  <Input
                    style={{ height: 20, width: 230 }}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    id={'title'}
                    value={values.title}
                    autoComplete="off"
                    list="nameSuggestions"
                  />
                  {errors.title && touched.title ? (
                    <WarningWrapper>
                      {' '}
                      <Icons.WarningIcon
                        fill={colors.yellow}
                        style={{ marginRight: 10 }}
                        width={16}
                      />{' '}
                      {errors.title}
                    </WarningWrapper>
                  ) : null}
                </AddItemPopup.InputRow>
                <AddItemPopup.InputRow style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <LabelWrapper>
                    <Label>Period</Label>
                  </LabelWrapper>
                  <Label>
                    <Input
                      style={{ height: 12, width: 12 }}
                      type={'radio'}
                      value={true}
                      checked={period === true}
                      onChange={(e) => {
                        setPeriodStatus(true);
                      }}
                    />
                    Defined
                  </Label>
                  <Label>
                    <Input
                      style={{ height: 12, width: 12 }}
                      type={'radio'}
                      value={false}
                      checked={period === false}
                      onChange={(e) => {
                        setPeriodStatus(false);
                      }}
                    />
                    Undefined
                  </Label>
                </AddItemPopup.InputRow>
                <LabelWrapper>
                  <Label>Date</Label>
                </LabelWrapper>
                <AddItemPopup.InputRow style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Label>
                    <RangePicker
                      dateFormat="MM/dd/YYYY"
                      defaultValue={[moment(startTime), moment(endTime)]}
                      onChange={([e, i]) => {
                        setStartTimeStatus(new Date(e));
                        setEndTimeStatus(new Date(i));
                      }}
                    />
                  </Label>
                </AddItemPopup.InputRow>
              </AddItemPopup>
            </AddItemPopupWrapper>
          </Modal>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  facebookPages: state.facebookPages,
  banner: state.banner,
});

export default connect(mapStateToProps, { edit, getAll, remove, getProductNameSuggestions, getByPageId })(
  EditBanner,
);

export { EditBanner };
