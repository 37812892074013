import Url from '../config/api';
import { buildAxiosInstance } from 'actions/common';

const baseUrl = `${Url.baseUrl}/Product`;

const axiosClient = buildAxiosInstance(baseUrl);

export const toggleProductStatus = (productId, done) => {
  return (dispatch) => {
    axiosClient
      .put(`${baseUrl}/UpdateProductStatus/${productId}`)
      .then((res) => {
        done && done(null, res.data);
        dispatch({
          type: 'UPDATE_PRODUCT_STATUS_OK',
          data: res.data,
        });
      })
      .catch((err) => {
        done && done(err);
        dispatch({ type: 'UPDATE_PRODUCT_STATUS_ERR' });
      });
  };
};

export const getProductNameSuggestions = (done) => {
  return (dispatch) => {
    axiosClient
      .get(`${baseUrl}/GetProductNameSuggestions`)
      .then((res) => {
        done && done(null, res.data);
        dispatch({
          type: 'ADD_PRODUCT_NAME_SUGGESTIONS',
          data: res.data,
        });
      })
      .catch((err) => {
        done && done(err);
        dispatch({ type: 'ADD_PRODUCT_NAME_SUGGESTIONS_ERROR' });
      });
  };
};
