import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import shared from 'app/shared/';
import styled from 'styled-components';
import SettingsTitle from '../components/SettingsTitle';
import EventCard from '../components/VisibilityEventCard';
import PageEventPopUp from '../components/AddPageEventPopUp';
import {
  getCharacteristics,
  getPageCharacteristics,
  setPageCharacteristics,
  setPageSocialLinks,
} from '../../../actions/visibilitySettingsActions';
import { scrollToAlert } from 'app/shared/views/alert';
import { Select } from 'antd';
import event_image from '../../../assets/images/product_image.png';

const { Option } = Select;

const {
  views: { AddItemPopup, Input, Alert },
  helpers: { colors },
} = shared;

const Wrapper = styled.div`
  padding: 0.7% 30px;
`;

const InlineFlexWrapper = styled.div`
  display: flex;
  margin-top: 3%;
  width: 100%;
`;

const MainLabel = styled.div`
  display: flex;
  font-size: 15px;
  margin-right: 32px;
  color: ${colors.textColor};
`;
const Label = styled.label`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  color: #748aa1;
`;

const VisibilitySettings = ({ setExecuteOnSubmit, setHasChanges, children }) => {
  const dispatch = useDispatch();
  const activePage = useSelector((state) => state.facebookPages.activePage);
  const [addPageEvent, setAddPageEvent] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: '' });
  const [eventAlert, setEventAlert] = useState({ show: false, message: '' });
  const [highlightId, setHighlightId] = useState();
  const [characteristics, setCharacteristics] = useState([]);
  const [selectedCharacteristics, setSelectedCharacteristics] = useState([]);
  const [socialLinks, setSocialLinks] = useState({
    facebook: '',
    instagram: '',
    youTube: '',
    website: '',
    phone: '',
  });
  const alertRef = useRef(null);
  const eventAlertRef = useRef(null);

  useEffect(() => {
    let highlightTimer = setTimeout(() => setHighlightId(null), 5000);
    return () => {
      clearTimeout(highlightTimer);
    };
  }, [highlightId]);

  useEffect(() => {
    let alertTimer = setTimeout(() => setAlert({ show: false, message: '' }), 5000);
    return () => {
      clearTimeout(alertTimer);
    };
  }, [alert]);

  useEffect(() => {
    let eventAlertTimer = setTimeout(() => setEventAlert({ show: false, message: '' }), 5000);
    return () => {
      clearTimeout(eventAlertTimer);
    };
  }, [eventAlert]);

  useEffect(() => {
    dispatch(getCharacteristics(setCharacteristics));
    dispatch(getPageCharacteristics());
  }, [dispatch]);

  useEffect(() => {
    setSelectedCharacteristics([
      ...activePage.pageCharacteristics?.map((obj) => obj?.characteristicId?.toString()),
    ]);
    setSocialLinks({ ...activePage.socialLinks });
  }, [activePage]);

  useEffect(() => {
    setExecuteOnSubmit(() => () => {
      const onDone = (status) => {
        setAlert({
          show: true,
          message:
            status === 'OK'
              ? 'You have successfully saved your changes!'
              : 'Could not update your changes!',
          type: status === 'OK' ? 'success' : 'error',
        });

        scrollToAlert(alertRef);
      };

      dispatch(
        setPageSocialLinks(socialLinks, (status) => {
          if (status === 'OK') {
            dispatch(setPageCharacteristics(selectedCharacteristics, (status2) => onDone(status2)));
          }
        }),
      );
    });
  }, [selectedCharacteristics, socialLinks, dispatch, setExecuteOnSubmit, setHasChanges]);
  return (
    <Wrapper>
      <div style={{ flex: 1 }}>
        <PageEventPopUp
          toggle={() => setAddPageEvent(!addPageEvent)}
          show={addPageEvent}
          pageEvent={addPageEvent}
          setEventAlert={setEventAlert}
          setHighlightId={setHighlightId}
        />
        <SettingsTitle>Online Visibility</SettingsTitle>
        <Alert ref={alertRef} {...alert} />
        <InlineFlexWrapper>
          <MainLabel>Select Restaurant Tags</MainLabel>
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <AddItemPopup.InputRow>
              <Label htmlFor={'Charasteristics'}>Charasteristics</Label>
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder={'Please select charasteristics'}
                value={[...selectedCharacteristics]}
                onChange={(values) => setSelectedCharacteristics([...values])}
                id={'Charasteristics'}
                name={'Charasteristics'}
              >
                {characteristics?.map((characteristic) => (
                  <Option key={characteristic.id}>{characteristic.name}</Option>
                ))}
              </Select>
            </AddItemPopup.InputRow>
          </div>
        </InlineFlexWrapper>
        <InlineFlexWrapper>
          <MainLabel>Business Links</MainLabel>
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <AddItemPopup.InputRow>
              <Label htmlFor={'website'}>Website</Label>
              <Input
                height={38}
                name={'website'}
                value={socialLinks.website}
                onChange={(e) => setSocialLinks({ ...socialLinks, website: e.target.value })}
              />
            </AddItemPopup.InputRow>
            <AddItemPopup.InputRow>
              <Label htmlFor={'phone'}>Phone</Label>
              <Input
                height={38}
                name={'phone'}
                value={socialLinks.phone}
                onChange={(e) => setSocialLinks({ ...socialLinks, phone: e.target.value })}
              />
            </AddItemPopup.InputRow>
            <AddItemPopup.InputRow>
              <Label htmlFor={'facebook'}>Facebook</Label>
              <Input
                height={38}
                name={'facebook'}
                value={socialLinks.facebook}
                onChange={(e) => setSocialLinks({ ...socialLinks, facebook: e.target.value })}
              />
            </AddItemPopup.InputRow>
            <AddItemPopup.InputRow>
              <Label htmlFor={'instagram'}>Instagram</Label>
              <Input
                height={38}
                name={'instagram'}
                value={socialLinks.instagram}
                onChange={(e) => setSocialLinks({ ...socialLinks, instagram: e.target.value })}
              />
            </AddItemPopup.InputRow>
            <AddItemPopup.InputRow>
              <Label htmlFor={'youTube'}>Youtube</Label>
              <Input
                height={38}
                name={'youTube'}
                value={socialLinks.youTube}
                onChange={(e) => setSocialLinks({ ...socialLinks, youTube: e.target.value })}
              />
            </AddItemPopup.InputRow>
          </div>
        </InlineFlexWrapper>
        <InlineFlexWrapper>
          <MainLabel>Events</MainLabel>
          <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
            <AddItemPopup.InputRow
              style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap' }}
            >
              {activePage.pageEvents
                ?.sort((a, b) => {
                  if (a.id < b.id) return -1;
                  if (a.id > b.id) return 1;
                  return 0;
                })
                .map((pageEvent) => (
                  <EventCard
                    key={pageEvent.id}
                    id={pageEvent.id}
                    highlightId
                    isHighlighted={highlightId === pageEvent.id}
                    image={pageEvent.imagePath || event_image}
                    isActive={pageEvent.active}
                    isExpired={pageEvent.date && moment(pageEvent.date) < moment()}
                    title={pageEvent.title}
                    description={pageEvent.description}
                    date={pageEvent.date && moment(pageEvent.date).format('DD-MM-YYYY HH:mm')}
                    onClick={() => setAddPageEvent(pageEvent)}
                  />
                ))}
              <EventCard onClick={() => setAddPageEvent(true)} createEvent={true} />
            </AddItemPopup.InputRow>
          </div>
        </InlineFlexWrapper>
        <Alert ref={eventAlertRef} {...eventAlert} />
      </div>
      <InlineFlexWrapper>
        <MainLabel />
        {children}
      </InlineFlexWrapper>
    </Wrapper>
  );
};

export default VisibilitySettings;

export { VisibilitySettings };
