import React from 'react';
import styled from 'styled-components';
import currencyFormatter from 'app/shared/helpers/currencyFormatter';
import moment from 'moment';

const BillWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const BillHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px 20px 10px;
`;

const HeaderLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #1d2036;
  padding-right: 4px;
`;
const HeaderCounter = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: #5c677b;
`;

const ProductRow = styled.div`
  background-color: ${(props) => (props.index % 2 === 0 ? '#F9FAFC' : '#FFFFFF')};
  padding: 0 20px;
`;
const ProductName = styled.div`
  font-size: 12px;
  color: #1d2036;
  line-height: 20px;
  flex: 1;
`;

const ProductQty = styled.div`
  font-size: 12px;
  color: #5c677b;
  flex: 1;
  text-align: right;
`;

const ProductPrice = styled.div`
  font-size: 12px;
  color: #2ecc71;
  flex: 1;
  font-weight: 600;
  text-align: right;
`;

const NormalProductWrapper = styled.div`
  padding: 8px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const BillTime = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: #5c677b;
`;

const Bill = ({ order, currency }) => {
  return (
    <BillWrapper>
      <BillHeader>
        <HeaderLabel>Order No. </HeaderLabel>
        <HeaderCounter>{order?.orderCounter}</HeaderCounter>
        <HeaderLabel style={{ marginLeft: 'auto' }}>Order Time:</HeaderLabel>
        <BillTime>{moment(order?.orderDate).format('HH:mm')}</BillTime>
      </BillHeader>
      {order?.orderProducts.map((product, index) => (
        <ProductRow index={index} key={product.toString() + index}>
          <NormalProductWrapper>
            <ProductName>{product.name}</ProductName>
            <ProductQty>{product.qty}</ProductQty>
            <ProductPrice>{currencyFormatter(product.price, currency)}</ProductPrice>
          </NormalProductWrapper>
          {product.isCombo &&
            product.toppings?.map((topping, index) => (
              <NormalProductWrapper index={index} key={topping.toString() + index}>
                <ProductName style={{ paddingLeft: 8 }}>{topping.name}</ProductName>
                <ProductQty style={{ paddingRight: 4 }}>{topping.qty}</ProductQty>
                <ProductPrice>{currencyFormatter(topping.price, currency)}</ProductPrice>
              </NormalProductWrapper>
            ))}
        </ProductRow>
      ))}
    </BillWrapper>
  );
};

export default Bill;
