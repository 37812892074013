import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import Select from 'react-select';
import shared from 'app/shared';
import addCategoryIllustration from 'assets/images/addCategoryIllustration.svg';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { add, getAll } from '../../../actions/crudActions';
import { getProductNameSuggestions } from '../../../actions/productActions';
import crudTypes from '../../../config/crudTypes';
const {
  views: { Modal, AddItemPopup, Button, UploadInput, Icons },
  helpers: { colors },
} = shared;

const AddItemPopupWrapper = styled.div`
  width: 450px;
`;

const ErrorWrapper = styled.div`
  width: 100%;
  background: rgba(242, 69, 61, 0.2);
  color: #f2453d;
  margin-bottom: 10px;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  align-items: center;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 1px 5px;
  display: ${(props) => (props.show === 'ERROR' ? 'flex' : 'none')};
`;

const WarningWrapper = styled.div`
  width: 100%;
  display: flex;
  background: rgba(255, 152, 0, 0.2);
  color: #ff9800;
  margin-bottom: 10px;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  align-items: center;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 1px 5px;
  margin-top: 10px;
`;

const LabelWrapper = styled.div`
  padding-left: 10px;
`;

const Label = styled.label`
  color: ${colors.textColor};
  font-size: 12px;
  line-height: 23px;
  font-weight: 500;
`;

const AddProductPopup = (props) => {
  const [uri, setUri] = useState();
  const [postStatus, setPostStatus] = useState();
  const [uploadKey, setUploadKey] = useState(Math.random());
  const [nameSuggestions, setNameSuggestion] = useState([]);
  const { toggle, categoryType, getAll } = props;

  //const { getProductNameSuggestions } = props;

  const updateNameSuggestions = (nameSuggestion) => {
    const newList = props.products.productNameSuggestions.filter((prodName) =>
      prodName.toLowerCase().startsWith(nameSuggestion),
    );
    setNameSuggestion(newList);
  };

  const changePostStatus = (resetForm) => (status) => {
    setPostStatus(status);
    if (status === 'OK') {
      getAll(crudTypes.category);
      setUri(null);
      resetForm();
      setUploadKey(Math.random());
      toggle();
    }
  };

  const options = [];

  const getOptions = () =>
    props.categories.data && props.categories.data.length > 0
      ? props.categories.data.map((category) => ({
          value: category.id,
          label: category.name,
        }))
      : options;

  if (!props.show) {
    return null;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: '',
        categoryId: props.preselectCategory,
        price: '',
        description: '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required('Product Name is required')
          .test('len', 'Max 45 characters allowed', (val) => val?.length < 46),
        categoryId: Yup.string().required('Category is required'),
        description: Yup.string().test(
          'len',
          'Max 125 characters allowed',
          (val) => val?.length < 126 || val === null || val === undefined,
        ),
        price: Yup.number('Price must be a number')
          .positive('Price must be over 0')
          .min(1)
          .required('Price is required'),
      })}
      onSubmit={(values, { resetForm }) => {
        let newValues;
        if (uri) {
          newValues = {
            name: values.name,
            categoryId: values.categoryId,
            price: values.price,
            description: values.description,
            imagePath: uri,
          };
        } else {
          newValues = {
            name: values.name,
            categoryId: values.categoryId,
            price: values.price,
            description: values.description,
          };
        }

        props.add(crudTypes.product, newValues, changePostStatus(resetForm));
      }}
    >
      {(innerProps) => {
        const { touched, errors, values, handleChange, handleSubmit, setFieldValue } = innerProps;
        return (
          <Modal toggle={props.toggle} show={props.show}>
            <AddItemPopupWrapper>
              <AddItemPopup
                title={'Add New Product'}
                actions={[
                  () => (
                    <Button.Button
                      color={colors.blue}
                      text={'Save'}
                      onClick={() => handleSubmit()}
                    />
                  ),
                  () => (
                    <Button.WhiteButton
                      text={'Cancel'}
                      onClick={() => {
                        props.toggle();
                      }}
                    />
                  ),
                ]}
                illustration={addCategoryIllustration}
              >
                <ErrorWrapper show={postStatus}>
                  <Icons.XIcon fill={colors.red} style={{ marginRight: 10 }} width={16} />
                  <span>Error adding the product</span>
                </ErrorWrapper>
                <AddItemPopup.InputRow>
                  <UploadInput
                    labelText={'Upload Image'}
                    value={''}
                    key={uploadKey}
                    uriGenerator={(uri) => {
                      setUri(uri);
                    }}
                  />
                </AddItemPopup.InputRow>
                <AddItemPopup.InputRow>
                  <LabelWrapper>
                    <Label>Product Name</Label>
                  </LabelWrapper>
                  <Input
                    style={{ height: 40 }}
                    onChange={(e) => {
                      handleChange(e);
                      updateNameSuggestions(e.target.value);
                    }}
                    id={'name'}
                    value={values.name}
                    autoComplete="off"
                    list="nameSuggestions"
                  />
                  <datalist id="nameSuggestions">
                    {nameSuggestions?.map((name) => (
                      <option value={name}> </option>
                    ))}
                  </datalist>
                  {errors.name && touched.name ? (
                    <WarningWrapper>
                      {' '}
                      <Icons.WarningIcon
                        fill={colors.yellow}
                        style={{ marginRight: 10 }}
                        width={16}
                      />{' '}
                      {errors.name}
                    </WarningWrapper>
                  ) : null}
                </AddItemPopup.InputRow>
                {!categoryType && (
                  <AddItemPopup.InputRow>
                    <LabelWrapper>
                      <Label>Category</Label>
                    </LabelWrapper>
                    <Select
                      name={'categoryId'}
                      options={getOptions()}
                      onChange={(val) => setFieldValue('categoryId', val.value)}
                      isSearchable={'false'}
                      style={{ width: '-webkit-fill-available', height: 40 }}
                      value={getOptions().find((opt) => opt.value === values.categoryId)}
                    />
                    {errors.categoryId && touched.categoryId ? (
                      <WarningWrapper>
                        <Icons.WarningIcon
                          fill={colors.yellow}
                          style={{ marginRight: 10 }}
                          width={16}
                        />{' '}
                        {errors.categoryId}
                      </WarningWrapper>
                    ) : null}
                  </AddItemPopup.InputRow>
                )}
                <AddItemPopup.InputRow>
                  <LabelWrapper>
                    <Label>Price</Label>
                  </LabelWrapper>
                  <Input
                    addonBefore={props.facebookPages?.activePage?.currency}
                    style={{ height: 40 }}
                    onChange={handleChange}
                    id={'price'}
                    value={values.price}
                    autoComplete="off"
                  />
                  {errors.price && touched.price ? (
                    <WarningWrapper>
                      <Icons.WarningIcon
                        fill={colors.yellow}
                        style={{ marginRight: 10 }}
                        width={16}
                      />
                      {errors.price}
                    </WarningWrapper>
                  ) : null}
                </AddItemPopup.InputRow>
                <AddItemPopup.InputRow>
                  <LabelWrapper>
                    <Label>Description</Label>
                  </LabelWrapper>
                  <Input
                    style={{ height: 40 }}
                    onChange={handleChange}
                    id={'description'}
                    value={values.description}
                    autoComplete="off"
                  />
                  {errors.description && touched.description ? (
                    <WarningWrapper>
                      {' '}
                      <Icons.WarningIcon
                        fill={colors.yellow}
                        style={{ marginRight: 10 }}
                        width={16}
                      />{' '}
                      {errors.description}
                    </WarningWrapper>
                  ) : null}
                </AddItemPopup.InputRow>
              </AddItemPopup>
            </AddItemPopupWrapper>
          </Modal>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  facebookPages: state.facebookPages,
  categories: state.categories,
  products: state.products,
});

export default connect(mapStateToProps, { add, getAll, getProductNameSuggestions })(
  AddProductPopup,
);

export { AddProductPopup };
