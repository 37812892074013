import React, { Component } from 'react';
import { connect } from 'react-redux';
import shared from '../../../../app/shared';

const {
  helpers: {
    resetScroll
  },
  views: {
    Page
  },
} = shared;

const initialState = {
};

class AdminHelpCenterPage extends Component {
  state = initialState;

  componentWillMount() {

  }

  componentDidMount() {
    resetScroll();
  }

  render() {
    return (
      <Page>TODO: Help Center</Page>
      );
  }
}

AdminHelpCenterPage.path = '/admin/helpCenter';

const mapStateToProps = state => ({
});

export default connect(
  mapStateToProps,
  { },
)(AdminHelpCenterPage);
