import React, { useState } from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';
import moment from 'moment';
import shared from 'app/shared';
import { NavLink } from 'react-router-dom';
import products from '../../products';
import clients from '../../clients';
// import orders from '../../orders';
import currencyFormatter from 'app/shared/helpers/currencyFormatter';



const {
  views: { EditClient },
} = clients;

const {
  views: { EditProduct },
} = products;

// const {
//   views: { EditOrder },
// } = orders;

const {
  views: { Card, TabBar, Table, Button },
  helpers: { colors },
} = shared;

const tabs = [
  {
    text: 'Top Orders',
    value: 'topOrders',
  },
  {
    text: 'Top Clients',
    value: 'topClients',
  },
  {
    text: 'Top Products',
    value: 'topProducts',
  },
];

const TopBarCard = styled(Card)`
  min-height: 364px;
  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }

  @media all and (max-width: 768px) {
    display: none;
  }
`;

const MobileCard = styled(Card)`
  display: none;

  @media all and (max-width: 1024px) {
    display: block;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopSection = (props, updateTab) => {
  const [currentTab, updateCurrentTab] = useState('topOrders');

  const getTopData = () => {
    const getCondition = () => {
      switch (currentTab) {
        case 'topOrders':
          return 'ordersTotalValue';
        case 'topClients':
          return 'ordersCount';
        case 'topProducts':
          return 'totalCount';
        default:
          return 'id';
      }
    };

    const getValues = (currentTab) => {
      const values = { ...props[currentTab] };

      switch (currentTab) {
        case 'topOrders':
          values.data = values.data.filter((value) => {
            return value;
          });
        break;

        case 'topProducts':
          values.data = values.data.filter((product) => {
            return product;
          });
        break;

        case 'topClients':
          values.data = values.data.filter((client) => client);
        break;

        default:
          // return values
      }

      return values;
    };

    const values = getValues(currentTab);

    if (!(values.data && values.data.length > 0)) return [];

    values.data.sort((a, b) => {
      const attribute = getCondition();
      return Number(b[attribute]) - Number(a[attribute]);
    });

    return values.data.slice(0, 10);
  };

  const getTableData = () => {
    switch (currentTab) {
      case 'topOrders':
        return {
          header: [
            {
              label: 'BILL NO.',
              key: 'billId',
              sortable: false,
              renderer: ({ billId }) => billId,
            },
            {
              label: 'CLIENT',
              key: 'clientPhone',
              sortable: false,
            },
            {
              label: 'SOURCE',
              key: 'source',
              sortable: false,
              renderer: ({ source, tableName }) => {
                const mapSource = {
                  'online-order': 'Online',
                  'table-order': 'Table',
                  'mobile-order': 'Mobile',
                  'internal-order': 'Internal',
                  'marketplace-order': 'Marketplace',
                  'pok-order': 'POK',
                };
                let sourceLabel = tableName || mapSource[source];
                return (
                  <Button.Button
                    text={sourceLabel}
                    size={Button.ButtonSize.medium}
                    style={{
                      backgroundColor: tableName ? '#2ECC71' : colors.blue,
                      bordeRadius: 3,
                      fontWeight: 'bold',
                      fontSize: '12px',
                      lineHeight: '14px',
                      textAlign: 'center',
                      color: colors.white,
                    }}
                  />
                );
              },
            },
            {
              label: 'QUANTITY',
              key: 'ordersCount',
              sortable: false,
            },
            {
              label: 'VALUE',
              key: 'ordersTotalValue',
              sortable: false,
              renderer: ({ ordersTotalValue }) => `${currencyFormatter(ordersTotalValue)}`,
            },
            {
              label: 'DATE CREATED',
              key: 'date',
              sortable: false,
              renderer: ({ date }) => moment(date).format('YYYY-MM-DD HH:mm'),
            },
            // {
            //   label: 'ACTION',
            //   width: '70px',
            //   key: 'id',
            //   sortable: true,
            //   renderer: ({ billId }) => {
            //     return (
            //       <NavLink to={`${EditOrder.path}/${billId}`}>
            //         <Button.Button
            //           style={{ fontWeight: 600 }}
            //           text={'View'}
            //           size={Button.ButtonSize.medium}
            //           color={colors.blue}
            //         />
            //       </NavLink>
            //     );
            //   },
            // },
          ],
          values: getTopData(),
        };
      case 'topProducts':
        return {
          header: [
            {
              label: 'NAME',
              key: 'name',
              sortable: false,
            },
            {
              label: 'PRICE',
              key: 'price',
              sortable: false,
              renderer: ({ price }) => `${currencyFormatter(price)}`,
            },
            {
              label: 'QUANTITY',
              key: 'totalCount',
              sortable: false,
              renderer: ({ totalCount }) => (
                <Button.Button
                  text={totalCount}
                  size={Button.ButtonSize.medium}
                  style={{
                    backgroundColor: colors.lightBlue,
                    fontWeight: 'bold',
                    fontSize: '12px',
                    lineHeight: '14px',
                    textAlign: 'center',
                    color: colors.blue,
                  }}
                />
              ),
            },
            {
              label: 'CATEGORY',
              key: 'categoryName',
              sortable: false,
              renderer: ({ categoryName }) => categoryName,
            },
            {
              label: 'DESCRIPTION',
              key: 'description',
              sortable: false,
              renderer: ({ description }) => description,
            },
            {
              label: 'ACTION',
              width: '70px',
              key: 'id',
              sortable: false,
              renderer: ({ id }) => {
                return (
                  <NavLink to={`${EditProduct.path}/${id}`} style={{ textDecoration: 'none' }}>
                    <Button.Button
                      style={{ fontWeight: 600 }}
                      text={'View'}
                      size={Button.ButtonSize.medium}
                      color={colors.blue}
                    />
                  </NavLink>
                );
              },
            },
          ],
          values: getTopData(),
        };
      case 'topClients':
        return {
          header: [
            {
              label: 'CLIENT',
              key: 'phoneNumber',
              sortable: false,
              renderer: ({ phoneNumber }) => {
                return phoneNumber;
              },
            },
            {
              label: 'ORDERS',
              key: 'ordersCount',
              sortable: false,
              renderer: ({ ordersCount }) => (
                <Button.Button
                  text={ordersCount}
                  size={Button.ButtonSize.medium}
                  style={{
                    backgroundColor: colors.lightBlue,
                    fontWeight: 'bold',
                    fontSize: '12px',
                    lineHeight: '14px',
                    textAlign: 'center',
                    color: colors.blue,
                  }}
                />
              ),
            },
            {
              label: 'REVENUE',
              key: 'ordersTotalValue',
              sortable: false,
              renderer: ({ ordersTotalValue }) => `${currencyFormatter(ordersTotalValue)}`,
            },
            {
              label: 'LOCATION',
              key: 'address',
              sortable: false,
              renderer: ({ address }) =>
                address
            },
            {
              label: 'ACTION',
              width: '70px',
              key: 'id',
              sortable: false,
              renderer: ({ id }) => {
                return (
                  <NavLink to={`${EditClient.path}/${id}`} style={{ textDecoration: 'none' }}>
                    <Button.Button
                      style={{ fontWeight: 600 }}
                      text="Edit"
                      size={Button.ButtonSize.medium}
                      color={colors.blue}
                    />
                  </NavLink>
                );
              },
            },
          ],
          values: getTopData(),
        };
      default:
        return {
          header: [
            {
              label: 'NAME',
              key: 'fullName',
              sortable: true,
            },
            {
              label: 'QUANTITY',
              key: 'quantity',
              renderer: ({ quantity }) => `${quantity}`,
              sortable: true,
            },
            {
              label: 'DATE CREATED',
              key: 'dateCreated',
              sortable: true,
            },
            {
              label: 'ACTION',
              width: '70px',
              key: 'action',
              sortable: false,
              renderer: () => {
                return (
                  <Button.Button
                    style={{ fontWeight: 600 }}
                    text={'View'}
                    size={Button.ButtonSize.medium}
                    color={colors.blue}
                  />
                );
              },
            },
          ],
          values: getTopData(),
        };
    }
  };

  const getTableDataTablet = () => {
    switch (currentTab) {
      case 'topOrders':
        return {
          header: [
            {
              label: 'BILL NO.',
              key: 'billId',
              sortable: false,
              renderer: ({ billId }) => billId,
            },
            {
              label: 'VALUE',
              key: 'ordersTotalValue',
              renderer: ({ ordersTotalValue }) => `${currencyFormatter(ordersTotalValue)}`,
              sortable: false,
            },
            // {
            //   label: 'ACTION',
            //   width: '70px',
            //   key: 'billId',
            //   sortable: false,
            //   renderer: ({ billId }) => {
            //     return (
            //       <NavLink to={`${EditOrder.path}/${billId}`}>
            //         <Button.Button
            //           style={{ fontWeight: 600 }}
            //           text={'View'}
            //           size={Button.ButtonSize.medium}
            //           color={colors.blue}
            //         />
            //       </NavLink>
            //     );
            //   },
            // },
          ],
          values: getTopData(),
        };
      case 'topProducts':
        return {
          header: [
            {
              label: 'NAME',
              key: 'name',
              sortable: true,
            },
            {
              label: 'PRICE',
              key: 'price',
              renderer: ({ price }) => `${currencyFormatter(price)}`,
              sortable: true,
            },
            {
              label: 'ACTION',
              width: '70px',
              key: 'id',
              sortable: false,
              renderer: ({ id }) => {
                return (
                  <NavLink to={`${EditProduct.path}/${id}`} style={{ textDecoration: 'none' }}>
                    <Button.Button
                      style={{ fontWeight: 600 }}
                      text={'View'}
                      size={Button.ButtonSize.medium}
                      color={colors.blue}
                    />
                  </NavLink>
                );
              },
            },
          ],
          values: getTopData(),
        };
      case 'topClients':
        return {
          header: [
            // {
            //   label: 'CLIENT',
            //   key: 'client',
            //   sortable: false,
            //   renderer: ({ email, phone }) => {
            //     return email || phone || '-';
            //   },
            // },
            {
              label: 'PHONE',
              key: 'phoneNumber',
              renderer: ({ phoneNumber }) => phoneNumber,
              sortable: true,
            },
            {
              label: 'ACTION',
              width: '70px',
              key: 'id',
              sortable: false,
              renderer: ({ id }) => {
                return (
                  <NavLink to={`${EditClient.path}/${id}`} style={{ textDecoration: 'none' }}>
                    <Button.Button
                      style={{ fontWeight: 600 }}
                      text="Edit"
                      size={Button.ButtonSize.medium}
                      color={colors.blue}
                    />
                  </NavLink>
                );
              },
            },
          ],
          values: getTopData(),
        };
      default:
        return {
          header: [
            {
              label: 'NAME',
              key: 'fullName',
              sortable: true,
            },
            {
              label: 'QUANTITY',
              key: 'quantity',
              renderer: ({ quantity }) => `$${quantity}`,
              sortable: true,
            },
            {
              label: 'DATE CREATED',
              key: 'dateCreated',
              sortable: true,
            },
            {
              label: 'ACTION',
              width: '70px',
              key: 'action',
              sortable: false,
              renderer: () => {
                return (
                  <Button.Button
                    style={{ fontWeight: 600 }}
                    text={'View'}
                    size={Button.ButtonSize.medium}
                    color={colors.blue}
                  />
                );
              },
            },
          ],
          values: getTopData(),
        };
    }
  };

  const updateState = (value) => {
    updateCurrentTab(value);
    props.updateTab(value);
  };

  return (
    <TopWrapper>
      <TabBar
        tabs={tabs}
        selectedElement={currentTab}
        updateSelectedElement={(value) => updateState(value)}
      />
      <TopBarCard style={{ marginTop: 10 }}>
        <Table.TableView data={getTableData()} />
      </TopBarCard>

      <MobileCard style={{ marginTop: 10 }}>
        <Table.TableView data={getTableDataTablet()} />
      </MobileCard>
    </TopWrapper>
  );
};

const mapStateToProps = (state) => ({
  tables: state.tables,
  topOrders: state.topOrders,
  topClients: state.topClients,
  topProducts: state.topProducts
});

export default connect(mapStateToProps)(TopSection);

export { TopSection };
