import moment from 'moment';

const CategoryCsvMapper = categories => categories.map(c => 
    ({
        'Name': c.name,
        'Category Type': c.categoryType?'Extra Category': 'Main Category',
        'Creation Date': moment(c.creationDate).format('YYYY-MM-DD HH:mm'),
        'Number of Products': c.productCount,
        'Products': c.magProducts.map(p => p.name).join(', ')
    }));

export default CategoryCsvMapper;