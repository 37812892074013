import Url from '../config/api';
import { buildAxiosInstance } from 'actions/common';

const { baseUrl} = Url;

const baseURI = `${baseUrl}/admin/`;

const axiosClient = buildAxiosInstance(baseUrl);

export const getAllOrders = (setStatus=null) => {
  return (dipatch) => {
    axiosClient.get(`${baseURI}GetAllOrders`)
          .then(res =>{
              if(res.status >= 200 && res.status <= 300){
                  setStatus && setStatus('OK');
                  dipatch({
                    type: 'GET_ORDERS_OK',
                    data: res.data
                  })
              }
          })
          .catch(() => {
              setStatus && setStatus('ERROR');
              dipatch({type: 'GET_ORDERS_ERROR'})
      })
  }
}

export const getAllPages = (setStatus=null) => {
  return (dipatch) => {
    axiosClient.get(`${baseURI}GetAllPages`)
          .then(res =>{
              if(res.status >= 200 && res.status <= 300){
                  setStatus && setStatus('OK');
                  dipatch({
                    type: 'GET_PAGES_OK',
                    data: res.data
                  })
              }
          })
          .catch(() => {
              setStatus && setStatus('ERROR');
              dipatch({type: 'GET_PAGES_ERROR'})
      })
  }
}

export const getAllSubscriptions = (setStatus=null, pageId = 0) => {
    return (dipatch) => {
      axiosClient.get(`${baseURI}GetAllSubscriptions?pageId=${pageId}`)
            .then(res =>{
                if(res.status >= 200 && res.status <= 300){
                    setStatus && setStatus('OK');
                    dipatch({
                      type: 'GET_SUBSCRIPTIONS_OK',
                      data: res.data
                    })
                }
            })
            .catch(() => {
                setStatus && setStatus('ERROR');
                dipatch({type: 'GET_SUBSCRIPTIONS_ERROR'})
        })
    }
}

export const getTopNewClients = (setStatus = null) => {
  return (dipatch) => {
    axiosClient.get(`${baseURI}GetTopNewClients/`)
      .then(res => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
          dipatch({
            type: 'GET_TOP_NEW_CLIENTS_OK',
            data: res.data
          })
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
        dipatch({ type: 'GET_TOP_NEW_CLIENTS_ERROR' })
      })
  }
}

export const getTopPayingClients = (setStatus = null) => {
  return (dipatch) => {
    axiosClient.get(`${baseURI}GetTopPayingClients/`)
      .then(res => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
          dipatch({
            type: 'GET_TOP_PAYING_CLIENTS_OK',
            data: res.data
          })
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
        dipatch({ type: 'GET_TOP_PAYING_CLIENTS_ERROR' })
      })
  }
}

export const getTopInactiveClients = (setStatus = null) => {
  return (dipatch) => {
    axiosClient.get(`${baseURI}GetTopInactiveClients/`)
      .then(res => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
          dipatch({
            type: 'GET_TOP_INACTIVE_CLIENTS_OK',
            data: res.data
          })
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
        dipatch({ type: 'GET_TOP_INACTIVE_CLIENTS_ERROR' })
      })
  }
}

export const getTotalsClients = (setStatus = null, filter = 0, clientId = 0) => {
  return (dipatch) => {
    axiosClient.get(`${baseURI}GetTotalsClients?filter=${filter}&clientId=${clientId}`)
      .then(res => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
          dipatch({
            type: 'GET_TOTALS_CLIENTS_OK',
            data: res.data
          })
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
        dipatch({ type: 'GET_TOTALS_CLIENTS_OK_ERROR' })
      })
  }
}

export const getSubscriptionInvoices = (setStatus = null, pageId = 0) => {
  return (dipatch) => {
    axiosClient.get(`${baseURI}/GetSubscriptionInvoices/${pageId}`)
      .then(res => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
          dipatch({
            type: 'GET_SUBSCRIPTION_INVOICES_OK',
            data: res.data
          })
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
        dipatch({ type: 'GET_SUBSCRIPTION_INVOICES_ERROR' })
      })
  }
};

export const getAllUsers = (page = 1, done) => {
  return (dipatch) => {
    axiosClient.get(`${baseUrl}/Auth/users/${page}/10`)
      .then(res => {
        if (res.status >= 200 && res.status <= 300) {
          dipatch({
            type: 'GET_ALL_USERS_OK',
            data: res.data,
          });
          done && done(null, res);
        }
      })
      .catch(err => {
        dipatch({ type: 'GET_ALL_USERS_ERROR' });
        done && done(err);
      })
  }
};

export const lockUser = (userId, done) => {
  return (dipatch) => {
    axiosClient.get(`${baseUrl}/Auth/lock-account/${userId}`)
      .then(res => {
        if (res.status >= 200 && res.status <= 300) {
          dipatch({
            type: 'LOCK_USER_OK',
            data: {
              userId,
            },
          });
          done && done(null, res.data);
        }
      })
      .catch(err => {
        dipatch({ type: 'LOCK_USER_ERROR' });
        done && done(err);
      });
  };
};

export const unlockUser = (userId, done) => {
  return (dipatch) => {
    axiosClient.get(`${baseUrl}/Auth/unlock-account/${userId}`)
      .then(res => {
        if (res.status >= 200 && res.status <= 300) {
          dipatch({
            type: 'UNLOCK_USER_OK',
            data: {
              userId,
            },
          });
          done && done(null, res.data);
        }
      })
      .catch((err) => {
        dipatch({ type: 'UNLOCK_USER_ERROR' });
        done && done(err);
      });
  };
};
