import moment from 'moment';

const filterByPeriod = (item, customDate) => {
  // const today = moment().clone().startOf('day');
  // const yesterday = today.clone().subtract(1, 'days').startOf('day');
  // const lastWeek = today.clone().subtract(1, 'week').startOf('week');
  // const lastMonth = today.clone().subtract(1, 'month').startOf('month');
  // const creationDate = moment(item.creationDate);
  // switch (selectedPeriod) {
  //   case 'today': return creationDate.format('L') === today.format('L');
  //   case 'yesterday': return creationDate.format('L') === yesterday.format('L');
  //   case 'last-week': return creationDate.isBetween(lastWeek, lastWeek.clone().add(1, 'week'));
  //   case 'last-month': return creationDate.isBetween(lastMonth, lastMonth.clone().add(1, 'month'));
  //   case 'custom': {
  //     if (customDate.startDate && customDate.endDate) {
  //       return creationDate.isBetween(customDate.startDate.clone().startOf('day'), customDate.endDate.clone().endOf('day'));
  //     }
  //     return true;
  //   }
  //   default: return true;
  // }

  const lastOrderDate = moment(item.lastOrderDate);

  if (customDate.startDate && customDate.endDate) {
    return lastOrderDate.isBetween(customDate.startDate.clone().startOf('day'), customDate.endDate.clone().endOf('day'));
  }
  return true;
};

const isNumInRange = (num, min, max) => {
  if (typeof num !== 'number') {
    return false;
  }

  const hasMin = typeof min === 'number';
  const hasMax = typeof max === 'number';

  if (hasMin && hasMax) {
    return num >= min && num <= max;
  } else if (hasMin) {
    return num >= min;
  } else if (hasMax) {
    return num <= max;
  } else {
    return true;
  }
};

const filterByOrderCount = (item, min, max) => {
  return isNumInRange(item.orderCount, min, max);
};

const filterByRevenue = (item, min, max) => {
  return isNumInRange(item.totalSum, min, max);
};

export const applyCustomFilters = ({ customDate, orderMin, orderMax, revenueMin, revenueMax }) => {
  return (item) => {
    return [
      filterByPeriod(item, customDate),
      filterByOrderCount(item, orderMin, orderMax),
      filterByRevenue(item, revenueMin, revenueMax),
    ].every(flag => flag);
  }
};
