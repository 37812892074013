const getActivePage = () => {
  let pageID = JSON.parse(localStorage.getItem('Page'));
  let pages = JSON.parse(localStorage.getItem('Pages'));
  return pages?.find((p) => p.id === pageID);
};

export function partialNumberFormatter(input) {
  return input;
}

const FALLBACK_CURRENCY = 'USD';
export default (input, currency = null, opts = {}) => {
  let formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: currency ?? getActivePage()?.currency ?? FALLBACK_CURRENCY,
    ...opts,
  });

  return formatter.format(input).replace(',00', '');
};
