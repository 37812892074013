import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import _ from 'lodash';

import { setupAutoSuggest, getLatLng, geocodeByLatLng, getCurrentLocation } from './helper';
import mapThemes from './styles.json';
import MarkerIcon from './images/marker.svg';
import RestaurantMarkerIcone from './images/restaurantMarker.svg';

import ConfigMap from '../../../../config/map';
import Icons from '../icons';
import colors from '../../helpers/colors';
// eslint-disable-next-line
import ZonePolygon, { POLYGON_OPTIONS, getVertices } from './zonePolygon';

import './css/locationPicker.css';

const mapStyles = {
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  map: {
    position: 'flex',
    width: '100%',
    height: '100%',
  },
};

const MemoMarker = React.memo(
  Marker,
  (a, b) => _.isEqual(a.position, b.position) && _.isEqual(a.map, b.map),
);

const LocationPicker = React.memo((props) => {
  const {
    google,
    theme,
    location,
    onLocationPicked,
    autocompleteRef,
    resetToCurrent,

    zones = [],
    addZone = false,
    onZoneAdded,
    hoveredZone,
    setHoveredZone,
    setCurrentZone,

    pageInfo,
    ...rest
  } = props;

  const map = useRef();
  const [centerPos, setCenterPos] = useState({
    lat: 41.327705,
    lng: 19.818546,
  });
  const [pageLocation, setPageLocation] = useState(null);

  // if page info provided display blue marker and centre to bussines location
  useEffect(() => {
    if (pageInfo?.locationCoords) {
      setPageLocation({
        pos: pageInfo.locationCoords,
        address: pageInfo.location,
      });
      setCenterPos(pageInfo.locationCoords);
    }
  }, [pageInfo]);

  useEffect(() => {
    if (!map.current || !addZone) return;
    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      polygonOptions: {
        ...POLYGON_OPTIONS,
        editable: true,
        draggable: true,
      },
    });
    drawingManager.setMap(map.current);
    const listener = google.maps.event.addListener(drawingManager, 'polygoncomplete', (polygon) => {
      if (onZoneAdded) {
        polygon.setMap(null);
        onZoneAdded(getVertices(polygon.getPath().getArray()));
      }
    });

    return () => {
      google.maps.event.removeListener(listener);
      drawingManager.setMap(null);
    };
  }, [google, addZone, onZoneAdded]);

  useEffect(() => {
    if (!google || !setCurrentZone) return;
    let currentZone = null;
    if (location && location.pos) {
      const pt = new google.maps.LatLng(location.pos.lat, location.pos.lng);
      currentZone =
        location &&
        zones?.find((z) =>
          google.maps.geometry.poly.containsLocation(
            pt,
            new google.maps.Polygon({ paths: z.restrictedArea }),
          ),
        );
    }
    setCurrentZone(currentZone);
  }, [google, location, zones, setCurrentZone]);

  const onLocationChanged = useCallback(
    async (event) => {
      const pos = await getLatLng(event);
      const results = await geocodeByLatLng(google, pos);
      const address = results[0].formatted_address;
      const newLocation = {
        pos,
        address,
      };
      if (typeof onLocationPicked === 'function') {
        onLocationPicked(newLocation);
      }
    },
    [google, onLocationPicked],
  );

  useEffect(() => {
    if (resetToCurrent) {
      getCurrentLocation(ConfigMap.googleMapsKey).then((pos) => {
        setCenterPos({
          lat: pos.lat(),
          lng: pos.lng(),
        });
        onLocationChanged({ latLng: pos });
      });
    }
  }, [resetToCurrent, onLocationChanged]);

  useEffect(() => {
    if (location === null || (!location?.address && !location?.pos)) {
      getCurrentLocation(ConfigMap.googleMapsKey).then((pos) => {
        const newPos = {
          lat: pos.lat(),
          lng: pos.lng(),
        };
        geocodeByLatLng(google, newPos).then((results) => {
          const address = results[0].formatted_address;
          onLocationPicked &&
            onLocationPicked({
              pos: newPos,
              address,
            });
          setCenterPos(newPos);
        });
      });
    } else if (location) {
      if (!location.address && location.pos) {
        geocodeByLatLng(google, location.pos).then((results) => {
          const address = results[0].formatted_address;
          onLocationPicked &&
            onLocationPicked({
              pos: location.pos,
              address,
            });
          setCenterPos(location.pos);
        });
      }
      if (location.pos) {
        setCenterPos(location.pos);
      }
    }
  }, [location, onLocationPicked, google]);

  const onSuggestClicked = useCallback(
    async (place) => {
      let address = place.formatted_address;
      if (!address.startsWith(place.name)) {
        address = `${place.name}, ${address}`;
      }
      const pos = await getLatLng(place.geometry);
      const newLocation = {
        pos,
        address,
      };

      setCenterPos(newLocation.pos);
      if (typeof onLocationPicked === 'function') {
        onLocationPicked(newLocation);
      }
    },
    [onLocationPicked],
  );

  return (
    <Map
      {...rest}
      // initialCenter={centerPos}
      google={google}
      center={centerPos}
      gestureHandling="greedy"
      style={{ ...mapStyles.map, ...(props.mapStyles || {}) }}
      containerStyle={{ ...mapStyles.container, ...(props.containerStyles || {}) }}
      styles={mapThemes[theme] || []}
      onClick={(a, b, e) => onLocationChanged(e)}
      onReady={(_, _map) => {
        map.current = _map;
        if (props.suggestInputRef) {
          setupAutoSuggest(google, props.suggestInputRef, onSuggestClicked);
        }
      }}
      disableDefaultUI
    >
      <MemoMarker
        title={location?.address || 'My location'}
        position={location?.pos}
        draggable={true}
        onDragend={(a, b, e) => onLocationChanged(e)}
        icon={{
          url: MarkerIcon,
        }}
      />

      {pageInfo && pageLocation?.pos && (
        <MemoMarker
          google={google}
          title={pageInfo.pageName}
          position={pageLocation?.pos}
          icon={{
            url: RestaurantMarkerIcone,
          }}
        />
      )}
      {!pageInfo &&
        zones.map(({ restrictedArea: zone }, i) => (
          <ZonePolygon
            key={i}
            paths={zone}
            hover={hoveredZone === i}
            onMouseOver={() => setHoveredZone && setHoveredZone(i)}
            onMouseOut={() => setHoveredZone && setHoveredZone(null)}
            onClick={(e) => onLocationChanged(e)}
            name={`Zone ${i + 1}`}
          />
        ))}
    </Map>
  );
});

const MapsLoadingContainer = () => (
  <span style={{ display: 'flex', justifyContent: 'center' }}>
    <Icons.Loading fill={colors.blue} width="20px" />
  </span>
);

export default GoogleApiWrapper({
  apiKey: ConfigMap.googleMapsKey,
  LoadingContainer: MapsLoadingContainer,
  libraries: ['places', 'geometry', 'drawing'],
})(LocationPicker);
