const initialState = {
  data: null
}

export default (state = initialState, action) => {
  switch(action.type){
    case 'GET_POK_CONF_OK':
      return {
        data : {...action.data}
      }
      case 'GET_POK_CRED_OK':
      return {
        data : {...action.data}
      }
    case 'GET_POK_CONF_ERROR':
      return {
        ...initialState
      }
    case 'CHANGE_POK_CONF_OK':
      return {
        data: {...action.data}
      }
    case 'REMOVE_POK_CONF_OK':
      return {
        data: {}
      }
    default :
      return {
        ...state
      }
  }
}
