export const ORDER_STATUS = {
  0: 'Pending',
  1: 'Paid',
  2: 'Delivering',
  4: 'Pending',
  5: 'Cancelled',
  6: 'Waiting for Confirmation',
};

export const ORDER_PAYMENT_METHODS = {
  0: 'Cash',
  1: 'Cash',
  2: 'Cash',
  3: 'POK',
};
