import React from 'react';
import Modal from '../../shared/views/modal';
import AddItemPopup from '../../shared/views/addItemPopup';
import styled from 'styled-components';
import StarRatings from 'react-star-ratings';
import ReviewComponent from './ReviewComponent';
import Review from '../../../assets/images/Review.svg';

const UserReviewModal = (props) => {
  const {
    userId,
    users,
    toggle,
  } = props;

  return (
    <Modal 
      show={userId ? true : false} 
      toggle={toggle} showCloseIcon={false}
      illustration={Review}
    >
        <AddItemPopupWrapper>
          <AddItemPopup
              title={'User Review'}
              illustration={Review}
              style={{padding: 0}}
              SubHeader={()=>(
                <div style={{borderBottom: '1px solid #BFCADB', width: '100%'}}>
                  <Content>
                    <Flex>
                        <TopLabelWrapper>
                            {`Overall Review ${users.find((x) => x.id === userId)?.waiterReviews.length || 0}`}
                        </TopLabelWrapper>
                        <StarRatings
                            rating={users.find((x) => x.id === userId)?.reviewRatingAverage || 0}
                            starRatedColor="#FFD037"
                            numberOfStars={5}
                            starDimension="20px"
                            starSpacing="4px"
                            name='rating'
                        /> 
                    </Flex>
                  </Content>
                </div>
              )}
          >            
            </AddItemPopup>
            <Scrollable>
                {users.find((x) => x.id === userId)?.waiterReviews.map(
                    (rv) => <ReviewComponent rating={rv.rating} comment={rv.comment} date={rv.createdDate}/>
                    )}
            </Scrollable>
        </AddItemPopupWrapper>
    </Modal>
  );
};

export default UserReviewModal;

const AddItemPopupWrapper = styled.div`
  width: 860px;
  height: 600px;
`;

const Content = styled.div`
  padding: 24px 35px;
  `;
  
const Scrollable = styled.div`
    overflow: scroll;
    height: 420px;
    padding-left: 16px;
    margin: 0 12px 35px 20px;
`;

const Flex = styled.div`
  display: flex;
`;

const TopLabelWrapper = styled.div`
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #2A2E45;
    margin-right: 8px
`;
