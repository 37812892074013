import React, { useState, createContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  setClientInfo,
  checkNumberVerificationStatus,
  analyticsIdentifyUser,
} from 'actions/shoppingActions';
import VerifyPhoneNumber from 'app/shared/views/verifyPhoneNumber';
import OrderReview from './components/OrderReview';

const StepperContext = createContext();

const StepWrapper = styled.div`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  transition: opacity 250ms ease-in;
  position: relative;
  flex-direction: column;
  background-color: #ffffff;
  max-width: 502px;
  margin: auto;
  width: 100%;
  height: 100%;
`;

const Stepper = ({
  initialStep,
  steps,
  onFinish,
  children,
  internalOrder,
  stripe,
  pageInfo,
  iframeConfig,
  pokUrl,
}) => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(initialStep || 0);

  const [showOrderReview, setShowOrderReview] = useState(false);
  const [showPokModal, setShowPokModal] = useState(false);
  const [showVerifyNumber, setShowVerifyNumber] = useState(false);

  const clientInfo = useSelector((state) => state.shopping.client);
  const order = useSelector((state) => state.shopping.order);
  const isCurrentStep = (step) => {
    return steps[currentStep] === step;
  };

  const nextStep = async () => {
    if (currentStep > steps.length) return;
    if (currentStep + 1 === steps.length) {
      if (!showOrderReview) {
        setShowOrderReview(true);
        return;
      }
      if (!internalOrder) {
        analyticsIdentifyUser({ clientPhone: clientInfo.phone, pageInfo });
        const numberStat = await dispatch(checkNumberVerificationStatus(clientInfo.phone));
        if (numberStat === 2) {
          setShowVerifyNumber(true);
          return;
        }
      }
      setShowOrderReview(false);
      onFinish && onFinish();
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep <= 0) return;
    setCurrentStep(currentStep - 1);
  };

  const reset = () => setCurrentStep(0);

  return (
    <StepperContext.Provider
      value={{
        currentStep,
        isCurrentStep,
        nextStep,
        prevStep,
        reset,
      }}
    >
      {showVerifyNumber && (
        <VerifyPhoneNumber
          show={showVerifyNumber}
          toggle={() => setShowVerifyNumber(false)}
          setClientPhone={(phone) => {
            dispatch(
              setClientInfo({
                ...clientInfo,
                phone: phone,
              }),
            );
          }}
          onConfirm={nextStep}
        />
      )}
      {showOrderReview && (
        <OrderReview
          initialStep={initialStep}
          setCurrentStep={setCurrentStep}
          show={showOrderReview}
          setShow={setShowOrderReview}
          order={order}
          currency={pageInfo.currency}
          stripe={stripe}
          pageInfo={pageInfo}
          collectClientName={iframeConfig?.collectClientName}
          internalOrder={internalOrder}
          onProceed={nextStep}
          showPokModal={showPokModal}
          setShowPokModal={setShowPokModal}
          pokUrl={pokUrl}
        />
      )}

      {!showOrderReview && children}
    </StepperContext.Provider>
  );
};

const Step = ({ stepKey, render, ...rest }) => (
  <StepperContext.Consumer>
    {({ isCurrentStep, prevStep, nextStep, reset }) => {
      const isActive = isCurrentStep(stepKey);
      if (!isActive) {
        return null;
      }
      return (
        <StepWrapper show={isActive}>
          {render({
            ...rest,
            isActive,
            prevStep,
            nextStep,
            reset,
          })}
        </StepWrapper>
      );
    }}
  </StepperContext.Consumer>
);

Stepper.Step = Step;
export default Stepper;
