import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import noRecordsImage from 'assets/images/no_records.svg';

import colors from '../helpers/colors';
import TableValidator from '../validators/table.validator';

import SortIcon from 'assets/icons/sort_arrows.svg';
import CheckBox from 'app/shared/views/checkbox';

const TableWrapper = styled.div`
  padding: 0 25px;
`;

const Table = styled.table`
  width: 100%;
`;

const Td = styled.td`
  line-height: 58px;
  /* padding-left: 35px; */
  /* padding-right: 35px; */
  font-size: 12px;
  color: ${colors.black};
  @media all and (max-width: 768px) {
    line-height: 50px;
    /* padding-left: 25px; */
    /* padding-right: 25px; */
  }
`;

const Th = styled.th`
  line-height: 67px;
  text-align: left;
  /* padding-left: 35px; */
  /* padding-right: 35px; */
  color: ${colors.textColor};
  font-weight: 600;
  font-size: 12px;

  @media all and (max-width: 768px) {
    line-height: 50px;
    /* padding-left: 25px; */
    /* padding-right: 25px; */
  }
`;

const EmptyImage = styled.img`
  display: block;
  margin: 50px auto;
`;

const Tr = styled.tr`
  border-bottom: 1px solid ${colors.lightGrey};

  :last-child {
    border: none;
  }
`;

/**
 * TODO: Find a way to fix the Th on thead hack (borderBottom)
 * @param header
 * @param values
 * @param action
 * @returns {*}
 * @constructor
 */

const TableView = ({ data: { header, values = [], action = () => {} } }) => (
  <TableWrapper>
    <Table>
      <thead>
        <tr>
          {header.map((element) => (
            <Th
              style={{
                borderBottom: `1px solid ${colors.lightGrey}`,
                cursor: element.sortable && 'pointer',
              }}
              key={element.label}
              onClick={() => action(element.key)}
            >
              {element.label}
              {element.sortable && (
                <img style={{ marginLeft: '14px' }} src={SortIcon} alt="sort icon" />
              )}
            </Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {values.map((element, elementIndex) =>
            <Tr key={`${element.id}-${elementIndex}`}>
              {header.map((elm, index) => (
                <Td
                  key={`${elm.key}-${element.id}-${index}`}
                  style={elm.width ? { width: elm.width } : {}}
                >
                  {elm.renderer === undefined ? element[elm.key] : elm.renderer(element)}
                </Td>
              ))}
            </Tr>
        )}
      </tbody>
    </Table>
    {_.isEmpty(values) && <EmptyImage src={noRecordsImage} alt={'No records'} />}
  </TableWrapper>
);

TableView.propTypes = TableValidator.TableSchema;

export const SelectTableView = (props) => {
  const {
    selected,
    setSelected,
    data: { header },
  } = props;

  const someSelected = _.some(selected);
  const allSelected = !_.isEmpty(selected) && _(selected).every();
  const allHead = {
    key: 'all',
    label: (
      <CheckBox
        checked={allSelected}
        indeterminate={someSelected && !allSelected}
        click={() => setSelected(allSelected ? [] : _.keys(selected))}
        text={'ALL'}
      />
    ),
    renderer: (el) => {
      return (
        <div>
          <CheckBox
            checked={selected[el.id]}
            click={() => setSelected({ [el.id]: !selected[el.id] })}
          />
        </div>
      );
    },
    sortable: false,
    width: '80px',
  };
  const newData = {
    ...props.data,
    header: [allHead, ...header],
  };
  return <TableView {...props} data={newData} />;
};

export default { TableView, SelectTableView };
