import React from 'react';
import styled from 'styled-components';

import createEmptyArray from '../../helpers/createEmptyArray';

import { CartesianContext } from './lineChart';

const Axis = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
`;

const YAxis = styled(Axis)`
  flex-direction: column;
`;

const AxisElement = styled.div`
  height: 100%;
  width: 1px;
  background-color: #ebedf4;
`;

const YAxisElement = styled(AxisElement)`
  width: 100%;
  height: 1px;
`;

const CartesianGridWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

class CartesianGrid extends React.Component {
  cartesianGrid = (xAxis, yAxis) => {
    const yAxisArray = createEmptyArray(yAxis);

    return (
      <CartesianGridWrapper>
        <YAxis>
          {yAxisArray.map((value, index) => (
            <YAxisElement key={`${value}-${index}`} />
          ))}
        </YAxis>
      </CartesianGridWrapper>
    );
  };

  render() {
    return (
      <CartesianContext.Consumer>
        {({ xAxis, yAxis }) => {
          return this.cartesianGrid(xAxis, yAxis);
        }}
      </CartesianContext.Consumer>
    );
  }
}

CartesianGrid.contextType = CartesianContext;

export default CartesianGrid;
