import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import currencyFormatter from 'app/shared/helpers/currencyFormatter';
import Card from 'app/shared/views/card';


import { AddButton, RemoveButton, GreenButton } from 'app/shared/views/buttons';

import { addProductToOrder, removeProductFromOrder } from 'actions/shoppingActions';

const ProductCard = (props) => {
  const { product, qty, currency, addProductToOrder, removeProductFromOrder } = props;
  return (
    <ProductCardWrapper>
      <StyledCard onClick={() => props.setAddProduct(product)}>
        <StyledProductCard>
          <ProductImage src={product.imagePath} />
          <ProductInfo>
            <ProductName>
              <Ellipsis>{product.name}</Ellipsis>
            </ProductName>
            <ProductPrice label="Price">{currencyFormatter(product.price, currency)}</ProductPrice>
          </ProductInfo>
        </StyledProductCard>
      </StyledCard>

      <ProductActions>
        {product.isCombo ? (
          <GreenButton
            style={{ flex: 1, borderRadius: 15 }}
            text={'Combo'}
            onClick={() => props.setAddProduct(product)}
          />
        ) : (
          <>
            <RemoveButton onClick={() => removeProductFromOrder(product)} disabled={qty === 0} />
            <ProductOrderCount>{qty}</ProductOrderCount>
            <AddButton onClick={() => addProductToOrder({ ...product, toppings: [] })} />
          </>
        )}
      </ProductActions>
    </ProductCardWrapper>
  );
};

const ProductCardWrapper = styled.div`
  position: relative;
`;

const StyledProductCard = styled.div`
  width: 180px;
  height: 127px;
  position: absolute;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: -webkit-transform ease 500ms;
  transition: transform ease 500ms;
  display: flex;
  flex-direction: column;
`;

const StyledCard = styled(Card)`
  width: 180px;
  height: 127px;
  border-radius: 20px;
  border: none;
`;

const ProductImage = styled.div`
  flex: 8;
  border-radius: 20px 20px 0px 0px;
  background: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const ProductInfo = styled.div`
  flex: 4;
  background-color: #f9fafc;
  border-radius: 0px 0px 20px 20px;
  padding: 5px 10px;
`;

const ProductName = styled.span`
  color: #1d2036;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0;
`;
const Ellipsis = styled.div`
  display: block;
  display: -webkit-box;
  margin: 0 auto;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProductPrice = styled.span`
  width: 100%;
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  color: #2ECC71;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &:before {
    content: '${(props) => props.label || ''}';
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #8F92A7;
  }
`;

const ProductActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
`;

const ProductOrderCount = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1d2036;
  display: flex;
  align-items: center;
  text-align: center;
`;

export default connect(() => ({}), {
  addProductToOrder,
  removeProductFromOrder,
})(ProductCard);
