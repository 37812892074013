import AdminDashboardPage from './dashboard/views/dashboard.page';
import AdminClientsPage from './clients/views/clients.page';
import AdminViewClientPage from './clients/views/client.page';
import AdminHelpCenterPage from './helpCenter/views/helpCenter.page';
import AdminMessengerPage from './messenger/views/messenger.page';
import AdminDetailsPage from './details/views/details.page';
import AdminUsersPage from './users/views/users.page';

export default {
  views: {
    AdminDashboardPage,
    AdminClientsPage,
    AdminViewClientPage,
    AdminHelpCenterPage,
    AdminMessengerPage,
    AdminDetailsPage,
    AdminUsersPage
  },
};
