import React from 'react';
import shared from 'app/shared';

const {
  views: {
    Shopping: { ChooseProducts, LocationDetails, Stepper },
  },
} = shared;

const OnlineShopping = ({
  pageInfo,
  setIsLoading,
  categories,
  extraCategories,
  search,
  setSearch,
  iframeConfig,
  scrollableArea,
  order,
  initialStep,
  steps,
  stripe,
  onPlaceOrder,
  preselectedProduct,
}) => {
  return (
    <Stepper
      steps={Object.values(steps)}
      initialStep={initialStep}
      onFinish={() => onPlaceOrder(setIsLoading)}
      stripe={stripe}
      pageInfo={pageInfo}
      iframeConfig={iframeConfig}
    >
      <Stepper.Step
        stepKey={steps.LOCATION_DETAILS}
        render={({ prevStep, nextStep }) => (
          <LocationDetails
            proceedText={'Proceed'}
            onProceed={nextStep}
            pageInfo={pageInfo}
            geoRegions={[]}
            setGeoRegions={() => {}}
            iframeConfig={iframeConfig}
            absolutePosition={true}
            heightToRemove={70}
          />
        )}
      />
      <Stepper.Step
        stepKey={steps.PRODUCTS}
        render={({ prevStep, nextStep }) => (
          <ChooseProducts
            id={'ChooseProducts'}
            proceedText={'Place Order'}
            order={order}
            pageInfo={pageInfo}
            categories={categories}
            extraCategories={extraCategories}
            onProceed={nextStep}
            stripe={stripe}
            search={search}
            setSearch={setSearch}
            hideBanner={false}
            hideStatus={
              !pageInfo.pageService.find((x) => x.name === 'OnlineOrder' && x.isActive === true)
            }
            disableOnlineOrder={
              iframeConfig.disableOnlineOrder ||
              !pageInfo.pageService.find((x) => x.name === 'OnlineOrder' && x.isActive === true)
            }
            iframeConfig={iframeConfig}
            scrollableArea={scrollableArea}
            onBack={prevStep}
            preselectedProduct={preselectedProduct}
          />
        )}
      />
    </Stepper>
  );
};

export default OnlineShopping;
