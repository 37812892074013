import React, { useRef } from 'react';
import { Input } from 'antd';
import icons from 'app/shared/views/icons';

const inputStyles = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: '16px',
  display: 'flex',
  alignItems: 'center',
  color: '#748AA1',
  padding: '11px 20px',
  width: '100%',
  background: '#FFFFFF',
  border: 'none',
  borderRadius: '15px',
  backgroundColor: '#F9FAFC',
};

const ProductSearchInput = ({ style, ...props }) => {
  const myRef = useRef(null);
  const icon = <icons.Search strokeWidth={2} fill={'#748AA1'} width={'19px'} height={'19px'} />;
  return (
    <div ref={myRef}>
      <Input
        type="text"
        height={35}
        end={icon}
        placeholder={'Search item...'}
        onFocus={() => myRef.current.scrollIntoView({ behavior: 'smooth' })}
        style={{
          ...inputStyles,
          ...style,
        }}
        {...props}
      />
    </div>
  );
};

export default ProductSearchInput;
