import styled from 'styled-components';
import shared from 'app/shared';

const{
  views:{ Text:{ H1:{ MainTitle, }, }, },
} = shared;

export const hrStyle = {
  margin: '6% auto',
  width: '80%'
};

export const MainLinkWrapper = styled(MainTitle)`
  font-size: 100%;
  margin-top: 2.5%;
  
  :active{
    opacity: 0.7;
  }
`;
