import React, { Component } from 'react';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import mediaQueries from '../helpers/mediaQueries';

const { queries } = mediaQueries;

const gutter = 30;

const GridContext = React.createContext(null);

const GridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  transform: translate(-${gutter}px, -${gutter}px);
  width: calc(100% + ${gutter}px);
`;

class Grid extends Component {
  gridRef = null;

  state = {
    width: 0,
  };

  debouncedFunction = null;

  componentDidMount() {
    this.debouncedFunction = debounce(this.updateWidth, 12);
    window.addEventListener('resize', this.debouncedFunction);

    /**
     * TODO: Refactor this hack
     */
    this.updateWidth();

    setTimeout(() => {
      this.updateWidth();
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedFunction);
  }

  updateWidth = () => {
    const { gridRef, state } = this;
    if (gridRef !== null && state !== undefined && state.width !== gridRef.clientWidth) {
      this.setState({
        width: gridRef.clientWidth,
      });
    }
  };

  render() {
    const {
      state: { width },
      props: { children },
    } = this;
    return (
      <GridWrapper
        ref={ref => {
          this.gridRef = ref;
          this.updateWidth();
        }}
      >
        <GridContext.Provider value={width}>{children}</GridContext.Provider>
      </GridWrapper>
    );
  }
}

const GridSectionWrapper = styled.div`
  margin-left: ${gutter}px;
  box-sizing: border-box;
  margin-top: ${gutter}px;

  ${queries.phone} {
    width: ${props =>
      props.singleSectionWidth * props.mobile + props.gutter * (props.mobile - 1)}px;
  }

  ${queries.tablet} {
    width: ${props =>
      props.singleSectionWidth * props.tablet + props.gutter * (props.tablet - 1)}px;
  }

  ${queries.desktop} {
    width: ${props =>
      props.singleSectionWidth * props.desktop + props.gutter * (props.desktop - 1)}px;
  }
`;

Grid.Section = ({ children, sizes = { desktop: 12, tablet: 12, mobile: 12 } }) => {
  const desktop = sizes.desktop || 12;
  const tablet = sizes.tablet || 12;
  const mobile = sizes.mobile || 12;

  return (
    <GridContext.Consumer>
      {value => {
        const singleSectionWidth = (value - 12 * gutter) / 12;
        // const sectionWidth = (singleSectionWidth * desktop) + (gutter * desktop)

        return (
          <GridSectionWrapper
            mobile={mobile}
            tablet={tablet}
            desktop={desktop}
            singleSectionWidth={singleSectionWidth}
            gutter={gutter}
          >
            <div />
            {children}
          </GridSectionWrapper>
        );
      }}
    </GridContext.Consumer>
  );
};

export default Grid;
