import React, { useEffect } from 'react';
import styled from 'styled-components';
import Card from '../../card';
import Icons from '../../icons';
import isEscape from '../../../helpers/isEscape';
import ToggleValidator from '../../../validators/toggle.validator';
import hexToRGBA from '../../../helpers/hexToRGBA';
import slideFromTopAnimation from '../../../helpers/slideFromTopAnimation';
import transition from '../../../helpers/transition';

const OverlayWrapper = styled.div`
  background: ${hexToRGBA('#161929', 0.9)};
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  transition: ${transition};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.show ? 1 : 0)};
  top: 0;
  left: 0;
  z-index: 9999;
  -webkit-transform: translate3d(0, 0, 0);
  padding: 0 !important;
`;
const CustomCard = styled(Card)`
  border-radius: 4px;
  box-shadow: 0px 16px 28px rgba(63, 86, 104, 0.1);
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 450px;
  border: 0;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
`;

const ContentWrapper = styled.div`
  transition: 0.4s ease-in-out;
  position: relative;
  opacity: ${(props) => (props.show ? 1 : 0)};
  animation: ${(props) => (props.show ? slideFromTopAnimation : '')} 0.4s ease-in-out;
  width: 100%;
  max-width: 500px;
  z-index: 100;
`;

const Title = styled.div`
  width: 100%;
  margin-left: 10px;
  margin-bottom: 30px;
  margin-right: 20px;
`;

const IconDiv = styled.div`
  width: 100%;
  margin: 10px;
`;

const PaymentMethods = (props) => {
  const {
    show,
    toggle,
    selectPok,
    selectCash,
    internalOrder,
    pokData,
    isValid,
    setError,
    confirmPaymentMethodCash,
    confirmPaymentMethodPok,
  } = props;

  useEffect(() => {
    document.body.style.overflowY = show ? 'hidden' : 'unset';
    document.body.style.position = show ? 'fixed' : 'unset';
  }, [show]);

  useEffect(() => {
    document.addEventListener('keyup', toggleOnEscape);
    return function cleanup() {
      document.removeEventListener('keyup', toggleOnEscape);
    };
  });

  const toggleOnEscape = (event) => {
    if (event.defaultPrevented) return;
    if (isEscape(event) && show) toggle();
  };
  return (
    <OverlayWrapper show={show} onClick={toggle}>
      <ContentWrapper show={show} onClick={(event) => event.stopPropagation()}>
        <CustomCard style={{ maxHeight: '90vh', overflowY: 'scroll', borderRadius: '7px 7px 0 0' }}>
          <IconDiv>
            <div style={{ marginTop: '5px', marginLeft: '88%' }}>
              <Icons.Check />
            </div>
          </IconDiv>
          <Title>
            <h1 style={{ fontSize: '25px', fontWeight: 900 }}>Choose payment method</h1>
            <h3 style={{ marginTop: '23px', marginLeft: '5px' }}>Select your payment method</h3>
            <p style={{ fontSize: '12px', marginTop: '10px', marginLeft: '5px' }}>
              You can choose between paying with POK or pay on delivery by pressing the button
              below.
            </p>
          </Title>
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '1.5rem',
            }}
          >
            {(pokData !== null && internalOrder === false) && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                  backgroundColor: '#F9FAFC',
                  width: '92%',
                  borderRadius: '7px',
                  padding: '1.5rem 1rem',
                }}
                onClick={async () => {
                  if (!isValid) {
                    setError();
                  } else {
                    selectPok();
                    confirmPaymentMethodPok();
                  }
                  toggle();
                }}
              >
                <div style={{ display: 'flex', gap: '0.5rem' }}>
                  <div>
                    <Icons.PokIcon />
                  </div>
                  <div>
                    <h2>POK</h2>
                    <p>Online payment</p>
                  </div>
                </div>
                <div>
                  <Icons.Vector />
                </div>
              </div>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
                backgroundColor: '#F9FAFC',
                width: '92%',
                borderRadius: '7px',
                padding: '1.5rem 1rem',
              }}
              onClick={() => {
                selectCash();
                confirmPaymentMethodCash();
                toggle();
              }}
            >
              <div style={{ display: 'flex', gap: '0.5rem' }}>
                <div>
                  <Icons.CashIcon />
                </div>
                <div>
                  <h2>Cash</h2>
                  <p>Pay on delivery</p>
                </div>
              </div>
              <div>
                <Icons.Vector />
              </div>
            </div>
          </div>
        </CustomCard>
      </ContentWrapper>
    </OverlayWrapper>
  );
};

PaymentMethods.propTypes = {
  ...ToggleValidator.ToggleSchema,
};

export default PaymentMethods;
