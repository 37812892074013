import Url from '../config/api';
import { updateActivePage } from './facebookAction';
import { buildAxiosInstance } from 'actions/common';

const { baseUrl } = Url;

const baseURI = `${baseUrl}/Table`;

const axiosClient = buildAxiosInstance(baseUrl);

export const getTableByCode = (tableCode, setStatus = null) => {
  return (dispatch, getState) => {
    axiosClient
      .get(`${baseURI}/{tableCode}`)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          // ...
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
      });
  };
};

export const addTables = (tableNo, tableCategoryId, setStatus = null) => {
  return (dispatch, getState) => {
    const pageId = getState().facebookPages.activePage.id;
    axiosClient
      .post(
        `${baseURI}/add-tables`,
        {
          tableNo,
          tableCategoryId,
        }, {
          headers: {
            page: JSON.parse(localStorage.getItem('Page')),
          },
        },
      )
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          dispatch(updateActivePage(pageId, setStatus));
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
      });
  };
};

export const removeTables = (tableNo, tableCategoryId, setStatus = null) => {
  return (dispatch, getState) => {
    const pageId = getState().facebookPages.activePage.id;
    axiosClient
      .post(`${baseURI}/remove-tables`, {
        tableNo,
        tableCategoryId,
      })
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          dispatch(updateActivePage(pageId, setStatus));
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
      });
  };
};
