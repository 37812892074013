import OrdersPage from './views/orders.page';
import EditOrder from './views/editOrder';
import AddOrder from './views/addOrder';

export default {
  views: {
    OrdersPage,
    EditOrder,
    AddOrder,
  },
};
