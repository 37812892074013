import React from 'react';
import styled from 'styled-components';
import FilterBar from './filterBar';
import DatePicker from './dateRangeFilter';

const FilterByPeriodWrapper = styled.div`
  margin-right: 30px;
  display: inline;
`;

const SimpleFilterBar = ({
  selectedPeriod,
  updateSelectedPeriod = null,
  clearFilters,
  updateCustomDate,
  customDate,
}) => {
  return (
    <FilterBar>
      <FilterBar.Start>
        <FilterBar.Components.RadioGroup
          selectedElement={selectedPeriod}
          updateSelectedElement={updateSelectedPeriod}
        >
          <FilterByPeriodWrapper>
            <FilterBar.Components.Title>Filter by Period</FilterBar.Components.Title>
          </FilterByPeriodWrapper>
          <FilterBar.Components.RadioElement value={'today'}>
            Today
          </FilterBar.Components.RadioElement>
          <FilterBar.Components.RadioElement value={'yesterday'}>
            Yesterday
          </FilterBar.Components.RadioElement>
          <FilterBar.Components.RadioElement value={'last-week'}>
            Last week
          </FilterBar.Components.RadioElement>
          <FilterBar.Components.RadioElement value={'last-month'}>
            Last Month
          </FilterBar.Components.RadioElement>
          <FilterBar.Components.RadioElement value={'total'}>
            Total
          </FilterBar.Components.RadioElement>
        </FilterBar.Components.RadioGroup>
      </FilterBar.Start>
      <FilterBar.End>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <DatePicker
            selectedElement={selectedPeriod}
            updateSelectedElement={updateSelectedPeriod}
            updateCustomDate={updateCustomDate}
            customDate={customDate}
            clearFilters={clearFilters}
          />
          <div style={{ marginLeft: 20 }}>
            <FilterBar.Components.ClearFilters onClick={clearFilters} />
          </div>
        </div>
      </FilterBar.End>
    </FilterBar>
  );
};

export default SimpleFilterBar;
