export const isMarketplaceDomain = () => {
  const forceMarketplaceRoutes = process.env.REACT_APP_FORCE_MARKETPLACE_ROUTES === 'true';
  return forceMarketplaceRoutes || window.location.hostname.toLowerCase().endsWith('orderoo.al');
};

export const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const socialURL = (url) => {
  if (isValidHttpUrl(url)) {
    // if valid return unmodified
    return url;
  } else {
    // if not valid, prepend https
    return `https://${url}`;
  }
};
