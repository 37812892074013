import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import shared from 'app/shared';
import Select from 'react-select';
import SettingsTitle from '../components/SettingsTitle';
import { updatePageCurrency, updateUserProfile, updatePageBanner } from 'actions/pageActions';
import { updateActivePage } from 'actions/facebookAction';
import CheckBox from 'app/shared/views/checkbox';
import { scrollToAlert } from 'app/shared/views/alert';

import { useIsMount } from '../hooks';

const {
  views: { Alert, UploadInput, Input },
  helpers: { colors },
} = shared;

const InlineFlexWrapper = styled.div`
  display: inline-flex;
  margin-top: 3%;
  width: 60%;
`;

const MainLabel = styled.div`
  display: flex;
  width: 35%;
  height: 100%;
  padding-right: 30px;
  font-size: 15px;
  color: ${colors.textColor};
`;

const InLineLabel = styled.span`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  color: #748aa1;
`;

const CurrencySelect = styled(Select)`
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;

  & .currency__control {
    border-color: #e1e6ec;
  }

  & .currency__single-value {
    color: #434343;
    font-weight: normal;
  }
`;

const currencyOptions = [
  { value: 'ALL', label: 'ALL - Albanian LEK' },
  { value: 'USD', label: 'USD - US Dollar' },
  { value: 'EUR', label: 'EUR - European Euro' },
];

const ProfileSettings = ({
  activePage,
  updatePageCurrency,
  updateActivePage,
  updateUserProfile,
  updatePageBanner,
  setExecuteOnSubmit,
  children,
  setHasChanges,
}) => {
  const [currency, setCurrency] = useState(
    currencyOptions.find((o) => o.value === activePage?.currency) ?? currencyOptions[0].value,
  );
  const [profileDetails, setProfileDetails] = useState(() => {
    if (!activePage) return {};
    return {
      businessName: activePage.pageName || '',
      userEmail: activePage.userEmail || '',
      userPhoneNumber: activePage.userPhoneNumber || '',
      automaticAcceptOnlineOrder: activePage.automaticAcceptOnlineOrder ?? true,
      automaticAcceptTableOrder: activePage.automaticAcceptTableOrder ?? true,
      autoPrintReceipt: activePage.autoPrintReceipt,
    };
  });
  const [alert, setAlert] = useState({ show: false, message: '' });
  const alertRef = useRef(null);

  const hasOnlineOrder =
    activePage &&
    !!activePage.pageService.find((x) => x.name === 'OnlineOrder' && x.isActive === true);
  const hasTableOrder =
    activePage &&
    !!activePage.pageService.find((x) => x.name === 'TableOrder' && x.isActive === true);

  const isMount = useIsMount();

  useEffect(() => {
    let alertTimer = setTimeout(() => setAlert({ show: false, message: '' }), 5000);
    return () => {
      clearTimeout(alertTimer);
    };
  }, [alert]);

  // useEffect(() => {
  //   if (activePage) {
  //     setProfileDetails({
  //       businessName: activePage.pageName || '',
  //       userEmail: activePage.userEmail || '',
  //       userPhoneNumber: activePage.userPhoneNumber || '',
  //     });
  //   }
  // }, [activePage]);

  useEffect(() => {
    if (!isMount) {
      setHasChanges(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setHasChanges, currency]);

  useEffect(() => {
    setExecuteOnSubmit(() => () => {
      if (activePage) {
        updatePageCurrency(activePage.id, currency.value, (status) => {
          if (status === 'OK') {
            updateUserProfile(
              activePage.id,
              {
                businessName: profileDetails.businessName,
                email: profileDetails.userEmail,
                phoneNumber: profileDetails.userPhoneNumber,
                automaticAcceptOnlineOrder: profileDetails.automaticAcceptOnlineOrder,
                automaticAcceptTableOrder: profileDetails.automaticAcceptTableOrder,
                autoPrintReceipt: profileDetails.autoPrintReceipt,
              },
              (status) => {
                if (status === 'OK') {
                  updateActivePage(activePage.id);
                }

                setAlert({
                  show: true,
                  message:
                    status === 'OK'
                      ? 'You have successfully changed your profile settings!'
                      : 'Could not change your profile settings!',
                  type: status === 'OK' ? 'success' : 'error',
                });
                scrollToAlert(alertRef);
                setHasChanges(false);
              },
            );
          } else {
            setAlert({
              show: true,
              message: 'Could not change your profile settings!',
              type: 'error',
            });
            scrollToAlert(alertRef);
          }
        });
      }
    });
  }, [
    activePage,
    currency,
    profileDetails,
    setExecuteOnSubmit,
    updatePageCurrency,
    updateUserProfile,
    updateActivePage,
    setHasChanges,
  ]);

  const handleChange = (field) => (e) => {
    setProfileDetails({
      ...profileDetails,
      [field]: e.target.value,
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '0.7% 30px',
        paddingBottom: '30px',
      }}
    >
      <SettingsTitle title="Profile Settings" />
      <Alert ref={alertRef} {...alert} />

      <InlineFlexWrapper>
        <MainLabel>Add your business preferences</MainLabel>

        <div style={{ width: '65%', marginTop: '-2px' }}>
          <div style={{ width: '100%', marginBottom: '10px' }}>
            <InLineLabel>Business Name</InLineLabel>
            <Input
              height={38}
              value={profileDetails.businessName}
              onChange={handleChange('businessName')}
              readOnly
            />
          </div>
          <div style={{ width: '100%', marginBottom: '10px' }}>
            <InLineLabel>Email Address</InLineLabel>
            <Input
              height={38}
              value={profileDetails.userEmail}
              onChange={handleChange('userEmail')}
              readOnly
            />
          </div>
          <div style={{ width: '100%', marginBottom: '10px' }}>
            <InLineLabel>Phone Number</InLineLabel>
            <Input
              height={38}
              value={profileDetails.userPhoneNumber}
              onChange={handleChange('userPhoneNumber')}
            />
          </div>
          <div style={{ width: '100%', marginBottom: '10px' }}>
            <InLineLabel>Currency</InLineLabel>
            <CurrencySelect
              classNamePrefix="currency"
              className="selectWrapper"
              labelText="Currency"
              value={currency}
              onChange={(val) => {
                setCurrency(val);
              }}
              isSearchable={true}
              options={currencyOptions}
            />
            {hasOnlineOrder && (
              <div style={{ width: '100%', marginBottom: '10px' }}>
                <InLineLabel>Online Orders</InLineLabel>
                <div>
                  <CheckBox
                    text={'Automatically accept online orders'}
                    checked={profileDetails.automaticAcceptOnlineOrder}
                    onChange={(e) =>
                      setProfileDetails({
                        ...profileDetails,
                        automaticAcceptOnlineOrder: e.target.checked,
                      })
                    }
                  />
                </div>
              </div>
            )}
            {hasTableOrder && (
              <div style={{ width: '100%', marginBottom: '10px' }}>
                <InLineLabel>Table Orders</InLineLabel>
                <div>
                  <CheckBox
                    text={'Automatically accept table orders'}
                    checked={profileDetails.automaticAcceptTableOrder}
                    onChange={(e) => {
                      setProfileDetails({
                        ...profileDetails,
                        automaticAcceptTableOrder: e.target.checked,
                      });
                    }}
                  />
                </div>
              </div>
            )}
            <div style={{ width: '100%', marginBottom: '10px' }}>
              <InLineLabel>Receipts</InLineLabel>
              <div>
                <CheckBox
                  text={'Automatically print receipts for new orders'}
                  checked={profileDetails.autoPrintReceipt}
                  onChange={(e) => {
                    setProfileDetails({
                      ...profileDetails,
                      autoPrintReceipt: e.target.checked,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </InlineFlexWrapper>

      <InlineFlexWrapper style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
        {/* <SettingsTitle label="Upload Header Image" style={{ display: 'flex', width: '100%' }} /> */}
        <MainLabel>Upload Header Image</MainLabel>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <span
            style={{
              width: '35%',
              fontSize: '12px',
              lineHeight: '22px',
              color: '#748AA1',
              paddingRight: '30px',
            }}
          >
            Image format (jpeg, png). File size (max. 2mb). Prefered Aspect Ratio (16:9)
          </span>
          <UploadInput
            height={110}
            imagePath={activePage && activePage.bannerURL}
            uriGenerator={(uri) => {
              updatePageBanner(uri);
            }}
            style={{
              width: '200px',
              height: '105px',
              cursor: 'pointer',
            }}
          />
        </div>
      </InlineFlexWrapper>

      <InlineFlexWrapper>
        <MainLabel />
        {children}
      </InlineFlexWrapper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activePage: state.facebookPages.activePage,
  userDetails: state.userDetails,
});

export default connect(mapStateToProps, {
  updatePageCurrency,
  updateUserProfile,
  updateActivePage,
  updatePageBanner,
})(ProfileSettings);
