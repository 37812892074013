import React, { useState, useEffect } from 'react';
import shared from 'app/shared';
import { connect } from 'react-redux';
import crudTypes from '../../../config/crudTypes';
import { getAll } from 'actions/crudActions';
import { filterData } from 'config/helpers/searcher';

import styled from 'styled-components';
import ConversationsList from '../components/conversationList';
import SearchInput from 'app/shared/views/searchInput';
import Button from '../components/buttons';
import NewMessagePopup from 'app/marketing/views/newMessagePopup';
import MessageBodySection from 'app/marketing/components/messageBody';
import MessageInputSection from 'app/marketing/components/messageInput';
import { selectAllSms } from 'reducers/campaignsReducer';

const {
  views: {
    Card,
    FilterableTableStructure: { SearchWrapper, ActionBarWrapper },
  },
  helpers: { colors },
} = shared;

const MessageWindowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ViewWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const CardWrapper = styled(Card)`
  width: 100%;
  display: flex;
  height: 650px;
  margin-top: 30px;
`;

const CardHeader = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  height: 67px;
  border-bottom: 1px solid ${colors.lightGrey};
  width: 100%;
  color: ${colors.textColor};
`;

const MessageWindowHeader = styled(CardHeader)`
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CreditUsageWrapper = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
`;

const CreditUsage = ({ smsCredits }) => (
  <CreditUsageWrapper>
    <span style={{ color: colors.blue }}>1</span> out of{' '}
    <span style={{ color: colors.blue }}>{smsCredits} Credits</span> will be used in this message.
  </CreditUsageWrapper>
);

const SmsInbox = ({ clients, getAll, allSmsMessages, smsCredits }) => {
  const [selectedClientId, setSelectedClientId] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [showNewMessagePopup, setShowNewMessagePopup] = useState(false);

  useEffect(() => {
    getAll(crudTypes.client);
  }, [getAll]);

  const filteredClients = filterData(clients || [], searchValue, [
    'smsConversations.smsMessages.message',
    'smsConversations.smsCampaignMessages.message',
  ]);
  const activeClient = filteredClients.find((c) => c.id === selectedClientId);
  const conversation = activeClient?.smsConversations?.[0];
  const messages = conversation
    ? allSmsMessages.filter((m) => m.smsConversationId === conversation.id)
    : [];

  return (
    <ViewWrapper>
      <ActionBarWrapper>
        <NewMessagePopup show={showNewMessagePopup} toggle={() => setShowNewMessagePopup(false)} />
        <Button text={'Create New Message'} onClick={() => setShowNewMessagePopup(true)} />
        <SearchWrapper>
          <SearchInput search={searchValue} onSearchChanged={setSearchValue} />
        </SearchWrapper>
      </ActionBarWrapper>
      <CardWrapper>
        <ConversationsList
          selectedClientId={selectedClientId}
          setSelectedClientId={setSelectedClientId}
          clients={filteredClients}
        />
        <MessageWindowWrapper>
          <MessageWindowHeader>
            <span style={{ color: colors.blue }}>Reply</span>
            <CreditUsage smsCredits={smsCredits} />
          </MessageWindowHeader>
          <MessageBodySection messages={messages} />
          <MessageInputSection clientId={selectedClientId} />
        </MessageWindowWrapper>
      </CardWrapper>
    </ViewWrapper>
  );
};

const mapStateToProps = (state) => ({
  clients: state.clients.data,
  allSmsMessages: selectAllSms(state),
  smsCredits: state.facebookPages.activePage?.smsCredits ?? 0,
});

export default connect(mapStateToProps, { getAll })(SmsInbox);
