import styled from 'styled-components';
import colors from 'app/shared/helpers/colors';
import React from 'react';
import logo from 'assets/icons/logo.svg';
import image from './image';
import text from './text';

const {
  Image: { ImageWrapper },
} = image;

const {
  H1: { MainTitle },
  H3: { MainSubtitle },
} = text;

const MainBox = styled.div`
  align-items: center;
  padding: 35px;
  border-radius: 0.3%;
  color: ${colors.textColor};
  background: ${colors.white};
  border: 1px solid #e9eaf1;
  box-sizing: border-box;

  margin-left: auto;
  margin-right: auto;

  width: 570px;
`;

const localStyle = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'table',
  top: '0',
  left: '0',
};

class BoxView extends React.Component {
  render() {
    const { logoPosition, logoPadding, boxAlign = 'middle', className } = this.props;

    return (
      <div style={localStyle}>
        <div style={{ display: 'table-cell', verticalAlign: boxAlign }}>
          <MainBox className={className}>
            <div style={{ position: 'relative' }}>
              <ImageWrapper align={logoPosition} padding={logoPadding}>
                <img alt={'logo'} src={logo} width={160} />
              </ImageWrapper>

              {this.props.title && (
                <MainTitle style={{ marginTop: '5%' }}>{this.props.title}</MainTitle>
              )}

              {this.props.subtitle && (
                <MainSubtitle style={{ marginTop: '4%' }}>{this.props.subtitle}</MainSubtitle>
              )}

              {this.props.children}
            </div>
          </MainBox>
        </div>
      </div>
    );
  }
}

export default BoxView;
