import React, { useState, useEffect } from 'react';
import { withRouter, Prompt } from 'react-router-dom';
import { Table } from 'antd';
import { Formik } from 'formik';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import styled from 'styled-components';
import shared from 'app/shared';
import Icons from '../../shared/views/icons';
import { edit, remove, getAll } from '../../../actions/crudActions';
import crudTypes from '../../../config/crudTypes';
import { CSVFileExporter } from 'app/shared/helpers/CSVFileExporter';
import CategoryCsvMapper from './categoryCsvMapper';
import AddProductPopup from '../../products/views/addProductPopup';
import currencyFormatter from 'app/shared/helpers/currencyFormatter';

const {
  views: {
    Button,
    Page,
    Input,
    AddItemPopup,
    Alert,
    Text: {
      Link: { Link },
    },
  },
  helpers: { colors },
} = shared;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;
const Flex1 = styled.div`
  flex: 1;
`;

const EditCategoryHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EditCategoryTitle = styled.div`
  font-size: 20px;
  color: ${colors.black};
  margin-left: 22px;
  @media all and (min-width: 768px) and (max-width: 1024px) {
    margin-left: 10px;
    margin-right: 10px;
  }
  @media all and (max-width: 768px) {
    display: none;
  }
`;

const EditCategoryDate = styled.div`
  font-size: 12px;
  color: ${colors.grey};
  margin: 10px;
  flex: 1;
  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }
  @media all and (max-width: 768px) {
    display: none;
  }
`;

const EditCategoryBody = styled.div`
  background: ${colors.white};
  padding: 0 30px;
  margin-top: 44px;
  display: flex;
  max-height: 700px;
  border: 1px solid #e9eaf1;
  box-sizing: border-box;
  border-radius: 3px;
  flex-wrap: wrap;
  @media all and (min-width: 768px) and (max-width: 1024px) {
    flex-direction: column;
  }
  @media all and (max-width: 768px) {
    flex-direction: column;
  }
`;

const EditCategoryDetail = styled.div`
  width: 30%;
  border-right: 1px solid #e9eaf1;

  padding: 25px 25px 25px 0;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
    border: none;
    padding-right: 0px;
  }
  @media all and (max-width: 768px) {
    width: 100%;
    border: none;
    padding-right: 0px;
  }
`;

const EditCategoryProducts = styled.div`
  width: 70%;
  padding: 25px 0 25px 55px;
  overflow-y: scroll;

  &::-webkit-scrollbar: {
    width: 3px;
    background-color: ${colors.blue};
    color: ${colors.blue};
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
    padding-left: 0px;
  }
  @media all and (max-width: 768px) {
    width: 100%;
    padding-left: 0px;
  }
`;
const EditCategoryLabel = styled.div`
  margin-bottom: 30px;
  font-size: ${(props) => `${props.fontSize}px`};
  line-height: 22px;
  color: ${colors.blue};

  :hover {
    cursor: ${(props) => `${props.cursor}`};
  }
`;

const ErrorWrapper = styled.div`
  min-height: 24px;
  padding: 5px;
  width: 100%;
  background: rgba(242, 69, 61, 0.2);
  color: #f2453d;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  align-items: center;
  letter-spacing: 1px;
  font-weight: bold;
  display: ${(props) => (props.show === 'ERROR' ? 'flex' : 'none')};
`;

const NotificationWrapper = styled.div`
  flex-basis: 100%;
  margin-top: 32px;
`;

const Label = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  flex: 1;
  align-items: left;
  color: #748aa1;
  margin-bottom: 10px;
  width: 100%;
`;

const ProductImage = styled.img`
  border-radius: 3px;
  height: 20px;
  width: 40px;
  flex: 1;
  margin-left: 28px;
  margin-right: 20px;
  @media all and (max-width: 768px) {
    display: none;
  }
`;
const ProductName = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #3f3d56;
  flex: 3;
`;
const ProductValue = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  color: #2ecc71;
  padding-right: 28px;
  text-align: right;
`;

const EditCategory = (props) => {
  const [updateStatus, setUpdateStatus] = useState('');
  const [deleteStatus, setDeleteStatus] = useState('');
  const [showAddProductPopup, setShowAddProductPopup] = useState(false);
  const toggleShowAddProductPopup = () => setShowAddProductPopup(!showAddProductPopup);
  const { getAll, products, categories } = props;
  const str = window.location.href;
  const id = parseInt(str.substring(str.lastIndexOf('/') + 1).trim());
  const filteredCategory = categories?.filter((x) => x.id === id) || [];
  const activeCategory = filteredCategory.length > 0 ? filteredCategory[0] : null;

  useEffect(() => {
    getAll(crudTypes.category);
  }, [products, getAll]);

  useEffect(() => {
    let deleteStatusTimer = setTimeout(() => setDeleteStatus(''), 5000);
    return () => {
      clearTimeout(deleteStatusTimer);
    };
  }, [deleteStatus]);

  useEffect(() => {
    let updateStatusTimer = setTimeout(() => setUpdateStatus(''), 5000);
    return () => {
      clearTimeout(updateStatusTimer);
    };
  }, [updateStatus]);

  const changeUpdateStatus = (status) => {
    setDeleteStatus('');
    setUpdateStatus(status);
  };

  const changeDeleteStatus = (status) => {
    setUpdateStatus('');
    setDeleteStatus(status);
  };

  const editCategory = (data) => {
    let category = {
      ...activeCategory,
    };
    category.name = data.name;
    delete category.magProducts;
    props.edit(category.id, crudTypes.category, category, changeUpdateStatus);
  };

  const deleteProd = (productID) => {
    props.remove(productID, crudTypes.product, changeDeleteStatus);
  };

  const exportCSV = () => {
    if (activeCategory) {
      CSVFileExporter(`category-${activeCategory.id}.csv`, CategoryCsvMapper([activeCategory]));
    }
  };

  return (
    activeCategory && (
      <Formik
        enableReinitialize
        initialValues={{
          name: (activeCategory && activeCategory.name) || '',
          magProducts: activeCategory == null ? [] : activeCategory.magProducts,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required('Category name is required')
            .test('len', 'Max 30 characters allowed', (val) => val?.length < 31),
        })}
        onSubmit={(values, { setSubmitting }) => {
          editCategory(values);
          setSubmitting(false);
        }}
      >
        {(innerProps) => {
          const { values, touched, errors, handleChange, handleSubmit } = innerProps;

          const columns = [
            {
              title: <Label style={{ marginLeft: 28 }}>Product:</Label>,
              dataIndex: 'product',
              key: 'product',
              width: '35%',
              shouldCellUpdate: true,
              render: (name, record) => (
                <Flex>
                  <Flex1>
                    <ProductImage alt="logo" src={record.imagePath} height="20" width="40" />
                  </Flex1>
                  <ProductName>{record.name}</ProductName>
                </Flex>
              ),
            },
            {
              title: (
                <Label style={{ textAlign: 'right', paddingRight: 40, width: '100%' }}>
                  Price:
                </Label>
              ),
              dataIndex: 'value',
              key: 'value',
              width: '35%',
              render: (value, record) => (
                <ProductValue>{currencyFormatter(record.price)}</ProductValue>
              ),
            },
            {
              title: '',
              dataIndex: 'id',
              key: 'deleteAction',
              width: '20%',
              render: (id, record) => (
                <Flex style={{ justifyContent: 'center' }}>
                  <Button.RemoveAllButton onClick={() => deleteProd(record.id)} />
                </Flex>
              ),
            },
          ];

          return (
            <div>
              <Prompt
                when={innerProps.dirty}
                message="You have unsaved changes. Are you sure you want to leave?"
              />
              <Page>
                <EditCategoryHeaderWrapper>
                  <Link onClick={props.history.goBack}>
                    <Icons.BackProducts fill={colors.blue} />
                  </Link>
                  <EditCategoryTitle>Edit Category</EditCategoryTitle>
                  <EditCategoryDate>
                    Date Created:
                    {`${
                      activeCategory && format(new Date(activeCategory.creationDate), 'MM-DD-YYYY')
                    }`}
                  </EditCategoryDate>
                  <Button.WhiteButton
                    color={colors.textColor}
                    text={'Export CSV'}
                    style={{ marginRight: 22 }}
                    onClick={exportCSV}
                  />
                  <Button.Button
                    color={colors.blue}
                    text={'Save'}
                    onClick={handleSubmit}
                    disabled={!innerProps.dirty}
                  />
                  {/* <Button.Button
                  color={colors.red}
                  text={'Delete'}
                  style={{ marginLeft: 22 }}
                  onClick={() => deleteProd()}
                /> */}
                </EditCategoryHeaderWrapper>
                <NotificationWrapper>
                  <Alert show={updateStatus === 'OK'} message="Update successful!" />
                  <Alert show={deleteStatus === 'OK'} message="Product deleted successfully!" />
                  <ErrorWrapper show={updateStatus}>
                    <Icons.XIcon fill={colors.red} style={{ marginRight: 10 }} />
                    <span>Error editing the category</span>
                  </ErrorWrapper>
                  <ErrorWrapper show={deleteStatus}>
                    <Icons.XIcon fill={colors.red} style={{ marginRight: 10 }} />
                    <span>Error deleting the category</span>
                  </ErrorWrapper>
                </NotificationWrapper>
                <EditCategoryBody>
                  <EditCategoryDetail>
                    <EditCategoryLabel fontSize={16}>{`${
                      activeCategory?.categoryType ? 'Extra' : 'Main'
                    } Category Details`}</EditCategoryLabel>
                    <AddItemPopup.InputRow>
                      <Input
                        style={{ paddingRight: 10 }}
                        id={'name'}
                        labelText={'Category Name'}
                        onChange={handleChange}
                        value={values.name}
                        autoComplete="off"
                      />
                      <ErrorWrapper
                        show={errors.name && touched.name ? 'ERROR' : ''}
                        style={{ marginTop: 10 }}
                      >
                        <Icons.XIcon fill={colors.red} style={{ marginRight: 10 }} />
                        <span>{errors.name}</span>
                      </ErrorWrapper>
                    </AddItemPopup.InputRow>
                  </EditCategoryDetail>
                  <EditCategoryProducts>
                    <EditCategoryLabel fontSize={16}>Products</EditCategoryLabel>
                    <AddProductPopup
                      show={showAddProductPopup}
                      toggle={toggleShowAddProductPopup}
                      preselectCategory={activeCategory.id}
                      categoryType={activeCategory.categoryType}
                    />
                    <Table
                      name={'magProducts1'}
                      size={'default'}
                      rowKey={(row, index) => row.id}
                      rowClassName={(record, index) =>
                        index % 2 === 0 ? 'table-row-dark' : 'table-row-light'
                      }
                      bordered={false}
                      columns={columns}
                      dataSource={values.magProducts}
                      loading={values.magProducts ? false : true}
                      pagination={false}
                    />
                    <Flex style={{ backgroundColor: '#FFF', height: 44, marginTop: 8 }}>
                      <Button.WhiteButton
                        text={
                          <Flex>
                            <Icons.Plus
                              fill={colors.blue}
                              style={{
                                width: 20,
                                height: 20,
                                backgroundColor: 'transparent',
                                borderRadius: 3,
                                marginLeft: 4,
                              }}
                            />
                            <span style={{ color: colors.blue, marginLeft: 20, marginRight: 4 }}>
                              Add Product
                            </span>
                          </Flex>
                        }
                        style={{ border: 'none', minWidth: 164, display: 'flex' }}
                        onClick={toggleShowAddProductPopup}
                      />
                    </Flex>
                  </EditCategoryProducts>
                </EditCategoryBody>
              </Page>
            </div>
          );
        }}
      </Formik>
    )
  );
};

const mapStateToProps = (state) => ({
  categories: state.categories.data,
  products: state.products.data,
  facebookPages: state.facebookPages,
});

EditCategory.path = '/dashboard/category/editcategory';

export default withRouter(connect(mapStateToProps, { edit, remove, getAll })(EditCategory));

export { EditCategory };
