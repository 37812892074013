import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import useQueryParams from 'hooks/useQueryParams';
import styled from 'styled-components';
import ShoppingLayout, { ShoppingContext } from './Shopping.layout';
import { ORDER_STATUS } from 'app/orders/constants';
import './assets/style/global.css';

import {
  getOrderById,
  saveOrder,
  updateOrderPaymentMethod,
  setPaymentMethod,
  startNewOrder,
} from 'actions/shoppingActions';

import shared from 'app/shared';
import NotSubscribed from 'app/shared/views/shopping/components/NotSubscribed';
import Url from 'config/api';

const {
  views: {
    Shopping: { ChooseProducts, LocationDetails, PaymentDetails, Stepper, Constants },
  },
} = shared;
const { PAYMENT_METHODS } = Constants;
const { OrderCancelled, AwaitingConfirmation, PaymentSucceeded, PaymentDeclined } = PaymentDetails;

const steps = {
  PRODUCTS: 'products',
  LOCATION_DETAILS: 'location',
};

const ConfirmationWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  z-index: 999998;
  min-height: 100vh;
  max-width: 502px;
  margin: auto;
  @media (min-width: 501px) {
    border: 1px #e8e9f3 solid;
  }
`;
const OnlineShoppingPage = ({ match }) => {
  const queryParams = useQueryParams();
  const preselectedProduct = queryParams.get('preselect');

  const dispatch = useDispatch();
  const order = useSelector((state) => state.shopping.order);
  const page = useSelector((state) => state.shopping.pageInfo);
  const geoRegion = useSelector((state) => state.shopping.geoRegion);


  const [pageInfo, setPageInfo] = useState(null);
  const [waitingConfirmation, setWaitingConfirmation] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [declinedError, setDeclinedError] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [initialStep, setInitialStep] = useState(0);
  const [paymentSessionId, setPaymentSessionId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [pokUrl, setPokUrl] = useState('');
  const stripe = useMemo(() => {
    if (pageInfo && pageInfo.publicPaymentKey) {
      return loadStripe(pageInfo.publicPaymentKey);
    }
    return null;
  }, [pageInfo]);

  useEffect(() => {
    if (page?.identifiableName) {
      window.open(`${Url.onlineOrderRedirectUrl}/restaurants/${page?.identifiableName}`, '_self');
    }
  }, [page]);

  const onInit = ({ pageInfo, lastOrder, setIsLoading }) => {
    setPageInfo(pageInfo);
    if (ORDER_STATUS[lastOrder.orderStatus] === 'Waiting for Confirmation') {
      setInitialStep(2);
      waitForConfirmation(setIsLoading, lastOrder.id);
    }
  };

  const waitForConfirmation = useCallback(
    (setIsLoading, orderId) => {
      setWaitingConfirmation(true);
      const timer = setInterval(() => {
        dispatch(
          getOrderById(orderId, (err, data) => {
            if (err) {
              return;
            }

            const status = ORDER_STATUS[data.orderStatus];
            if (status !== 'Pending' && page.automaticAcceptOnlineOrder === false) {
              if (status === 'Cancelled') {
                setIsCancelled(true);
                setIsLoading(false);
              } else {
                setSucceeded(true);
                setIsLoading(false);
              }
              setWaitingConfirmation(false);
              clearInterval(timer);
            }
          }),
        );
      }, 5000);
    },
    [dispatch, page],
  );

  const onPlaceOrder = useCallback(
    (setIsLoading) => {
      setIsLoading(true);
      dispatch(
        saveOrder(pageInfo.id, false, (err, order) => {
          //false because it is not internal order
          if (err) {
            setIsLoading(false);
          } else {
            if (order.paymentMethod === 3) {
              setPokUrl(order.confirmationUrl);
              console.log(order);
            }
            if (ORDER_STATUS[order.orderStatus] === 'Paid') {
              setDeclinedError(null);
              setPaymentSessionId(null);
              setSucceeded(true);
              setIsLoading(false);
            } else if (
              ORDER_STATUS[order.orderStatus] === 'Pending' &&
              page.automaticAcceptOnlineOrder === false
            ) {
              waitForConfirmation(setIsLoading, order.id);
            } else {
              setIsLoading(false);
            }
          }
        }),
      );
    },
    [dispatch, waitForConfirmation, page, pageInfo],
  );

  const onRetry = useCallback(() => {
    setInitialStep(1);
    setDeclinedError(null);
  }, []);

  const changeToCash = useCallback(() => {
    if (!paymentSessionId) return;
    setPaymentMethod(PAYMENT_METHODS.ON_DELIVERY);
    dispatch(
      updateOrderPaymentMethod(paymentSessionId, PAYMENT_METHODS.ON_DELIVERY, (err) => {
        if (err) {
          return;
        }
        setSucceeded(true);
        setDeclinedError(null);
      }),
    );
  }, [paymentSessionId, dispatch]);

  const reset = () => {
    dispatch(startNewOrder());
    setWaitingConfirmation(false);
    setIsCancelled(false);
    setDeclinedError(false);
    setSucceeded(false);
  };

  if (pageInfo && !pageInfo.isSubscribed) {
    return (
      <ShoppingLayout match={match}>
        <NotSubscribed />
      </ShoppingLayout>
    );
  }

  if (succeeded) {
    return (
      <ShoppingLayout match={match}>
        <ShoppingContext.Consumer>
          {({ pageInfo, setSearch }) => (
            <PaymentSucceeded
              order={order}
              currency={pageInfo.currency}
              geoRegion={geoRegion}
              reset={() => {
                setSearch();
                reset();
              }}
            />
          )}
        </ShoppingContext.Consumer>
      </ShoppingLayout>
    );
  }

  if (declinedError) {
    return (
      <ShoppingLayout match={match}>
        <ShoppingContext.Consumer>
          {({ pageInfo, setSearch }) => (
            <PaymentDeclined
              onRetry={onRetry}
              changeToCash={changeToCash}
              reset={() => {
                setSearch();
                reset();
              }}
            />
          )}
        </ShoppingContext.Consumer>
      </ShoppingLayout>
    );
  }

  if (isCancelled) {
    return (
      <ShoppingLayout match={match}>
        <ShoppingContext.Consumer>
          {({ pageInfo, setSearch }) => (
            <OrderCancelled
              order={order}
              currency={pageInfo.currency}
              geoRegion={geoRegion}
              reset={() => {
                setSearch();
                reset();
              }}
            />
          )}
        </ShoppingContext.Consumer>
      </ShoppingLayout>
    );
  }

  return (
    <ShoppingLayout match={match} onInit={onInit}>
      <ShoppingContext.Consumer>
        {({
            pageInfo,
            categories,
            search,
            setSearch,
            setIsLoading,
            extraCategories,
            iframeConfig,
            scrollableArea,
          }) => (
          <>
            {waitingConfirmation && (
              <ConfirmationWrapper>
                <AwaitingConfirmation
                  order={order}
                  currency={pageInfo.currency}
                  geoRegion={geoRegion}
                />
              </ConfirmationWrapper>
            )}
            <Stepper
              steps={
                iframeConfig.chooseProductsFirst || iframeConfig.disableOnlineOrder
                  ? Object.values(steps)
                  : Object.values(steps).reverse()
              }
              internalOrder={false}
              initialStep={initialStep}
              onFinish={() => onPlaceOrder(setIsLoading)}
              stripe={stripe}
              pageInfo={pageInfo}
              iframeConfig={iframeConfig}
              pokUrl={pokUrl}
            >
              <Stepper.Step
                stepKey={steps.PRODUCTS}
                render={({ nextStep, prevStep }) => (
                  <ChooseProducts
                    id={'ChooseProducts'}
                    proceedText={'Place Order'}
                    order={order}
                    pageInfo={pageInfo}
                    categories={categories}
                    extraCategories={extraCategories}
                    onProceed={nextStep}
                    onBack={
                      !(iframeConfig.chooseProductsFirst || iframeConfig.disableOnlineOrder) &&
                      prevStep
                    }
                    stripe={stripe}
                    search={search}
                    setSearch={setSearch}
                    disableOnlineOrder={
                      iframeConfig.disableOnlineOrder ||
                      !pageInfo.pageService.find(
                        (x) => x.name === 'OnlineOrder' && x.isActive === true,
                      )
                    }
                    iframeConfig={iframeConfig}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    scrollableArea={scrollableArea}
                    preselectedProduct={preselectedProduct}
                  />
                )}
              />
              <Stepper.Step
                stepKey={steps.LOCATION_DETAILS}
                render={({ prevStep, nextStep }) => (
                  <LocationDetails
                    proceedText={'Proceed'}
                    onProceed={nextStep}
                    onBack={
                      (iframeConfig.chooseProductsFirst || iframeConfig.disableOnlineOrder) &&
                      prevStep
                    }
                    pageInfo={pageInfo}
                    geoRegions={[]}
                    setGeoRegions={() => {
                    }}
                    iframeConfig={iframeConfig}
                  />
                )}
              />
            </Stepper>
          </>
        )}
      </ShoppingContext.Consumer>
    </ShoppingLayout>
  );
};

export default OnlineShoppingPage;
