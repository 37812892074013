const initialState = {
  data: [],
}

export default (state = initialState, action) => {
  const types = {
    'GET_TOP_NEW_CLIENTS_OK':
      { data: action.data },
    'GET_TOP_PAYING_CLIENTS_OK':
      { data: action.data },
    'GET_TOP_INACTIVE_CLIENTS_OK':
      { data: action.data },
  }

  return types[action.type] || state;
}
