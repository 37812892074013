import styled from 'styled-components';

const ImageWrapper = styled.div`
  margin: 0;
  padding: ${props => props.padding || 'auto 0'};
  width: 100%;
  height: ${props => props.height || 'auto'};
  display: block;
  text-align: ${props => props.align || 'center'};
`;

const TinyImage = styled.img`
  width: 20%;
`;

const SmallImage = styled.img`
  width: 40%;
`;

const MediumImage= styled.img`
  width: 60%;
`;

const LargeImage=styled.img`
  width: 80%;
`;

const FullImage=styled.img`
  width: 100%;
`;

export default{
  Image:{

    Images:{
      TinyImage,
      SmallImage,
      MediumImage,
      LargeImage,
      FullImage,
    },
    ImageWrapper,
  }
}
