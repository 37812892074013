import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import personImage from 'assets/images/person_image.png';
import Navigation from './navigation';
import TopBar from './topBar';
import MobileTopBar from './topBar.Mobile';
import Spinner from './spinner';
import mediaQueries from '../helpers/mediaQueries';
import { setActivePage, setLocalActivePages, getFacebookPages } from 'actions/facebookAction';
import { setLocalUser, getUserDetails } from 'actions/userActions';
import { getAll } from 'actions/crudActions';
import { getNotifications } from 'actions/notificationAction';
import { showUpgradeModal } from 'actions/subscriptionActions';
import UpgradeModal from '../../upgrade/views/modal';
import { setupConnection } from '../helpers/notificationHubHelper';
import OrderViewModal from 'app/shared/views/notificationModal/orderView';
import CallWaiterModal from 'app/shared/views/notificationModal/callWaiter';
import GetBillModal from 'app/shared/views/notificationModal/getBillModal';
import AutoPrinter from 'app/shared/views/printReceipt/shtypshkronja';

const AdminLayoutWrapper = styled.div`
  display: flex;
  position: relative;
`;

const TopBarWrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 30px;

  ${mediaQueries.mobile(`
    display: none;
  `)}
`;

const MobileTopBarWrapper = styled.div`
  margin-bottom: 10px;
  display: none;

  ${mediaQueries.mobile(`
    display: block;
  `)}
`;

const ContentWrapper = styled.div`
  flex-grow: 2;
  margin: 24px 45px 40px 270px;
  max-width: calc(100vw - 315px);

  @media only screen and (max-width: ${mediaQueries.sizes.phone}px) {
    margin: 10px 15px 40px;
    max-width: calc(100vw - 30px);
  }

  overflow: auto;
  height: calc(var(--vh, 1vh) * 100);
  padding-bottom: 52px;
`;

const AdminLayout = withRouter((props) => {
  const {
    isBartender = false,
    isAdmin = false,
    location,
    userDetails,
    activePageSubscription,
    notifications,
    pageAdminConfiguration,
  } = props;
  const [showNavigation, toggleNavigation] = useState(false);
  const { setupConnection } = props;
  const {
    setLocalUser,
    setActivePage,
    setLocalActivePages,
    getFacebookPages,
    getUserDetails,
    facebookPages,
  } = props;

  useEffect(() => {
    const isAdmin = localStorage.getItem('isAdmin');
    if (isAdmin !== null) {
      props.history.push('/admin');
    } else {
      getFacebookPages();
    }
  }, [getFacebookPages, props.history]);

  useEffect(() => {
    if (!facebookPages.activePage && facebookPages.data.length) {
      let currentPageId = localStorage.getItem('Page');
      if (currentPageId) {
        currentPageId = JSON.parse(currentPageId);
      } else {
        currentPageId = facebookPages.data[0].id;
      }

      if (currentPageId) {
        setActivePage(currentPageId);
        if (localStorage.getItem('User') !== null) {
          setLocalUser();
        } else {
          getUserDetails(() => {
            setLocalUser();
          });
        }
        setLocalActivePages();
      }
    }
  }, [
    setActivePage,
    setLocalUser,
    getUserDetails,
    setLocalActivePages,
    facebookPages.data,
    facebookPages.activePage,
  ]);

  // useEffect(() => {
  //   if (!facebookPages.activePage) {
  //     const storedPage = localStorage.getItem('Page');
  //     if (storedPage) {
  //       setLocalUser();
  //       setLocalActivePages();
  //       setActivePage(JSON.parse(storedPage));
  //     } else if (isAuthenticated()) {
  //       setRedirectTo('/facebook');
  //     }
  //   }
  // }, [setLocalUser, getFacebookPages, setActivePage, setLocalActivePages, facebookPages.activePage]);

  useEffect(() => {
    if (activePageSubscription && activePageSubscription.isActive === true) {
      if (notifications.connectedToHub === 'IDLE' && facebookPages.activePage) {
        setupConnection();
      }
    }
  }, [
    notifications.connectedToHub,
    facebookPages.activePage,
    activePageSubscription,
    setupConnection,
  ]);

  useEffect(() => {
    if (userDetails.user && location.pathname !== '/dashboard/upgrade') {
      if (activePageSubscription && activePageSubscription.isActive === false) {
        props.history.push('/dashboard/upgrade');
      }
    }
  }, [userDetails.user, location.pathname, activePageSubscription, props.history]);

  const localNoPrint = localStorage.getItem('localNoPrint') === 'true';
  const autoPrint = facebookPages.activePage?.autoPrintReceipt && !localNoPrint;
  const enableNotificationModal = !isAdmin || pageAdminConfiguration.enableNotificationModal;

  return (
    <AdminLayoutWrapper>
      {enableNotificationModal && (
        <>
          {autoPrint && <AutoPrinter />}
          <OrderViewModal />
          <CallWaiterModal />
          <GetBillModal />
        </>
      )}

      <Navigation
        isBartender={isBartender}
        show={showNavigation}
        toggleNavigation={() => toggleNavigation(!showNavigation)}
        onlineOrderURL={
          props.facebookPages &&
          props.facebookPages.activePage &&
          props.facebookPages.activePage.onlineOrderURL
        }
      />
      <ContentWrapper>
        <TopBarWrapper>
          <TopBar
            isBartender={isBartender}
            personImage={personImage}
            togglePayment={() => {
              props.showUpgradeModal(true);
            }}
          />
        </TopBarWrapper>
        <MobileTopBarWrapper>
          <MobileTopBar
            toggleNavigation={() => {
              toggleNavigation(!showNavigation);
            }}
          />
        </MobileTopBarWrapper>

        {!props.facebookPages.activePage ||
        props.facebookPages.activePage.status === 'Connecting' ? (
          <Spinner size={80} style={{ height: '100px' }} />
        ) : (
          props.children
        )}
      </ContentWrapper>
      {activePageSubscription &&
      (activePageSubscription.isTrialSubscription || !activePageSubscription.isActive) ? (
        <UpgradeModal
          show={props.upgradeModal.show}
          close={() => {
            props.showUpgradeModal(false);
          }}
        />
      ) : null}
    </AdminLayoutWrapper>
  );
});

const mapStateToProps = (state) => ({
  facebookPages: state.facebookPages,
  categories: state.categories,
  products: state.products,
  orders: state.orders,
  clients: state.clients,
  notifications: state.notifications,
  userDetails: state.userDetails,
  upgradeModal: {
    show: state.subscriptions.showUpgradeModal,
  },
  activePageSubscription: state.subscriptions.activePageSubscription,
  pageAdminConfiguration: state.pageConfiguration.pageAdminConfiguration,
});

export default connect(mapStateToProps, {
  setLocalUser,
  getUserDetails,
  setActivePage,
  setLocalActivePages,
  getAll,
  getNotifications,
  showUpgradeModal,
  setupConnection,
  getFacebookPages,
})(AdminLayout);
