import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';

import { setClientInfo, setPaymentCard } from 'actions/shoppingActions';
import { useDispatch, useSelector } from 'react-redux';

import { CardElement, useElements } from '@stripe/react-stripe-js';
import * as Yup from 'yup';
import Input from 'app/shared/views/input';
import { PAYMENT_METHODS } from '../constants';

const cardStyles = {
  base: {
    color: '#434343',
    fontSize: '16px',
    fontFamily: 'Inter',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: '#434343',
    },
    // fontWeight: 300,
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
};

const PaymentInfoCard = ({
  clientInfo = {},
  clientInfoErrors = {},
  disabledPaymentMethods = [],
  readOnly,
  collectClientName,
  ...props
}) => {
  const dispatch = useDispatch();
  const elements = useElements();
  const paymentMethod = useSelector((state) => state.shopping.paymentMethod);

  const [cardError, setCardError] = useState(null);

  const [form, setForm] = useState({
    values: {
      firstName: clientInfo.firstName || '',
      lastName: clientInfo.lastName || '',
      phone: clientInfo.phone || '',
    },
    schema: collectClientName
      ? {
          firstName: Yup.string().required('First name is required'),
          lastName: Yup.string().required('Last name is required'),
          phone: Yup.string().matches(
            /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
            'Phone number is not valid',
          ),
        }
      : {
          phone: Yup.string().matches(
            /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
            'Phone number is not valid',
          ),
        },
    touched: [],
    errors: {},
  });

  useEffect(() => {
    setForm((form) => ({
      ...form,
      values: {
        ...form.values,
        phone: clientInfo.phone || '',
      },
    }));
  }, [clientInfo.phone]);

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setForm((form) => ({
        ...form,
        values: {
          ...form.values,
          [name]: value,
        },
        touched: [...form.touched, name],
      }));

      dispatch(setClientInfo({ ...clientInfo, [name]: value }));
    },
    [clientInfo, dispatch],
  );

  useEffect(() => {
    const t = setTimeout(() => {
      const plainSchema = {};
      for (let field of form.touched) {
        if (clientInfo[field] === undefined || clientInfo[field] === null) {
          continue;
        }
        plainSchema[field] = form.schema[field];
      }
      Yup.object()
        .shape(plainSchema)
        .validate(clientInfo)
        .then(() => {
          setForm((form) => ({
            ...form,
            errors: {},
          }));
        })
        .catch((err) => {
          setForm((form) => ({
            ...form,
            errors: {
              [err.path]: err.message,
            },
          }));
        });
    }, 700);

    return () => clearTimeout(t);
  }, [clientInfo, form.schema, form.touched]);

  const onPaymentDetailsChange = useCallback(
    async (e) => {
      // e.preventDefault();
      if (e.error) {
        setCardError(e.error.message.replace('.', ''));
        dispatch(setPaymentCard(null));
      } else if (e.complete) {
        const card = elements.getElement(CardElement);
        dispatch(setPaymentCard(card));
        setCardError(null);
      }
    },
    [elements, dispatch],
  );

  return (
    <PaymentInfoCardWrapper {...props}>
      <ClientDetailsWrapper>
        {collectClientName && (
          <TwoColumns>
            <div style={{ flex: 1 }}>
              <Input.Input
                type="text"
                name="firstName"
                placeholder="First Name"
                height="40"
                value={form.values.firstName || ''}
                onChange={handleChange}
                // onBlur={handleBlur}
                readOnly={readOnly}
                style={{ flex: 1 }}
              />
              {form.errors.firstName && <ErrorWrapper>{form.errors.firstName}</ErrorWrapper>}
            </div>
            <div style={{ flex: 1 }}>
              <Input.Input
                type="text"
                name="lastName"
                placeholder="Last Name"
                height="40"
                value={form.values.lastName || ''}
                onChange={handleChange}
                // onBlur={handleBlur}
                readOnly={readOnly}
                style={{ flex: 1 }}
              />
              {form.errors.lastName && <ErrorWrapper>{form.errors.lastName}</ErrorWrapper>}
            </div>
          </TwoColumns>
        )}

        {paymentMethod.methodId === PAYMENT_METHODS.WITH_CARD.methodId && (
          <div style={{ flex: 1 }}>
            <PaymentCardNumber
              options={{
                style: cardStyles,
                hidePostalCode: true,
              }}
              onChange={onPaymentDetailsChange}
            />
            {cardError && <ErrorWrapper>{cardError}</ErrorWrapper>}
          </div>
        )}

        <div
          style={{
            flex: 1,
            paddingBottom: '10px',
            width: '95%',
            marginLeft: '12.3px',
            borderRadius: '7px',
          }}
        >
          <Input.PhoneNumberInput
            placeholder={'Place your phone number'}
            style={{ border: form.errors.phone ? '1px solid red' : 'unset', borderRadius: '7px' }}
            defaultCountry={'AL'}
            value={form.values.phone}
            onChange={(phone) => handleChange({ target: { name: 'phone', value: phone } })}
          />
          {form.errors.phone && <ErrorWrapper>{form.errors.phone}</ErrorWrapper>}
        </div>
      </ClientDetailsWrapper>
    </PaymentInfoCardWrapper>
  );
};

const PaymentInfoCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
`;

const ClientDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const TwoColumns = styled.div`
  display: flex;
  margin-left: 20px;
  margin-right: 20px;

  & > * {
    &:nth-child(2n + 1) {
      margin-right: 20px;
    }
  }
`;

const PaymentCardNumber = styled(CardElement)`
  height: 40px;
  min-height: 40px;
  background: #f9fafc;
  border-radius: 4px;
  border: none;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #748aa1;
  padding: 11px 20px;
`;

const ErrorWrapper = styled.div`
  color: #f2453d;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  border-radius: 3px;
  align-items: center;
  letter-spacing: 1px;
  font-weight: 600;
  padding: 1px 5px;
  display: flex;
  width: 100%;
  margin-top: 1px;
`;

export default PaymentInfoCard;
