import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import shared from 'app/shared';
import { NavLink } from 'react-router-dom';
import AddClientPopup from './addClientPopup';
import EditClient from './editClient';
import crudTypes from '../../../config/crudTypes';
import { getAllV2, getAll, createObjectFromState } from 'actions/crudActions';
import { connect } from 'react-redux';
import { paginateNoOp } from 'config/helpers/paginator';
import { getSortState, sortData } from 'config/helpers/sorter';
import { filterData } from 'config/helpers/searcher';
import MobileFilter from '../../shared/views/mobileFilter/mobileFilter';
import { CSVFileExporter } from 'app/shared/helpers/CSVFileExporter';
import ClientCsvMapper from './clientCsvMapper';
import SearchInput from 'app/shared/views/searchInput';
import currencyFormatter from 'app/shared/helpers/currencyFormatter';


const {
  views: { Table, Button, Paginator, Page, Card, FilterableTableStructure, SimpleFilterBar },
  helpers: { resetScroll, colors },
} = shared;

const {
  StatsSectionWrapper,
  ActionBarWrapper,
  SearchWrapper,
  TableWrapper,
  PaginatorWrapper,
} = FilterableTableStructure;

const initialState = {
  filters: {
    selectedPeriod: 'today',
  },
  sort: {
    key: 'creationDate',
    order: -1,
  },
  currentPage: 1,
  customDate: { startDate: null, endDate: null },
  pageSize: 10,
  search: '',
  redirect: false,
  getStatus: '',
  showEditPopup: false,
};

const TableWrapperTablet = styled(Card)`
  display: none;
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGrey};
  border-radius: 3px;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: block;
    margin-top: 25px;
  }
  @media all and (max-width: 768px) {
    display: block;
    margin-top: 25px;
  }
`;

const ButtonWrapper = styled.div`
  @media all and (max-width: 768px) {
    display: none;
  }
`;

class ClientsPage extends React.Component {
  state = initialState;

  setGetStatus = (status) => {
    this.setState({ getStatus: status });
  };

  componentWillMount() {
    this.props.getAllV2(crudTypes.client, 'CreationDate', createObjectFromState(this.state, true));
  }

  componentDidMount() {
    resetScroll();
  }

  setSearchValue = (val) => {
    this.setState({ search: val, currentPage: 1 });
  };

  updateSortState = (key) => {
    this.setState((prevState) => ({
      sort: getSortState(prevState.sort, key),
    }), () => {
      this.props.getAllV2(crudTypes.client, 'CreationDate', createObjectFromState(this.state, true));
    });
  };

  getTableData = (values = []) => ({
    action: this.updateSortState,
    header: [
      {
        label: 'CLIENT',
        key: 'client',
        renderer: ({ fullName, email, phone }) => {
          if (phone) {
            let newPhoneNumber = phone.slice(0, -3) + "***";
            phone = newPhoneNumber;
          }
          if (fullName && fullName.trim() !== '') return fullName;
          return email || phone || '-';
        },
        sortable: false,
      },
      {
        label: 'LOCATION',
        key: 'location',
        renderer: ({ locations }) => (locations.length > 0 && locations[0].locationName) || '-',
        sortable: true,
      },
      {
        label: 'DATE',
        key: 'creationDate',
        sortable: true,
        renderer: ({ creationDate }) =>
          creationDate && moment(creationDate).format('YYYY-MM-DD HH:mm'),
      },
      {
        label: 'ORDERS',
        key: 'orderCount',
        renderer: ({ orderCount }) => orderCount,
        sortable: true,
      },
      {
        label: 'REVENUE',
        key: 'totalSum',
        renderer: ({ totalSum }) => (
          <span style={{ fontWeight: 600, fontSize: '12px', lineHeight: '58px', color: '#2ecc71' }}>
            {currencyFormatter(totalSum)}
          </span>
        ),
        sortable: true,
      },
      {
        label: 'ACTION',
        width: '70px',
        key: 'id',
        sortable: true,
        renderer: ({ id }) => {
          return (
            <NavLink to={`${EditClient.path}/${id}`}>
              <Button.Button
                style={{ fontWeight: 600 }}
                text="Edit"
                size={Button.ButtonSize.medium}
                color={colors.blue}
              />
            </NavLink>
          );
        },
      },
    ],
    values: values,
  });

  getTableDataTablet = (values = []) => ({
    action: this.updateSortState,
    header: [
      {
        label: 'CLIENT',
        key: 'client',
        renderer: ({ fullName, email, phone }) => {
          return email || phone || '-';
        },
        sortable: false,
      },
      {
        label: 'DATE',
        key: 'creationDate',
        sortable: true,
        renderer: ({ creationDate }) =>
          creationDate && moment(creationDate).format('YYYY-MM-DD HH:mm'),
      },
      {
        label: 'ACTION',
        width: '70px',
        key: 'id',
        sortable: true,
        renderer: ({ id }) => {
          return (
            <NavLink to={`${EditClient.path}/${id}`}>
              <Button.Button
                style={{ fontWeight: 600 }}
                text="Edit"
                size={Button.ButtonSize.medium}
                color={colors.blue}
              />
            </NavLink>
          );
        },
      },
    ],
    values: values,
  });

  updateSelectedPeriod = (selectedPeriod) => {
    if (selectedPeriod !== 'custom') {
      this.setState({
        customDate: {
          startDate: null,
          endDate: null,
        },
      }, () => {
        this.props.getAllV2(crudTypes.client, 'CreationDate', createObjectFromState(this.state, true));
      });
    }
    this.setState((prevState) => ({
      filters: { ...prevState.filters, selectedPeriod },
      currentPage: 1,
    }));
  };

  updateCustomDate = (customDate) => {
    this.setState({
      customDate: { startDate: customDate.startDate, endDate: customDate.endDate },
      currentPage: 1,
    }, () => {
      this.props.getAllV2(crudTypes.client, 'CreationDate', createObjectFromState(this.state, true));
    });
  };

  toggleAddPopup = () => this.setState({ showEditPopup: !this.state.showEditPopup });

  clearFilters = () => {
    this.setState({ filters: initialState.filters });
    this.setState({ customDate: { startDate: null, endDate: null }, currentPage: 1 });
  };

  updatePaginatorPage = (currentPage) => {
    this.setState({ currentPage }, () => {
      this.props.getAllV2(crudTypes.client, 'CreationDate', createObjectFromState(this.state, true));
    });
  };

  toggle = () => this.setState({ toggleValue: !this.state.toggleValue });

  createExportCSV = (data) => {
    return () => {
      CSVFileExporter('clients.csv', ClientCsvMapper(data));
    };
  };
  
  
  render() {
    const {
      state: {
        filters: { selectedPeriod },
        currentPage,
        pageSize,
        sort,
        search,
        showEditPopup,
        customDate,
      },
      updateSelectedPeriod,
      clearFilters,
      updatePaginatorPage,
      toggleAddPopup,
      updateCustomDate,
      createExportCSV,
    } = this;

    // const clients = this.props.clients.data.filter((client) => !isTableClient(client));
    // const timeFilteredData = genericFilter(
    //   selectedPeriod,
    //   clients,
    //   customDate,
    //   'creationDate',
    //   null,
    // );
    
    

    const searchData = filterData(
      this.props.clients.data.filter((x) => this.props.clients.data.includes(x)) || [],
      search,
    );     		

    const sortedData = sortData(searchData, sort);	
    const paginatedData = paginateNoOp(sortedData, currentPage, pageSize);	

    return (
      <Page>
        <AddClientPopup show={showEditPopup} toggle={toggleAddPopup} />
        <SimpleFilterBar
          selectedPeriod={selectedPeriod}
          updateSelectedPeriod={updateSelectedPeriod}
          clearFilters={clearFilters}
          updateCustomDate={updateCustomDate}
          customDate={customDate}
        />
        <MobileFilter
          selectedPeriod={selectedPeriod}
          updateSelectedPeriod={updateSelectedPeriod}
          clearFilters={clearFilters}
        />
        <StatsSectionWrapper>
          <ActionBarWrapper>
            <ButtonWrapper>
              <Button.Button
                color={colors.blue}
                text={'Add New'}
                onClick={toggleAddPopup}
                style={{ marginRight: 5 }}
              />
              <Button.WhiteButton
                color={colors.textColor}
                style={{ marginRight: 5 }}
                text={'Export CSV'}
                onClick={() => getAllV2(crudTypes.client, 'CreationDate', createObjectFromState({...this.state, noPagination: true}, true))(clients => {
                  createExportCSV(sortData(clients.data,sort))()
                })}
              />
            </ButtonWrapper>
            <SearchWrapper>
              <SearchInput search={search} onSearchChanged={(x) => this.setSearchValue(x)} />
            </SearchWrapper>
          </ActionBarWrapper>
          <TableWrapper>
            <Table.TableView data={this.getTableData(paginatedData.current)} />
          </TableWrapper>
          <TableWrapperTablet>
            <Table.TableView data={this.getTableDataTablet(paginatedData.current)} />
          </TableWrapperTablet>
          <PaginatorWrapper>
            <Paginator
              changePage={updatePaginatorPage}
              numberOfPages={this.props.clients.current.totalPages || 1}
              page={paginatedData.currentPage}
            />
          </PaginatorWrapper>
        </StatsSectionWrapper>
      </Page>
    );
  }
}

ClientsPage.path = '/dashboard/clients';

const mapStateToProps = (state) => ({
  clients: {
    data: state.clients.data.map((el) => ({
      ...el,
      location: el.locations.length === 0 ? '' : el.locations[0].locationName,
    })),
    current: state.clients.current,
  },
});

export default connect(mapStateToProps, {
  getAll, getAllV2
})(ClientsPage);

export { ClientsPage };
