const initialState = {
  data: [],
  activePage: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_FB_PAGES_OK':
      return {
        ...state,
        data: [...action.data],
      };
    case 'SET_ACTIVE_PAGE_WAIT':
      return {
        data: [...state.data],
        activePage: {
          ...state.activePage,
          status: 'Connecting',
        },
      };
    case 'SET_ACTIVE_PAGE_OK':
      return {
        data: state.data.map((page) => (page.id === action.data.id ? action.data : page)),
        activePage: action.data,
      };
    case 'SET_LOCAL_ACTIVE_PAGES_OK':
      return {
        ...state,
        data: [...action.pages],
      };
    case 'SMS_CREDIT_BALANCE_UPDATED':
      return {
        ...state,
        data: state.data.map((page) =>
          page.id === action.pageId
            ? { ...state.activePage, smsCredits: action.data.updatedSmsCredits }
            : page,
        ),
        activePage: { ...state.activePage, smsCredits: action.data.updatedSmsCredits },
      };

    case 'LOGIN_USER_OK':
      return {
        ...state,
        data: [],
        activePage: null,
      };

    case 'OPEN_HOURS_UPDATED_OK':
      return {
        ...state,
        activePage: {
          ...state.activePage,
          openHours: action.data,
        },
      };
    case 'TOGGLE_PAGE_EVENT':
      return {
        ...state,
        activePage: {
          ...state.activePage,
          pageEvents: [...action.data],
        },
      };
    case 'SET_PAGE_EVENT':
      return {
        ...state,
        activePage: {
          ...state.activePage,
          pageEvents: [
            ...state.activePage.pageEvents.filter((pe) => pe.id !== action.data.id),
            action.data,
          ],
        },
      };
    case 'SET_PAGE_CHARACTERISTICS':
      return {
        ...state,
        activePage: {
          ...state.activePage,
          pageCharacteristics: [...action.data],
        },
      };
    case 'SET_PAGE_SOCIAL_LINKS':
      return {
        ...state,
        activePage: {
          ...state.activePage,
          socialLinks: { ...action.data },
        },
      };
    default:
      return state;
  }
};
