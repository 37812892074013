import React from 'react';
import styled from 'styled-components';

import Icons from 'app/shared/views/icons';

import allSetSvg from '../assets/images/allSet.svg';

import MainButton from './MainButton';

const AllSet = ({ title = 'All Set', message, actionText, onAction, footnote }) => {
  if (Array.isArray(message)) {
    message = message.join('\n');
  }

  return (
    <AllSetWrapper>
      <AllSetTitle>{title}</AllSetTitle>
      <AllSetImg alt="All Set" src={allSetSvg} />
      <AllSetMessage>{message}</AllSetMessage>
      {actionText && <MainButton
        text={actionText}
        onClick={onAction}
        style={{ width: '100%' }}
      />}
      {footnote && <AllSetFootnote>
        <Icons.Info fill="#BFCADB" width={'12px'} height={'12px'} style={{ margin: '0 4px' }} />
        {footnote}
      </AllSetFootnote>}
    </AllSetWrapper>
  );
};

const AllSetWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const AllSetTitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 46px;
  color: #001529;
  margin-bottom: 10px;
`;

const AllSetMessage = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 22px;
  color: #5C677B;
  white-space: pre;
  margin: 25px 0;
`;

const AllSetImg = styled.img`
  max-width: 100%;
  width: 100%;
  margin: 5px 0;
`;

const AllSetFootnote = styled.div`
  background: #F9FAFC;
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.004em;
  color: #BFCADB;

  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
`;

export default AllSet;
