import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'app/shared/views/buttons';
import DotSpinner from 'app/shared/views/DotSpinner';
import Icons from 'app/shared/views/icons';
const WaiterFunctionalities = ({ getBill, callWaiter, setShowReview }) => {
  const [isRingingCallWaiter, setIsRingingCallWaiter] = useState(false);
  const [isRingingGetBill, setIsRingingGetBill] = useState(false);
  useEffect(() => {
    if (isRingingCallWaiter) {
      let t = setTimeout(() => {
        setIsRingingCallWaiter(false);
        clearTimeout(t);
      }, 5000);
    }
  }, [isRingingCallWaiter]);

  useEffect(() => {
    if (isRingingGetBill) {
      let t = setTimeout(() => {
        setIsRingingGetBill(false);
        clearTimeout(t);
      }, 5000);
    }
  }, [isRingingGetBill]);
  return (
    <InfoSpace style={{ margin: '15px 0', padding: '0 20px' }}>
      <ActionButton
        onClick={() => {
          setIsRingingGetBill(true);
          getBill();
        }}
        style={{
          width: '100%',
          height: 26,
          background: '#2A2E45',
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: 12,
          color: '#FFFFFF',
          boxSizing: 'border-box',
          borderRadius: 4,
        }}
        text={
          isRingingGetBill ? (
            <DotSpinner size={8} color="#fff" />
          ) : (
            <>
              <Icons.BillOutline width="10px" height="12px" />
              <span
                style={{
                  marginLeft: 8,
                  fontSize: 12,
                  fontStyle: 'normal',
                  fontWeight: 400,
                }}
              >
                Get Bill
              </span>
            </>
          )
        }
      />
      <ActionButton
        onClick={() => {
          setIsRingingCallWaiter(true);
          callWaiter();
        }}
        style={{
          width: '100%',
          height: 26,
          background: '#2A2E45',
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: 12,
          color: '#FFFFFF',
          boxSizing: 'border-box',
          borderRadius: 4,
        }}
        text={
          isRingingCallWaiter ? (
            <DotSpinner size={8} color="#fff" />
          ) : (
            <>
              <Icons.RingOutline width="10px" height="12px" />
              <span
                style={{
                  marginLeft: 8,
                  fontSize: 12,
                  fontStyle: 'normal',
                  fontWeight: 400,
                }}
              >
                Call Waiter
              </span>
            </>
          )
        }
      />
      <ActionButton
        onClick={setShowReview}
        text={
          <>
            <Icons.StarOutline width="12px" height="12px" />
            <span
              style={{
                marginLeft: 8,
                fontSize: 12,
                fontStyle: 'normal',
                fontWeight: 400,
              }}
            >
              Review
            </span>
          </>
        }
        style={{
          width: '100%',
          height: 26,
          background: '#2A2E45',
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: 12,
          color: '#FFFFFF',
          boxSizing: 'border-box',
          borderRadius: 4,
        }}
      />
    </InfoSpace>
  );
};

const ActionButton = ({ onClick, text }) => {
  return (
    <Button
      onClick={onClick}
      style={{
        width: '100%',
        height: 26,
        background: '#FFFFFF',
        color: '#2A2E45',
        boxSizing: 'border-box',
        borderRadius: 4,
        lineHeight: '20px',
      }}
      text={text}
    />
  );
};

const InfoSpace = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  & > * {
    flex: 1;
    margin-right: 20px;
    &:last-child {
      margin-right: unset;
    }
  }
`;
export default WaiterFunctionalities;
