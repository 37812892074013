import React from 'react';
import styled from 'styled-components';
import Category from './Category';

const HorizontalScrollableSpace = styled.div`
  display: flex;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    flex: 0 0 auto;
    margin-right: ${(props) => props.gap || 3}px;

    &:last-child {
      padding-right: ${(props) => props.gap * 2 || '18'}px;
    }
  }
  @media only screen and (min-width: 500px) {
    flex-wrap: wrap;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  @media only screen and (max-width: 499px) {
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: auto;
    scroll-behavior: smooth;

    & > * {
      &:first-child {
        margin-left: 18px;
      }
    }
  }

  @media only screen and (min-width: 901px) {
    margin: ${({ openedIframe }) => (openedIframe ? '0 40px 0' : '0 100px 0')};
    padding-top: 30px;
    padding-bottom: 20px;
  }

  @media only screen and (max-width: 900px) {
    padding: 10px 20px;
  }
`;

const Categories = ({ categories, pages, events, selectedCategory, openedIframe }) => {
  return (
    <HorizontalScrollableSpace key={'filteredCategories'} gap={10} openedIframe={openedIframe}>
      <Category key={777} category={{ id: 777, name: 'All' }} selectedCategory={selectedCategory} />
      {(events.length > 0 || selectedCategory === 'events') && (
        <Category
          key={'events'}
          category={{ id: 'events', name: 'Events' }}
          selectedCategory={selectedCategory}
        />
      )}

      {categories.map(
        (category) =>
          pages.reduce(
            (acc, p) =>
              p.aggregatorCategories.find((aC) => aC.id === category.id) ? [...acc, p] : acc,
            [],
          ).length !== 0 &&
          category.id !== 777 && (
            <Category key={category.id} category={category} selectedCategory={selectedCategory} />
          ),
      )}
    </HorizontalScrollableSpace>
  );
};

export default Categories;
