const initialState = {
    user: null
};

export default (state = initialState, action) => {
    switch(action.type) {
      case 'GET_USER_DETAILS_OK':
        return {
          user: action.user
        }
      case 'SET_LOCAL_USER':
        return {
          ...state,
          user: action.user || { ...state.user }
        }

      case 'LOGIN_USER_OK':
        return {
          ...state,
          user: null,
        };
      default:
        return state
    }
}
