import React from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';
import CurrencyFormatter from '../../../shared/helpers/currencyFormatter';
import styled from 'styled-components';
import { getClientFullName } from 'app/shared/helpers/client';

const Text = styled.p``;

const Header = styled(Text)`
  font-weight: bold;
`;

const RestaurantNameWrapper = styled(Text)`
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const ReceiptWrapper = styled.div`
  padding: 0;
  margin: 16px;
  & * {
    color: black;
  }
`;

const OrderProductsWithValues = ({ printProductDescription = false, orderProducts }) => (
  <>
    <Row>
      <Col span={10}>
        <Header>Products:</Header>
      </Col>
      <Col span={7}>
        <Header>Quantity:</Header>
      </Col>
      <Col span={7}>
        <Header style={{ textAlign: 'right' }}>Value:</Header>
      </Col>
    </Row>
    {orderProducts.map((product) => (
      <Row key={product.id} gutter={[0, 8]}>
        <Col span={10}>
          <Text>{product.name}</Text>
        </Col>
        <Col span={7}>
          <div style={{ paddingLeft: 16 }}>
            <Text>{product.qty}</Text>
          </div>
        </Col>
        <Col span={7}>
          <Text style={{ textAlign: 'right' }}>
            {CurrencyFormatter(product.qty * product.price)}
          </Text>
        </Col>
        {printProductDescription && (
          <Row gutter={[0, 8]}>
            <Col span={24}>
              <Text style={{ paddingLeft: 8 }}>{product.description}</Text>
            </Col>
          </Row>
        )}
        {product.toppings?.map((topping) => (
          <Row key={topping.id} gutter={[0, 8]}>
            <Col span={10}>
              <Text style={{ paddingLeft: 8 }}>{topping.name}</Text>
            </Col>
            <Col span={7}>
              <Text style={{ paddingLeft: 16 }}>{topping.qty}</Text>
            </Col>
            <Col span={7}>
              <Text style={{ textAlign: 'right' }}>
                {CurrencyFormatter(topping.qty * topping.price)}
              </Text>
            </Col>
          </Row>
        ))}
      </Row>
    ))}
  </>
);

const OrderProductsWithoutValues = ({ printProductDescription = false, orderProducts }) => (
  <>
    <Row>
      <Col span={14}>
        <Header>Products:</Header>
      </Col>
      <Col span={7}>
        <Header>Quantity:</Header>
      </Col>
    </Row>
    {orderProducts.map((product) => (
      <Row key={product.id} gutter={[8, 8]}>
        <Col span={14}>
          <Text>{product.name}</Text>
        </Col>
        <Col span={7}>
          <div style={{ paddingLeft: 16 }}>
            <Text style={{ textAlign: 'right' }}>{product.qty}</Text>
          </div>
        </Col>
        {printProductDescription && (
          <Row gutter={[0, 8]}>
            <Col span={24}>
              <Text style={{ paddingLeft: 8 }}>{product.description}</Text>
            </Col>
          </Row>
        )}
        {product.toppings?.map((topping) => (
          <Row key={topping.id} gutter={[0, 8]}>
            <Col span={17}>
              <Text style={{ paddingLeft: 8 }}>{topping.name}</Text>
            </Col>
            <Col span={7}>
              <Text style={{ paddingLeft: 23, textAlign: 'right' }}>{topping.qty}</Text>
            </Col>
          </Row>
        ))}
      </Row>
    ))}
  </>
);

const ComponentToPrint = React.forwardRef(
  ({ activePage, order, restaurantName, totalValue, receiptConfiguration }, ref) => {
    if (order == null) return null;

    const printRestaurantName = receiptConfiguration?.printRestaurantName ?? true;
    const printOrderValues = receiptConfiguration?.printOrderValues ?? true;
    const printProductDescription = receiptConfiguration?.printProductDescription ?? false;

    const isTableOrder = !!order?.tableId;

    const {
      orderCounter,
      orderDate,
      clientPhone,
      customerDescription,
      orderProducts,
      discount,
      deliveryFee,
      orderLocation,
      orderLocation2,
      waiterName,
      tableName,
    } = order;
    const clientName = getClientFullName(order);
    return (
      <ReceiptWrapper ref={ref}>
        {printRestaurantName && (
          <Row>
            <Col
              span={24}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <RestaurantNameWrapper>{restaurantName}</RestaurantNameWrapper>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24} style={{ display: 'flex', paddingBottom: 8 }}>
            <Text style={{ minWidth: 80 }}>Order No.</Text>
            <Text style={{ fontWeight: 'bold' }}>{orderCounter}</Text>
          </Col>
          {isTableOrder && (
            <>
              {tableName && (
                <Col span={24} style={{ display: 'flex', paddingBottom: 8 }}>
                  <Text style={{ minWidth: 80 }}>Table No.</Text>
                  <Text style={{ fontWeight: 'bold' }}>{tableName}</Text>
                </Col>
              )}
              {waiterName && (
                <Col span={24} style={{ display: 'flex', paddingBottom: 8 }}>
                  <Text style={{ minWidth: 80 }}>Waiter</Text>
                  <Text style={{ fontWeight: 'bold' }}>{waiterName}</Text>
                </Col>
              )}
            </>
          )}
          <Col span={24} style={{ display: 'flex', paddingBottom: 8 }}>
            <Text style={{ minWidth: 80 }}>Date:</Text>
            <Text style={{ fontWeight: 'bold' }}>
              {moment(orderDate).format('HH:mm DD.MM.YYYY')}
            </Text>
          </Col>
          {clientName && (
            <Col span={24} style={{ display: 'flex', paddingBottom: 8 }}>
              <Text style={{ minWidth: 80 }}>Client:</Text>
              <Text style={{ fontWeight: 'bold' }}>{clientName}</Text>
            </Col>
          )}
          {clientPhone && (
            <Col span={24} style={{ display: 'flex', paddingBottom: 8 }}>
              <Text style={{ minWidth: 80 }}>Phone:</Text>
              <Text style={{ fontWeight: 'bold' }}>{clientPhone}</Text>
            </Col>
          )}
          {orderLocation && (
            <Col span={24} style={{ display: 'flex', paddingBottom: 8 }}>
              <Text style={{ minWidth: 80 }}>Address:</Text>
              <Text style={{ fontWeight: 'bold' }}>{orderLocation}</Text>
            </Col>
          )}
          {orderLocation2 && (
            <Col span={24} style={{ display: 'flex', paddingBottom: 8 }}>
              <Text style={{ minWidth: 80 }} />
              <Text style={{ fontWeight: 'bold' }}>{orderLocation2}</Text>
            </Col>
          )}
          <Col span={24} style={{ display: 'flex', paddingBottom: 8 }}>
            <Text style={{ minWidth: 80 }}>Note:</Text>
            <Text style={{ fontWeight: 'bold' }}>{customerDescription}</Text>
          </Col>
          <Col span={24}>
            <hr />
          </Col>
        </Row>
        {printOrderValues ? (
          <OrderProductsWithValues
            printProductDescription={printProductDescription}
            orderProducts={orderProducts}
          />
        ) : (
          <OrderProductsWithoutValues
            printProductDescription={printProductDescription}
            orderProducts={orderProducts}
          />
        )}
        {printOrderValues && (
          <>
            <Row>
              <Col span={10}>
                <Text>Promotion Discount</Text>
              </Col>
              <Col span={7} />
              <Col span={7}>
                <Text style={{ textAlign: 'right' }}>{CurrencyFormatter(discount)}</Text>
              </Col>
            </Row>
            {!isTableOrder && (
              <Row>
                <Col span={10}>
                  <Text>Delivery fee</Text>
                </Col>
                <Col span={7} />
                <Col span={7}>
                  <Text style={{ textAlign: 'right' }}>{CurrencyFormatter(deliveryFee)}</Text>
                </Col>
              </Row>
            )}
            <Row>
              <hr />
            </Row>
            <Row style={{ marginTop: 16 }}>
              <Col span={12}>
                <Header>Total:</Header>
              </Col>
              <Col span={12}>
                <Text style={{ textAlign: 'right' }}> {totalValue} </Text>
              </Col>
            </Row>
          </>
        )}
      </ReceiptWrapper>
    );
  },
);

export default ComponentToPrint;
