import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ScrollButton } from './buttons';

const HorizontalScrollableSpaceWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: auto;
  position: relative;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
  & > * {
    flex: 0 0 auto;
    margin-right: ${(props) => props.gap || 3}px;

    &:first-child {
      margin-left: 18px;
    }

    &:last-child {
      padding-right: ${(props) => props.gap * 2 || '18'}px;
    }
  }
`;

const Button = styled(ScrollButton)`
  position: absolute;
  height: 60px;
  padding: 0px;
  min-height: 50px;
  width: 20px;
  top: 5px;

  @media only screen and (max-width: 499px) {
    display: none;
  }

  @media only screen and (min-width: 500px) {
    display: ${(props) => (props.scrollWidth > 502 ? 'flex' : 'none')};
  }
  padding-bottom: 2px;
`;

const HorizontalScrollableSpace = (props) => {
  const hSSref = useRef(null);

  const [scrollWidth, setScrollWidth] = useState(hSSref.current?.scrollWidth);

  useEffect(() => {
    setScrollWidth(hSSref.current?.scrollWidth);
  }, [hSSref, scrollWidth]);

  const scroll = (scrollOffset) => {
    hSSref.current.scrollTo({
      top: 0,
      left: (hSSref.current.scrollLeft += scrollOffset),
      behavior: 'smooth',
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <Button
        scrollWidth={hSSref.current?.scrollWidth}
        onClick={() => scroll(-160)}
        // style={{
        //   transform: 'translateY(calc(100% - 60px))',
        // }}
      />
      <Button
        scrollWidth={hSSref.current?.scrollWidth}
        onClick={() => scroll(160)}
        style={{
          right: 0,
          // transform: 'translateY(calc(100% - 60px)) rotate(180deg)',
          transform: 'rotate(180deg)',
        }}
      />
      <HorizontalScrollableSpaceWrapper ref={hSSref} {...props}>
        {props.children}
      </HorizontalScrollableSpaceWrapper>
    </div>
  );
};

export default HorizontalScrollableSpace;
