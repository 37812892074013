import Url from '../config/api';
import moment from 'moment';
import { api } from 'actions/shoppingActions';
import { buildAxiosInstance } from 'actions/common';

const { baseUrl } = Url;

const axiosClient = buildAxiosInstance(baseUrl);

export const dispatchSuccessV2 = (type, res) => {
  return {
    type: `${type}_OK`,
    data: res.data.items || res.data.results,
    totalPages: res.data.totalPages,
  };
};

export const dispatchSuccess = (type, res) => {
  return {
    type: `${type}_OK`,
    data: res.data,
  };
};

const dispatchError = (type) => {
  return {
    type: `${type}_ERROR`,
  };
};

function getSourceFromSelectedItem(item) {
  switch (item.toLowerCase()) {
    case 'online':
      return 'online-order';
    case 'table':
      return 'table-order';
    default:
      throw new TypeError(`Unknown source: ${item}`);
  }
}

function createFilterCondition(obj, dateField) {
  switch (obj.filterByPeriod) {
    case 'all':
      break;
    case 'today':
      obj.filterByPeriodStart = moment().toDate().setHours(0, 0, 0, 0);
      obj.filterByPeriodEnd = null;
      break;
    case 'yesterday':
      obj.filterByPeriodStart = moment().subtract(1, 'day').toDate().setHours(0, 0, 0, 0);
      obj.filterByPeriodEnd = moment().subtract(2, 'day').toDate().setHours(23, 59, 59, 0);
      break;
    case 'last-week':
      obj.filterByPeriodStart = moment()
        .subtract(1, 'week').subtract(new Date().getDay() - 1, 'day').toDate().setHours(0, 0, 0, 0);
      obj.filterByPeriodEnd = moment()
        .subtract(new Date().getDay() + 1, 'day').toDate().setHours(23, 59, 59, 0);
      break;
    case 'last-month':
      obj.filterByPeriodStart = moment().subtract(1, 'month').subtract(new Date().getDate() - 1, "day").toDate().setHours(0, 0, 0, 0);
      obj.filterByPeriodEnd = moment().subtract(new Date().getDate() + 1, "day").toDate().setHours(23, 59, 59, 0);
      break;
    case 'total':
      obj.filterByPeriodStart = null;
      obj.filterByPeriodEnd = null;
      break;
    case 'custom':
      break;
    default:
      throw new TypeError(`Invalid date: ${obj.filterByPeriod}`);
  }

  let filterParameterCount = 0;
  let filterConditions = [];
  let filterParameters = [];
  function appendValue(value) {
    filterParameters.push(value);
    return `@${filterParameterCount++}`;
  }

  function appendDate(date, endDate) {
    let time = moment.utc(date).add(1, 'days');
    if (endDate) {
      time = time.endOf('day');
    } else {
      time = time.startOf('day');
    }
    return appendValue(time.format('yyyy-MM-DD HH:mm:ss'));
  }

  if (obj.filterByPeriodStart) {
    if (obj.filterByPeriodEnd) {
      filterConditions.push(
        `${dateField} >= ${appendDate(obj.filterByPeriodStart)} && ${dateField} <= ${appendDate(
          obj.filterByPeriodEnd,
          true,
        )}`,
      );
    } else {
      filterConditions.push(`${dateField} >= ${appendDate(obj.filterByPeriodStart)}`);
    }
  }

  if (obj.filterBySource && obj.filterBySource !== 'all') {
    filterConditions.push(
      `Source == ${appendValue(getSourceFromSelectedItem(obj.filterBySource))}`,
    );
  }

  if (obj.filterByStatus && obj.filterByStatus !== 'all') {
    switch (obj.filterByStatus) {
      case 'pending':
        filterConditions.push(`Status == ${appendValue(0)} || Status == ${appendValue(4)}`);
        break;
      case 'paid':
        filterConditions.push(`Status == ${appendValue(1)} || Status == ${appendValue(2)}`);
        break;
      case 'cancelled':
        filterConditions.push(`Status == ${appendValue(5)}`);
        break;
      default:
        throw new TypeError(`Invalid payment status: ${obj.filterByStatus}`);
    }
  }

  const pagination = {};
  if (!obj.noPagination) {
    pagination.pageNumber = obj.page;
    pagination.pageSize = obj.pageSize;
  }
  return {
    ...pagination,
    filter: {
      filterCondition: filterConditions
        .filter((x) => x)
        .map((x) => '(' + x + ')')
        .join(' && '),
      filterParameters,
    },
    sorting: [
      {
        columnName: obj.sortKey,
        direction: obj.sortDirection,
      },
    ],
  };
}

export function sortKeyToDbFunc(key, isClient) {
  switch (key) {
    case 'orderCounter':
      return 'OrderCounter';
    case 'client':
      return isClient ? 'Phone' : 'Client';
    case 'source':
      return 'Source';
    case 'orderProducts':
      return 'MagOrderProducts.First().Qty';
    case 'totalValue':
      return 'MagOrderProducts.First().Qty * MagOrderProducts.First().Price';
    case 'deliveryFee':
      return 'DeliveryFee';
    case 'paymentMethod':
      return 'MagOrdersPayments.First()';
    case 'orderStatus':
      return 'Status';
    case 'orderDate':
      return 'OrderDate';
    case 'creationDate':
      return 'CreationDate';
    case 'location':
      return 'Location';
    case 'orderCount':
      return 'MagOrders.Count()';
    case 'totalSum':
      return 'MagOrders.Select(x => x.MagOrderProducts.First().Qty * x.MagOrderProducts.First().Price).Sum()';
    case 'id':
      return 'Id';
    default:
      throw new TypeError(`Unknown sorting key: ${key}`);
  }
}

export function createObjectFromState(state, isClient) {
  const obj = {};
  if (state.filters.selectedPaymentStatus) {
    obj.filterByStatus = state.filters.selectedPaymentStatus.toLowerCase();
  }
  if (state.filters.selectedPeriod) {
    obj.filterByPeriod = state.filters.selectedPeriod.toLowerCase();
  }

  if (state.filters.selectedOrderSource) {
    obj.filterBySource = state.filters.selectedOrderSource.toLowerCase();
  }

  obj.filterByPeriodStart =
    state.customDate.startDate && state.customDate.startDate.toDate().setHours(0, 0, 0, 0);
  obj.filterByPeriodEnd =
    state.customDate.endDate && state.customDate.endDate.toDate().setHours(0, 0, 0, 0);

  obj.pageSize = state.pageSize;
  obj.page = state.currentPage;
  obj.noPagination = !!state.noPagination;
  obj.sortKey = sortKeyToDbFunc(state.sort.key, isClient);
  obj.sortDirection = state.sort.order === 1 ? 'asc' : 'desc';
  return obj;
}

function createFilterFromObject(obj, dateField) {
  obj = obj || {};
  obj.filterByStatus = obj.filterByStatus || 'all';
  obj.filterByPeriod = obj.filterByPeriod || 'today';
  obj.filterBySource = obj.filterBySource || 'all';
  obj.filterByPeriodStart = obj.filterByPeriodStart || null;
  obj.filterByPeriodEnd = obj.filterByPeriodEnd || null;
  obj.page = obj.page || 1;
  obj.pageSize = obj.pageSize || 10;
  return createFilterCondition(obj, dateField);
}

export const getAllV2 = (type, dateField, filterObj = null, setStatus = null) => {
  return (dispatch) => {
    axiosClient
      .post(`${baseUrl}/v2/${type.uri}/get-all`, createFilterFromObject(filterObj, dateField), {
        headers: {
          page: JSON.parse(localStorage.getItem('Page')),
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ ...dispatchSuccessV2(`GET_ALL_${type.tag}`, res) });
          setStatus && setStatus('OK');
        } else {
          setStatus && setStatus('ERROR');
        }
      })
      .catch(() => {
        dispatch({ ...dispatchError(`GET_ALL_${type.tag}`) });
        setStatus && setStatus('ERROR');
      });
  };
};

export const getAll = (type, setStatus = null) => {
  return (dispatch) => {
    axiosClient
      .get(`${baseUrl}/${type.uri}`, {
        headers: {
          page: JSON.parse(localStorage.getItem('Page')),
        }})
      .then((res) => {
        if (res.status === 200) {
          dispatch({ ...dispatchSuccess(`GET_ALL_${type.tag}`, res) });
          setStatus && setStatus('OK');
        } else {
          setStatus && setStatus('ERROR');
        }
      })
      .catch(() => {
        dispatch({ ...dispatchError(`GET_ALL_${type.tag}`) });
        setStatus && setStatus('ERROR');
      });
  };
};

export const getClientOrders = (clientId, pageNumber, setStatus = null) => {
  return (dispatch) => {
    axiosClient
      .post(`${baseUrl}/v2/Orders/get-client-orders/${clientId}/${pageNumber}`)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          dispatch({ ...dispatchSuccessV2(`GET_ALL_ORDERS`, res) });
          setStatus && setStatus('OK');
        } else {
          setStatus && setStatus('ERROR');
        }
      })
      .catch(() => {
        dispatch({ ...dispatchError(`GET_ALL_ORDERS`) });
        setStatus && setStatus('ERROR');
      });
  };
};

export const getAllPromotions = (type, pageId, data, setStatus = null) => {
  return (dispatch) => {
    const headers = {
      page: pageId,
    };
    axiosClient
      .post(`${baseUrl}/${type.uri}/get-all`, data, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ ...dispatchSuccessV2(`GET_ALL_${type.tag}`, res) });
          setStatus && setStatus('OK');
        } else {
          setStatus && setStatus('ERROR');
        }
      })
      .catch((e) => {
        dispatch({ ...dispatchError(`GET_ALL_${type.tag}`) });
        setStatus && setStatus('ERROR');
      });
  };
};

export const getAllVouchers = (
  type,
  pageId,
  data = { pageIndex: 1, pageSize: 9000 },
  setStatus = null,
) => {
  return (dispatch) => {
    const headers = {
      page: pageId,
    };
    const { pageIndex, pageSize } = data;
    axiosClient
      .get(`${baseUrl}/${type.uri}/${pageIndex}/${pageSize}/${pageId}`, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ ...dispatchSuccessV2(`GET_ALL_${type.tag}`, res) });
          setStatus && setStatus('OK');
        } else {
          setStatus && setStatus('ERROR');
        }
      })
      .catch((e) => {
        dispatch({ ...dispatchError(`GET_ALL_${type.tag}`) });
        setStatus && setStatus('ERROR');
      });
  };
};

export const getValidVoucher = (voucherCode, setStatus = null) => {
  return (dispatch) => {
    axiosClient
      .get(`${baseUrl}/Voucher/validate-voucher-code/${voucherCode}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch({ ...dispatchSuccess(`GET_VALID_VOUCHER`, res) });
          setStatus && setStatus('OK');
        } else {
          setStatus && setStatus('ERROR');
        }
      })
      .catch((e) => {
        dispatch({ ...dispatchError(`GET_VALID_VOUCHER`) });
        setStatus && setStatus('ERROR');
      });
  };
};

export const getAllPaginated = (type, setStatus = null) => {
  return async (dispatch) => {
    let page = 1;
    const pageSize = 100;
    let totalCount = 0;
    const results = [];
    do {
      try {
        const res = await axiosClient.get(`${baseUrl}/${type.uri}/${page}/${pageSize}`);
        if (res.status !== 200) {
          setStatus && setStatus('ERROR');
          break;
        }
        totalCount = res.data.rowCount;
        results.push(...res.data.results);
        page += 1;
      } catch {
        dispatch({ ...dispatchError(`GET_ALL_${type.tag}`) });
        setStatus && setStatus('ERROR');
        break;
      }
    } while (results.length < totalCount);
    dispatch({ ...dispatchSuccess(`GET_ALL_${type.tag}`, { data: results }) });
    setStatus && setStatus('OK');
  };
};

export const getById = (type, id, setStatus = null) => {
  return (dispatch) => {
    axiosClient
      .get(`${baseUrl}/${type.uri}/${id}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch({ ...dispatchSuccess(`GET_${type.tag}_BY_ID`, res) });
          setStatus && setStatus('OK');
        } else {
          setStatus && setStatus('ERROR');
        }
      })
      .catch(() => {
        dispatch({ ...dispatchError(`GET_${type.tag}_BY_ID`) });
        setStatus && setStatus('ERROR');
      });
  };
};

export const getByPageId = (type, id, setStatus = null) => {
  return (dispatch) => {
    axiosClient
      .get(`${baseUrl}/${type.uri}/get-by-pageId/${id}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch({ ...dispatchSuccess(`GET_${type.tag}_BY_ID`, res) });
          setStatus && setStatus('OK');
        } else {
          setStatus && setStatus('ERROR');
        }
      })
      .catch(() => {
        dispatch({ ...dispatchError(`GET_${type.tag}_BY_ID`) });
        setStatus && setStatus('ERROR');
      });
  };
};

export const add = (type, data, setStatus = null) => {
  const request = {
    ...data,
    promotionType: parseInt(data.promotionType),
    discountType: parseInt(data.discountType),
  };
  return (dispatch) => {
    axiosClient
      .post(`${baseUrl}/${type.uri}`, request)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          dispatch({ ...dispatchSuccess(`POST_${type.tag}`, res) });
          setStatus && setStatus('OK', res.data);
        } else {
          setStatus && setStatus('ERROR', null, res.data);
        }
      })
      .catch((e) => {
        dispatch({ ...dispatchError(`POST_${type.tag}`) });
        setStatus && setStatus('ERROR', null, e.response.data);
      });
  };
};

export const edit = (id, type, data, setStatus = null) => {
  return (dispatch) => {
    axiosClient
      .put(`${baseUrl}/${type.uri}/${id}`, data)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          dispatch({ ...dispatchSuccess(`PUT_${type.tag}`, res) });
          setStatus && setStatus('OK', res.data);
        } else {
          setStatus && setStatus('ERROR', null, res.data);
        }
      })
      .catch((e) => {
        dispatch({ ...dispatchError(`PUT_${type.tag}`) });
        setStatus && setStatus('ERROR', null, e.error);
      });
  };
};

export const remove = (id, type, setStatus = null) => {
  return (dispatch) => {
    axiosClient
      .delete(`${baseUrl}/${type.uri}/${id}`)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          dispatch({ ...dispatchSuccess(`DELETE_${type.tag}`, { data: id }) });
          setStatus && setStatus('OK');
        } else {
          setStatus && setStatus('ERROR');
        }
      })
      .catch(() => {
        dispatch({ ...dispatchError(`DELETE_${type.tag}`) });
        setStatus && setStatus('ERROR');
      });
  };
};

function toReportTime(time) {
  switch (time) {
    case 'all':
      return 'total';
    case 'today':
      return 'today';
    case 'yesterday':
      return 'yesterday';
    case 'last-week':
      return 'week';
    case 'last-month':
      return 'month';
    case 'total':
    default:
      return time;
  }
}

export const getReports = (obj, setStatus = null) => {
  return (dispatch) => {
    if (obj.time === 'custom') {
      const startDate = moment.utc(obj.startDate).format('yyyy-MM-DD HH:mm:ss');
      const endDate = moment.utc(obj.endDate || Date.now()).format('yyyy-MM-DD HH:mm:ss');
      obj.time += `?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(
        endDate,
      )}`;
    }
    axiosClient
      .get(`${baseUrl}/v2/Report/${toReportTime(obj.time)}`)
      .then((res) => {
        dispatch({ ...dispatchSuccess(`REPORT`, res) });
        setStatus && setStatus('OK');
      })
      .catch(() => {
        dispatch({ ...dispatchError(`REPORT`) });
        setStatus && setStatus('ERROR');
      });
  };
};

export const getAllTop = (type, setStatus = null) => {
  return (dispatch) => {
    axiosClient
      .get(`${baseUrl}/v2/${type.uri}/get-top`)
      .then((res) => {
        if (res.status === 200) {
          dispatch({ ...dispatchSuccess(`GET_ALL_TOP_${type.tag}`, res) });
          setStatus && setStatus('OK');
        } else {
          setStatus && setStatus('ERROR');
        }
      })
      .catch(() => {
        dispatch({ ...dispatchError(`GET_ALL_${type.tag}`) });
        setStatus && setStatus('ERROR');
      });
  };
};

export const getCategoriesV2 = (type, pageId, data, setStatus = null) => {
  return (dispatch) => {
    const headers = {
      page: pageId,
    };
    axiosClient
      .post(`${baseUrl}/v2/${type.uri}/get-all`, data, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ ...dispatchSuccessV2(`GET_ALL_${type.tag}_V2`, res) });
          setStatus && setStatus('OK');
        } else {
          setStatus && setStatus('ERROR');
        }
      })
      .catch(() => {
        dispatch({ ...dispatchError(`GET_ALL_${type.tag}`) });
        setStatus && setStatus('ERROR');
      });
  };
};

export const getClientPromotions = (type, phone) => {
  return api.get(`${baseUrl}/v2/${type.uri}/promo/${phone}`);
};

export const getAllPromotionsForClient = (type, pageId) => {
  return (dispatch) => {
    api
      .get(`${baseUrl}/v2/${type.uri}/page/${pageId}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch({ ...dispatchSuccess(`SET_${type.tag}`, res) });
        }
      })
      .catch((e) => {
        dispatch({ ...dispatchError(`SET_${type.tag}`) });
      });
  };
};
