import axios from 'axios';
import * as https from 'https';

const agent = new https.Agent({
  rejectUnauthorized: false, // This allows the agent to accept self-signed certificates
});

export const buildAxiosInstance = (baseUrl) => {
  return axios.create({
    baseURL: baseUrl,
    httpsAgent: agent,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${localStorage.getItem('authToken')}`,
    },
  });
};

export const buildAxiosWithoutToken = (baseUrl) => {
  return axios.create({
    baseURL: baseUrl,
    httpsAgent: agent,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
};
