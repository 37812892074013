import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import styled from 'styled-components';
import Spinner from 'app/shared/views/spinner';

import { getBillById } from '../../../actions/shoppingActions';

const PaymentDetailsWrapper = styled.div`
  height: 100vh;
  display: flex !important;
  flex-direction: column !important;
  background-color: #ffffff !important;
  position: relative !important;
  bottom: 0 !important;
  width: 100vw !important;
  max-width: 500px !important;
  margin: auto;
  @media (min-width: 500px) {
    border: 1px #e8e9f3 solid;
  }
`;

const StyledHeader = styled.div`
  width: 100%;
  position: sticky !important;
  top: 0 !important;
  background-color: #ffffff !important;
  display: flex !important;
  flex-direction: row;
  justify-content: center !important;
  align-items: center;
  padding: 30px 20px 0;
  z-index: 10;
`;

const OverflowContainer = styled.div`
  height: 70vh;
  overflow-y: scroll !important;
`;

const StyledBody = styled.div`
  width: 100%;
  flex: 1;
  z-index: 5;
`;

const PaymentStatusTitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  flex: 2;
  color: ${(props) => props.color};
  width: 100%;
`;

const OrderRow = styled.div`
  background-color: ${(props) => (props.index % 2 === 0 ? '#F9FAFC' : '#FFFFFF')};
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const OrderRowProduct = styled.div`
  padding: ${(props) => (props.isCombo ? '0 20px 10px' : '10px 20px')};
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const OrderHeaderText = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  width: 100%;
  position: sticky;
  top: 0;
  padding: 20px 20px 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2a2e45;
`;

const ProductName = styled.div`
  flex: 2;
  margin-left: ${(props) => (props.isCombo ? '8px' : 0)};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #1d2036;
  line-height: 20px;
`;
const ProductTotal = styled.div`
  flex: 1;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1d2036;
`;
const ProductQty = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #5c677b;
  flex: 1;
  text-align: center;
  margin-right: ${(props) => (props.isCombo ? '4px' : 0)};
`;
const ProductPrice = styled.div`
  flex: 1;
  text-align: right;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #2ecc71;
`;

const BillingInformation = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px 24px 20px;
  margin-top: 12px;
`;
const BillingInformationHeader = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  color: #2a2e45;
  padding-bottom: 20px;
`;
const BillingInformationRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0;
`;
const BillingInformationLabel = styled.div`
  flex: 1;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #1d2036;
`;
const BillingInformationValue = styled.div`
  flex: 2;
  text-align: right;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #2a2e45;
`;

const ORDER_STATUS = {
  ORDER_REQUESTED: 0,
  ORDER_ACCEPTED: 1,
  ORDER_DELIVERING: 2,
  ORDER_CLOSED: 3,
  ORDER_PAYMENT_PROGRESS: 4,
  ORDER_CANCELLED: 5,
  ORDER_WAITING_CONFIRMATION: 6,
};

const FinalBill = () => {
  const [orderInfo, setOrderInfo] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(getBillById(setOrderInfo, params.id));
  }, [params.id, dispatch]);

  return (
    <PaymentDetailsWrapper>
      <OverflowContainer>
        <StyledHeader
          style={
            orderInfo.orderStatus === ORDER_STATUS.ORDER_REQUESTED
              ? { marginTop: 12 }
              : { flexDirection: 'column' }
          }
        >
          <PaymentStatusTitle
            color={
              orderInfo.orderStatus === ORDER_STATUS.ORDER_REQUESTED
                ? '#2A2E45'
                : orderInfo.orderStatus === ORDER_STATUS.ORDER_CANCELLED
                ? '#FF3A44'
                : '#2ECC71'
            }
          >
            {orderInfo.orderStatus === ORDER_STATUS.ORDER_REQUESTED && 'Order Requested'}
            {orderInfo.orderStatus === ORDER_STATUS.ORDER_WAITING_CONFIRMATION &&
              'Waiting Confirmation'}
            {orderInfo.orderStatus === ORDER_STATUS.ORDER_ACCEPTED && 'Order Accepted'}
            {orderInfo.orderStatus === ORDER_STATUS.ORDER_CANCELLED && 'Order Rejected'}
            {orderInfo.orderStatus === ORDER_STATUS.ORDER_DELIVERING && 'Delivery in Progress'}
            {orderInfo.orderStatus === ORDER_STATUS.ORDER_CLOSED && 'Order Delivered'}
          </PaymentStatusTitle>
          {orderInfo.orderStatus === ORDER_STATUS.ORDER_REQUESTED ||
          orderInfo.orderStatus === ORDER_STATUS.ORDER_WAITING_CONFIRMATION ? (
            <Spinner
              size={'60px'}
              color={'#2ECC71'}
              style={{
                position: 'absolute',
                right: 0,
                top: -12,
              }}
            />
          ) : null}
        </StyledHeader>
        <BillingInformation>
          <BillingInformationHeader>Billing Information</BillingInformationHeader>
          <BillingInformationHeader>{orderInfo.pageName}</BillingInformationHeader>
          <BillingInformationRow span={24} style={{ display: 'flex', paddingBottom: 8 }}>
            <BillingInformationLabel>Order No:</BillingInformationLabel>
            <BillingInformationValue>{orderInfo.orderCounter}</BillingInformationValue>
          </BillingInformationRow>
          <BillingInformationRow span={24} style={{ display: 'flex', paddingBottom: 8 }}>
            <BillingInformationLabel>Date:</BillingInformationLabel>
            <BillingInformationValue>
              {moment(orderInfo.orderDate).format('Do MMMM YYYY, h:mm:ss a')}
            </BillingInformationValue>
          </BillingInformationRow>
          <BillingInformationRow span={24} style={{ display: 'flex', paddingBottom: 8 }}>
            <BillingInformationLabel>Phone Number:</BillingInformationLabel>
            <BillingInformationValue>{orderInfo.clientPhone}</BillingInformationValue>
          </BillingInformationRow>
          <BillingInformationRow span={24} style={{ display: 'flex', paddingBottom: 8 }}>
            <BillingInformationLabel>Address:</BillingInformationLabel>
            <BillingInformationValue>{orderInfo.orderLocation}</BillingInformationValue>
          </BillingInformationRow>
          {orderInfo.orderLocation2 ? (
            <BillingInformationRow span={24} style={{ display: 'flex', paddingBottom: 8 }}>
              <BillingInformationLabel>Address Details:</BillingInformationLabel>
              <BillingInformationValue>{orderInfo.orderLocation2}</BillingInformationValue>
            </BillingInformationRow>
          ) : null}
          {orderInfo.customerDescription ? (
            <BillingInformationRow span={24} style={{ display: 'flex', paddingBottom: 8 }}>
              <BillingInformationLabel>Order Details:</BillingInformationLabel>
              <BillingInformationValue>{orderInfo.customerDescription}</BillingInformationValue>
            </BillingInformationRow>
          ) : null}
        </BillingInformation>
        <StyledBody>
          <OrderHeaderText>Order</OrderHeaderText>
          {orderInfo.orderProducts &&
            orderInfo.orderProducts.map((item, index) => (
              <OrderRow key={item.id} index={index}>
                <OrderRowProduct>
                  <ProductName>{item.name}</ProductName>
                  <ProductQty>{item.qty}</ProductQty>
                  <ProductPrice>
                    {item.price % 1 === 0
                      ? (item.price = parseInt(item.price, 10)) * item.qty
                      : item.price * item.qty}
                    {' ' + orderInfo.currency}
                  </ProductPrice>
                </OrderRowProduct>
                {item.isCombo &&
                  item.toppings.map((topping) => (
                    <OrderRowProduct
                      key={`${item.id}${topping.id.toString()}`}
                      isCombo={item.isCombo}
                    >
                      <ProductName isCombo={item.isCombo}>{topping.name}</ProductName>
                      <ProductQty isCombo={item.isCombo}>{topping.qty}</ProductQty>
                      <ProductPrice>
                        {topping.price % 1 === 0
                          ? (topping.price = parseInt(topping.price, 10)) * topping.qty
                          : topping.price * topping.qty}
                        {' ' + orderInfo.currency}
                      </ProductPrice>
                    </OrderRowProduct>
                  ))}
              </OrderRow>
            ))}
        </StyledBody>
      </OverflowContainer>
      <OrderRow
        style={{
          backgroundColor: '#fff',
          paddingBottom: 50,
          bottom: 0,
          position: 'sticky',
        }}
      >
        <OrderRow
          style={{
            borderTop: '1px solid #F9FAFC',
            padding: '10px 0',
            margin: '0 20px',
            width: 'calc(100% - 40px)',
            flexDirection: 'row',
          }}
        >
          <ProductName>Delivery Fee:</ProductName>
          <ProductPrice>
            {orderInfo.deliveryFee % 1 === 0
              ? (orderInfo.deliveryFee = parseInt(orderInfo.deliveryFee, 10))
              : orderInfo.deliveryFee}
            {' ' + orderInfo.currency}
          </ProductPrice>
        </OrderRow>
        <OrderRow
          style={{
            flexDirection: 'row',
            width: 'calc(100% - 40px)',
            paddingTop: '20px',
          }}
        >
          <ProductTotal>Total:</ProductTotal>
          <ProductPrice style={{ fontWeight: 'bold' }}>
            {orderInfo.totalValue % 1 === 0
              ? (orderInfo.totalValue = parseInt(orderInfo.totalValue, 10))
              : orderInfo.totalValue}
            {' ' + orderInfo.currency}
          </ProductPrice>
        </OrderRow>
      </OrderRow>
    </PaymentDetailsWrapper>
  );
};

export default FinalBill;
