import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import shared from 'app/shared';
import {NavLink} from 'react-router-dom';
import ViewClient from '../../clients/views/client.page';

import {
  getTopNewClients,
  getTopPayingClients,
  getTopInactiveClients
} from '../../../../actions/adminActions';

const {
  views: { Card, TabBar, Table, Button },
  helpers: { colors, currencyFormatter },
} = shared;

const TopBarCard = styled(Card)`
  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }

  @media all and (max-width: 768px) {
    display: none;
  }
`;

const MobileCard = styled(Card)`
  display: none;

  @media all and (max-width: 1024px) {
    display: block;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const tabs = [
  {
    text: 'New Clients',
    value: 'newClients',
  },
  {
    text: 'Top Paying Clients',
    value: 'topPayingClients',
  },
  {
    text: 'Inactive Clients',
    value: 'inactiveClients',
  },
];

const getHeader = (currentTab) => {
  if(currentTab === 'inactiveClients'){
    return [
      {
        label: 'ID',
        key: 'ID',
        sortable: false,
        renderer: ({ positionNo }) => positionNo,
      },
      {
        label: 'FULL NAME',
        key: 'clientName',
        sortable: false,
        renderer: ({ fullName }) => fullName,
      },
      {
        label: 'ADMIN',
        key: 'admin',
        sortable: false,
        renderer: ({ admin }) => admin,
      },
      {
        label: 'LOCATION',
        key: 'location',
        renderer: ({ location }) => location,
        sortable: false,
      },  
      {
        label: 'INACTIVE START DATE',
        key: 'orderDate',
        sortable: false,
        renderer: ({ dateCreated }) => dateCreated
      },
      {
        label: 'ACTION',
        width: '70px',
        key: 'action',
        sortable: false,
        renderer: ({ id }) => {
          return (
            <NavLink to={`${ViewClient.path}/${id}`} >
              <Button.Button
                style={{ fontWeight: 600 }}
                text={"View"}
                size={Button.ButtonSize.medium}
                color={colors.blue}
              />
            </NavLink>
          );
        },
      },
    ]
  }
  else if(currentTab === 'topPayingClients'){
    return [
      {
        label: 'ID',
        key: 'ID',
        sortable: false,
        renderer: ({ positionNo }) => positionNo,
      },
      {
        label: 'FULL NAME',
        key: 'clientName',
        sortable: false,
        renderer: ({ fullName }) => fullName,
      },
      {
        label: 'ADMIN',
        key: 'admin',
        sortable: false,
        renderer: ({ admin }) => admin,
      },
      {
        label: 'LOCATION',
        key: 'location',
        renderer: ({ location }) => location,
        sortable: false,
      },
      {
        label: 'TOTAL PAID',
        key: 'totalSubscriptionPaid',
        sortable: false,
        renderer: ({ totalSubscriptionPaid }) => currencyFormatter(totalSubscriptionPaid)
      },    
      {
        label: 'DATE CREATED',
        key: 'orderDate',
        sortable: false,
        renderer: ({ dateCreated }) => dateCreated
      },
      {
        label: 'ACTION',
        width: '70px',
        key: 'action',
        sortable: false,
        renderer: ({ id }) => {
          return (
            <NavLink to={`${ViewClient.path}/${id}`} >
              <Button.Button
                style={{ fontWeight: 600 }}
                text={"View"}
                size={Button.ButtonSize.medium}
                color={colors.blue}
              />
            </NavLink>
          );
        },
      },
    ]   
  }
  else {
    return [
      {
        label: 'ID',
        key: 'ID',
        sortable: false,
        renderer: ({ positionNo }) => positionNo,
      },
      {
        label: 'FULL NAME',
        key: 'clientName',
        sortable: false,
        renderer: ({ fullName }) => fullName,
      },
      {
        label: 'ADMIN',
        key: 'admin',
        sortable: false,
        renderer: ({ admin }) => admin,
      },
      {
        label: 'LOCATION',
        key: 'location',
        renderer: ({ location }) => location,
        sortable: false,
      },  
      {
        label: 'DATE CREATED',
        key: 'orderDate',
        sortable: false,
        renderer: ({ dateCreated }) => dateCreated
      },
      {
        label: 'ACTION',
        width: '70px',
        key: 'action',
        sortable: false,
        renderer: ({ id }) => {
          return (
            <NavLink to={`${ViewClient.path}/${id}`} >
              <Button.Button
                style={{ fontWeight: 600 }}
                text={"View"}
                size={Button.ButtonSize.medium}
                color={colors.blue}
              />
            </NavLink>
          );
        },
      },
    ]   
  }
}

const getHeaderTablet = (currentTab) => {
  return [
    {
      label: 'ID',
      key: 'ID',
      sortable: false,
      renderer: ({ positionNo }) => positionNo,
    },
    {
      label: 'FULL NAME',
      key: 'clientName',
      sortable: false,
      renderer: ({ fullName }) => fullName,
    },
    {
      label: 'ACTION',
      width: '70px',
      key: 'action',
      sortable: false,
      renderer: ({ id }) => {
        return (
          <NavLink to={`${ViewClient.path}/${id}`} >
            <Button.Button
              style={{ fontWeight: 600 }}
              text={"View"}
              size={Button.ButtonSize.medium}
              color={colors.blue}
            />
          </NavLink>
        );
      },
    },    
  ];
}

const TopSection = (props) => {
  const [currentTab, updateCurrentTab]
    = useState('newClients');

  useEffect(() => {
    props[currentTab]();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const getTopData = () => {
    const values = { ...props.topClients };

    values.data.forEach((item, index) => {
      item.positionNo = ++index;
    });

    return values.data;
  }

  const getTableData = (header) => {
    return {
        header: header,
        values: getTopData(),
    }
  };

  return (
    <TopWrapper>
      <TabBar
        tabs={tabs}
        selectedElement={currentTab}
        updateSelectedElement={value => updateCurrentTab(value)}/>
      <TopBarCard style={{ marginTop: 10 }}>
        <Table.TableView data={getTableData(getHeader(currentTab))} />
      </TopBarCard>
      <MobileCard style={{ marginTop: 10 }}>
        <Table.TableView data={getTableData(getHeaderTablet(currentTab))} />
      </MobileCard>
    </TopWrapper>
  );
};

const mapStateToProps = state => ({
  topClients: state.adminTopClients
});

const mapDispatchToProps = dispatch => ({
  'newClients':
    () => dispatch(getTopNewClients()),
  'topPayingClients':
    () => dispatch(getTopPayingClients()),
  'inactiveClients':
    () => dispatch(getTopInactiveClients()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopSection)

export {TopSection};
