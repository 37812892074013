import Url from '../config/api';
import { getActivePageSubscription } from './subscriptionActions';
import { getNotifications } from './notificationAction';
import { getPageConfiguration } from './pageActions';
import { buildAxiosInstance } from 'actions/common';

const { baseUrl } = Url;

const axiosClient = buildAxiosInstance(baseUrl);

const baseURI = `${baseUrl}/Pages`;

export const getFacebookPages = (setStatus = null) => {
  return (dispatch) => {
    axiosClient
      .get(`${baseURI}`)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
          localStorage.setItem('Pages', JSON.stringify(res.data));
          dispatch({
            type: `GET_FB_PAGES_OK`,
            data: res.data,
          });
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
        dispatch({ type: 'GET_FB_PAGES_ERROR' });
      });
  };
};

export const setActivePage = (id, setStatus = null) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_ACTIVE_PAGE_WAIT',
    });
    const guestCheckoutId = getState().subscriptions.guestCheckoutId;
    const uri =
      `${baseURI}/${id}` + (guestCheckoutId ? `?guest_checkout_id=${guestCheckoutId}` : '');
    axiosClient
      .get(uri)
      .then((res) => {
        setStatus && setStatus('OK');
        dispatch(setActivePageOk(res.data));

        dispatch(getPageConfiguration(id, 'receiptConfiguration'));
        dispatch(getPageConfiguration(id, 'pageAdminConfiguration'));
        dispatch(getPageConfiguration(id, 'PUBLIC_onlineOrderIframeConfiguration'));
        dispatch(getPageConfiguration(id, 'PUBLIC_tableOrderIframeConfiguration'));
        dispatch(getActivePageSubscription(id));
        dispatch(getNotifications());
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
        dispatch({ type: 'SET_ACTIVE_PAGE_ERROR' });
      });
  };
};

export const updateActivePage = (id, setStatus) => {
  return (dispatch) => {
    axiosClient
      .get(`${baseURI}/${id}`)
      .then((res) => {
        setStatus && setStatus('OK');
        dispatch(setActivePageOk(res.data));
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
        dispatch({ type: 'SET_ACTIVE_PAGE_ERROR' });
      });
  };
};

export const setActivePageOk = (page) => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_ACTIVE_PAGE_OK', data: page });
    dispatch({ type: 'PAGE_INFO_OK', data: page });
    localStorage.setItem('Page', JSON.stringify(page.id));
    localStorage.setItem('Pages', JSON.stringify(getState().facebookPages.data));
  };
};

export const setLocalActivePages = () => {
  return (dispatch) => {
    const pages = JSON.parse(localStorage.getItem('Pages'));
    pages &&
      dispatch({
        type: 'SET_LOCAL_ACTIVE_PAGES_OK',
        pages,
      });
  };
};
