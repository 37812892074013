import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import shared from 'app/shared';
import crudTypes from 'config/crudTypes';
import { getReports, getAll, getAllTop } from 'actions/crudActions';
import StatsSection from './statsSection';
import TopSection from './topSection';
import MobileFilter from '../../shared/views/mobileFilter/mobileFilter';

const {
  views: { Page, SimpleFilterBar },
} = shared;

const initialState = {
  filters: {
    selectedPeriod: 'today',
  },
  selectedStatCard: 'revenue',
  filteredData: [],
  customDate: { startDate: null, endDate: null },
  currentTab: 'topOrders',
};

const StatsSectionWrapper = styled.div`
  margin-top: 30px;
`;

const TopSectionWrapper = styled.div`
  margin-top: 60px;
  @media all and (max-width: 768px) {
    margin-top: 0;
  }
`;

class DashboardPage extends Component {
  state = initialState;

  componentWillMount() {
    this.props.getReports({time: this.state.filters.selectedPeriod, ...this.state.customDate})

    if (this.state.currentTab === 'topOrders') {
      this.props.getAllTop(crudTypes.order);
    } else if (this.state.currentTab === 'topClients') {
      this.props.getAllTop(crudTypes.client);
    } else if (this.state.currentTab === 'topProducts') {
      this.props.getAllTop(crudTypes.product);
    }
  }



  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentTab !== this.state.currentTab) {
      if (this.state.currentTab === 'topOrders') {
        this.props.getAllTop(crudTypes.order);
      } else if (this.state.currentTab === 'topClients') {
        this.props.getAllTop(crudTypes.client);
      } else if (this.state.currentTab === 'topProducts') {
        this.props.getAllTop(crudTypes.product);
      }
    }
  }

  updateCurrentTab = (value) => {
    this.setState({
      currentTab: value,
    })
    console.log(this.state.currentTab);
  }

  updateSelectedPeriod = (selectedPeriod) => {
    if (selectedPeriod !== 'custom') {
      this.setState({
        customDate: {
          startDate: null,
          endDate: null,
        },
      });
    }
    this.setState({ filters: { selectedPeriod } }, () => {
      this.props.getReports({ time: this.state.filters.selectedPeriod, ...this.state.customDate });
    });
  };

  updateCustomDate = (customDate) => {
    this.setState(
      { customDate: { startDate: customDate.startDate, endDate: customDate.endDate } },
      () => {
        this.props.getReports({
          time: this.state.filters.selectedPeriod,
          ...this.state.customDate,
        });
      },
    );
  };
  clearFilters = () => {
    this.setState({ filters: initialState.filters });
    this.setState({ customDate: { startDate: null, endDate: null } }, () => {
      this.props.getReports({ time: this.state.filters.selectedPeriod, ...this.state.customDate });
    });
  };

  updateSelectedStatCard = (selectedStatCard) => {
    this.setState({ selectedStatCard });
  };

  render() {

    const {
      state: {
        filters: { selectedPeriod },
        selectedStatCard,
        customDate,
      },
      updateSelectedPeriod,
      clearFilters,
      updateSelectedStatCard,
      updateCustomDate,
      updateCurrentTab,
    } = this;

    return (
      <Page>
        <SimpleFilterBar
          selectedPeriod={selectedPeriod}
          updateSelectedPeriod={updateSelectedPeriod}
          clearFilters={clearFilters}
          updateCustomDate={updateCustomDate}
          customDate={customDate}
        />
        <MobileFilter
          selectedPeriod={selectedPeriod}
          updateSelectedPeriod={updateSelectedPeriod}
          clearFilters={clearFilters}
        />
        <StatsSectionWrapper>
          <StatsSection
            selectedElement={selectedStatCard}
            updateSelectedElement={updateSelectedStatCard}
            chartData={this.props.reports[selectedStatCard] || []}
            totalOrders={this.props.reports.total.orderCount}
            totalRevenue={this.props.reports.total.revenue}
            totalClients={this.props.reports.total.clientCount}
            selectedPeriod={selectedPeriod}
            customDates={customDate}
          />
        </StatsSectionWrapper>
        <TopSectionWrapper>
          <TopSection {...this.props.top} updateTab={updateCurrentTab} />
        </TopSectionWrapper>
      </Page>
    );
  }
}

DashboardPage.path = '/dashboard';

const mapStateToProps = (state) => ({
  reports: state.reports,
  top: state.top
});

export default connect(mapStateToProps, { getAll, getAllTop, getReports })(DashboardPage);
