import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import icons from 'app/shared/views/icons';

export const ErrorWrapper = styled.div`
  // color: #f2453d;
  // font-size: 11px;
  // font-weight: 400;
  // line-height: 18px;
  // border-radius: 3px;
  // align-items: center;
  // letter-spacing: 1px;
  // font-weight: 600;
  // padding: 1px 5px;
  // display: flex;
  // width: 100%;
  // margin-top: 1px;

  background: #f9fafc;
  padding: 20px 30px;
  display: ${(props) => (props.show === false ? 'none' : 'flex')};
  align-items: center;

  & > :nth-child(n-1) {
    padding-right: 10px;
  }

  & .message {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #ff3a44;
  }
`;

const ErrorMessage = ({ message, ...rest }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (message) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [message]);

  return (
    <ErrorWrapper show={show} {...rest}>
      <icons.WarningIcon width="16" height="16" fill="#FF3A44" />
      <span className="message">{message}</span>
    </ErrorWrapper>
  );
};

export default ErrorMessage;
