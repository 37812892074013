import Url from '../config/api';
import { buildAxiosInstance } from 'actions/common';

const { baseUrl } = Url;
const axiosClient = buildAxiosInstance(baseUrl);

const baseURI = `${baseUrl}/Categories`;

export const linkProductTopping = (toppingcatId, prodId, pageId, setStatus = null) => {
  return (dispatch) => {
    axiosClient
      .get(`${baseURI}/linkproducttopping/${toppingcatId}/${prodId}/${pageId}`)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
          console.log('res', res);
          // dispatch({
          //     type: 'ORDER_REFUND_OK',
          //     data: res.data,
          // });
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
        dispatch({ type: 'LINK_PROD_TOPPING_ERROR' });
      });
  };
};

export const unLinkProductTopping = (toppingcatId, prodId, pageId, setStatus = null) => {
  return (dispatch) => {
    axiosClient
      .get(`${baseURI}/unlinkproducttopping/${toppingcatId}/${prodId}/${pageId}`)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
          // dispatch({
          //     type: 'ORDER_REFUND_OK',
          //     data: res.data,
          // });
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
        dispatch({ type: 'UNLINK_PROD_TOPPING_ERROR' });
      });
  };
};

export const updateProductArrangement = ({ categoryId, oldIndex, newIndex }, setStatus = null) => {
  return (dispatch, getState) => {
    if (oldIndex === newIndex) return;

    dispatch({
      type: 'UPDATE_PRODUCT_ARRANGEMENT',
      data: { categoryId, oldIndex, newIndex },
    });
    const category = getState().categories.data.find((x) => x.id === categoryId);
    const newArrangement = category.magProducts.map((x) => x.id);
    axiosClient
      .put(`${baseURI}/UpdateProductArrangement`, {
        categoryId,
        newArrangement,
      })
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
      });
  };
};

export const updateCategoryArrangement = (
  { categoryType, oldIndex, newIndex },
  setStatus = null,
) => {
  return (dispatch, getState) => {
    const pageId = getState().facebookPages.activePage?.id;
    if (!pageId) return;
    if (oldIndex === newIndex) return;

    dispatch({
      type: 'UPDATE_CATEGORY_ARRANGEMENT',
      data: { categoryType, oldIndex, newIndex },
    });
    const newArrangement = getState()
      .categories.data.filter((x) => x.categoryType === categoryType)
      .map((x) => x.id);
    axiosClient
      .put(`${baseURI}/UpdateCategoryArrangement`, {
        pageId,
        categoryType,
        newArrangement,
      })
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
      });
  };
};

export const searchCategories = (search, setStatus = null) => (dispatch) => {
  axiosClient
    .get(`${baseURI}/Search`, { params: { search } })
    .then((res) => {
      if (res.status >= 200 && res.status <= 300) {
        dispatch({ type: 'GET_ALL_CATEGORIES_OK', data: res.data });
        setStatus && setStatus('OK');
      }
    })
    .catch(() => {
      setStatus && setStatus('ERROR');
    });
};
