import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import shared from 'app/shared';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { savePageEvent } from '../../../actions/visibilitySettingsActions';
import { DatePicker } from 'antd';
import moment from 'moment';
import { Row, Col } from 'antd';
import './AddPageEventPopUp.css';

const {
  views: { Modal, Button, Alert, AntdUploadInput, Input, InputArea, Text, Icons },
  helpers: { colors },
} = shared;

const AddItemPopupWrapper = styled.div`
  width: 75vw;
  max-width: 800px;
`;

const EventDetailsTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #3f3d56;
`;

const Label = styled.label`
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  color: #434343;
  flex: 1;
`;

const StyledInput = styled(Input)`
  border-width: 0 0 1px 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  max-width: 300px;
`;
const StyledInputArea = styled(InputArea)`
  border-width: 0 0 1px 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  max-width: 300px;
`;

const Header = styled.div`
  display: flex;
  padding: 13px 35px;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.fog};
  height: ${(props) => props.height ?? 'unset'};
  flex: 0 0 auto;
`;

const Footer = styled.div`
  padding: 20px 30px;
  display: flex;
  flex-direction: row-reverse;
  flex: 0 0 auto;
  & > * {
    margin-left: 20px;
  }
`;

const Content = styled.div`
  padding: 20px 35px 20px;
  flex: 1 1 auto;
  overflow-y: scroll;
`;

const Title = styled(Text.H4)`
  color: ${colors.blue};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 90vh;
`;

const WarningWrapper = styled.div`
  width: 100%;
  display: flex;
  background: rgba(255, 152, 0, 0.2);
  color: #ff9800;
  margin-bottom: 10px;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  align-items: center;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 1px 5px;
  margin-top: 10px;
`;
export const AddPageEventPopup = ({
  toggle,
  show = false,
  pageEvent,
  setEventAlert,
  setHighlightId,
}) => {
  const dispatch = useDispatch();
  const [postStatus, setPostStatus] = useState('');
  const dateFormat = 'DD/MM/YYYY HH:mm:ss';

  const changePostStatus = (done) => (err, data) => {
    const status = err == null ? 'OK' : 'ERROR';
    setPostStatus(status);
    if (status === 'OK') {
      done(data);
    }
  };
  const postPageEvent = (data, done) => {
    dispatch(savePageEvent(data, changePostStatus(done)));
  };

  useEffect(() => {
    let updateStatusTimer = setTimeout(() => setPostStatus(null), 5000);
    return () => {
      clearTimeout(updateStatusTimer);
    };
  }, [postStatus]);

  const disabledStartDate = (startValue) => {
    const endValue = moment().add(-1, 'days');
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() < endValue.valueOf();
  };

  return show ? (
    <Formik
      enableReinitialize
      initialValues={{
        id: pageEvent.id || 0,
        imagePath: pageEvent.imagePath,
        title: pageEvent.title || '',
        description: pageEvent.description || '',
        date: pageEvent.date ? moment(pageEvent.date) : undefined,
        customUrl: pageEvent.customUrl,
      }}
      validationSchema={Yup.object().shape({
        imagePath: Yup.string().required('Image is required'),
        title: Yup.string()
          .required('Title is required')
          .test('len', 'Max 45 characters allowed', (val) => val?.length < 46),
        description: Yup.string()
          .required('Description is required')
          .test(
            'len',
            'Max 125 characters allowed',
            (val) => val?.length < 126 || val === null || val === undefined,
          ),
      })}
      onSubmit={(values, { resetForm }) => {
        postPageEvent({ ...values, active: true }, (pageEvent) => {
          resetForm();
          setEventAlert({
            show: true,
            message: 'You have successfully saved your changes!',
          });
          setHighlightId(pageEvent.id);
          toggle();
        });
      }}
    >
      {(innerProps) => {
        const { values, handleSubmit, setFieldValue, touched, errors } = innerProps;

        return (
          <Modal toggle={toggle} show={!!show}>
            <AddItemPopupWrapper>
              <Wrapper>
                <Header>
                  <Title>{pageEvent.id ? 'Edit Event' : 'Create Event'}</Title>
                </Header>

                <Row style={{ display: 'flex' }}>
                  <Col span={10}>
                    <AntdUploadInput
                      imageUrl={values.imagePath}
                      setImageUrl={(uri) => {
                        setFieldValue('imagePath', uri);
                      }}
                    />
                  </Col>
                  <Col span={14}>
                    <Alert show={postStatus === 'OK'} message="Update successful!" />
                    <Alert
                      type="error"
                      show={postStatus === 'ERROR'}
                      message={`Error ${values.ID ? 'editing' : 'adding'} the event!`}
                    />
                    <Alert
                      type="warning"
                      show={postStatus && postStatus !== 'ERROR' && postStatus !== 'OK'}
                      message={postStatus}
                    />
                    <Content>
                      <Row style={{ margin: '16px 0' }}>
                        <EventDetailsTitle>Event Details</EventDetailsTitle>
                        {errors.imagePath && touched.imagePath ? (
                          <WarningWrapper>
                            <Icons.WarningIcon
                              fill={colors.yellow}
                              style={{ marginRight: 10 }}
                              width={16}
                            />
                            {errors.imagePath}
                          </WarningWrapper>
                        ) : null}
                      </Row>
                      <Row style={{ display: 'flex' }}>
                        <Label>Title:</Label>
                        <div style={{ flex: 2 }}>
                          <StyledInput
                            name={'title'}
                            placeholder={'Title'}
                            onChange={(e) => setFieldValue('title', e.target.value)}
                            value={values.title}
                          />
                          {errors.title && touched.title ? (
                            <WarningWrapper>
                              <Icons.WarningIcon
                                fill={colors.yellow}
                                style={{ marginRight: 10 }}
                                width={16}
                              />
                              {errors.title}
                            </WarningWrapper>
                          ) : null}
                        </div>
                      </Row>
                      <Row style={{ display: 'flex' }}>
                        <Label>Description:</Label>
                        <div style={{ flex: 2 }}>
                          <StyledInputArea
                            name={'description'}
                            placeholder={'Add Description'}
                            onChange={(e) => setFieldValue('description', e.target.value)}
                            value={values.description}
                            rows={2}
                          />
                          {errors.description && touched.description ? (
                            <WarningWrapper>
                              <Icons.WarningIcon
                                fill={colors.yellow}
                                style={{ marginRight: 10 }}
                                width={16}
                              />
                              {errors.description}
                            </WarningWrapper>
                          ) : null}
                        </div>
                      </Row>
                      <Row style={{ display: 'flex', alignItems: 'center' }}>
                        <Label>Date/Time:</Label>
                        <div style={{ flex: 2 }}>
                          <DatePicker
                            showTime={true}
                            placeholder="Select Event Start Time"
                            disabledDate={disabledStartDate}
                            value={values.date}
                            format={dateFormat}
                            onChange={(value) => setFieldValue('date', value)}
                            onOk={(value) => setFieldValue('date', value)}
                            style={{ width: '100%' }}
                          />
                          {errors.date && touched.date ? (
                            <WarningWrapper>
                              <Icons.WarningIcon
                                fill={colors.yellow}
                                style={{ marginRight: 10 }}
                                width={16}
                              />
                              {errors.date}
                            </WarningWrapper>
                          ) : null}
                        </div>
                      </Row>
                      <Row style={{ display: 'flex' }}>
                        <Label>Custom URL:</Label>
                        <div style={{ flex: 2 }}>
                          <StyledInput
                            name={'url'}
                            placeholder={'https://your.custom/url'}
                            onChange={(e) => setFieldValue('customUrl', e.target.value)}
                            value={values.customUrl}
                            rows={2}
                          />
                          {errors.customUrl && touched.customUrl ? (
                            <WarningWrapper>
                              <Icons.WarningIcon
                                fill={colors.yellow}
                                style={{ marginRight: 10 }}
                                width={16}
                              />
                              {errors.customUrl}
                            </WarningWrapper>
                          ) : null}
                        </div>
                      </Row>
                    </Content>
                    <Footer>
                      <Button.Button
                        color={colors.oceanBlue}
                        text={'Save'}
                        onClick={handleSubmit}
                      />
                      <Button.WhiteButton
                        text={'Cancel'}
                        style={{ display: 'flex' }}
                        onClick={() => {
                          toggle();
                        }}
                      />
                    </Footer>
                  </Col>
                </Row>
              </Wrapper>
            </AddItemPopupWrapper>
          </Modal>
        );
      }}
    </Formik>
  ) : null;
};

const MemoizedAddPageEventPopup = React.memo(
  AddPageEventPopup,
  (a, b) => a.show === b.show && a.pageEvent === b.pageEvent,
);
export default MemoizedAddPageEventPopup;
