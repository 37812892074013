export const paginate = (data, currentPage = 1, pageSize = 9) => {
  const pageNumber = Math.ceil(Number(data.length) / Number(pageSize));
  const begin = (currentPage - 1) * pageSize;
  const end = begin + pageSize;
  const currentData = data.slice(begin, end);

  return {
    current: currentData,
    currentPage: currentPage,
    pages: pageNumber,
  };
};

export const paginateNoOp = (data, currentPage = 1, pageSize = 9) => {
  const pageNumber = Math.ceil(Number(data.length) / Number(pageSize));
  return {
    current: data,
    currentPage: currentPage,
    pages: pageNumber,
  };
};export const getPages = (data, pageSize) => (data && Math.ceil(data.length / pageSize)) || 1;
