import decodeJwt from 'jwt-decode';
import Url from '../config/api';
import Bugsnag from '@bugsnag/js';
import { buildAxiosWithoutToken } from 'actions/common';

const baseUrl = `${Url.baseUrl}/Auth`;

const axiosClient = buildAxiosWithoutToken(baseUrl);

export const signIn = (username, password, done) => {
  return (dispatch) => {
    axiosClient
      .post(`${baseUrl}/signin`, { username, password })
      .then((res) => {
        const token = JSON.parse(res.data.token);
        const jwtInfo = decodeJwt(token.auth_token);
        const isAdmin = jwtInfo.Role === 'MasterAdmin';
        localStorage.setItem('authToken', token.auth_token);
        const refreshToken = res.data.refreshToken;
        localStorage.setItem('refreshToken', refreshToken?.token);
        if (isAdmin) {
          localStorage.setItem('isAdmin', 1);
        }
        dispatch({
          type: 'LOGIN_USER_OK',
          token: token.auth_token,
        });
        Bugsnag.setUser(jwtInfo.id, username, null);
        done && done(null, jwtInfo, isAdmin);
      })
      .catch((err) => {
        if (err.isAxiosError) {
          err.message = err.response.data;
        }
        localStorage.removeItem('authToken');
        done && done(err);
      });
  };
};

export const signUp = (data, done) => {
  return (dispatch, getState) => {
    const guestCheckoutId = getState().subscriptions.guestCheckoutId;
    axiosClient
      .post(`${baseUrl}/signup`, { ...data, guestCheckoutId })
      .then((res) => {
        done && done(null);
        dispatch({
          type: 'REGISTER_USER_OK',
        });
      })
      .catch((err) => {
        if (err.isAxiosError) {
          err.message = err.response.data;
        }
        done && done(err);
      });
  };
};

export const forgotPassword = (email, done) => {
  return () => {
    axiosClient
      .post(`${baseUrl}/forgot-password`, { email })
      .then(() => {
        done && done(null);
      })
      .catch((err) => {
        done && done(err);
      });
  };
};

export const resetPassword = (confirmationCode, password, confirmPassword, done) => {
  return () => {
    axiosClient
      .post(`${baseUrl}/reset-password`, {
        confirmationCode,
        password,
        confirmPassword,
      })
      .then((res) => {
        done && done(null, res.data);
      })
      .catch((err) => {
        done && done(err);
      });
  };
};

export const confirmEmail = (confirmationCode, done) => {
  return () => {
    axiosClient
      .post(`${baseUrl}/confirm-email`, { confirmationCode })
      .then((res) => {
        done && done(null, res);
      })
      .catch((err) => {
        done && done(err);
      });
  };
};
