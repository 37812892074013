import axios from 'axios';
import JsZip from 'jszip';

const downloadUrl = async (url, filename, mime, bom) => {
  var instance = axios.create({
    timeout: 1000,
  });
  return instance.get(url, {
    responseType: 'arraybuffer',
  }).then((res) => {
    const blobData = typeof bom !== 'undefined' ? [bom, res.data] : [res.data]
    const blob = new Blob(blobData, { type: mime || res.headers['content-type'] || 'application/octet-stream' });
    saveFile(blob, filename)
  });
};

export const downloadAndZip = async (infos, zipFilename) => {
  if (!infos || !infos.length || !zipFilename) return;

  var instance = axios.create({
    timeout: 1000,
  });
  const archive = JsZip();
  for (let info of infos) {
    const { url, filename, mime, bom } = info;

    const res = await instance.get(url, { responseType: 'arraybuffer' });
    const blobData = typeof bom !== 'undefined' ? [bom, res.data] : [res.data];

    const blob = new Blob(blobData, { type: mime || res.headers['content-type'] || 'application/octet-stream' });
    archive.file(filename, blob);
  }
  const zipFile = await archive.generateAsync({type: 'blob'});
  saveFile(zipFile, zipFilename);
};

export const saveFile = (blob, filename) => {
  if (!blob) return;

  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', filename);

  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }

  document.body.appendChild(tempLink);
  tempLink.click();

  setTimeout(() => {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
  }, 200);
};

export default downloadUrl;
