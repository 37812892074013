import { createSelector } from 'reselect';

const selectActivePage = (state) => state.facebookPages.activePage;

export const IsOnlineOrderActive = createSelector(selectActivePage, (activePage) => {
  return activePage?.pageService?.find((service) => service.name === 'OnlineOrder')?.isActive;
});

export const IsTableOrderActive = createSelector(selectActivePage, (activePage) => {
  return activePage?.pageService?.find((service) => service.name === 'TableOrder')?.isActive;
});

export const IsReservationActive = createSelector(selectActivePage, (activePage) => {
  return activePage?.pageService?.find((service) => service.name === 'Reservation')?.isActive;
});

export const IsDeliveryActive = createSelector(selectActivePage, (activePage) => {
  return activePage?.pageService?.find((service) => service.name === 'Delivery')?.isActive;
});
