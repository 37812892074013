import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import {
  OrderCancelled,
  AwaitingConfirmation,
  PaymentSucceeded,
  PaymentDeclined,
} from 'app/shared/views/shopping/steps/PaymentDetails';
import { ORDER_STATUS } from 'app/orders/constants';

import {
  getOrderById,
  saveOrder,
  updateOrderPaymentMethod,
  setPaymentMethod,
  startNewInternalOrder,
} from 'actions/shoppingActions';
import ContextLayout, { ShoppingContext } from './Context';

import shared from 'app/shared';
import 'app/shared/views/shopping/assets/global.css';

const {
  views: {
    Shopping: { ChooseProducts, LocationDetails, Stepper, Constants },
    Card,
    Icons,
  },
  helpers: { hexToRGBA, slideFromTopAnimation, transition },
} = shared;

const { PAYMENT_METHODS } = Constants;

const OverlayWrapper = styled.div`
  background: ${hexToRGBA('#161929', 0.9)};
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  transition: ${transition};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.show ? 1 : 0)};
  top: 0;
  left: 0;
  z-index: 9997;
  -webkit-transform: translate3d(0, 0, 0);
  padding: 0 !important;
`;

const CustomCard = styled(Card)`
  border-radius: 4px;
  box-shadow: 0px 16px 28px rgba(63, 86, 104, 0.1);
  position: relative;
  width: 100%;
  overflow: hidden;
  border: 0;
  display: flex;
  flex-direction: column;
`;

const CloseIcon = styled(Icons.Cross)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  position: absolute;
  top: -30px;
  right: 5px;
  z-index: 999;
  text-align: center;
  :hover {
    cursor: pointer;
  }
  border-radius: 999px;
`;

const ContentWrapper = styled.div`
  transition: 0.4s ease-in-out;
  position: relative;
  opacity: ${(props) => (props.show ? 1 : 0)};
  animation: ${(props) => (props.show ? slideFromTopAnimation : '')} 0.4s ease-in-out;
  width: calc(100% - 16px);
  max-width: 500px;
  z-index: 100;
`;

const steps = {
  PRODUCTS: 'products',
  LOCATION_DETAILS: 'location',
};

const ConfirmationWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  z-index: 999998;
  min-height: 100vh;
  max-width: 502px;
  margin: auto;
  @media (min-width: 501px) {
    border: 1px #e8e9f3 solid;
  }
`;

const AddOrderModal = React.memo((props) => {
  const dispatch = useDispatch();
  const { show, toggle } = props;

  const activePage = useSelector((state) => state.facebookPages?.activePage);
  const order = useSelector((state) => state.shopping.order);

  const geoRegion = useSelector((state) => state.shopping.geoRegion);

  const [waitingConfirmation, setWaitingConfirmation] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [declinedError, setDeclinedError] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [initialStep, setInitialStep] = useState(0);
  const [paymentSessionId, setPaymentSessionId] = useState(null);

  const stripe = useMemo(() => {
    if (activePage && activePage.publicPaymentKey) {
      return loadStripe(activePage.publicPaymentKey);
    }
    return null;
  }, [activePage]);

  const waitForConfirmation = useCallback(
    (setIsLoading, orderId) => {
      setIsLoading(false);
      setWaitingConfirmation(true);
      const timer = setInterval(() => {
        dispatch(
          getOrderById(orderId, (err, data) => {
            if (err) {
              return;
            }

            const status = ORDER_STATUS[data.orderStatus];
            if (status !== 'Waiting for Confirmation') {
              if (status === 'Cancelled') {
                setIsCancelled(true);
              } else {
                setIsLoading(false);
              }
              setWaitingConfirmation(false);
              clearInterval(timer);
            }
          }),
        );
      }, 5000);
    },
    [dispatch],
  );

  const onPlaceOrder = useCallback(
    (setIsLoading) => {
      setIsLoading(true);
      dispatch(
        saveOrder(activePage.id, true, (err, order) => {
          //true for internal order
          if (err) {
            setIsLoading(false);
          } else {
            if (ORDER_STATUS[order.orderStatus] === 'Paid') {
              setDeclinedError(null);
              setPaymentSessionId(null);
              setSucceeded(true);
              setIsLoading(false);
            } else if (ORDER_STATUS[order.orderStatus] === 'Pending') {
              waitForConfirmation(setIsLoading, order.id);
            } else {
              setIsLoading(false);
            }
          }
        }),
      );
    },
    [dispatch, waitForConfirmation, activePage],
  );

  const onRetry = useCallback(() => {
    setInitialStep(1);
    setDeclinedError(null);
  }, []);

  const changeToCash = useCallback(() => {
    if (!paymentSessionId) return;
    dispatch(setPaymentMethod(PAYMENT_METHODS.ON_DELIVERY));
    dispatch(
      updateOrderPaymentMethod(paymentSessionId, PAYMENT_METHODS.ON_DELIVERY, (err) => {
        if (err) {
          return;
        }
        setSucceeded(true);
        setDeclinedError(null);
      }),
    );
  }, [paymentSessionId, dispatch]);

  const reset = () => {
    dispatch(startNewInternalOrder());
    setWaitingConfirmation(false);
    setIsCancelled(false);
    setDeclinedError(false);
    setSucceeded(false);
  };

  const closeModal = () => {
    reset();
    toggle();
  };

  return (
    <OverlayWrapper show={show}>
      <ContentWrapper show={show} onClick={(event) => event.stopPropagation()}>
        <CustomCard style={{ height: '85vh', overflow: 'visible' }}>
          <CloseIcon fill={'#2A2E45'} onClick={closeModal} width={'8px'} height={'8px'} />
          <ContextLayout>
            {succeeded ? (
              <PaymentSucceeded
                order={order}
                currency={activePage.currency}
                geoRegion={geoRegion}
                reset={reset}
              />
            ) : declinedError ? (
              <PaymentDeclined onRetry={onRetry} changeToCash={changeToCash} reset={reset} />
            ) : isCancelled ? (
              <OrderCancelled
                order={order}
                currency={activePage.currency}
                geoRegion={geoRegion}
                reset={reset}
              />
            ) : (
              <ShoppingContext.Consumer>
                {({
                  activePage,
                  categories,
                  extraCategories,
                  search,
                  setSearch,
                  setIsLoading,
                  iframeConfig,
                  scrollableArea,
                }) => (
                  <>
                    {waitingConfirmation && (
                      <ConfirmationWrapper>
                        <AwaitingConfirmation
                          order={order}
                          currency={activePage.currency}
                          geoRegion={geoRegion}
                        />
                      </ConfirmationWrapper>
                    )}
                    <Stepper
                      steps={Object.values(steps)}
                      initialStep={initialStep}
                      onFinish={() => onPlaceOrder(setIsLoading)}
                      internalOrder={true}
                      stripe={stripe}
                      pageInfo={{ ...activePage, isList: true }}
                      iframeConfig={iframeConfig}
                    >
                      <Stepper.Step
                        stepKey={steps.PRODUCTS}
                        render={({ nextStep, prevStep }) => (
                          <ChooseProducts
                            id={'ChooseProducts'}
                            proceedText={'Place Order'}
                            order={order}
                            pageInfo={{ ...activePage, isList: true }}
                            categories={categories}
                            extraCategories={extraCategories}
                            onProceed={nextStep}
                            onBack={prevStep}
                            stripe={stripe}
                            search={search}
                            setSearch={setSearch}
                            hideBanner={true}
                            disableOnlineOrder={iframeConfig.disableOnlineOrder}
                            iframeConfig={iframeConfig}
                            internalOrder={true}
                            collectClientName={iframeConfig.collectClientName}
                            scrollableArea={scrollableArea}
                            addBottomPadding={100}
                          />
                        )}
                      />
                      <Stepper.Step
                        stepKey={steps.LOCATION_DETAILS}
                        render={({ prevStep, nextStep }) => (
                          <LocationDetails
                            internalOrder={true}
                            proceedText={'Proceed'}
                            onProceed={nextStep}
                            onBack={prevStep}
                            pageInfo={{ ...activePage, isList: true }}
                            geoRegions={[]}
                            setGeoRegions={() => {}}
                            iframeConfig={iframeConfig}
                            absolutePosition={true}
                          />
                        )}
                      />
                    </Stepper>
                  </>
                )}
              </ShoppingContext.Consumer>
            )}
          </ContextLayout>
        </CustomCard>
      </ContentWrapper>
    </OverlayWrapper>
  );
});

export default React.memo(AddOrderModal, (a, b) => a.show === b.show);
