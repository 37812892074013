import React from 'react';
import styled from 'styled-components';
import RadioGroup from './radioGroup';
import colors from '../helpers/colors';
import transition from '../helpers/transition';
import hexToRGBA from '../helpers/hexToRGBA';

const TabsWrapper = styled.div`
  border: 1px solid ${colors.blue};
  border-radius: 3px;
  display: flex;
`;

const TabElementWrapper = styled.div`
  height: 40px;
  border-right: 1px solid ${colors.blue};
  width: 100%;
  line-height: 40px;
  text-align: center;
  color: ${props => (props.isSelected ? colors.white : colors.blue)};
  background-color: ${props => (props.isSelected ? colors.blue : colors.white)};
  transition: ${transition};

  :hover {
    cursor: pointer;
    background-color: ${props =>
      props.isSelected ? hexToRGBA(colors.blue, 0.9) : hexToRGBA(colors.blue, 0.1)};
  }

  :last-child {
    border-right: none;
  }
`;

const TabElement = ({ text, updateSelectedElement = () => {}, ...rest }) => (
  <TabElementWrapper onClick={updateSelectedElement} {...rest}>
    {text}
  </TabElementWrapper>
);

const Tabs = ({ elements, selectedElement, updateSelectedElement }) => (
  <TabsWrapper>
    <RadioGroup selectedElement={selectedElement} updateSelectedElement={updateSelectedElement}>
      {elements.map(element => (
        <RadioGroup.Element
          key={element.value}
          Element={TabElement}
          text={element.text}
          value={element.value}
        />
      ))}
    </RadioGroup>
  </TabsWrapper>
);

export default Tabs;
