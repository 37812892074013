const initialState = {
  orders: [],
  clients: [],
  products: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `GET_TOP_${action.tag || ''}_OK`:
      let newState = { ...state }
      newState[action.prop] = action.data
      return newState;
    default:
      return state;
  }
};
