import React from 'react';
import styled from 'styled-components';
import Icons from 'app/shared/views/icons';

// import orderooWhiteLogo from 'assets/images/logo-white.svg';
import WaiterFunctionality from './WaiterFunctionality';
import { StatusBadge, StringTime } from 'app/shared/views/shopping/components/Badge';

const ShoppingHeader = ({
  pageInfo,
  tableInfo,
  callWaiter,
  getBill,
  setShowReview,
  enableWaiterFunctionalities,
  ...props
}) => {
  if (!pageInfo) return null;
  // const showLogo = pageInfo.isSubscribed;

  return (
    <HeaderWrapper>
      <StyledHeader cover={pageInfo.bannerURL} {...props}>
        {/*<LogoWrapper style={{ display: 'none' }}>
          <img src={orderooWhiteLogo} alt="orderoo-logo" height="18px" />
        </LogoWrapper>*/}
        <TableName>{tableInfo.name}</TableName>
      </StyledHeader>
      <HeaderBody>
        <Name>{pageInfo.pageName}</Name>

        {pageInfo.pageCategory && <Categories>{pageInfo.pageCategory}</Categories>}

        <HeaderDetail>
          <Icons.OpeningHours />
          <DetailInfo>
            <StringTime openHours={pageInfo.openHours} />
            <StatusBadge
              key="statusBadge"
              isOpen={pageInfo.isOpen}
              openHours={pageInfo.openHours}
              style={{ paddingRight: 0 }}
            />
          </DetailInfo>
        </HeaderDetail>
      </HeaderBody>
      {enableWaiterFunctionalities && (
        <WaiterFunctionality
          getBill={getBill}
          callWaiter={callWaiter}
          setShowReview={setShowReview}
        />
      )}
    </HeaderWrapper>
  );
};
const HeaderWrapper = styled.div`
  background-color: white;
`;

const StyledHeader = styled.div`
  background-image: url(${(props) => props.cover});
  // linear-gradient(180deg, rgba(49, 133, 252, 0.1) 0%, rgba(49, 133, 252, 0.5) 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  min-height: 140px;
`;

const HeaderBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 1rem 1rem 1rem;
  border-bottom: 1px dashed;

  &:before {
    width: 90%;
  }
`;

// const LogoWrapper = styled.div`
//   padding-bottom: 15px;
// `;

const Name = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: black;
  padding: 25px 9px;
  margin-top: auto;
`;

const Categories = styled.p`
  position: relative;
  left 25px;
  bottom: 20px;
  width: 50px;
`;

const HeaderDetail = styled.div`
  display: flex;
  justify-constent: center;
  align-items: center;
  width 100%;
  padding-bottom: 20px;
`;

const DetailInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const TableName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 0.875rem;
  height: 28px;
  margin-left: 24px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 26px;

  background: #000;
  box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
`;

export default ShoppingHeader;
