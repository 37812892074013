import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import colors from '../../shared/helpers/colors';
import hexToRGBA from '../../shared/helpers/hexToRGBA';

import {
  UpgradeButton,
  Title,
} from './elements';

import paddle from '../images/paddle.svg';

const PaymentStep = ({ reference, activePage, chosenPlan, }) => {
  const onPayClicked = useCallback(() => {
    // eslint-disable-next-line
    const { planId, planName } = chosenPlan;
    const pageId = activePage.id;
    const Paddle = window.Paddle;
    const passthrough = JSON.stringify({ pageId, });
    Paddle.Checkout.open({
      product: planId,
      passthrough: passthrough,
      successCallback: () => {
        setTimeout(() => {
          window.location.href = '/dashboard';
        }, 3000);
      },
    });
  }, [chosenPlan, activePage]);

  return (
    <ContentWrapper ref={reference}>
      <ContentTitle>Payment Method</ContentTitle>
      <AmountWrapper>
        <AmountLabel>Orderoo Pro - Amount to pay:</AmountLabel>
        <AmountValue>
          <span>${chosenPlan.amount}</span>
        </AmountValue>
      </AmountWrapper>
      <IlustrationWrapper>
        <img alt="paddle" src={paddle} />
      </IlustrationWrapper>
      <UpgradeButton
        text="Pay"
        width="100%"
        onClick={onPayClicked}
      />
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const ContentTitle = styled(Title)`
  font-size: 24px;
  line-height: 22px;
  padding-bottom: 25px;
  justify-content: center
`;

const IlustrationWrapper = styled.div`
  padding: 120px 0
`;

const AmountWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AmountLabel = styled.label`
  color: ${colors.textColor};
  padding: 6px 12px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`;

const AmountValue = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  background: ${hexToRGBA(colors.blue, 0.2)};
  border-radius: 3px;

  & > span {
    color: ${colors.blue};
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 17px;
  }
`;

const mapStateToProps = state => ({
  activePage: state.facebookPages.activePage,
});

export default connect(
  mapStateToProps,
)(PaymentStep);
