import decodeJwt from 'jwt-decode';
import Url from '../config/api';
import { ORDER_STATUS } from 'app/orders/constants';
import { getPageConfiguration } from './pageActions';
import normalizePhoneNumber from 'app/shared/helpers/phonenumber';
import Bugsnag from '@bugsnag/js';
import { getAllPromotionsForClient } from 'actions/crudActions';
import crudTypes from 'config/crudTypes';
import { getPokSettingsByPageId } from 'actions/pokSettingsActions';
import { buildAxiosInstance } from 'actions/common';

const { baseUrl } = Url;

export let api = buildAxiosInstance(baseUrl)

const ORDER_COOKIE_ID_KEY = 'orderCookieId';
const VERIFIED_PHONE_NUMBER = 'verifiedPhoneNumber';

const setTokenToApi = (token) => {
  api.interceptors.request.use((config) => {
    if (!token.startsWith('Bearer')) {
      token = `Bearer ${token}`;
    }
    config.headers.Authorization = token;
    return config;
  });
};

export const analyticsIdentifyUser = ({ clientPhone, pageInfo, tableInfo }) => {
  const userData = {
    phoneNumber: normalizePhoneNumber(clientPhone),
    pageId: pageInfo?.id,
    pageName: pageInfo?.pageName,
    tableId: tableInfo?.id,
    tableName: tableInfo?.name,
  };
  Bugsnag.addMetadata('client', userData);
  window.smartlook && window.smartlook('identify', userData.phoneNumber, userData);
};

export const initTableOrderShopping = (tableCode, done) => {
  return (dispatch, getState) => {
    Promise.resolve()
      .then(async () => {
        const authApiUrl = `/Auth/get-auth-token/table/${tableCode}`;
        const res = await api.get(authApiUrl);
        const jwtToken = res.data.auth_token;
        setTokenToApi(jwtToken);
        return { jwtToken };
      })
      .then(async ({ jwtToken }) => {
        const jwtInfo = decodeJwt(jwtToken);
        const pageId = jwtInfo.PageId;
        let pageInfo = { pageId };
        try {
          const res = await api.get(`/Pages/${jwtInfo.PageId}`);
          pageInfo = { ...res.data };
          if (res.status === 200) {
            dispatch({ type: 'PAGE_INFO_OK', data: pageInfo });
            dispatch(getPokSettingsByPageId(pageInfo.id));
            dispatch(getAllPromotionsForClient(crudTypes.promotion, pageInfo.id));
            dispatch(
              getPageConfiguration(pageInfo.id, 'PUBLIC_tableOrderIframeConfiguration', null, api),
            );
          }
        } catch (err) {
          console.error('Failed to get page info: ', err);
        }

        let lastOrder = { id: 0, orderStatus: 0 };
        const lastOrderCookieId = localStorage.getItem(ORDER_COOKIE_ID_KEY);
        if (lastOrderCookieId !== null) {
          try {
            const res = await api.get(`/Clients/GetLastOrder`, {
              params: {
                cookieId: lastOrderCookieId,
              },
            });
            if (res.status === 200 && Object.keys(res.data).length > 0) {
              const waitingConfirmation =
                ORDER_STATUS[res.data.orderStatus] === 'Waiting for Confirmation';
              if (waitingConfirmation) {
                dispatch({ type: 'GET_ORDER_OK', data: mapOrderFromEntity(res.data) });
              } else {
                dispatch({ type: 'GET_ORDER_OK', data: mapLastOrderFromEntity(res.data) });
              }
            }
          } catch (err) {
            console.error('Failed to get client data: ', err);
          }
        }

        try {
          const res = await api.get(`/Subscribe/GetSubscriptionByPageId/${pageInfo.id}`);
          pageInfo.isSubscribed = res.data.isActive;
        } catch (err) {
          pageInfo.isSubscribed = false;
        }

        let tableInfo = {};
        try {
          const res = await api.get(`/Table/by-table-code/${tableCode}`);
          tableInfo = res.data;
        } catch (err) {
          console.error('Failed to get  table info: ', err);
        }

        analyticsIdentifyUser({ lastOrder, pageInfo, tableInfo });
        const iframeConfig = getState().pageConfiguration.PUBLIC_tableOrderIframeConfiguration;
        return { jwtToken, pageInfo, tableInfo, jwtInfo, lastOrder, iframeConfig };
      })
      .then(async (data) => {
        const res = await api.get('/Categories/Search');
        const categories = res.data;
        return { ...data, categories };
      })
      .then(async (data) => {
        const res = await api.get('/Categories', { params: { categoryType: 1 } });
        const extraCategories = res.data.map((cat) => ({
          ...cat,
          magProducts: cat.magProducts.filter((p) => !p.disabled),
        }));
        return { ...data, extraCategories };
      })
      .then((data) => {
        done && done(null, data);
      })
      .catch((err) => {
        done && done(err);
      });
  };
};

export const initAggregatorShopping = (pageName, done) => initShopping(pageName, done, true);
export const initShopping = (pageName, done, aggregator = false) => {

  return (dispatch, getState) => {
    Promise.resolve()
      .then(async () => {
        const res = await api.get(
          aggregator
            ? `/Auth/get-aggregator-auth-token/${pageName}`
            : `/Auth/get-auth-token/${pageName}`,
        );
        const jwtToken = res.data.auth_token;
        setTokenToApi(jwtToken);
        return { jwtToken };
      })
      .then(async ({ jwtToken }) => {
        const jwtInfo = decodeJwt(jwtToken);
        const pageId = jwtInfo.PageId;
        let pageInfo = { pageId };
        try {
          const res = await api.get(`/Pages/${jwtInfo.PageId}`);
          pageInfo = { ...res.data, isList: true };
          if (res.status === 200) {
            dispatch({ type: 'PAGE_INFO_OK', data: pageInfo });
            dispatch(getAllPromotionsForClient(crudTypes.promotion, pageInfo.id));
            dispatch(getPokSettingsByPageId(pageInfo.id));
            dispatch(
              getPageConfiguration(pageInfo.id, 'PUBLIC_onlineOrderIframeConfiguration', null, api),
            );
          }
        } catch (err) {
          console.error('Failed to get page info: ', err);
        }

        let lastOrder = { id: 0, orderStatus: 0 };
        const lastOrderCookieId = localStorage.getItem(ORDER_COOKIE_ID_KEY);
        if (lastOrderCookieId !== null) {
          try {
            const res = await api.get(`/Clients/GetLastOrder`, {
              params: {
                cookieId: lastOrderCookieId,
              },
            });
            if (res.status === 200 && Object.keys(res.data).length > 0) {
              const waitingConfirmation =
                ORDER_STATUS[res.data.orderStatus] === 'Waiting for Confirmation';
              if (waitingConfirmation) {
                dispatch({ type: 'GET_ORDER_OK', data: mapOrderFromEntity(res.data) });
              } else {
                dispatch({ type: 'GET_ORDER_OK', data: mapLastOrderFromEntity(res.data) });
              }
              lastOrder = {
                ...lastOrder,
                ...res.data,
              };
            }
          } catch (err) {
            console.error('Failed to get client data: ', err);
          }
        }

        try {
          const res = await api.get(`/Subscribe/GetSubscriptionByPageId/${pageInfo.id}`);
          pageInfo.isSubscribed = res.data.isActive;
        } catch (err) {
          pageInfo.isSubscribed = false;
        }

        analyticsIdentifyUser({ lastOrder, pageInfo });
        const iframeConfig = getState().pageConfiguration.PUBLIC_onlineOrderIframeConfiguration;
        return { jwtToken, pageInfo, jwtInfo, lastOrder, iframeConfig };
      })
      .then(async (data) => {
        const res = await api.get('/Categories/Search');
        const categories = res.data;
        return { ...data, categories };
      })
      .then(async (data) => {
        const res = await api.get('/Categories', { params: { categoryType: 1 } });
        const extraCategories = res.data.map((cat) => ({
          ...cat,
          magProducts: cat.magProducts.filter((p) => !p.disabled),
        }));
        return { ...data, extraCategories };
      })
      .then((data) => {
        done && done(null, data);
      })
      .catch((err) => {
        done && done(err);
      });
  };
};

export const startNewOrder = (done) => {
  return (dispatch) => {
    dispatch({ type: 'START_NEW_ORDER' });
    done && done(null);
  };
};

export const startNewInternalOrder = (done, page) => {
  return (dispatch) => {
    dispatch({ type: 'START_NEW_INTERNAL_ORDER' });
    dispatch({ type: 'PAGE_INFO_OK', data: page });
    done && done(null);
  };
};

export const getPageInfo = (pageId, done) => {
  return () => {
    api
      .get(`/Pages/GetByPageId/${pageId}`)
      .then((res) => {
        done && done(null, res.data);
      })
      .catch((err) => {
        done && done(err);
      });
  };
};

export const addUnfinishedCheckout = async () => {
  return api.post(`${baseUrl}/UnfinishedCheckout`);
};

export const saveOrder = (id, internalOrder, done) => {
  return (dispatch, getState) => {
    const { order, paymentMethod, client } = getState().shopping;
    const orderEntity = mapOrderToEntity({ order, paymentMethod, client });
    const cookieId = localStorage.getItem(ORDER_COOKIE_ID_KEY);
    const businessId = internalOrder ? localStorage.getItem('Page') : id;

    if (internalOrder) {
      (!orderEntity.id
        ? api.post(
            `${baseUrl}/pok/BusinessManager/orders/internal/create`,
            {
              ...orderEntity,
              businessId,
              cookieId,
            },
            // {
            //   headers: {
            //     page: JSON.parse(localStorage.getItem('Page'))
            //   },
            // },
          )
        : api.put(
            `${baseUrl}/Orders/${orderEntity.id}?pageid=${JSON.parse(
              localStorage.getItem('Page'),
            )}`,
            { ...orderEntity, cookieId },
            {
              headers: {
                page: JSON.parse(localStorage.getItem('Page'))
              },
            }
          )
      )
        .then((res) => {
          dispatch({
            type: 'ORDER_SAVED',
            data: { id: res.data.id, orderStatus: res.data.orderStatus },
          });
          if (res.data.cookieId) {
            localStorage.setItem(ORDER_COOKIE_ID_KEY, res.data.cookieId);
          }
          done && done(null, res.data);
        })
        .catch((err) => {
          dispatch({ type: 'ORDER_ERROR' });
          done && done(err);
        });
    } else {
      (!orderEntity.id
        ? api.post('/Orders/online/create', { ...orderEntity, businessId, cookieId })
        : api.put(`/Orders/${orderEntity.id}`, { ...orderEntity, cookieId })
      )
        .then((res) => {
          dispatch({
            type: 'ORDER_SAVED',
            data: {
              id: res.data.id,
              orderStatus: res.data.orderStatus,
              discount: res.data.discount,
              totalValue: res.data.totalValue - res.data.deliveryFee,
              pokPaymentConfirmed: res.data.pokPaymentConfirmed,
            },
          });
          if (res.data.cookieId) {
            localStorage.setItem(ORDER_COOKIE_ID_KEY, res.data.cookieId);
          }
          done && done(null, res.data);
        })
        .catch((err) => {
          dispatch({ type: 'ORDER_ERROR' });
          done && done(err);
        });
    }
  };
};

export const confirmPokCredentials = (credentials) => {
  return api.post(`${baseUrl}/PokClient/confirmCredentials`, {
    keyId: credentials.keyId,
    keySecret: credentials.keySecret,
  });
};

export const orderWithPok = (order, pageInfo) => {
  const webHookUrl = `${baseUrl}/UnfinishedCheckout/pok/${order.pokConnectionId}`;
  return api.get(
    `${baseUrl}/PokClient/order/${pageInfo.id}?amount=${order.total}&currencyCode=${pageInfo.currency}&autoCapture=true&shippingCost=${order.deliveryFee}&webHookUrl=${webHookUrl}`,
  );
};

export const saveTableOrder = (order, tableId, pageId, paymentMethod, internalOrder, done) => {
  return (dispatch, getState) => {
    const cookieId = localStorage.getItem(ORDER_COOKIE_ID_KEY);
    const phoneNumber = normalizePhoneNumber(localStorage.getItem(VERIFIED_PHONE_NUMBER));
    const { client } = getState().shopping;
    const businessId = pageId;
    const internalBusinessId = localStorage.getItem('Page');

    const data = {
      customerDescription: order.description,
      totalValue: order.total,
      total: order.total,
      pokPaymentConfirmed: order.pokPaymentConfirmed,
      pokConnectionId: order.pokConnectionId,
      tableId: tableId,
      clientPhone: phoneNumber,
      clientName: client.firstName,
      clientLastName: client.lastName,
      paymentMethod: paymentMethod,
      voucherCode: order.voucherCode,
      businessId: internalOrder ? internalBusinessId : businessId,
      cookieId,
      // offerId: order.offerId,
      orderProducts: order.orderProducts.map((product) => ({
        productId: product.id,
        qty: product.qty,
        price: product.price,
        name: product.name,
        toppings: product.toppings.map((topping) => ({
          productId: topping.id,
          qty: topping.qty,
          price: topping.price,
          name: topping.name,
        })),
      })),
    };

    if (internalOrder) {
      api
        .post(`${baseUrl}/Orders/internal/create`, data)
        .then((res) => {
          dispatch({
            type: 'ORDER_SAVED',
            data: { id: res.data.id, orderStatus: res.data.orderStatus },
          });
          if (res.data.cookieId) {
            localStorage.setItem(ORDER_COOKIE_ID_KEY, res.data.cookieId);
          }
          done &&
            done(null, {
              ...order,
              id: res.data.id,
            });
        })
        .catch((err) => {
          dispatch({ type: 'ORDER_ERROR' });
          done && done(err);
        });
    } else {
      api
        .post('/Orders/table/create', data)
        .then((res) => {
          dispatch({
            type: 'ORDER_SAVED',
            data: {
              id: res.data.id,
              orderStatus: res.data.orderStatus,
              discount: res.data.discount,
              totalValue: res.data.totalValue,
            },
          });
          if (res.data.cookieId) {
            localStorage.setItem(ORDER_COOKIE_ID_KEY, res.data.cookieId);
          }
          done &&
            done(null, {
              ...order,
              id: res.data.id,
            });
        })
        .catch((err) => {
          dispatch({ type: 'ORDER_ERROR' });
          done && done(err.response.data);
        });
    }
  };
};

export const getOrderById = (id, done) => {
  return (dispatch) => {
    api
      .get(`/Orders/${id}`)
      .then((res) => {
        dispatch({ type: 'GET_ORDER_OK', data: mapOrderFromEntity(res.data) });
        done && done(null, res.data);
      })
      .catch((err) => {
        dispatch({ type: 'GET_ORDER_ERROR' });
        done && done(err);
      });
  };
};

export const getOrderBySessionId = (sessionId, done) => {
  return (dispatch) => {
    api
      .get(`/Orders/get-order-by-sessionid/${sessionId}`)
      .then((res) => {
        dispatch({ type: 'GET_ORDER_OK', data: mapOrderFromEntity(res.data) });
        done && done(null, res.data);
      })
      .catch((err) => {
        dispatch({ type: 'GET_ORDER_ERROR' });
        done && done(err);
      });
  };
};

export const updateOrderPaymentMethod = (sessionId, paymentMethod, done) => {
  return (dispatch) => {
    api
      .get(`/Orders/get-order-by-sessionid/${sessionId}`)
      .then(async (res) => {
        const order = res.data;
        order.paymentMethod = paymentMethod.methodId;
        await api.put(`/Orders/${order.id}`, order);
        dispatch({ type: 'UPDATE_ORDER_PAYMENT_METHOD_OK' });
        done && done(null, order);
      })
      .catch((err) => {
        dispatch({ type: 'UPDATE_ORDER_PAYMENT_METHOD_ERROR' });
        done && done(err);
      });
  };
};

export const callWaiter = (pageId, itemId, setStatus) => {
  return () => {
    api
      .post('/Notification/AddNotification', {
        pageId,
        itemId,
        notificationType: 'WAITER',
      })
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
      });
  };
};

export const requestBill = (pageId, itemId, setStatus) => {
  return () => {
    api
      .post('/Notification/AddNotification', {
        pageId,
        itemId,
        notificationType: 'GET_BILL',
      })
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setStatus && setStatus('OK');
        }
      })
      .catch(() => {
        setStatus && setStatus('ERROR');
      });
  };
};

export const getBill = (setBills) => {
  const cookieId = localStorage.getItem(ORDER_COOKIE_ID_KEY);
  return () => {
    api
      .get(`/Orders/get-bill/${cookieId}`)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setBills(res?.data);
        }
      })
      .catch(() => {
        setBills(false);
      });
  };
};

export const getBillById = (setBills, id) => {
  return () => {
    api
      .get(`/Orders/bill/${id}`)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setBills(res?.data);
        }
      })
      .catch(() => {
        setBills(false);
      });
  };
};

export const addProductToOrder = (product, promotion) => {
  return (dispatch) => {
    dispatch({
      type: 'ORDER_ADD_PRODUCT',
      data: product,
      promotions: promotion,
    });
  };
};

export const removeProductFromOrder = (product, promotion, one = true) => {
  return (dispatch) => {
    dispatch({
      type: 'ORDER_REMOVE_PRODUCT',
      data: product,
      one: one,
      promotions: promotion,
    });
  };
};

export const updateComboProduct = (product, promotions) => {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_COMBO_PRODUCT',
      data: { product, promotions },
    });
  };
};

export const setOrderVoucherCode = (voucherCode) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_ORDER_VOUCHER_CODE',
      data: voucherCode,
    });
  };
};

export const setOrderValidVoucher = (validVoucher, promotionDiscount) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_ORDER_VALID_VOUCHER',
      data: { validVoucher, promotionDiscount },
    });
  };
};

export const setOrderTotal = (promotion) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_ORDER_TOTAL',
      data: promotion,
    });
  };
};

export const setOrderLocation = (orderLocation) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_ORDER_LOCATION',
      data: orderLocation,
    });
  };
};

export const setPaymentMethod = (paymentMethod) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_PAYMENT_METHOD',
      data: paymentMethod,
    });
  };
};

export const setPaymentCard = (card) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_PAYMENT_CARD',
      data: card,
    });
  };
};

export const setClientInfo = (clientInfo = {}) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_CLIENT_INFO',
      data: clientInfo,
    });
  };
};

export const setOrderDescription = (orderDescription) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_ORDER_DESCRIPTION',
      data: orderDescription,
    });
  };
};

export const setAddressInfo = (addressInfo) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_ADDRESS_INFO',
      data: addressInfo,
    });
  };
};

export const setCurrentGeoRegion = (geoRegion) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_CURRENT_GEOREGION',
      data: geoRegion,
    });
  };
};

const mapOrderToEntity = ({ order, paymentMethod, client }) => {
  return {
    id: order.id,
    orderLocationCoords: order.orderLocation?.pos,
    orderLocation: order.orderLocation?.address,
    orderLocation2: order.addressInfo,
    customerDescription: order.description,
    clientName: client.firstName,
    clientLastName: client.lastName,
    clientPhone: normalizePhoneNumber(client.phone),
    totalValue: order.totalValue,
    orderStatus: order.orderStatus,
    pokPaymentConfirmed: order.pokPaymentConfirmed,
    paymentMethod: paymentMethod.methodId,
    // promotion: order.promotion,
    voucherCode: order.voucherCode,
    orderProducts: order.orderProducts.map((product) => ({
      productId: product.id,
      qty: product.qty,
      price: product.price,
      name: product.name,
      toppings: product.toppings.map((topping) => ({
        productId: topping.id,
        qty: topping.qty,
        price: topping.price,
        name: topping.name,
      })),
    })),
  };
};

const mapOrderFromEntity = (orderEntity) => {
  return {
    order: {
      id: orderEntity.id,
      totalValue: orderEntity.totalValue,
      taxFee: orderEntity.taxFee ?? 0,
      deliveryFee: orderEntity.deliveryFee,
      pokPaymentConfirmed: orderEntity.pokPaymentConfirmed,
      // offerId: orderEntity.offerId,
      orderLocation: {
        pos: orderEntity.orderLocationCoords,
        address: orderEntity.orderLocation,
      },
      description: orderEntity.customerDescription,
      addressInfo: orderEntity.orderLocation2,
      orderStatus: orderEntity.orderStatus,
      businessId: JSON.parse(localStorage.getItem('Page')),
      orderProducts: orderEntity.orderProducts.map((product) => ({
        id: product.productId,
        qty: product.qty,
        price: product.price,
        name: product.name,
        isCombo: product.isCombo,
        toppings: product.toppings.map((topping) => ({
          productId: topping.id,
          qty: topping.qty,
          price: topping.price,
          name: topping.name,
        })),
      })),
    },
    client: {
      firstName: orderEntity.clientName,
      lastName: orderEntity.clientLastName,
      phone: normalizePhoneNumber(orderEntity.clientPhone),
    },
    paymentMethod: {
      methodId: orderEntity.paymentMethod,
    },
  };
};

const mapLastOrderFromEntity = (orderEntity) => {
  let orderLocation = null;
  if (orderEntity.orderLocationCoords && orderEntity.orderLocation) {
    orderLocation = {
      pos: orderEntity.orderLocationCoords,
      address: orderEntity.orderLocation,
    };
  }

  return {
    order: {
      orderLocation,
      description: '',
      addressInfo: orderEntity.orderLocation2,
    },
    client: {
      firstName: orderEntity.clientName,
      lastName: orderEntity.clientLastName,
      phone: normalizePhoneNumber(orderEntity.clientPhone),
    },
  };
};

export const submitWaiterReview = (rating, comment, tableId, setStatus = null) => {
  return (dispatch, getState) => {
    const pageId = getState().shopping.pageInfo.id;
    api
      .put(`/Waiter/add-review`, {
        rating: rating,
        comment: comment,
        tableId: tableId,
        pageId: pageId,
      })
      .then(() => {
        setStatus && setStatus(true);
      })
      .catch(() => {
        setStatus && setStatus(false);
      });
  };
};

export const checkNumberVerificationStatus = (phoneNumber) => {
  return async () => {
    try {
      phoneNumber = normalizePhoneNumber(phoneNumber);
      const res = await api.get(`/Clients/get-number-status/${phoneNumber}`);
      if (res.data === 1) {
        localStorage.setItem(VERIFIED_PHONE_NUMBER, phoneNumber);
      }
      return res.data;
    } catch (e) {
      console.log('error', e);
    }
  };
};

export const getVerificationCode = (phoneNumber, setStatus = null) => {
  return () => {
    phoneNumber = normalizePhoneNumber(phoneNumber);
    api
      .get(`/Clients/get-verification-code/${phoneNumber}`)
      .then((res) => {
        if (res.data === 1) {
          localStorage.setItem(VERIFIED_PHONE_NUMBER, phoneNumber);
        }
        setStatus && setStatus(res.data);
      })
      .catch((e) => {
        setStatus && setStatus(e?.response.data);
      });
  };
};

export const verifyCode = (phoneNumber, code, setStatus = null) => {
  return () => {
    phoneNumber = normalizePhoneNumber(phoneNumber);
    api
      .get(`/Clients/verify-code/${phoneNumber}/${code}`)
      .then((res) => {
        setStatus && setStatus(res.data);
        if (res.data === 1) {
          localStorage.setItem(VERIFIED_PHONE_NUMBER, phoneNumber);
        }
      })
      .catch((e) => {
        setStatus && setStatus(e?.response.data);
      });
  };
};

export const getProductsByCategory = (startIndex, stopIndex, categoryId, search, done = null) => {
  return async () => {
    try {
      const result = await api.get(`Product/${startIndex}/${stopIndex}`, {
        params: { categoryId, search },
      });
      done && done(null, result.data);
    } catch (e) {
      done && done(e);
    }
  };
};

export const getFirstProduct = (search, done = null) => {
  return async () => {
    try {
      const categories = await api.get('/Categories/Search', { params: { search } });
      const categoryId = categories.data[0].id;
      const result = await api.get(`Product/0/0`, {
        params: { categoryId, search },
      });
      const product = result.data[0];
      done && done(null, product);
    } catch (e) {
      done && done(e);
    }
  };
};

export const getFinalBill = (orderCookie, setOrderInfo) => {
  return () => {
    api
      .get(`/Orders/get-bill/${orderCookie}`)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setOrderInfo(res?.data);
        }
      })
      .catch(() => {
        setOrderInfo(false);
      });
  };
};
