import React from 'react';
import styled from 'styled-components';
import Text from './text';
import colors from '../helpers/colors';

const Header = styled.div`
  display: flex;
  padding: 13px 35px;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.fog};
  height: ${props => props.height ?? 'unset'};
  flex: 0 0 auto;
`;

const Footer = styled.div`
  padding: 30px 35px;
  border-top: 1px solid ${colors.fog};
  display: flex;
  flex-direction: row-reverse;
  flex: 0 0 auto;
  & > * {
    margin-left: 20px;
  }
`;

const Content = styled.div`
  padding: 20px 35px 20px;
  flex: 1 1 auto;
  overflow-y: scroll;
`;

const Title = styled(Text.H4)`
  color: ${colors.blue};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 90vh;
`;

const AddItemPopup = ({ title, HeaderComponent, SubHeader, headerHeight, illustration, children, actions, ...rest }) => (
  <Wrapper {...rest}>
    <Header height={headerHeight}>
      <Title>{title}</Title>
      {illustration && <img src={illustration} alt={"Illustration"} />}
      {HeaderComponent && <HeaderComponent />}
    </Header>
    {SubHeader && <SubHeader />}
    {children && <Content>{ children }</Content>}
    {actions && <Footer>
      {actions.map((Item, i) => (
        <Item key={i} />
      ))}
    </Footer>}
  </Wrapper>
);

AddItemPopup.InputRow = styled.div`
  margin-bottom: 15px;
`;

export default AddItemPopup;
