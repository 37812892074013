import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import logo from 'assets/icons/logo.svg';
//import productTour from 'assets/icons/product_tour.svg';
import { ButtonWithIcon } from './buttons';
import Icons from './icons';
import colors from '../helpers/colors';
import { normalMenu, bartenderMenu } from '../helpers/menu';
import transition from '../helpers/transition';
import mediaQueries from '../helpers/mediaQueries';
import logout from '../helpers/logout';
import hexToRGBA from '../helpers/hexToRGBA';
import { Modal, Result } from 'antd';

const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  padding-top: 20px;
  padding-bottom: 30px;
  padding-right: 30px;
  background-color: ${colors.white};
  width: 245px;
  height: 100vh;
  box-sizing: border-box;
  border-right: 1px solid ${colors.lightGrey};
  overflow-y: auto;
  position: fixed;
  transition: ${transition};
  z-index: 9997;

  @media only screen and (max-width: ${mediaQueries.sizes.phone}px) {
    left: ${(props) => (props.show ? '0px' : '-245px')};
  }

  ${mediaQueries.mobile(`
    top: 47px;
    border-radius: 0 6px 6px 0;
    height: calc(100vh - 94px);
    border: 1px solid ${colors.lightGrey};
    height: -webkit-fill-available;
  `)}
`;

const Logo = styled.img`
  ${mediaQueries.mobile(`
    height: 18px;
    width: 75px;
    margin-top: 2px;
  `)}
`;

const MenuItemsWrapper = styled.div`
  padding-top: 120px;
  flex-grow: 2;
  display: flex;
  flex-direction: column;

  ${mediaQueries.mobile(`
    flex-grow: inherit;
  `)}
`;

const MenuItemLink = styled(NavLink)`
  display: inline-flex;
  align-items: center;
  text-decoration: none;
`;

const MenuItemText = styled.span`
  margin-left: 28px;
  color: ${colors.textColor};
  font-size: 18px;
  line-height: 22px;

  ${mediaQueries.mobile(`
    font-size: 16px;
  `)}
`;

const MenuItem = ({ Icon, exact, text, to, ...rest }) => (
  <MenuItemLink exact={exact} to={to} activeClassName="active-nav-link" {...rest}>
    <Icon fill="#E9EAF1" style={{ width: 30, height: 30 }} />
    <MenuItemText>{text}</MenuItemText>
  </MenuItemLink>
);

const MenuItemWrapper = styled.div`
  margin-bottom: 20px;

  :last-child {
    margin-bottom: 0;
  }
`;

// const TourWidgetWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
// `;

// const TourImage = styled.img`
//   ${mediaQueries.mobile(`
//     display: none;
//   `)}
// `;

// const TakeATour = styled(Text.Link.Link)`
//   width: max-content;
//   margin-top: 10px;

//   ${mediaQueries.mobile(`
//     margin-top: 40px;
//     font-size: 14px;
//   `)}
// `;

// const TourWidget = () => (
//   <TourWidgetWrapper>
//     <TourImage src={productTour} width={164} height={115} alt={"Product Tour"} />
//     <TakeATour>Take a Tour</TakeATour>
//   </TourWidgetWrapper>
// );

const ButtonWrapper = styled.div`
  margin-top: 40px;
  align-self: baseline;

  ${mediaQueries.mobile(`
    margin-top: 20px;
  `)}
`;

const ButtonMarginWrapper = styled.div`
  margin-bottom: 20px;
`;

const NavigationHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${mediaQueries.mobile(`
    margin-top: 20px;
  `)}
`;

const BackButtonWrapper = styled.div`
  display: none;

  ${mediaQueries.mobile(`
    display: block;
  `)}
`;

const OverlayWrapper = styled.div`
  @media all and (max-width: 768px) {
    background: linear-gradient(
      90deg,
      ${hexToRGBA('#FAFAFA', 0.9)} 0%,
      ${hexToRGBA('#fafafa', 0.5)} 100%
    );
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    transition: ${transition};
    visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.show ? 1 : 0)};
    top: 0;
    left: 0;
    z-index: 9999;
  }
`;

const Navigation = ({ isBartender = false, show = false, toggleNavigation, onlineOrderURL }) => {
  const menu = isBartender ? bartenderMenu : normalMenu;
  const [isVisible, setIsVisible] = useState(false);
  return (
    <OverlayWrapper show={show} onClick={toggleNavigation}>
      <Aside className={'scrollbar'} show={show}>
        <NavigationHeader>
          <NavLink to="/dashboard">
            <Logo src={logo} width={121} alt={'Orderoo Logo'} />
          </NavLink>
          <BackButtonWrapper onClick={toggleNavigation}>
            <Icons.Back fill={colors.blue} />
          </BackButtonWrapper>
        </NavigationHeader>
        <MenuItemsWrapper>
          {menu.navBar.map((item) => (
            <MenuItemWrapper key={item.text}>
              <MenuItem exact={!!item.exact} text={item.text} Icon={item.Icon} to={item.to} />
            </MenuItemWrapper>
          ))}
          <MenuItemWrapper style={{ marginTop: '40px' }}>
            <MenuItem to={'/signin'} text={'Log Out'} onClick={logout} Icon={Icons.Logout} />
          </MenuItemWrapper>
        </MenuItemsWrapper>

        <ButtonWrapper>
          <ButtonMarginWrapper>
            <ButtonWithIcon
              Icon={Icons.Copy}
              style={{ display: '-webkit-inline-box' }}
              text={'Online Order URL'}
              color={colors.green}
              onClick={() => {
                navigator.clipboard.writeText(onlineOrderURL).then(() => {
                  setIsVisible(true)
                });
              }}
            />
          </ButtonMarginWrapper>
          <ButtonWithIcon Icon={Icons.Copyright} text={'orderoo 2021'} color={colors.blue} />
          <Modal
            visible={isVisible}
            onCancel={() => setIsVisible(false)}
            footer={null}
            centered
            maskClosable={true}
            destroyOnClose={true}
            closable={false}
          >
            <Result
              status="success"
              title="Success!"
              subTitle='URL to online order has been copied'
            />
          </Modal>
        </ButtonWrapper>
      </Aside>
    </OverlayWrapper>
  );
};

export default Navigation;
