import { useEffect, useReducer, useState } from 'react';

export const useDebounce = (value, timeout) => {
  const [state, setState] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setState(value), timeout);

    return () => clearTimeout(handler);
  }, [value, timeout]);

  return state;
};

export const useReducerThunkDispatch = (reducer, initialState) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const thunkDispatch = (action) => {
    if (typeof action === 'function') {
      return action(dispatch);
    }
    return dispatch(action);
  };

  return [state, thunkDispatch];
};
