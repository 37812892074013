import React, { useState, useEffect, createContext, useRef } from 'react';
import { ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import Spinner from 'app/shared/views/spinner';

import { initAggregatorShopping, api } from 'actions/shoppingActions';
import { useDebounce } from 'app/shared/helpers/hooks';

export const ShoppingContext = createContext({});

const ShoppingLayout = ({ pageName, children, onInit }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [pageInfo, setPageInfo] = useState(null);
  const [iframeConfig, setIframeConfig] = useState({});
  const [categories, setCategories] = useState([]);
  const [extraCategories, setExtraCategories] = useState([]);
  const [search, setSearch] = useState(null);
  const debouncedSearchTerm = useDebounce(search, 300);
  const [scrollableArea, setScrollableArea] = useState(useRef(null));

  useEffect(() => {
    if (debouncedSearchTerm == null) return;
    api.get('/Categories/Search', { params: { search: debouncedSearchTerm } }).then((res) => {
      const categories = res.data;
      setCategories(categories);
    });
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (pageName) {
      setIsLoading(true);
      dispatch(
        initAggregatorShopping(
          pageName,
          (
            err,
            { jwtInfo, pageInfo, categories, extraCategories, lastOrder, iframeConfig } = {},
          ) => {
            if (err) {
              console.log(err); // something went wrong
              return;
            } else {
              setPageInfo(pageInfo);
              setIframeConfig(iframeConfig);
              setCategories(categories);
              setExtraCategories(extraCategories);
            }
            onInit &&
              onInit({
                pageInfo,
                categories,
                setIsLoading,
                lastOrder,
              });
            setIsLoading(false);
          },
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageName]);

  return (
    <ShoppingWrapper ref={(ref) => setScrollableArea(ref)}>
      {pageInfo ? (
        <Helmet>
          <title>{pageInfo.pageName}</title>
          <meta
            name="viewport"
            content="width=device-width, minimal-ui,initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
        </Helmet>
      ) : null}

      <ShoppingContext.Provider
        value={{
          pageInfo,
          categories,
          extraCategories,
          isLoading,
          setIsLoading,
          search,
          setSearch,
          iframeConfig,
          scrollableArea,
        }}
      >
        {isLoading ? (
          <LoadingWrapper>
            <Spinner size={80} style={{ height: '100vh', width: '100vw' }} />
          </LoadingWrapper>
        ) : (
          pageInfo && children
        )}
      </ShoppingContext.Provider>

      <ToastContainer
        position="bottom-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
      />
    </ShoppingWrapper>
  );
};

const ShoppingWrapper = styled.div`
  background-color: #ffffff;
  max-width: 502px;
  margin: auto;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  @media (min-width: 501px) {
    border: 1px #e8e9f3 solid;
  }
`;

const LoadingWrapper = styled.div`
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  z-index: 999999;
`;

export default ShoppingLayout;
