import React from 'react';
import styled from 'styled-components';

const Line = styled.div`
  line-height: 0;
  display: box;
  vertical-align: middle;
  height: 100%;
  border-bottom: 1px solid lightgrey;
  text-align: center;
`;

const Text = styled.span`
  font-size: 127.65%;
  background-color: white;
  padding: 0 3%;
  color: lightgrey;
`;

const hr = ({text, style=null}) => (
  <div style={style}>
    <Line style={style}>
      {text && <Text>{text}</Text>}
    </Line>
  </div>
);

export default hr;
