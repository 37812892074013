import React, { useEffect, useState } from 'react';
import { Table, Select } from 'antd';
import { withRouter, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import shared from 'app/shared';
import { Formik } from 'formik';
import styled from 'styled-components';
import { edit, remove, getAll } from 'actions/crudActions';
import { saveOrder, updateOrderStatus } from 'actions/orderActions';
import crudTypes from '../../../config/crudTypes';
import { refundOrder } from 'actions/orderActions';
import currencyFormatter from 'app/shared/helpers/currencyFormatter';
import product_image from '../../../assets/images/product_image.png';
import ProductModal from './editProductModal/editProductModal';

import './style.css';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

const { Option, OptGroup } = Select;

const {
  views: {
    Page,
    Button: { Button, WhiteButton, AddButton, RemoveButton, RemoveAllButton, GreenButton },
    Icons,
    Alert,
    Text: {
      Link: { Link },
    },
  },
  helpers: { colors },
} = shared;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;
const Flex1 = styled.div`
  flex: 1;
`;

const DetailsWrapper = styled.div`
  padding: 24px 28px;
`;

const ProductImage = styled.img`
  border-radius: 3px;
  height: 20px;
  width: 40px;
  flex: 1;
  margin-left: 28px;
  margin-right: 20px;
  @media all and (max-width: 768px) {
    display: none;
  }
`;

const ProductName = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #3f3d56;
  flex: 3;
`;
const ProductQuantity = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  color: #1d2036;
  text-align: center;
  margin: 0 8px;
`;

const ProductValue = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  color: #2ecc71;
  padding-right: 28px;
  text-align: right;
`;

const Label = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  flex: 1;
  align-items: left;
  color: #748aa1;
  margin-bottom: 10px;
  width: 100%;
`;

const Details = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  flex: 3;
  align-items: left;
  color: #3f3d56;
`;

const OrderPending = styled.div`
  height: 26px;
  min-width: 120px;
  padding-top: 2px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: ${colors.orange};
  border-radius: 18px;
  display: inline-block;
  float: right;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0025em;
`;

const EditOrderWrapper = styled.div`
  background: ${colors.white};
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px solid #e9eaf1;
  margin-top: 40px;
`;

const OrderDetailsLabel = styled.div`
  flex: 1;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  padding-bottom: 30px;
  color: ${colors.oceanBlue};
  display: flex;
  align-items: center;
  & > * {
    margin-left: 10px;
  }
`;

const EditOrderHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media all and (max-width: 768px) {
    justify-content: space-between;
  }
`;

const EditOrderTitle = styled.div`
  font-size: 20px;
  color: ${colors.black};
  margin-left: 22px;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    margin-right: 22px;
  }
  @media all and (max-width: 768px) {
    display: none;
  }
`;

const EditOrderHeaderLabel = styled.div`
  font-size: 12px;
  color: ${colors.grey};
  & > * {
    margin-left: 30px;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }
  @media all and (max-width: 768px) {
    display: none;
  }
`;

const DateCreatedWrapper = styled.span`
  @media all and (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }
`;

const ErrorWrapper = styled.div`
  min-height: 24px;
  padding: 5px;
  width: 100%;
  background: rgba(242, 69, 61, 0.2);
  color: #f2453d;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  align-items: center;
  letter-spacing: 1px;
  font-weight: bold;
  display: ${(props) => (props.show === 'ERROR' ? 'flex' : 'none')};
`;

const QuantityWraper = styled.span`
  width: 100%;
  display: inline-flex;
  align-items: center;
  margin: auto;
  justify-content: center;
`;

const Total = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #1d2036;
  text-align: left;
  padding-left: 90px;
`;

const NotificationWrapper = styled.div`
  flex-basis: 100%;
  margin-top: 32px;
`;

const CustomExpandIcon = (props) => {
  if ((props.record.toppings?.length || 0) < 1) return null;
  return (
    <Link onClick={(e) => props.onExpand(props.record, e)} style={{ border: 'none' }}>
      {props.expanded ? <Icons.Remove fill={colors.blue} /> : <Icons.Add fill={colors.blue} />}
    </Link>
  );
};

const AddOrder = (props) => {
  const activeOrder = {
    id: 0,
    orderProducts: [],
  };
  const canEdit = true;
  const { getAll } = props;

  const [updateStatus, setUpdateStatus] = useState('');
  const [addProduct, setAddProduct] = useState(false);

  useEffect(() => {
    let updateStatusTimer = setTimeout(() => setUpdateStatus(''), 5000);
    return () => {
      clearTimeout(updateStatusTimer);
    };
  }, [updateStatus]);

  useEffect(() => {
    if (!activeOrder) {
      getAll(crudTypes.order);
      getAll(crudTypes.product);
      getAll(crudTypes.category);
    }
  }, [getAll, activeOrder]);

  const changeUpdateStatus = (status) => {
    setUpdateStatus(status);
  };
  return (
    <Page>
      <Formik
        enableReinitialize
        initialValues={{
          tableData: activeOrder ? [...activeOrder.orderProducts] : [],
        }}
        onSubmit={(values) => {
          const orderToBeSubmitted = { ...activeOrder };
          orderToBeSubmitted.orderProducts = values.tableData;
          props.saveOrder(
            orderToBeSubmitted,
            activeOrder.paymentMethod,
            {
              firstName: activeOrder.clientName,
              lastName: activeOrder.clientLastName,
              phone: activeOrder.clientPhone,
            },
            changeUpdateStatus,
          );
        }}
        // validationSchema={Yup.object().shape({
        //   tableData: Yup.array()
        //     .min(1, 'Product Name is required')
        //     .required('Product Name is required'),
        // })}
      >
        {(innerProps) => {
          const { values, isValid, setFieldValue } = innerProps;
          const getImageURL = (productId) =>
            props.products.data.find((x) => x.id === productId)?.imagePath;

          const isCombo = (productId) =>
            props.products.data.find((x) => x.id === productId)?.isCombo;

          const getProduct = (productId) => props.products.data.find((x) => x.id === productId);

          const columns = [
            {
              title: <Label style={{ marginLeft: 28 }}>Products:</Label>,
              dataIndex: 'product',
              key: 'product',
              width: '30%',
              shouldCellUpdate: true,
              render: (name, record) =>
                record.productId !== 0 ? (
                  <Flex>
                    <Flex1>
                      <ProductImage
                        alt="logo"
                        src={getImageURL(record.productId) || product_image}
                        height="20"
                        width="40"
                      />
                    </Flex1>
                    <ProductName>{record.name}</ProductName>
                  </Flex>
                ) : (
                  <Flex>
                    <Select
                      defaultValue={-1}
                      style={{ minWidth: 240 }}
                      onChange={(prodId) => {
                        const tableDataRowIndex = values['tableData'].findIndex(
                          (x) => x.productId === 0,
                        );
                        let tableData = [...values['tableData']];
                        let tableDataRow = { ...values['tableData'][tableDataRowIndex] };

                        tableDataRow.productId = prodId;
                        tableDataRow.name = props.products.data.find(
                          (item) => item.id === prodId,
                        ).name;
                        tableDataRow.price = props.products.data.find(
                          (item) => item.id === prodId,
                        ).price;
                        tableData[tableDataRowIndex] = tableDataRow;
                        setFieldValue('tableData', tableData);
                      }}
                    >
                      <Option key={-1} value={-1}>
                        Select Product
                      </Option>
                      {props.categories &&
                        props.categories.data.map(
                          (cat) =>
                            cat.categoryType === 0 && (
                              <OptGroup key={`${cat.id}${cat.name}`} label={cat.name}>
                                {cat.magProducts &&
                                  cat.magProducts.map(
                                    (prod) =>
                                      !innerProps.values.tableData.find(
                                        (element) => element.productId === prod.id,
                                      ) && (
                                        <Option key={prod.id} value={prod.id}>
                                          {prod.name}
                                        </Option>
                                      ),
                                  )}
                              </OptGroup>
                            ),
                        )}
                    </Select>
                  </Flex>
                ),
            },
            {
              title: <Label style={{ textAlign: 'center' }}>Quantity:</Label>,
              dataIndex: 'quantity',
              key: 'quantity',
              width: '30%',
              render: (qty, record) => (
                <QuantityWraper>
                  {canEdit && isCombo(record.productId) ? (
                    <GreenButton
                      size={12}
                      text={'Edit'}
                      onClick={() => {
                        setAddProduct({ ...getProduct(record.productId), index: record.id });
                      }}
                      style={{
                        borderRadius: 15,
                        width: 80,
                        height: 20,
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: 12,
                        lineHeight: 0,
                      }}
                    />
                  ) : (
                    <>
                      {canEdit && (
                        <RemoveButton
                          disabled={record.productId === 0}
                          style={{ width: 24, height: 24 }}
                          onClick={() => {
                            const tableDataRowIndex = values['tableData'].findIndex(
                              (x) => x.id === record.id,
                            );
                            let tableData = [...values['tableData']];
                            let tableDataRow = { ...values['tableData'][tableDataRowIndex] };
                            if (tableDataRow.qty > 1) {
                              tableDataRow.qty--;
                            }
                            tableData[tableDataRowIndex] = tableDataRow;
                            setFieldValue('tableData', tableData);
                          }}
                        />
                      )}
                      <ProductQuantity>{record.qty}</ProductQuantity>
                      {canEdit && (
                        <AddButton
                          disabled={record.productId === 0}
                          style={{ width: 24, height: 24 }}
                          onClick={() => {
                            const tableDataRowIndex = values['tableData'].findIndex(
                              (x) => x.id === record.id,
                            );
                            let tableData = [...values['tableData']];
                            let tableDataRow = { ...values['tableData'][tableDataRowIndex] };
                            tableDataRow.qty++;
                            tableData[tableDataRowIndex] = tableDataRow;
                            setFieldValue('tableData', [...tableData]);
                          }}
                        />
                      )}
                    </>
                  )}
                </QuantityWraper>
              ),
            },
            {
              title: (
                <Label style={{ textAlign: 'right', paddingRight: 28, width: '100%' }}>
                  Value:
                </Label>
              ),
              dataIndex: 'value',
              key: 'value',
              width: '30%',
              render: (value, record) => (
                <ProductValue>{currencyFormatter(record.price * record.qty)}</ProductValue>
              ),
            },
            {
              title: '',
              dataIndex: 'id',
              key: 'deleteAction',
              width: '10%',
              render: (id, record) =>
                canEdit && (
                  <RemoveAllButton
                    onClick={() =>
                      setFieldValue(
                        'tableData',
                        values['tableData'].filter((x) => x.id !== record.id),
                      )
                    }
                  />
                ),
            },
          ];
          const footer = () => (
            <>
              {canEdit && (
                <Flex style={{ backgroundColor: '#FFF', height: 44 }}>
                  <WhiteButton
                    disabled={!!values.tableData.find((item) => item.productId === 0)}
                    text={
                      <Flex>
                        <Icons.Plus
                          fill={colors.white}
                          style={{
                            width: 40,
                            height: 24,
                            backgroundColor: colors.blue,
                            borderRadius: 3,
                            marginLeft: 4,
                          }}
                        />
                        <span style={{ color: colors.blue, marginLeft: 20, marginRight: 4 }}>
                          Add Product
                        </span>
                      </Flex>
                    }
                    style={{ marginLeft: 24, border: 'none', minWidth: 164, display: 'flex' }}
                    onClick={() => {
                      let tableData = _.concat(values.tableData, []);
                      tableData.push({
                        id: uuidv4(),
                        name: '',
                        productId: 0,
                        orderId: activeOrder.id,
                        price: 0,
                        qty: 1,
                      });
                      setFieldValue('tableData', [...tableData]);
                    }}
                  />
                </Flex>
              )}
              <Flex style={{ paddingTop: 12 }}>
                <Total style={{ width: '60%' }}>Total:</Total>
                <ProductValue style={{ width: '30%' }}>
                  {values.tableData.length > 0 &&
                    currencyFormatter(
                      values.tableData.reduce((acc, product) => {
                        const toppingsSum =
                          product.toppings?.reduce((acc, obj) => acc + obj.price * obj.qty, 0) || 0;
                        return acc + product.price * product.qty + toppingsSum;
                      }, 0),
                    )}
                </ProductValue>
              </Flex>
            </>
          );

          return (
            <>
              <EditOrderHeaderWrapper>
                <Link onClick={props.history.goBack}>
                  <Icons.BackProducts fill={colors.blue} />
                </Link>
                <EditOrderTitle>Add New Order</EditOrderTitle>
                <EditOrderHeaderLabel style={{ flex: 1 }}>
                  <DateCreatedWrapper>
                    {/*Order Number: {`${activeOrder && activeOrder.orderCounter}`}*/}
                  </DateCreatedWrapper>
                </EditOrderHeaderLabel>
                {activeOrder && activeOrder.orderStatus === 1 ? (
                  <Button
                    onClick={() => {
                      if (
                        window.confirm(
                          `Are you sure you want to ${
                            activeOrder.paymentMethod === 0 ? 'reject' : 'reject and refund'
                          } this order?`,
                        )
                      ) {
                        props.refundOrder(activeOrder.id, changeUpdateStatus);
                      }
                    }}
                    text={activeOrder.paymentMethod === 0 ? 'Reject' : 'Reject and Refund'}
                    color={colors.red}
                    style={{
                      marginRight: '22px',
                      width: 'unset',
                      color: colors.white,
                      padding: '0 30px',
                      fontWeight: 'bold',
                    }}
                  />
                ) : null}
                {canEdit && (
                  <Button
                    color={colors.blue}
                    text={'Save'}
                    onClick={innerProps.handleSubmit}
                    disabled={!isValid || !!values.tableData.find((item) => item.productId === 0)}
                  />
                )}
              </EditOrderHeaderWrapper>
              <NotificationWrapper>
                <Alert show={updateStatus === 'OK'} message="Update successful!" />
                <ErrorWrapper show={updateStatus}>
                  <Icons.XIcon fill={colors.red} style={{ marginRight: 10 }} width={16} />
                  <span>Error editing the order</span>
                </ErrorWrapper>
              </NotificationWrapper>
              <EditOrderWrapper>
                <Prompt
                  when={innerProps.dirty}
                  message="You have unsaved changes. Are you sure you want to leave?"
                />
                <DetailsWrapper>
                  <Flex>
                    <OrderDetailsLabel>Order Details</OrderDetailsLabel>
                    <OrderPending>Unsaved</OrderPending>
                  </Flex>
                  {activeOrder?.tableName ? (
                    <Flex>
                      <Label>Table:</Label>
                      <Details>{activeOrder?.tableName}</Details>
                    </Flex>
                  ) : (
                    <>
                      {/* <Flex>
                        <Label>Client Name:</Label>
                        <Details>{activeOrder && `${activeOrder.clientName} ${activeOrder.clientLastName}`}</Details>
                      </Flex> */}
                      <Flex>
                        <Label>Client Address:</Label>
                        <Details>{activeOrder && activeOrder.orderLocation}</Details>
                      </Flex>
                      <Flex>
                        <Label>Address Details:</Label>
                        <Details>{activeOrder && activeOrder.orderLocation2}</Details>
                      </Flex>
                      <Flex>
                        <Label>Client Phone Number:</Label>
                        <Details>{activeOrder && activeOrder.clientPhone}</Details>
                      </Flex>
                    </>
                  )}

                  <Flex>
                    <Label>Note:</Label>
                    <Details>{activeOrder && activeOrder.customerDescription}</Details>
                  </Flex>
                </DetailsWrapper>
                <Table
                  name={'tableData'}
                  size={'default'}
                  rowKey={(row) => row.id}
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? 'table-row-dark' : 'table-row-light'
                  }
                  bordered={false}
                  footer={() => footer()}
                  columns={columns}
                  dataSource={innerProps.values.tableData}
                  pagination={!innerProps.values.tableData}
                  loading={!innerProps.values.tableData}
                  expandIcon={(p) => <CustomExpandIcon {...p} />}
                  expandedRowRender={(record) =>
                    record.toppings.length > 0 &&
                    record.toppings.map((topping) => (
                      <div key={record.id} style={{ display: 'flex', flexDirection: 'column' }}>
                        <div key={topping.id} style={{ display: 'flex', flexDirection: 'row' }}>
                          <Flex style={{ width: '30%' }}>
                            <Flex1 style={{ paddingLeft: 8 }}>
                              <ProductImage
                                alt="logo"
                                src={getImageURL(topping.productId) || product_image}
                                height="20"
                                width="40"
                              />
                            </Flex1>
                            <ProductName>{topping.name}</ProductName>
                          </Flex>
                          <div style={{ flex: 1, width: '30%' }}>
                            <ProductQuantity>{topping.qty}</ProductQuantity>
                          </div>
                          <div style={{ width: '30%' }}>
                            <ProductValue>
                              {currencyFormatter(topping.qty * topping.price)}
                            </ProductValue>
                          </div>
                          <div style={{ width: '10%' }} />
                        </div>
                      </div>
                    ))
                  }
                  defaultExpandedRowKeys={innerProps.values.tableData.reduce((prodsId, prod) => {
                    if (prod.toppings?.length > 0) {
                      prodsId.push(prod.id);
                    }
                    return prodsId;
                  }, [])}
                />
                {!!addProduct && (
                  <ProductModal
                    key={addProduct?.id}
                    show={!!addProduct}
                    product={addProduct}
                    orderProducts={innerProps.values.tableData.filter(
                      (p) => p.productId === addProduct?.id,
                    )}
                    toggle={() => setAddProduct(false)}
                    categories={props.categories?.data}
                    updateComboProduct={(val) => {
                      setFieldValue(
                        'tableData',
                        _.concat(
                          values['tableData']?.filter((x) => x.productId !== val[0].productId),
                          val,
                        ),
                      );
                    }}
                  />
                )}
              </EditOrderWrapper>
            </>
          );
        }}
      </Formik>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  orders: state.orders,
  products: state.products,
  categories: state.categories,
  facebookActivePage: state.facebookPages.activePage,
  tables: state.tables,
});

AddOrder.path = '/dashboard/order/add';
export default withRouter(
  connect(mapStateToProps, { edit, remove, refundOrder, getAll, saveOrder, updateOrderStatus })(
    AddOrder,
  ),
);
