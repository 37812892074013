import React from 'react';
import styled from 'styled-components';
import { AddButton, RemoveButton, RemoveAllButton, GreenButton } from 'app/shared/views/buttons';
import { useDispatch } from 'react-redux';
import { addProductToOrder, removeProductFromOrder } from 'actions/shoppingActions';
import colors from '../../../../helpers/colors';
import currencyFormatter from '../../../../helpers/currencyFormatter';

const OrderInfoCard = ({ order, currency, setAddProduct, readOnly, showFees, ...props }) => {
  const dispatch = useDispatch();

  const orderProducts = Object.values(order.orderProducts).reduce((acc, products) => {
    return acc.concat(products);
  }, []);

  return (
    <OrderInfoCardWrapper {...props}>
      <OderInfoTitle>Order</OderInfoTitle>
      {orderProducts.map((p, index) => {
        return (
          <div key={`p${p.id}${index}`}>
            <OrderProductEntry key={`p${p.id}${p.index}`}>
              <OrderProductName>{p.name}</OrderProductName>
              <OrderProductActions>
                <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                  {p.isCombo ? (
                    !readOnly ? (
                      <GreenButton
                        size={12}
                        text={'Edit'}
                        onClick={() => {
                          setAddProduct(p);
                        }}
                        style={{
                          borderRadius: 15,
                          width: 80,
                          height: 20,
                          fontStyle: 'normal',
                          fontWeight: 'bold',
                          fontSize: 12,
                          lineHeight: 0,
                        }}
                      />
                    ) : (
                      <OrderProductQuantity>{p.qty}</OrderProductQuantity>
                    )
                  ) : (
                    <>
                      {!readOnly && (
                        <RemoveButton
                          onClick={() => dispatch(removeProductFromOrder(p))}
                          style={{ height: '20px', width: '20px', borderRadius: 999 }}
                        />
                      )}
                      <OrderProductQuantity style={{ minWidth: 40 }}>{p.qty}</OrderProductQuantity>
                      {!readOnly && (
                        <AddButton
                          onClick={() => dispatch(addProductToOrder(p))}
                          style={{ height: '20px', width: '20px', borderRadius: 999 }}
                        />
                      )}
                    </>
                  )}
                </div>
                <div
                  style={{ minWidth: '50%', display: 'flex', justifyContent: 'flex-end', flex: 1 }}
                >
                  <PriceValue
                    style={{ marginRight: readOnly ? 0 : '15px', justifyContent: 'flex-end' }}
                  >
                    {currencyFormatter(
                      // ((p.price * p.qty) + p.toppings.reduce((acc, topping) => (acc + (topping.price * topping.qty)), 0)),
                      p.price * p.qty,
                      currency,
                    )}
                  </PriceValue>
                  {!readOnly && (
                    <RemoveAllButton onClick={() => dispatch(removeProductFromOrder(p, false))} />
                  )}
                </div>
              </OrderProductActions>
            </OrderProductEntry>
            {p.isCombo &&
              p.toppings?.map((t) => (
                <OrderProductEntry key={`t${p.id}${index}${t.id}`} isTopping={true}>
                  <OrderProductName isTopping={true}>{t.name}</OrderProductName>
                  <OrderProductActions>
                    <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                      <OrderProductQuantity>{t.qty}</OrderProductQuantity>
                    </div>
                    <div
                      style={{
                        minWidth: '50%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flex: 1,
                      }}
                    >
                      <PriceValue
                        style={{ marginRight: readOnly ? 0 : '39px', justifyContent: 'flex-end' }}
                      >
                        {currencyFormatter(t.price * t.qty, currency)}
                      </PriceValue>
                    </div>
                  </OrderProductActions>
                </OrderProductEntry>
              ))}
          </div>
        );
      })}

      {showFees ? (
        <OrderInfoTotal>
          <Divider style={{ marginBottom: '20px' }} />
          <OrderTotalPanelRow style={{ paddingRight: !readOnly ? '39px' : '' }}>
            <FeeLabel color="#8F92A7">Delivery Fee:</FeeLabel>
            <FeeValue color="#8F92A7">{currencyFormatter(order.deliveryFee, currency)}</FeeValue>
          </OrderTotalPanelRow>
          <OrderTotalPanelRow style={{ paddingRight: !readOnly ? '39px' : '' }}>
            <FeeLabel color="#1D2036">Value:</FeeLabel>
            <FeeValue color="#1D2036" style={{ justifyContent: 'flex-end' }}>
              {currencyFormatter(order.totalValue, currency)}
            </FeeValue>
          </OrderTotalPanelRow>
          <Divider style={{ margin: '20px 0' }} />
          <OrderTotalPanelRow style={{ paddingRight: !readOnly ? '39px' : '' }}>
            <OderInfoTitle style={{ flex: 1 }}>Total:</OderInfoTitle>
            <PriceValue style={{ justifyContent: 'flex-end' }}>
              {currencyFormatter(order.total, currency)}
            </PriceValue>
          </OrderTotalPanelRow>
        </OrderInfoTotal>
      ) : (
        <OrderInfoTotal style={{ flexDirection: 'row' }}>
          <OderInfoTitle style={{ flex: 1 }}>Total:</OderInfoTitle>
          <PriceValue style={{ justifyContent: 'flex-end', marginRight: '39px' }}>
            {
              // check if there is order?.totalValue for online order, total without delivery fee
            }
            {currencyFormatter(order?.totalValue || order.total, currency)}
          </PriceValue>
        </OrderInfoTotal>
      )}
    </OrderInfoCardWrapper>
  );
};

const OrderInfoCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
`;

const OrderProductEntry = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: ${(props) => (props.isTopping ? '8px' : '20px')};
`;

const OrderProductName = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  font-style: normal;
  min-width: 32%;
  padding-left: ${(props) => (props.isTopping ? '8px' : 0)};
  flex: 1;
`;

const OderInfoTitle = styled.span`
  display: flex;
  align-items: center;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #1d2036;
`;

const OrderInfoTotal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 32px;
`;

const OrderProductQuantity = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  color: #1d2036;

  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 80px;
`;

const OrderProductActions = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const PriceValue = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  min-width: 36px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  color: #2ecc71;
  min-width: 35px;
`;

const Divider = styled.div`
  background-color: #f9fafc;
  min-height: 2px;
  width: 100%;
`;

const OrderTotalPanelRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  padding-bottom: 10px;
  &:last-child {
    padding-bottom: 0;
  }
`;

const FeeLabel = styled.span`
  color: ${(props) => props.color || colors.textColor};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
`;

const FeeValue = styled.span`
  color: ${(props) => props.color || colors.textColor};
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
`;

export default OrderInfoCard;
