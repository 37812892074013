import React from 'react';
import styled from 'styled-components';

import { Formik } from 'formik';
import * as Yup from 'yup';

import shared from 'app/shared';

import FormButton from '../components/MainButton';
import FieldError from '../components/FieldError';

const {
  views: {
    Input,
  },
} = shared;

const ForgotPassForm = (props) => {

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Email is invalid').required('Email is required'),
      })}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        if (typeof props.onSubmit === 'function') {
          await props.onSubmit(values);
        }
        setSubmitting(false);
        resetForm();
      }}
    >
      {({ values, touched, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <FormWrapper>
            <InputWrapper>
              <Input
                labelText="Email"
                name="email"
                type="text"
                height={38}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.email && errors.email && (
                <FieldError>
                  {errors.email}
                </FieldError>
              )}
            </InputWrapper>

            <FormButton
              text="Reset"
              onClick={handleSubmit}
              disabled={isSubmitting}
              style={{ marginTop: '20px' }}
            />
          </FormWrapper>
        )
      }
    </Formik>
  )
};

const FormWrapper = styled.div`

`;

const InputWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin: 5px 0;
`;

export default ForgotPassForm;
