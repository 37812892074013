import React, { useState, useEffect, createContext, useRef } from 'react';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import Spinner from 'app/shared/views/spinner';

import { api } from 'actions/shoppingActions';
import { useDebounce } from 'app/shared/helpers/hooks';

const ShoppingWrapper = styled.div`
  background-color: #ffffff;
  max-width: 502px;
  margin: auto;
  height: calc(var(--vh, 1vh) * 85);
  width: 100%;
  overflow-y: scroll;
  position: relative;
  border: 1px #e8e9f3 solid;
`;

const LoadingWrapper = styled.div`
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  z-index: 999999;
`;

export const ShoppingContext = createContext({});

const ContextLayout = ({ children }) => {
  const activePage = useSelector((state) => state.facebookPages?.activePage);
  const categoryies = useSelector((state) => state.categories.data);

  const iframeConfig = useSelector(
    (state) => state.pageConfiguration.PUBLIC_onlineOrderIframeConfiguration,
  );

  const [categories, setCategories] = useState([]);
  const [extraCategories, setExtraCategories] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const debouncedSearchTerm = useDebounce(search, 300);
  const [scrollableArea, setScrollableArea] = useState(useRef(null));

  useEffect(() => {
    setCategories(
      categoryies.filter((cat) => cat.categoryType === 0 && cat.magProducts.length > 0),
    );
    setExtraCategories(
      categoryies.filter((cat) => cat.categoryType === 1 && cat.magProducts.length > 0),
    );
  }, [categoryies]);

  useEffect(() => {
    if (debouncedSearchTerm == null) return;
    const token = `Bearer ${localStorage.getItem('authToken')}`;

    api
      .get('/Categories/Search', {
        params: { search: debouncedSearchTerm },
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        const categories = res.data;
        setCategories(categories);
      });
  }, [debouncedSearchTerm]);

  return (
    <ShoppingWrapper ref={(ref) => setScrollableArea(ref)}>
      {activePage ? (
        <Helmet>
          <title>{activePage.pageName}</title>
          <meta
            name="viewport"
            content="width=device-width, minimal-ui,initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
        </Helmet>
      ) : null}

      <ShoppingContext.Provider
        value={{
          activePage,
          categories,
          extraCategories,
          iframeConfig,
          isLoading,
          setIsLoading,
          search,
          setSearch,
          scrollableArea,
        }}
      >
        {isLoading && (
          <LoadingWrapper>
            <Spinner size={80} style={{ height: '100vh', width: '100vw' }} />
          </LoadingWrapper>
        )}
        {activePage && children}
      </ShoppingContext.Provider>

      <ToastContainer
        position="bottom-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
      />
    </ShoppingWrapper>
  );
};

export default ContextLayout;
