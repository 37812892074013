import React, { Component } from 'react';
import { connect } from 'react-redux';
import shared from '../../../../app/shared';

const {
  helpers: {
    resetScroll
  },
  views: {
    Page
  },
} = shared;

const initialState = {
  filters: {
    selectedPeriod: 'today',
  },
  filteredData: [],
  customDate: {
    startDate: null,
    endDate: null
  },
};

class AdminMessengerPage extends Component {
  state = initialState;

  componentWillMount() {

  }

  componentDidMount() {
    resetScroll();
  }

  render() {
    return (
      <Page>TODO: Messenger</Page>
      );
  }
}

AdminMessengerPage.path = '/admin/messenger';

const mapStateToProps = state => ({
});

export default connect(
  mapStateToProps,
  { },
)(AdminMessengerPage);
