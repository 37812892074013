import React, { useState, useEffect, useCallback, useRef } from 'react';
import { AutoSizer, WindowScroller } from 'react-virtualized';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  addProductToOrder,
  removeProductFromOrder,
  getFirstProduct,
  setCurrentGeoRegion,
} from 'actions/shoppingActions';
import Categories from '../components/Categories';
import ProductSearch from '../components/ProductSearch';
import ErrorMessage from '../components/ErrorMessage';
import { BlackButton } from 'app/shared/views/buttons';
import currencyFormatter from 'app/shared/helpers/currencyFormatter';
import HorizontalScrollableSpace from 'app/shared/views/horizontalScrollableSpace';
import OrderInfo from 'app/shared/views/shopping/components/orderDetails';
import ProductComponents from '../components/productComponent';
import Header from '../components/Header';
import { getAllVouchers, getClientPromotions } from 'actions/crudActions';
import crudTypes from 'config/crudTypes';
import Icons from 'app/shared/views/icons';
import OrderPreview from '../components/OrderPreview';

const { ProductCard, ProductList, ProductModal } = ProductComponents;

const ChooseProducts = ({
  order,
  categories,
  extraCategories,
  pageInfo,
  onProceed,
  onBack,
  search,
  setSearch,
  hideBanner,
  disableOnlineOrder,
  internalOrder,
  iframeConfig,
  scrollableArea,
  hideStatus,
  preselectedProduct,
}) => {
  const dispatch = useDispatch();

  const staticFooter = useRef(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [error, setError] = useState(null);
  const [addProduct, setAddProduct] = useState(false);
  const promotion = useSelector((state) => state.shopping.promotion);
  const clientInfo = useSelector((state) => state.shopping.client);
  const [clientPromotions, setClientPromotions] = useState([]);
  const geoRegion = useSelector((state) => state.shopping.geoRegion);
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (!preselectedProduct) return;
    // search and get first product matching name
    dispatch(
      getFirstProduct(preselectedProduct, (err, product) => {
        if (!err) {
          setAddProduct({ ...product });
        }
      }),
    );
  }, [preselectedProduct, dispatch]);

  useEffect(() => {
    setSelectedCategory(categories[0]);
  }, [categories]);

  useEffect(() => {
    if (scrollableArea.current === null) return;
    if (hideBanner) {
      scrollableArea.scrollTo(0, 0);
    } else if (scrollableArea.scrollTop > 196) {
      scrollableArea.scrollTo(0, 196);
    }
  }, [selectedCategory, hideBanner, scrollableArea]);

  useEffect(() => {
    if (order.description.length > 125) {
      setError({
        message: 'Max 125 characters allowed in Order Details!',
      });
    } else {
      setError(false);
    }
  }, [order.description]);

  useEffect(() => {
    let errorTimer = setTimeout(() => setError(''), 5000);
    return () => {
      clearTimeout(errorTimer);
    };
  }, [error]);

  useEffect(() => {
    getClientPromotions(crudTypes.client, clientInfo.phone)
      .then(function (res) {
        setClientPromotions(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [clientInfo.phone]);
  const CheckCount = () => {
    let currentPromo;
    if (clientPromotions.length !== 0 && promotion !== null) {
      currentPromo = clientPromotions.filter((promo) => promo.promotionId === promotion.id);
      if (currentPromo.length === 0) {
        return true;
      }
      if (!promotion.recurring && currentPromo.map((count) => count.promoCount) >= 1) {
        return false;
      }
      return (
        currentPromo.map((count) => count.promoCount) < promotion.recurringCount ||
        promotion.recurringCount === 0
      );
    } else return true;
  };

  const preview = () => {
    setShowPreview(true);
  };

  const ChangePromotionBanner = () => {
    if (!promotion) return null;

    if (promotion.isActive === true && CheckCount() === true) {
      if (promotion.promotionType === 0) {
        if (promotion.discountType === 0) {
          return (
            <Badge>
              <div style={{ marginTop: '18px' }}>
                <Icons.Information />
              </div>
              <div style={{ position: 'absolute', left: '80px', top: '27px' }}>
                <PromotionTitle>Promotion: Product Based</PromotionTitle>
                <Name>
                  Buy {promotion.promotionCount.count} products and earn{' '}
                  {promotion.discountValue.value} '' {pageInfo.currency} discount
                </Name>
              </div>
            </Badge>
          );
        }
        if (promotion.discountType === 1) {
          return (
            <Badge>
              <div style={{ marginTop: '18px' }}>
                <Icons.Information />
              </div>
              <div style={{ position: 'absolute', left: '80px', top: '27px' }}>
                <PromotionTitle>Promotion: Product Based</PromotionTitle>
                <Name>
                  Buy {promotion.promotionCount.count} products and earn{' '}
                  {promotion.discountPercentage.percentage}% discount
                </Name>
              </div>
            </Badge>
          );
        }
        if (promotion.discountType === 2) {
          return (
            <Badge>
              <div style={{ marginTop: '18px' }}>
                <Icons.Information />
              </div>
              <div style={{ position: 'absolute', left: '80px', top: '27px' }}>
                <PromotionTitle>Promotion: Product Based</PromotionTitle>
                <Name>
                  Buy {promotion.promotionCount.count} products and earn{' '}
                  {promotion.discountFreeProduct.productId}
                </Name>
              </div>
            </Badge>
          );
        }
      }
      if (promotion.promotionType === 1) {
        if (promotion.discountType === 0) {
          return (
            <Badge>
              <div style={{ marginTop: '18px' }}>
                <Icons.Information />
              </div>
              <div style={{ position: 'absolute', left: '80px', top: '27px' }}>
                <PromotionTitle>Promotion: Value Based</PromotionTitle>
                <Name>
                  Reach {currencyFormatter(promotion.promotionValue.value)} in total and earn{' '}
                  {promotion.discountValue.value} '' {pageInfo.currency} discount
                </Name>
              </div>
            </Badge>
          );
        }
        if (promotion.discountType === 1) {
          return (
            <Badge>
              <div style={{ marginTop: '18px' }}>
                <Icons.Information />
              </div>
              <div style={{ position: 'absolute', left: '80px', top: '27px' }}>
                <PromotionTitle>Promotion: Value Based</PromotionTitle>
              </div>
              <Name>
                Reach {currencyFormatter(promotion.promotionValue.value)} in total and earn{' '}
                {promotion.discountPercentage.percentage}% discount
              </Name>
            </Badge>
          );
        }
        if (promotion.discountType === 2) {
          return (
            <Badge>
              <div style={{ marginTop: '18px' }}>
                <Icons.Information />
              </div>
              <div style={{ position: 'absolute', left: '80px', top: '27px' }}>
                <PromotionTitle>Promotion: Value Based</PromotionTitle>
                <Name>
                  Reach {currencyFormatter(promotion.promotionValue.value)} in total and earn{' '}
                  {promotion.discountFreeProduct.productId}
                </Name>
              </div>
            </Badge>
          );
        }
      }
    }
    return null;
  };

  const onSubmit = useCallback(() => {
    if (!pageInfo.isOpen && !internalOrder) {
      setError({
        message: `${pageInfo.pageName} is closed and cannot accept an order right now`,
      });
    } else if (
      (order.totalValue < pageInfo.minimumOrderValue && !internalOrder) ||
      order.totalValue === 0
    ) {
      const message = (
        <p>
          {((order.totalValue < pageInfo.minimumOrderValue && !internalOrder) ||
            order.totalValue === 0) && (
            <p>
              {`Your total order value must be at least ${currencyFormatter(
                pageInfo.minimumOrderValue,
                pageInfo.currency,
              )}`}
            </p>
          )}
        </p>
      );
      setError({
        message: message,
      });
    } else {
      if (
        (order.totalValue < pageInfo.minimumOrderValue && !internalOrder) ||
        order.totalValue === 0
      ) {
        setError({
          message: `Your total order value must be at least ${currencyFormatter(
            pageInfo.minimumOrderValue,
            pageInfo.currency,
          )}`,
        });
        return;
      }

      dispatch(setCurrentGeoRegion(geoRegion));
      dispatch(getAllVouchers(crudTypes.voucher, pageInfo.id));

      onProceed && onProceed();
    }
  }, [pageInfo, order, onProceed, internalOrder, dispatch, geoRegion]);

  return (
    <>
      {showPreview === true ? (
        <OrderPreview
          show={order.orderProducts}
          setShow={setShowPreview}
          pageInfo={pageInfo}
          onProceed={onSubmit}
          internalOrder={internalOrder}
        />
      ) : (
        <>
          <ChooseProductsWrapper>
            {!hideBanner && (
              <Header
                pageInfo={pageInfo}
                onBack={onBack}
                hideStatus={iframeConfig.hideStatus || hideStatus}
              />
            )}
            <div
              style={{
                position: 'sticky',
                background: '#fff',
                zIndex: 11,
                top: 0,
              }}
            >
              <div>{ChangePromotionBanner()}</div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '0.5rem',
                  padding: pageInfo.isList ? 0 : '20px',
                }}
              >
                <ProductSearch
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  style={
                    pageInfo.isList
                      ? {
                          height: 50,
                          borderBottom: '1px solid #F9FAFC',
                          backgroundColor: '#F9FAFC',
                        }
                      : {}
                  }
                  outerStyle={{
                    width: 'calc(100% - 1.75rem)',
                    backgroundColor: '#F9FAFC',
                    borderRadius: '6px',
                  }}
                />
              </div>
              <Categories
                selectedCategory={selectedCategory}
                categories={categories}
                order={order}
                setSelectedCategory={setSelectedCategory}
              />
            </div>
            {selectedCategory &&
              (pageInfo.isList ? (
                <WindowScroller scrollElement={scrollableArea ?? window}>
                  {(windowScrollerProps) => (
                    <AutoSizer disableHeight={true}>
                      {({ width }) => (
                        <ProductList
                          key={`${selectedCategory.id}-${search}`}
                          category={selectedCategory}
                          categories={categories}
                          pageInfo={pageInfo}
                          setAddProduct={setAddProduct}
                          promotion={promotion}
                          order={order}
                          search={search}
                          canOrder={internalOrder || !disableOnlineOrder}
                          windowScrollerProps={{ ...windowScrollerProps, width }}
                          internalOrder={internalOrder}
                        />
                      )}
                    </AutoSizer>
                  )}
                </WindowScroller>
              ) : (
                <HorizontalScrollableSpace gap={10} key={selectedCategory.id}>
                  {selectedCategory.magProducts.map((product) => (
                    <ProductCard
                      key={product.id}
                      product={product}
                      categories={categories}
                      currency={pageInfo.currency}
                      description={product.description}
                      qty={((order, p) => {
                        const orderProducts = order.orderProducts || [];
                        const op = orderProducts.find((op) => op.id === p.id);
                        return op ? op.qty : 0;
                      })(order, product)}
                      addProduct={() => {
                        dispatch(addProductToOrder(product, promotion));
                      }}
                      removeProduct={() => {
                        dispatch(removeProductFromOrder(product, promotion));
                      }}
                      isCombo={product.isCombo}
                      setAddProduct={setAddProduct}
                    />
                  ))}
                </HorizontalScrollableSpace>
              ))}
            {!!addProduct && (
              <ProductModal
                key={addProduct?.id}
                show={!!addProduct}
                product={addProduct}
                orderProducts={order.orderProducts?.filter(
                  (p) => p.categoryId === addProduct?.categoryId,
                )}
                toggle={() => setAddProduct(false)}
                categories={extraCategories}
                currency={pageInfo.currency}
                portraitProductImage={iframeConfig?.portraitProductImage}
                canOrder={internalOrder || !disableOnlineOrder}
                promotion={promotion}
                pageInfo={pageInfo}
              />
            )}
            {!pageInfo.isList && (
              <>
                <Divider />
                <Section>
                  <OrderInfo
                    order={order}
                    // removeProduct={removeProductFromOrder}
                    currency={pageInfo.currency}
                    setAddProduct={setAddProduct}
                    magProducts={selectedCategory?.magProducts}
                  />
                </Section>
              </>
            )}
          </ChooseProductsWrapper>
          <div
            ref={staticFooter}
            style={{
              position: 'fixed',
              bottom: '1rem',
              maxWidth: 498,
              transition: 'bottom 0.3s linear',
              zIndex: 15,
              width: '100vw',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '1rem 0',
            }}
          >
            <ErrorMessage
              message={error && error.message}
              show={!!error}
              style={{ padding: '10px 30px' }}
            />
            <BlackButton
              onClick={preview}
              style={{
                minHeight: '60px',
                height: 60,
                fontSize: 16,
                fontWeight: 'bold',
                color: '#FFFFFF',
                borderRadius: '7px',
                display: order.totalValue ? 'flex' : 'none',
                width: '95%',
              }}
              text={
                <ButtonText>
                  <ButtonInfo>
                    <AllQty>
                      {order.orderProducts.legth !== 0 &&
                        order.orderProducts.reduce(
                          (accumulator, currProd) => accumulator + currProd.qty,
                          0,
                        )}
                    </AllQty>
                    <p style={{ padding: '3px' }}>View Order</p>
                  </ButtonInfo>
                  <p style={{ marginRight: '30px', padding: '3px' }}>{order.totalValue}</p>
                </ButtonText>
              }
            />
          </div>
        </>
      )}
    </>
  );
};

const ChooseProductsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #ffffff;
  transition: height 0.5s linear;
  width: 100%;
  flex: 1;
  padding-bottom: 10rem;
`;

const Divider = styled.div`
  background-color: #f9fafc;
  min-height: 2px;
  width: 100%;
`;

const Section = styled.div`
  padding: 20px;

  &:last-child {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`;

const ButtonText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  width: 100%;
`;

const ButtonInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
`;

const AllQty = styled.p`
  border: 2px solid white;
  border-radius: 50%;
  padding: 0.25rem 0.4rem;
  margin-right: 5px;
`;

const Badge = styled.div`
  display: block;
  padding: 5px 15px;
  width: 90%;
  margin-top: 10px;
  height: 71px;
  margin-left: 5%;
  background: ${(promotion) => (promotion.isActive ? '#4aa84f' : '#4aa84f')};
  border-radius: 6px;
  color: #2ecc71;
  background: #eafaf1;
  border: 2px solid #2ecc71;
`;
const Name = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  white-space: nowrap;
`;

const PromotionTitle = styled.h3`
  font-size: 17px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
`;
export default ChooseProducts;
