import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import colors from '../helpers/colors';
import React from 'react';

const H1 = styled.h1`
  font-size: 36px;
  line-height: 43px;
  font-weight: 500;
  color: ${colors.darkestGrey};
`;

const H2 = styled.h2`
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  color: ${colors.darkestGrey};
`;

const H3 = styled.h3`
  font-size: 24px;
  line-height: 29px;
  font-weight: 500;
  color: ${colors.darkestGrey};
`;

const H4 = styled.h4`
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  color: ${colors.darkestGrey};
`;

const H5 = styled.h5`
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: ${colors.darkestGrey};
`;

const H6 = styled.h6`
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: ${colors.darkestGrey};
`;

const Link = styled.a`
  font-size: 12px;
  line-height: 14px;
  color: ${(props) => props.color ?? colors.blue};
  border-bottom: 1px solid transparent;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.15s ease-in-out;

  :hover {
    border-bottom: 1px solid ${(props) => props.color ?? colors.blue};
    cursor: pointer;
  }
`;

const AppLink = styled(NavLink)`
  font-size: 12px;
  line-height: 14px;
  color: ${(props) => props.color ?? colors.blue};
  border-bottom: 1px solid transparent;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.15s ease-in-out;

  :hover {
    border-bottom: 1px solid ${(props) => props.color ?? colors.blue};
    cursor: pointer;
  }
`;

const P = styled.p`
  font-size: 16px;
  line-height: 22px;
  color: ${colors.darkestGrey};
`;

const MainTitle = styled(H1)`
  color: ${colors.blue};
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  font-family: Inter, sans-serif;
`;

const MainSubtitle = styled(H3)`
  width: 70%;
  margin-top: 5%;
  margin-left: 15%;
  text-align: center;
  font-weight: 500;
  font-size: 100%;
  line-height: 150%;
  font-family: 'Inter', sans-serif;

  color: ${colors.brownGrey};
`;
const Paragraph = styled(P)`
  color: ${colors.brownGrey};
  font-size: 100%;
  line-height: 180%;
  font-family: 'Inter', sans-serif;
  letter-spacing: 1px;
`;
const MainParagraph = styled(Paragraph)`
  width: 87.5%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 7%;
`;

const MainLabel = styled.label`
  font-family: 'Inter', sans-serif;
  color: ${colors.brownGrey};
  font-style: normal;
  font-weight: bold;
  font-size: 100%;
  text-align: center;
  vertical-align: text-top;
`;

const ExternalLink = styled.a`
  color: ${colors.blue};
  font-weight: normal;
  word-spacing: 1px;

  :hover {
    text-decoration: underline;
  }

  :active {
    color: orange;
  }
`;

const Label = ({ text, outerStyle }) => (
  <div style={outerStyle}>
    <label>{text}</label>
  </div>
);

export default {
  H1: { H1, MainTitle },
  H2,
  H3: { H3, MainSubtitle },
  H4,
  H5,
  H6,
  Label: { MainLabel, Label },
  Link: { Link, ExternalLink, AppLink },
  Paragraph: { P, MainParagraph, Paragraph },
};
