import React from 'react';
import CountDown from 'react-countdown';
import LocationPicker from 'app/shared/views/locationPicker';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderLocation } from 'actions/shoppingActions';
import styled from 'styled-components';
import { refundOrder } from 'actions/orderActions';

const PaymentDetailsWrapper = styled.div`
  background-color: #ffffff !important;
  position: relative !important;
  bottom: 0 !important;
  width: 100vw !important;
  max-width: 500px !important;
  
  overflow: hidden !important;
  margin: auto;
  
`;

export default (props) => {
  const pageInfo = useSelector((state) => state.shopping.pageInfo);
  const order = useSelector((state) => state.shopping.order);
  const dispatch = useDispatch();

  const renderer = ({ hours, minutes, seconds, completed }) => {
     let minToSeconds = minutes * seconds;
    if (seconds === 0)
    {
      minToSeconds = minutes * 60;
    }
    else if (minutes === 0) {
      minToSeconds = seconds;
    }
    const totalWidth = 300;
    let newWidth = minToSeconds / totalWidth * 100;
    
    if (completed) {
      dispatch(refundOrder(order.id))
      return (<h2>order cancelled</h2>)
    } else {
      // Render a countdown
      return (
        <PaymentDetailsWrapper>
          <div style={{height: '30rem'}}>
            <LocationPicker
              pageInfo={pageInfo}
              location={order.orderLocation}
              onLocationPicked={(location) => {
                dispatch(setOrderLocation(location));
              }}
              containerStyles={{ position: 'absolute', height: '30rem' }}
            />
          </div>
          <div style={{ width: '100%' }}>
            <div>
              <h2 style={{ fontSize: '2rem', fontWeight: 800, margin: "0.5vw" }}>Waiting</h2>
              <p style={{marginLeft: '0.5vw', fontSize: '1rem'}}>Please wait until the restaurant confirms your order</p>
            </div>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "2vw"}}>
              <div
                style={{
                  border: '0.5px dashed #A5B4CC',
                  borderRadius: '12px',
                }}
              ></div>
              <h1 style={{ fontSize: '3.8vw' }}>
                {minutes}:{seconds}
              </h1>
              <div
                style={{
                  width: '180px',
                  height: '11px',
                  backgroundColor: 'gray',
                  borderRadius: '30px',
                }}
              >
                <div
                  style={{
                    width: `${newWidth}%`,
                    height: '11px',
                    backgroundColor: 'black',
                    borderRadius: '30px',
                  }}
                ></div>
              </div>
            </div>
          </div>
        </PaymentDetailsWrapper>
      );
    }
  };

  return (
    <div style={({ ...props.style }, { display: 'flex', height: '100%', width: '100%' })}>
      <div style={{ margin: 'auto' }}>
        <CountDown date={Date.now() + 300000} renderer={renderer} />
      </div>
    </div>
  );
};
